import React from 'react';
import PropTypes from 'prop-types';
import {columnIds, columnIdToReadableName} from "pages/Player/VideoPlayerListPage/config";
import DateRangeFilter from "components/FilterSidebar/DateRangeFilter";
import FilterSidebar from "components/FilterSidebar";
import NumberRangeFilter from "components/FilterSidebar/NumberRangeFilter";
import MultiSelectFilter from "components/FilterSidebar/MultiSelectFilter";
import {FilterType} from "components/FilterSidebar/useFilter";

function FilterSidebarVideoPlayerPage({ filter, uniqueDomains }) {
  const numericPlaceholders = {
    placeholder1: '0',
    placeholder2: '100',
  };
  const options = filter.filterSectionProps.addCheckedStatus(columnIds.domainName, uniqueDomains.map((domain) => ({
    value: domain,
    label: domain,
  })));

  return (
    <FilterSidebar filter={filter}>
      <MultiSelectFilter
        { ...filter.filterSectionProps }
        columnId={columnIds.domainName}
        title={ columnIdToReadableName[ columnIds.domainName ] }
        options={ options }
        nothingFoundText="No domains found"
        defaultExpanded={true}
        search={true}
      />
      <DateRangeFilter
        columnId={ columnIds.dateCreated }
        title={ columnIdToReadableName[columnIds.dateCreated] }
        { ...filter.filterSectionProps }
        defaultExpanded={true}
      />
      <NumberRangeFilter
        columnId={ columnIds.impressions }
        title={ columnIdToReadableName[ columnIds.impressions ] }
        onSubmit={ filter.applyDraftColumnFilters }
        { ...filter.filterSectionProps }
        { ...numericPlaceholders }
        defaultExpanded={true}
      />
      <NumberRangeFilter
        columnId={ columnIds.plays }
        title={ columnIdToReadableName[ columnIds.plays ] }
        onSubmit={ filter.applyDraftColumnFilters }
        { ...filter.filterSectionProps }
        { ...numericPlaceholders }
        defaultExpanded={true}
      />
      <NumberRangeFilter
        columnId={ columnIds.minutesPlayed }
        title={ columnIdToReadableName[ columnIds.minutesPlayed ] }
        onSubmit={ filter.applyDraftColumnFilters }
        { ...filter.filterSectionProps }
        { ...numericPlaceholders }
        defaultExpanded={true}
      />
    </FilterSidebar>
  )
}

FilterSidebarVideoPlayerPage.propTypes = {
  filter: PropTypes.shape(FilterType),
  uniqueDomains: PropTypes.arrayOf(PropTypes.string),
};

export default FilterSidebarVideoPlayerPage;