import React from 'react';
import PropTypes from 'prop-types';

export function Rocket({ className, color, label }) {
    // Apply inline style only if color prop is not currentColor
    const style = color !== 'currentColor' ? { color } : undefined;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="1044.5 329.5 13.01 13.01"
            className={className}
            role="img"
            aria-label={label}
            style={style}
            width="16"
            height="16"
            >
            <path d="m1055.59 329.53-2.55.56c-.87.18-1.65.6-2.28 1.23l-1.48 1.47-1.81.03a1.29 1.29 0 0 0-.89.37l-1.82 1.82c-.51.5-.24 1.37.46 1.54l1.79.4c-.05.62.14 1.22.46 1.75l-.77.77a.59.59 0 1 0 .83.83l.77-.77c.53.33 1.11.51 1.75.46l.4 1.82a.94.94 0 0 0 1.57.43l1.82-1.82.09-.12c.18-.25.27-.48.28-.74v-1.81l1.47-1.48a4.6 4.6 0 0 0 1.26-2.3l.53-2.56a1.6 1.6 0 0 0-1.88-1.88Zm.25 1.17c.28-.06.52.18.46.46l-.53 2.59a3.36 3.36 0 0 1-.92 1.69l-1.66 1.63a.61.61 0 0 0-.15.43l-.03 2.03v.06l-1.51 1.5-.43-1.84c-.08-.3-.37-.52-.68-.46l-.31.06c-.3.05-.55-.16-.83-.27l2.96-2.93a.59.59 0 1 0-.83-.83l-2.96 2.93c-.11-.28-.32-.53-.27-.83l.06-.31a.57.57 0 0 0-.43-.68l-1.85-.43 1.48-1.48a.1.1 0 0 1 .06-.03l2.03-.03a.6.6 0 0 0 .43-.15l1.66-1.66a3.36 3.36 0 0 1 1.69-.92l2.56-.53Zm-.34.59-2.16.43-.3.15c-.05.02-.06.07-.03.12 0 .02.02.03.03.04l.46.43c.39.35.74.73 1.04 1.16l.37.53c.03.04.08.03.13 0l.03-.03.21-.46.43-2.13c.03-.1-.01-.22-.12-.24a.23.23 0 0 0-.09 0Z" fill="currentColor"/>
        </svg>
    );
}

Rocket.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.string,
};

Rocket.defaultProps = {
    color: 'currentColor', // Or any default color
};
