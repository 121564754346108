import {Button} from "components/Button";
import {Delete} from "icons";
import {Modal} from "components/Modal";
import React, {useState} from "react";
import {ReactComponent as WwwWarning} from "assets/images/WWWwarning.svg";
import {deleteDomain} from "api/qencode-content-delivery-api";
import PropTypes from "prop-types";
import {useAuth} from "context/AuthContext";
import ErrorMessage from "components/ErrorMessage";

function DeleteDomainModal({
  open,
  handleCancel,
  selectedDomain,
  onSuccessfulFinish,
  }) {
  const { getToken } = useAuth();
  const [deletionInProgress, setDeletionInProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  async function handleConfirmDeletion() {
    const accessToken = await getToken();
    setDeletionInProgress(true);
    const {success, error} = await deleteDomain({
      domainId: selectedDomain.id,
      accessToken,
    });
    if (success) {
      handleCancel();
      onSuccessfulFinish?.();
    } else {
      setErrorMessage(error);
    }
    setDeletionInProgress(false);
  }

  return (
    <Modal
      isOpen={open}
      variant="popUp"
      onClose={handleCancel}
      header={
        <>
          <WwwWarning/>
          <h3>
            Delete this Domain!
          </h3>
          <p>
            Are you sure you want to delete this domain?
          </p>
        </>
      }
      footer={
        <>
          <Button
            type="secondary"
            onClick={ handleCancel }
          >
            Cancel
          </Button>
          <Button
            icon={<Delete/>}
            type='danger'
            onClick={handleConfirmDeletion}
            state={ deletionInProgress ? 'disabled' : 'default' }
          >
            Delete
          </Button>
        </>
      }
    >
      { errorMessage && <ErrorMessage>{ errorMessage }</ErrorMessage> }
    </Modal>
  );
}

DeleteDomainModal.propTypes = {
  open: PropTypes.bool,
  handleCancel: PropTypes.func,
  domainId: PropTypes.string,
  onSuccessfulFinish: PropTypes.func,
};

export default DeleteDomainModal;