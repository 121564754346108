import PropTypes from "prop-types";
import React from "react";

export const Move = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M5.57399 3.77796C5.57399 3.93648 5.72083 4.06499 5.902 4.06499H7.39042V7.43356H4.06487V5.90072C4.06487 5.71955 3.93636 5.57268 3.77784 5.57268C3.70922 5.57268 3.64287 5.60078 3.59081 5.65188L1.53325 7.6717C1.37292 7.8291 1.35458 8.10524 1.4923 8.28849C1.50507 8.30548 1.51896 8.32132 1.53386 8.33588L3.59126 10.3471C3.71173 10.4649 3.89292 10.4487 3.99596 10.3111C4.04043 10.2516 4.06487 10.176 4.06487 10.0978V8.65202H7.39042V11.9351H5.90054C5.71937 11.9351 5.5725 12.0636 5.5725 12.2221C5.5725 12.2907 5.60059 12.3571 5.65169 12.4091L7.67151 14.4667C7.82891 14.627 8.10506 14.6453 8.2883 14.5076C8.30529 14.4949 8.32114 14.481 8.3357 14.4661L10.3469 12.4087C10.4647 12.2882 10.4486 12.107 10.3109 12.004C10.2515 11.9595 10.1758 11.9351 10.0976 11.9351H8.60888V8.65202H11.9349V10.0993C11.9349 10.1775 11.9594 10.2531 12.0038 10.3125C12.1069 10.4502 12.2881 10.4663 12.4085 10.3486L14.4659 8.33734C14.4808 8.32278 14.4947 8.30694 14.5075 8.28995C14.6452 8.1067 14.6269 7.83056 14.4665 7.67316L12.409 5.65334C12.3569 5.60224 12.2906 5.57417 12.222 5.57417C12.0634 5.57417 11.9349 5.72101 11.9349 5.90219V7.43356H8.60888V4.06499H10.0991C10.1773 4.06499 10.2529 4.04055 10.3123 3.99608C10.45 3.89304 10.4662 3.71185 10.3484 3.59139L8.33716 1.53398C8.3226 1.51908 8.30676 1.50519 8.28977 1.49242C8.10652 1.3547 7.83037 1.37304 7.67298 1.53338L5.65316 3.59094C5.60206 3.64299 5.57399 3.70934 5.57399 3.77796Z" fill="currentColor"/>
    </svg>    
  );
};

Move.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Move.defaultProps = {
  color: 'currentColor', // Or any default color
};
