import PropTypes from "prop-types";
import React from "react";

export const Lock = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"
    >
      <rect x="2.625" y="6.125" width="8.75" height="6.125" rx="2" stroke="currentColor" strokeWidth="1.2"/>
      <path d="M9.625 6.125V4.375C9.625 2.92525 8.44975 1.75 7 1.75V1.75V1.75C5.55025 1.75 4.375 2.92525 4.375 4.375V6.125" stroke="currentColor" strokeWidth="1.2"/>
      <path d="M6.88711 7.4375H4.4375C4.16136 7.4375 3.9375 7.66136 3.9375 7.9375V9.89719C3.9375 10.3164 4.42247 10.5495 4.74985 10.2876L7.19946 8.32793C7.56853 8.03268 7.35975 7.4375 6.88711 7.4375Z" fill="currentColor"/>
    </svg>    
  );
};

Lock.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Lock.defaultProps = {
  color: 'currentColor', // Or any default color
};