import PropTypes from "prop-types";
import React from "react";

export const PlayFilled = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M7.99844 14.5984C11.6435 14.5984 14.5984 11.6435 14.5984 7.99844C14.5984 4.35336 11.6435 1.39844 7.99844 1.39844C4.35336 1.39844 1.39844 4.35336 1.39844 7.99844C1.39844 11.6435 4.35336 14.5984 7.99844 14.5984ZM6.34844 6.21132V9.78348C6.34844 10.0121 6.53062 10.1974 6.75536 10.1974C6.82344 10.1974 6.89044 10.18 6.95021 10.1468L10.1696 8.36075C10.3669 8.25129 10.4396 7.99988 10.332 7.79921C10.2946 7.72945 10.2382 7.67211 10.1696 7.63406L6.95021 5.84798C6.75292 5.73852 6.50574 5.81247 6.39812 6.01313C6.36552 6.07393 6.34844 6.14207 6.34844 6.21132Z" fill="currentColor"/>
    </svg>    
  );
};

PlayFilled.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

PlayFilled.defaultProps = {
  color: 'currentColor', // Or any default color
};