import PropTypes from "prop-types";
import React from "react";

export function Copy({ className, color, label }) {
  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg
      className={className}
      width="12"
      height="12"
      aria-label={label}
      viewBox="0 0 12 12"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.08989 1.95506C8.08989 1.75838 8.01176 1.56976 7.87269 1.43068C7.73361 1.29161 7.54499 1.21348 7.34831 1.21348H1.95506C1.75838 1.21348 1.56976 1.29161 1.43068 1.43068C1.29161 1.56976 1.21348 1.75838 1.21348 1.95506V7.34831C1.21348 7.54499 1.29161 7.73361 1.43068 7.87269C1.56976 8.01176 1.75838 8.08989 1.95506 8.08989H2V4.4C2 3.07452 3.07452 2 4.4 2H8.08989V1.95506Z"
            fill="currentColor"/>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.95506 0C1.43654 0 0.939267 0.205978 0.572623 0.572623C0.205978 0.939267 0 1.43654 0 1.95506V7.34831C0 7.86683 0.205979 8.3641 0.572623 8.73075C0.939267 9.09739 1.43654 9.30337 1.95506 9.30337H2V8.08989H1.95506C1.75838 8.08989 1.56976 8.01176 1.43068 7.87269C1.29161 7.73361 1.21348 7.54499 1.21348 7.34831V1.95506C1.21348 1.75838 1.29161 1.56976 1.43068 1.43068C1.56976 1.29161 1.75838 1.21348 1.95506 1.21348H7.34831C7.54499 1.21348 7.73361 1.29161 7.87269 1.43068C8.01176 1.56976 8.08989 1.75838 8.08989 1.95506V2H9.30337V1.95506C9.30337 1.43654 9.09739 0.939267 8.73075 0.572623C8.3641 0.205979 7.86683 0 7.34831 0H1.95506ZM2.6966 8.08989V9.30337V10.045C2.6966 10.5635 2.90258 11.0608 3.26923 11.4274C3.63587 11.7941 4.13315 12.0001 4.65166 12.0001H10.0449C10.5634 12.0001 11.0607 11.7941 11.4274 11.4274C11.794 11.0608 12 10.5635 12 10.045V4.65175C12 4.13323 11.794 3.63596 11.4274 3.26931C11.0607 2.90267 10.5634 2.69669 10.0449 2.69669H9.30337H8.08989H4.65166C4.13315 2.69669 3.63587 2.90267 3.26923 3.26931C2.90258 3.63596 2.6966 4.13323 2.6966 4.65175V8.08989ZM3.91009 8.70215L3.91011 8.69663L3.91009 8.69111V4.65175C3.91009 4.45507 3.98822 4.26645 4.12729 4.12738C4.26636 3.9883 4.45498 3.91017 4.65166 3.91017H10.0449C10.2416 3.91017 10.4302 3.9883 10.5693 4.12738C10.7084 4.26645 10.7865 4.45507 10.7865 4.65175V10.045C10.7865 10.2417 10.7084 10.4303 10.5693 10.5694C10.4302 10.7084 10.2416 10.7866 10.0449 10.7866H4.65166C4.45498 10.7866 4.26636 10.7084 4.12729 10.5694C3.98822 10.4303 3.91009 10.2417 3.91009 10.045V8.70215Z"
        fill="currentColor"
      />
    </svg>
  );
}

Copy.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Copy.defaultProps = {
  color: 'currentColor', // Or any default color
};