import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Modal} from "components/Modal";
import {ReactComponent as BucketWithWarning} from "assets/images/BucketWithWarning.svg";
import {InputField} from "components/InputField";
import {Button} from "components/Button";
import {Delete, Warning} from "icons";
import style from "./style.module.css";
import {deleteBucket} from "api/qencode-account-api";
import {useAuth} from "context/AuthContext";

function DeleteBucketModal({ open, handleClose, bucket, onSuccessfulFinish }) {
  const {getToken} = useAuth();
  const [confirm, setConfirm] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  
  function clearFormAndClose() {
    setConfirm('');
    setErrorMessage('');
    handleClose();
  }
  
  async function onSubmit(e) {
    e.preventDefault();
    
    if (confirm === bucket?.name) {
      setSubmitting(true);
      const accessToken = await getToken();
      const {
        success,
        error,
      } = await deleteBucket({
        accessToken,
        region: bucket?.region,
        name: bucket?.name,
      });
      if (success) {
        clearFormAndClose();
        onSuccessfulFinish?.(bucket);
      } else {
        setErrorMessage(error);
      }
      setSubmitting(false);
    } else {
      setErrorMessage(`Bucket names don't match`);
    }
  }
  
  function onInputChange(e) {
    setConfirm(e.target.value);
    setErrorMessage();
  }

  return (
    <Modal
      isOpen={open}
      onClose={handleClose}
      variant="popUp"
      header={
        <>
          <BucketWithWarning/>
          <h3>
            Delete this Bucket?
          </h3>
          <p>
            Are you sure you want to delete bucket{' '}
            <span className={style.bucketName}>
              { bucket?.name }
            </span>
            ?
          </p>
        </>
      }
      footer={
        <>
          <Button
            onClick={clearFormAndClose}
            type="secondary"
          >
            Cancel
          </Button>
          <Button
            icon={ <Delete/> }
            variant='danger'
            state={ submitting ? "disabled" : "default" }
            onClick={onSubmit}
          >
            Delete
          </Button>
        </>
      }
    >
      <form onSubmit={onSubmit}>
        <InputField
          label="To confirm deletion, enter the bucket name"
          inputText={confirm}
          onChange={onInputChange}
          state={ errorMessage ? "error" : "default" }
          hint={ errorMessage }
        />
      </form>
      <p className={style.warning}>
        <Warning/>
        This will erase all files used in this bucket.
      </p>
    </Modal>
  )
}

DeleteBucketModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default DeleteBucketModal;