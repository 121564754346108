import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputField } from 'components/InputField';
import { FormCheckbox } from 'components/FormCheckbox';
import { Button } from 'components/Button';
import styles from '../../index.module.css'

export const BackblazeForm = ({ storageCredentials, onChange, onSave, onCancel }) => {
    const { bucket_name, access_key_id, secret_access_key, public_permissions } = storageCredentials.backblaze;

    const [validationErrors, setValidationErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        onChange({
            target: {
                name: name,
                value: value
            }
        })

        const [, key] = name.split('.');

        // Clear validation errors when input changes
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [key]: null,
        }));
    };

    const handleSave = () => {
        const errors = {};

        if (!bucket_name) {
            errors.bucket_name = 'Bucket name is required';
        }
        if (!access_key_id) {
            errors.access_key_id = 'Access Key ID is required';
        }
        if (!secret_access_key) {
            errors.secret_access_key = 'Secret Access Key is required';
        }

        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
        } else {
            onSave();
        }
    };

    // enable button when all input fields are set
    const isSaveDisabled = !bucket_name || !access_key_id || !secret_access_key;

    return (
        <div className={styles.storageFormContainer}>
            <div className={styles.formRow}>
                <InputField
                    inputText={bucket_name}
                    onChange={handleInputChange}
                    name="backblaze.bucket_name"
                    placeholder=""
                    label="Bucket Name"
                    state={validationErrors.bucket_name ? 'error' : 'default'}
                    hint={validationErrors.bucket_name || ''}
                />
            </div>

            <div className={styles.formRow}>
                <InputField
                    inputText={access_key_id}
                    onChange={handleInputChange}
                    name="backblaze.access_key_id"
                    placeholder=""
                    label="Access Key ID"
                    autocomplete="off"
                    state={validationErrors.access_key_id ? 'error' : 'default'}
                    hint={validationErrors.access_key_id || ''}
                />
                <InputField
                    inputText={secret_access_key}
                    onChange={handleInputChange}
                    name="backblaze.secret_access_key"
                    placeholder=""
                    label="Secret Access Key"
                    type="password"
                    autocomplete="new-password"
                    state={validationErrors.secret_access_key ? 'error' : 'default'}
                    hint={validationErrors.secret_access_key || ''}
                />
            </div>

            <div className={styles.formRow}>
                <FormCheckbox
                    name="backblaze.public_permissions"
                    checked={public_permissions || false}
                    label="Public permissions"
                    onChange={handleInputChange}
                />
            </div>

            <div className={styles.formFooter}>
                <Button 
                    size="sm"
                    type="secondary"
                    onClick={onCancel}
                >
                    Cancel
                </Button> 
                <Button size="sm" onClick={handleSave} state={isSaveDisabled ? "disabled" : undefined}>
                    Save
                </Button>
            </div>
        </div>
    );
};

BackblazeForm.propTypes = {
    storageCredentials: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

export default BackblazeForm;




// import React, { useState } from 'react';
// import PropTypes from 'prop-types';
// import { InputField } from 'components/InputField';
// import { FormCheckbox } from 'components/FormCheckbox';
// import { Button } from 'components/Button';
// import styles from '../../index.module.css'

// export const BackblazeForm = ({ storageCredentials, onChange, onSave }) => {

//     const [validationErrors, setValidationErrors] = useState({});

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;

//         onChange({
//             target: {
//                 name: name,
//                 value: value
//             }
//         })

//         const [, key] = name.split('.');

//         // Clear validation errors when input changes
//         setValidationErrors((prevErrors) => ({
//             ...prevErrors,
//             [key]: null,
//         }));
//     };    

//     const handleSave = () => {
//         const errors = {};

//         if (!storageCredentials.backblaze.bucket_name) {
//             errors.bucket_name = 'Bucket name is required';
//         }
//         if (!storageCredentials.backblaze.access_key_id) {
//             errors.access_key_id = 'Access Key ID is required';
//         }
//         if (!storageCredentials.backblaze.secret_access_key) {
//             errors.secret_access_key = 'Secret Access Key is required';
//         }

//         if (Object.keys(errors).length > 0) {
//             setValidationErrors(errors);
//         } else {
//             onSave();
//         }
//     };

//     // enable button when all input fields are set
//     const isSaveDisabled = !storageCredentials.backblaze.bucket_name || !storageCredentials.backblaze.access_key_id || !storageCredentials.backblaze.secret_access_key;

//     return (
//         <div className={styles.storageFormContainer}>
//             <div className={styles.formRow}>
//                 <InputField
//                     inputText={storageCredentials.backblaze.bucket_name}
//                     onChange={handleInputChange}
//                     name="backblaze.bucket_name"
//                     placeholder=""
//                     label="Bucket Name"
//                     state={validationErrors.bucket_name ? 'error' : 'default'}
//                     hint={validationErrors.bucket_name || ''}
//                 />
//             </div>

//             <div className={styles.formRow}>
//                 <InputField
//                     inputText={storageCredentials.backblaze.access_key_id}
//                     onChange={handleInputChange}
//                     name="backblaze.access_key_id"
//                     placeholder=""
//                     label="Access Key ID"
//                     state={validationErrors.access_key_id ? 'error' : 'default'}
//                     hint={validationErrors.access_key_id || ''}
//                 />
//                 <InputField
//                     inputText={storageCredentials.backblaze.secret_access_key}
//                     onChange={handleInputChange}
//                     name="backblaze.secret_access_key"
//                     placeholder=""
//                     label="Secret Access Key"
//                     type="password"
//                     state={validationErrors.secret_access_key ? 'error' : 'default'}
//                     hint={validationErrors.secret_access_key || ''}
//                 />
//             </div>

//             <div className={styles.formRow}>
//                 <FormCheckbox
//                     name="backblaze.public_permissions"
//                     checked={storageCredentials.backblaze.public_permissions || false}
//                     label="Public permissions"
//                     onChange={handleInputChange}
//                 />
//             </div>

//             <div className={styles.formFooter}>
//                 <Button size="sm" onClick={handleSave} state={isSaveDisabled ? "disabled" : undefined}>
//                     Save
//                 </Button>                
//             </div>
//         </div>
//     );
// };

// BackblazeForm.propTypes = {
//     storageCredentials: PropTypes.object.isRequired,
//     onChange: PropTypes.func.isRequired,
//     onSave: PropTypes.func.isRequired,
// };

// export default BackblazeForm;
