import {getProjectList} from "api/qencode-account-api";
import utcStringToDate from "helpers/utcStringToDate";

// const origin = 'https://api-live-qa.qencode.com'
// const api_version = 'v1'

// const base_api_url = `${origin}/${api_version}`

import config from 'config';

const { protocol, apiVersion, liveApiSubdomain, domain } = config;

// The base URL for API
const base_api_url = `${protocol}://${liveApiSubdomain}.${domain}/${apiVersion}`;

export async function listLiveStreams({
    accessToken,
  }) {
  try {
    const response = await fetch(`${base_api_url}/live-streams`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`
      },
    });

    const json = await response.json();

    if (!response.ok) {
      let error = `HTTP error ${ response.status }`;
      try {
        error = JSON.parse(json.error).detail;
      } catch (error) {}

      return {
        success: false,
        error,
      };
    }

    return {
      success: true,
      data: json.streams.map((stream) => ({
        ...stream,
        createdAtDate: utcStringToDate(stream['created_at']),
        status: stream.status.name,
      })),
    }
  } catch (error) {
    return {
      success: false,
      error: 'An error has happened when loading list of domains.',
    };
  }
}

export async function createLiveStream({ accessToken, body }) {
    try {
        const response = await fetch(`${ base_api_url }/live-streams`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify(body),
        });
        const json = await response.json();

        if (json.error === 1) {
            return {
                success: false,
                error: json.detail,
            };
        }
        return {
            success: true,
        }
    } catch (error) {
        return {
            success: false,
            error: "An error happened",
        }
    }
}

export async function deleteStream(
  {
    accessToken,
    id,
  }
) {
  try {
    const response = await fetch(`${ base_api_url }/live-streams/${ id }`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });

    if (response.ok) {
      return {
        success: true,
      }
    }
    return {
      success: false,
      error: "An error happened",
    }
  } catch (error) {
    return {
      success: false,
      error: "An error happened",
    }
  }
}


export async function updateLiveStream({ id, accessToken, body }) {
    try {
        const response = await fetch(`${base_api_url}/live-streams/${ id }`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(body),
        });

        const {
            detail,
            error,
            data
        } = await response.json();


        if (error || !response.ok) {
            return {
              success: false,
              error: error?.replace('Error saving stream data: ', '') || 'Failed to update live stream',
              detail,
            };
        }

        return { success: true, data };
    } catch (error) {
        return { success: false, error: error.message || 'Failed to update stream' };
    }
}

export async function getAccessToken({ accessToken, body, id }) {
    try {
        const response = await fetch(`${base_api_url}/access_token/${ id }`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(body),
        });

        if (!response.ok) {
            // Handle HTTP error responses
            return { success: false, error: `HTTP error: ${response.status}` };
        }

        const {
            detail,
            error,
            'access_token': data,
        } = await response.json();


        if (error) {
            return { success: false, error, detail };
        }

        return { success: true, data };
    } catch (error) {
        return { success: false, error: error.message || 'Failed to update stream' };
    }
}

export async function listLiveStreamsWithProjects(options) {
  try {
    const [streams, projects] = await Promise.allSettled([
      listLiveStreams(options),
      getProjectList({
          ...options,
          type: 'live',
      })
    ]);
    if (streams.status === 'fulfilled' && streams.value.success) {
        if (projects.status === 'fulfilled' && projects.value.success) {
            const idToProject = {};
            for (let project of projects.value.data) {
                idToProject[ project.id ] = project;
            }

            const streams2 = streams.value.data.map((stream) => {
                const project = idToProject[ stream['project_id'] ];
                return {
                    ...stream,
                    project,
                    projectName: project?.name || '-',
                };
            });

            return {
                ...streams.value,
                data: streams2,
                data2: projects.value.data,
            }
        } else {
            return streams.value;
        }
    }
  } catch (error) {
    return {
      data: [],
      success: false,
    }
  }
}

