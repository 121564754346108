import PropTypes from "prop-types";
import React from "react";

export const VerticalDots = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none"
    >
      <circle cx="14" cy="14" r="1.5" transform="rotate(-90 14 14)" fill="currentColor"/>
      <circle cx="14" cy="9" r="1.5" transform="rotate(-90 14 9)" fill="currentColor"/>
      <circle cx="14" cy="19" r="1.5" transform="rotate(-90 14 19)" fill="currentColor"/>
    </svg>    
  );
};

VerticalDots.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

VerticalDots.defaultProps = {
  color: 'currentColor', // Or any default color
};