// has error on downloading - http://localhost:3000/my_encodings/e732d0f735261fdd8a09c90da4d4c43d
// no errors completed - http://localhost:3000/my_encodings/be462fdabff6e5fccb777cd4db4d3313

import React, { 
    // useState, 
    // useRef 
} from 'react';


import { formatSize, formatDuration } from 'helpers/formatting'

import { CopyButton } from 'components/CopyButton'

import styles from './index.module.css';

// 44b4f51b2faa294eca6244d4a3c808c5 - multiple sources aka stitching

export const Source = ({inputFiles, loading, error}) => {
    
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div className={styles.errorMessage}>{error}</div>;
    }

    if (!inputFiles) {
        return null;
    }

    return (
        <div className={styles.inputFilesContainer}>

            <div className="tableContainer">
                <table className={`standardTable ${inputFiles?.length === 0 ? 'emptyTable' : ''}`}>
                    <thead>
                        <tr>
                            <th >FORMAT</th>
                            <th className='cellNoWrap'>VIDEO CODEC</th>
                            <th className='cellNoWrap'>AUDIO CODEC</th>
                            <th>RESOLUTION</th>
                            <th>SIZE</th>
                            <th>DURATION</th>
                            <th>URL</th>
                        </tr>
                    </thead>
                    <tbody>
                    {inputFiles.map((sourceFile, index) => (
                      <tr key={index} className="tableRow">
                        <td className="cellUppercase">
                            <div className="cellContainer">{sourceFile?.container || '—'}</div>
                        </td>                        
                        <td className="cellUppercase">
                            <div className="cellContainer">{sourceFile?.video_codec || '—'}</div>
                        </td>
                        <td className="cellUppercase">
                            <div className="cellContainer">{sourceFile?.audio_codec || '—'}</div>
                        </td>
                        <td className='cellNoWrap'>
                            <div className="cellContainer">
                                {
                                    sourceFile?.width && sourceFile?.height 
                                    ? <>{sourceFile?.width} x {sourceFile?.height}</>
                                    : <>—</>
                                }
                            </div>
                        </td>
                        <td className='cellNoWrap'>
                            {/* <div className="cellContainer">{formatSize(sourceFile?.size, 'MB') || '—'}</div> */}
                            {
                                sourceFile?.size
                                ?<>{formatSize(sourceFile?.size, 'MB')}</>
                                :<>—</>
                            }
                            
                        </td>
                        <td className="cellNoWrap">
                            <div className="cellContainer">{formatDuration(sourceFile?.duration) || '—'}</div>
                        </td>

                        <td className={styles.linkCell}>
                            <div className='cellContainer'> 
                                <div className='cellLimited'>
                                    <a  className={styles.link}
                                        href={sourceFile?.url} 
                                        target="_blank" rel="noreferrer noopener"
                                    >
                                            {sourceFile?.url}
                                    </a>                                    
                                </div>
                                <CopyButton contentToCopy={sourceFile?.url} />
                            </div>
                        </td>          
                      </tr>
                    ))}                    
                    </tbody>
                </table>    

            </div>

        </div>
    );
};