import React from 'react';
import PropTypes from 'prop-types';

export function Globe({ className, color, label }) {
    // Apply inline style only if color prop is not currentColor
    const style = color !== 'currentColor' ? { color } : undefined;

    return (
        <svg
            className={className}
            role="img"
            aria-label={label}
            style={style}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13Z"
                stroke="currentColor"
                strokeWidth="1.2"
            />
            <path
                d="M1.32581 5.11919C1.68673 5.829 2.2239 6.10601 2.93732 5.95022C4.00743 5.71653 3.84634 4.76885 4.6153 4.08744C5.38425 3.40602 6.04362 2.55248 5.64891 1.18555"
                stroke="currentColor"
                strokeWidth="1.2"
            />
            <path
                d="M11.7645 3.24609C10.7213 3.90804 10.1415 4.58302 10.0252 5.27102C9.85063 6.30302 10.416 6.82388 10.8759 7.44898C11.3357 8.07408 11.8976 9.29053 11.7645 10.6474"
                stroke="currentColor"
                strokeWidth="1.2"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.18519 8.80343C4.92986 8.19603 4.1223 7.65667 4.35487 6.87054C4.54819 6.21709 5.50777 5.96869 6.32033 5.99169C6.85581 6.00684 8.35965 6.27918 8.53574 7.45511C8.66822 8.33982 7.70198 9.2533 7.3698 9.9632C7.12099 10.4949 7.52149 11.3088 7.21017 11.5539C6.91745 11.7844 6.0459 11.413 5.61391 10.6594C5.18191 9.90582 5.44053 9.41083 5.18519 8.80343Z"
                fill="currentColor"
            />
        </svg>

    )
}

Globe.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.string,
};

Globe.defaultProps = {
    color: 'currentColor', // Or any default color
};
