// getting role from main layout that gets it from protected route
import React, { useMemo, useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './Sidebar.module.css';

import { 
    ChevronRight, ChevronLeft,
    Dashboard, Transcoding, CDN, LiveSteaming, MediaStorage, Statistics, VideoPlayer
} from '../../icons'

import { MenuItem } from './MenuItem'

import { ReactComponent as QencodeLogo } from '../../assets/images/QencodeLogo.svg';
import { ReactComponent as QLogo } from '../../assets/images/QencodeLogoQ.svg';

export const Sidebar = ({ isCollapsed, onToggle, userRole }) => {
    //console.log("userRole: ", userRole)

    const [scrollY, setScrollY] = useState(0);
    const sidebarRef = useRef(null);
    const location = useLocation();

    const toggleIcon = isCollapsed ? <ChevronRight label="Expand" /> : <ChevronLeft label="Collapse" />;

    const computedSidebarItems = useMemo(() => {
        const initialSidebarItemsState = [
            {
                name: "Services",
                items:[
                    {
                        label: 'Dashboard',
                        icon: <Dashboard />,
                        to: '/dashboard',
                        restrictedRoles: ['billing'], // Example: restrict 'billing' from seeing the dashboard
                    }, 
                    {
                        label: 'Transcoding',
                        icon: <Transcoding />,
                        restrictedRoles: ['billing'],
                        submenuItems: [
                            {
                                label: 'Transcode a video',
                                to: '/request_builder',
                            },
                            {
                                label: 'Job History',
                                to: '/my_encodings',
                            },
                            {
                                label: 'Projects',
                                to: '/project/vod_projects',
                            },
                            {
                                label: 'Templates',
                                to: '/transcoding_templates'
                            }
                        ],
                    },    
                    {
                        label: 'Live Streaming',
                        icon: <LiveSteaming />,
                        restrictedRoles: ['billing'],
                        submenuItems: [
                            {
                                label: 'Streams',
                                to: '/stream/list',
                            },
                            {
                                label: 'Projects',
                                to: '/project/live_projects',
                            },
                        ],
                    },  
                    {
                        label: 'Media Storage',
                        icon: <MediaStorage />,
                        isBeta: true,
                        restrictedRoles: ['billing'],
                        submenuItems: [
                            {
                                label: 'Buckets',
                                to: '/storage/list',
                            },
                            {
                                label: 'Access Keys',
                                to: '/storage/credentials',
                            },
                        ],
                    },  
                    {
                        label: 'Content Delivery',
                        icon: <CDN />,
                        restrictedRoles: ['billing'],
                        submenuItems: [
                            {
                                label: 'Domains',
                                to: '/delivery/domains',
                            },
                            {
                                label: 'Signing Keys',
                                to: '/delivery/signing-keys',
                            },
                        ],
                    },    
                    {
                        label: 'Video Player',
                        icon: <VideoPlayer />,
                        to: '/players',
                        restrictedRoles: ['billing'],
                    },   
                    {
                        label: 'Statistics',
                        icon: <Statistics />,
                        to: '/usage_stats',
                        restrictedRoles: [],
                    },                                 
                ]
            }
        ];  

        // Filter out items that are restricted for the current user's role
        const filterItemsByRole = (items) => {
            return items.filter(item => !item.restrictedRoles.includes(userRole));
        };

        // Compute active status and filter items
        const computeActiveStatus = (items, currentPath) => {
            return items.map(section => ({
                ...section,
                items: filterItemsByRole(section.items).map(item => {
                    const isActive = currentPath.startsWith(item.to) || item.submenuItems?.some(submenuItem => currentPath.startsWith(submenuItem.to));
                    return {
                        ...item,
                        isActive,
                        submenuItems: item.submenuItems?.map(submenuItem => ({
                            ...submenuItem,
                            isActive: currentPath.startsWith(submenuItem.to)
                        }))
                    };
                })
            }));
        };        

        return computeActiveStatus(initialSidebarItemsState, location.pathname);
    }, [location.pathname, userRole]); // Only recompute if location.pathname or userRole changes   

    // Handle scroll for sidebar when collapsed
    useEffect(() => {
        const handleScroll = () => {            
            if (sidebarRef.current) {
                setScrollY(sidebarRef.current.scrollTop);
            }
        };

        const sidebarElement = sidebarRef.current;
        sidebarElement.addEventListener('scroll', handleScroll);

        return () => sidebarElement.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className={`${styles.container} ${isCollapsed ? styles.collapsed : ''}`}>
            <div className={styles.header}>
                { isCollapsed ? <QLogo className={styles.logo} /> : <QencodeLogo className={styles.logo} /> }
                <div 
                    className={styles.toggleButton}
                    onClick={onToggle} 
                >{toggleIcon}</div>                                 
            </div>
            <div className={styles.body} ref={sidebarRef}>
                {computedSidebarItems.map((section) => (
                    <div key={section.name} className={styles.section}>
                        {section.items.map((item) => (
                            <MenuItem
                                key={item.label}
                                label={item.label}
                                icon={item.icon}
                                to={item.to}
                                isActive={item.isActive}
                                isBeta={item.isBeta}
                                submenuItems={item.submenuItems}
                                isCollapsed={isCollapsed}
                                external={item.external}
                                scrollY={scrollY}
                            />
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};


// show all menu items
// import React, { useMemo, useRef, useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import styles from './Sidebar.module.css';

// import { 
//     ChevronRight, ChevronLeft,
//     Dashboard, Transcoding, CDN, LiveSteaming, MediaStorage, Statistics, VideoPlayer
// } from '../../icons'

// import { MenuItem } from './MenuItem'

// import { ReactComponent as QencodeLogo } from '../../assets/images/QencodeLogo.svg';
// import { ReactComponent as QLogo } from '../../assets/images/QencodeLogoQ.svg';

// export const Sidebar = ({ isCollapsed, onToggle }) => {

//     const [scrollY, setScrollY] = useState(0);
//     const sidebarRef = useRef(null);

//     const location = useLocation();

//     const toggleIcon = isCollapsed ? <ChevronRight label="Expand" /> : <ChevronLeft label="Collapse" />;

//     const computedSidebarItems = useMemo(() => {

//         const initialSidebarItemsState = [
//             {
//                 name: "Services",
//                 items:[
//                     {
//                         label: 'Dashboard',
//                         icon: <Dashboard />,
//                         to: '/dashboard',
//                     }, 
//                     {
//                         label: 'Transcoding',
//                         icon: <Transcoding />,
//                         submenuItems: [
//                             {
//                                 label: 'Transcode a video',
//                                 to: '/request_builder',
//                             },
//                             {
//                                 label: 'Job History',
//                                 to: '/my_encodings',
//                             },
//                             {
//                                 label: 'Projects',
//                                 to: '/project/vod_projects',
//                             },
//                             {
//                                 label: 'Templates',
//                                 to: '/transcoding_templates'
//                             }
//                         ],
//                     },    
//                     {
//                         label: 'Live Streaming',
//                         icon: <LiveSteaming />,
//                         submenuItems: [
//                             {
//                                 label: 'Streams',
//                                 to: '/stream/list',
//                             },
//                             {
//                                 label: 'Projects',
//                                 to: '/project/live_projects',
//                             },
//                         ],
//                     },  
//                     {
//                         label: 'Media Storage',
//                         icon: <MediaStorage />,
//                         isBeta: true,
//                         submenuItems: [
//                             {
//                                 label: 'Buckets',
//                                 to: '/storage/list',
//                             },
//                             {
//                                 label: 'Access Keys',
//                                 to: '/storage/credentials',
//                             },
//                         ],
//                     },  
//                     {
//                         label: 'Content Delivery',
//                         icon: <CDN />,
//                         submenuItems: [
//                             {
//                                 label: 'Domains',
//                                 to: '/delivery/domains',
//                             },
//                             {
//                                 label: 'Signing Keys',
//                                 to: '/delivery/signing-keys',
//                             },
//                         ],
//                     },    
//                     {
//                         label: 'Video Player',
//                         icon: <VideoPlayer />,
//                         to: '/players',
//                     },   
//                     {
//                         label: 'Statistics',
//                         icon: <Statistics />,
//                         to: '/usage_stats',
//                     },                                 
//                 ]
//             }
//         ];  


//         // Function to compute active status
//         const computeActiveStatus = (items, currentPath) => {
//             return items.map(section => ({
//                 ...section,
//                 items: section.items.map(item => {
//                     const isActive = currentPath.startsWith(item.to) || item.submenuItems?.some(submenuItem => currentPath.startsWith(submenuItem.to));
//                     return {
//                         ...item,
//                         isActive,
//                         submenuItems: item.submenuItems?.map(submenuItem => ({
//                             ...submenuItem,
//                             isActive: currentPath.startsWith(submenuItem.to)
//                         }))
//                     };
//                 })
//             }));
//         };        

//         return computeActiveStatus(initialSidebarItemsState, location.pathname);
//     }, [location.pathname]); // Only recompute if location.pathname or sidebarItems change    

//     // need this to handle situations when sidebar is collapsed and submenu is opened
//     // and when sidebar menu items overflow and use scrolls sidebar 
//     useEffect(() => {
//         const handleScroll = () => {            
//             if (sidebarRef.current) {
//                 setScrollY(sidebarRef.current.scrollTop);
//             }
//         };

//         const sidebarElement = sidebarRef.current;
//         sidebarElement.addEventListener('scroll', handleScroll);

//         return () => sidebarElement.removeEventListener('scroll', handleScroll);
//     }, []);

//     return (
//         <div className={`${styles.container} ${isCollapsed ? styles.collapsed : ''}`}>
//             <div className={styles.header}>
//                 { isCollapsed ? <QLogo className={styles.logo} /> : <QencodeLogo className={styles.logo} /> }
//                 <div 
//                     className={styles.toggleButton}
//                     onClick={onToggle} 
//                 >{toggleIcon}</div>                                 
//             </div>
//             <div className={styles.body} ref={sidebarRef}>
//                 {computedSidebarItems.map((section) => (
//                     <div key={section.name} className={styles.section}>
//                         {section.items.map((item) => (
//                             <MenuItem
//                                 key={item.label}
//                                 label={item.label}
//                                 icon={item.icon}
//                                 to={item.to}
//                                 isActive={item.isActive}
//                                 isBeta={item.isBeta}
//                                 submenuItems={item.submenuItems}
//                                 isCollapsed={isCollapsed}
//                                 external={item.external}
//                                 scrollY={scrollY}
//                             />
//                         ))}
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };






// // menu is displayed based on roles

// import React, { useMemo, useRef, useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import { useAuth } from 'context/AuthContext';
// import { jwtDecode } from 'jwt-decode'; // Use named import
// import styles from './Sidebar.module.css';

// import { 
//     ChevronRight, ChevronLeft,
//     Dashboard, Transcoding, CDN, LiveSteaming, MediaStorage, Statistics, VideoPlayer
// } from '../../icons';

// import { MenuItem } from './MenuItem';

// import { ReactComponent as QencodeLogo } from '../../assets/images/QencodeLogo.svg';
// import { ReactComponent as QLogo } from '../../assets/images/QencodeLogoQ.svg';

// export const Sidebar = ({ isCollapsed, onToggle }) => {
//     const [scrollY, setScrollY] = useState(0);
//     const [userRole, setUserRole] = useState(null);
//     const [isLoading, setIsLoading] = useState(true);
//     const sidebarRef = useRef(null);

//     const location = useLocation();
//     const { getToken } = useAuth();

//     const toggleIcon = isCollapsed ? <ChevronRight label="Expand" /> : <ChevronLeft label="Collapse" />;

//     useEffect(() => {
//         const fetchToken = async () => {
//             try {
//                 const token = await getToken();
//                 if (token) {
//                     const decodedToken = jwtDecode(token);
//                     setUserRole(decodedToken?.role);
//                 }
//             } catch (error) {
//                 console.error('Failed to decode token:', error);
//             } finally {
//                 setIsLoading(false);
//             }
//         };

//         fetchToken();
//     }, [getToken]);

//     const computedSidebarItems = useMemo(() => {
//         if (isLoading) return []; // Don't render anything if loading

//         // Define menu items with role-based access
//         const initialSidebarItemsState = [
//             {
//                 name: "Services",
//                 items: [
//                     {
//                         label: 'Dashboard',
//                         icon: <Dashboard />,
//                         to: '/dashboard',
//                         rolesAllowed: ['admin', 'developer', 'billing', 'owner'], // Allowed roles
//                     },
//                     {
//                         label: 'Transcoding',
//                         icon: <Transcoding />,
//                         rolesAllowed: ['admin', 'developer', 'owner'],
//                         submenuItems: [
//                             {
//                                 label: 'Transcode a video',
//                                 to: '/request_builder',
//                             },
//                             {
//                                 label: 'Job History',
//                                 to: '/my_encodings',
//                             },
//                             {
//                                 label: 'Projects',
//                                 to: '/project/vod_projects',
//                             },
//                             {
//                                 label: 'Templates',
//                                 to: '/transcoding_templates'
//                             }
//                         ],
//                     },
//                     {
//                         label: 'Live Streaming',
//                         icon: <LiveSteaming />,
//                         rolesAllowed: ['admin', 'developer', 'owner'],
//                         submenuItems: [
//                             {
//                                 label: 'Streams',
//                                 to: '/stream/list',
//                             },
//                             {
//                                 label: 'Projects',
//                                 to: '/project/live_projects',
//                             },
//                         ],
//                     },
//                     {
//                         label: 'Media Storage',
//                         icon: <MediaStorage />,
//                         isBeta: true,
//                         rolesAllowed: ['admin', 'developer', 'owner'],
//                         submenuItems: [
//                             {
//                                 label: 'Buckets',
//                                 to: '/storage/list',
//                             },
//                             {
//                                 label: 'Access Keys',
//                                 to: '/storage/credentials',
//                             },
//                         ],
//                     },
//                     {
//                         label: 'Content Delivery',
//                         icon: <CDN />,
//                         rolesAllowed: ['admin', 'developer', 'owner'],
//                         submenuItems: [
//                             {
//                                 label: 'Domains',
//                                 to: '/delivery/domains',
//                             },
//                             {
//                                 label: 'Signing Keys',
//                                 to: '/delivery/signing-keys',
//                             },
//                         ],
//                     },
//                     {
//                         label: 'Video Player',
//                         icon: <VideoPlayer />,
//                         to: '/players',
//                         rolesAllowed: ['admin', 'developer', 'owner'],
//                     },
//                     {
//                         label: 'Statistics',
//                         icon: <Statistics />,
//                         to: '/usage_stats',
//                         rolesAllowed: ['admin', 'developer', 'owner', 'billing'],
//                     },
//                 ]
//             }
//         ];

//         // Function to compute active status and filter items by role
//         const computeActiveStatus = (items, currentPath) => {
//             return items.map(section => ({
//                 ...section,
//                 items: section.items
//                     .filter(item => item.rolesAllowed.includes(userRole)) // Filter by user role
//                     .map(item => {
//                         const isActive = currentPath.startsWith(item.to) || item.submenuItems?.some(submenuItem => currentPath.startsWith(submenuItem.to));
//                         return {
//                             ...item,
//                             isActive,
//                             submenuItems: item.submenuItems?.map(submenuItem => ({
//                                 ...submenuItem,
//                                 isActive: currentPath.startsWith(submenuItem.to)
//                             }))
//                         };
//                     })
//             }));
//         };

//         return computeActiveStatus(initialSidebarItemsState, location.pathname);
//     }, [location.pathname, isLoading, userRole]);

//     // // Handle scroll to update scrollY
//     // useEffect(() => {
//     //     const handleScroll = () => {
//     //         if (sidebarRef.current) {
//     //             setScrollY(sidebarRef.current.scrollTop);
//     //         }
//     //     };

//     //     const sidebarElement = sidebarRef.current;
//     //     sidebarElement.addEventListener('scroll', handleScroll);

//     //     return () => sidebarElement.removeEventListener('scroll', handleScroll);
//     // }, []);

//     // Show a loading indicator while checking for the token
//     if (isLoading) {
//         return <div>Loading...</div>;
//     }

//     return (
//         <div className={`${styles.container} ${isCollapsed ? styles.collapsed : ''}`}>
//             <div className={styles.header}>
//                 {isCollapsed ? <QLogo className={styles.logo} /> : <QencodeLogo className={styles.logo} />}
//                 <div
//                     className={styles.toggleButton}
//                     onClick={onToggle}
//                 >{toggleIcon}</div>
//             </div>
//             <div className={styles.body} ref={sidebarRef}>
//                 {computedSidebarItems.map((section) => (
//                     <div key={section.name} className={styles.section}>
//                         {section.items.map((item) => (
//                             <MenuItem
//                                 key={item.label}
//                                 label={item.label}
//                                 icon={item.icon}
//                                 to={item.to}
//                                 isActive={item.isActive}
//                                 isBeta={item.isBeta}
//                                 submenuItems={item.submenuItems}
//                                 isCollapsed={isCollapsed}
//                                 external={item.external}
//                                 scrollY={scrollY}
//                             />
//                         ))}
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };





