import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.css';

function Controls({
  title,
  leftContent,
  rightContent,
}) {
  let leftLength = title ? 1 : 0;
  const isLeftFragment = React.isValidElement(leftContent) && leftContent.type === React.Fragment;
  if (isLeftFragment) {
    leftLength += leftContent.props.children.length;
  } else if (React.isValidElement(leftContent)) {
    leftLength += 1;
  }

  return (
    <div className={style.controls}>
      <div className={style.leftContent} style={{ '--column-count': leftLength }}>
        { title ? (<h4 className={style.heading}>{title}</h4>) : null}
        {leftContent}
      </div>
      { rightContent && (
        <div className={style.rightContent}>
          { rightContent }
        </div>
      ) }
    </div>
  )
}

Controls.propTypes = {
  title: PropTypes.string,
};

export default Controls;