// error on downloading - http://localhost:3000/my_encodings/e732d0f735261fdd8a09c90da4d4c43d
// no errors completed - http://localhost:3000/my_encodings/be462fdabff6e5fccb777cd4db4d3313



import React, { 
    useState, useEffect, useCallback, 
    //useRef 
} from 'react';

import { 
    Link, 
    //useNavigate, 
    //useLocation 
} from 'react-router-dom';

import { Stepper } from 'components/Stepper';
import { useQencodeApi } from 'api/useQencodeApi';

import { 
    Issue as ErrorIcon, 
    Support,
    ChevronLeft, 
    //Copy, 
    //Check, 
    Dot, Clock, 
    Project as ProjectIcon,
    CheckLine as CompletedIcon, 
    Download as DownloadingIcon, 
    Status as EncodingIcon,
    Pending as QueuedIcon,
    Folder as SavingIcon
} from 'icons'

import { Button } from 'components/Button';

import { CopyButton } from 'components/CopyButton'

//import { useApi } from 'api/useApi'

import { formatDateTime } from 'helpers/formatting';

// import Tippy from '@tippyjs/react';
// import 'tippy.js/dist/tippy.css';

import config from 'config';

import styles from './index.module.css';

const { landingSubdomain, domain } = config;
const supportUrl = `https://${landingSubdomain}.${domain}/#contact-block`;

export const Status = ({ taskToken, projectName, data, loading, error, onComplete, onResubmit, resubmittingTask, resubmitError }) => {

    //const navigate = useNavigate();
    //const location = useLocation();

    const { getStatus } = useQencodeApi();

    //const { resubmitTask } = useApi();    

    // for header
    //const jobIdRef = useRef(null);
    // const [tooltipContent, setTooltipContent] = useState('Click to Copy');
    //const [copied, setCopied] = useState(false);
    // const tippyInstance = useRef(null);        

    // for status steps
    const [lastStatusUrl, setLastStatusUrl] = useState('');
    //const [loadingStatusData, setLoadingStatusData] = useState(true); // probably no need to show anything to user when fetching status update
    const [statusError, setStatusError] = useState(null);
    const [statusData, setStatusData] = useState(null);

    const [savedExecutionLog, setSavedExecutionLog] = useState(null);
    const [savedInputFiles, setSavedInputFiles] = useState(null);

    // handle resubmit - moved to parent
    // const [resubmittingTask, setResubmittingTask] = useState(false); 
    // const [resubmitError, setResubmitError] = useState(null);

    const [steps, setSteps] = useState({
        created: { title: 'Created', begin: null, end: null, duration: null, passed: true, error: false },
        queued: { title: 'Queued', begin: null, end: null, duration: null, passed: false, error: false },
        downloading: { title: 'Downloading', begin: null, end: null, duration: null, passed: false, error: false },
        encoding: { title: 'Encoding', begin: null, end: null, duration: null, passed: false, error: false },
        saving: { title: 'Saving', begin: null, end: null, duration: null, passed: false, error: false },
        completed: { title: 'Completed', begin: null, end: null, duration: null, passed: false, error: false }
    });    

    const calculateDuration = (start, end) => {
        if (!start || !end) return null;
        const startTime = new Date(start);
        const endTime = new Date(end);
        if (startTime.getTime() === endTime.getTime()) return null; // Check if start and end are the same
        const duration = Math.abs(endTime - startTime) / 1000; // duration in seconds
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const seconds = duration % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toFixed(0).toString().padStart(2, '0')}`;
    };    

    const calculateTotalSize = (files) => {
        //console.log("files: ", files);
        if (!files || files.length === 0) return "0 MB";
    
        // Sum all sizes in MB, ensuring file.size is valid
        const totalSizeMB = files.reduce((sum, file) => {
            const fileSize = parseFloat(file.size);
            return sum + (isNaN(fileSize) ? 0 : fileSize);
        }, 0);
    
        // Convert to GB if total size is greater than or equal to 1024 MB
        if (totalSizeMB >= 1024) {
            const totalSizeGB = totalSizeMB / 1024;
            return `${totalSizeGB.toFixed(2)} GB`;
        }
    
        //console.log("totalSizeMB:", totalSizeMB)

        return `${totalSizeMB.toFixed(2)} MB`;
    };    

    // const calculateTotalDuration = (files) => {
    //     if (!files || files.length === 0) return "00:00:00";
    
    //     // Sum all durations in seconds
    //     const totalDurationSeconds = files.reduce((sum, file) => {
    //         return sum + parseFloat(file.duration);
    //     }, 0);
    
    //     const hours = Math.floor(totalDurationSeconds / 3600);
    //     const minutes = Math.floor((totalDurationSeconds % 3600) / 60);
    //     const seconds = totalDurationSeconds % 60;
    
    //     return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toFixed(0).toString().padStart(2, '0')}`;
    // };


    // calculate total duration of encoding process
    const calculateProcessDuration = (steps) => {
        const firstStep = steps.created.begin;
        const lastStepEnd = steps.completed.end || steps.saving.end || steps.encoding.end || steps.downloading.end || steps.queued.end;
        
        if (!firstStep || (!lastStepEnd && !steps.completed.passed)) return null;
      
        const endTime = lastStepEnd || new Date(); // Use current time if the process is ongoing
        return calculateDuration(firstStep, endTime);
    };
      

      
    const getStatusIcon = (status) => {
        switch (status) {
            case 'downloading':
                return <DownloadingIcon className={styles.statusIconDownload} />;
            case 'queued':
                return <QueuedIcon className={styles.statusIconQueued} />;
            case 'encoding':
                return <EncodingIcon className={styles.statusIconEncoding} />;
            case 'saving':
                return <SavingIcon className={styles.statusIconSaving} />;
            case 'completed':
                return <CompletedIcon className={styles.statusIconComplete} />;
            default:
                return null; // Or a default icon if needed
        }
    };

    const fetchStatusData = useCallback(async () => {
        try {
            //setLoadingStatusData(true);
            const settings = {
                taskToken,
                extended: 1,
                statusUrl: lastStatusUrl
            };
            const { success, data, detail } = await getStatus(settings);
            if (success && data) {
                //console.log("Status data: ", data);
                setLastStatusUrl(data.status_url);
                setStatusData(data);
                setStatusError(null);
                if (data.status === 'completed') {
                    console.log('call onComplete')
                    onComplete(); // Notify the parent component when the job is complete                    
                }
            } else {
                setStatusError(detail || "Failed to get Status data");
                console.error(detail || "Failed to get Status data");
            }
        } catch (error) {
            setStatusError(error.message);
            console.error(error); // Here, replace with more advanced error handling if necessary
        } finally {
            //setLoadingStatusData(false); // Ensures loading is set to false regardless of the outcome
        }
    }, [getStatus, taskToken, lastStatusUrl, onComplete]);

    useEffect(() => {
        if (data && !loading && !error) {
            setStatusData(data); // in this initial data there is most likely execution_log, so need to save it and use it
            setLastStatusUrl(data.status_url);
            //setLoadingStatusData(false);
            if (data.execution_log) {
                setSavedExecutionLog(data.execution_log); // Save the execution_log if present
            }    
            if (data.source_meta) {
                setSavedInputFiles(data.source_meta); // Save the source_meta if present
            }            
        }
    }, [data, loading, error]);

    useEffect(() => {
        if (statusData) {
            //console.log("statusData: ", statusData);
            if (statusData.status !== "completed") {
                const interval = setInterval(() => {
                    fetchStatusData();
                }, 5000);
                return () => clearInterval(interval);
            }
        } else {
            console.log("No status data");
        }
    }, [statusData, fetchStatusData]);

    // Track status data and update steps
    useEffect(() => {
        if (statusData) {       
            console.log("statusData: ", statusData);
            
            const statusKeys = ['created', 'queued', 'downloading', 'encoding', 'saving', 'completed'];

            const { error, error_description, status, execution_log } = statusData;

            // Initialize updatedSteps with execution_log if available, otherwise use savedExecutionLog
            const log = statusData.execution_log || savedExecutionLog || {};
            const updatedSteps = {
                created: { title: 'Created', begin: log.create_timestamp, end: log.create_timestamp, duration: null },
                queued: { title: 'Queued', begin: log.queue_begin, end: log.queue_end, duration: calculateDuration(log.queue_begin, log.queue_end) },
                downloading: { title: 'Downloading', begin: log.upload_begin, end: log.upload_end, duration: calculateDuration(log.upload_begin, log.upload_end) },
                encoding: { title: 'Encoding', begin: log.encode_begin, end: log.encode_end, duration: calculateDuration(log.encode_begin, log.encode_end) },
                saving: { title: 'Saving', begin: log.save_begin, end: log.save_end, duration: calculateDuration(log.save_begin, log.save_end) },
                completed: { title: 'Completed', begin: log.save_end, end: log.save_end, duration: null }
            };            
    
            if (!execution_log) {
                // Update based on status
                console.log("update based on status");

                if (error) {
                    // actaully if error do nothing, since transcoding will be completed and status with log will be fetched
                } else {
                    // Handle non-error case
                    statusKeys.forEach((step, i) => {
                        updatedSteps[step].passed = i <= statusKeys.indexOf(status);
                    });
                }
    
                setSteps(updatedSteps);
            } else {
                // Update based on execution_log
                console.log("update based on execution_log");

                // Set the saved execution log
                setSavedExecutionLog(execution_log);
    
                if (error) {
                    // set status error
                    setStatusError(error_description || "Error running transcoding job")

                    // Identify the step that had the error and set error to true for that step
                    let errorFound = false;
    
                    for (const step in updatedSteps) {
                        if (updatedSteps[step].end === null && updatedSteps[step].begin !== null) {
                            updatedSteps[step].error = true;
                            errorFound = true;
                            break;
                        }
                    }
    
                    // Mark all steps before the error step as passed
                    if (errorFound) {                        
                        for (const step in updatedSteps) {
                            if (updatedSteps[step].error) break;
                            updatedSteps[step].passed = true;
                        }
                    }
                } else {
                    // Mark current step and all previous steps as passed
                    statusKeys.forEach((step, i) => {
                        updatedSteps[step].passed = i <= statusKeys.indexOf(status);
                    });
                }
    
                setSteps(updatedSteps);
            }
        } 
    }, [statusData, savedExecutionLog]);


    // move this to parent
    // const handleResubmitJob = async () => {
    //     console.log("Resubmit job: ", taskToken)

    //     try {
    //         setResubmittingTask(true)
    //         setResubmitError(null);
    //         const { success, data, detail } = await resubmitTask(taskToken);
    //         if (success && data) {
    //             console.log("data: ", data)
    //             const {token, project_name} = data
    //             // navigate to new transcoding job and pass project_name as state
    //             navigate(`/my_encodings/${token}`, { state: { projectName: project_name } });

    //             // // Get the current location path
    //             // const currentPath = location.pathname;
    //             // // Replace the old token with the new token in the current path
    //             // const newPath = currentPath.replace(taskToken, token);
    //             // // Navigate to the new path and pass project_name as state
    //             // navigate(newPath, { state: { projectName: project_name } });                
    //         } else {
    //             setResubmitError(detail || "Failed to get Status data");
    //             console.error(detail || "Failed to get Status data");
    //         }
    //     } catch (error) {
    //         setResubmitError(error.message);
    //         console.error(error); // Here, replace with more advanced error handling if necessary
    //     } finally {
    //         setResubmittingTask(false)
    //     }

    // }    
     

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div className={styles.errorMessage}>{error}</div>;
    }

    if (!statusData) {
        return null;
    }

    // 36221db6d4b2429c9a4cc37f48a9a39b - error on Saving
    // 57a6f608377d885a4ca44ab2042cef24 - completed

    // 44b4f51b2faa294eca6244d4a3c808c5 - multiple sources aka stitching

    // statuses https://www.figma.com/design/qaN0frpQmcczLZoJvAqabV/Qencode-Portal-Site?node-id=8165-26431&t=9TOi7LUe8glMqhnO-0

    return (
        <div className={styles.statusContainer}>
            
            <div className={styles.statusBlockHeader}>
                <div className={styles.jobDetailsContainer}>
                    <Link to='/my_encodings' className={styles.backLink}>
                        <ChevronLeft label="Back" className={styles.backLinkIcon} />
                        <div className={styles.backLinkText}>Back to Jobs</div>
                    </Link>      
                    <div className={styles.jobDetails}>
                        <div className={styles.jobDetailsHeader}>
                            <div className={styles.jobIdContainer}>
                                JOB ID: <span className={styles.jobIdTitle}>{taskToken}</span>
                            </div>
                            <CopyButton contentToCopy={taskToken} />
                        </div>
                        <div className={styles.jobDetailsBody}>
                            {
                                projectName &&
                                <>
                                    <div className={styles.jobDetailsItem}>
                                        <ProjectIcon className={styles.jobDetailsItemIcon}  /> 
                                        <div className={styles.jobDetailsItemText}>{projectName}</div> 
                                    </div>        
                                    <Dot className={styles.delimeter} />                          
                                </>                              
                            }

                            {/* {
                                savedInputFiles && calculateTotalDuration(savedInputFiles) &&
                                <>
                                    <div className={styles.jobDetailsItem}>
                                        <Clock className={styles.jobDetailsItemIcon}  /> 
                                        <div className={styles.jobDetailsItemText}>{calculateTotalDuration(savedInputFiles)}</div> 
                                    </div>
                                    <Dot className={styles.delimeter} />
                                </>
                            } */}

                            {
                                savedExecutionLog && calculateProcessDuration(steps) && (
                                    <>
                                        <div className={styles.jobDetailsItem}>
                                            <Clock className={styles.jobDetailsItemIcon} />
                                            <div className={styles.jobDetailsItemText}>{calculateProcessDuration(steps)}</div>
                                        </div>
                                        <Dot className={styles.delimeter} />
                                    </>
                                )
                            }                            
                            

                            {
                                savedInputFiles && calculateTotalSize(savedInputFiles) &&
                                <>
                                    <div className={styles.jobDetailsItemSize}>
                                        <div className={styles.jobDetailsItemText}>{calculateTotalSize(savedInputFiles)}</div>                                                                
                                    </div>
                                    <Dot className={styles.delimeter} />                                
                                </>
                            }

                            {
                                savedExecutionLog && formatDateTime(savedExecutionLog?.create_timestamp) &&
                                <>
                                    <div className={styles.jobDetailsItem}>
                                        <div className={styles.jobDetailsItemText}>{savedExecutionLog && formatDateTime(savedExecutionLog?.create_timestamp)}</div>
                                    </div>
                                    <Dot className={styles.delimeter} />                                
                                </>
                            }

                            <div className={styles.jobDetailsItem}>
                                {
                                    statusData && statusData.error === 1
                                    ?
                                    <><ErrorIcon className={styles.errorIcon} /><div className={styles.jobDetailsItemText}>Error</div></>
                                    :
                                    <>  
                                        {getStatusIcon(statusData?.status)}
                                        <div className={styles.jobDetailsItemText}>{statusData?.status}</div>
                                    </>
                                }
                            </div>                           
                        </div>                        
                    </div>              
                </div>
                
                <Button
                    //onClick={handleResubmitJob}
                    onClick={onResubmit}
                    type="secondary"
                    state={resubmittingTask ? "disabled" : undefined}
                >
                    Resubmit Job
                </Button>
            </div>

            {
                resubmitError && <div className={styles.errorMessage}>{resubmitError}</div>
            }
            

            <div className={styles.statusProgressBlock}>

                <div className={styles.stepsContainer}>
                    <Stepper
                        position="start"
                        passed={steps.created.passed}
                        error={steps.created.error}
                        nextStepPassed={steps.queued.passed}
                        nextStepError={steps.queued.error}
                        title={steps.created.title}
                        timestamp={steps.created.begin || steps.created.end}
                        duration={steps.created.duration}
                    />
                    <Stepper
                        position="middle"
                        passed={steps.queued.passed}
                        error={steps.queued.error}
                        nextStepPassed={steps.downloading.passed}
                        nextStepError={steps.downloading.error}
                        title={steps.queued.title}
                        timestamp={steps.queued.begin || steps.queued.end}
                        duration={steps.queued.duration}
                    />  
                    <Stepper
                        position="middle"
                        passed={steps.downloading.passed}
                        error={steps.downloading.error}
                        nextStepPassed={steps.encoding.passed}
                        nextStepError={steps.encoding.error}
                        title={steps.downloading.title}
                        timestamp={steps.downloading.begin || steps.downloading.end}
                        duration={steps.downloading.duration}
                    />      
                    <Stepper
                        position="middle"
                        passed={steps.encoding.passed}
                        error={steps.encoding.error}
                        nextStepPassed={steps.saving.passed}
                        nextStepError={steps.saving.error}
                        title={steps.encoding.title}
                        timestamp={steps.encoding.begin || steps.encoding.end}
                        duration={steps.encoding.duration}
                    />     
                    <Stepper
                        position="middle"
                        passed={steps.saving.passed}
                        error={steps.saving.error}
                        nextStepPassed={steps.completed.passed}
                        nextStepError={steps.completed.error}
                        title={steps.saving.title}
                        timestamp={steps.saving.begin || steps.saving.end}
                        duration={steps.saving.duration}
                    />                    
                    <Stepper
                        position="end"
                        passed={steps.completed.passed}
                        error={steps.completed.error}
                        title={steps.completed.title}
                        timestamp={steps.completed.begin || steps.completed.end}
                        duration={steps.completed.duration}
                    />                                                               
                </div>

                {statusError && 
                    <div className={styles.statusErrorContainer}>
                        <div className={styles.errorLeft}>
                            <div className={styles.statusErrorIndicator}>
                                <ErrorIcon className={styles.errorIcon} /><span className={styles.statusErrorText}>Error</span>
                            </div>
                            <span className={styles.statusErrorMessage}>{statusError}</span>
                        </div>   
                        <a 
                            className={styles.contactSupportLink}
                            href={supportUrl} target='_blank' rel="noopener noreferrer"
                        >
                            <Support label="Support" className={styles.errorIcon}/>
                            <span className={styles.contactSupportLinkText}>Contact Support</span>
                        </a>                     
                    </div>
                }    

                {/* {loadingStatusData && <div>Loading...</div>} */}

            </div>
        </div>
    );
};
