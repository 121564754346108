import React, {useCallback, useMemo, useState} from 'react';
import {columnIds, columnIdToReadableName} from './config.js';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import globalFilterFn from "components/Table/columnFilters/exactCaseInsensitiveFilter";
import pageStyle from "./style.module.css";
import Search from "components/Search";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import useFilter from "components/FilterSidebar/useFilter";
import FilterSidebar from "pages/Player/VideoPlayerListPage/FilterSidebar";
import EmptyMessage from "pages/Player/VideoPlayerListPage/EmptyMessage";
import {listPlayersWithStats} from "api/qencode-account-api";
import useDataLoadingStatus from "hooks/useDataLoadingStatus";
import MoreActions from "pages/Player/VideoPlayerListPage/MoreRowActions";
import CloneModal from "pages/Player/VideoPlayerListPage/CloneModal";
import {modals} from './config';
import CreateModal from "pages/Player/VideoPlayerListPage/CreateModal";
import DeleteModal from "pages/Player/VideoPlayerListPage/DeleteModal";
import EditModal from "pages/Player/VideoPlayerListPage/EditModal";
import CreatePlayerButton from "pages/Player/VideoPlayerListPage/CreatePlayerButton";
import DateFormatted from "components/DateFormatted";
import isWithinDateRange from "components/FilterSidebar/DateRangeFilter/isWithinDateRange";
import isWithinEnumerableRange from "components/FilterSidebar/MultiSelectFilter/filterFn";
import isWithinNumberRange from "components/FilterSidebar/NumberRangeFilter/isWithinNumberRange";
import MaskedKey from "components/MaskedKey";
import {Link} from "react-router-dom";
import TableWrapper from "components/TableWrapper";


function VideoPlayerListPage() {
  const func = useCallback( listPlayersWithStats, [])
  const {
    data,
    loading,
    loadedSuccessfully,
    refresh,
    errorMessage,
  } = useDataLoadingStatus(func);

  const [openedModal, setOpenedModal] = useState();
  const [selected, setSelected] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([{
    id: columnIds.dateCreated,
    desc: true,
  }]);
  
  const columns = useMemo(() => [
    {
      id: columnIds.playerName,
      accessorKey: 'name',
      header: () => ( <span>{ columnIdToReadableName[ columnIds.playerName ] }</span> ),
      cell: (info) => (
        <Link  to={`/player/${ info.row?.original?.id }`  } className={ `linkEllipsis` } >
          { info.getValue() }
        </Link>
      ),
    },
    {
      id: columnIds.licenseKey,
      accessorKey: 'id',
      enableSorting: false,
      header: () => ( <span>{ columnIdToReadableName[ columnIds.licenseKey ] }</span> ),
      cell: (info) => (
        <MaskedKey rawKey={info.getValue()} withCopy={true} />
      ),
    },
    {
      id: columnIds.domainName,
      accessorKey: 'domain',
      filterFn: 'isWithinEnumerableRange',
      header: () => ( <span>{ columnIdToReadableName[ columnIds.domainName ] }</span> ),
      cell: (info) => ( <span className={pageStyle.ellipsis}>{ info.getValue() }</span> ),
    },
    {
      id: columnIds.impressions,
      accessorFn: (originalRow) => originalRow?.stats?.impressions,
      filterFn: 'isWithinNumberRange',
      header: () => ( <span>{ columnIdToReadableName[ columnIds.impressions ] }</span> ),
    },
    {
      id: columnIds.plays,
      accessorFn: (originalRow) => originalRow?.stats?.plays,
      filterFn: 'isWithinNumberRange',
      header: () => ( <span>{ columnIdToReadableName[ columnIds.plays ] }</span> ),
    },
    {
      id: columnIds.minutesPlayed,
      accessorFn: (originalRow) => originalRow?.stats?.['minutes_played'],
      filterFn: 'isWithinNumberRange',
      header: () => ( <span>{ columnIdToReadableName[ columnIds.minutesPlayed ] }</span> ),
    },
    {
      id: columnIds.dateCreated,
      accessorKey: 'created',
      sortingFn: 'datetime',
      filterFn: 'isWithinDateRange',
      header: () => ( <span>{ columnIdToReadableName[ columnIds.dateCreated ] }</span> ),
      cell: (info) => (
        <DateFormatted date={ info.getValue() } />
      ),
    },
    {
      id: columnIds.moreActions,
      enableSorting: false,
      cell: (info) => (
        <MoreActions
          player={ info.row.original }
          handleOpenModal={handleOpenModal}
        />
        ),
    }
  ], []);
  const filter = useFilter({ setPagination });
  const table = useReactTable({
    data,
    columns,
    defaultColumn: {
      cell: (info) => (<span>{ info.getValue() || '—' }</span>),
    },
    getCoreRowModel: getCoreRowModel(),
    /* Sorting */
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    /* Filtering */
    globalFilterFn,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    filterFns:{
      isWithinDateRange,
      isWithinEnumerableRange,
      isWithinNumberRange,
    },
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    onColumnFiltersChange: filter.setColumnFiltersAndClearPagination,
    
    state: {
      sorting,
      globalFilter,
      pagination,
      columnFilters: filter.columnFilters,
    },
  });
  
  
  function handleOpenModal(modalType, player) {
    setOpenedModal(modalType);
    setSelected(player);
  }
  
  function openCreateModal() {
    setOpenedModal(modals.create);
  }
  
  function handleCloseModals() {
    handleOpenModal();
  }
  
  const modalProps = {
    selected,
    handleCancel: handleCloseModals,
    onSuccessfulFinish: refresh,
  };
  
  const uniqueDomains = Array.from(new Set(data.map((player) => player?.domain)));
  
  return (
    <TableWrapper>
      <div className={pageStyle.toolbar}>
        <Search setGlobalFilter={setGlobalFilter} globalFilter={globalFilter}/>
        <FilterSidebar
          filter={filter}
          uniqueDomains={uniqueDomains}
        />
        {/*<ActiveFilterList filter={filter}/>*/}
        <CreatePlayerButton onClick={openCreateModal} className={pageStyle.createButton} />
      </div>
      <Table
        loading={loading}
        loadedSuccessfully={loadedSuccessfully}
        errorMessage={errorMessage}
        keepShowingContentOnLoad
        table={table}
        minWidth={700}
        clearAllFilters={filter.clearAllFilters}
        getEmptyComponent={() => <EmptyMessage onClick={openCreateModal} />}
        columnIdToClassName={{
          [columnIds.moreActions]: pageStyle.moreActionsColumn,
          [columnIds.plays]: pageStyle.playsColumn,
          [columnIds.impressions]: pageStyle.impressionsColumn,
          [columnIds.minutesPlayed]: pageStyle.minutesPlayedColumn,
          [columnIds.dateCreated]: pageStyle.dateCreatedColumn,
        }}
      />
      <Pagination table={ table } />
      <CreateModal
        { ...modalProps }
        open={ openedModal === modals.create }
      />
      <CloneModal
        { ...modalProps }
        open={ openedModal === modals.clone }
      />
      <DeleteModal
        { ...modalProps }
        open={ openedModal === modals.delete }
      />
      <EditModal
        { ...modalProps }
        open={ openedModal === modals.edit }
      />
    </TableWrapper>
  );
}

VideoPlayerListPage.propTypes = {};


export default VideoPlayerListPage;