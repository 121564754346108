import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.css';
import {Button} from "components/Button";

function TlsStatusButton({ domain, children, openTlsStatusModal }) {
  if (domain?.tlsSubscription?.status === 'pending') {
    return (
      <Button
        className={ style.button }
        onClick={() => openTlsStatusModal( domain )}
        variant="link"
      >
        { children }
      </Button>
    );
  }
  return <span>{ children }</span>
}

TlsStatusButton.propTypes = {
  domain: PropTypes.shape({
    tlsSubscription: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }),
  }),
  openTlsStatusModal: PropTypes.func.isRequired,
};

export default TlsStatusButton;