import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './SubmenuItem.module.css'; 

import { RightArrow } from "../../../icons"

export const SubmenuItem = ({ label, to, isActive }) => {
    return (
        <Link to={to} className={`${styles.container} ${isActive ? styles.active : ''}`}>
            <RightArrow className={styles.icon}/>
            <span>{label}</span>          
        </Link>
    );
};

SubmenuItem.propTypes = {
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    isActive: PropTypes.bool
};

SubmenuItem.defaultProps = {
    label: 'Submenu Item',
    to: '/',
    isActive: false
};