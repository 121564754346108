import React from "react";
import {Delete} from "icons";
import {Rename} from "icons/Rename";
import PropTypes from "prop-types";
import {OptionsMenu} from "components/OptionsMenu";
import {modals} from "pages/SigningKeysPage/config";

function MoreActionsPopover({ selectedSigningKey, handleOpenModal }) {
  return (
    <div className="cellContainer">
      <OptionsMenu
        menuItems={
          [
            {
              icon: <Rename label="Rename"/>,
              text: 'Rename',
              onClick: () => handleOpenModal( modals.rename, selectedSigningKey ),
            },
            {
              icon: <Delete label="Remove" />,
              onClick: () => handleOpenModal( modals.deletion, selectedSigningKey ),
              text: "Delete",
              danger: true,
            }
          ]
        }
      />
    </div>
  );
}

MoreActionsPopover.propTypes = {
  selectedSigningKey: PropTypes.object.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
};

export default MoreActionsPopover;