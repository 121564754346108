import React from 'react';
import PropTypes from 'prop-types';
import styles from './Toggle.module.css'; 

export const Toggle = React.forwardRef(({ label, checked, disabled, onChange }, ref) => {
  return (
    <label className={`${styles.toggleContainer} ${checked ? styles.checked : ''} ${disabled ? styles.disabled : ''}`}>
        <span className={styles.toggle}>
            <input 
                ref={ref}
                type="checkbox" 
                checked={checked}
                disabled={disabled}
                onChange={onChange}
            />            
        </span>
        {label && <span className={styles.label}>{label}</span>}
    </label>
  );
});

Toggle.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

Toggle.defaultProps = {
    label: '',
    checked: false,
    disabled: false,
    onChange: () => {},
};
