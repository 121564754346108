import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.css';
import ExpandableCell from "components/Table/Cells/Expandable";
import classNames from "classnames";
import MeterForFraction from "components/Meter/MeterForFraction";

function ExpandableCellWithMeter({
  info,
  level0Value,
  level1Value,
  dataProperty,
}) {
  const {row, getValue} = info;
  const nestedRow = row.depth > 0;
  let total = 0;
  let value = getValue();
  
  if (nestedRow) {
    total = row.getParentRow().original[dataProperty];

    if (level1Value) {
      value = level1Value;
    }
  } else if (level0Value) {
    value = level0Value;
  }

  return (
    <ExpandableCell
      info={info}
    >
      <span className={ classNames( style.title, {
        [style.nested]: nestedRow,
      } ) }>
        { value }
      </span>
      { nestedRow ?  (
        <MeterForFraction
          variant="horizontal"
          fraction={row.original[dataProperty]}
          total={total}
          maxFraction={total}
          horizontalLinesInvisible
        />
      ) : null }
    </ExpandableCell>
  );
}

ExpandableCellWithMeter.propTypes = {
  dataProperty: PropTypes.string.isRequired,
  info: PropTypes.object.isRequired,
};

export default ExpandableCellWithMeter;