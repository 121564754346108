import PropTypes from "prop-types";
import React from "react";

export const Duplicate = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M11.2301 3.14266C11.9896 3.14266 12.7289 3.38784 13.338 3.84173C13.338 2.49321 12.2448 1.40002 10.8962 1.40002H5.10155C3.75301 1.40002 2.6598 2.49321 2.6598 3.84173C3.27019 3.3878 4.01061 3.14266 4.77129 3.14266H11.2301ZM11 5.12172C12.3255 5.12172 13.4001 6.19624 13.4001 7.52172V11.0217C13.4001 12.3472 12.3255 13.4217 11 13.4217H4.99996C3.67445 13.4217 2.59992 12.3472 2.59992 11.0217V7.52172C2.59992 6.19624 3.67445 5.12172 4.99996 5.12172L11 5.12172ZM14.6001 7.52172C14.6001 5.5335 12.9883 3.92172 11 3.92172H4.99996C3.0117 3.92172 1.3999 5.5335 1.3999 7.52172V11.0217C1.3999 13.0099 3.0117 14.6217 4.99996 14.6217H11C12.9883 14.6217 14.6001 13.0099 14.6001 11.0217V7.52172ZM10 9.82166C10.3314 9.82166 10.6 9.55304 10.6 9.22167C10.6 8.8903 10.3314 8.62167 10 8.62167H8.60001V7.22167C8.60001 6.8903 8.33138 6.62167 8 6.62167C7.66862 6.62167 7.39999 6.8903 7.39999 7.22167V8.62167H5.99997C5.66859 8.62167 5.39996 8.8903 5.39996 9.22167C5.39996 9.55304 5.66859 9.82166 5.99997 9.82166H7.39999V11.2217C7.39999 11.553 7.66862 11.8217 8 11.8217C8.33138 11.8217 8.60001 11.553 8.60001 11.2217V9.82166H10Z" fill="currentColor"/>
    </svg>    
  );
};

Duplicate.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Duplicate.defaultProps = {
  color: 'currentColor', // Or any default color
};