import React from 'react';

import styles from './index.module.css';

import { 
  AddFunds as AddFundsIcon, 
  Invoices as InvoicesIcon,
  Receipt as ReceipstIcon, 
  Dollar as PaymentMethodsIcon, 
  Settings2 as SettingsIcon
} from '../../icons'

import { Tabs } from '../../components/Tabs';

import AddFunds from './AddFunds'
import Invoices from './Invoices'
import Receipts from './Receipts'
import PaymentMethods from './PaymentMethods'
import Settings from './Settings'

const billingTabs = [
    { name: 'add-funds', icon: <AddFundsIcon />, label: 'Add funds', component: <AddFunds /> },
    { name: 'invoices', icon: <InvoicesIcon />, label: 'Invoices', component: <Invoices /> },
    { name: 'receipts', icon: <ReceipstIcon />, label: 'Receipts', component: <Receipts /> },
    { name: 'payment-methods', icon: <PaymentMethodsIcon />, label: 'Payment Methods', component: <PaymentMethods /> },
    { name: 'settings', icon: <SettingsIcon />, label: 'Settings', component: <Settings /> },
];

export const BillingPage = () => {
  return (
      <div className={styles.contentContainer}>
        <Tabs tabs={billingTabs} basePath="/billing" />
      </div>
  );
};