import React from 'react';
import {Button} from "components/Button";
import PropTypes from "prop-types";
import {Add} from "icons";


function CreateDomainButton({ handleOpen }) {
  return (
    <>
      <Button
        onClick={handleOpen}
        icon={<Add/>}
      >
        Create Domain
      </Button>
    </>
  );
}

CreateDomainButton.propTypes = {
  handleOpen: PropTypes.func.isRequired,
};

export default CreateDomainButton;
