import React from 'react';
import PropTypes from 'prop-types';
import TableHeading from "components/Table/Heading";
import TableBody from "components/Table/Body";
import {Button} from "components/Button";
import { ReactComponent as LookingGlass } from 'assets/images/LookingGlass.svg';
import {NoData} from 'components/NoData';
import classNames from "classnames";

const TableWrapperWithHeadingProps = {
  customClassTable: PropTypes.string,
  fixed: PropTypes.bool,
  keepShowingContentOnLoad: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  minWidth: PropTypes.number,
  table: PropTypes.object.isRequired,
};

function Table(props) {
  const {
    loading,
    loadedSuccessfully,
    errorMessage,
    getEmptyComponent,
    keepShowingContentOnLoad = false,
    table,
    customClassTable,
    minWidth,
    fixed = true,
    columnIdToClassName = {}, /* Better alternative to columnIdToClassName is setting className in "meta" property in column definitions. This way everything is in one place. */
    clearAllFilters,
    rowClass,
    alternativeContent,
    noDataComponent,
    noDataImage,
    noDataTitle,
    noDataDescription,
    noDataFooter,
    onRowClick,
    rowSelectionAllowed,
    horizontalLinesInvisible,
    lastLineInvisible,
    skeletonRowCount,
    topRowsComponent,
  } = props;
  const {state} = table.options;
  const columnFilteringActive = state.columnFilters?.length;
  const globalFilterActive = Boolean(state.globalFilter);
  let filteredCount = table.getRowModel().rows.length;
  const totalRowCount = table.getRowCount();
  const tableHeadingProps = {
    customClassTable,
    fixed,
    keepShowingContentOnLoad,
    loading,
    minWidth,
    table,
    columnIdToClassName,
    horizontalLinesInvisible,
    lastLineInvisible,
    rowSelectionAllowed,
  };
  const tableBodyProps = {
    table,
    rowClass,
    onRowClick,
    rowSelectionAllowed,
    skeletonRowCount,
    topRowsComponent,
  };

  function resetFilterAndPagination() {
    table.setGlobalFilter('');
    clearAllFilters?.();
  }
  
  if (alternativeContent) {
    return (
      <TableWrapperWithHeading
        { ...tableHeadingProps }
        afterTable={alternativeContent}
      />
    )
  }

  if (loading) {
    if (totalRowCount === 0) { /* This is for showing loader on initial load. todo maybe employ filteredCount */
      return (
        <TableWrapperWithHeading { ...tableHeadingProps }>
          <TableBody
            {...tableBodyProps}
            loading={true}
          />
        </TableWrapperWithHeading>
      );
    } else /* if we already have some content, let's decide if show it with reduced opacity or loader. */ {
      if (keepShowingContentOnLoad) {
        return (
          <TableWrapperWithHeading { ...tableHeadingProps }>
            <TableBody {...tableBodyProps} />
          </TableWrapperWithHeading>
        );
      } else {
        return (
          <TableWrapperWithHeading
            { ...tableHeadingProps }
            afterTable={<Loading/>}
          />
        );
      }
    }
  }
  else if (loadedSuccessfully) {
    if (globalFilterActive || columnFilteringActive) {
      if (filteredCount === 0) {
        return (
          <TableWrapperWithHeading
            { ...tableHeadingProps }
            afterTable={(
              <FilterNoResults
                clearAllFilters={resetFilterAndPagination}
                globalFilterActive={ globalFilterActive }
                columnFilteringActive={ columnFilteringActive }
              />
            )}
          />
        );
      } else {
        return (
          <TableWrapperWithHeading { ...tableHeadingProps }>
            <TableBody {...tableBodyProps} />
          </TableWrapperWithHeading>
        );
      }
    }
    /* No filtering and still no results? Show empty table data message. */
    else if (!topRowsComponent && totalRowCount === 0) {
      if (getEmptyComponent) {
        const EmptyComponent = getEmptyComponent();
        
        return (
          <TableWrapperWithHeading
            { ...tableHeadingProps }
            afterTable={ EmptyComponent }
          />
        );
      } else if (noDataComponent) {
        return (
          <TableWrapperWithHeading
            { ...tableHeadingProps }
            afterTable={ noDataComponent }
          />
        );
      } else if (noDataImage || noDataTitle || noDataDescription || noDataFooter) {
        return (
          <TableWrapperWithHeading
            { ...tableHeadingProps }
            afterTable={
              <NoData
                image={noDataImage}
                title={noDataTitle}
                description={noDataDescription}
                footer={noDataFooter}
              />
            }
          />
        );
      }
    }
    else {
      return (
        <TableWrapperWithHeading { ...tableHeadingProps }>
          <TableBody {...tableBodyProps} />
        </TableWrapperWithHeading>
      );
    }
  }
  const error = errorMessage ?? 'An error has happened.';
  if (error) {
    return (
      <TableWrapperWithHeading
        { ...tableHeadingProps }
        afterTable={ (
          <div className="tableNodata">
            <span>
              { error }
            </span>
          </div>
        ) }
      />
    );
  }
}

Table.propTypes = {
  ...TableWrapperWithHeadingProps,
  loadedSuccessfully: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  getEmptyComponent: PropTypes.func,
  clearAllFilters: PropTypes.func,
  rowClass: PropTypes.string,
  noDataImage: PropTypes.element,
  noDataTitle: PropTypes.string,
  noDataDescription: PropTypes.string,
  skeletonRowCount: PropTypes.number,
};

function FilterNoResults({
  clearAllFilters,
  columnFilteringActive,
  globalFilterActive,
}) {
  let whatUpdate = 'query or filter';
  if (columnFilteringActive) {
    if (globalFilterActive) {
      whatUpdate = 'query or filter';
    } else {
      whatUpdate = 'filter';
    }
  } else if (globalFilterActive) {
    whatUpdate = 'query';
  }
  
  return (
    <div className="tableNodata">
      <div className="tableNodataImage">
        <LookingGlass/>
      </div>
      <p className="tableNodataTitle">
        No results found
      </p>
      <p className="tableNodataDescription">
        { `Please update ${ whatUpdate } and try again.` }
      </p>
      <Button
        variant="secondary"
        onClick={clearAllFilters}
      >
        Reset
      </Button>
    </div>
  )
}

FilterNoResults.propTypes = {
  clearAllFilters: PropTypes.func.isRequired,
}

function Loading() {
  return (
    <div className="tableNodata">
      <span>Loading...</span>
    </div>
  );
}

function TableWrapperWithHeading( {
    customClassTable,
    fixed,
    keepShowingContentOnLoad,
    loading,
    minWidth,
    table,
    children,
    afterTable,
    columnIdToClassName,
    rowSelectionAllowed,
    horizontalLinesInvisible,
    lastLineInvisible,
  } ) {
  return (
    <div className="tableContainer">
      <table
        className={ classNames('standardTable', customClassTable, {
          'fixedTable': fixed,
          'keepShowingContentOnLoad': keepShowingContentOnLoad,
          'emptyTable': table?.getRowModel()?.rows?.length < 1,
          'loadingTable': loading,
          'horizontalLinesInvisible': horizontalLinesInvisible,
          'lastLineInvisible': lastLineInvisible,
        }) }
        style={{ minWidth: `${minWidth}px` }}
        aria-busy={ loading }
      >
        <colgroup>
          { table.getHeaderGroups()[0]?.headers.map((value, index) => {
            const className = value.column.columnDef?.meta?.className || '';
            return (
              <col key={value.id} className={ `${ columnIdToClassName[value.id] || '' } ${ className }`   }/>
            )
          } )}
        </colgroup>
        <TableHeading table={ table } rowSelectionAllowed={rowSelectionAllowed}/>
        { children }
      </table>
        { afterTable }
    </div>
  );
}


TableWrapperWithHeading.propTypes = {
  ...TableWrapperWithHeadingProps,
  // afterTable: PropTypes.any,
};

export default Table;
