// import React, {useEffect, useRef} from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/Button';
import { OutputCard } from './OutputCard';
import { Add } from 'icons';
import styles from '../index.module.css';

import { getFileNameWithoutExtension, getFileExtension } from './helpers'
import { parseDestinationUrl } from 'helpers/urlParser';

export const OutputSettings = ({ formats, initialFormats, destination, videoSrc, onFormatsUpdate }) => {

    const handleFormatUpdate = (index, newFormat) => {
        const updatedFormats = [...formats];
        updatedFormats[index] = newFormat;
        onFormatsUpdate(updatedFormats);
    };

    const handleAddOutput = () => {

        // destination.url should always be baseUrl

        // let defaultOutput = {
        //     output: 'mp4', // default output value
        //     video_codec: 'libx264', // default codec value
        //     height: 2160,
        //     quality: 20,
        // };

        const fileName = getFileNameWithoutExtension(videoSrc);
        // const baseUrl = destination ? destination.url : '';

        const output = 'mp4'

        let defaultOutput = {
            output, // default output value,
            video_codec: 'libx264',
            height: 2160,
            quality: 20,
            // destination: {
            //     ...destination,
            //     url: `${baseUrl}${fileName}${getFileExtension(output)}`
            // }
        };

        // because added extra layer of fortam selector tabs, set as empty
        defaultOutput = {}

        // only add destination it it's set
        if(destination){
            const { baseUrl } = destination ? parseDestinationUrl(destination?.url) : ''

            defaultOutput = {
                ...defaultOutput,
                destination: {
                    ...destination,
                    url: `${baseUrl}${fileName}${getFileExtension(output)}`
                }
            }
        }

        const updatedFormats = [...formats, defaultOutput];
        onFormatsUpdate(updatedFormats);
    };

    const handleFormatDelete = (index) => {
        console.log({formats})

        // Create a new list excluding the format at the specified index
        const updatedFormats = formats.filter((_, i) => i !== index);

        // Update the formats list
        onFormatsUpdate(updatedFormats);

        console.log({updatedFormats})
    };

    const handleFormatDuplicate = (index) => {
        const formatToDuplicate = formats[index];
        const duplicatedFormat = { ...formatToDuplicate };
        const updatedFormats = [
            ...formats.slice(0, index + 1),
            duplicatedFormat,
            ...formats.slice(index + 1)
        ];
        console.log("Duplicate: ", updatedFormats)
        onFormatsUpdate(updatedFormats);
    };

    return (
        <div className={styles.outputSettingsContainer}>
            {formats.map((format, index) => (
                <OutputCard 
                    key={index} 
                    index={index}
                    format={format} 
                    initialFormat={initialFormats[index]}
                    destination={destination}
                    videoSrc={videoSrc}
                    onFormatUpdate={(newFormat) => handleFormatUpdate(index, newFormat)} 
                    // don't pass delete function to make sure if there is only one format it can't be deleted
                    onFormatDelete={formats.length > 1 ? () => handleFormatDelete(index) : undefined}
                    onFormatDuplicate={() => handleFormatDuplicate(index)}
                />
            ))}
            <Button
                icon={<Add label="Add Output" />}
                onClick={handleAddOutput}
                variant="link"
            >
                Add Output
            </Button>            
        </div>
    );
};

OutputSettings.propTypes = {
    formats: PropTypes.array.isRequired,
    initialFormats: PropTypes.array.isRequired,
    onFormatsUpdate: PropTypes.func.isRequired
};





// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { Button } from 'components/Button'
// import { OutputCard } from './OutputCard';
// import { Add } from 'icons';
// import styles from '../index.module.css';

// export const OutputSettings = ({ formats, onFormatsUpdate }) => {
//     const [localFormats, setLocalFormats] = useState(formats);

//     useEffect(() => {
//         setLocalFormats(formats);
//     }, [formats]);

//     const handleFormatUpdate = (index, newFormat) => {
//         const updatedFormats = [...localFormats];
//         updatedFormats[index] = newFormat;
//         setLocalFormats(updatedFormats);
//         onFormatsUpdate(updatedFormats);
//     };

//     return (
//         <div className={styles.outputSettingsContainer}>
//             {localFormats.map((format, index) => (
//                 <OutputCard 
//                     key={index} 
//                     index={index}
//                     format={format} 
//                     onFormatUpdate={(newFormat) => handleFormatUpdate(index, newFormat)} 
//                 />
//             ))}
//             <Button
//                 icon={<Add label="Add Output" />}
//                 onClick={() => {}}
//                 variant="link"
//             >
//                 Add Output
//             </Button>            
//         </div>
//     );
// };

// OutputSettings.propTypes = {
//     formats: PropTypes.array.isRequired,
//     onFormatsUpdate: PropTypes.func.isRequired
// };

