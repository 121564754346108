import PropTypes from "prop-types";
import React from "react";

export const Pending = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M7.9999 2.60002C5.01727 2.60002 2.5999 5.0174 2.5999 8.00002C2.5999 10.9827 5.01727 13.4 7.9999 13.4C8.53705 13.4 9.05485 13.3218 9.54307 13.1765C9.86068 13.082 10.1948 13.2629 10.2893 13.5805C10.3838 13.8981 10.2029 14.2322 9.88531 14.3267C9.28719 14.5047 8.65416 14.6 7.9999 14.6C4.35453 14.6 1.3999 11.6454 1.3999 8.00002C1.3999 4.35465 4.35453 1.40002 7.9999 1.40002C9.61504 1.40002 11.096 1.98081 12.243 2.94432C12.4967 3.15746 12.5296 3.53593 12.3165 3.78966C12.1033 4.04338 11.7248 4.07629 11.4711 3.86314C10.5323 3.07449 9.32236 2.60002 7.9999 2.60002ZM12.9174 4.50699C13.2051 4.34263 13.5716 4.44265 13.736 4.73039C13.9136 5.04129 14.0667 5.36813 14.1926 5.70827C14.3077 6.01903 14.149 6.36421 13.8382 6.47926C13.5275 6.59431 13.1823 6.43565 13.0672 6.12489C12.9644 5.84704 12.8393 5.57988 12.694 5.32558C12.5296 5.03784 12.6297 4.67135 12.9174 4.50699ZM14.5994 8.13638C14.6074 7.8051 14.3453 7.5301 14.014 7.52214C13.6827 7.51418 13.4077 7.77628 13.3998 8.10756C13.3963 8.25367 13.3868 8.40092 13.3713 8.54907C13.3483 8.76742 13.3127 8.98111 13.2653 9.18953C13.1918 9.51266 13.3942 9.83416 13.7174 9.90763C14.0405 9.9811 14.362 9.77872 14.4355 9.4556C14.4934 9.2009 14.5368 8.94026 14.5647 8.67451C14.5836 8.49421 14.5951 8.31473 14.5994 8.13638ZM13.1694 11.0816C13.4379 11.2758 13.4982 11.6509 13.304 11.9194C12.993 12.3496 12.6321 12.7392 12.2308 13.0808C11.9785 13.2956 11.5998 13.2652 11.385 13.0129C11.1702 12.7606 11.2006 12.3819 11.453 12.1671C11.7817 11.8872 12.0772 11.5682 12.3316 11.2163C12.5258 10.9477 12.9009 10.8875 13.1694 11.0816ZM7.72178 5.00002C7.72178 4.61343 8.03518 4.30002 8.42178 4.30002C8.80838 4.30002 9.12178 4.61343 9.12178 5.00002V8.41445C9.12178 8.69028 8.95979 8.9404 8.70808 9.05322L6.28609 10.1388C5.9333 10.2969 5.51913 10.1391 5.36101 9.78633C5.20289 9.43355 5.36069 9.01938 5.71347 8.86125L7.72178 7.9611V5.00002Z" fill="currentColor"/>
    </svg>    
  );
};

Pending.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Pending.defaultProps = {
  color: 'currentColor', // Or any default color
};