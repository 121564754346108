export function durationStringToSeconds(duration) {
  if (duration.length === 8) {
    const [hours, minutes, seconds] = duration.split(":");
    return (parseInt(hours) * 3600) + (parseInt(minutes) * 60) + parseInt(seconds);
  }
  return 0;
}

export function secondsToDuration(seconds) {
  if (typeof seconds === 'number' && !Number.isNaN(seconds) && isFinite(seconds)) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    
    const hoursStr = (hours < 10 ? "0" : "") + hours;
    const minutesStr = (minutes < 10 ? "0" : "") + minutes;
    const secondsStr = (remainingSeconds < 10 ? "0" : "") + remainingSeconds;
    
    return hoursStr + ":" + minutesStr + ":" + secondsStr;
  }
  return '-';
}
