import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import style from './style.module.css';

const coordinatesOutsideScreen = {
  top: -9999,
  left: -9999,
};


function getCoords(cellElement, dropdownWidth) {
  const cellElementRect = cellElement.getBoundingClientRect();
  const rightEdgeDistance = window.innerWidth - cellElementRect.left - dropdownWidth;
  const top = `${ cellElementRect.y + cellElementRect.height - 10 + window.scrollY }px`;
  const left = `${ cellElementRect.x }px`;
  return {
    top,
    left: (rightEdgeDistance > 0 || !cellElement.previousElementSibling) ? left : getCoords(cellElement.previousElementSibling, dropdownWidth).left,
  };
}

function isElementBottomEdgeOverflowing(element) {
  const rect = element.getBoundingClientRect();
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
  return rect.bottom > viewportHeight;
}

function CellDetails({
  children,
  open,
  dropdownButtonRef,
  hideMenu,
  dropdownWidth,
  maxHeight = 360,
  }) {
  const [menuPosition, setMenuPosition] = useState(coordinatesOutsideScreen);
  const menuRef = useRef(null);
  
  useEffect(() => {
    if ( dropdownButtonRef?.current ) {
      const cell = dropdownButtonRef?.current.closest('td');
      const coords = getCoords(cell, dropdownWidth);
      setMenuPosition( coords );
      
      setTimeout(() => {
        const isOverflowing = menuRef?.current && isElementBottomEdgeOverflowing(menuRef?.current)

        if (isOverflowing) {
          menuRef.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          } );
        }
      }, 200);
    }
  }, [dropdownButtonRef, dropdownWidth, open]);
  
  const handleClickOutside = useCallback((event) => {
    if (
      !menuRef?.current?.contains(event.target) &&
      !dropdownButtonRef?.current?.contains(event.target)
    ) {
      hideMenu();
      setMenuPosition(coordinatesOutsideScreen);
    }
  }, [dropdownButtonRef, hideMenu]);
  
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);
  
  return (
    <>
      { open && ReactDOM.createPortal((
        <div
          className={style.dropdown}
          ref={menuRef}
          style={{
            position: 'absolute',
            top: menuPosition.top,
            left: menuPosition.left,
            maxHeight,
            '--dropdown-width': `${ dropdownWidth }px`,
          }}
        >
          { children }
        </div>
      ), document.getElementById('menu-portal')) }
    </>
  );
}

CellDetails.propTypes = {
  open: PropTypes.bool.isRequired,
  hideMenu: PropTypes.func.isRequired,
  dropdownWidth: PropTypes.number.isRequired,
};

export default CellDetails;