import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import Table from "components/Table";
import bytesToReadable from "helpers/bytesToReadable";
import style from "./style.module.css";
import Search from "components/Search";
import {
  typesOptions,
  columnIds,
  typeIds,
  typeToNoData,
  typeIdToSubRowProperty,
  typeIdToNameProperty,
  typesWithSearch
} from 'pages/StatisticsPage/CdnTab/config';
import {Select} from "components/Select";
import Controls from "components/Controls";
import MoneyRounded from "components/Table/Cells/MoneyRounded";
import VerticalGapsLayout from "components/VerticalGapsLayout";
import Pagination from "components/Table/Pagination";
import formatNumber from "helpers/formatNumber";
import getTableValueFallback from "helpers/getTableValueFallback";
import TableBodyRow from "components/Table/Body/Row";
import ReactTableLikeRow from "components/Table/Body/ReactTableLikeRow";
import globalFilterFn from "components/Table/columnFilters/exactCaseInsensitiveFilter";
import NameHeaderCell from "pages/StatisticsPage/CdnTab/UsageBy/NameHeaderCell";
import NameBodyCell from "pages/StatisticsPage/CdnTab/UsageBy/NameBodyCell";

function UsageBy({
  stats,
}) {
  const [expanded, setExpanded] = useState({});
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([{
    id: columnIds.bandwidth,
    desc: true,
  }]);
  const [selectedType, setSelectedType] = useState(typeIds.regions);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowPinning, setRowPinning] = useState({
    top: [],
    bottom: [],
  });

  function handleCheckType(e) {
    setSelectedType(e?.target?.value);
    setExpanded({});
  }
  
  const selectedData = useMemo(() => stats.data[selectedType] || [], [selectedType, stats.data]);
  
  const nameAccessorFn = useCallback((info) => {
    const possibleProperty = typeIdToNameProperty[selectedType].find((property) => info.hasOwnProperty(property))
    return info[possibleProperty];
  }, [selectedType]);

  const columns = useMemo(() => [
    {
      id: columnIds.name,
      accessorFn: nameAccessorFn,
      header: () => <NameHeaderCell selectedType={selectedType}/>,
      cell: (info) => <NameBodyCell
        info={info}
        selectedType={selectedType}
      />,
    },
    {
      id: columnIds.bandwidth,
      accessorKey: 'bandwidth',
      header: () => <span>Bandwidth</span>,
      cell: (info) => <span>{ bytesToReadable(info.getValue()) }</span>,
      meta: {
        className: style.bandwidthColumn,
      },
    },
    {
      id: columnIds.requests,
      accessorKey: 'requests',
      header: () => <span>Requests</span>,
      cell: (info) => <span>{ getTableValueFallback(info.getValue(), formatNumber)}</span>,
      meta: {
        className: style.requestsColumn,
      },
    },
    {
      id: columnIds.amount,
      accessorKey: 'amount',
      header: () => <span>Cost</span>,
      cell: (info) => <div className={style.costWrapper}><MoneyRounded value={info.getValue()} semiBold /></div>,
      meta: {
        className: style.costColumn,
        align: 'right',
      },
    }
  ], [nameAccessorFn, selectedType]);
  
  const currentTypeData = useMemo(() => {
    let all = {
      bandwidth: 0,
      amount: 0,
      requests: 0,
      name: 'All',
    }
    for (let record of selectedData) {
      for (let propertyName of Object.keys(all)) {
        if (propertyName !== 'name') {
          all[propertyName] += record[propertyName] || 0;
        }
      }
    }
    return new ReactTableLikeRow(all, columns);
  }, [selectedData, columns]);
  
  const getSubRows = useCallback((row) => {
    const property = typeIdToSubRowProperty[selectedType];
    return row[property] || [];
  }, [selectedType]);

  const table = useReactTable({
    data: selectedData,
    columns,
    defaultColumn: {
      cell: (info) => <span>{ info.getValue() || '—' }</span>,
    },
    getCoreRowModel: getCoreRowModel(),
    /* Sorting */
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    /* Filtering */
    globalFilterFn,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    maxLeafRowFilterDepth: 0, /* Do not search sub-rows. Otherwise row couldn't be expanded (most of the time, because sub-rows will be filtered out) */
    /* Expanded */
    getSubRows,
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: setExpanded,
    paginateExpandedRows: false,
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    /* Row pinning */
    onRowPinningChange: setRowPinning,
    keepPinnedRows: true,
    state: {
      sorting,
      globalFilter,
      expanded,
      pagination,
      rowPinning,
    },
  });

  return (
    <VerticalGapsLayout>
      <Controls
        title="Usage by"
        leftContent={
          <Select
            className={style.customSelect}
            options={typesOptions}
            onChange={handleCheckType}
            value={selectedType}
          />
        }
        rightContent={
          typesWithSearch.includes(selectedType) ? (
            <Search
              className={style.search}
              setGlobalFilter={setGlobalFilter}
              globalFilter={globalFilter}
            />
          ) : null
        }
      />
      <Table
        { ...stats }
        { ...typeToNoData[selectedType] }
        table={table}
        skeletonRowCount={5}
        minWidth={590}
        topRowsComponent={
          <TableBodyRow
            row={currentTypeData}
          />
        }
      />
      <Pagination table={table}/>
    </VerticalGapsLayout>
  );
}


UsageBy.propTypes = {
  stats: PropTypes.object.isRequired,
};

export default UsageBy;