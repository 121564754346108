import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Select} from "components/Select";
import Calendar from "components/Calendar";
import {format} from "date-fns";
import style from 'pages/StatisticsPage/components/DatePicker/style.module.css';
import {Calendar as CalendarIcon} from 'icons';
import classNames from 'classnames';

const dateTemplate = 'MMM dd, yyyy';
const modalType = {
  start: 'start',
  end: 'end',
};

function DatePicker({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    options,
    handleSelect,
    leftContent,
  }) {
  const rootRef = useRef();
  const [openCalendarType, setOpenCalendarType] = useState();
  
  function setStartDateDatepicker(value) {
    if (value) {
      setStartDate(value);
    }
    setOpenCalendarType();
  }
  
  function setEndDateDatepicker(value) {
    if (value) {
      setEndDate(value);
    }
    setOpenCalendarType();
  }
  
  const handleClickOutside = useCallback((event) => {
    if (
      !rootRef?.current?.contains(event.target)
    ) {
      setOpenCalendarType();
    }
  }, [rootRef]);
  
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);
  const selectedOption = options.find(option => option.selected);
  const startKey = `start-${ startDate.getFullYear() }-${ startDate.getMonth() }`;
  const endKey = `end-${ endDate.getFullYear() }-${ endDate.getMonth() }`;

  return (
    <div
      ref={rootRef}
      className={style.datePicker}
    >
      { leftContent }
      <div className={style.rangeWrapper}>
        <div className={style.wrapper}>
          <button
            className={style.rangeBoundary}
            onClick={() => setOpenCalendarType((type) => type === modalType.start ? undefined : modalType.start )}
          >
            {format( startDate, dateTemplate )}
          </button>
          <div className={classNames(style.calendarWrapper, {
            [style.hidden]: openCalendarType !== modalType.start,
          })}>
            <Calendar
              key={startKey}
              defaultMonth={startDate}
              selected={startDate}
              onSelect={setStartDateDatepicker}
              disabled={{
                after: endDate,
              }}
            />
          </div>
        </div>
        <div className={style.separator}></div>
        <div className={style.wrapper}>
          <button
            className={style.rangeBoundary}
            onClick={() => setOpenCalendarType((type) => type === modalType.end ? undefined : modalType.end )}
          >
            {format( endDate, dateTemplate )}
          </button>
          <div className={classNames(style.calendarWrapper, {
            [style.hidden]: openCalendarType !== modalType.end,
          })}>
            <Calendar
              key={endKey}
              defaultMonth={endDate}
              selected={endDate}
              onSelect={setEndDateDatepicker}
              disabled={{
                before: startDate,
                after: new Date(),
              }}
            />
          </div>
        </div>
      </div>
      <Select
        className={style.customSelect}
        icon={<CalendarIcon/>}
        onChange={handleSelect}
        options={options}
        value={selectedOption.value}
      />
    </div>
  );
}

DatePicker.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
  options: PropTypes.array,
  handleSelect: PropTypes.func,
};

export default DatePicker;