export const videoFormatCodeToReadable = {
  'mp4': 'MP4',
  'webm': 'WEBM',
  'advanced_hls': 'HLS',
  'hls': 'HLS',
  'mpeg-dash': 'MPEG-DASH',
  'webm-dash': 'MPEG-DASH',
  'advanced_dash': 'MPEG-DASH',
  'mp3': 'MP3',
  'jpg': 'JPG',
  'png': 'PNG',
  'jpeg': 'JPEG',
  'repack': 'REPACK',
  'gif': 'GIF',
  'hls_audio': 'HLS-AUDIO',
  'thumbnail': 'Thumbnail',
  'thumbnails': 'Thumbnails',
  'flac': 'FLAC',
  'metadata': 'Metadata',
  'speech_to_text': 'Speech-to-Text',
};

export const codecCodeToReadable = {
  'libx264': 'H.264',
  'h264': 'H.264',
  'libx265': 'H.265',
  'h265': 'H.265',
  'hevc': 'HEVC',
  'libvpx': 'VP8',
  'vp8': 'VP8',
  'libvpx-vp9': 'VP9',
  'vp9': 'VP9',
  'libaom-av1': 'AV1',
  'av1': 'AV1',
  'mp3': 'MP3',
  'libvpx-vp8': 'VP8',
  'h264_nvenc': 'H.264 NVENC',
  'h265_nvenc': 'H.265 NVENC',
  'av1_nvenc': 'AV1 NVENC',
  /* Audio codecs */
  'aac': 'AAC',
  'libfdk_aac': 'FDK AAC',
};

