// has error on downloading - http://localhost:3000/my_encodings/e732d0f735261fdd8a09c90da4d4c43d
// no errors completed - http://localhost:3000/my_encodings/be462fdabff6e5fccb777cd4db4d3313


import React, { 
    useState, 
    useEffect, 
    useCallback, 
} from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { useQencodeApi } from 'api/useQencodeApi';
import { useApi } from 'api/useApi'

import { Status } from './Status'
import { StatusLoading } from './StatusLoading'

import { Source } from './Source'
// import { SourceLoading } from './SourceLoading'
import { TableLoading } from './TableLoading'

import { InputParameters } from './InputParameters'
import { InputParametersLoading } from './InputParametersLoading'

import { Payload } from './Payload';

import { Output } from './Output'

import { Warnings } from './Warnings'

import styles from './index.module.css';

export const TranscodingJobPage = () => {

    const { taskToken } = useParams();

    const location = useLocation();
    const projectName = location.state?.projectName;

    // let { state } = useLocation();
    // console.log("state: ", state)

    // console.log("location: ", location)

    const navigate = useNavigate();

    const { getStatus } = useQencodeApi(); 
    const { resubmitTask } = useApi();  

    const [loadingStatusData, setLoadingStatusData] = useState(true);
    const [statusError, setStatusError] = useState(null);
    const [statusData, setStatusData] = useState(null);     

    const [inputFiles, setInputFiles] = useState(null)

    const [queryObject, setQueryObject] = useState(null);  
    
    const [payloadData, setPayloadData] = useState(null);  

    // outputs
    const [outputVideos, setOutputVideos ] = useState([]);  
    const [outputAudios, setOutputAudios ] = useState([]);  
    const [outputTexts, setOutputTexts ] = useState([]);  
    const [outputImages, setOutputImages ] = useState([]);  
    const [outputWarnings, setOutputWarnings ] = useState([]);  

    // handle resubmit
    const [resubmittingTask, setResubmittingTask] = useState(false); 
    const [resubmitError, setResubmitError] = useState(null);    

    const fetchStatusData = useCallback(async (taskToken) => {
        try {
            setLoadingStatusData(true);
            const settings = {
                taskToken,
                extended: 1
            }
            //const { success, data, detail } = await getStatus(settings);
            const { success, data, detail } = await getStatus(settings);
            if (success && data) {
                
                // set source one time on initial load
                if (data?.source_meta) setInputFiles(data.source_meta);

                // set outputs, can be updated when job completes and triggered by status component
                if (data?.videos) setOutputVideos(data.videos);  
                if (data?.audios) setOutputAudios(data.audios);   
                if (data?.texts) setOutputTexts(data.texts);  
                if (data?.images) setOutputImages(data.images);
                if (data?.warnings) setOutputWarnings(data.warnings);

                // set output query on initial load
                if (data?.input_params?.query) setQueryObject(data.input_params.query);

                if (data?.payload) setPayloadData(data.payload);

                setStatusData(data)
                setStatusError(null);
            } else {
                setStatusError(detail || "Failed to get Status data");
                console.error(detail || "Failed to get Status data");
            }
        } catch (error) {
            setStatusError(error.message);
            console.error(error); // Here, replace with more advanced error handling if necessary
        } finally {
            setLoadingStatusData(false); // Ensures loading is set to false regardless of the outcome
        }
    }, [getStatus]);      
   
    useEffect(() => {
        if(taskToken){
            fetchStatusData(taskToken)
        } else {
            console.log("Task token is missing")
        }
    }, [fetchStatusData, taskToken]);    


    // this is called when transcoding job completion detected in Status component
    // we need it as when complete it finally has extra data needed for outputs
    const fetchStatusDataAfterComplete = useCallback(async (taskToken) => {
        try {
            //setLoadingStatusData(true);
            const settings = {
                taskToken,
                extended: 1
            }
            const { success, data, detail } = await getStatus(settings);
            if (success && data) {

                // set input
                if (data?.source_meta) setInputFiles(data.source_meta);  
                
                // set outputs, can be updated when job completes and triggered by status component
                if (data?.videos) setOutputVideos(data.videos);  
                if (data?.audios) setOutputAudios(data.audios);   
                if (data?.texts) setOutputTexts(data.texts);  
                if (data?.images) setOutputImages(data.images);
                if (data?.warnings) setOutputWarnings(data.warnings);

                setStatusData(data)
                setStatusError(null);
            } else {
                setStatusError(detail || "Failed to get Status data");
                console.error(detail || "Failed to get Status data");
            }
        } catch (error) {
            setStatusError(error.message);
            console.error(error); // Here, replace with more advanced error handling if necessary
        } finally {
            //setLoadingStatusData(false); // Ensures loading is set to false regardless of the outcome
        }
    }, [getStatus]);        


    const handleJobComplete = () => {
        fetchStatusDataAfterComplete(taskToken)
    };  

    const handleResubmitJob = async () => {
        console.log("Resubmit job: ", taskToken)

        try {
            setResubmittingTask(true)
            setResubmitError(null);
            const { success, data, detail } = await resubmitTask(taskToken);
            if (success && data) {
                console.log("data: ", data)
                const {token, project_name} = data

                // Reset relevant states before navigating
                setStatusData(null);
                setInputFiles(null);
                setQueryObject(null);
                setPayloadData(null)
                setOutputVideos([]);
                setOutputAudios([]);
                setOutputTexts([]);
                setOutputImages([]);
                setOutputWarnings([]);

                // navigate to new transcoding job and pass project_name as state
                navigate(`/my_encodings/${token}`, { state: { projectName: project_name } });

                // Fetch the new job status
                fetchStatusData(token);
            } else {
                setResubmitError(detail || "Failed to get Status data");
                console.error(detail || "Failed to get Status data");
            }
        } catch (error) {
            setResubmitError(error.message);
            console.error(error); // Here, replace with more advanced error handling if necessary
        } finally {
            setResubmittingTask(false)
        }

    };    

    // const handleResubmitJob = async () => {
    //     console.log("Resubmit job: ", taskToken);

    //     try {
    //         const { success, data, detail } = await resubmitTask(taskToken);
    //         if (success && data) {
    //             console.log("data: ", data);
    //             const { token, project_name } = data;

    //             // Reset relevant states before navigating
    //             setStatusData(null);
    //             setInputFiles(null);
    //             setQueryObject(null);
    //             setOutputVideos([]);
    //             setOutputAudios([]);
    //             setOutputTexts([]);
    //             setOutputImages([]);

    //             // Navigate to new transcoding job and pass project_name as state
    //             navigate(`/my_encodings/${token}`, { state: { projectName: project_name } });
    //         } else {
    //             setStatusError(detail || "Failed to get Status data");
    //             console.error(detail || "Failed to get Status data");
    //         }
    //     } catch (error) {
    //         setStatusError(error.message);
    //         console.error(error);
    //     }
    // };    

    return (
        <div className={styles.container}>
            
            {/* Job Status Component */}
            {
                loadingStatusData
                ? <div className={styles.section}><StatusLoading /></div>
                :
                <div className={styles.section}>
                    <Status 
                        taskToken={taskToken}
                        projectName={projectName}
                        data={statusData} 
                        loading={loadingStatusData} 
                        error={statusError}
                        onComplete={handleJobComplete}
                        onResubmit={handleResubmitJob}
                        resubmittingTask={resubmittingTask}
                        resubmitError={resubmitError}
                    />            
                </div>         
            }

            {/* Input Files Component */}
            {
                loadingStatusData
                ?
                <div className={styles.section}>
                    <div className={styles.sectionTitle}>Input Files</div>
                    <TableLoading />
                </div>
                :
                <div className={styles.section}>
                    <div className={styles.sectionTitle}>Input Files</div>
                    <Source inputFiles={inputFiles} loading={loadingStatusData} error={statusError}/>
                </div>
            }

            {/* Output Files Component */}
            {
                loadingStatusData
                ?
                <div className={styles.section}>
                    <div className={styles.sectionTitle}>Output Files</div>
                    <TableLoading />
                </div>
                :
                <div className={styles.section}>
                    <div className={styles.sectionTitle}>Output Files</div>
                    <Output 
                        videos={outputVideos}
                        audios={outputAudios}
                        texts={outputTexts}
                        images={outputImages}

                        loading={loadingStatusData} 
                        error={statusError}
                    />    
                </div>                
            }

            {
                (outputWarnings && outputWarnings.length > 0) &&
                <div className={styles.section}>
                    <div className={styles.sectionTitle}>Warnings</div>
                    <Warnings warnings={outputWarnings}/>
                </div>
            }

            <div className={styles.section}>
                <div className={styles.sectionTitle}>Input Parameters</div>
                {/* Input Parameters Component */}
                {
                    loadingStatusData
                    ?
                    <InputParametersLoading />
                    :
                    <InputParameters queryObject={queryObject} loading={loadingStatusData} error={statusError}/>
                }
            </div>

            {
                payloadData &&
                <div className={styles.section}>
                    <div className={styles.sectionTitle}>Payload</div>
                    <Payload payloadData={payloadData}/>
                </div>                
            }
        </div>
    );
};

