import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import Table from "components/Table";
import bytesToReadable from "helpers/bytesToReadable";
import Region from "components/Region";
import Card from "pages/StatisticsPage/components/Card";
import MoneyRounded from "components/Table/Cells/MoneyRounded";
import {ReactComponent as RegionImage} from "assets/images/Region.svg";
import style from "pages/StatisticsPage/StorageTab/UsageBy/style.module.css";
import formatNumber from "helpers/formatNumber";
import getTableValueFallback from "helpers/getTableValueFallback";

const columnIds = {
  region: 'region',
  bandwidth: 'bandwidth',
  requests: 'requests',
  cost: 'cost',
};

function TopDeliveryRegions({
   stats,
   title = 'Top Content Delivery Regions',
 }) {
  const [sorting, setSorting] = useState([{
    id: columnIds.bandwidth,
    desc: true,
  }]);

  const columns = useMemo(() => [
    {
      id: columnIds.region,
      header: () => <span>Region</span>,
      accessorKey: 'region',
      cell: (info) => <Region regionCode={info.getValue()}/>,
    },
    {
      id: columnIds.bandwidth,
      header: () => <span>Bandwidth</span>,
      accessorKey: 'bandwidth',
      cell: (info) => <span>{ bytesToReadable( parseInt(info.getValue()) ) }</span>,
      meta: {
        className: style.bandwidthColumn,
      },
    },
    {
      id: columnIds.requests,
      accessorKey: 'requests',
      header: () => <span>Requests</span>,
      cell: (info) => <span>{ getTableValueFallback(info.getValue(), formatNumber)}</span>,
      meta: {
        className: style.requestsColumn,
      },
    },
    {
      id: columnIds.cost,
      accessorKey: 'requests_cost',
      header: () => <span>Cost</span>,
      cell: (info) => <div className={style.costWrapper}><MoneyRounded value={info.getValue()} semiBold/></div> ,
      meta: {
        className: style.costColumn,
        align: 'right',
      },
    }
  ], []);

  const table = useReactTable({
    data: stats.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    /* Sorting */
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      pagination: {
        pageIndex: 0,
        pageSize: 5,
      },
    },
  });
  
  return (
    <Card title={title}>
      <Table
        { ...stats }
        table={table}
        lastLineInvisible
        noDataImage={<RegionImage/>}
        noDataDescription="No data found"
        skeletonRowCount={5}
      />
    </Card>
  )
}

TopDeliveryRegions.propTypes = {
  stats: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default TopDeliveryRegions;