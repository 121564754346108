import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Modal} from "components/Modal";
import {Button} from "components/Button";
import {Delete} from "icons";
import { ReactComponent as PlayerWarning } from "assets/images/PlayerWarning.svg";
import {deletePlayer} from "api/qencode-account-api";
import {useAuth} from "context/AuthContext";
import style from "./style.module.css";
import {VideoPlayerType} from "pages/Player/VideoPlayerListPage/config";
import ErrorMessage from "components/ErrorMessage";

function DeleteModal({
     open,
     handleCancel,
     onSuccessfulFinish,
     selected,
   }) {
  const { getToken } = useAuth();
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  async function handleConfirmDeletion() {
    const accessToken = await getToken();
    setSubmitting(true);
    const {
      success,
      error,
    } = await deletePlayer({
      accessToken,
      id: selected?.id,
    });
    if (success) {
      handleCancel?.();
      onSuccessfulFinish?.();
    } else {
      setErrorMessage(error);
    }
    setSubmitting(false);
  }
  
  return (
    <Modal
      isOpen={open}
      variant="popUp"
      onClose={handleCancel}
      header={
        <>
          <PlayerWarning/>
          <h3>Delete this Video Player?</h3>
          <p>
            Are you sure you want to delete video player <span className={ style.projectName } >{ selected?.name ?? '' }</span>? This will erase all statistics used in this video player.
          </p>
          { errorMessage && <ErrorMessage>{ errorMessage }</ErrorMessage> }
        </>
      }
      footer={
        <>
          <Button
            type="secondary"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            icon={ <Delete/> }
            type='danger'
            onClick={handleConfirmDeletion}
            state={ submitting ? 'disabled' : 'default' }
          >
            Delete
          </Button>
        </>
      }
    >
    
    </Modal>
  )
}

DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  onSuccessfulFinish: PropTypes.func,
  selected: PropTypes.shape( VideoPlayerType ),
};

export default DeleteModal;