import React, {useCallback, useMemo, useState} from 'react';
import useDataLoadingStatus from "hooks/useDataLoadingStatus";
import {getBucketList} from "api/qencode-account-api";
import {columnIds, regionCodeToName} from "pages/MediaStorage/BucketsPage/config";
import MoreActions from "pages/MediaStorage/BucketsPage/MoreActions";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import DateFormatted from "components/DateFormatted";
import {Link} from "react-router-dom";
import CreateBucketModal from "./CreateBucketModal";
import {modals} from "pages/MediaStorage/BucketsPage/config";
import pageStyle from "./style.module.css";
import Search from "components/Search";
import CreateBucketButton from "pages/MediaStorage/BucketsPage/CreateBucketButton";
import BucketSettingsModal from "pages/MediaStorage/BucketSettingsModal";
import DeleteBucketModal from "pages/MediaStorage/DeleteBucketModal";
import useMediaStorageTokens from "components/MediaStorageEnabledStatus/useMediaStorageTokens";
import CalculateSize from "pages/MediaStorage/BucketsPage/CalculateSize";
import CalculateItems from "pages/MediaStorage/BucketsPage/CalculateItems";
import MediaStorageEnabledStatus from "components/MediaStorageEnabledStatus";
import NoData from './NoData';
import useSkipper from "hooks/useSkipper";
import utcStringToDate from "helpers/utcStringToDate";
import getGlobalFilterWithValueMap from 'components/Table/columnFilters/getGlobalFilterWithValueMap';
import TableWrapper from "components/TableWrapper";

const globalFilterFn = getGlobalFilterWithValueMap({
  [columnIds.region]: regionCodeToName,
});

function BucketsPage() {
  const loadingStatus = useDataLoadingStatus( getBucketList );
  const {
    data,
    setData,
    refresh,
  } = loadingStatus;
  const [sorting, setSorting] = useState([{
    id: columnIds.dateCreated,
    desc: true,
  }]);
  const [openedModal, setOpenedModal] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [globalFilter, setGlobalFilter] = useState('');
  const [selected, setSelected] = useState();
  const mediaStorageTokens = useMediaStorageTokens();

  const columns = useMemo(() => [
    {
      id: columnIds.name,
      header: () => <span>Bucket Name</span>,
      accessorKey: 'name',
      cell: (info) => {
        const params = new URLSearchParams();
        params.set('region', encodeURIComponent(info.row?.original?.region));
        params.set('name', encodeURIComponent(info.getValue()));
        
        return (
          <Link to={ `/storage/list/bucket?${ params }` } className="linkEllipsis">
            { info.getValue() }
          </Link>
        )
      },
    },
    {
      id: columnIds.region,
      header: () => <span>Region</span>,
      accessorKey: 'region',
      cell: (info) => {
        const regionCode = info.getValue();
        return (
          <>{ regionCodeToName[regionCode] || regionCode }</>
        )
      },
    },
    {
      id: columnIds.size,
      header: () => <span>Size</span>,
      accessorKey: 'size',
      enableSorting: true,
      cell: (info) => <CalculateSize info={info} columnId={columnIds.size} mediaStorageData={mediaStorageTokens.tokens}/>,
    },
    {
      id: columnIds.items,
      header: () => <span>Items</span>,
      accessorKey: 'items',
      enableSorting: true,
      cell: (info) => <CalculateItems info={info} columnId={columnIds.items} mediaStorageData={mediaStorageTokens.tokens}/>,
    },
    {
      id: columnIds.dateCreated,
      header: () => <span>Date Created</span>,
      accessorKey: 'created',
      sortingFn: 'datetime',
      cell: (info) => {
        return (
          <DateFormatted date={ info.getValue() }/>
        )
      },
    },
    {
      id: columnIds.moreActions,
      enableSorting: false,
      cell: (info) => (
        <MoreActions
          handleOpenModal={openModal}
          bucket={info.row?.original}
          setSelected={setSelected}
        />
        ),
    },
  ], [mediaStorageTokens]);
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();

  const table = useReactTable({
    data,
    columns,
    autoResetPageIndex,
    meta: {
      updateData: (rowIndex, columnId, value) => {
        // Skip page index reset until after next rerender
        skipAutoResetPageIndex()
        setData(old =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              }
            }
            return row
          })
        );
      },
    },
    getCoreRowModel: getCoreRowModel(),
    /* Sorting */
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    /*Filtering*/
    globalFilterFn,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination,
      globalFilter,
    },
  });
  
  function openModal(modal) {
    setOpenedModal(modal);
  }
  
  const handleAfterCreate = useCallback((newRow) => {
    setData(old =>
      [...old, {
        ...newRow,
        created: utcStringToDate(newRow.created),
      }]
    );
  }, [setData]);
  
  const handleAfterDelete = useCallback((oldRow) => {
    setData(old =>
      old.filter((row) => row.name !== oldRow.name),
    );
  }, [setData]);

  return (
    <TableWrapper>
      <div className={pageStyle.toolbar}>
        <Search setGlobalFilter={setGlobalFilter} globalFilter={globalFilter}/>
        <CreateBucketButton openModal={openModal}/>
      </div>
      <Table
        {...loadingStatus}
        table={table}
        getEmptyComponent={() => <NoData openModal={openModal}/>}
        columnIdToClassName={{
          [columnIds.region]: pageStyle.regionColumn,
          [columnIds.size]: pageStyle.sizeColumn,
          [columnIds.items]: pageStyle.itemsColumn,
          [columnIds.dateCreated]: pageStyle.dateCreatedColumn,
          [columnIds.moreActions]: pageStyle.moreActionsColumn,
        }}
        alternativeContent={(
           mediaStorageTokens.enableButtonVisible ? <div className={pageStyle.mediaStorageEnableWrapper}>
              <MediaStorageEnabledStatus mediaStorageTokens={mediaStorageTokens} />
            </div> : null
        )}
      />
      { mediaStorageTokens.enableButtonVisible ? undefined : <Pagination table={table}/> }
      <CreateBucketModal
        open={modals.create === openedModal}
        handleClose={() => setOpenedModal()}
        onSuccessfulFinish={handleAfterCreate}
      />
      <BucketSettingsModal
        open={modals.edit === openedModal}
        handleClose={() => {
          setOpenedModal();
        }}
        refreshTable={refresh}
        bucket={selected}
        key={ `${selected?.region}-${selected?.name}` }
        goToDeleteModal={() => setOpenedModal(modals.delete)}
      />
      <DeleteBucketModal
        open={modals.delete === openedModal}
        handleClose={() => setOpenedModal()}
        onSuccessfulFinish={handleAfterDelete}
        bucket={selected}
      />
    </TableWrapper>
  )
}

BucketsPage.propTypes = {};

export default BucketsPage;