import PropTypes from "prop-types";
import React from "react";

export const Statistics = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M2.75 6C2.75 4.20507 4.20507 2.75 6 2.75H14C15.7949 2.75 17.25 4.20507 17.25 6V14C17.25 15.7949 15.7949 17.25 14 17.25H6C4.20507 17.25 2.75 15.7949 2.75 14V6ZM6 1.25C3.37665 1.25 1.25 3.37665 1.25 6V14C1.25 16.6234 3.37665 18.75 6 18.75H14C16.6234 18.75 18.75 16.6234 18.75 14V6C18.75 3.37665 16.6234 1.25 14 1.25H6ZM10.6429 13.4286H9.35718C9.08104 13.4286 8.85718 13.2047 8.85718 12.9286V7.7143C8.85718 7.08312 9.36885 6.57144 10 6.57144C10.6312 6.57144 11.1429 7.08312 11.1429 7.7143V12.9286C11.1429 13.2047 10.919 13.4286 10.6429 13.4286ZM7.21418 13.4286H5.92847C5.65232 13.4286 5.42847 13.2047 5.42847 12.9286V11.1429C5.42847 10.5117 5.94014 10 6.57132 10C7.20251 10 7.71418 10.5117 7.71418 11.1429V12.9286C7.71418 13.2047 7.49032 13.4286 7.21418 13.4286ZM12.7856 13.4286H14.0714C14.3475 13.4286 14.5714 13.2047 14.5714 12.9286V8.85715C14.5714 8.22597 14.0597 7.71429 13.4285 7.71429C12.7973 7.71429 12.2856 8.22597 12.2856 8.85715V12.9286C12.2856 13.2047 12.5095 13.4286 12.7856 13.4286Z" fill="currentColor"/>
    </svg>    
  );
};

Statistics.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Statistics.defaultProps = {
  color: 'currentColor', // Or any default color
};