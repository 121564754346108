import PropTypes from "prop-types";
import React from "react";

export const Inactive = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path d="M8 13.65C4.87929 13.65 2.35 11.1207 2.35 8C2.35 4.87929 4.87929 2.35 8 2.35C11.1207 2.35 13.65 4.87929 13.65 8C13.65 11.1207 11.1207 13.65 8 13.65Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.4272 6.42111C10.6615 6.1868 10.6615 5.8069 10.4272 5.57259C10.1928 5.33827 9.81294 5.33827 9.57863 5.57259L7.99969 7.15153L6.42075 5.57259C6.18643 5.33827 5.80653 5.33827 5.57222 5.57259C5.33791 5.8069 5.33791 6.1868 5.57222 6.42111L7.15116 8.00006L5.57222 9.579C5.33791 9.81331 5.33791 10.1932 5.57222 10.4275C5.80653 10.6618 6.18643 10.6618 6.42075 10.4275L7.99969 8.84858L9.57863 10.4275C9.81294 10.6618 10.1928 10.6618 10.4272 10.4275C10.6615 10.1932 10.6615 9.81331 10.4272 9.579L8.84822 8.00006L10.4272 6.42111Z" fill="currentColor"/>
    </svg>    
  );
};

Inactive.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Inactive.defaultProps = {
  color: 'currentColor', // Or any default color
};