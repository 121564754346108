import PropTypes from "prop-types";
import React from "react";

export const UploadFile = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
    >
      <path d="M7.07261 13.7103H5C2.92893 13.7103 1.25 12.1191 1.25 10.1562C1.25 8.32583 2.70996 6.81862 4.58644 6.62352C5.22929 0.29216 14.7707 0.29216 15.4136 6.62352C17.29 6.81862 18.75 8.32583 18.75 10.1562C18.75 12.1191 17.0711 13.7103 15 13.7103H13.0882" stroke="currentColor" strokeWidth="1.2" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M14.1171 14.0072C14.1171 16.2808 12.2736 18.1239 9.99948 18.1239C7.72537 18.1239 5.88184 16.2808 5.88184 14.0072C5.88184 11.7337 7.72537 9.89062 9.99948 9.89062C12.2736 9.89062 14.1171 11.7337 14.1171 14.0072ZM10.3652 16.0672C10.536 16.0672 10.6745 15.9398 10.6745 15.7826V13.9325H11.4836C11.5389 13.9325 11.5924 13.9143 11.6344 13.8813C11.7317 13.8046 11.7431 13.6699 11.6599 13.5803L10.2379 12.0502C10.2276 12.0391 10.2164 12.0288 10.2043 12.0193C10.0748 11.9169 9.87954 11.9305 9.76825 12.0497L8.34017 13.58C8.30404 13.6187 8.28418 13.668 8.28418 13.719C8.28418 13.8369 8.38802 13.9325 8.51612 13.9325H9.34118V15.7826C9.34118 15.9398 9.47964 16.0672 9.65043 16.0672H10.3652Z" fill="currentColor"/>
    </svg>    
  );
};

UploadFile.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

UploadFile.defaultProps = {
  color: 'currentColor', // Or any default color
};