import React, { useState, useEffect } from 'react';

import { useAWS } from 'api/useAWS';

import { Modal } from 'components/Modal';

import { Button } from 'components/Button'
import { InputField } from 'components/InputField'

import { validateFields } from 'helpers/validation';

export const NewFolderModal = ({ currentBucket, configData, locationName, initialItems, open, handleCancel, onSuccessfulFinish }) => {

    // console.log("itemToRename: ", itemToRename)

    const {
        createNewFolderInQencodeBucket
    } = useAWS();

    const [currentFolders, setCurrentFolders] = useState([]);

    const [validationErrors, setValidationErrors] = useState({});

    const [credentialsNewFolder, setCredentialsNewFolder] = useState({
        folder_name: '',
        folder_location: ''
    });        
    const [isSubmitting, setIsSubmitting] = useState(false);    
    const [newFolderError, setNewFolderError] = useState(null);
  
    // when modal opens set initial states
    useEffect(() => {
        if(open && initialItems){

            // filter out files as I only need to check vs folders
            const folders = initialItems.filter(item => item.type === 'folder');
            setCurrentFolders(folders)            

            setCredentialsNewFolder(currentCredentials => ({
                ...currentCredentials,
                folder_location: locationName || ''
            }));            

        }
    }, [open, locationName, initialItems])    


    const closeNewFolderModal = () => {
        // reset error
        if (newFolderError) {
            setNewFolderError(null);
        }       

        handleCancel()
    };      

    const handleInputChangeNewFolder = (e) => {
        if (newFolderError) {
            setNewFolderError(null);
        }
    
        const { name, value } = e.target;
        // Check if the value is an empty string and set it to null if true
        const newValue = value === '' ? '' : value;
    
        const newCredentials = { ...credentialsNewFolder, [name]: newValue };
        setCredentialsNewFolder(newCredentials);
    
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: null
        }));
    };     

    const handleCreateNewFolder = async () => {
        if (newFolderError) {
            setNewFolderError(null);
        }

        // Check if this foldername already exists in currentFolders
        const existingFolder = currentFolders.find(folder => folder.displayName === credentialsNewFolder.folder_name.trim());
        if (existingFolder) {
            setNewFolderError('Folder with the same name already exists in this directory');
            return; // Exit early if folder exists
        }        

        const fieldsToValidate = ['folder_name']; 
        const { isValid, errors } = validateFields(credentialsNewFolder, fieldsToValidate);
        setValidationErrors(errors); // Update the validation errors state
      
        if (!isValid) {
          console.log("Validation errors", errors);
          return; // Stop the submission if validation fails
        } 

        setIsSubmitting(true); // Disable the button
    
        try {
            const currentLocation = credentialsNewFolder.folder_location;
            const folderName = credentialsNewFolder.folder_name;
            const bucketName = currentBucket.name;
    
            // Prepare the config for S3 operations
            const config = {
                access_id: configData.access_id,
                secret_key: configData.secret_key,
                region: currentBucket.region,
            };

            // Concatenate the current path with the new folder name
            const fullFolderPath = `${currentLocation}${folderName}`
            //console.log("fullFolderPath: ", fullFolderPath)
    
            // Create the new folder in the bucket
            const { success, message, error } = await createNewFolderInQencodeBucket(bucketName, fullFolderPath, config);
    
            if (success) {
                console.log(message);

                onSuccessfulFinish()    
            } else {
                setNewFolderError(error || "Error creating folder")
                console.error(error || "Error creating folder");
            }
        } catch (error) {
            setNewFolderError(error.message || "Unexpected error creating folder");
            console.error(error.message || "Failed to update team member");
        } finally {
            setIsSubmitting(false); // Re-enable the Create button
        }
    };    

    return (
        <Modal isOpen={open} onClose={closeNewFolderModal}
            header={
                <>
                    <h3>Create Folder</h3>                   
                </>
            }
            footer={
                <>
                    <Button
                        variant="modalFooter"
                        onClick={closeNewFolderModal}
                        type="secondary"
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="modalFooter"
                        onClick={handleCreateNewFolder} 
                        state={(isSubmitting || (credentialsNewFolder?.folder_name?.length < 1)) ? "disabled" : undefined}
                    >
                        {isSubmitting ? "Creating..." : "Create"}
                    </Button>                       
                </>
            }
        >
            <div className="form">
                <div className="row">
                    <InputField
                        inputText={credentialsNewFolder.folder_name}
                        onChange={handleInputChangeNewFolder}
                        name="folder_name"
                        placeholder=""
                        label="Name"
                        state={newFolderError || validationErrors.folder_name ? 'error' : 'default'}
                        hint={newFolderError ? newFolderError : validationErrors.folder_name ? validationErrors.folder_name : undefined}                            
                    />                           
                </div>   
                <div className="row">
                    <InputField
                        inputText={credentialsNewFolder.folder_location}
                        name="folder_location"
                        placeholder=""
                        label="Location"
                        state="disabled"
                    />                           
                </div>                                           
            </div>    
        </Modal>           
    );
}
