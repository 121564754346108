import {useEffect, useState, useMemo} from "react";

function getMapReversed(map) {
  return Object.entries(map)
    .reduce((acc, [key, value]) => {
      acc[value] = acc[value] || [];
      if (!acc[value].includes(key)) {
        acc[value].push(key);
      }
      return acc;
    }, {});
}

export default function useLineChartDataset({
                                              data,
                                              longlistToShortlist,
                                              chartDataProperty,
                                              quantityDataProperty,
                                              longlistProperty = 'tariff_name',
                                              dates,
                                            }) {
  const shortlistToLonglist = useMemo(() => getMapReversed(longlistToShortlist), [longlistToShortlist]);
  const [shortlistOptions, setShortlistOptions] = useState([]);
  
  useEffect(() => {
    const uniqueShortlistValues = Array.from(new Set(data.map((item) => {
      const longlistValue = item[longlistProperty];
      if (item[chartDataProperty]) {
        return longlistToShortlist[longlistValue] || longlistValue;
      }
      return undefined;
    }).filter(i => i) ));
    const optionsInitial = uniqueShortlistValues.map(( shortlistValue) => ({
      value: shortlistValue,
      label: shortlistValue,
      checked: true,
    }));
    setShortlistOptions(optionsInitial);
  }, [data, longlistToShortlist, longlistProperty, chartDataProperty])
  
  const longlistCheckedIds = useMemo(() => shortlistOptions.reduce(( acc, item) => {
    if (item.checked) {
      acc.push( shortlistToLonglist[ item.value ] || [] );
    }
    return acc;
  }, []).flat(), [shortlistOptions, shortlistToLonglist]);

  const shortlistCheckedIds = useMemo(() => shortlistOptions.reduce(( acc, item) => {
    if (item.checked) {
      acc.push( [item.value] || [] );
    }
    return acc;
  }, []).flat(), [shortlistOptions]);

  const {
    amount,
    quantity,
    amountSums,
  } = useMemo(() => {
    const amountSums = {};
    const template = {};
    const datesEntries = {
      amount: Object.fromEntries(dates.map((date) => [date, {
        date,
        ...template,
      } ])),
      quantity: Object.fromEntries(dates.map((date) => [date, {
        date,
        ...template,
      } ])),
    }

    if (longlistCheckedIds.length > 0) {
      for (let item of data) {
        const shortlistProperty = longlistToShortlist[item[longlistProperty]] || longlistProperty;
        datesEntries.amount[item.date] = datesEntries.amount[item.date] || {
          date: item.date,
        };
        datesEntries.quantity[item.date] = datesEntries.quantity[item.date] || {
          date: item.date,
        };
        const amountValue = (item[chartDataProperty] || 0);
        if (amountValue) {
          datesEntries.amount[item.date][shortlistProperty] = (datesEntries.amount[item.date][shortlistProperty] || 0) + amountValue;
          amountSums[shortlistProperty] = (amountSums[shortlistProperty] || 0) + amountValue;
        }
        const quantityValue = (item[quantityDataProperty] || 0);
        if (quantityValue) {
          datesEntries.quantity[item.date][shortlistProperty] = (datesEntries.quantity[item.date][shortlistProperty] || 0) + quantityValue;
        }
      }
    }

    return {
      amount: Object.values(datesEntries.amount),
      quantity: datesEntries.quantity,
      amountSums,
    };
  }, [dates, longlistCheckedIds.length, data, longlistToShortlist, longlistProperty, chartDataProperty, quantityDataProperty]);

  return {
    chartData: amount,
    shortlistCheckedIds,
    options: shortlistOptions,
    setOptions: setShortlistOptions,
    quantity,
    amountSums,
  };
}
