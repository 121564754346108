import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

import styles from './UserDropdown.module.css';

import { ListItem } from './ListItem'
import { Settings, Invoices, Logout, ChevronDown } from '../icons'


const UserDropdown = ({ userData, userRole }) => {

  const { logout } = useAuth();
  const navigate = useNavigate();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);


  const handleLogout = () => {
    logout();
    navigate('/');
  };  

  const handleNavigation = (path) => {
    toggleDropdown(); // This will close the dropdown
    navigate(path);   // This will navigate to the given path
  };

  return (
    <div className={styles.userDropdown} ref={dropdownRef}>
      <div className={styles.userButtonContainer} onClick={toggleDropdown}>
        <div className={styles.userButton}>
            {userData?.first_name?.[0]}{userData?.last_name?.[0]}
        </div>
        <ChevronDown />
      </div>
      {/* <div onClick={toggleDropdown} className={styles.userButton}>
        {userData?.first_name?.[0]}{userData?.last_name?.[0]}
      </div> */}
      {isDropdownOpen && (
        <div className={styles.dropdownContent}>
          <div className={styles.userInfo}>
            <div className={styles.userIcon}>
                {userData?.first_name?.[0]}{userData?.last_name?.[0]}
            </div>
            <div className={styles.userSummary}>
                <div className={styles.userName}>{userData?.first_name} {userData?.last_name}</div>
                {userData.email && <div className={styles.userEmail}>{userData.email}</div>}
            </div>
          </div>
          <div className={styles.delimeter}></div>
          <div>
            <ListItem
                icon={<Settings label="Settings" />}
                onClick={() => handleNavigation('/settings')}
                text="Settings"
            />
            {/* <ListItem
                icon={<Invoices label="Invoices" />}
                onClick={() => handleNavigation('/billing')}
                text="Billing"
            />        */}

            {userRole !== 'developer' && (
              <ListItem
                  icon={<Invoices label="Invoices" />}
                  onClick={() => handleNavigation('/billing')}
                  text="Billing"
              />
            )}       

            <div className={styles.delimeter}></div>
            <ListItem
                icon={<Logout label="Logout" />}
                onClick={handleLogout}
                text="Log Out"
            />   
          </div>           
        </div>
      )}
    </div>
  );
};

export default UserDropdown;


// import React, { useState } from 'react';
// import styles from './UserDropdown.module.css';

// const UserDropdown = ({ userData }) => {
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

//   const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

//   return (
//     <div className={styles.userDropdown}>
//       <div onClick={toggleDropdown} className={styles.userButton}></div>
//       {isDropdownOpen && (
//         <div className={styles.dropdownContent}>
//             <div>{userData.email}</div>

//             <a href="/settings">Settings</a>
//             <a href="/billing">Billing</a>
//         </div>
//       )}
//     </div>
//   );
// };

// export default UserDropdown;
