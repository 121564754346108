// has error on downloading - http://localhost:3000/my_encodings/e732d0f735261fdd8a09c90da4d4c43d
// no errors completed - http://localhost:3000/my_encodings/be462fdabff6e5fccb777cd4db4d3313

import React, { 
    // useState, 
    // useRef 
} from 'react';

import styles from './index.module.css';

// 44b4f51b2faa294eca6244d4a3c808c5 - multiple sources aka stitching

export const TableLoading = () => {
    
    return (
        <div className={styles.inputFilesContainer}>

            <div className="tableContainer">
                <table className={`standardTable loadingTable`}>
                    <thead>
                        <tr>
                            <th >FORMAT</th>
                            <th className='cellNoWrap'>VIDEO CODEC</th>
                            <th className='cellNoWrap'>AUDIO CODEC</th>
                            <th>RESOLUTION</th>
                            <th>SIZE</th>
                            <th>DURATION</th>
                            <th>URL</th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr className="tableRow">
                        <td>
                            <div className="cellLoading skeleton-loading"></div>
                        </td>                      
                        <td>
                            <div className="cellLoading skeleton-loading"></div>
                        </td>  
                        <td>
                            <div className="cellLoading skeleton-loading"></div>
                        </td>  
                        <td>
                            <div className="cellLoading skeleton-loading"></div>
                        </td>  
                        <td>
                            <div className="cellLoading skeleton-loading"></div>
                        </td>  
                        <td>
                            <div className="cellLoading skeleton-loading"></div>
                        </td>  

                        <td className={styles.linkCell}>
                            <div className="cellLoading skeleton-loading"></div>
                        </td>          
                      </tr>                
                    </tbody>
                </table>    

            </div>

        </div>
    );
};