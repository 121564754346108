import React from 'react';
import PropTypes from 'prop-types';
import utcStringToDate from "helpers/utcStringToDate";
import { UTCDate } from "@date-fns/utc";
import {format} from 'date-fns';

function DateFormatted({ date, utcString }) {
  let utcDate;
  if (date) {
    utcDate = date;
  }
  else if (typeof utcString === 'string') {
    utcDate = utcStringToDate(utcString);
  }
  else {
    return '-';
  }

  if (!Number.isNaN(utcDate.getTime())) {
    utcDate = new UTCDate(utcDate);
    return (
      <>{ format( utcDate, "MMM d, yyyy, h:mm a") }</>
    );
  }
  return '-';
}

DateFormatted.propTypes = {
  utcString: PropTypes.string,
};

export default DateFormatted;