import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputField } from 'components/InputField';
import { FormCheckbox } from 'components/FormCheckbox';
import { Button } from 'components/Button';
import styles from '../../index.module.css'

export const GenericS3Form = ({ storageCredentials, onChange, onSave, onCancel }) => {
    const { bucket_name, s3_endpoint, access_key_id, secret_access_key, public_permissions } = storageCredentials.generic_s3;

    const [validationErrors, setValidationErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        onChange({
            target: {
                name: name,
                value: value
            }
        })

        const [, key] = name.split('.');

        // Clear validation errors when input changes
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [key]: null,
        }));
    };

    const handleSave = () => {
        const errors = {};

        if (!bucket_name) {
            errors.bucket_name = 'Bucket name is required';
        }
        if (!s3_endpoint) {
            errors.s3_endpoint = 'Endpoint is required';
        }
        if (!access_key_id) {
            errors.access_key_id = 'Access Key ID is required';
        }
        if (!secret_access_key) {
            errors.secret_access_key = 'Secret Access Key is required';
        }

        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
        } else {
            onSave();
        }
    };

    // enable button when all input fields are set
    const isSaveDisabled = !bucket_name || !s3_endpoint || !access_key_id || !secret_access_key;

    return (
        <div className={styles.storageFormContainer}>
            <div className={styles.formRow}>
                <InputField
                    inputText={bucket_name}
                    onChange={handleInputChange}
                    name="generic_s3.bucket_name"
                    placeholder=""
                    label="Bucket Name"
                    state={validationErrors.bucket_name ? 'error' : 'default'}
                    hint={validationErrors.bucket_name || ''}
                />
                <InputField
                    inputText={s3_endpoint}
                    onChange={handleInputChange}
                    name="generic_s3.s3_endpoint"
                    placeholder="domain.com"
                    label="Endpoint"
                    state={validationErrors.s3_endpoint ? 'error' : 'default'}
                    hint={validationErrors.s3_endpoint ? validationErrors.s3_endpoint : undefined}
                />                  
            </div>

            <div className={styles.formRow}>
                <InputField
                    inputText={access_key_id}
                    onChange={handleInputChange}
                    name="generic_s3.access_key_id"
                    placeholder=""
                    label="Access Key ID"
                    autocomplete="off"
                    state={validationErrors.access_key_id ? 'error' : 'default'}
                    hint={validationErrors.access_key_id || ''}
                />
                <InputField
                    inputText={secret_access_key}
                    onChange={handleInputChange}
                    name="generic_s3.secret_access_key"
                    placeholder=""
                    label="Secret Access Key"
                    type="password"
                    autocomplete="new-password"
                    state={validationErrors.secret_access_key ? 'error' : 'default'}
                    hint={validationErrors.secret_access_key || ''}
                />
            </div>

            <div className={styles.formRow}>
                <FormCheckbox
                    name="generic_s3.public_permissions"
                    checked={public_permissions || false}
                    label="Public permissions"
                    onChange={handleInputChange}
                />
            </div>

            <div className={styles.formFooter}>
                <Button 
                    size="sm"
                    type="secondary"
                    onClick={onCancel}
                >
                    Cancel
                </Button>                 
                <Button size="sm" onClick={handleSave} state={isSaveDisabled ? "disabled" : undefined}>
                    Save
                </Button>
            </div>
        </div>
    );
};

GenericS3Form.propTypes = {
    storageCredentials: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

