import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { 
    useLocation, 
    useNavigate, 
    useParams  
} from 'react-router-dom';

// import { validateFields } from './validation';
import { validateFields } from '../../helpers/validation';

import { Button } from '../../components/Button';
import { InputField } from '../../components/InputField'

import { ReactComponent as Logo } from '../../assets/images/QencodeLogo.svg';
import { ReactComponent as Illustration } from '../../assets/images/AccountSetup.svg';

import { ReactComponent as Transcoding } from '../../assets/images/Transcoding.svg';
import { ReactComponent as LiveStreaming } from '../../assets/images/LiveStreaming.svg';
import { ReactComponent as VideoPlayer } from '../../assets/images/VideoPlayer.svg';
import { ReactComponent as MediaStorage } from '../../assets/images/MediaStorage.svg';

import { ArrowRight, CheckLine2 as CheckLine} from '../../icons/'

import { MultivalidationPassword } from '../../components/MultivalidationPassword'

import styles from './index.module.css';

// also possible scenario signup/<token>?org=<organization_name>

// when auth using Google/Github /signup/<token>?sso=<provider>
// sso provider can be "google", "github"

const CompleteSignupPage = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [validationErrors, setValidationErrors] = useState({});

    const navigate = useNavigate();
    const location = useLocation();

    const { token } = useParams(); 

    const queryParams = new URLSearchParams(location.search);
    const organization = queryParams.get('org');
    const secret = queryParams.get('secret'); // when invited to the team

    // only need to check if this paramerter is present
    const sso = queryParams.get('sso');

    const [credentials, setCredentials] = useState({ 
        provider: sso, // used for auth via Google or Github
        first_name: '',
        last_name: '',
        organization: organization || '', // Pre-fill from URL if present
        password: '',
        // password_confirm: '', // this field is optional
        // phone: '', // this field is optional, 
        token: token, // Include the token in the state
        secret: secret
    });

    const [currentStep, setCurrentStep] = useState('completeSignup'); // start with the first step
    //const [currentStep, setCurrentStep] = useState('quickSetup');

    // Define a variable that will hold class names conditionally
    const containerClassNames = [
        styles.container,
        (!organization && currentStep === 'completeSignup') ? styles.withInfoWindow : ''
    ].join(' ');

    const { 
        completeSignup, completeSignupError, setCompleteSignupError, completeInvite,
        //isAuthenticated,
        logout
    } = useAuth();

    // no need for this in this page as user gets here from a link in email
    // const from = location.state?.from?.pathname || '/dashboard';

    // useEffect(() => {
    //   if (isAuthenticated) {
    //     navigate(from); // Redirect if already authenticated
    //   }
    // }, [isAuthenticated, navigate, from]);

    // const from = location.state?.from?.pathname || '/dashboard';

    // useEffect(() => {
    //   if (isAuthenticated) {
    //     navigate(from); // Redirect if already authenticated
    //   }
    // }, [isAuthenticated, navigate, from]); 


    // New useEffect to logout any existing user session
    useEffect(() => {
        logout(); // Ensure no existing session interferes with the signup process
    }, [logout]);    


    const handleCompleteSignup = async (e) => {
        e.preventDefault();
    
        // Start with mandatory fields
        let fieldsToValidate = ['first_name', 'last_name', 'organization', 'password'];
    
        // Check if 'phone' field is present and it is not an empty string, then include it in the validation
        if (credentials.phone && credentials.phone.trim() !== '') {
            fieldsToValidate.push('phone');
        }
    
        // Validate fields
        const { isValid, errors } = validateFields(credentials, fieldsToValidate);
        setValidationErrors(errors); // Update the validation errors state
    
        if (!isValid) {
            console.log("Validation errors", errors);
            return; // Stop the submission if validation fails
        }
    
        setIsSubmitting(true); // Disable the button while submitting
        //const success = await completeSignup(credentials); // Call the completeSignup function from the context

        let success

        if(organization){
            // if there is org in query param
            success = await completeInvite(credentials);
            // success = await completeInvite({
            //     token: credentials.token,
            //     secret: credentials.secret,
            //     first_name: credentials.first_name,
            //     last_name: credentials.last_name,
            //     password: credentials.password
            // });
        } else {
            success = await completeSignup(credentials); // Call the completeSignup function from the context
        }

        setIsSubmitting(false); // Re-enable the button after submission
    
        if (success) {
            // broadcase message that user logged in
            const channel = new BroadcastChannel('Qencode_channel');
            channel.postMessage({ type: 'login' });
            channel.close();

            setCurrentStep('quickSetup'); // Move to the next step on successful signup
            // logged in AuthContext
            //console.log(detail || "Signup completed successfully");
        } else {
            // logged in AuthContext
            //console.log(detail || "Signup complete failed"); // Log the failure or show an error message
        }
    };
    


    // to manage multiline validation for password
    const passwordValidationRules = [
        {
            message: 'Min. 8 characters long',
            test: (value) => value.length >= 8
        },        
        {
            message: 'Include an uppercase letter',
            test: (value) => /[A-Z]/.test(value)
        },
        {
            message: 'Include a lowercase letter',
            test: (value) => /[a-z]/.test(value)
        },
        {
            message: 'Include a number',
            test: (value) => /\d/.test(value)
        }
    ];    

    const [passwordValidations, setPasswordValidations] = useState(passwordValidationRules.map(rule => ({ message: rule.message, isValid: null })));

    const validatePassword = (value) => {
        if (value === '') {
            // Reset all validations to neutral state
            setPasswordValidations(passwordValidationRules.map(rule => ({ message: rule.message, isValid: null })));
            return;
        }

        const newValidations = passwordValidationRules.map(rule => ({
            message: rule.message,
            isValid: rule.test(value)
        }));

        console.log("newValidations: ", newValidations)

        setPasswordValidations(newValidations);
    };       



    const handleInputChange = (e) => {
        // Reset error when the user starts typing
        if (completeSignupError) {
            setCompleteSignupError(null);
        }
    
        const { name, value } = e.target;
    
        // Only update credentials for 'phone' if it is not empty
        if (name === 'phone' && value.trim() === '') {
            // Remove 'phone' key from credentials if it exists
            const { [name]: removed, ...rest } = credentials;
            setCredentials(rest);
        } else {
            // Update or add the value for other fields or if 'phone' has a non-empty value
            setCredentials({ ...credentials, [name]: value });
        }
    
        // Reset the specific field's error when the user starts typing
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: null
        }));

        // run validation for MultiValidation block
        // when typing password
        if(name === 'password'){
            validatePassword(value);
        }        
    };
    

    useEffect(() => {
        // This function runs when the component mounts
        setCompleteSignupError(null); // Clear the login error state
        // Optionally, if you want to clear any signup errors when navigating to the login page:
        // setSignupError(null);
      
        // This function runs when the component unmounts
        return () => {
            setCompleteSignupError(null); // Clear the login error state again
          // Optionally, clear the signup errors again:
          // setSignupError(null);
        };
    }, [setCompleteSignupError, /* setSignupError */]);

    const handleSkipQuickSetup = () => {
        navigate('/dashboard')
    }
    

    return (
        // <div className={styles.container}>
        <div className={containerClassNames}>
            <Logo className={styles.logo} />

            {/* Conditional rendering based on the step */}
            {currentStep === 'completeSignup' && (
                <>
                    <div className={styles.authWindow}>

                        <div className={styles.title}>Complete account setup</div>

                        <div className={styles.info}>Finish your setup to get started with your account.</div>

                        <div className={styles.form}>
                            <div className={styles.row}>
                                <InputField
                                    inputText={credentials.first_name}
                                    onChange={handleInputChange}
                                    name="first_name"
                                    placeholder=""
                                    label="First name"
                                    size="lg"
                                    // error={validationErrors.first_name}
                                    state={validationErrors.first_name ? 'error' : 'default'}
                                    hint={validationErrors.first_name ? validationErrors.first_name : undefined}
                                />   
                                <InputField
                                    inputText={credentials.last_name}
                                    onChange={handleInputChange}
                                    name="last_name"
                                    placeholder=""
                                    label="Last name"
                                    size="lg"
                                    state={validationErrors.last_name ? 'error' : 'default'}
                                    hint={validationErrors.last_name ? validationErrors.last_name : undefined}
                                />    
                            </div>

                            <div className={styles.row}>
                                <InputField
                                    inputText={credentials.organization}
                                    onChange={handleInputChange}
                                    name="organization"
                                    placeholder=""
                                    label="Organization"
                                    size="lg"
                                    // state={organization ? "disabled" : undefined}
                                    state={organization ? "disabled" : validationErrors.organization ? 'error' : undefined}
                                    hint={validationErrors.organization ? validationErrors.organization : undefined}
                                />     
                            </div>

                            <div className={styles.row}>
                                <InputField
                                    inputText={credentials.phone}
                                    onChange={handleInputChange}
                                    name="phone"
                                    placeholder=""
                                    label="Phone (optional)"
                                    type="tel"
                                    size="lg"
                                    state={validationErrors.phone ? 'error' : 'default'}
                                    hint={validationErrors.phone ? validationErrors.phone : undefined}
                                />     
                            </div>

                            {/* if sso don't show password input */}
                            {
                                !sso &&
                                <>
                                    <div className={styles.row}>             
                                        <InputField
                                            inputText={credentials.password}
                                            onChange={handleInputChange}
                                            name="password"
                                            placeholder=""
                                            size="lg"
                                            type='password'
                                            label="Password"
                                            // state={validationErrors.password ? 'error' : 'default'}
                                            // hint={validationErrors.password ? validationErrors.password : undefined}

                                            state={
                                                passwordValidations.some(validation => validation.isValid === false) || 
                                                (validationErrors.password) ? 
                                                'error' : 
                                                'default'
                                            }
                                            hint={(validationErrors.password ? validationErrors.password : undefined)}                                        
                                        />
                                    </div>
                                    <div className={styles.passwordValidationContainer}>
                                        <MultivalidationPassword validations={passwordValidations} />    
                                    </div>
                                </>                                
                            }


                            <div className={styles.formFooter}>
                                <Button onClick={handleCompleteSignup} className={styles.formFooterBtn} state={isSubmitting ? "disabled" : undefined} fullWidth={true} size='lg'>
                                    Start Using Qencode
                                </Button>                                
                            </div>

                            {completeSignupError && <div className={styles.errorMessage}>{completeSignupError}</div>}
                        </div>
                    </div>      
                    {
                        !organization &&
                        <div className={styles.infoWindow}>
                            <Illustration className={styles.illustration} />

                            <div className={styles.infoWindowCardContainer}>
                                <div className={styles.infoWindowCard}>
                                    <div className={styles.infoWindowCardTitle}>
                                        <CheckLine className={styles.infoWindowCardTitleIcon}/>
                                        <span className={styles.infoWindowCardTitleText}>Free Plan. No credit card required.</span>
                                    </div>
                                    <div className={styles.infoWindowCardContent}>
                                        No subscriptions or minimum contracts to use Qencode. Your free account allows you to use all of our services.
                                    </div>
                                </div> 

                                <div className={styles.infoWindowCard}>
                                    <div className={styles.infoWindowCardTitle}>
                                        <CheckLine className={styles.infoWindowCardTitleIcon}/>
                                        <span className={styles.infoWindowCardTitleText}>Transcode, Store, Deliver, and Play</span>
                                    </div>
                                    <div className={styles.infoWindowCardContent}>
                                        Access to all Qencode services including: Artificial Intelligence, Transcoding, Live Streaming, Media Storage, Content Delivery and Video Player.
                                    </div>
                                </div>  


                                <div className={styles.infoWindowCard}>
                                    <div className={styles.infoWindowCardTitle}>
                                        <CheckLine className={styles.infoWindowCardTitleIcon}/>
                                        <span className={styles.infoWindowCardTitleText}>Get full access</span>
                                    </div>
                                    <div className={styles.infoWindowCardContent}>
                                        Simple tools to easily develop the automated workflow you need, powered by a robust API so you can get started right away.
                                    </div>
                                </div> 

                                <div className={styles.infoWindowCard}>
                                    <div className={styles.infoWindowCardTitle}>
                                        <CheckLine className={styles.infoWindowCardTitleIcon}/>
                                        <span className={styles.infoWindowCardTitleText}>and much more...</span>
                                    </div>
                                </div>                                                                  

                            </div>

                        </div>     
                    }     
                </>
            )}

            {currentStep === 'quickSetup' && (
                <div className={styles.quickSetupWindow}>
                    <div className={styles.title}>Hi {credentials.first_name} 👋, welcome to Qencode!</div>

                    <div className={styles.info}>We are happy to welcome you to Qencode. How would you like to get started?</div>

                    <div className={styles.startingContainer}>
                        <div className={styles.startBlock}>
                            <Transcoding className={styles.startBlockImage}/>
                            <div className={`h4Medium ${styles.startBlockTitle}`}>Transcoding</div>
                            <div className={`b2Regular ${styles.startBlockText}`}>
                                Transcode video files into popular formats and codecs.
                            </div>
                            <Button onClick={() => {}} fullWidth={true}>Transcode a Video</Button>
                        </div>
                        <div className={styles.startBlock}>
                            <LiveStreaming className={styles.startBlockImage}/>
                            <div className={`h4Medium ${styles.startBlockTitle}`}>Live Streaming</div>
                            <div className={`b2Regular ${styles.startBlockText}`}>
                                Stream live videos at scale across the globe.
                            </div>
                            <Button onClick={() => {}} fullWidth={true}>Create a Stream</Button>
                        </div>
                        <div className={styles.startBlock}>
                            <VideoPlayer className={styles.startBlockImage}/>
                            <div className={`h4Medium ${styles.startBlockTitle}`}>Video Player</div>
                            <div className={`b2Regular ${styles.startBlockText}`}>
                                Create and customize a video player for websites.
                            </div>
                            <Button onClick={() => {}} fullWidth={true}>Create a Player</Button>
                        </div>
                        <div className={styles.startBlock}>
                            <MediaStorage className={styles.startBlockImage}/>
                            <div className={`h4Medium ${styles.startBlockTitle}`}>Media Storage</div>
                            <div className={`b2Regular ${styles.startBlockText}`}>
                                Store media files in S3 optimized for online delivery.
                            </div>
                            <Button onClick={() => {}} fullWidth={true}>Create a Bucket</Button>
                        </div>                        
                    </div>

                    <Button
                        onClick={handleSkipQuickSetup}
                        type="secondary"
                        className={styles.skipButton}
                        trailingIcon={<ArrowRight />} 
                    >
                        Skip to Dashboard
                    </Button>
                </div>
            )}


        </div>
    );
};

export default CompleteSignupPage;

