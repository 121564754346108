import PropTypes from "prop-types";
import React from "react";

// color controlled by class from parent and/or by color as parameter
// <Delete className={styles.deleteIcon} color="var(--Primary-500, #5B3EF5)" />
export const Delete = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg
      className={className} 
      role="img"
      aria-label={label}
      style={style} // This will apply the color as an inline style
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path d="M8 7.8999C8 7.40283 7.59692 7 7.09985 7C6.60278 7 6.19995 7.40283 6.19995 7.8999V10.1001C6.19995 10.5972 6.60278 11 7.09985 11C7.59692 11 8 10.5972 8 10.1001V7.8999Z" fill="currentColor"/>
      <path d="M10.0999 7C10.5969 7 11 7.40283 11 7.8999V10.1001C11 10.5972 10.5969 11 10.0999 11C9.60278 11 9.19995 10.5972 9.19995 10.1001V7.8999C9.19995 7.40283 9.60278 7 10.0999 7Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2.93115 5.09985H2.5C2.16846 5.09985 1.8999 4.8313 1.8999 4.5C1.8999 4.16846 2.16846 3.8999 2.5 3.8999H5.53198L5.6355 3.48828C5.87012 2.55444 6.70947 1.8999 7.67212 1.8999H9.32886C10.2925 1.8999 11.1323 2.55566 11.366 3.49048L11.4683 3.8999H14.5C14.8313 3.8999 15.0999 4.16846 15.0999 4.5C15.0999 4.66602 15.0325 4.81641 14.9233 4.92505C14.8149 5.03296 14.6653 5.09985 14.5 5.09985H14.0725L13.7654 11.6682C13.6755 13.5889 12.092 15.0999 10.1692 15.0999H6.87671C4.96289 15.0999 3.38354 13.6023 3.28198 11.6912L2.93115 5.09985ZM6.79932 3.78052C6.8999 3.38037 7.25952 3.09985 7.67212 3.09985H9.32886C9.7417 3.09985 10.1018 3.38086 10.2019 3.78174L10.4177 4.64551C10.4846 4.9126 10.7246 5.09985 11 5.09985H12.8713L12.5667 11.6121C12.5068 12.8926 11.4512 13.8999 10.1692 13.8999H6.87671C5.60083 13.8999 4.5481 12.9016 4.48022 11.6274L4.13281 5.09985H6C6.2749 5.09985 6.51489 4.91284 6.58179 4.646L6.79932 3.78052Z" fill="currentColor"/>
    </svg>     
  );
};

Delete.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Delete.defaultProps = {
  color: 'currentColor', // Or any default color
};