export const columnIds = {
  name: 'name',
  key: 'key',
  dateCreated: 'dateCreated',
  moreActions: 'moreActions',
};

export const modals = {
  rename: 'rename',
  delete: 'delete',
  regenerate: 'regenerate',
};

