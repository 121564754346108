import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from "components/ErrorMessage";
import style from "pages/MediaStorage/BucketSettingsModal/style.module.css";
import {Toggle} from "components/Toggle";
import {Button} from "components/Button";
import {Edit} from "icons";

function EnableCdnFields({
                           bucket,
                           enableCdnData,
                           corsData,
                           accessPolicyData,
                         }) {
  
  if (bucket) {
    return (
      <>
        <div className={`${style.section} ${style.toggleSection}`}>
          <div>
            <h4 className={style.sectionTitle}>Enable CDN</h4>
            <p className={style.detail}>
              Enable high-speed content delivery for this bucket to facilitate
              fast playback around the world.
            </p>
          </div>
          <Toggle
            checked={enableCdnData.enableCdn}
            onChange={() => enableCdnData.setEnableCdn( ( prevState ) => !prevState )}
          />
        </div>
        {
          enableCdnData.enableCdn ? (
            <div className={`${style.section} ${style.cors}`}>
              <div>
                <h4 className={style.sectionTitle}>CORS Configuration</h4>
                <p className={style.detail}>Set permission settings for access to this bucket.</p>
              </div>
              <Button
                onClick={corsData.goToCors}
                type="secondary"
              >
                <Edit className={style.edit}/>
                Edit
              </Button>
            </div>
          ) : null
        }
        {
          enableCdnData.enableCdn ? (
            <div className={`${style.section} ${style.toggleSection}`}>
              <div>
                <h4 className={style.sectionTitle}>
                  Enable Authenticated Access
                </h4>
                <p className={style.detail}>
                  This setting prevents all open and unrestricted access to your
                  bucket content, and allows you to create and distribute uniquely
                  signed URLs for objects in your bucket.
                </p>
                { accessPolicyData.authStatusError && <ErrorMessage>{ accessPolicyData.authStatusError }</ErrorMessage> }
                { accessPolicyData.errorSubmittingPolicy && <ErrorMessage>{ accessPolicyData.errorSubmittingPolicy }</ErrorMessage> }
              </div>
              <Toggle
                checked={accessPolicyData.enableAuthentication}
                disabled={accessPolicyData.loading}
                onChange={() =>
                  accessPolicyData.setEnableAuthentication( ( prevState ) => !prevState )
                }
              />
            </div>
          ) : null
        }
      </>
    )
  }
}

EnableCdnFields.propTypes = {
  bucket: PropTypes.object,
  enableCdnData: PropTypes.shape({
    enableCdn: PropTypes.bool,
    enableCdnInitial: PropTypes.bool,
    setEnableCdn: PropTypes.func.isRequired,
  }),
  corsData: PropTypes.shape({
    goToCors: PropTypes.func.isRequired,
  }),
  accessPolicyData: PropTypes.shape({
    enableAuthentication: PropTypes.bool,
    authStatusError: PropTypes.string,
    errorSubmittingPolicy: PropTypes.string,
    setEnableAuthentication: PropTypes.func,
  }),
};

export default EnableCdnFields;