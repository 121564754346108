
export default function getGlobalFilterWithValueMap(columnToValueMap) {
  return function globalFilterWithAdaptedValues(row, columnId, searchValue) {
    const cellValue = row.getValue(columnId);
    if (typeof cellValue === 'string') {
      const cellValueTrimmedLowercase = cellValue.trim().toLowerCase();
      let cellValue2 = '';
      
      if (Object.hasOwn(columnToValueMap, columnId)) {
        cellValue2 = columnToValueMap[columnId][cellValueTrimmedLowercase] || cellValueTrimmedLowercase || '';
      }
      
      return cellValueTrimmedLowercase.includes(searchValue)
        || cellValue2.toLowerCase().includes(searchValue);
    }
    return false;
  }
}
