import {InputField} from "components/InputField";
import style from './style.module.css';
import {LookingGlass} from "icons";
import React from "react";
import PropTypes from "prop-types";

function Search({ globalFilter, setGlobalFilter, className }) {
  return (
    <InputField
      className={ `${ style.search } ${ className || '' }`  }
      placeholder="Search..."
      icon={ <LookingGlass/> }
      inputText={ globalFilter ?? '' }
      onChange={ (event) => setGlobalFilter(String(event.target.value)) }
    />
  );
}

Search.propTypes = {
  globalFilter: PropTypes.string.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Search;