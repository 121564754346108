import React from 'react';
import PropTypes from 'prop-types';
import Meter from "components/Meter";

/**
 *
 */

function MeterForFraction({
    title,
    rawValue,
    fraction,
    total,
    maxFraction,
    ...rest
  }) {
  const percent = fraction * 100 / total;
  let progressbarFillPercent = (fraction / maxFraction) * 100;
  if ( !(typeof progressbarFillPercent === 'number' && Number.isFinite(progressbarFillPercent)) ) {
    progressbarFillPercent = 0;
  }
  
  let percentFormatted = '';
  
  if (percent < .01) {
    percentFormatted = '< 0.01';
  }
  else {
    percentFormatted = parseFloat(Number(percent).toFixed(2));
  }

  return (
    <Meter
      title={title}
      rawValue={rawValue}
      progressbarFillPercent={progressbarFillPercent}
      percentFormatted={percentFormatted}
      {...rest}
    />
  );
}

MeterForFraction.propTypes = {
  title: PropTypes.string,
  rawValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]) ,
  fraction: PropTypes.number,
  total: PropTypes.number,
  maxFraction: PropTypes.number,
};

export default MeterForFraction;