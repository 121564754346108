import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.css';
import {format} from "date-fns";
import classNames from "classnames";

function Tooltip(props) {
  const {
    children,
    indexValue,
    monthly,
    absX,
  } = props;

  let heading = '';
  if (monthly) {
    heading = format(indexValue, 'MMMM yyyy');
  } else {
    heading = format(indexValue, 'MMMM dd, yyyy');
  }

  return (
    <div className={ classNames(
      style.container, {
        [style.rightAligned]: absX < 200,
      }
    ) } >
      <h3 className={style.heading}>
        { heading }
      </h3>
      { children }
    </div>
  )
}

Tooltip.propTypes = {
  monthly: PropTypes.bool,
  absX: PropTypes.number,
};

export default Tooltip;