import PropTypes from "prop-types";
import React from "react";

// color controlled by class from parent and/or by color as parameter
// <Upload className={styles.uploadIcon} color="var(--Primary-500, #5B3EF5)" />
export const Upload = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      // style={{ color: color }} // This will apply the color as an inline style
      style={style} // Apply style conditionally
      xmlns="http://www.w3.org/2000/svg" 
      width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M8.4999 3.0999C5.51756 3.0999 3.0999 5.51756 3.0999 8.4999C3.0999 11.4822 5.51756 13.8999 8.4999 13.8999C11.4822 13.8999 13.8999 11.4822 13.8999 8.4999C13.8999 5.51756 11.4822 3.0999 8.4999 3.0999ZM1.8999 8.4999C1.8999 4.85482 4.85482 1.8999 8.4999 1.8999C12.145 1.8999 15.0999 4.85482 15.0999 8.4999C15.0999 12.145 12.145 15.0999 8.4999 15.0999C4.85482 15.0999 1.8999 12.145 1.8999 8.4999Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.48299 11.0852C9.48299 11.3143 9.28124 11.5 9.03237 11.5H7.99082C7.74195 11.5 7.5402 11.3143 7.5402 11.0852V8.3887H6.33797C6.15131 8.3887 6 8.24941 6 8.07757C6 8.00319 6.02895 7.93127 6.08159 7.87485L8.16251 5.64455C8.32467 5.47075 8.60916 5.45087 8.79795 5.60016C8.81546 5.614 8.83178 5.62906 8.84678 5.6452L10.9189 7.87534C11.0402 8.00592 11.0236 8.20232 10.8817 8.31401C10.8205 8.36222 10.7426 8.3887 10.662 8.3887H9.48299V11.0852Z" fill="currentColor"/>
    </svg>
  );
};

Upload.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Upload.defaultProps = {
  color: 'currentColor', // Or any default color
};