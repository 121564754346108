import PropTypes from "prop-types";
import React from "react";

export const AddFunds = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path d="M2.61255 5.83011V5.29943C2.61255 4.48679 3.11589 3.7588 3.87578 3.47164L9.31485 1.41791C10.1672 1.09689 11.0775 1.72591 11.0775 2.63687V4.81823" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.993 8.57715C10.5887 8.58008 10.2634 8.90826 10.2634 9.31263C10.2634 9.71699 10.5916 10.0452 10.996 10.0422C11.4003 10.0422 11.7285 9.71406 11.7285 9.30969C11.7285 8.90533 11.4003 8.57715 10.993 8.57715Z" fill="currentColor"/>
      <path d="M5.86823 13.705L11.7287 13.705C12.8031 13.705 13.6821 12.8259 13.6821 11.7515V6.77084C13.6821 5.69188 12.8076 4.81738 11.7287 4.81738H3.91485C3.19533 4.81738 2.61255 5.40016 2.61255 6.11969V8.97263" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.91459 15.3326C1.93704 15.3326 0.333252 13.7288 0.333252 11.7513C0.333252 9.77371 1.93704 8.16992 3.91459 8.16992C5.89279 8.16992 7.49592 9.77371 7.49592 11.7513C7.49592 13.7288 5.89279 15.3326 3.91459 15.3326ZM3.91467 9.72337C4.24604 9.72337 4.51467 9.992 4.51467 10.3234V11.151H5.34277C5.67414 11.151 5.94277 11.4196 5.94277 11.751C5.94277 12.0823 5.67414 12.351 5.34277 12.351H4.51467V13.18C4.51467 13.5113 4.24604 13.78 3.91467 13.78C3.58329 13.78 3.31467 13.5113 3.31467 13.18V12.351H2.48682C2.15545 12.351 1.88682 12.0823 1.88682 11.751C1.88682 11.4196 2.15545 11.151 2.48682 11.151H3.31467V10.3234C3.31467 9.992 3.58329 9.72337 3.91467 9.72337Z" fill="currentColor"/>
    </svg>    
  );
};

AddFunds.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

AddFunds.defaultProps = {
  color: 'currentColor', // Or any default color
};