import React from 'react';

const TemplatePage = () => {
  return (
      <div>
        <h1>Template Page</h1>
        <p>This is Template Page.</p>
      </div>
  );
};

export default TemplatePage;