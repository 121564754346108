import PropTypes from "prop-types";
import React from "react";

export const CheckLine = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M7.9999 2.59999C5.01727 2.59999 2.5999 5.01736 2.5999 7.99999C2.5999 10.9826 5.01727 13.4 7.9999 13.4C10.9825 13.4 13.3999 10.9826 13.3999 7.99999C13.3999 5.01736 10.9825 2.59999 7.9999 2.59999ZM1.3999 7.99999C1.3999 4.35462 4.35453 1.39999 7.9999 1.39999C11.6453 1.39999 14.5999 4.35462 14.5999 7.99999C14.5999 11.6454 11.6453 14.6 7.9999 14.6C4.35453 14.6 1.3999 11.6454 1.3999 7.99999ZM10.5073 6.62065C10.7417 6.85497 10.7417 7.23487 10.5073 7.46918L7.90317 10.0733C7.79065 10.1859 7.63804 10.2491 7.47891 10.2491C7.31978 10.2491 7.16716 10.1859 7.05464 10.0733L5.49214 8.51085C5.25783 8.27653 5.25783 7.89663 5.49214 7.66232C5.72646 7.428 6.10636 7.428 6.34067 7.66232L7.47891 8.80055L9.65881 6.62065C9.89312 6.38634 10.273 6.38634 10.5073 6.62065Z" fill="currentColor"/>
    </svg>    
  );
};

CheckLine.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

CheckLine.defaultProps = {
  color: 'currentColor', // Or any default color
};