import React, { useState } from 'react';
import { Button } from 'components/Button'; 
import { useBackend } from 'api/useBackend'; 

import { mediaStorageAnimation } from './animations';
import Lottie from "lottie-react";

// import { ReactComponent as MediaStorage } from 'assets/images/MediaStorage.svg'
import { ReactComponent as MediaStorage } from 'assets/images/MediaStorageSmall.svg'

import styles from './EnableMediaStorage.module.css';

export const EnableMediaStorage = ({ onEnableSuccess, onEnableFail, onCancel }) => {
    const { enableMediaStorage } = useBackend();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleEnable = async () => {
        setLoading(true);
        setError(null);
        try {

            const { success, data, detail } = await enableMediaStorage();

            // {
            //     "error": 0,
            //     "detail": null,
            //     "timestamp": 1721655504.825568,
            //     "data": {
            //         "access_id": "**********************",
            //         "secret_key": "**********************"
            //     }
            // }            

            if (success && data) {
                console.log("config data: ", data);
                onEnableSuccess(data)
                setError(null)
            } else {
                setError(detail || "Failed to enable media storage");
                console.error(detail || "Failed to enable media storage");  
                onEnableFail(detail || "Failed to enable media storage")
            }
        } catch (error) {
            setError(error.message);
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.container}>
            {loading ? (
                <>
                    <Lottie animationData={mediaStorageAnimation} loop={true} />
                    <div className={styles.waitMessage}>
                        Please wait a few minutes while your Media Storage environment is being configured.
                    </div>
                </>
            ) : (
                <>                    
                    <MediaStorage />
                    <div className={styles.messageContainer}>
                        {/* <div className={styles.title}>Account not enabled for Media Storage</div> */}
                        {/* <div className={styles.description}>Please enable your account for Media Storage to get started.</div> */}
                        <div className={styles.description}>Account not enabled for Media Storage</div>
                    </div>

                    <div className={styles.formFooter}>
                        <div className={styles.formMainExtraButtons}></div>
                        <div className={styles.formMainButtons}>
                            <Button 
                                size="sm"
                                onClick={onCancel}
                                type="secondary"
                            >
                                Cancel
                            </Button> 
                            <Button 
                                size="sm"
                                onClick={handleEnable}>
                                Enable Media Storage
                            </Button>  
                        </div>
                    </div>  

                    {/* <Button onClick={handleEnable}>Enable Media Storage</Button> */}
                    {error && <div className={styles.errorMessage}>{error}</div>}
                </>
            )}
        </div>
    );
};
