import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Select} from "components/Select";
import {
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import globalFilterFn from "components/Table/columnFilters/exactCaseInsensitiveFilter";
import Table from "components/Table";
import bytesToReadable from "helpers/bytesToReadable";
import {secondsToDuration} from "helpers/duration";
import ExpandableCellWithMeter from "pages/StatisticsPage/components/ExpandableCellWithMeter";
import style from "./style.module.css";
import Search from "components/Search";
import MoneyRounded from "components/Table/Cells/MoneyRounded";
import Controls from "components/Controls";
import VerticalGapsLayout from "components/VerticalGapsLayout";
import {ReactComponent as LiveStreamingImage} from 'assets/images/LiveStreaming.svg';
import Pagination from "components/Table/Pagination";
import {streamingCodeToName} from "pages/StatisticsPage/config";

const columnIds = {
  name: 'name',
  bandwidth: 'bandwidth',
  duration: 'duration',
  cost: 'cost',
};

const optionsWithNoData = [
  {
    value: 'stream',
    label: 'Streams',
    noDataImage: <LiveStreamingImage/>,
    noDataTitle: 'No streams found',
    noDataDescription: 'Start by creating a new stream',
  },
  {
    value: 'project',
    label: 'Projects',
    noDataImage: <LiveStreamingImage/>,
    noDataTitle: 'No projects found',
    noDataDescription: 'Start by creating a new project',
  }
];

export const typesOptions = optionsWithNoData.map(({label, value}) => ({
  label,
  value,
}));

export const typeToNoData = optionsWithNoData.reduce((map, {
  value,
  noDataImage,
  noDataTitle,
  noDataDescription,
  }) => {
  return {
    ...map,
    [value]: {
      noDataImage,
      noDataTitle,
      noDataDescription,
    },
  };
}, {});


function UsageBy({
  byStream,
  byProject,
}) {
  const [selectedCategory, setSelectedCategory] = useState('project');
  const loadingStatus = useMemo(() => {
    if (selectedCategory === 'project') {
      return byProject;
    }
    return byStream;
  }, [byProject, byStream, selectedCategory]);
  
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([{
    id: columnIds.bandwidth,
    desc: true,
  }]);
  const [expanded, setExpanded] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  
  function handleSelect(e) {
    setSelectedCategory(e?.target?.value);
    setExpanded({});
  }

  const columns = useMemo(() => [
    {
      id: columnIds.name,
      accessorKey: 'name',
      header: () => <span>{
        selectedCategory === 'project' ? 'Project Name' : 'Stream Name'
      }</span>,
      cell: (info) => {
        const value = info.getValue();
        const level1Value = streamingCodeToName[value] || value;
        return (
          <ExpandableCellWithMeter
            info={info}
            dataProperty="duration"
            level1Value={level1Value}
          />
        );
      },
      meta: {
        className: style.nameColumn,
      },
    },
    {
      id: columnIds.bandwidth,
      accessorKey: 'bandwidth',
      header: () => <span>Bandwidth</span>,
      cell: (info) => <span>{ bytesToReadable(info.getValue()) }</span>,
      meta: {
        className: style.bandwidthColumn,
      },
    },
    {
      id: columnIds.duration,
      accessorKey: 'duration',
      header: () => <span>Duration</span>,
      cell: (info) => <span>{ secondsToDuration(info.getValue()) }</span>,
      meta: {
        className: style.durationColumn,
      },
    },
    {
      id: columnIds.cost,
      accessorKey: 'amount',
      header: () => <span>Stream Cost</span>,
      cell: (info) => <MoneyRounded value={info.getValue()} semiBold/>,
      meta: {
        className: style.costColumn,
      },
    }
  ], [selectedCategory]);
  
  const table = useReactTable({
    data: loadingStatus.data,
    columns,
    defaultColumn: {
      cell: (info) => <span>{ info.getValue() || '—' }</span>,
    },
    getCoreRowModel: getCoreRowModel(),
    /* Sorting */
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    /* Filtering */
    globalFilterFn,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    maxLeafRowFilterDepth: 0, /* Do not search sub-rows. Otherwise row couldn't be expanded (most of the time, because sub-rows will be filtered out) */
    /* Expanded */
    getSubRows: row => (row.tariffs || []).map((row) => {
      return ({
        ...row,
        name: row.tariff_name,
      })
    }),
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: setExpanded,
    paginateExpandedRows: false,
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      globalFilter,
      expanded,
      pagination,
    },
  });
  
  return (
    <VerticalGapsLayout>
      <Controls
        title="Usage by"
        leftContent={
          <Select
            options={typesOptions}
            value={selectedCategory}
            onChange={handleSelect}
          />
        }
        rightContent={
          <Search
            className={style.search}
            setGlobalFilter={setGlobalFilter}
            globalFilter={globalFilter}
          />
        }
      />
      <Table
        { ...loadingStatus }
        table={table}
        skeletonRowCount={5}
        minWidth={620}
        { ...typeToNoData[selectedCategory] }
      />
      <Pagination table={table}/>
    </VerticalGapsLayout>
  );
}

UsageBy.propTypes = {
  byStream: PropTypes.object.isRequired,
  byProject: PropTypes.object.isRequired,
};

export default UsageBy;