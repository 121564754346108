import {useNavigation} from "react-day-picker";
import React from "react";
import style from "./style.module.css";
import {ChevronRight} from "icons/ChevronRight";
import {companyYears} from "helpers/years";
import {months} from "helpers/months";
import Select from "components/Calendar/Select";

const yearOptions = companyYears.map((year) => ({
  value: String(year),
  label: String(year),
}));

const monthOptions = months.map((month, index) => ({
  value: String(index),
  label: month,
}));

function CustomCaption(props) {
  const { goToMonth, nextMonth, previousMonth } = useNavigation();

  function selectMonth(index) {
    const date = new Date( props.displayMonth.getFullYear() , index, 1 );
    goToMonth( date );
  }
  
  function selectYear(year) {
    const date = new Date(year, props.displayMonth.getMonth(), 1);
    goToMonth( date );
  }
  
  return (
    <div className={ style.headingWrapper }>
      <button
        className={ style.previous }
        disabled={!previousMonth}
        onClick={() => previousMonth && goToMonth(previousMonth)}
      >
        <ChevronRight/>
      </button>
      <div className={ style.date }>
        <Select
          value={ months[ props.displayMonth.getMonth() ] }
          options={monthOptions}
          onChange={selectMonth}
        />
        <Select
          value={ String(props.displayMonth.getFullYear()) }
          options={yearOptions}
          onChange={selectYear}
        />
      </div>
      <button
        className={ style.next }
        disabled={!nextMonth}
        onClick={() => nextMonth && goToMonth(nextMonth)}
      >
        <ChevronRight/>
      </button>
    </div>
  );
}


export default CustomCaption;