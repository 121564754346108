import React from 'react';
import PropTypes from 'prop-types';
import {Button} from "components/Button";
import {Add, Delete} from "icons";
import {InputField} from "components/InputField";
import style from './style.module.css';

function InputList({
  placeholder,
  addButtonText,
  list = [],
  setList,
  firstItemHeading,
  disabled,
}) {
  function handleAdd() {
    let max = Math.max(...list.map((item) => item.id));
    if (!Number.isFinite(max)) {
      max = 1;
    }
    setList([
      ...list,
      {
        id: max + 1,
        value: '',
      }
    ]);
  }
  
  function handleRemove(id) {
    const newList = list.filter((item) => item.id !== id);
    setList(newList);
  }
  
  function handleUpdate(id, newValue) {
    const newList = list.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          value: newValue,
        }
      } else {
        return item;
      }
    });
    setList(newList);
  }

  return (
    <>
      <div className={style.list}>
        {
          list.map((item, index) => (
            <InputListItem
              key={item.id}
              disabled={disabled}
              value={item.value}
              placeholder={placeholder}
              onRemove={() => handleRemove(item.id)}
              onChange={(e) => handleUpdate(item.id, e.target.value)}
              label={ (index === 0) ? firstItemHeading : '' }
            />
          ))
        }
      </div>
      <Button
        icon={ <Add/> }
        className={style.addButton}
        variant="link"
        state={ disabled ? "disabled" : "default" }
        onClick={() => handleAdd()}
      >
        { addButtonText }
      </Button>
    </>
  )
}

function InputListItem({
  value,
  onChange,
  onRemove,
  placeholder,
  label,
  disabled,
}) {
  return (
    <div className={style.wrapper}>
      <InputField
        label={label}
        inputText={value}
        onChange={onChange}
        placeholder={placeholder}
        state={ disabled ? "disabled" : "default" }
      />
      <Button
        icon={ <Delete/> }
        type="secondary"
        state={ disabled ? "disabled" : "default" }
        className={style.deleteButton}
        onClick={onRemove}
      />
    </div>
  )
}


InputList.propTypes = {
  placeholder: PropTypes.string,
  addButtonText: PropTypes.string,
};

export default InputList;