import React, { useState, useCallback, useEffect } from 'react';
import { 
    useLocation,
    useNavigate, 
    Link 
} from 'react-router-dom';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { Button } from '../../components/Button';
import { NoData } from '../../components/NoData';

import { Modal } from '../../components/Modal';

import { getInvoicesList, getInvoiceData } from '../../api/qencode-account-api'

import { useAuth } from '../../context/AuthContext'
import { useUser } from '../../context/UserContext' 

import { Active, Inactive, Download, Print, EyeOpen } from '../../icons'

import { ReactComponent as QencodeLogo } from '../../assets/images/QencodeLogo.svg';

import { OptionsMenu } from '../../components/OptionsMenu';

import { formatCurrency, formatDate, formatPeriod } from 'helpers/formatting';

import styles from './index.module.css';

// const formatPeriod = (date) => {
//     const [year, month] = date.split('-');
//     const dateObject = new Date(year, month - 1);
//     const monthName = dateObject.toLocaleString('default', { month: 'short' });
//     return `${monthName} ${year}`;
// }
  
// const formatDate = (inputDate) => {
//     const date = new Date(inputDate);
//     const options = { month: 'short', day: 'numeric', year: 'numeric' };
//     return date.toLocaleDateString('en-US', options);
// }

// const formatCurrency = (value) => {
//     return new Intl.NumberFormat('en-US', {
//         style: 'currency',
//         currency: 'USD',
//     }).format(value);
// }


const Invoices = () => {

    const { 
        userData
    } = useUser(); 


    const location = useLocation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const { 
        isAuthenticated, 
        //getAccessToken,
        getToken
    } = useAuth();

    const [invoicesData, setInvoicesData] = useState([]);
    const [invoicesError, setInvoicesError] = useState(null);
    const [loadingInvoice, setLoadingInvoice] = useState(true);

    const [shouldGeneratePdf, setShouldGeneratePdf] = useState(false);
    const [shouldPrint, setShouldPrint] = useState(false);
    const [shouldView, setShouldView] = useState(false);

    const [currentInvoice, setCurrentInvoice] = useState(null);
    const [loadInvoiceError, setLoadInvoiceError] = useState(null);

    const [isInvoiceModalOpen, setInvoiceModalOpen] = useState(false);

    const fetchInvoicesData = useCallback(async () => {
        if (isAuthenticated) {
          setLoading(true);
          //const token = getAccessToken();
          const token = await getToken()
          const { success, data, total_count } = await getInvoicesList(token);
          console.log("total_count: ", total_count)
          if (success && data) {
            console.log("invoices data: ", data)
            setInvoicesData(data);
            setInvoicesError(null);
          } else {
            setInvoicesError("Failed to get invoices data");
            console.log("Failed to get invoices data");
          }
          setLoading(false);
        } else {
          setInvoicesData([]);
          setInvoicesError(null);
          setLoading(false);
        }
    }, [isAuthenticated, getToken]);    

    useEffect(() => {
        fetchInvoicesData();
    }, [fetchInvoicesData]); 
 
    const getInvoiceById = useCallback(async (id) => {
        //const token = getAccessToken();
        const token = await getToken()
        const settings = {id}
        //const settings = {} // to test error
        setLoadingInvoice(true)
        const { success, data } = await getInvoiceData(token, settings);
        setLoadingInvoice(false)
        if (success && data) {
            console.log("Invoice data: ", data)
            setCurrentInvoice(data)
        } else {
            console.log("Failed to get invoice data");
            setLoadInvoiceError("Failed to get invoice data")
        }            
    }, [getToken]); 

    useEffect(() => {    
        // Parse the query parameters
        const searchParams = new URLSearchParams(location.search);
        const invoiceId = searchParams.get('id');
        const tab = searchParams.get('tab');

        if (invoiceId && tab === 'invoices') {
            console.log('Load invoice:', invoiceId);
            
            // open modal window with invoice 
            setInvoiceModalOpen(true)

            // get invoice by id
            getInvoiceById(invoiceId)
        } else {
            // close modal when going from http://localhost:3000/billing?tab=invoices&id=C72F685-4189 back to http://localhost:3000/billing?tab=invoices
            setInvoiceModalOpen(false)
        }
    }, [location.search, getInvoiceById]); // Depend on location.search and invoicesData      
    
    // Define menuItems as a function that takes a member's uid and returns the menu items
    const getMenuItems = (invoice, onDownload, onPrint, onView) => [
        {
            icon: <EyeOpen label="View" />,
            onClick: () => onView(invoice), 
            text: "View"
        },
        {
            icon: <Download label="Download" />,
            onClick: () => onDownload(invoice), 
            text: "Download"
        },
        {
            icon: <Print label="Print" />,
            onClick: () => onPrint(invoice), 
            text: "Print"
        },
    ];  
    
    const generatePdf = useCallback(async (invoice) => {
        const invoiceElement = document.getElementById('invoice-section');
        if (invoiceElement) {
            try {
                const canvas = await html2canvas(invoiceElement);
                const data = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: 'portrait',
                });
                const imgProps = pdf.getImageProperties(data);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save(`invoice_${invoice?.invoice_id}.pdf`);
                // Reset the flag and potentially notify the user of success
                setShouldGeneratePdf(false);
                console.log('PDF generated successfully!');
            } catch (error) {
                console.error('Error while generating PDF: ', error);
                // Reset the flag and notify user of failure
                setShouldGeneratePdf(false);
            }
        } else {
            console.error('No invoice element found to print!');
        }
    }, []);
        

    const createInvoiceLink = (invoice) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('id', invoice.invoice_id);
      
        // Ensure the 'tab' parameter is present
        if (!searchParams.get('tab')) {
          searchParams.set('tab', 'invoices');
        }
      
        return `?${searchParams.toString()}`;
    };        
    
    // const handleDownload = async (invoice) => {

    //     // Parse the query parameters to get invoice ID from params
    //     const searchParams = new URLSearchParams(location.search);
    //     const invoiceIdFromParams = searchParams.get('id');

    //     const invoiceId = invoice.invoice_id;
        
    //     // in passed inoice ID is same as in params load it
    //     // other cases will be handle in useEffect watching location.search change
    //     if (invoiceId === invoiceIdFromParams) {
    //         console.log('Load invoice:', invoiceId);
            
    //         // open modal window with invoice 
    //         setInvoiceModalOpen(true)

    //         // get invoice by id
    //         getInvoiceById(invoiceId)
    //     } else {
    //         // Update the URL with the new invoice ID
    //         navigate(createInvoiceLink(invoice), { replace: true })
    //     }       


    //     // Set the flag to generate PDF
    //     setShouldGeneratePdf(true);    
        
    // };

    // const handlePrint = async (invoice) => {

    //     // Parse the query parameters to get invoice ID from params
    //     const searchParams = new URLSearchParams(location.search);
    //     const invoiceIdFromParams = searchParams.get('id');

    //     const invoiceId = invoice.invoice_id;
        
    //     // in passed inoice ID is same as in params load it
    //     // other cases will be handle in useEffect watching location.search change
    //     if (invoiceId === invoiceIdFromParams) {
    //         console.log('Load invoice:', invoiceId);
            
    //         // open modal window with invoice 
    //         setInvoiceModalOpen(true)

    //         // get invoice by id
    //         getInvoiceById(invoiceId)
    //     } else {
    //         // Update the URL with the new invoice ID
    //         navigate(createInvoiceLink(invoice), { replace: true })
    //     }       


    //     // Set the flag to print
    //     setShouldPrint(true);    
        
    // };

    // const handleView = async (invoice) => {

    //     // Parse the query parameters to get invoice ID from params
    //     const searchParams = new URLSearchParams(location.search);
    //     const invoiceIdFromParams = searchParams.get('id');

    //     const invoiceId = invoice.invoice_id;
        
    //     // in passed inoice ID is same as in params load it
    //     // other cases will be handle in useEffect watching location.search change
    //     if (invoiceId === invoiceIdFromParams) {
    //         console.log('Load invoice:', invoiceId);
            
    //         // open modal window with invoice 
    //         setInvoiceModalOpen(true)

    //         // get invoice by id
    //         getInvoiceById(invoiceId)
    //     } else {
    //         // Update the URL with the new invoice ID
    //         navigate(createInvoiceLink(invoice), { replace: true })
    //     }       


    //     // Set the flag to print
    //     setShouldView(true);    
        
    // };

    const handleInvoiceAction = async (invoice, actionFlagSetter, updateUrl = true) => {
        const searchParams = new URLSearchParams(location.search);
        const invoiceIdFromParams = searchParams.get('id');
        const invoiceId = invoice.invoice_id;
    
        if (invoiceId === invoiceIdFromParams) {
            console.log('Load invoice:', invoiceId);
            setInvoiceModalOpen(true);
            getInvoiceById(invoiceId);
        } else if (updateUrl) {
            navigate(createInvoiceLink(invoice), { replace: true });
            if (actionFlagSetter) {
                actionFlagSetter(true);
            }
        }
    };

    const handleDownload = async (invoice) => {
        handleInvoiceAction(invoice, setShouldGeneratePdf);
    };
    
    const handlePrint = async (invoice) => {
        handleInvoiceAction(invoice, setShouldPrint);
    };
    
    const handleView = async (invoice) => {
        handleInvoiceAction(invoice, setShouldView);
    };

    const handleInvoiceClick = (invoice) => {
        console.log("Invoice clicked: ", invoice);
        handleInvoiceAction(invoice, null, false);
    };

    // const handleInvoiceClick = (invoice) => {
    //     // Log the entire invoice data
    //     console.log("Invoice clicked: ", invoice);

    //     // Parse the query parameters to get invoice ID from params
    //     const searchParams = new URLSearchParams(location.search);
    //     const invoiceIdFromParams = searchParams.get('id');

    //     const invoiceId = invoice.invoice_id;
        
    //     // in passed inoice ID is same as in params load it
    //     // other cases will be handle in useEffect watching location.search change
    //     if (invoiceId === invoiceIdFromParams) {
    //         console.log('Load invoice:', invoiceId);
            
    //         // open modal window with invoice 
    //         setInvoiceModalOpen(true)

    //         // get invoice by id
    //         getInvoiceById(invoiceId)
    //     }
    // };

    const closeInvoiceModal = () => {
        console.log("Close invoice modal")

        // when modal is closed 
        // set http://localhost:3000/billing?tab=invoices&id=671A271-5418 => http://localhost:3000/billing?tab=invoices

        // Parse the current search parameters
        const searchParams = new URLSearchParams(location.search);
        // Remove the 'id' parameter from the URL
        searchParams.delete('id');
        // Navigate to the updated URL without the 'id'
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });

        setInvoiceModalOpen(false)
        setCurrentInvoice(null)
        setLoadInvoiceError(null)
    }

    // will generate and save PDF when clicked from menu and invoice is loaded
    useEffect(() => {
        const generatePdfIfReady = async () => {
            if (shouldGeneratePdf && currentInvoice && !loadingInvoice) {
                await generatePdf(currentInvoice);
                // Reset the flag after generating the PDF
                setShouldGeneratePdf(false);
            }
        };
    
        generatePdfIfReady();
    }, [shouldGeneratePdf, loadingInvoice, generatePdf, currentInvoice]);   
    
    // will print invoice when clicked in menu and when invoice loaded
    useEffect(() => {
        const printInvoice = async () => {
            if (shouldPrint && currentInvoice && !loadingInvoice) {
                // print invoice
                window.print()
                // Reset the flag after generating the PDF
                setShouldPrint(false);
            }
        };
    
        printInvoice();
    }, [shouldPrint, loadingInvoice, currentInvoice]);   

    // will show invoice when clicked in menu and when invoice loaded
    useEffect(() => {
        const showInvoice = async () => {
            if (shouldView && currentInvoice && !loadingInvoice) {
                // show invoice
                setInvoiceModalOpen(true)
                // Reset the flag after generating the PDF
                setShouldView(false);
            }
        };
    
        showInvoice();
    }, [shouldView, loadingInvoice, currentInvoice]);       

    if (loading) {
        return <div>Loading...</div>; // Show a loading state
    }

    return (
        <div>
            <div className="tableContainer">
                <table className={`standardTable ${invoicesData?.length === 0 ? 'emptyTable' : ''}`}>
                    <thead>
                        <tr>
                            <th>Invoice period</th>
                            <th>Invoice id</th>
                            <th>Amount</th>
                            <th>Due date</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {invoicesData.map(invoice => (
                      <tr key={invoice.invoice_id} className="tableRow">
                        <td>
                            <div className="cellContainer">{formatPeriod(invoice.period)}</div>
                        </td>
                        <td className='cellHighlighted'>
                            <div className="cellContainer">
                                <Link 
                                    to={createInvoiceLink(invoice)}
                                    onClick={() => handleInvoiceClick(invoice)}
                                    className="link-style" // Use your actual class for styling
                                >
                                    {invoice.invoice_id}
                                </Link>
                            </div>
                        </td>
                        <td>
                            <div className="cellContainer">{formatCurrency(invoice.amount)}</div>
                        </td>
                        <td className="cellCapitilized">
                            <div className="cellContainer">{formatDate(invoice.due_date)}</div>
                        </td>
                        <td>
                            <div className={styles.cellContainer}>
                                <div className={styles.invoiceStatus}>
                                    {invoice.status === "paid" && <Active className={`${styles.statusIcon} ${styles.paid}`} />}
                                    {invoice.status === "overdue" && <Inactive className={`${styles.statusIcon} ${styles.overdue}`}/>}
                                    <div>{invoice.status}</div>  
                                </div>                                
                            </div>
                        </td>
                        <td className="optionButtonsCell">
                            <div className="cellContainer">
                                <OptionsMenu menuItems={getMenuItems(invoice, handleDownload, handlePrint, handleView)} />
                            </div>
                        </td>
                      </tr>
                    ))}                    
                    </tbody>
                </table>    
                {/* {
                    invoicesData?.length === 0 &&
                    <div className="tableNodata">
                        <div className="tableNodataTitle">No invoices found</div>
                    </div>                        
                }         */}

                {
                    invoicesData?.length === 0 &&
                    <NoData
                        title="No invoices found"
                    />                       
                }                
            </div>

            {invoicesError && <div className={styles.errorMessage}>{invoicesError}</div>}

            <Modal isOpen={isInvoiceModalOpen} onClose={closeInvoiceModal}
                variant="fullScreen"
                header={
                    <>  
                        <div className={styles.fullScreenModalTitle}>
                            <h3>Invoice</h3>     
                            <span className={styles.fullScreenModalTitleId}>
                                { loadingInvoice && <>loading...</>} {currentInvoice?.invoice_id}                                
                            </span>                               
                        </div>                      
                        <div>
                            <Button
                                icon={<Print />}
                                onClick={() => window.print()}
                            >
                                Print
                            </Button>      
                        </div>      
                    </>
                }
            >
                <div id='invoice-section' className={styles.fullScreenModalContainer}>      

                    <div className={styles.fullScreenBody}> 
                        <div className={styles.invoiceHeader}><QencodeLogo /></div>

                        <div className={styles.fullScreenSummary}>
                            <div className={styles.fullScreenSummaryLeft}>
                                <div className={styles.fullScreenSummaryCard}>
                                    <div className={styles.fullScreenSummaryCardTitle}>From</div>
                                    <div className={styles.fullScreenSummaryCardBusinessName}>
                                        Qencode, Corp.
                                    </div>
                                    <div className={styles.fullScreenSummaryCardAddress}>
                                        <div>
                                            16530 Ventura Blvd.<br/>
                                            Suite 402<br/>
                                            Encino, CA 91436, USA<br/>
                                        </div>
                                        <div>
                                            +1 310 905-8600<br/>
                                            support@qencode.com
                                        </div>                                    
                                    </div>

                                </div>
                                <div className={styles.fullScreenSummaryCard}>
                                    <div className={styles.fullScreenSummaryCardTitle}>To</div>
                                    <div className={styles.fullScreenSummaryCardBusinessName}>
                                        {userData.business_name}
                                    </div>
                                    <div className={styles.fullScreenSummaryCardAddress}>
                                        {
                                            userData.billing_address &&
                                            <div>
                                                {userData.billing_address}
                                            </div>
                                        }
                                        {
                                            (userData.phone || userData.billing_email) &&
                                            <div>
                                                {userData.phone}<br/>
                                                {userData.billing_email}
                                            </div>                                          
                                        }                                  
                                    </div>

                                </div>
                            </div>
                            <div className={styles.fullScreenSummaryRight}>
                                <div className={styles.fullScreenSummaryCard}>
                                    <div className={styles.fullScreenSummaryCardTitle}>Invoice ID:</div>
                                    <div className={styles.fullScreenSummaryCardData}>{currentInvoice?.invoice_id}</div>
                                </div>
                                <div className={styles.fullScreenSummaryCard}>
                                    <div className={styles.fullScreenSummaryCardTitle}>Invoice period</div>
                                    {
                                        currentInvoice?.period && <div className={styles.fullScreenSummaryCardData}>{formatPeriod(currentInvoice?.period)}</div>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={styles.fullScreenFooter}>
                        If you have questions about your invoice, 
                        please review <Link to={'/billing'}>Billing</Link> or contact us at <a href="mailto:support@qencode.com">support@qencode.com</a>
                    </div>

                    { loadInvoiceError && <div className={styles.errorMessage}>{loadInvoiceError}</div> }   
                </div>  
            </Modal>                
        </div>
    );
};

export default Invoices;