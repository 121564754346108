import React from 'react';


const Settings = () => {

    // const [loading, setLoading] = useState(true);
    const loading = false;


    if (loading) {
        return <div>Loading...</div>; // Show a loading state
    }

    return (
        <div>
            Settings Component
        </div>
    );
};

export default Settings;