export default class ReactTableLikeRow {
  depth = 0;
  id = '984149e4-3a24-4d39-bf03-d575616dc0e6';
  columns = [];
  data = {};
  constructor(inputData = {}, columns = [], id) {
    this.data = inputData;
    this.columns = columns;
    this.id = id || this.id;
  }
  getVisibleCells() {
    return this.columns.map((columnDef) => {
      const value = this.data[columnDef.id];
      
      const info = {
        getValue() {
          return value;
        },
        row: this,
      }
      
      return ({
        id: columnDef.id,
        getContext() {
          return columnDef.cell(info);
        },
        column: {
          columnDef: {
            cell: columnDef.cell(info),
          },
        }
      })
    });
  }
  getIsSelected() {
    return false;
  }
  getIsExpanded() {
    return false;
  }
  getCanExpand() {
    return false;
  }
  getToggleSelectedHandler() {
    return function() {};
  }
}