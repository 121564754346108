import React, {useMemo} from 'react';
import {ReactComponent as VideoFormats} from "assets/images/VideoFormat.svg";
import {videoFormatCodeToReadable} from "helpers/videoFormats";
import {getCoreRowModel, getPaginationRowModel, useReactTable} from "@tanstack/react-table";
import Table from "components/Table";
import Card from "pages/StatisticsPage/components/Card";
import formatNumber from "helpers/formatNumber";
import MeterForFraction from "components/Meter/MeterForFraction";
import getTableValueFallback from "helpers/getTableValueFallback";
import PropTypes from "prop-types";

const columnIds = {
  format: 'format',
  minutes: 'minutes',
};

function TopFormats({
  stats,
  }) {
  const total = useMemo(() => stats.data.reduce((subTotal, item) => subTotal + item.duration, 0), [stats.data]);

  const columns = useMemo(() => [
    {
      id: columnIds.format,
      header: () => <span>Format</span>,
      accessorKey: 'format',
      meta: {
        colspan: 2,
      },
      enableSorting: false,
      cell: (info) => {
        const {
          name,
          duration
        } = info.row.original;
        
        return (
          <MeterForFraction
            title={ videoFormatCodeToReadable[name] || name}
            rawValue={ formatNumber(duration).toUpperCase() }
            fraction={duration}
            maxFraction={total}
            total={total}
          />
        )
      },
    },
    {
      id: columnIds.minutes,
      header: () => <span>Minutes</span>,
      accessorKey: 'duration',
      meta: {
        colspan: 0,
        align: 'right',
      },
      enableSorting: false,
      cell: (info) => <span>{ getTableValueFallback(info.getValue(), formatNumber)}</span>,
    },
  ], [total]);

  const table = useReactTable({
    data: stats.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination: {
        pageIndex: 0,
        pageSize: 5,
      },
    },
  });
  
  return (
    <Card title="Top Formats">
      <Table
        { ...stats }
        table={table}
        horizontalLinesInvisible
        noDataImage={<VideoFormats/>}
        noDataDescription="No data found"
        skeletonRowCount={5}
      />
    </Card>
  );
}

TopFormats.propTypes = {
  stats: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        duration: PropTypes.number.isRequired,
      })
    ),
  }),
};

export default TopFormats;