import React, { 
    useState, useEffect, 
    useCallback 
} from 'react';

import { useApi } from '../../api/useApi'

import { useUser } from '../../context/UserContext' 

import { validateFields } from '../../helpers/validation';

import { InputField } from '../../components/InputField'
import { Button } from '../../components/Button';

import styles from './index.module.css';

const Organization = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);

    const [organizationData, setOrganizationData] = useState({});
    const [organizationError, setOrganizationError] = useState(null);

    const { 
        getOrganizationData,
        updateOrganizationData 
    } = useApi();

    const { 
        fetchUserData
    } = useUser();     

    // Initialize credentials with organizationData if it exists or provide a fallback
    const [credentials, setCredentials] = useState({
        name: "",
        website: "",
        phone: "",   
        address: "",     
    });

    // New state to track changes
    const [changedFields, setChangedFields] = useState({});

    const [validationErrors, setValidationErrors] = useState({});

    const fetchOrganizationData = useCallback(async () => {
        try {
            setLoading(true);
            const { success, data, detail } = await getOrganizationData();
            if (success && data) {
                console.log("organization data: ", data);
                setOrganizationData(data);
                setOrganizationError(null);
            } else {
                setOrganizationError(detail || "Failed to get organization data");
                console.error(detail || "Failed to get organization data");
            }
        } catch (error) {
            setOrganizationError(error.message);
            console.error(error); // Here, replace with more advanced error handling if necessary
        } finally {
            setLoading(false); // Ensures loading is set to false regardless of the outcome
        }
    }, [getOrganizationData]); // Verify that `getOrganizationData` doesn't need additional dependencies
    
    

      useEffect(() => {
        fetchOrganizationData();
      }, [fetchOrganizationData]);


    // Update the local state when organizationData changes
    useEffect(() => {
        if (organizationData) {
            // console.log("call setCredentials")
            setCredentials({
                name: organizationData.name || '',
                website: organizationData.website || '',
                phone: organizationData.phone || '',
                address: organizationData.address || '',
            });
        }
    }, [organizationData]);      

    // const handleInputChange = (e) => {
    //     if (organizationError) {
    //         setOrganizationError(null);
    //     }

    //     const { name, value } = e.target;
    //     const newCredentials = { ...credentials, [name]: value };
    //     setCredentials(newCredentials);

    //     // Check if the field's new value is different from userData's value
    //     if (organizationData[name] !== value) {
    //         setChangedFields({ ...changedFields, [name]: value });
    //     } else {
    //         // If the field's value is reverted to the original, remove it from changedFields
    //         const updatedChangedFields = { ...changedFields };
    //         delete updatedChangedFields[name];
    //         setChangedFields(updatedChangedFields);
    //     }

    //     setValidationErrors(prevErrors => ({
    //         ...prevErrors,
    //         [name]: null
    //     }));
    // }; 

    const handleInputChange = (e) => {
        if (organizationError) {
            setOrganizationError(null);
        }
    
        const { name, value } = e.target;
        // Check if the value is an empty string and set it to null if true
        const newValue = value === '' ? '' : value;
    
        const newCredentials = { ...credentials, [name]: newValue };
        setCredentials(newCredentials);
    
        // Check if the field's new value is different from organizationData's value
        if (organizationData[name] !== newValue) {
            setChangedFields({ ...changedFields, [name]: newValue });
        } else {
            // If the field's value is reverted to the original, remove it from changedFields
            const updatedChangedFields = { ...changedFields };
            delete updatedChangedFields[name];
            setChangedFields(updatedChangedFields);
        }
    
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: null
        }));
    };
    

    const handleUpdateOrganization = async (e) => {
        e.preventDefault();
    
        // Clear previous errors
        if (organizationError) {
            setOrganizationError(null);
        }
    
        // Validate the fields that have changed
        //const fieldsToValidate = Object.keys(changedFields);
        // Only include fields that are not null in the fieldsToValidate array
        // all fields in this form are optional, so do validation only if there is some value
        const fieldsToValidate = Object.keys(changedFields).filter(key => changedFields[key] !== null);

        const { isValid, errors } = validateFields(changedFields, fieldsToValidate);
        
        // Update the state with any new validation errors
        setValidationErrors(errors);
    
        // Exit the function early if validation fails
        if (!isValid) {
            console.log("Validation errors", errors);
            return;
        }
    
        // Proceed with updating the organization
        setIsSubmitting(true);  // Indicate that submission is in progress
        try {
            const { success, data, detail } = await updateOrganizationData(changedFields);
    
            if (success && data) {
                console.log("Organization updated", data);
                setOrganizationData(data);
                setOrganizationError(null);
                setChangedFields({});

                // reload user data, user data has organization_name field that is used in Team settings
                fetchUserData();                
            } else {
                setOrganizationError(detail || "Failed to update organization");
                console.error(detail || "Failed to update organization");
            }
        } catch (error) {
            setOrganizationError(error.message || "An unexpected error occurred while updating");
            console.error(error);
        } finally {
            setIsSubmitting(false);  // Ensure submission state is always reset
        }
    };


    if (loading) {
        return <div>Loading...</div>; // Show a loading state
    }

    return (
        <div className={styles.formContainer}>
            <div className={styles.form}>
                <div className={styles.row}>
                    <InputField
                        inputText={credentials.name}
                        onChange={handleInputChange}
                        name="name"
                        placeholder=""
                        label="Organization name"
                        state={validationErrors.name ? 'error' : 'default'}
                        hint={validationErrors.name ? validationErrors.name : undefined}
                    />  
                </div>
                <div className={styles.row}>
                    <InputField
                        inputText={credentials.website}
                        onChange={handleInputChange}
                        name="website"
                        placeholder=""
                        label="Website"
                        state={validationErrors.website ? 'error' : 'default'}
                        hint={validationErrors.website ? validationErrors.website : undefined}
                    />  
                    <InputField
                        inputText={credentials.phone}
                        onChange={handleInputChange}
                        name="phone"
                        placeholder=""
                        label="Office phone"
                        type="tel"
                        state={validationErrors.phone ? 'error' : 'default'}
                        hint={validationErrors.phone ? validationErrors.phone : undefined}
                    /> 
                </div>
                <div className={styles.row}>
                    <InputField
                        inputText={credentials.address}
                        onChange={handleInputChange}
                        name="address"
                        placeholder=""
                        label="Address"
                        state={validationErrors.address ? 'error' : 'default'}
                        hint={validationErrors.address ? validationErrors.address : undefined}
                    />  
                </div>                

                <div className={styles.formFooter}>
                    <Button 
                        onClick={handleUpdateOrganization} 
                        className={styles.formFooterBtn} 
                        state={isSubmitting || Object.keys(changedFields).length === 0 ? "disabled" : undefined} 
                    >
                        Save Changes
                    </Button>                       
                </div>

                {organizationError && <div className={styles.errorMessage}>{organizationError}</div>}
            </div>
        </div>
    );
};

export default Organization;