import {useAuth} from "context/AuthContext";
import {useEffect, useState} from "react";

export default function useDataLoadingStatus(loadData, loadNow) {
  const { getToken } = useAuth();
  const [refreshCount, setRefreshCount] = useState(1);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadedSuccessfully, setLoadedSuccessfully] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [startedTimes, setStartedTimes] = useState(0);
  const [finishedTimes, setFinishedTimes] = useState(0);
  
  useEffect( () => {
    async function init() {
      const accessToken = await getToken();
      setLoading(true);
      setStartedTimes((i) => i + 1);
      const {
        success,
        data,
        data2,
        error,
      } = await loadData({
        accessToken,
      });
      setLoading(false);
      if (success) {
        setData(data);
        setData2(data2);
        setLoadedSuccessfully(true);
      } else {
        setLoadedSuccessfully(false);
        setErrorMessage(error);
      }
      setFinishedTimes(i => i + 1);
    }
    
    if (loadNow === undefined || loadNow) {
      init();
    }
  }, [refreshCount, getToken, loadData, loadNow]);

  return {
    data,
    setData,
    data2,
    errorMessage,
    loadedSuccessfully,
    loading,
    refresh: () => setRefreshCount((count) => count + 1),
    startedTimes,
    finishedTimes,
  };
}
