const environment = process.env.REACT_APP_ENV || 'qa'; // Default to 'qa' if not set

console.log("environment: ", environment)

const config = {
    dev: {
        protocol: 'https',
        apiSubdomain: 'api-dev',
        landingSubdomain: 'cloud-dev',
        accountApiSubdomain: 'account-api-dev',
        cdnApiSubdomain: 'api-cdn-dev',
        liveApiSubdomain: 'api-live-dev',
        s3Subdomain: 's3-dev',
        mediaStorageSubdomain: 'media-storage',
        domain: 'qencode.com',
        apiVersion: 'v1',
    },
    qa: {
        protocol: 'https',
        apiSubdomain: 'api-qa',
        landingSubdomain: 'cloud-qa',
        accountApiSubdomain: 'account-api-qa',
        cdnApiSubdomain: 'api-cdn-qa',
        liveApiSubdomain: 'api-live-qa',
        s3Subdomain: 's3-qa',
        mediaStorageSubdomain: 'media-storage',
        domain: 'qencode.com',
        apiVersion: 'v1',
    },
    qa1: {
        protocol: 'https',
        apiSubdomain: 'api-qa',
        landingSubdomain: 'cloud-qa',
        accountApiSubdomain: 'account-api-qa1',
        cdnApiSubdomain: 'api-cdn-qa',
        liveApiSubdomain: 'api-live-qa',
        s3Subdomain: 's3-qa',
        mediaStorageSubdomain: 'media-storage',
        domain: 'qencode.com',
        apiVersion: 'v1',
    },    
    qa2: {
        protocol: 'https',
        apiSubdomain: 'api-qa',
        landingSubdomain: 'cloud-qa',
        accountApiSubdomain: 'account-api-qa2',
        cdnApiSubdomain: 'api-cdn-qa',
        liveApiSubdomain: 'api-live-qa',
        s3Subdomain: 's3-qa',
        mediaStorageSubdomain: 'media-storage',
        domain: 'qencode.com',
        apiVersion: 'v1',
    },    
    stage: {
        protocol: 'https',
        apiSubdomain: 'api-stage',
        landingSubdomain: 'cloud-stage',
        accountApiSubdomain: 'account-api-stage',
        cdnApiSubdomain: 'api-cdn-stage',
        liveApiSubdomain: 'api-live-stage',
        s3Subdomain: 's3-stage',
        mediaStorageSubdomain: 'media-storage',
        domain: 'qencode.com',
        apiVersion: 'v1',
    },
    prod: {
        protocol: 'https',
        apiSubdomain: 'api',
        landingSubdomain: 'cloud',
        accountApiSubdomain: 'account-api',
        cdnApiSubdomain: 'api-cdn',
        liveApiSubdomain: 'api-live',
        s3Subdomain: 's3',
        mediaStorageSubdomain: 'media-storage',
        domain: 'qencode.com',
        apiVersion: 'v1',
    }
}[environment];

export default config;
