import PropTypes from "prop-types";
import React from "react";

export const EyeOpen = ({ className, color, label }) => {

  const style = color !== 'currentColor' ? { color } : undefined;

  return (  
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}       
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M14.1669 8.1227C13.1443 6.55554 11.1581 4.26923 8.49998 4.26923C5.84191 4.26923 3.85571 6.55554 2.83306 8.1227C2.68019 8.35692 2.68019 8.64308 2.83306 8.8773C3.85571 10.4445 5.84191 12.7308 8.49998 12.7308C11.1581 12.7308 13.1443 10.4445 14.1669 8.8773C14.3198 8.64308 14.3198 8.35692 14.1669 8.1227ZM1.82542 9.59086C1.39153 8.92595 1.39153 8.07405 1.82542 7.40914C2.87186 5.80553 5.17742 3 8.49998 3C11.8226 3 14.1281 5.80553 15.1746 7.40914C15.6085 8.07405 15.6085 8.92595 15.1746 9.59086C14.1281 11.1945 11.8226 14 8.49998 14C5.17742 14 2.87186 11.1945 1.82542 9.59086ZM7.28124 8.49992C7.28124 9.19258 7.82636 9.76915 8.5167 9.76915C9.20705 9.76915 9.75217 9.19258 9.75217 8.49992C9.75217 7.80726 9.20705 7.23069 8.5167 7.23069C7.82636 7.23069 7.28124 7.80726 7.28124 8.49992ZM8.5167 5.96146C9.8719 5.96146 10.9706 7.09793 10.9706 8.49992C10.9706 9.90191 9.8719 11.0384 8.5167 11.0384C7.16148 11.0384 6.06286 9.90191 6.06286 8.49992C6.06286 7.09793 7.16148 5.96146 8.5167 5.96146Z" fill="currentColor"/>
    </svg>
  );
};

EyeOpen.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

EyeOpen.defaultProps = {
  color: 'currentColor', // Or any default color
};