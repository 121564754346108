import React from 'react';
import PropTypes from 'prop-types';
import ValueRange from "components/FilterSidebar/ValueRange";

function DateRange({
   wrapperRef,
   start,
   end,
   setDate,
   onFocusStart,
   onFocusEnd,
}) {

  function getDateAndYear(e) {
    const [month, day, year] = e.target.value?.split(/\D/);
    const date = new Date(
      `${ year }-${month}-${day}`
    );
    return {
      date,
      year,
    };
  }
  
  function setStartDateText(e) {
    const {date} = getDateAndYear(e);
    if (e?.target?.value?.length === 10) {
      setDate(date);
    }
  }
  
  function setEndDateText(e) {
    const {date} = getDateAndYear(e);
    if (e?.target?.value?.length === 10) {
      setDate(undefined, date);
    }
  }
  
  const commonProps = {
    mask: '99/99/9999',
    maskChar: '',
    placeholder: 'mm/dd/yyyy',
  };

  return (
    <ValueRange
      wrapperRef={wrapperRef}
      commonProps={commonProps}
      startProps={{
        ref: start.ref,
        onChange: setStartDateText,
        onFocus: onFocusStart,
        'aria-invalid': start.invalid,
      }}
      endProps={{
        ref: end.ref,
        onChange: setEndDateText,
        onFocus: onFocusEnd,
        'aria-invalid': end.invalid,
      }}
    />
  );
}

DateRange.propTypes = {
  wrapperRef: PropTypes.shape({ current: PropTypes.any }),
  start: PropTypes.shape({
    ref: PropTypes.shape({ current: PropTypes.any }),
    invalid: PropTypes.bool,
  }),
  end: PropTypes.shape({
    ref: PropTypes.shape({ current: PropTypes.any }),
    invalid: PropTypes.bool,
  }),
  setDate: PropTypes.func.isRequired,
  onFocusStart: PropTypes.func.isRequired,
  onFocusEnd: PropTypes.func.isRequired,
};

export default DateRange;