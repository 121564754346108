import React from 'react';
import {DayPicker} from "react-day-picker";
import Caption from "components/Calendar/CustomCaption";
import './Calendar.css';

function Calendar(props) {
  return (
    <DayPicker
      mode="single"
      showOutsideDays
      { ...props }
      components={{
        Caption,
      }}
    />
  );
}

export default Calendar;