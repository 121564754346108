
import {Button} from "components/Button";
import {Delete} from "icons";
import {Modal} from "components/Modal";
import React, {useState} from "react";
import {ReactComponent as KeyWarning} from "assets/images/KeyWarning.svg";
import {deleteKey} from "api/qencode-content-delivery-api";
import PropTypes from "prop-types";
import {useAuth} from "context/AuthContext";
import ErrorMessage from "components/ErrorMessage";

function DeleteKeyModal({
  open,
  handleCancel,
  keyId,
  keyName,
  onSuccessfulFinish,
  }) {
  const { getToken } = useAuth();
  const [errorMessage, setErrorMessage] = useState();
  const [submitting, setSubmitting] = useState(false);
  async function handleConfirmDeletion() {
    const accessToken = await getToken();
    setSubmitting(true);
    const {success, error} = await deleteKey({
      accessToken,
      id: keyId,
    });
    if (success) {
      onSuccessfulFinish?.();
    } else {
      setErrorMessage(error);
    }
    setSubmitting(false);
  }


  return (
    <Modal
      isOpen={open}
      variant="popUp"
      onClose={handleCancel}
      header={
        <>
          <KeyWarning/>
          <h3>
            Delete this Signing Key!
          </h3>
          <p>
            Are you sure you want to delete  signing key<span>{ keyName === '-' ? '' : ` ${ keyName }` }</span>?
          </p>
        </>
      }
      footer={
        <>
          <Button
            type="secondary"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            icon={ <Delete/> }
            type='danger'
            onClick={handleConfirmDeletion}
            state={ submitting ? 'disabled' : 'default' }
          >
            Delete
          </Button>
        </>
      }
    >
      { errorMessage && <ErrorMessage>{ errorMessage }</ErrorMessage> }
    </Modal>
  );
}

DeleteKeyModal.propTypes = {
  open: PropTypes.bool,
  handleCancel: PropTypes.func,
  keyId: PropTypes.string,
  keyName: PropTypes.string,
  onSuccessfulFinish: PropTypes.func,
};

export default DeleteKeyModal;