import React from 'react';
import PropTypes from 'prop-types';

export function Stopped({ className, color, label }) {
    // Apply inline style only if color prop is not currentColor
    const style = color !== 'currentColor' ? { color } : undefined;

    return (
        <svg
            fill="none"
            viewBox="1045.4 274.37 11.2 11.27"
            className={className}
            role="img"
            aria-label={label}
            style={style}
            width="14"
            height="14"
            >
            <path
                d="m1048.58 279.12-.61-1.464a.4.4 0 0 0-.77.153v3.991c0 1.105.9 2 2 2h3.71c.16 0 .31-.106.37-.265a.394.394 0 0 0-.24-.512l-.59-.212a6.608 6.608 0 0 1-3.87-3.691Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1049 274.4c-1.99 0-3.6 1.612-3.6 3.6v4c0 1.988 1.61 3.6 3.6 3.6h4c1.99 0 3.6-1.612 3.6-3.6v-4c0-1.988-1.61-3.6-3.6-3.6h-4Zm-2.4 3.6c0-1.325 1.07-2.4 2.4-2.4h4c1.33 0 2.4 1.075 2.4 2.4v4c0 1.325-1.07 2.4-2.4 2.4h-4c-1.33 0-2.4-1.075-2.4-2.4v-4Z" fill="currentColor"/>
        </svg>
    );
}

Stopped.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.string,
};

Stopped.defaultProps = {
    color: 'currentColor', // Or any default color
};
