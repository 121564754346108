import React from "react";

/*
 * Function to skip pagination reset that occurs when table data is changed.
 * Taken from the official docs (see examples for Editable Data).
 */
export default function useSkipper() {
  const shouldSkipRef = React.useRef(true)
  const shouldSkip = shouldSkipRef.current
  
  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false
  }, []);
  
  React.useEffect(() => {
    shouldSkipRef.current = true
  })
  
  return [shouldSkip, skip]
}
