import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputField } from 'components/InputField';
import { FormCheckbox } from 'components/FormCheckbox';
import { Button } from 'components/Button';
import styles from '../../index.module.css'

export const FTPForm = ({ storageCredentials, onChange, onSave, onCancel }) => {
    const { ftp_endpoint, ftp_username, ftp_password, ftp_tls, ftp_is_passive } = storageCredentials.ftp;

    const [validationErrors, setValidationErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        onChange({
            target: {
                name: name,
                value: value
            }
        })

        const [, key] = name.split('.');

        // Clear validation errors when input changes
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [key]: null,
        }));
    };

    const handleSave = () => {
        const errors = {};

        if (!ftp_endpoint) {
            errors.ftp_endpoint = 'FTP endpoint is required';
        }
        if (!ftp_username) {
            errors.ftp_username = 'Username is required';
        }
        if (!ftp_password) {
            errors.ftp_password = 'Password is required';
        }

        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
        } else {
            onSave();
        }
    };

    // enable button when all input fields are set
    const isSaveDisabled = !ftp_endpoint || !ftp_username || !ftp_password;

    return (
        <div className={styles.storageFormContainer}>
            <div className={styles.formRow}>
                <InputField
                    inputText={ftp_endpoint}
                    onChange={handleInputChange}
                    name="ftp.ftp_endpoint"
                    placeholder="mydomain.com"
                    label="Endpoint"
                    state={validationErrors.ftp_endpoint ? 'error' : 'default'}
                    hint={validationErrors.ftp_endpoint || ''}
                />
            </div>

            <div className={styles.formRow}>
                <InputField
                    inputText={ftp_username}
                    onChange={handleInputChange}
                    name="ftp.ftp_username"
                    placeholder=""
                    label="Username"
                    autocomplete="off"
                    state={validationErrors.ftp_username ? 'error' : 'default'}
                    hint={validationErrors.ftp_username || ''}
                />
                <InputField
                    inputText={ftp_password}
                    onChange={handleInputChange}
                    name="ftp.ftp_password"
                    placeholder=""
                    label="Password"
                    type="password"
                    autocomplete="new-password"
                    state={validationErrors.ftp_password ? 'error' : 'default'}
                    hint={validationErrors.ftp_password || ''}
                />
            </div>

            <div className={styles.formRow}>
                <FormCheckbox
                    name="ftp.ftp_tls"
                    checked={ftp_tls || false}
                    label="Use TLS"
                    onChange={handleInputChange}
                />
                <FormCheckbox
                    name="ftp.ftp_is_passive"
                    checked={ftp_is_passive || false}
                    label="Use Passive Mode"
                    onChange={handleInputChange}
                />
            </div>

            <div className={styles.formFooter}>
                <Button 
                    size="sm"
                    type="secondary"
                    onClick={onCancel}
                >
                    Cancel
                </Button>                 
                <Button size="sm" onClick={handleSave} state={isSaveDisabled ? "disabled" : undefined}>
                    Save
                </Button>
            </div>
        </div>
    );
};

FTPForm.propTypes = {
    storageCredentials: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

