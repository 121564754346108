import PropTypes from "prop-types";
import React from "react";

export function Arrow2 ({ className, color, label }) {
  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg
      className={className}
      role="img"
      aria-label={label}
      style={style}
      width="8"
      height="9"
      viewBox="0 0 8 9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.87024 7.5V1"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
      />
      <path d="M6.93886 4H0.801605C0.0888808 4 -0.268054 4.86171 0.235918 5.36568L3.30455 8.43431C3.61697 8.74673 4.1235 8.74673 4.43592 8.43431L7.50455 5.36569C8.00852 4.86171 7.65159 4 6.93886 4Z" fill="currentColor"/>
    </svg>
  );
}

Arrow2.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Arrow2.defaultProps = {
  color: 'currentColor', // Or any default color
};
