import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ValueRange from "components/FilterSidebar/ValueRange";
import FilterSection from "components/FilterSidebar/FilterSection";
import useValueRange from "components/FilterSidebar/useValueRange";
import {clearTypes, FilterSectionProps} from "components/FilterSidebar/useFilter";

function NumberRangeFilter({
   columnId,
   title,
   columnToFilterExpanded,
   onSubmit,
   clearFilter,
   setNewDraft,
   defaultExpanded,
   placeholder1 = '',
   placeholder2 = '',
   lastClearType,
   getFilterValue,
   lastClearFilterId,
   applyFilterCount,
   columnToError,
   clearError,
   max,
}) {
  const filterValue = getFilterValue(columnId);
  const expandedFromActiveFilterTime = columnToFilterExpanded[columnId];
  const {
    startRef,
    endRef,
  } = useValueRange(columnId, lastClearFilterId, lastClearType);
  const [invalid, setInvalid] = useState(false);
  const [touched, setTouched] = useState(0);
  const visibleInvalid = (columnToError[columnId] || invalid) && applyFilterCount > touched;
  const commonProps = {
    mask: '999999999',
    maskChar: '',
    onSubmit,
    onChange,
    inputMode: 'numeric',
    'aria-invalid': visibleInvalid,
  };
  
  function onChange() {
    setTouched(applyFilterCount);
    let start = parseInt(startRef.current?.getInputValue(), 10);
    let end = parseInt(endRef.current?.getInputValue(), 10);
  
    const startSet = typeof start === 'number' && !Number.isNaN(start) ;
    const endSet = typeof end === 'number' && !Number.isNaN(end);
    setInvalid(false);
    clearError(columnId);

    if (startSet && endSet) {
      if (max) {
        start = start > max ? max : start;
        end = end > max ? max : end;
      }
      
      if (end >= start) {
        setNewDraft(columnId, [start, end]);
      } else {
        clearFilter(columnId, clearTypes.clearDraftFilter);
        setInvalid(true);
      }
    }
  }

  return (
    <FilterSection
      title={ title }
      expandedFromActiveFilterTime={expandedFromActiveFilterTime}
      selectedCount={ filterValue.length > 0 ? 1 : 0 }
      handleClear={() => clearFilter(columnId)}
      defaultExpanded={ defaultExpanded }
      error={columnToError[columnId]}
    >
    <ValueRange
      commonProps={commonProps}
      startProps={{
        ref: startRef,
        placeholder: placeholder1,
      }}
      endProps={{
        ref: endRef,
        placeholder: placeholder2,
      }}
    />
    </FilterSection>
  )
}

NumberRangeFilter.propTypes = {
  columnId: PropTypes.string,
  title: PropTypes.string,
  defaultExpanded: PropTypes.bool,
  placeholder: PropTypes.string,
  max: PropTypes.number,
  ...FilterSectionProps,
};

export default NumberRangeFilter;