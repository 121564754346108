import PropTypes from "prop-types";

export const transcodingTypeToColor = {
  "Audio": "#c43249",
  "Repack": "#6b00a3",
  "Thumbnails": "#3c69e0",
  "SD": "#082876",
  "HD": "#0f3896",
  "1440P": "#134fb2",
  "4K":  "#175fc7",
  "5K": "#1b72de",
  "6K": "#1e80f0",
  "7K": "#54a4f3",
  "8K": "#aed6fa",
  "Speech-to-Text": "#aed6fa",
  "Speech-to-Text(speed)": "#aed6fa",
  "Speech-to-Text(balanced)": "#426f96",
  "Speech-to-Text(accuracy)": "#2a4d6b",
};


export const regionToColor = {
  "usa": "#082876",
  "europe": "#0a308b",
  "asia": "#1641aa",
  "southamerica_std": "#1d4bbc",
  "anzac":  "#2054d2",
  "asia_india": "#2660ee",
  "latam":  "#467cff",
  "africa_std":  "#739cff",
}


export const regionCodeToColor = {
  'us-west': '#333',
  'eu-central': '#0a308b',
}

export const regionCodeToName = {
  'None': 'Transcoder → CDN',
  'us-west': 'US West',
  'eu-central': 'Europe',
  'usa': 'United States',
  'europe': 'Europe',
  'asia': 'Asia',
  'southamerica_std': 'South America',
  'anzac': 'Australia and New Zealand',
  'asia_india': 'Asia and India',
  'latam': 'Latin America',
  'africa_std': 'Africa',
  'asia_southkorea': 'South Korea',
}

export const summaryChartColors = {
  /* Please note BillingPanel uses duplicates of these colors (in rgb format). See css. */
  transcoding: '#316FEA',
  liveStreaming: '#8153EF',
  contentDelivery: '#FF6762',
  mediaStorage: '#EF3DD3',
};

export const transcodingLonglistToShortlist = {
  'None': '-',
  null: '-',
  undefined: '-',
  '': '-',
  'HD': 'HD',
  'HD(AV1)': 'HD',
  'HD(HEVC)': 'HD',
  'HD(VP9)': 'HD',
  'SD': 'SD',
  'SD(AV1)': 'SD',
  'SD(HEVC)': 'SD',
  'SD(VP9)': 'SD',
  'UHD': '1440P',
  'UHD 3k': '1440P',
  'UHD 3k(AV1)': '1440P',
  'UHD 3k(HEVC)': '1440P',
  'UHD 3k(VP9)': '1440P',
  'UHD 4k': '4K',
  'UHD 5k': '5K',
  'UHD 6k': '6K',
  'UHD 7k': '7K',
  'UHD 8k': '8K',
  'UHD 4k(AV1)': '4K',
  'UHD 4k(HEVC)': '4K',
  'UHD 4k(VP9)': '4K',
  'UHD 5k(AV1)': '5K',
  'UHD 5k(HEVC)': '5K',
  'UHD 5k(VP9)': '5K',
  'UHD 6k(AV1)': '6K',
  'UHD 6k(HEVC)': '6K',
  'UHD 6k(VP9)': '6K',
  'UHD 7k(AV1)': '7K',
  'UHD 7k(HEVC)': '7K',
  'UHD 7k(VP9)': '7K',
  'UHD 8k(AV1)': '8K',
  'UHD 8k(HEVC)': '8K',
  'UHD 8k(VP9)': '8K',
  'UHD(HEVC)': '1440P',
  'UHD(VP9)': '1440P',
  'UHD(AV1)': '1440P',
  'Audio': 'Audio',
  'Repack': 'Repack',
  'Thumbnails': 'Thumbnails',
  'speech_to_text_speed': 'Speech-to-Text (speed)',
  'speech_to_text_balanced': 'Speech-to-Text (balanced)',
  'speech_to_text_accuracy': 'Speech-to-Text (accuracy)',
};

export const streamingCodeToName = {
  'LIVE SD': 'SD',
  'LIVE HD': 'HD',
  'LIVE UHD 3k': '3K',
  'LIVE UHD 4k': '4K',
};

export const serviceCategoryIds = {
  transcoding: 'transcoding',
  liveStreaming: 'liveStreaming',
  contentDelivery: 'contentDelivery',
  mediaStorage: 'mediaStorage',
};

export const serviceCategoryIdToName = {
  [serviceCategoryIds.transcoding]: 'Transcoding',
  [serviceCategoryIds.liveStreaming]: 'Live Streaming',
  [serviceCategoryIds.contentDelivery]: 'Content Delivery',
  [serviceCategoryIds.mediaStorage]: 'Media Storage',
};

export const serviceCodeToName = {
  storage: 'Storage',
  live_streaming: 'Live streaming',
};

export const TranscodingType = PropTypes.shape({
  topResolution: PropTypes.shape({
    status: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          duration: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          percent: PropTypes.number.isRequired,
        })
      ).isRequired,
    }),
  }),
  stats: PropTypes.shape({
    status: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          amount: PropTypes.number.isRequired,
          date: PropTypes.string.isRequired,
          duration: PropTypes.number.isRequired,
          tariff_name: PropTypes.string.isRequired,
          tariff_price: PropTypes.number.isRequired,
        })
      ).isRequired,
    }),
  }),
  total: PropTypes.shape({
    status: PropTypes.shape({
      data: PropTypes.oneOfType(
        [
          PropTypes.array,
          PropTypes.shape({
            amount: PropTypes.number.isRequired,
            duration: PropTypes.number.isRequired,
            per_title_encoding: PropTypes.shape({
              percent: PropTypes.number.isRequired,
              size: PropTypes.number.isRequired,
            }),
          })
        ]
      ),
    }),
  }),
  totalCount: PropTypes.shape({
    status: PropTypes.shape({
      data: PropTypes.oneOfType(
        [
          PropTypes.array,
          PropTypes.shape({
            count: PropTypes.number.isRequired,
            delta: PropTypes.number.isRequired,
          })
        ]
      ),
    }),
  }),
  statsByProject: PropTypes.shape({
    status: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          amount: PropTypes.number.isRequired,
          duration: PropTypes.number,
          name: PropTypes.string.isRequired,
          source_duration: PropTypes.number.isRequired,
          tariffs: PropTypes.arrayOf(
            PropTypes.shape({
              amount: PropTypes.number.isRequired,
              duration: PropTypes.number,
              name: PropTypes.string.isRequired,
              source_duration: PropTypes.number,
              tariff_name: PropTypes.string.isRequired,
              task_count: PropTypes.number.isRequired,
            })
          )
        })
      ),
    }),
  }),
  topProject: PropTypes.shape({
    status: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          duration: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          percent: PropTypes.number.isRequired,
        })
      ),
    }),
  }),
  topCodec: PropTypes.shape({
    status: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          duration: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        })
      ),
    }),
  }),
  topFormat: PropTypes.shape({
    status: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          duration: PropTypes.number.isRequired,
        })
      ),
    }),
  }),
});