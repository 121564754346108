import PropTypes from "prop-types";
import React from "react";

export const Expand = ({ className, color, label }) => {

  const style = color !== 'currentColor' ? { color } : undefined;

  return (  
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}       
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M13.75 13.5C13.75 13.9142 14.0858 14.25 14.5 14.25C14.9142 14.25 15.25 13.9142 15.25 13.5L15.25 3.5C15.25 3.08579 14.9142 2.75 14.5 2.75C14.0858 2.75 13.75 3.08579 13.75 3.5L13.75 13.5ZM7.42017 5.47937L7.42017 7.99994L2.50008 7.99994C2.08587 7.99994 1.75008 8.33572 1.75008 8.74994C1.75008 9.16415 2.08587 9.49994 2.50008 9.49994L7.42017 9.49994L7.42017 12.0206C7.42017 12.7803 8.37722 13.1607 8.93696 12.6236L12.3451 9.35292C12.6921 9.01994 12.6921 8.48006 12.3451 8.14708L8.93696 4.87645C8.37723 4.3393 7.42017 4.71973 7.42017 5.47937Z" fill="currentColor"/>
    </svg>
  );
};

Expand.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Expand.defaultProps = {
  color: 'currentColor', // Or any default color
};