import React, {useState} from 'react';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import pageStyle from './index.module.css';
import CreateSigningKeyButton from "./CreateSigningKeyButton";
import {listSigningKeysWithDomains} from "api/qencode-content-delivery-api";
import useDataLoadingStatus from "hooks/useDataLoadingStatus";
import { ReactComponent as KeyImage } from "assets/images/Key1.svg";
import MoreActionsPopover from "pages/SigningKeysPage/MoreActionsPopover";
import Table from "components/Table";
import globalFilterFn from "components/Table/columnFilters/exactCaseInsensitiveFilter";
import DateFormatted from "components/DateFormatted";
import MaskedKey from "components/MaskedKey";
import Search from "components/Search";
import DeleteKeyModal from "pages/SigningKeysPage/DeleteKeyModal";
import RenameKeyModal from "pages/SigningKeysPage/RenameKeyModal";
import PrivateKeyModal from "pages/SigningKeysPage/PrivateKeyModal";
import Pagination from "components/Table/Pagination";
import CopyToClipboard from "components/CopyToClipboard";
import {modals} from "pages/SigningKeysPage/config";
import TableWrapper from "components/TableWrapper";


const columnIds = {
  'name': 'name',
  'signingKey': 'signingKey',
  'type': 'type',
  'keyEntity': 'keyEntity',
  'dateCreated': 'dateCreated',
  'moreActions': 'moreActions',
};

export default function SigningKeysPage() {
  const [openedModal, setOpenedModal] = useState();
  const [selectedSigningKey, setSelectedSigningKey] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  
  const loadingStatus = useDataLoadingStatus( listSigningKeysWithDomains );
  const {
    data,
    refresh,
  } = loadingStatus;
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);

  function handleOpenModal( modalName, signingKey ) {
    setOpenedModal( modalName );
    setSelectedSigningKey( signingKey );
  }

  function closeModalAndFinish() {
    setOpenedModal();
    setSelectedSigningKey();
    refresh();
  }

  function handleCloseModal(e) {
    e?.preventDefault();
    setOpenedModal();
  }
  
  function handleFinishCreatingKey( signingKey ) {
    setSelectedSigningKey( signingKey );
    setOpenedModal( modals.privateKey );
    refresh();
  }

  const columns = React.useMemo(
    () => [
      {
        id: columnIds.name,
        accessorKey: 'name',
        cell: info => (
          <div className="cellContainer">
            <span className={ pageStyle.cellLimited }>
              { info.getValue() }
            </span>
          </div>
        ),
        header: () => <span>Name</span>,
      },
      {
        id: columnIds.signingKey,
        accessorKey: 'id',
        cell: (info) => (
          <MaskedKey rawKey={ info.getValue() } withCopy={true} />
        ),
        header: () => <span>Signing Key Id</span>,
      },
      {
        id: columnIds.type,
        accessorKey: 'typeColumnValue',
        cell: (info) => {
          const value = info.getValue();
          return (
            <div className={ pageStyle.cellLimited }>{ value }</div>
          )
        },
        header: () => <span>Type</span>,
      },
      {
        id: columnIds.keyEntity,
        accessorKey: 'keyEntityColumnValue',
        cell: (info) => {
          const value = info.getValue();
          return (
            <div className={ pageStyle.keyEntityCell }>
              <div className={ pageStyle.cellLimited }>
                { value }
              </div>
              { value !== '-' && <CopyToClipboard text={value}/> }
            </div>
          );
        },
        header: () => <span>Key Entity</span>,
      },
      {
        id: columnIds.dateCreated,
        accessorKey: 'created',
        sortingFn: 'datetime',
        cell: info => <DateFormatted utcString={ info.getValue() }/>,
        header: () => <span>Date Created</span>
      },
      {
        id: columnIds.moreActions,
        enableSorting: false,
        cell: ({ row }) => (
          <MoreActionsPopover
            selectedSigningKey={ row.original }
            onSuccessfulFinish={ refresh }
            handleOpenModal={ handleOpenModal }
          />
        )
      }
    ],
    [refresh]
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    /* Sorting */
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    /* Filtering */
    globalFilterFn,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    
    state: {
      sorting,
      globalFilter,
      pagination,
    },
  });

  return (
    <TableWrapper>
      <div className={pageStyle.toolbar}>
        <Search setGlobalFilter={setGlobalFilter} globalFilter={globalFilter}/>
        <CreateSigningKeyButton onSuccessfulFinish={ handleFinishCreatingKey }/>
      </div>
      <Table
        { ...loadingStatus }
        table={table}
        minWidth={700}
        getEmptyComponent={() => <EmptyMessage handleFinishCreatingKey={ handleFinishCreatingKey }/>}
        columnIdToClassName={{
          [columnIds.moreActions]: pageStyle.moreActionsColumn,
        }}
      />
      <Pagination table={ table } />
      <DeleteKeyModal
        open={ openedModal === modals.deletion }
        handleCancel={ handleCloseModal }
        keyId={ selectedSigningKey?.id }
        keyName={ selectedSigningKey?.name }
        onSuccessfulFinish={ closeModalAndFinish }
      />
      <RenameKeyModal
        open={ openedModal === modals.rename }
        handleCancel={ handleCloseModal }
        keyId={ selectedSigningKey?.id }
        onSuccessfulFinish={ closeModalAndFinish }
      />
      <PrivateKeyModal
        open={ openedModal === modals.privateKey }
        privateKey={ selectedSigningKey?.[ 'private_key' ] }
        handleCancel={ handleCloseModal }
      />
    </TableWrapper>
  );
}

function EmptyMessage({ handleFinishCreatingKey }) {
  return (
    <div className="tableNodata">
      <div className="tableNodataImage">
        <KeyImage/>
      </div>
      <h3 className="tableNodataTitle">
        No Signing Keys created yet.
      </h3>
      <p className="tableNodataDescription">
        Start by creating a new signing key
      </p>
      <CreateSigningKeyButton onSuccessfulFinish={ handleFinishCreatingKey }/>
    </div>
  );
}
