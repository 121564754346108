import PropTypes from "prop-types";
import React from "react";

export const Clone = ({ className, color, label }) => {
  const style = color !== 'currentColor' ? { color } : undefined;
  
  return (
    <svg
      width="14"
      height="14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      aria-label={label}
      style={style}
      >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.23 2.14c.76 0 1.5.25 2.1.7A2.43 2.43 0 0 0 9.9.4H4.1a2.44 2.44 0 0 0-2.44 2.44c.61-.45 1.35-.7 2.11-.7h6.46ZM10 4.12a2.4 2.4 0 0 1 2.4 2.4v3.5a2.4 2.4 0 0 1-2.4 2.4H4a2.4 2.4 0 0 1-2.4-2.4v-3.5A2.4 2.4 0 0 1 4 4.12h6Zm3.6 2.4a3.6 3.6 0 0 0-3.6-3.6H4a3.6 3.6 0 0 0-3.6 3.6v3.5a3.6 3.6 0 0 0 3.6 3.6h6a3.6 3.6 0 0 0 3.6-3.6v-3.5ZM9 8.82a.6.6 0 1 0 0-1.2H7.6v-1.4a.6.6 0 0 0-1.2 0v1.4H5a.6.6 0 1 0 0 1.2h1.4v1.4a.6.6 0 1 0 1.2 0v-1.4H9Z"
        fill="currentColor"/>
    </svg>
  );
};

Clone.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Clone.defaultProps = {
  color: 'currentColor', // Or any default color
};