import PropTypes from "prop-types";
import React from "react";

export const Dot = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="3" height="3" viewBox="0 0 3 3" fill="none"
    >
      <circle cx="1.5" cy="1.5" r="1.5" fill="currentColor"/>
    </svg>    
  );
};

Dot.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Dot.defaultProps = {
  color: 'currentColor', // Or any default color
};