import PropTypes from "prop-types";
import React from "react";

export const SampleVideo = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M8.125 7.94906V12.052C8.125 12.2978 8.32427 12.4971 8.57008 12.4971C8.64651 12.4971 8.72166 12.4774 8.78828 12.44L12.4354 10.3885C12.6496 10.268 12.7256 9.99659 12.6051 9.78235C12.5651 9.7113 12.5064 9.6526 12.4354 9.61263L8.78828 7.56114C8.57404 7.44063 8.30267 7.51662 8.18216 7.73086C8.14468 7.79748 8.125 7.87263 8.125 7.94906Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.2586 3.39791C6.07316 3.21597 7.98696 3.125 10 3.125C12.013 3.125 13.9268 3.21597 15.7414 3.39791C17.1975 3.54446 18.3569 4.68204 18.5312 6.1351C18.6772 7.35661 18.75 8.64492 18.75 10C18.75 11.3551 18.6772 12.6434 18.5315 13.8649C18.3572 15.318 17.1975 16.4554 15.7414 16.602C13.9268 16.784 12.013 16.875 10 16.875C7.98696 16.875 6.07316 16.784 4.2586 16.6021C2.80248 16.4555 1.6431 15.318 1.46878 13.8649C1.32284 12.6434 1.25 11.3551 1.25 10C1.25 8.64492 1.32284 7.35661 1.46853 6.13507C1.64284 4.68201 2.80248 3.54446 4.2586 3.39791Z" stroke="currentColor" strokeWidth="1.2"/>
    </svg>    
  );
};

SampleVideo.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

SampleVideo.defaultProps = {
  color: 'currentColor', // Or any default color
};