export const columnIds = {
  name: 'name',
  region: 'region',
  size: 'size',
  items: 'items',
  dateCreated: 'dateCreated',
  moreActions: 'moreActions',
};


export const regionCodeToName = {
  'us-west': 'US West',
  'eu-central': 'Europe',
};

export const regionCodeToNameOptions = Object.entries(regionCodeToName).map(([ value, label ]) => ({
  label,
  value,
}));

export const modals = {
  create: 'create',
  edit: 'edit',
  delete: 'delete',
};
