import {Button} from "components/Button";
import {Modal} from "components/Modal";
import {ReactComponent as Folder} from "assets/images/Folder.svg";
import React from "react";
import {InputField} from "components/InputField";
import {createProject} from "api/qencode-account-api";
import PropTypes from "prop-types";
import useSingleFieldForm from "helpers/useSingleFieldForm";


function CreateProjectModal({
    open,
    handleCancel,
    onSuccessfulFinish,
  }) {
  const {
    newName,
    submitting,
    errorMessage,
    handleNameInput,
    clearFormAndClose,
    handleConfirmSubmit,
  } = useSingleFieldForm({
    handleCancel,
    onSuccessfulFinish,
    apiFunction: (({ ...rest }) => createProject({
      type: 'vod',
      ...rest,
    })) ,
  });

  return (
    <Modal
      isOpen={open}
      onClose={clearFormAndClose}
      variant="popUp"
      header={
        <>
          <Folder/>
          <h3>
            Create New Project
          </h3>
          <p>
            Choose a name for your Project
          </p>
        </>

      }
      footer={
        <>
          <Button
            onClick={clearFormAndClose}
            type="secondary"
            variant="modalFooter"
          >
            Cancel
          </Button>
          <Button
            variant="modalFooter"
            onClick={handleConfirmSubmit}
            state={ submitting ? "disabled" : "default" }
          >
            Add Project
          </Button>
        </>
      }
    >
       <form
         className="form"
         onSubmit={handleConfirmSubmit}
       >
         <div className="row">
           <InputField
             placeholder="Project name"
             onChange={ handleNameInput }
             inputText={ newName }
             name="new-project-name"
             state={ submitting ? "disabled" : ( errorMessage ? 'error' : 'default' ) }
             hint={ errorMessage }
           />
         </div>
       </form>
    </Modal>
  );
}

CreateProjectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  onSuccessfulFinish: PropTypes.func,
};

export default CreateProjectModal;
