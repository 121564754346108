import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.css';

function MiniTable({ headings, children }) {
  return (
    <table className={style.table}>
      <thead>
        <tr>
          { headings.map((columnTitle) => (
                <th
                  key={columnTitle}
                  className={style.headerCell}
                >{ columnTitle }
                </th>
              )
            )
          }
        </tr>
      </thead>
      <tbody>
        { children }
      </tbody>
    </table>
  );
}

MiniTable.propTypes = {
  headings: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default MiniTable;