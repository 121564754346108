import React from 'react';
import PropTypes from 'prop-types';
import Card from "pages/StatisticsPage/components/Card";
import TopTotal from "pages/StatisticsPage/components/TopTotal";
import style from './style.module.css';
import Heading from "pages/StatisticsPage/components/Heading";

function Player({
  playerTotals,
}) {
  return (
    <Card>
      <Heading
        title="Video Player"
        href={`?tab=player`}
      />
      <div className={style.wrapper}>
        <TopTotal
          title="Impressions"
          total={playerTotals?.data?.impressions}
          loading={playerTotals.loading}
        />
        <TopTotal
          title="Plays"
          total={playerTotals?.data?.plays}
          loading={playerTotals.loading}
        />
        <TopTotal
          title="Minutes Played"
          total={playerTotals?.data?.minutes_played}
          loading={playerTotals.loading}
          fixedPrecision={2}
        />
      </div>
    </Card>
  );
}

Player.propTypes = {
  playerTotals: PropTypes.object.isRequired,
};

export default Player;