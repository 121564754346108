import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import style from './style.module.css';
import TranscodingTopResolutions from "pages/StatisticsPage/TranscodingTab/TopResolutions";
import Controls from "components/Controls";
import StreamsTopResolutions from 'pages/StatisticsPage/LiveStreamsTab/TopResolutions';
import TopCdn from './TopCdn';
import TopStorage from './TopStorage';
import Player from './Player';
import Columns from "components/Columns";
import Card from "pages/StatisticsPage/components/Card";
import TopTotal from "pages/StatisticsPage/components/TopTotal";
import Heading from "pages/StatisticsPage/components/Heading";
import OptionList from "components/OptionList";
import Chart from "pages/StatisticsPage/components/Chart";
import {ReactComponent as TranscodingImage} from 'assets/images/Transcoding2.svg';
import {ReactComponent as StreamingImage} from 'assets/images/Streaming.svg';
import {
  summaryChartColors,
  serviceCategoryIds as checkboxTypes,
  serviceCategoryIdToName
} from 'pages/StatisticsPage/config';
import AllTooltipContent from "pages/StatisticsPage/AllTab/AllTooltipContent";
import BriefItem from "pages/StatisticsPage/components/BriefPanel/BriefItem";
import NoChartData from "pages/StatisticsPage/components/Chart/NoData";


const checkboxTypesArray = Object.values(checkboxTypes);
const emptyDayTemplate = Object.fromEntries(checkboxTypesArray.map(i => [i, 0]));


function AllTab({
  datepicker,
  transcoding,
  streaming,
  storage,
  cdn,
  player,
}) {
  const anythingStillLoading = transcoding.topResolution.status.loading
    || streaming.topResolution.status.loading
    || storage.topBucket.status.loading
    || cdn.topRegion.status.loading
    || player.total.status.loading;

  const transcodingAmount = transcoding.total.status.data?.amount || 0;
  const streamingAmount = streaming.total.status.data?.amount || 0;
  const storageAmount = storage.total.status?.data?.total_cost?.value || 0;
  const cdnAmount = cdn.total.status?.data?.ls?.amount + cdn.total.status?.data?.ms?.amount;

  const {
    chartData,
    quantity,
    checkboxTypeToSum,
  } = useMemo(() => {
    const checkboxTypeToSum = {};
    const datesEntries = {
      amount: Object.fromEntries(datepicker.dates.map((date) => [date, {
        date,
        ...emptyDayTemplate,
      } ])),
      quantity: Object.fromEntries(datepicker.dates.map((date) => [date, {
        date,
        ...emptyDayTemplate,
      } ])),
    }
    const cdnData = [
      ...(cdn.stats.status.data.ls || []),
      ...(cdn.stats.status.data.ms || []),
    ];
    
    for (let {date, amount, storage_gb_month} of storage.stats.status.data) {
      datesEntries.amount[date] = datesEntries.amount[date] || {
        date,
        ...emptyDayTemplate,
      };
      datesEntries.quantity[date] = datesEntries.quantity[date] || {
        date,
        ...emptyDayTemplate,
      };
      datesEntries.amount[date][checkboxTypes.mediaStorage] += amount || 0;
      checkboxTypeToSum[checkboxTypes.mediaStorage] = (checkboxTypeToSum[checkboxTypes.mediaStorage] || 0) + amount || 0;
      datesEntries.quantity[date][checkboxTypes.mediaStorage] += storage_gb_month || 0;
    }

    for (let {date, amount, duration} of streaming.stats.status.data) {
      datesEntries.amount[date] = datesEntries.amount[date] || {
        date,
        ...emptyDayTemplate,
      };
      datesEntries.quantity[date] = datesEntries.quantity[date] || {
        date,
        ...emptyDayTemplate,
      };
      datesEntries.amount[date][checkboxTypes.liveStreaming] += amount || 0;
      checkboxTypeToSum[checkboxTypes.liveStreaming] = (checkboxTypeToSum[checkboxTypes.liveStreaming] || 0) + amount || 0;
      datesEntries.quantity[date][checkboxTypes.liveStreaming] += duration || 0;
    }

    for (let {date, amount, duration} of transcoding.stats.status.data) {
      datesEntries.amount[date] = datesEntries.amount[date] || {
        date,
        ...emptyDayTemplate,
      };
      datesEntries.quantity[date] = datesEntries.quantity[date] || {
        date,
        ...emptyDayTemplate,
      };
      datesEntries.amount[date][checkboxTypes.transcoding] += amount || 0;
      checkboxTypeToSum[checkboxTypes.transcoding] = (checkboxTypeToSum[checkboxTypes.transcoding] || 0) + amount || 0;
      datesEntries.quantity[date][checkboxTypes.transcoding] += duration || 0;
    }

    for (let {date, amount, bandwidth} of cdnData) {
      datesEntries.amount[date] = datesEntries.amount[date] || {
        date,
        ...emptyDayTemplate,
      };
      datesEntries.quantity[date] = datesEntries.quantity[date] || {
        date,
        ...emptyDayTemplate,
      };
      datesEntries.amount[date][checkboxTypes.contentDelivery] += amount || 0;
      checkboxTypeToSum[checkboxTypes.contentDelivery] = (checkboxTypeToSum[checkboxTypes.contentDelivery] || 0) + amount || 0;
      datesEntries.quantity[date][checkboxTypes.contentDelivery] += bandwidth || 0;
    }

    return {
      chartData: Object.values(datesEntries.amount),
      quantity: datesEntries.quantity,
      checkboxTypeToSum,
    };
  }, [
    cdn.stats.status.data,
    storage.stats.status.data,
    streaming.stats.status.data,
    transcoding.stats.status.data,
    datepicker.dates,
  ]);
  
  const [options, setOptions] = useState([]);
  const checkedArray = useMemo(() => options.filter((i) => i.checked).map(i => i.value), [options]) ;
  
  useEffect(() => {
    const initialOptions = [];
    const cdnData = [
      ...(cdn.stats.status.data.ls || []),
      ...(cdn.stats.status.data.ms || []),
    ];
    
    if (transcoding.stats.status.data.length) {
      initialOptions.push({
        label: serviceCategoryIdToName[checkboxTypes.transcoding],
        value: checkboxTypes.transcoding,
        checked: true,
      });
    }
    
    if (streaming.stats.status.data.length) {
      initialOptions.push({
        label: serviceCategoryIdToName[checkboxTypes.liveStreaming],
        value: checkboxTypes.liveStreaming,
        checked: true,
      });
    }
    
    if (storage.stats.status.data.length) {
      initialOptions.push({
        label: serviceCategoryIdToName[checkboxTypes.mediaStorage],
        value: checkboxTypes.mediaStorage,
        checked: true,
      });
    }
    
    if (cdnData.length) {
      initialOptions.push({
        label: serviceCategoryIdToName[checkboxTypes.contentDelivery],
        value: checkboxTypes.contentDelivery,
        checked: true,
      });
    }
    setOptions(initialOptions);
  }, [
    cdn.stats.status.data.ls,
    cdn.stats.status.data.ms,
    storage.stats.status.data,
    streaming.stats.status.data,
    transcoding.stats.status.data,
  ]);
  
  const noDataInSelected = useMemo(() => {
    const sum = checkedArray.reduce((acc, checkedId) => acc + (checkboxTypeToSum[checkedId] || 0), 0);
    return typeof sum === 'number' && Number.isFinite(sum) && sum === 0;
  }, [checkedArray, checkboxTypeToSum]);

  return (
    <div>
      <div className={style.chartWrapper}>
        <div className={style.chartLeft}>
          <Controls
            title="Cost breakdown"
            rightContent={
              <OptionList
                options={options}
                setOptions={setOptions}
                skeleton={anythingStillLoading}
                horizontal={true}
              />
            }
          />
          <Chart
            chartData={chartData}
            quantity={quantity}
            loading={ anythingStillLoading }
            type="narrow"
            dataKeys={checkedArray}
            keyToColor={summaryChartColors}
            legend="USD"
            contentComponent={AllTooltipContent}
          >
            {
              noDataInSelected ? (
                <NoChartData
                  startDate={datepicker.startDate}
                  endDate={datepicker.endDate}
                />
              ) : null
            }
          </Chart>
        </div>
      </div>
      <dl>
        <Columns minWidth={128}>
          <BriefItem
            heading="Transcoding"
            type='money'
            valueAppearance='medium'
            value={transcodingAmount}
            loading={transcoding.total.status.loading}
          />
          <BriefItem
            heading="Live Streaming"
            type='money'
            valueAppearance='medium'
            value={streamingAmount}
            loading={streaming.total.status.loading}
          />
          <BriefItem
            heading="Storage"
            type='money'
            valueAppearance='medium'
            value={storageAmount}
            loading={storage.total.status.loading}
          />
          <BriefItem
            heading="CDN"
            type='money'
            valueAppearance='medium'
            value={cdnAmount}
            loading={cdn.total.status.loading}
          />
          <BriefItem
            heading="Total Usage"
            type='money'
            valueAppearance='medium'
            value={ transcodingAmount + streamingAmount + storageAmount + cdnAmount }
            loading={
              transcoding.total.status.loading
              || streaming.total.status.loading
              || storage.total.status.loading
              || cdn.total.status.loading
            }
          />
        </Columns>
      </dl>
      <Columns minWidth={250}>
        <Card>
          <Heading
            title="Transcoding"
            href="?tab=transcoding"
          />
          <TopTotal
            title="Total Minutes"
            total={ Math.ceil(transcoding.total.status.data?.duration * 100) / 100 }
            loading={transcoding.total.status.loading}
          />
          <TranscodingTopResolutions
            stats={transcoding.topResolution.status}
            noDataImage={ <TranscodingImage/> }
          />
        </Card>
        <Card>
          <Heading
            title="Live Streaming"
            href="?tab=live-streams"
          />
          <TopTotal
            title="Total Minutes"
            total={ Math.ceil(streaming.total.status.data.duration * 100) / 100 }
            loading={streaming.total.status.loading}
          />
          <StreamsTopResolutions
            stats={streaming.topResolution.status}
            noDataImage={ <StreamingImage/> }
          />
        </Card>
        <TopStorage
          stats={storage.topBucket.status}
          total={ storage.total.status?.data?.total_storage?.value || 0 }
        />
        <TopCdn
          stats={cdn.topRegion.status}
          totalValue={ cdn.total.status.data?.ls?.bandwidth + cdn.total.status.data?.ms?.bandwidth }
          loading={ cdn.topRegion.status.loading || cdn.total.status.loading }
        />
        <Player
          playerTotals={player.total.status}
        />
      </Columns>
    </div>
  )
}

AllTab.propTypes = {
  datepicker: PropTypes.object.isRequired,
};

export default AllTab;