import PropTypes from "prop-types";
import React from "react";

export const Azure = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  // const style = color !== 'currentColor' ? { color } : undefined;
  const style = color !== 'currentColor' ? { color } : { color: '#0089D6' };

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"
    >
      <path d="M12.0337 13.9513C12.0151 13.9555 10.3915 14.261 8.42582 14.6302L4.85183 15.3015L10.7497 15.3023L16.6477 15.3031L16.6235 15.2582L13.4442 9.39914C11.5907 5.98351 10.2866 3.58968 10.283 3.59636C10.2723 3.61632 8.35599 9.22698 8.35599 9.23857C8.35599 9.24468 9.19111 10.3058 10.2118 11.5967L12.0676 13.9437L12.0337 13.9513Z" fill="currentColor"/>
      <path d="M3.29088 10.328C2.22211 12.2973 1.34766 13.9112 1.34766 13.9145C1.34766 13.9178 2.13796 13.9195 3.10389 13.9182L4.86013 13.9158L7.2753 8.40845L9.73433 2.80061C9.75846 2.74536 9.77484 2.70151 9.77074 2.70317C9.76664 2.70483 8.74422 3.61547 7.49869 4.7268L5.23409 6.74741L3.29088 10.328Z" fill="currentColor"/>
    </svg>    
  );
};

Azure.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Azure.defaultProps = {
  color: 'currentColor', // Or any default color
};