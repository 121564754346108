import React, { useEffect, useRef, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styles from './Modal.module.css';

import { Warning } from '../../icons'

export const Modal = ({ isOpen, onClose, children, header, footer, icon, alert, variant, className  }) => {

  const variantClassName = styles[variant];

  const getIconComponent = (icon) => {
    return React.cloneElement(icon, { className: styles.icon });
  };

  const modalRef = useRef();
  const [active, setActive] = useState(false);

  const getScrollbarWidth = () => window.innerWidth - document.documentElement.clientWidth;

  useEffect(() => {

    const scrollbarWidth = getScrollbarWidth();
    const originalStyle = window.getComputedStyle(document.body).overflow;

    // When the modal is first rendered and isOpen is true, activate the transitions
    if (isOpen) {
      requestAnimationFrame(() => {
        setActive(true);
        // Add overflow: hidden; to body to disable scrolling
        document.body.style.overflow = 'hidden';
        if (scrollbarWidth > 0) {
          document.body.style.paddingRight = `${scrollbarWidth}px`;
        }
      });
    }

    return () => {
      document.body.style.overflow = originalStyle;
      if (scrollbarWidth > 0) {
        document.body.style.paddingRight = '';
      }
    };
  }, [isOpen]);

  const handleClose = useCallback(
    (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClose);
    return () => {
      document.removeEventListener('mousedown', handleClose);
      // Remove overflow hidden from body when modal closes or component unmounts
      //document.body.style.overflow = 'auto';
    };
  }, [handleClose]);

  const handleTransitionEnd = () => {
    // Deactivate the modal after the hide animation
    if (!isOpen) {
      setActive(false);
      // Remove overflow hidden from body when animation ends
      document.body.style.overflow = 'auto';
    }
  };

  useEffect(() => {
    if (!isOpen && active) {
      // Start the hide animation
      setActive(false);
    }
  }, [isOpen, active]);

  if (!isOpen && !active) return null;

  return ReactDOM.createPortal(
    <div className={`${styles.overlay}  ${active ? styles.active : ''} ${variantClassName || ''}`}>
      <div
        className={`${styles.modal} ${className || ''} ${active ? styles.active : ''}`}
        ref={modalRef}
        onTransitionEnd={handleTransitionEnd}
      >
        { icon && getIconComponent(icon)}
        { header &&
          <div className={styles.modalHeader}>
            { header }
            { variant === "fullScreen" &&
              <button onClick={onClose} className={styles.closeButton}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                  <path d="M8.27246 19.727L19.727 8.27246" stroke="#67717B" strokeWidth="2" strokeLinecap="round"/>
                  <path d="M8.27246 8.27246L19.727 19.727" stroke="#67717B" strokeWidth="2" strokeLinecap="round"/>
                </svg>
              </button>
            }
          </div> }
        { alert &&
          <div className={styles.modalAlert}>
            <Warning className={styles.alertIcon} />
            <div className={styles.alertText}>{alert}</div>
          </div>
        }
        { children &&
          <div className={styles.modalBody}>
            {children}
          </div>
        }
        { footer && <div className={styles.modalFooter}>{footer}</div>}
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  header: PropTypes.node,
  footer: PropTypes.node,
  icon: PropTypes.element,
  variant: PropTypes.string,
  className: PropTypes.string,
  onClose: PropTypes.func
};

Modal.defaultProps = {
  isOpen: false,
  header: null,
  footer: null,
  icon: null,
  variant: null,
  className: null,
  onClose: () => {}
};






// import React, { useEffect, useRef, useCallback } from 'react';
// import ReactDOM from 'react-dom';
// import styles from './Modal.module.css';

// export const Modal = ({ isOpen, onClose, children }) => {
//   const modalRef = useRef();

//   // Use useCallback to memoize the function so its reference doesn't change unless modalRef or onClose changes
//   const handleClose = useCallback(
//     (event) => {
//       if (modalRef.current && !modalRef.current.contains(event.target)) {
//         onClose();
//       }
//     },
//     [onClose]
//   );

//   useEffect(() => {
//     // Attach the listeners on component mount.
//     document.addEventListener('mousedown', handleClose);
//     // Detach the listeners on component unmount.
//     return () => {
//       document.removeEventListener('mousedown', handleClose);
//     };
//   }, [handleClose]); // The dependency array now includes the memoized handleClose function

//   if (!isOpen) return null;

//   return ReactDOM.createPortal(
//     <div className={styles.overlay}>
//       <div className={styles.modal} ref={modalRef}>
//         <button onClick={onClose} className={styles.closeButton}>
//           &times;
//         </button>
//         {children}
//       </div>
//     </div>,
//     document.getElementById('modal-root')
//   );
// };




