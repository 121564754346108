import PropTypes from "prop-types";
import React from "react";

export const QencodeStorage = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M5.89467 1.5H10.1053C11.5967 1.5 12.8966 2.51497 13.2583 3.96176L14.3337 8.26318C14.4441 8.7051 14.5 9.15892 14.5 9.61445V11.7143C14.5 13.2528 13.2528 14.5 11.7143 14.5H4.28571C2.74721 14.5 1.5 13.2528 1.5 11.7143V9.61445C1.5 9.15892 1.55587 8.7051 1.66635 8.26318L2.7417 3.96176C3.1034 2.51497 4.40335 1.5 5.89467 1.5Z" stroke="currentColor" strokeWidth="1.2"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6 7.02291C6.39226 6.74519 6.84313 6.60633 7.36149 6.60633C8.13902 6.60633 8.40756 6.89457 8.85491 6.90496C9.15345 6.91189 9.39167 6.86505 9.56774 6.76445L10 7.17444C9.68449 7.43856 9.25026 7.57617 8.7046 7.58725C7.88482 7.60388 7.55 7.29142 7.08072 7.29142C6.76786 7.29142 6.52358 7.34009 6.34647 7.43742L6 7.02291ZM7.98544 3.33789C8.94872 3.33789 9.81423 3.99302 9.9186 4.80116C10.023 5.6093 9.15747 6.26443 7.98544 6.26443C6.81341 6.26443 5.9479 5.6093 6.05227 4.80116C6.15665 3.99302 7.02215 3.33789 7.98544 3.33789ZM7.98544 4.06953C7.4777 4.06953 7.04495 4.39709 7.01886 4.80116C6.99276 5.20523 7.42552 5.5328 7.98544 5.5328C8.54536 5.5328 8.97811 5.20523 8.95202 4.80116C8.92593 4.39709 8.49317 4.06953 7.98544 4.06953Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M11.6211 8.92969C12.3903 8.92969 13.0139 9.55329 13.0139 10.3225V11.6225C13.0139 12.3918 12.3903 13.0154 11.6211 13.0154H4.37821C3.60896 13.0154 2.98535 12.3918 2.98535 11.6225V10.3225C2.98535 9.55329 3.60896 8.92969 4.37821 8.92969H11.6211ZM11.0175 10.3225C10.6329 10.3225 10.3211 10.6343 10.3211 11.019C10.3211 11.4036 10.6329 11.7154 11.0175 11.7154C11.4021 11.7154 11.7139 11.4036 11.7139 11.019C11.7139 10.6343 11.4021 10.3225 11.0175 10.3225ZM7.99964 10.6011H5.21392C4.9575 10.6011 4.74964 10.809 4.74964 11.0654C4.74964 11.3218 4.9575 11.5297 5.21392 11.5297H7.99964C8.25605 11.5297 8.46392 11.3218 8.46392 11.0654C8.46392 10.809 8.25605 10.6011 7.99964 10.6011Z" fill="currentColor"/>
    </svg>    
  );
};

QencodeStorage.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

QencodeStorage.defaultProps = {
  color: 'currentColor', // Or any default color
};