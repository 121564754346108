export const modals = {
    'rename': 'rename',
    'delete': 'delete',
    'create': 'create',
};

export const columnIds = {
    'streamId': 'streamId',
    'streamName': 'streamName',
    'playbackId': 'playbackId',
    'project': 'project',
    'dateCreated': 'dateCreated',
    'status': 'status',
    'streamKey': 'streamKey',
    'moreActions': 'moreActions',
};

export const columnIdToReadableName = {
    [columnIds.project]: 'Project',
    [columnIds.dateCreated]: 'Date Created',
    [columnIds.status]: 'Status',
};

export const streamStatusEnum = {
  'live': 'live',
  'idle': 'idle',
  'launching': 'launching',
  'stopped': 'stopped',
  'created': 'created',
  'starting': 'starting',
  'waiting': 'waiting',
  'stopping': 'stopping',
};

export const streamStatusToReadable = {
  [ streamStatusEnum.live ]: 'Live',
  [ streamStatusEnum.idle ]: 'Idle',
  [ streamStatusEnum.launching ]: 'Launching',
  [ streamStatusEnum.stopped ]: 'Stopped',
  [ streamStatusEnum.created ]: 'Created',
  [ streamStatusEnum.starting ]: 'Starting',
  [ streamStatusEnum.waiting ]: 'Waiting',
  [ streamStatusEnum.stopping ]: 'Stopping',
};

export const streamStatusTypicalOrder = [
  [streamStatusEnum.created],
  [streamStatusEnum.starting],
  [streamStatusEnum.waiting],
  [streamStatusEnum.launching],
  [streamStatusEnum.live],
  [streamStatusEnum.idle],
  [streamStatusEnum.stopping],
  [streamStatusEnum.stopped],
];
