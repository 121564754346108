import {useCallback, useEffect, useState} from 'react';
import {useAuth} from "context/AuthContext";
import {deleteCorsHeader, getCorsPolicy, setCorsPolicy} from "api/qencode-content-delivery-api";
import {httpHeaders} from "pages/MediaStorage/config";


export default function useCorsSection(bucket, enableCdnData, open) {
  const {
    enableCdnCounter,
    enableCdn,
    enableCdnInitial,
  } = enableCdnData;
  const { getToken } = useAuth();
  const [corsScreenActive, setCorsScreenActive] = useState(false);
  
  const [initialData, setInitialData] = useState({});
  const [submitting, setSubmitting] = useState(false);
  /* Main data */
  const [originList, setOriginList] = useState([{
    id: 1,
    value: '',
  }]);
  const [allowedMethods, setAllowedMethods] = useState([]);
  const [allowCredentials, setAllowCredentials] = useState(false);
  const [maxAge, setMaxAge] = useState();
  /* Errors */
  const [error, setError] = useState();
  const [maxAgeError, setMaxAgeError] = useState('');
  
  const checkMethod = useCallback(function checkMethod(method) {
    if (allowedMethods.includes(method)) {
      return setAllowedMethods( allowedMethods.filter(checkedMethod => checkedMethod !== method) );
    } else {
      setAllowedMethods( [...allowedMethods, method] );
    }
  }, [allowedMethods]);
  
  const getInitialValue = useCallback(function getInitialValue(headerName) {
    if (headerName === httpHeaders.credentials) {
      return initialData[ httpHeaders.credentials ] === 'true';
    }
    else if (headerName === httpHeaders.allowMethods) {
      return initialData[httpHeaders.allowMethods] || [];
    }
    else if (headerName === httpHeaders.maxAge) {
      return initialData[ httpHeaders.maxAge ] ?? '';
    }
    else if (headerName === httpHeaders.allowOrigin) {
      return initialData[ httpHeaders.allowOrigin ] || [];
    }
  }, [initialData]);
  
  const handleSubmit = useCallback(async (bucket) => {
    const accessToken = await getToken();
    const newCorsPolicies = {};
    const requestPromises = [];
    const initialOrigins = getInitialValue(httpHeaders.allowOrigin);
    const originListNonEmpty = originList.filter((origin) => Boolean(origin.value));
    const initialListValues = new Set(initialOrigins.map((origin) => origin.value).filter(i => i));
    const originListNonEmptyValues = new Set(originListNonEmpty.map((origin) => origin.value));
    const originsSame = initialListValues.isSubsetOf(originListNonEmptyValues) && originListNonEmptyValues.isSubsetOf(initialListValues);
    
    if (!originsSame) {
      if (originListNonEmptyValues.size === 0) {
        requestPromises.push(
          deleteCorsHeader({
            accessToken,
            bucketName: bucket.name,
            headerName: httpHeaders.allowOrigin,
          })
        );
      } else {
        newCorsPolicies[ httpHeaders.allowOrigin ] = Array.from(originListNonEmptyValues).join(',');
      }
    }
    
    const initialMethods = new Set( getInitialValue(httpHeaders.allowMethods) );
    const allowedMethodsSet = new Set(allowedMethods);
    const methodsSame = initialMethods.isSubsetOf(allowedMethodsSet) && allowedMethodsSet.isSubsetOf(initialMethods);
    if (!methodsSame) {
      if (allowedMethodsSet.size === 0) {
        requestPromises.push(
          deleteCorsHeader({
            accessToken,
            bucketName: bucket.name,
            headerName: httpHeaders.allowMethods,
          })
        );
      } else {
        newCorsPolicies[ httpHeaders.allowMethods ] = Array.from(allowedMethodsSet).join(',');
      }
    }
    
    const initialAllowCredentials = getInitialValue(httpHeaders.credentials);
    if ( String(initialAllowCredentials) !== String(allowCredentials)) {
      if (allowCredentials) {
        newCorsPolicies[ httpHeaders.credentials ] = allowCredentials ? 'true' : 'false';
      } else {
        requestPromises.push(
          deleteCorsHeader({
            accessToken,
            bucketName: bucket.name,
            headerName: httpHeaders.credentials,
          })
        );
      }
    }
    
    const initialMaxAge = getInitialValue(httpHeaders.maxAge);
    const maxAgeTrimmed = (maxAge || '').trim();
    if (String( (initialMaxAge) ) !== String(maxAgeTrimmed)) {
      if (maxAgeTrimmed.length === 0) {
        requestPromises.push(
          deleteCorsHeader({
            accessToken,
            bucketName: bucket.name,
            headerName: httpHeaders.maxAge,
          })
        )
      } else {
        const maxAgeNumber = parseInt(maxAgeTrimmed);
        if (typeof maxAgeNumber === 'number' && Number.isFinite(maxAgeNumber)) {
          newCorsPolicies[ httpHeaders.maxAge ] = maxAgeNumber;
        } else {
          setMaxAgeError('Please enter valid number');
        }
      }
    }

    if (Object.keys(newCorsPolicies).length > 0) {
      requestPromises.push(
        setCorsPolicy({
          accessToken,
          bucketName: bucket.name,
          body: newCorsPolicies,
        })
      );
    }
    
    if (requestPromises.length) {
      setSubmitting(true);
      await Promise.allSettled(requestPromises);
      setSubmitting(false);
      return true;
    }
    return false;
  }, [getToken, getInitialValue, originList, allowedMethods, allowCredentials, maxAge]);
  
  const goToBucketSettings = useCallback(function goBack() {
    setCorsScreenActive(false);
  }, []);
  
  const goToCors = useCallback(function goToCors() {
    setCorsScreenActive(true);
  }, []);
  
  useEffect(() => {
    async function load() {
      const accessToken = await getToken();
      
      const response = await getCorsPolicy({
        accessToken,
        bucketName: bucket.name,
      });
      if (response.success) {
        setInitialData(response.data);
        const allowOrigin = response.data[httpHeaders.allowOrigin];
        if (Array.isArray( allowOrigin ) && allowOrigin.length > 0) {
          setOriginList(allowOrigin);
        }
        else {
          setOriginList([{
            id: 1,
            value: '',
          }]);
        }
        
        setAllowedMethods(response.data[httpHeaders.allowMethods]);
        setAllowCredentials(response.data[httpHeaders.credentials] === 'true');
        setMaxAge(response.data[httpHeaders.maxAge]);
      } else {
        setError(response.error);
      }
    }
    if (open && bucket && enableCdn && enableCdn === enableCdnInitial) {
      load();
    }
  }, [open, bucket, getToken, enableCdnCounter, enableCdn, enableCdnInitial]);
  
  const clearForm = useCallback(() => {
    setOriginList( getInitialValue(httpHeaders.allowOrigin) );
    setAllowedMethods( getInitialValue( httpHeaders.allowMethods ) );
    setAllowCredentials( getInitialValue( httpHeaders.credentials ) );
    setMaxAge( getInitialValue( httpHeaders.maxAge ) );
    setError();
    setMaxAgeError('');
  }, [getInitialValue]);
  
  return {
    originList,
    setOriginList,
    allowedMethods,
    checkMethod,
    setAllowCredentials,
    setMaxAge: (...args) => {
      setMaxAge(...args);
      setMaxAgeError('');
    },
    allowCredentials,
    maxAge,
    corsScreenActive,
    goToBucketSettings,
    goToCors,
    handleSubmit,
    corsLoadError: error,
    submitting,
    maxAgeError,
    clearForm,
  };
}
