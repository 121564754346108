import React, { useState, useCallback, useEffect } from 'react';

import { getPaymentMethodsList, deletePaypal, deleteCard, makeCardDefault } from '../../api/qencode-account-api'

import { useAuth } from '../../context/AuthContext'

import { Add } from '../../icons'

import { Button } from '../../components/Button';

import { Modal } from '../../components/Modal';

import { NoData } from '../../components/NoData';

import { ModalTabs } from '../../components/ModalTabs';

import { PaymentMethodItem } from '../../components/PaymentMethodItem';

import { ReactComponent as WalletImage } from '../../assets/images/Wallet.svg';
import { ReactComponent as PaypalImage } from '../../assets/images/PPaypal.svg';
import { ReactComponent as CardImage } from '../../assets/images/Card.svg';

import AddCardForm from './AddPaymentForms/AddCardForm'
import AddPaypalForm from './AddPaymentForms/AddPaypalForm';

import styles from './index.module.css';


const PaymentMethods = () => {

    const [loading, setLoading] = useState(true);

    const { 
        isAuthenticated, 
        //getAccessToken, 
        getToken 
    } = useAuth();

    const [paymentMethodsData, setPaymentMethodsData] = useState([]);
    const [paymentMethodsError, setPaymentMethodsError] = useState(null);

    const [isAddPaymentMethodModalOpen, setAddPaymentMethodModalOpen] = useState(false);

    const fetchPaymentMethodsData = useCallback(async () => {
        if (isAuthenticated) {
          setLoading(true);
          //const token = getAccessToken();
          const token = await getToken()
          const { success, data } = await getPaymentMethodsList(token);
          if (success && data) {
            console.log("payment methods data: ", data)
            setPaymentMethodsData(data);
            setPaymentMethodsError(null);
          } else {
            setPaymentMethodsError("Failed to get payment methods data");
            console.log("Failed to get payment methods data");
          }
          setLoading(false);
        } else {
          setPaymentMethodsData([]);
          setPaymentMethodsError(null);
          setLoading(false);
        }
    }, [isAuthenticated, getToken]);    

    useEffect(() => {
        fetchPaymentMethodsData();
    }, [fetchPaymentMethodsData]);   

    const handleAddPaymentMethod = () => {
        setAddPaymentMethodModalOpen(true)
    };    
    
    const closeAddPaymentMethodModal = () => {
        setAddPaymentMethodModalOpen(false)
    }

    const addPaymentMethodToList = (newPaymentMethod) => {
        console.log("newPaymentMethod: ", newPaymentMethod)
        // setPaymentMethodsData([newPaymentMethod, ...paymentMethodsData]);

        // need to fetch payment methods again
        // the problem is that server doesn't sort list by time added, it returns all Paypal 1st and Cards after
        // another problem is that new added card is set as default, and if not refetch the list
        // of payment methods there would be another card in the list falsly default
        fetchPaymentMethodsData();
    };

    const removePaypalMethodFromList = (email) => {
        setPaymentMethodsData(currentMethods => {
            const index = currentMethods.findIndex(method => method.paypal_email === email);
            if (index !== -1) { // Check if the PayPal method is found
                return [
                    ...currentMethods.slice(0, index),
                    ...currentMethods.slice(index + 1)
                ];
            }
            return currentMethods; // Return the original array if no match was found
        });
    };

    const removeCardMethodFromList = (id) => {
        setPaymentMethodsData(currentMethods => currentMethods.filter(method => method.id !== id));
    }
    

    const makeDefault = async (method) => {
        console.log("Make default: ", method)
        // only for cards

        //const token = getAccessToken();
        const token = await getToken()

        const { id } = method

        const settings = {card_id: id}
        const {success, detail} = await makeCardDefault(token, settings)
        if(success){
            console.log(detail || "Card made defaul")
            // since there is no data returned, need to fetch it again
            fetchPaymentMethodsData()
        }else {
            console.log(detail || "Failed to make default Card payment method")
        }  
    }

    const removePaymentMethod = async (method) => {

        //const token = getAccessToken();
        const token = await getToken()

        const { type, id, paypal_email } = method

        if(type === "paypal" && paypal_email){
            // remove paypal payment method
            console.log("Remove Paypal: ", paypal_email)
            const settings = {email: paypal_email}
            const {success, detail} = await deletePaypal(token, settings)
            if(success){
                console.log(detail)
                // remove payment method from the list
                removePaypalMethodFromList(paypal_email)
            }else {
                console.log(detail || "Failed to remove Paypal payment method")
            }
        }

        if(type === "card" && id){
            // remove paypal payment method
            console.log("Remove Card: ", id)
            // deleteCard
            const settings = {card_id: id}
            const {success, detail} = await deleteCard(token, settings)
            if(success){
                console.log(detail || "Card removed")
                // remove payment method from the list
                removeCardMethodFromList(id)
            }else {
                console.log(detail || "Failed to remove Card payment method")
            }            
        }
    }

    const addPaymentMethodTabs = [
        { name: 'card', icon: <CardImage />, label: 'Credit Card', component: <AddCardForm onAddCard={addPaymentMethodToList} onCloseModal={closeAddPaymentMethodModal} /> },
        { name: 'paypal', icon: <PaypalImage />, component: <AddPaypalForm onAddPaypal={addPaymentMethodToList} onCloseModal={closeAddPaymentMethodModal} /> },
    ];

    if (loading) {
        return <div>Loading...</div>; // Show a loading state
    }

    return (
        <div>
            <div className={styles.paymentMethodsContainer}>


                {
                    paymentMethodsData.length > 0 ? (
                        <>
                            <div className={styles.paymentMethodsListContainer}>
                                {paymentMethodsData.map((paymentMethod, index) => (
                                    <PaymentMethodItem
                                        key={index}
                                        paymentMethod={paymentMethod}
                                        // onMakeDefault={(paymentMethod) => console.log("Make default: ", paymentMethod)}
                                        onMakeDefault={makeDefault}
                                        onRemove={removePaymentMethod}
                                    />
                                ))}
                            </div>          
                            <Button
                                variant="link"
                                icon={<Add />}
                                onClick={handleAddPaymentMethod}
                            >
                                Add Payment Method
                            </Button>                 
                        </>
                    ) : (
                        <NoData
                            image={<WalletImage />}
                            title="No payment methods found"
                            description={
                                <>
                                    You have no payment methods on your account.
                                    <br />
                                    Add a payment method to make deposits.
                                </>
                            }
                            footer={
                                <Button
                                    icon={<Add />}
                                    onClick={handleAddPaymentMethod}
                                >
                                    Add Payment Method
                                </Button>                            
                            }
                        />   
                    )
                }



            </div>

            {paymentMethodsError && <div className={styles.errorMessage}>{paymentMethodsError}</div>}


            <Modal isOpen={isAddPaymentMethodModalOpen} onClose={closeAddPaymentMethodModal}
                header={
                    <>
                        <h3>Add Payment Method</h3>                  
                    </>
                }
            >
                <div>
                    <ModalTabs tabs={addPaymentMethodTabs}/>
                </div>
            </Modal> 
        </div>
    );
};

export default PaymentMethods;