import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ModalTabs.module.css';

import { Radio } from '../Radio'

export const ModalTabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].name);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      <div className={styles.tabButtons}>
        {tabs.map((tab) => (
          <div
            key={tab.name}
            onClick={() => handleTabClick(tab.name)}
            className={activeTab === tab.name ? `${styles.tabButton} ${styles.active}` : styles.tabButton}
          >
            <Radio 
                name="option" 
                value={tab.name} 
                checked={activeTab === tab.name}
                onChange={() => handleTabClick(tab.name)} 
            />
            <div className={styles.name}>
                { tab.icon && tab.icon}
                { tab.label && tab.label}                
            </div>
          </div>
        ))}
      </div>
      <div>
        {tabs.map((tab) => (
          <div
            key={tab.name}
            style={{ display: activeTab === tab.name ? 'block' : 'none' }}
          >
            {tab.component}
          </div>
        ))}
      </div>
    </>
  );
};

ModalTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.element,
      label: PropTypes.string,
      component: PropTypes.element.isRequired,
    })
  ).isRequired,
};

