import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as Bucket} from "assets/images/Bucket.svg";
import CreateBucketButton from "pages/MediaStorage/BucketsPage/CreateBucketButton";

function NoData({ openModal }) {
  return (
    <div className="tableNodata">
      <div className="tableNodataImage">
        <Bucket/>
      </div>
      <h3 className="tableNodataTitle">
        You don't have any Buckets yet
      </h3>
      <p className="tableNodataDescription">
        Start by creating a new bucket
      </p>
      <CreateBucketButton openModal={openModal}/>
    </div>
  );
}

NoData.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default NoData;