import React from 'react';
import PropTypes from 'prop-types';
import {regionCodeToName} from "pages/StatisticsPage/config";

function Region({ regionCode }) {
  const newValue = regionCodeToName[regionCode] || regionCode || '-';
  
  return (
    <span className="oneLineEllipsis">{newValue}</span>
  )
}

Region.propTypes = {
  regionCode: PropTypes.string,
};

export default Region;