import PropTypes from "prop-types";
import React from "react";

export const Close = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path d="M11.889 5.33905C12.2281 4.99992 12.2281 4.45006 11.889 4.11092C11.5498 3.77178 11 3.77178 10.6608 4.11092L7.99989 6.77188L5.33893 4.11092C4.99979 3.77178 4.44994 3.77178 4.1108 4.11092C3.77166 4.45006 3.77166 4.99992 4.1108 5.33905L6.77175 8.00001L4.1108 10.661C3.77166 11.0001 3.77166 11.55 4.1108 11.8891C4.44994 12.2282 4.99979 12.2282 5.33893 11.8891L7.99989 9.22814L10.6608 11.8891C11 12.2282 11.5498 12.2282 11.889 11.8891C12.2281 11.55 12.2281 11.0001 11.889 10.661L9.22802 8.00001L11.889 5.33905Z" fill="currentColor"/>   
    </svg>    
  );
};

Close.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Close.defaultProps = {
  color: 'currentColor', // Or any default color
};