import { useEffect } from 'react';

// Component to update the document title based on pageName
const PageTitle = ({ title }) => {
  useEffect(() => {
    if (title) {
      document.title = `${title} - Qencode`; // Update the document title
    }
  }, [title]);

  return null; // This component does not render anything visible
};

export default PageTitle; 