import PropTypes from "prop-types";
import React from "react";

export const ChevronBottom = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path d="M11 7L8 10L5 7" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>    
  );
};

ChevronBottom.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

ChevronBottom.defaultProps = {
  color: 'currentColor', // Or any default color
};