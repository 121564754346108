import PropTypes from "prop-types";
import React from "react";

export const Dashboard = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M6 2.75C4.20507 2.75 2.75 4.20507 2.75 6V14C2.75 15.7949 4.20507 17.25 6 17.25H14C15.7949 17.25 17.25 15.7949 17.25 14V6C17.25 4.20507 15.7949 2.75 14 2.75H6ZM1.25 6C1.25 3.37665 3.37665 1.25 6 1.25H14C16.6234 1.25 18.75 3.37665 18.75 6V14C18.75 16.6234 16.6234 18.75 14 18.75H6C3.37665 18.75 1.25 16.6234 1.25 14V6ZM5.82861 3.82858H7.67147C7.94761 3.82858 8.17147 4.05243 8.17147 4.32858V15.6714C8.17147 15.9476 7.94761 16.1714 7.67147 16.1714H5.82861C4.72404 16.1714 3.82861 15.276 3.82861 14.1714V5.82858C3.82861 4.72401 4.72404 3.82858 5.82861 3.82858ZM10.75 11.7143H14.25C14.6642 11.7143 15 12.0501 15 12.4643C15 12.8785 14.6642 13.2143 14.25 13.2143H10.75C10.3358 13.2143 10 12.8785 10 12.4643C10 12.0501 10.3358 11.7143 10.75 11.7143Z" fill="currentColor"/>
    </svg>    
  );
};

Dashboard.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Dashboard.defaultProps = {
  color: 'currentColor', // Or any default color
};