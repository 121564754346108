import { useCallback } from 'react';
import queryString from 'query-string';

import config from 'config';

const { protocol, apiVersion, apiSubdomain, domain } = config;

// The base URL for API
const apiBaseUrl = `${protocol}://${apiSubdomain}.${domain}/${apiVersion}`;

// const protocol = 'https';
// const endpoint = 'api-qa.qencode.com';
// const api_version = 'v1';

// The base URL for API
// const apiBaseUrl = `${protocol}://${endpoint}/${api_version}`;

// A utility function to make fetch calls with specified options
async function fetchWithParams(url, data, options = {}) {
    const requestData = queryString.stringify(data);
    const fullUrl = `${apiBaseUrl}/${url}`;

    try {
        const response = await fetch(fullUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                ...options.headers,
            },
            body: requestData,
            ...options,
        });

        const responseBody = await response.json(); // Parse the JSON body upfront for any type of response

        // Check if the response indicates an error
        if (responseBody.error !== 0) {
            return {
                success: false,
                error: responseBody.error,
                message: responseBody.message || `API error: ${responseBody.error}`,
            };
        }

        // If the response is successful and contains no server-reported error code
        return { success: true, data: responseBody };

    } catch (error) {
        return {
            success: false,
            error: 1, // Using '1' as a generic error code for exceptions
            message: error.message || 'Failed to complete request due to an unexpected error',
        };
    }
}

// Your custom hook
export function useQencodeApi() {

    const getAccessToken = useCallback(async (api_key) => {
        const response = await fetchWithParams('access_token', { api_key });
        if (response.success) {
            return { token: response.data.token, expire: response.data.expire };
        } else {
            return { error: response.error, detail: response.message }; // Return error code and message
        }
    }, []);

    const createTask = useCallback(async (token) => {
        const response = await fetchWithParams('create_task', { token });
        if (response.success) {
            return { upload_url: response.data.upload_url, task_token: response.data.task_token };
        } else {
            return { error: response.error, detail: response.message }; // Return error code and message
        }
    }, []);    

    const startTranscoding = useCallback(async (task_token, queryJSON) => {
        const response = await fetchWithParams('start_encode2', {
            task_token: task_token,
            query: queryJSON,
            payload: 'Qencode Request Builder'
        });
        if (response.success) {
            return { status_url: response.data.status_url };
        } else {
            return { error: response.error, detail: response.message }; // Return error code and message
        }
    }, []);

    // using default status url to get status with 1st call
    // if need to fetch status periodically use status url returned by request
    const getStatus = useCallback(async ({taskToken, statusUrl, extended = 0}) => {

        const defaultStatusUrl = `${apiBaseUrl}/status`;
        const url = statusUrl || defaultStatusUrl;

        const requestData = queryString.stringify({ task_tokens: taskToken, extended });
    
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: requestData
            });
    
            const result = await response.json();
    
            // Check if the request itself failed
            if (result.error !== 0) {
                return {
                    success: false,
                    error: result.error,
                    detail: result.message || 'Failed to get task status'
                };
            }
    
            // Extract task status
            const taskStatus = result.statuses[taskToken];
    
            return {
                success: true,
                data: taskStatus
            };
        } catch (error) {
            return {
                success: false,
                error: 1,
                detail: error.message || 'Failed to fetch status due to an unexpected error'
            };
        }
    }, []);    

    return {
        getAccessToken,
        createTask,
        startTranscoding,
        getStatus
    };
}
