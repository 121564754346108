import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './ListItem.module.css'; // Ensure you have a corresponding CSS module file for Label
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { Checked, Dot } from "../../icons/";

export const ListItem = ({ text, icon, trailingIcon, submenuItems, wrappedIcon, shortcut, state, onClick, size, danger, checked, extraData, extraDataDelimeter, closeMenu  }) => {
    const [isFocused, setIsFocused] = useState(false);

    //console.log("extraData: ", extraData)

    // const getIconComponent = (icon) => {
    //     return React.cloneElement(icon, { className: styles.icon });
    // };

    const getIconComponent = (icon) => {
        if (wrappedIcon) {
            return (
                <div className={styles.wrappedIconContainer}>
                    {React.cloneElement(icon, { className: styles.icon })}
                </div>
            );
        } else {
            return React.cloneElement(icon, { className: styles.icon });
        }
    };

    const getTrailingIconComponent = (icon) => {
        return React.cloneElement(icon, { className: `${styles.trailingIcon} ${icon?.props?.className || ''}` });
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleItemClick = (callback) => {
        if (callback) {
            callback();
        }
        if (closeMenu) {
            closeMenu();
        }
    };

    const listItemContent = (
        <div
            tabIndex="0"
            onFocus={handleFocus}
            onBlur={handleBlur}
            onClick={onClick}
            className={`${styles.container} ${styles[size]} ${wrappedIcon ? styles.hasWrappedIcon : ''} ${isFocused ? styles.active : ''} ${state ? styles[state] : ''} ${danger ? styles.danger : ''}`}
        >
            {icon && getIconComponent(icon)}
            <div className={styles.text}>
                <div className={styles.textWrapper}>{text}</div>
                {/* {extraData && <Dot className={styles.delimeter} />} */}
                {/* {extraData && <div className={styles.extraData}>{extraData}</div>} */}

                {/* do not render if extraData is [null] */}
                {extraData && !(Array.isArray(extraData) && extraData.every(item => item === null)) && (
                    <>
                        <Dot className={styles.delimeter} />
                        <div className={styles.extraData}>{extraData}</div>
                    </>
                )}                
            </div>
            {shortcut && <div className={styles.shortcut}>Ctrl+A</div>}
            {checked && <Checked className={styles.checkedIcon} />}
            {trailingIcon && getTrailingIconComponent(trailingIcon)}
        </div>
    );

    return submenuItems ? (
        <Tippy
            content={
                <>
                    {submenuItems.map((menuItem, index) => (
                        <ListItem
                            key={index}
                            text={menuItem.text}
                            onClick={() => handleItemClick(menuItem.onClick)}
                            icon={menuItem.icon}
                            danger={menuItem.danger}
                            trailingIcon={menuItem.trailingIcon}
                            submenuItems={menuItem.submenuItems}
                            closeMenu={closeMenu} // Pass closeMenu to submenu items
                            extraDataDelimeter={menuItem.extraDataDelimeter}
                        />
                    ))}
                </>
            }            
            // visible={true}
            allowHTML={true}
            arrow={false}            
            placement="right-start"
            theme="menu"
            maxWidth={200}
            offset={[-25, -5]}
            interactive={true}
            // appendTo={() => document.getElementById('tooltip-portal')} 
        >
            {listItemContent}
        </Tippy>
    ) : (
        listItemContent
    );
};

ListItem.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.element,
    trailingIcon: PropTypes.element,
    size: PropTypes.string,
    shortcut: PropTypes.bool,
    state: PropTypes.string,
    danger: PropTypes.bool,
    extraDataDelimeter: PropTypes.bool,
    checked: PropTypes.bool,
    onClick: PropTypes.func,
    submenuItems: PropTypes.array,
    wrappedIcon: PropTypes.bool,
    // extraData: PropTypes.string,
    extraData: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),    
};

ListItem.defaultProps = {
    text: 'Item',
    icon: null,
    trailingIcon: null,
    size: 'md',
    shortcut: false,
    state: undefined,
    danger: false,
    extraDataDelimeter: true,
    checked: false,
    onClick: () => {},
    submenuItems: null,
    wrappedIcon: false,
    extraData: null,
};




// import React, {useState} from 'react';
// import PropTypes from 'prop-types';
// import styles from './ListItem.module.css'; // Ensure you have a corresponding CSS module file for Label

// import Tippy from '@tippyjs/react';
// import 'tippy.js/dist/tippy.css';

// import { Checked, Dot } from "../../icons/";

// export const ListItem = ({ text, icon, trailingIcon, submenuItems, wrappedIcon, shortcut, state, onClick, size, danger, checked, extraData }) => {

//     const [isFocused, setIsFocused] = useState(false);

//     console.log("submenuItems: ", submenuItems)

//     // const getIconComponent = (icon) => {
//     //     return React.cloneElement(icon, { className: styles.icon });
//     // };

//     const getIconComponent = (icon) => {
//         if(wrappedIcon){
//             return ( 
//                 <div className={styles.wrappedIconContainer}>
//                     {React.cloneElement(icon, { className: styles.icon })}
//                 </div>                
//             )
//         } else return React.cloneElement(icon, { className: styles.icon });
//     };    

//     const getTrailingIconComponent = (icon) => {
//         return React.cloneElement(icon, { className: `${styles.trailingIcon} ${icon?.props?.className || ''}` });
//     };

//     const handleFocus = () => {
//         setIsFocused(true);
//     };

//     const handleBlur = () => {
//         setIsFocused(false);
//     };

//     return (
//         <div
//             tabIndex="0"
//             onFocus={handleFocus}
//             onBlur={handleBlur}
//             onClick={onClick}
//             className={`${styles.container} ${styles[size]} ${icon ? styles.icon : ''} ${wrappedIcon ? styles.hasWrappedIcon : ''} ${isFocused ? styles.active : ''} ${state ? styles[state] : ''} ${danger ? styles.danger : ''}`}
//         >
//             { icon && getIconComponent(icon)}
//             <div className={styles.text}>
//                 <div className={styles.textWrapper}>{text}</div>
//                 {extraData && <Dot className={styles.delimeter} />}
//                 {extraData && <div className={styles.extraData}>{extraData}</div>}
//             </div>            
//             {shortcut && <div className={styles.shortcut}>Ctrl+A</div>}      
//             {checked && <Checked className={styles.checkedIcon} />}      
//             {trailingIcon && getTrailingIconComponent(trailingIcon)}
//         </div>
//     );
// };

// ListItem.propTypes = {
//     text: PropTypes.string.isRequired,
//     // icon: PropTypes.bool,
//     icon: PropTypes.element,
//     trailingIcon: PropTypes.element,
//     size: PropTypes.string,
//     shortcut: PropTypes.bool,
//     state: PropTypes.string,
//     danger: PropTypes.bool,
//     checked: PropTypes.bool,
//     onClick: PropTypes.func // Define the onClick prop
// };

// ListItem.defaultProps = {
//     text: 'Item',
//     icon: null,
//     trailingIcon: null,
//     size: 'md',
//     shortcut: false,
//     state: undefined,
//     danger: false,
//     checked: false,
//     onClick: () => {} // Default no-op function
// };