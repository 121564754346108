import React from "react";
import {APIRegenerate, Delete} from "icons";
import PropTypes from "prop-types";
import {OptionsMenu} from "components/OptionsMenu";
import {modals} from "pages/Streaming/StreamProjectsPage/config";

function MoreActionsPopover({ selectedKey, handleOpenModal }) {
  return (
    <div className="cellContainer">
      <OptionsMenu
        menuItems={
        [
          {
            icon: <APIRegenerate label="Regenerate"/>,
            text: 'Recreate API Key',
            onClick: () => handleOpenModal( modals.recreateApiKey, selectedKey ),
          },
          {
            icon: <Delete label="Remove" />,
            onClick: () => handleOpenModal( modals.deletion, selectedKey ),
            text: "Delete",
            danger: true,
          }
        ]
        }
      />
    </div>
  );
}

MoreActionsPopover.propTypes = {
  selectedKey: PropTypes.object.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
};

export default MoreActionsPopover;