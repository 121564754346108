import PropTypes from "prop-types";
import React from "react";

export const Project = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M13.163 4.75C12.8245 3.31665 11.5368 2.25 10 2.25C8.46321 2.25 7.17555 3.31665 6.83697 4.75H13.163ZM14.6911 4.75H15C17.0711 4.75 18.75 6.42893 18.75 8.5V15.5C18.75 17.5711 17.0711 19.25 15 19.25H5C2.92893 19.25 1.25 17.5711 1.25 15.5V8.5C1.25 6.42893 2.92893 4.75 5 4.75H5.30888C5.66846 2.48301 7.63185 0.75 10 0.75C12.3682 0.75 14.3315 2.48301 14.6911 4.75ZM2.75 8.5C2.75 7.25736 3.75736 6.25 5 6.25H15C16.2426 6.25 17.25 7.25736 17.25 8.5V15.5C17.25 16.7426 16.2426 17.75 15 17.75H5C3.75736 17.75 2.75 16.7426 2.75 15.5V8.5ZM5.3501 10C5.3501 9.50295 5.75304 9.10001 6.2501 9.10001H13.7501C14.2472 9.10001 14.6501 9.50295 14.6501 10C14.6501 10.4971 14.2472 10.9 13.7501 10.9H6.2501C5.75304 10.9 5.3501 10.4971 5.3501 10ZM6.2501 13.1C5.75304 13.1 5.3501 13.5029 5.3501 14C5.3501 14.4971 5.75304 14.9 6.2501 14.9H11.2501C11.7472 14.9 12.1501 14.4971 12.1501 14C12.1501 13.5029 11.7472 13.1 11.2501 13.1H6.2501Z" fill="currentColor"/>
    </svg>    
  );
};

Project.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Project.defaultProps = {
  color: 'currentColor', // Or any default color
};