import {useCallback, useEffect, useState} from 'react';
import {useAuth} from "context/AuthContext";
import {getAccessPolicy, setAccessPolicy} from "api/qencode-content-delivery-api";

const policies = {
  public: 'public',
  authenticated: 'authenticated',
};

export default function useAccessPolicy(bucket, enableCdnData, open) {
  const {
    enableCdnCounter,
    enableCdn,
    } = enableCdnData;
  const { getToken } = useAuth();
  const [enableAuthentication, setEnableAuthentication] = useState(undefined);
  const [enableAuthenticationInitial, setEnableAuthenticationInitial] = useState(undefined);
  
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [authStatusError] = useState();
  const [errorSubmittingPolicy, setErrorSubmittingPolicy] = useState();
  
  const clearForm = useCallback(() => {
    setEnableAuthentication(undefined);
    setErrorSubmittingPolicy(undefined);
  }, []);
  
  const handleSubmit = useCallback(async (bucket) => {
    const accessToken = await getToken();

    if (enableCdn && enableAuthenticationInitial !== enableAuthentication) {
      setSubmitting(true);
      const response = await setAccessPolicy( {
        accessToken,
        bucketName: bucket.name,
        policy: enableAuthentication ? policies.authenticated : policies.public,
      } );
      setSubmitting(false);
      if (response.success) {
        return true;
      } else {
        setErrorSubmittingPolicy(response.error);
        return false;
      }
    }
  }, [enableAuthentication, enableAuthenticationInitial, enableCdn, getToken]);
  
  useEffect(() => {
    async function init() {
      setLoading(true);
      const accessToken = await getToken();
      const accessPolicyResponse = await getAccessPolicy({
        accessToken,
        bucketName: bucket.name,
      });
      setLoading(false);
      if (accessPolicyResponse.success) {
        if (accessPolicyResponse.policy === policies.public) {
          setEnableAuthentication(false);
          setEnableAuthenticationInitial(false);
        } else if (accessPolicyResponse.policy === policies.authenticated) {
          setEnableAuthentication(true);
          setEnableAuthenticationInitial(true);
        }
      } else {
        setEnableAuthentication(false);
        setEnableAuthenticationInitial(undefined);
      }
      // else if (typeof accessPolicyResponse.error === 'string') {
      //   // setAuthStatusError(accessPolicyResponse.error);
      // }
    }

    if (open && bucket && enableCdn) {
      init();
    }
  }, [bucket, getToken, enableCdnCounter, enableCdn, open]);
  
  return {
    enableAuthentication,
    setEnableAuthentication,
    authStatusError,
    submitting,
    handleSubmit,
    errorSubmittingPolicy,
    clearForm,
    loading,
  };
}
