import React from 'react';
import PropTypes from 'prop-types';

export function ClockDashed({ className, color, label }) {
  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg
      className={className}
      role="img"
      aria-label={label}
      style={style}
      width="17"
      height="17"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.6a5.4 5.4 0 1 0 1.54 10.58.6.6 0 0 1 .35 1.15 6.6 6.6 0 1 1 2.35-11.39.6.6 0 0 1-.77.92A5.38 5.38 0 0 0 8 2.6Zm4.92 1.9a.6.6 0 0 1 .82.23c.17.31.33.64.45.98a.6.6 0 1 1-1.12.42c-.1-.28-.23-.55-.38-.8a.6.6 0 0 1 .23-.82Zm1.68 3.64a.6.6 0 1 0-1.2-.03 5.47 5.47 0 0 1-.13 1.08.6.6 0 0 0 1.17.27 6.66 6.66 0 0 0 .16-1.32Zm-1.43 2.94c.27.2.33.57.13.84-.3.43-.67.82-1.07 1.16a.6.6 0 0 1-.78-.91c.33-.28.63-.6.88-.95a.6.6 0 0 1 .84-.14ZM7.72 5a.7.7 0 1 1 1.4 0v3.41a.7.7 0 0 1-.41.64l-2.42 1.09a.7.7 0 0 1-.58-1.28l2.01-.9V5Z"
        fill="currentColor"
      />
    </svg>
  );
}

ClockDashed.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
};

ClockDashed.defaultProps = {
  color: 'currentColor', // Or any default color
};
