import React from 'react';
import {ReactComponent as TranscodingImage} from 'assets/images/Transcoding.svg';
import TranscodeButton from "pages/Transcoding/JobHistoryPage/TranscodeButton";


function EmptyMessage() {
  return (
    <div className="tableNodata">
      <div className="tableNodataImage">
        <TranscodingImage/>
      </div>
      <h3 className="tableNodataTitle">
        You don't have any Transcoding Jobs yet
      </h3>
      <p className="tableNodataDescription">
        Start by transcoding a video
      </p>
      <TranscodeButton/>
    </div>
  );
}

export default EmptyMessage;