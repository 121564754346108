import React from 'react';
import PropTypes from 'prop-types';
import styles from './CardButton.module.css';

import { Button } from '../Button'

import { ArrowRight, Retry, Edit, Dot } from '../../icons';

export const CardButton = ({ icon, title, description, value, valueIcon, extraData, important, className, name, active, onClick, onClear, onEdit }) => {

    const getIconComponent = (icon) => {
        return React.cloneElement(icon, { className: styles.icon });
    };  

    const getVelueIconComponent = (icon) => {
        return React.cloneElement(icon, { className: styles.valueIcon });
    };  

    return (
        <div 
            tabIndex="0"
            className={`${styles.card} ${className || ''} ${important ? styles.important : ''} ${active ? styles.active : ''}`}
            name={name}
            // onClick={onClick}
            onClick={active ? null : onClick} // Set onClick to null if the button is active
        >
            { icon && <div className={`${styles.iconContainer} ${active ? styles.active : ''}`}>{getIconComponent(icon)}</div>}
            <div className={styles.body}>
                { title && <div className={styles.title}>{title}</div>}
                {/* { value && active
                    ? 
                    <div className={`${styles.valueContainer} ${extraData ? styles.withExtra : ''}`}>
                        { extraData 
                            ?
                            <>
                                <div className={styles.value}>{value}</div> <Dot className={styles.delimeter} /> <div className={styles.extraData}>{extraData}</div>
                            </>
                            :<>{value}</>
                        }
                    </div>
                    : 
                    <>{description && <div className={styles.description}>{description}</div>}</>
                } */}

                { value && active
                    ? 
                    <div className={`${styles.valueContainer}`}>
                        { valueIcon && <div className={styles.valueIconContainer}>{getVelueIconComponent(valueIcon)}</div>}
                        <div className={styles.value}>{value}</div> 
                        { extraData && <><Dot className={styles.delimeter} /> <div className={styles.extraData}>{extraData}</div></>}                        
                    </div>
                    : 
                    <>{description && <div className={styles.description}>{description}</div>}</>
                }

            </div>
            {
                active
                ?
                <div className={styles.iconsContainer}>
                    {
                        onClear &&
                        <Button
                            icon={<Retry label="Clear" />}
                            onClick={onClear}
                            size="sm"
                            type="secondary"
                        />                           
                    }
                    {
                        onEdit &&
                        <Button
                            icon={<Edit label="Edit" />}
                            onClick={onEdit}
                            size="sm"
                        >
                            Edit
                        </Button>                         
                    }   
                </div>                
                :
                <div className={styles.arrowContainer}>
                    <ArrowRight className={styles.arrowIcon} />
                </div>
            }
        </div>
    );
};

CardButton.propTypes = {
    icon: PropTypes.element,
    title: PropTypes.string,
    value: PropTypes.string,
    valueIcon: PropTypes.element,
    extraData: PropTypes.string,
    description: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    onClick: PropTypes.func,
    onClear: PropTypes.func,
    onEdit: PropTypes.func
};

CardButton.defaultProps = {
    icon: null, 
    title: null,
    value: null,
    valueIcon: null,
    extraData: null,
    description: null,
    className: null,
    name: null,
    onClick: () => {},
    onClear: null,
    onEdit: null
};


// import React, {useState} from 'react';
// import PropTypes from 'prop-types';
// import styles from './CardButton.module.css';

// import { ArrowRight } from '../../icons';

// export const CardButton = ({ icon, title, description, className, name, active, onClick }) => {

//     const getIconComponent = (icon) => {
//         return React.cloneElement(icon, { className: styles.icon });
//     };

//     const [isFocused, setIsFocused] = useState(false);

//     const handleFocus = () => {
//         setIsFocused(true);
//     };

//     const handleBlur = () => {
//         setIsFocused(false);
//     };    

//     return (
//         <div 
//             tabIndex="0"
//             onFocus={handleFocus}
//             onBlur={handleBlur}
//             className={`${styles.card} ${className || ''} ${isFocused ? styles.focused : ''}`}
//             name={name}
//             onClick={onClick}
//         >
//             { icon && <div className={styles.iconContainer}>{getIconComponent(icon)}</div>}
//             <div className={styles.body}>
//                 { title && <div className={styles.title}>{title}</div>}
//                 { description && <div className={styles.description}>{description}</div>}
//             </div>
//             <div className={styles.arrowContainer}>
//                 <ArrowRight className={styles.arrowIcon} />
//             </div>
//         </div>
//     );
// };

// CardButton.propTypes = {
//     icon: PropTypes.element,
//     title: PropTypes.string,
//     description: PropTypes.string,
//     className: PropTypes.string,
//     name: PropTypes.string,
//     onClick: PropTypes.func
// };

// CardButton.defaultProps = {
//     icon: null, 
//     title: null,
//     description: null,
//     className: null,
//     name: null,
//     onClick: () => {}
// };