import {useCallback, useState} from "react";

export default function useSelect(defaultValue = '') {
  const [value, setValue] = useState(defaultValue);
  
  const onChange = useCallback((e) => {
    setValue(e?.target?.value);
  }, [setValue]);
  
  return {
    onChange,
    value,
  };
}
