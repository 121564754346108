import PropTypes from "prop-types";
import React from "react";

export const EyeClose = ({ className, color, label }) => {

  const style = color !== 'currentColor' ? { color } : undefined;

  return (  
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}       
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M12.5246 2.48083C12.6855 2.21087 12.5811 1.87044 12.2915 1.72046C12.0018 1.57049 11.6365 1.66775 11.4756 1.93771L10.5381 3.51032C9.90944 3.27862 9.22922 3.14123 8.50001 3.14123C5.17744 3.14123 2.87186 5.99357 1.82542 7.62392C1.39153 8.29992 1.39153 9.16604 1.82542 9.84204C2.42641 10.7784 3.44271 12.1178 4.82845 13.0884L3.97557 14.5192C3.81464 14.7891 3.91901 15.1295 4.20868 15.2795C4.49835 15.4295 4.86363 15.3322 5.02456 15.0623L12.5246 2.48083ZM5.4793 11.9966L6.52554 10.2415C6.23442 9.81721 6.06288 9.29613 6.06288 8.7329C6.06288 7.30752 7.1615 6.15209 8.51673 6.15209C8.66123 6.15209 8.80282 6.16523 8.94046 6.19043L9.86539 4.63884C9.43306 4.5066 8.97737 4.43163 8.50001 4.43163C5.84193 4.43163 3.85572 6.75608 2.83306 8.34939C2.68019 8.58751 2.68019 8.87845 2.83306 9.11658C3.41805 10.028 4.31833 11.1786 5.4793 11.9966ZM8.16206 7.49623C7.6486 7.65627 7.28127 8.15482 7.28127 8.7329C7.28127 8.80521 7.28701 8.87627 7.29807 8.94558L8.16206 7.49623ZM10.7696 7.70813L8.62159 11.3114C9.92815 11.2536 10.9706 10.1213 10.9706 8.7329C10.9706 8.36877 10.8989 8.02226 10.7696 7.70813ZM8.50001 13.0343C8.20596 13.0343 7.92013 13.0059 7.64292 12.9531L6.95129 14.1134C7.43958 14.2483 7.95617 14.3247 8.50001 14.3247C11.8226 14.3247 14.1282 11.4724 15.1746 9.84204C15.6085 9.16604 15.6085 8.29992 15.1746 7.62392C14.6336 6.78107 13.7561 5.61162 12.5756 4.67853L11.9228 5.77353C12.8909 6.55694 13.6514 7.54609 14.167 8.34939C14.3199 8.58751 14.3199 8.87845 14.167 9.11658C13.1443 10.7099 11.1581 13.0343 8.50001 13.0343Z" fill="currentColor"/>
    </svg>
  );
};

EyeClose.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

EyeClose.defaultProps = {
  color: 'currentColor', // Or any default color
};