import PropTypes from "prop-types";
import React from "react";

export const Folder = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 16 16" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M3.2 1.40002C1.98497 1.40002 1 2.385 1 3.60002V4.40002V11.6C1 13.2569 2.34315 14.6 4 14.6H12C13.6569 14.6 15 13.2569 15 11.6V6.80002C15 5.14317 13.6569 3.80002 12 3.80002H10.0259C9.95908 3.80002 9.89663 3.7666 9.85954 3.71096L8.97202 2.37969C8.56399 1.76765 7.87708 1.40002 7.14151 1.40002H3.2ZM2.2 3.60002C2.2 3.04774 2.64771 2.60002 3.2 2.60002H7.14151C7.47586 2.60002 7.78809 2.76713 7.97356 3.04532L8.86108 4.3766C9.12073 4.76608 9.55785 5.00002 10.0259 5.00002H12C12.9941 5.00002 13.8 5.80591 13.8 6.80002V11.6C13.8 12.5941 12.9941 13.4 12 13.4H4C3.00589 13.4 2.2 12.5941 2.2 11.6V4.40002V3.60002ZM3.75986 3.20004C3.31804 3.20004 2.95986 3.55821 2.95986 4.00004V4.80004C2.95986 5.02095 3.13895 5.20004 3.35986 5.20004H7.69343C8.00434 5.20004 8.19639 4.86085 8.03642 4.59424L7.43294 3.58844C7.28837 3.34748 7.02796 3.20004 6.74695 3.20004H3.75986Z" fill="currentColor"/>
    </svg>    
  );
};

Folder.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Folder.defaultProps = {
  color: 'currentColor', // Or any default color
};