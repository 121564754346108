import React from 'react';
import  PropTypes from 'prop-types';
import style from "pages/StatisticsPage/components/Chart/Tooltip/style.module.css";
import {serviceCategoryIds, serviceCategoryIdToName, summaryChartColors} from "pages/StatisticsPage/config";
import bytesToReadable from "helpers/bytesToReadable";
import {gigabytesToReadable} from "components/Table/Cells/GbReadable";
import formatNumber from "helpers/formatNumber";
import classNames from "classnames";
import MoneyRounded from "components/Table/Cells/MoneyRounded";


function AllTooltipContent(props) {
  const {
    data,
    indexValue,
    quantity,
    keys,
  } = props;
  const totalAmount = keys.reduce((sum, id) => sum + (data[id] || 0), 0);
  
  
  return (
    <table className="standardTable">
      <thead>
        <tr>
          <th>Type</th>
          <th>Quantity</th>
          <th className={style.bandwidthColumn}>Cost</th>
        </tr>
      </thead>
      <tbody>
      { keys
          .filter((id) => data[id] > 1e-6)
          .map((id) => {
        let value = parseFloat(Number(quantity[indexValue][id]).toFixed(2));
        if (id === serviceCategoryIds.contentDelivery) {
          value = bytesToReadable(value);
        } else if (id === serviceCategoryIds.mediaStorage) {
          value = gigabytesToReadable(value);
        } else if (id === serviceCategoryIds.liveStreaming) {
          value = formatNumber(value);
        } else if (id === serviceCategoryIds.transcoding) {
          value = formatNumber(value);
        }
        
        return (
          <tr key={id}>
            <td className={ classNames(style.cell, style.mainCell) }>
              <div className={style.colorSample} style={{ 'backgroundColor': summaryChartColors[id] }}></div>
              <span>
                { serviceCategoryIdToName[id] }
              </span>
            </td>
            <td className={style.cell}>
              { value }
            </td>
            <td className={style.cell}>
              <MoneyRounded value={ data[id] }/>
            </td>
          </tr>
        )
      }) }
      </tbody>
      <tfoot>
        <tr>
          <td className={ classNames(style.cell, style.mainCell) }>Total</td>
          <td className={style.cell}></td>
          <td className={ classNames(style.cell, style.total) }>
            <MoneyRounded value={totalAmount}/>
          </td>
        </tr>
      </tfoot>
    </table>
  )
}

AllTooltipContent.propTypes = {
  keys: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.object,
  indexValue: PropTypes.string.isRequired,
  quantity: PropTypes.object.isRequired,
};

export default AllTooltipContent;