import React from 'react';
import style from './style.module.css';

function TableWrapper({ children }) {
  return (
    <div className={style.tableWrapper}>
      { children }
    </div>
  );
}

TableWrapper.propTypes = {};

export default TableWrapper;