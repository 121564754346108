
import {Button} from "components/Button";
import {Delete} from "icons";
import {Modal} from "components/Modal";
import React, {useState} from "react";
import PropTypes from "prop-types";
import {ReactComponent as StreamWarning} from "assets/images/StreamWarning.svg";
import ErrorMessage from "components/ErrorMessage";
import {deleteStream} from "api/live-streaming-api";
import {useAuth} from "context/AuthContext";

function DeleteStreamModal({
  open,
  handleCancel,
  streamId,
  onSuccessfulFinish,
  }) {
  const { getToken } = useAuth();
  const [errorMessage, setErrorMessage] = useState();
  const [submitting, setSubmitting] = useState(false);

  async function handleConfirmDeletion() {
    let accessToken = await getToken();
    setSubmitting(true);
    const {success, error} = await deleteStream({
      accessToken,
      id: streamId,
    });
    if (success) {
      onSuccessfulFinish?.();
    } else {
      setErrorMessage(error);
    }
    setSubmitting(false);
  }


  return (
    <Modal
      isOpen={open}
      variant="popUp"
      onClose={handleCancel}
      header={
        <>
          <StreamWarning/>
          <h3>
            Delete this stream!
          </h3>
          <p>
            Are you sure you want to delete this stream?
          </p>
          { errorMessage && <ErrorMessage>{ errorMessage }</ErrorMessage> }
        </>
      }
      footer={
        <>
          <Button
            type="secondary"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            icon={ <Delete/> }
            type='danger'
            onClick={handleConfirmDeletion}
            state={ submitting ? 'disabled' : 'default' }
          >
            Delete
          </Button>
        </>
      }
    >
    </Modal>
  );
}

DeleteStreamModal.propTypes = {
  open: PropTypes.bool,
  handleCancel: PropTypes.func,
  streamId: PropTypes.string,
  onSuccessfulFinish: PropTypes.func,
};

export default DeleteStreamModal;