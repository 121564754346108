import PropTypes from "prop-types";
import React from "react";

export const Retry = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M4.15281 7.25H5.17878C5.42294 7.25 5.62088 7.43316 5.62088 7.6591C5.62088 7.74543 5.59137 7.82955 5.53656 7.89942L3.82505 10.0812C3.68162 10.264 3.40516 10.3047 3.20756 10.1719C3.17362 10.1491 3.14335 10.122 3.11768 10.0913L1.29248 7.90952C1.14302 7.73086 1.17837 7.47391 1.37145 7.33561C1.44892 7.28011 1.54411 7.25 1.64208 7.25H2.92693C3.50116 4.20909 6.13094 1.90002 9.30514 1.90002C12.902 1.90002 15.7998 4.86499 15.7998 8.5C15.7998 12.135 12.902 15.1 9.30514 15.1C7.84551 15.1 6.49765 14.61 5.41388 13.7847C5.15024 13.5839 5.09927 13.2075 5.30003 12.9438C5.50079 12.6802 5.87725 12.6292 6.14088 12.83C7.02438 13.5028 8.11886 13.9 9.30514 13.9C12.2194 13.9 14.5998 11.4924 14.5998 8.5C14.5998 5.50762 12.2194 3.10002 9.30514 3.10002C6.81026 3.10002 4.70657 4.86459 4.15281 7.25Z" fill="currentColor"/>
    </svg>    
  );
};

Retry.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Retry.defaultProps = {
  color: 'currentColor', // Or any default color
};