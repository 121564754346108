import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Modal} from "components/Modal";
import {Button} from "components/Button";
import {InputField} from "components/InputField";
import useSingleFieldForm from "helpers/useSingleFieldForm";
import {createProject} from "api/qencode-account-api";

function CreateModal({
    open,
    handleCancel,
    onSuccessfulFinish,
  }) {
  const apiFunction = useCallback((props) => createProject({
    ...props,
    type: 'live',
  }), []);

  const {
    newName,
    submitting,
    errorMessage,
    handleNameInput,
    clearFormAndClose,
    handleConfirmSubmit,
  } = useSingleFieldForm({
    handleCancel,
    onSuccessfulFinish,
    apiFunction,
  });
  
  return (
    <Modal
      isOpen={open}
      onClose={ handleCancel }
      header={
        <>
          <h3>Create Streaming Project</h3>
          <p>
            Launch high-quality live streams with global delivery in just a few simple steps
          </p>
        </>
        
      }
      footer={
        <>
          <Button
            variant="modalFooter"
            onClick={ clearFormAndClose }
            type="secondary"
          >
            Cancel
          </Button>
          <Button
            variant="modalFooter"
            state={ submitting ? "disabled" : "default" }
            onClick={ handleConfirmSubmit }
          >
            Create
          </Button>
        </>
      }
    >
      <form
        className="form"
        onSubmit={ handleConfirmSubmit }
      >
        <div className="row">
          <InputField
            placeholder="Enter Project Name"
            label="Project Name"
            inputText={newName}
            state={errorMessage ? 'error' : 'default'}
            hint={errorMessage}
            onChange={ handleNameInput }
          />
        </div>
      </form>
    </Modal>
  )
}

CreateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  onSuccessfulFinish: PropTypes.func,
};

export default CreateModal;