import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {getCoreRowModel, getPaginationRowModel, useReactTable} from "@tanstack/react-table";
import Table from "components/Table";
import {ReactComponent as ResolutionIcon} from 'assets/images/Resolution.svg';
import formatNumber from "helpers/formatNumber";
import MeterForPercentage from "components/Meter/MeterForPercentage";
import style from './style.module.css';


const columnIds = {
  topResolutions: 'topResolutions',
  minutes: 'minutes',
};

function TopResolutions({
    stats,
    noDataImage,
  }) {
  const columns = useMemo(() => [
    {
      id: columnIds.topResolutions,
      header: () => <span>Top Resolutions</span>,
      meta: {
        colspan: 2,
        className: style.topResolutionsColumn,
      },
      cell: (info) => {
        const {
          percent,
          duration,
          name,
        } = info.row.original;
        return (
          <MeterForPercentage
            title={name}
            percent={percent}
            rawValue={ formatNumber(duration).toUpperCase() }
            maxPercent={100}
          />
        )
      },
    },
    {
      id: columnIds.minutes,
      header: () => <span>Minutes</span>,
      meta: {
        colspan: 0,
        align: 'right',
      }
    },
  ], []);

  const table = useReactTable({
    data: stats.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination: {
        pageIndex: 0,
        pageSize: 5,
      },
    }
  });
  
  return (
    <Table
      { ...stats }
      table={table}
      horizontalLinesInvisible
      noDataImage={ noDataImage ? noDataImage : <ResolutionIcon/>}
      noDataDescription="No data found"
      skeletonRowCount={5}
    />
  )
}

TopResolutions.propTypes = {
  stats: PropTypes.object.isRequired,
};

export default TopResolutions;