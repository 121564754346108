import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useLocation, useNavigate, Link } from 'react-router-dom';

// import { validateFields } from './validation';
import { validateFields } from '../../helpers/validation';

import { Button } from '../../components/Button';
import { InputField } from '../../components/InputField'

import { 
    // Google, 
    // Github, 
    Check 
} from '../../icons/'

import Oauth2 from '../../components/Oauth2'

import { ReactComponent as Logo } from '../../assets/images/QencodeLogo.svg';

import styles from './index.module.css';

const SignupPage = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [validationErrors, setValidationErrors] = useState({});

    const [credentials, setCredentials] = useState({ email: '' });

    const [currentStep, setCurrentStep] = useState('verifyEmail'); // start with the first step

    const { 
        isAuthenticated, 
        signup, signupError, setSignupError,
        resendActivation, resendError, setResendError  
    } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from?.pathname || '/dashboard';

    useEffect(() => {
      if (isAuthenticated) {
        navigate(from); // Redirect if already authenticated
      }
    }, [isAuthenticated, navigate, from]);

    const handleEmailVerification = async (e) => {
        e.preventDefault();

        // do input validation on the client side 1st
        const fieldsToValidate = ['email'];
        const { isValid, errors } = validateFields(credentials, fieldsToValidate);
        setValidationErrors(errors); // Update the validation errors state
      
        if (!isValid) {
          console.log("Validation errors", errors);
          return; // Stop the submission if validation fails
        }  

        setIsSubmitting(true); // Disable the button
        const { email } = credentials;
        const success = await signup({ email }); // Call the signup function from the context
        if (success) {
          setCurrentStep('checkEmail'); // Move to the next step if email is verified
        }else {
            // error logged in AuthContext
            //console.log("Handle email verification failure, show message, etc.")
        }      
        setIsSubmitting(false); // Re-enable the button
    };

    const handleResendActivation = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Optionally, use a different state variable for loading
        const { email } = credentials;
        const success = await resendActivation({ email }); // Call the signup function from the context
        if (success) {
            console.log("Activation email was sent again")
        }else {
            // error shown by AuthContext
            // console.log("Handle resend activation email failure, show message, etc.")
        }          
        setIsSubmitting(false); // Optionally, use a different state variable for loading
    };

    const handleInputChange = (e) => {
        // Reset the authError when the user starts typing
        if (signupError) {
            setSignupError(null);
        }

        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });

        // Reset the specific field's error when the user starts typing
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: null
        }));
    };

    useEffect(() => {
        // This function runs when the component mounts
        setSignupError(null); // Clear the signup error state
        // Optionally, if you want to clear any login errors when navigating to the signup page:
        // setAuthError(null);
        setResendError(null)
      
        // This function runs when the component unmounts
        return () => {
          setSignupError(null); // Clear the signup error state again
          // Optionally, clear the login errors again:
          // setAuthError(null);
          setResendError(null)
        };
    }, [setSignupError, setResendError, /* setAuthError */]);    

    return (
        <div className={styles.container}>
            <Logo className={styles.logo} />

            {/* Conditional rendering based on the step */}
            {currentStep === 'verifyEmail' && (
                <div className={styles.authWindow}>

                    <div className={styles.title}>Start your free trial</div>

                    <div className={styles.info}>14-day free trial. No credit card required.</div>

                    <Oauth2 />
                    {/* <div className={styles.authButtonsContainer}>
                        <div className={styles.authButton}>
                            <Button icon={<Google />} type='secondary' size='lg' fullWidth={true}>Google</Button>  
                        </div>
                        <div className={styles.authButton}>
                            <Button icon={<Github />} type='secondary' size='lg' fullWidth={true}>Github</Button>   
                        </div>
                    </div> */}

                    <div className={styles.delimeterContainer}>
                        <div className={styles.delimeterLine}></div>
                        <div className={styles.delimeterText}>OR</div>
                        <div className={styles.delimeterLine}></div>
                    </div>

                    <div className={styles.form}>
                        <div className={styles.row}>
                            <InputField
                                inputText={credentials.email}
                                onChange={handleInputChange}
                                name="email"
                                placeholder="Work email"
                                size="lg"
                                // state={signupError ? 'error' : 'default'}
                                // hint={signupError ? signupError : undefined}
                                // state={validationErrors.email ? 'error' : 'default'}
                                // hint={validationErrors.email ? validationErrors.email : undefined}
                                state={signupError || validationErrors.email ? 'error' : 'default'}
                                hint={signupError ? signupError : validationErrors.email ? validationErrors.email : undefined}
                            />    
                        </div>
                        
                        <div className={styles.formFooter}>
                            <Button onClick={handleEmailVerification} className={styles.formFooterBtn} state={isSubmitting ? "disabled" : undefined} fullWidth={true} size='lg'>
                                Verify your email
                            </Button>                                
                        </div>
                    
                    </div>



                    <div className={styles.extraAuthMessage}>
                        Already have an account? <Link to='/login'>Sign in</Link>
                    </div>
                </div>
            )}

            {currentStep === 'checkEmail' && (
                <div className={styles.authWindow}>
                    <div className={styles.title}>You're almost there!</div>

                    <div className={styles.info}>Please check your email <strong>{credentials.email}</strong> to complete the signup process.</div>

                    <div className={styles.authSteps}>
                        <div className={`${styles.authStep} ${styles.authStepPassed}`}>
                            <div className={styles.authStepNumber}>{<Check className={styles.authStepIcon} />}</div>
                            <div className={styles.authStepText}>Provide email address</div>
                        </div>
                        <div className={styles.authStepLine}></div>
                        <div className={`${styles.authStep} ${styles.authStepCurrent}`}>
                            <div className={styles.authStepNumber}><span>2</span></div>
                            <div className={styles.authStepText}>Check email for verification link</div>
                        </div>
                        <div className={styles.authStepLine}></div>
                        <div className={styles.authStep}>
                            <div className={styles.authStepNumber}><span>3</span></div>
                            <div className={styles.authStepText}>Your almost there!</div>
                        </div>
                    </div>

                    <Button onClick={handleResendActivation} type="secondary" state={isSubmitting ? "disabled" : undefined} size='lg'>
                        Resend activation email
                    </Button>

                    {
                        resendError &&
                        <div className={styles.errorMessage}>{resendError}</div>
                    }
                </div>
            )}

        </div>
    );
};

export default SignupPage;

