import React, {useCallback, useState} from 'react';
import {
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import style from "./style.module.css";
import useDataLoadingStatus from "hooks/useDataLoadingStatus";
import {getTemplateList} from "api/qencode-account-api";
import Pagination from "components/Table/Pagination";
import { ReactComponent as Template } from "assets/images/Template.svg";
import Table from "components/Table";
import globalFilterFn from "components/Table/columnFilters/exactCaseInsensitiveFilter";
import MoreRowActions, { modals } from "pages/Transcoding/TemplatesPage/MoreRowActions";
import DeleteModal from "pages/Transcoding/TemplatesPage/DeleteModal";
import DuplicateModal from "pages/Transcoding/TemplatesPage/DuplicateModal";
import RenameModal from "pages/Transcoding/TemplatesPage/RenameModal";
import CreateTemplateButton from "pages/Transcoding/TemplatesPage/CreateTemplateButton";
import getCodecReadable from "helpers/getCodecReadable";
import DateFormatted from "components/DateFormatted";
import Search from "components/Search";
import ResolutionsCell from "pages/Transcoding/TemplatesPage/ResolutionsCell";
import {Link, useNavigate} from "react-router-dom";
import {columnIds} from "pages/Transcoding/TemplatesPage/config";
import DetailsCell from "pages/Transcoding/TemplatesPage/DetailsCell";
import TableWrapper from "components/TableWrapper";


export default function TemplatesPage() {
    const navigate = useNavigate();
    const loadingStatus = useDataLoadingStatus(useCallback((properties) => getTemplateList({
        ...properties,
    }), []));
    const {
        data,
        refresh,
    } = loadingStatus;

    const [openedModal, setOpenedModal] = useState();
    const [selectedTemplateId, setSelectedTemplateId] = useState();
    const selectedTemplate = data.find((item) => item.id === selectedTemplateId);

    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([{
      id: columnIds.dateCreated,
      desc: true,
    }]);

    const columns = React.useMemo(
        () => [
            {
                id: columnIds.name,
                accessorKey: 'name',
                cell: info => {
                    const templateName = info.getValue();
                    const id = info.row?.original?.id;

                    return (
                        <span className={ style.cellLimited }>
                            <Link
                              to={`/request_builder?templateId=${ id }`}
                              className="linkEllipsis"
                              state={{ templateId: id }}
                            >
                              { templateName }
                            </Link>
                        </span>
                    )
                },
                header: () => <span>Template Name</span>,
            },
            {
                id: columnIds.details,
                cell: (info) => {
                    return (
                        <DetailsCell info={info}/>
                    );
                },
                header: () => <span >Details</span>,
            },
            {
                id: columnIds.codec,
                cell: (info) => {
                    const codec = getCodecReadable(info.row.original?.query?.query?.format?.[0]);
                    return (
                        <span>{ codec }</span>
                    );
                },
            },
            {
                id: columnIds.resolution,
                cell: (info) => (
                    <ResolutionsCell
                      info={info}
                    />
                )
            },
            {
                id: columnIds.dateCreated,
                accessorKey: 'created',
                sortingFn: 'datetime',
                cell: info => (
                    <span>
                        <DateFormatted utcString={ info.getValue() } />
                    </span>
                ),
                header: () => <span>Date Created</span>,
            },
            {
                id: columnIds.lastUpdate,
                accessorKey: 'last_update',
                sortingFn: 'datetime',
                cell: (info) => {
                    let date = info.getValue();
                    if (!date) {
                      date = info.row?.original?.created;
                    }
                    return (
                        <span>
                            <DateFormatted utcString={ date } />
                        </span>
                    )
                },
                header: () => <span>Date Modified</span>,
            },
            {
                id: columnIds.moreActions,
                enableSorting: false,
                cell: ({ row }) => (
                    <MoreRowActions
                        templateId={row.original.id}
                        handleOpenModal={handleOpenModal}
                    />
                ),
            },
        ],
        []
    );
    
    function handleOpenModal(type, id) {
        setSelectedTemplateId(id);
        setOpenedModal(type);
    }

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        /* Sorting */
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        /* Filtering */
        globalFilterFn,
        getFilteredRowModel: getFilteredRowModel(),
        onGlobalFilterChange: setGlobalFilter,
        /* Pagination */
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,

        state: {
            sorting,
            globalFilter,
            pagination,
        },
    });

    function handleCloseModals() {
        handleOpenModal();
    }

    const modalProps = {
        templateId: selectedTemplateId,
        handleCancel: handleCloseModals,
        onSuccessfulFinish: refresh,
    };
    
    function goToTranscoding() {
      navigate('/request_builder');
    }

    return (
        <TableWrapper>
            <div className={style.toolbar}>
              <Search setGlobalFilter={setGlobalFilter} globalFilter={globalFilter}/>
                <CreateTemplateButton onClick={goToTranscoding}/>
            </div>
            <Table
                { ...loadingStatus }
                customClassTable={ style.table }
                table={table}
                minWidth={666}
                getEmptyComponent={() => <EmptyMessage onClick={goToTranscoding}/>}
                columnIdToClassName={{
                  [columnIds.details]: style.detailsColumn,
                  [columnIds.codec]: style.codecColumn,
                  [columnIds.dateCreated]: style.dateCreatedColumn,
                  [columnIds.lastUpdate]: style.dateModifiedColumn,
                  [columnIds.moreActions]: style.moreActionsColumn,
                }}
            />
            <Pagination table={ table } />
            <DeleteModal
                { ...modalProps }
                name={selectedTemplate?.name}
                open={ openedModal === modals.deletion }
            />
            <DuplicateModal
                { ...modalProps }
                open={ openedModal === modals.duplicate }
            />
            <RenameModal
                { ...modalProps }
                open={ openedModal === modals.rename }
            />
        </TableWrapper>
    );
}

function EmptyMessage({ onClick }) {
    return (
        <div className="tableNodata">
            <div className="tableNodataImage">
                <Template/>
            </div>
            <h3 className="tableNodataTitle">
                You don't have any Transcoding Templates yet
            </h3>
            <p className="tableNodataDescription">
                Start by creating a new transcoding template
            </p>
            <CreateTemplateButton onClick={onClick}/>
        </div>
    )
}

