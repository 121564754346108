import {ReactComponent as MediaStorageImage} from 'assets/images/MediaStorage.svg'
import {ReactComponent as RegionImage} from 'assets/images/RegionWithCircle.svg';
import {ReactComponent as LiveStreamingImage} from 'assets/images/LiveStreaming.svg';
import {ReactComponent as BucketImage} from 'assets/images/BucketWithCircle.svg';

export const typeIds = {
  buckets: 'buckets',
  liveStream: 'live_stream',
  liveStreamProject: 'live_stream_project',
  regions: 'regions',
  storage: 'storage',
};

export const typesWithSearch = [
  typeIds.buckets,
  typeIds.liveStreamProject,
];

const typesOptionsWithNoData = [
  {
    label: 'Regions',
    value: typeIds.regions,
    noDataImage: <RegionImage/>,
    noDataDescription: 'No data found',
  },
  {
    label: 'Storage',
    value: typeIds.storage,
    noDataImage: <MediaStorageImage/>,
    noDataDescription: 'No data found',
  },
  {
    label: 'Live streaming',
    value: typeIds.liveStream,
    noDataImage: <LiveStreamingImage/>,
    noDataDescription: 'No data found',
  },
  {
    label: 'Buckets',
    value: typeIds.buckets,
    noDataImage: <BucketImage/>,
    noDataDescription: 'No data found',
  },
  {
    label: 'Live stream projects',
    value: typeIds.liveStreamProject,
    noDataImage: <LiveStreamingImage/>,
    noDataDescription: 'No data found',
  }
];

export const typesOptions = typesOptionsWithNoData.map(({label, value}) => ({
  label,
  value,
}));

export const typeToNoData = typesOptionsWithNoData.reduce((map, {value, noDataImage, noDataDescription}) => {
  return {
    ...map,
    [value]: {
      noDataImage,
      noDataDescription,
    },
  };
}, {});

export const columnIds = {
  name: 'name',
  amount: 'amount',
  bandwidth: 'bandwidth',
  requests: 'requests',
  regions: 'regions',
};

export const typeIdToSubRowProperty = {
  [typeIds.buckets]: 'regions',
  [typeIds.storage]: 'buckets',
  [typeIds.regions]: 'services',
  [typeIds.liveStream]: 'streams',
  [typeIds.liveStreamProject]: 'regions',
};

export const typeIdToNameProperty = {
  [typeIds.buckets]: ['name', 'region'],
  [typeIds.storage]: ['region', 'bucket_name'],
  [typeIds.regions]: ['region', 'service_name'],
  [typeIds.liveStream]: ['region', 'project_name'],
  [typeIds.liveStreamProject]: ['name', 'region'],
};
