import PropTypes from "prop-types";
import React from "react";

export const Home = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path d="M3.5 14.5C2.94772 14.5 2.5 14.0523 2.5 13.5V8.12053C2.5 7.11983 2.99897 6.18501 3.83037 5.62808L7.38691 3.24563C8.0604 2.79448 8.9396 2.79448 9.61309 3.24563L13.1696 5.62808C14.001 6.18501 14.5 7.11983 14.5 8.12053V13.5C14.5 14.0523 14.0523 14.5 13.5 14.5H11.0497C10.4974 14.5 10.0497 14.0523 10.0497 13.5V10.6469C10.0497 10.0947 9.602 9.64695 9.04971 9.64695H7.95029C7.398 9.64695 6.95029 10.0947 6.95029 10.6469V13.5C6.95029 14.0523 6.50257 14.5 5.95029 14.5H3.5Z" fill="currentColor"/>
    </svg>    
  );
};

Home.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Home.defaultProps = {
  color: 'currentColor', // Or any default color
};