import PropTypes from "prop-types";
import React from "react";

export const StorageTemp = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M4.25586 5.21176C4.61755 3.76497 5.9175 2.75 7.40882 2.75H12.5912C14.0825 2.75 15.3824 3.76497 15.7441 5.21176L17.0677 10.5058C17.1888 10.9902 17.25 11.4877 17.25 11.987V14.5714C17.25 16.0508 16.0508 17.25 14.5714 17.25H5.42857C3.94924 17.25 2.75 16.0508 2.75 14.5714V11.987C2.75 11.4877 2.81124 10.9902 2.93234 10.5058L4.25586 5.21176ZM7.40882 1.25C5.2292 1.25 3.32928 2.73341 2.80064 4.84796L1.47713 10.142C1.32628 10.7454 1.25 11.365 1.25 11.987V14.5714C1.25 16.8792 3.12081 18.75 5.42857 18.75H14.5714C16.8792 18.75 18.75 16.8792 18.75 14.5714V11.987C18.75 11.365 18.6737 10.7454 18.5229 10.142L17.1994 4.84796C16.6707 2.73341 14.7708 1.25 12.5912 1.25H7.40882ZM16.1715 12.8571C16.1715 11.9104 15.404 11.1429 14.4572 11.1429H5.5429C4.59613 11.1429 3.82861 11.9104 3.82861 12.8571V14.4571C3.82861 15.4039 4.59613 16.1714 5.5429 16.1714H14.4572C15.404 16.1714 16.1715 15.4039 16.1715 14.4571V12.8571ZM12.8572 13.7143C12.8572 13.2409 13.2409 12.8571 13.7143 12.8571C14.1877 12.8571 14.5715 13.2409 14.5715 13.7143C14.5715 14.1877 14.1877 14.5714 13.7143 14.5714C13.2409 14.5714 12.8572 14.1877 12.8572 13.7143ZM6.57147 13.2H10C10.3156 13.2 10.5715 13.4558 10.5715 13.7714C10.5715 14.087 10.3156 14.3429 10 14.3429H6.57147C6.25588 14.3429 6.00004 14.087 6.00004 13.7714C6.00004 13.4558 6.25588 13.2 6.57147 13.2Z" fill="currentColor"/>
      <path d="M9.97892 4.13818C10.7227 4.13818 11.4288 4.35975 11.9918 4.75572C12.1525 4.86826 12.1711 5.06293 12.0329 5.19051C11.8943 5.3181 11.6513 5.3303 11.4904 5.21776C11.0658 4.91962 10.5368 4.75428 9.97882 4.75428C8.68299 4.75428 7.60769 5.64736 7.58621 6.75659C7.56472 7.86581 8.63999 8.75889 9.97814 8.75889C10.2145 8.75889 10.4458 8.7312 10.6673 8.67726C10.8754 8.62653 11.0979 8.71705 11.1645 8.87944C11.2313 9.04183 11.1163 9.2146 10.9073 9.26533C10.6085 9.33778 10.2962 9.37499 9.97804 9.37499C8.18528 9.37499 6.77038 8.19953 6.80735 6.75659C6.84432 5.31364 8.25996 4.13818 9.97892 4.13818ZM12.4755 7.86139C12.6595 7.96957 12.7042 8.17574 12.5766 8.32403C12.4285 8.49576 12.2549 8.65205 12.0597 8.78955C11.8863 8.91148 11.6207 8.89408 11.4704 8.75152C11.3235 8.61194 11.3417 8.40395 11.5113 8.28462C11.6569 8.18192 11.7868 8.06511 11.8978 7.93678C12.0283 7.78545 12.288 7.75099 12.4755 7.86139ZM12.7882 6.52161C13.0145 6.52612 13.1948 6.68005 13.1941 6.86313C13.1939 6.93469 13.1901 7.00642 13.1829 7.0782C13.1722 7.18381 13.1539 7.28804 13.1283 7.39046C13.0833 7.57016 12.8656 7.6846 12.6412 7.64269C12.4191 7.60119 12.2796 7.42265 12.3253 7.24457C12.345 7.16755 12.3593 7.08909 12.3679 7.0095C12.3737 6.95507 12.3769 6.90099 12.3774 6.84734C12.3792 6.66408 12.5617 6.51708 12.7882 6.52161ZM10.172 5.29336C10.3846 5.29336 10.5575 5.43128 10.5583 5.60141L10.5642 6.91616C10.5648 7.03549 10.4779 7.14408 10.3411 7.19489L9.21164 7.6129C9.01513 7.68534 8.78204 7.61928 8.69143 7.46535C8.60105 7.31141 8.68686 7.1279 8.88269 7.05545L9.78468 6.72013L9.78663 5.60141C9.7869 5.44829 9.92672 5.32127 10.1096 5.29739L10.172 5.29336ZM12.7533 5.47887C12.8414 5.60406 12.8988 5.68954 12.9618 5.82492C13.0418 5.99611 12.9414 6.18696 12.7334 6.25166C12.5221 6.3173 12.2852 6.22717 12.2051 6.05366C12.1584 5.9523 12.1204 5.8998 12.0549 5.80619C11.9442 5.64752 12.0066 5.44574 12.1965 5.35387C12.3899 5.26022 12.6399 5.3173 12.7533 5.47887Z" fill="#FC3C3C"/>
    </svg>    
  );
};

StorageTemp.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

StorageTemp.defaultProps = {
  color: 'currentColor', // Or any default color
};