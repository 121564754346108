import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import SkeletonBarChart from 'assets/images/SkeletonBarChart.svg';

import style from './style.module.css'
import Tooltip from './Tooltip';
import {format} from "date-fns";
import { ResponsiveBar } from "@nivo/bar";

function Chart({
  loading,
  chartData,
  keyToColor = {},
  dataKeys = [],
  color,
  legend,
  quantity,
  contentComponent,
  singleRow,
  formatAxisY,
  gridYValues,
  children,
  }) {
  const amountMonthly = useMemo(() => {
    const stats = {};
    
    for (let {date, ...restObj} of chartData) {
      if (typeof date === 'string') {
        const yearMonth = date.slice(0, 7);
        stats[yearMonth] = stats[yearMonth] || {
          yearMonth,
        };
        for (let [dataKey, dataValue] of Object.entries(restObj)) {
          stats[yearMonth][dataKey] = stats[yearMonth][dataKey] || 0;
          stats[yearMonth][dataKey] += dataValue || 0;
        }
      } else {
        console.error(date, restObj)
      }
    }
    
    return Object.values(stats);
  }, [chartData]);
  
  const quantityMonthly = useMemo(() => {
    const stats = {};
    
    if (quantity) {
      for (let {date, ...restObj} of Object.values(quantity)) {
        if (typeof date === 'string') {
          const yearMonth = date.slice(0, 7);
          stats[yearMonth] = stats[yearMonth] || {
            yearMonth,
          };
          for (let [dataKey, dataValue] of Object.entries(restObj)) {
            stats[yearMonth][dataKey] = stats[yearMonth][dataKey] || 0;
            stats[yearMonth][dataKey] += dataValue || 0;
          }
        } else {
          console.error(date, restObj)
        }
      }
    }
    
    return stats;
  }, [quantity]);

  if (loading) {
    return (
      <img className={style.imgWrapper} src={ `${ SkeletonBarChart }` } alt="Placeholder while chart is loading"/>
    );
  }
  
  if (children) {
    return <>{ children }</>;
  }

  const commonProps = {
    singleRow,
    legend,
    color,
    keyToColor,
    contentComponent,
    keys: dataKeys,
    formatAxisY,
    gridYValues,
  };
  
  if (chartData.length > 60) {
    const data = amountMonthly;

    return <div style={{width: '100%', height: '438px'}}>
      <MyResponsiveBar
        key="monthly"
        {...commonProps}
        data={data}
        indexBy="yearMonth"
        quantity={quantityMonthly}
        monthly={true}
      />
    </div>
  } else {
    const data = chartData;

    return <div style={{width: '100%', height: '438px'}}>
      <MyResponsiveBar
        key="daily"
        {...commonProps}
        data={data}
        indexBy='date'
        quantity={quantity}
      />
    </div>
  }
  
}

Chart.propTypes = {
  chartData: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  dataKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const MyResponsiveBar = ({
  data,
  indexBy,
  keys,
  keyToColor,
  color,
  legend,
  monthly,
  quantity,
  singleRow,
  formatAxisY,
  gridYValues,
  contentComponent: TooltipContent,
  }) => {
    const [hoveredColumnIndex, setHoveredColumnIndex] = useState();
    
    function formatNumber(value) {
      return Number(value).toFixed(2);
    }

    return (
      <ResponsiveBar
        barAriaLabel={(barDatum) => {
          if (Number.isFinite(hoveredColumnIndex)) {
            return barDatum.index === hoveredColumnIndex ? 'active-row' : 'inactive-row'
          }
          return '';
        }}
        onMouseEnter={function noRefCheck(data){
          setHoveredColumnIndex(data.index);
        }}
        onMouseLeave={function noRefCheck(){
          setHoveredColumnIndex();
        }}
        data={data}
        keys={ keys }
        indexBy={indexBy}
        margin={{
          top: 20,
          right: 20,
          bottom: 50,
          left: 80,
        }}
        padding={0.2}
        innerPadding={2}
        borderRadius={4}
        gridYValues={gridYValues}
        valueScale={{
          type: 'linear',
        }}
        indexScale={{
          type: 'band',
          round: true,
        }}
        borderColor={{
          from: 'color',
          modifiers: [
            [
              'darker',
              1.6
            ]
          ]
        }}
        enableLabel={false}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: ( monthly && data.length > 11 ) ? 30 : 0,
          truncateTickAt: 60,
          format: (tickValue) => {
            let template = 'MMM dd';
            if (monthly) {
              template = 'MMM yyyy';
            }
            else {
              template = data.length > 15 ? 'd' : 'MMM dd';
            }
            return format(tickValue, template)?.toUpperCase();
          }
        }}
        axisLeft={{
          tickSize: 0,
          format: formatAxisY ? formatAxisY : formatNumber,
          legend,
          legendOffset: -70,
          legendPosition: 'middle',
        }}
        role="application"
        tooltip={function noRefCheck({
                                       data,
                                       indexValue,
                                       id,
                                       absX,
                                     }) {
          return <Tooltip
            indexValue={indexValue}
            monthly={monthly}
            absX={absX}
          >
            <TooltipContent
              data={data}
              indexValue={indexValue}
              quantity={quantity}
              id={id}
              singleRow={singleRow}
              keys={keys}
            />
          </Tooltip>;
        }}
        colors={({id}) => {
          return color || keyToColor[id];
        }}
        motionConfig="stiff"
        theme={{
          tooltip: {
            wrapper: {
              zIndex: 9999, // just over Topbar
            },
          },
          axis: {
            ticks: {
              text: {
                fontSize: 12,
              }
            }
          },
          grid: {
            line: {
              stroke: '#E3E6E9',
            }
          }
        }}
      />
    )
  }
  
Chart.propTypes = {};

export default Chart;