import React from 'react';
import { useAuth } from '../context/AuthContext';
import { useUser } from '../context/UserContext'; 

// import { Link } from 'react-router-dom';

import { Tutorial, APIReference, SDK } from 'icons';

import { MenuItem } from 'components/Sidebar/MenuItem'

import UserDropdown from './UserDropdown';

import styles from './Topbar.module.css';

const Topbar = ({pageName, userRole}) => {
  const { isAuthenticated } = useAuth();

  const { userData } = useUser(); 

  const topBarMenuItems = [
      {
          name: "Resources",
          items: [
              {
                  label: 'Tutorials',
                  icon: <Tutorial />,
                  to: 'https://docs.qencode.com/tutorials',
                  external: true
              }, 
              {
                  label: 'API Reference',
                  icon: <APIReference />,
                  to: 'https://docs.qencode.com/api-reference',
                  external: true
              }, 
              {
                  label: 'SDK',
                  icon: <SDK />,
                  to: 'https://github.com/qencode-dev/',
                  external: true
              }, 
          ]
      }
  ];  
 

  return (
    <nav className={styles.container}>
        <div className={styles.content}>
            <div className={styles.pageName}>{pageName}</div>
            <div className={styles.rightPanel}>

                {topBarMenuItems.map((section) => (
                    <div key={section.name} className={styles.topBarMenu}>
                        {/* <div className={styles.sectionName}>{section.name}</div> */}
                        {section.items.map((item) => (
                            <MenuItem
                                key={item.label}
                                label={item.label}
                                icon={item.icon}
                                to={item.to}
                                isActive={item.isActive}
                                isBeta={item.isBeta}
                                submenuItems={item.submenuItems}
                                //isCollapsed={isCollapsed}
                                external={item.external}
                                //scrollY={scrollY}
                            />
                        ))}
                    </div>
                ))}

                <div className={styles.delimeter}></div>

                {isAuthenticated && <UserDropdown userData={userData} userRole={userRole}/> }
            </div>
        </div>
    </nav>
  );
};

export default Topbar;



// import React from 'react';
// import { useAuth } from '../context/AuthContext';
// import { useUser } from '../context/UserContext'; 

// // import { Link } from 'react-router-dom';

// import { Tutorial, APIReference, SDK } from 'icons';

// import { MenuItem } from 'components/Sidebar/MenuItem'

// import UserDropdown from './UserDropdown';

// import styles from './Topbar.module.css';

// const Topbar = ({pageName, userRole}) => {
//   const { isAuthenticated } = useAuth();

//   const { userData } = useUser(); 

//   const topBarMenuItems = [
//       {
//           name: "Resources",
//           items: [
//               {
//                   label: 'Tutorials',
//                   icon: <Tutorial />,
//                   to: 'https://docs.qencode.com/tutorials',
//                   external: true
//               }, 
//               {
//                   label: 'API Reference',
//                   icon: <APIReference />,
//                   to: 'https://docs.qencode.com/api-reference',
//                   external: true
//               }, 
//               {
//                   label: 'SDK',
//                   icon: <SDK />,
//                   to: 'https://github.com/qencode-dev/',
//                   external: true
//               }, 
//           ]
//       }
//   ];  
 

//   return (
//     <nav className={styles.container}>
//       <div className={styles.pageName}>{pageName}</div>
//       <div className={styles.rightPanel}>

//         {topBarMenuItems.map((section) => (
//             <div key={section.name} className={styles.topBarMenu}>
//                 {/* <div className={styles.sectionName}>{section.name}</div> */}
//                 {section.items.map((item) => (
//                     <MenuItem
//                         key={item.label}
//                         label={item.label}
//                         icon={item.icon}
//                         to={item.to}
//                         isActive={item.isActive}
//                         isBeta={item.isBeta}
//                         submenuItems={item.submenuItems}
//                         //isCollapsed={isCollapsed}
//                         external={item.external}
//                         //scrollY={scrollY}
//                     />
//                 ))}
//             </div>
//         ))}

//         <div className={styles.delimeter}></div>

//         {isAuthenticated && <UserDropdown userData={userData} userRole={userRole}/> }
//       </div>
//     </nav>
//   );
// };

// export default Topbar;




// import React from 'react';
// import { useAuth } from '../context/AuthContext';
// import { useUser } from '../context/UserContext'; 

// import UserDropdown from './UserDropdown';

// import styles from './Topbar.module.css';

// const Topbar = ({pageName, isCollapsed}) => {
//   const { isAuthenticated } = useAuth();

//   const { userData } = useUser(); 

//   return (
//     <nav className={styles.container}>
//       <div className={styles.pageName}>{pageName}</div>
//       <div className={styles.rightPanel}>
//         {isAuthenticated && <UserDropdown userData={userData}/> }
//       </div>
//     </nav>
//   );
// };

// export default Topbar;