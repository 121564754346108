import React, {useCallback, useState} from 'react';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import pageStyle from './index.module.css';
import CreateDomainButton from "./CreateDomainButton";
import CopyToClipboard from "components/CopyToClipboard";
import domainTypes from "helpers/domainTypes";
import useDataLoadingStatus from "hooks/useDataLoadingStatus";
import {listDomainsAndSubscriptions} from "api/qencode-content-delivery-api";
import MoreActionsPopover from "pages/DomainsPage/MoreActionsPopover";
import Table from "components/Table";
import EmptyMessage from "pages/DomainsPage/TableStatus/EmptyMessage";
import DateFormatted from "components/DateFormatted";
import TlsStatusModal from "pages/DomainsPage/TlsStatusModal";
import DeleteDomainModal from "pages/DomainsPage/DeleteDomainModal";
import CreateDomainModal from "pages/DomainsPage/CreateDomainModal";
import Search from "components/Search";
import Pagination from "components/Table/Pagination";
import TlsStatusButton from "pages/DomainsPage/TlsStatusButton";
import {useLocation, useNavigate} from "react-router-dom";
import getGlobalFilterWithValueMap from "components/Table/columnFilters/getGlobalFilterWithValueMap";
import TableWrapper from "components/TableWrapper";

const modals = {
  'create': 'create',
  'delete': 'delete',
  'tlsSubscription': 'tlsSubscription',
};

const statuses = {
  issued: 'Issued',
  pending: 'Pending',
  failed: 'Failed',
  '-': '-',
};

const columnIds = {
  'domainName': 'domainName',
  'domainId': 'domainId',
  'domainType': 'domainType',
  'tlsType': 'tlsType',
  'tlsStatus': 'tlsStatus',
  'dateCreated': 'dateCreated',
  'moreActions': 'moreActions',
};

const globalFilterFn = getGlobalFilterWithValueMap({
  [columnIds.domainType]: domainTypes.reduce((acc, typeObj) => {
    acc[typeObj.value] = typeObj.label;
    return acc;
  }, {}),
  [columnIds.tlsStatus]: statuses,
});

export default function DomainsPage() {
  const loadingStatus = useDataLoadingStatus( listDomainsAndSubscriptions );
  const {
    data,
    refresh,
  } = loadingStatus;
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [openedModal, setOpenedModal] = useState();
  const [selectedDomain, setSelectedDomain] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const navigate = useNavigate();
  const location = useLocation();

  const removeQueryParam = useCallback((paramKey) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete(paramKey);
    const newSearch = searchParams.toString();
    navigate({ search: newSearch, replace: true });
  }, [location.search, navigate]);

  React.useEffect(() => {
    if (location.search.includes('createDomainModal')) {
        setOpenedModal(modals.create);
        removeQueryParam('createDomainModal');
    }
  }, [location, removeQueryParam]);

  function handleCancel(e) {
    e?.preventDefault();
    setOpenedModal();
    setSelectedDomain();
  }

  const columns = React.useMemo(
    () => [
      {
        id: columnIds.domainName,
        accessorKey: 'domain_name',
        cell: (info) => {
          const domainName = info.getValue();
          
          return (
            <div className={ `cellContainer ${ pageStyle.cellContainer }` } >
              <div className={ pageStyle.cellLimited }>
                <TlsStatusButton
                  domain={ info.row.original }
                  openTlsStatusModal={openTlsStatusModal}
                  >
                  { domainName }
                </TlsStatusButton>
              </div>
                { domainName && (
                  <CopyToClipboard text={ domainName }/>
                ) }
            </div>
          )
        },
        header: () => <span>Domain Name</span>,
      },
      {
        id: columnIds.domainId,
        accessorKey: 'id',
        cell: (info) => (
          <div className={ pageStyle.domainCellContent }>
            <span className={ pageStyle.domainId }>
              { info.getValue() }
            </span>
            <CopyToClipboard text={ info.getValue() }/>
          </div>
        ),
        header: () => <span>Domain Id</span>,
      },
      {
        id: columnIds.domainType,
        accessorKey: 'type',
        cell: info => (
          <span>
            { domainTypes.find((type) => type.value === info.getValue())?.label }
          </span>
        ),
        header: () => <span>Domain Type</span>,
      },
      {
        id: columnIds.tlsType,
        accessorKey: 'tlsSubscription',
        cell: info => {
          return (
            <>
              { info.getValue() ? 'TLS Subscription' : '-' }
            </>
          )
        },
        header: () => <span>TLS Type</span>,
      },
      {
        id: columnIds.tlsStatus,
        accessorKey: 'tlsStatus',
        cell: (info) => {
          const status = info.getValue();
          return (
            <TlsStatusButton
              domain={ info.row.original }
              openTlsStatusModal={openTlsStatusModal}
            >
                { statuses[status] }
            </TlsStatusButton>
          )
        },
        header: () => <span>TLS Status</span>
      },
      {
        id: columnIds.dateCreated,
        accessorKey: 'created',
        sortingFn: 'datetime',
        cell: info => <DateFormatted utcString={ info.getValue() }/>,
        header: () => <span>Date Created</span>
      },
      {
        id: columnIds.moreActions,
        enableSorting: false,
        cell: ({ row }) =>  (
          <MoreActionsPopover
            domain={ row.original }
            handleStartDeletion={ (domain) => {
              setOpenedModal(modals.delete);
              setSelectedDomain(domain);
            } }
          />
        )
      }
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    /* Sorting */
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    /* Filtering */
    globalFilterFn,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      globalFilter,
      pagination,
    },
  });

  function handleFinishCreatingDomain(newDomain) {
    setSelectedDomain( newDomain );
    setOpenedModal( modals.tlsSubscription );
    refresh();
  }

  function openCreateModal() {
    setOpenedModal( modals.create );
  }

  function openTlsStatusModal(domain) {
    setSelectedDomain( domain );
    setOpenedModal( modals.tlsSubscription );
  }

  return (
    <TableWrapper>
      <div className={pageStyle.toolbar}>
        <Search setGlobalFilter={setGlobalFilter} globalFilter={globalFilter}/>
        <CreateDomainButton handleOpen={ openCreateModal } />
      </div>
      <Table
        { ...loadingStatus }
        customClassTable={ pageStyle.table }
        table={table}
        minWidth={720}
        getEmptyComponent={() => <EmptyMessage openCreateModal={ openCreateModal }/>}
        columnIdToClassName={{
          [columnIds.tlsType]: pageStyle.tlsType,
          [columnIds.tlsStatus]: pageStyle.tlsStatus,
          [columnIds.moreActions]: pageStyle.moreActionsColumn,
          [columnIds.domainType]: pageStyle.domainType,
        }}
      />
      <Pagination table={ table } />
      <DeleteDomainModal
        open={ openedModal === modals.delete }
        selectedDomain={ selectedDomain }
        handleCancel={ handleCancel }
        onSuccessfulFinish={ refresh }
      />
      <CreateDomainModal
        open={ openedModal === modals.create }
        handleCancel={handleCancel}
        onSuccessfulFinish={handleFinishCreatingDomain}
      />
      <TlsStatusModal
        open={ openedModal === modals.tlsSubscription }
        handleCancel={ handleCancel }
        selectedDomain={ selectedDomain }
      />
    </TableWrapper>
  );
}


