import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as ResolutionIcon} from 'assets/images/Resolution.svg';
import {getCoreRowModel, getPaginationRowModel, useReactTable} from "@tanstack/react-table";
import Table from "components/Table";
import formatNumber from "helpers/formatNumber";
import MeterForPercentage from "components/Meter/MeterForPercentage";
import style from './style.module.css';
import getTableValueFallback from "helpers/getTableValueFallback";

const columnIds = {
  resolutions: 'resolutions',
  minutes: 'minutes',
};

function TopResolutions({
  stats,
  noDataImage,
}) {

  const columns = useMemo(() => [
    {
      id: columnIds.resolutions,
      header: () => <span>Top Resolutions</span>,
      accessorKey: 'name',
      enableSorting: false,
      meta: {
        colspan: 2,
        className: style.topResolutionsColumn,
      },
      cell: (info) => {
        const {
          name,
          duration,
          percent,
        } = info.row.original;
        return (
          <MeterForPercentage
            title={name}
            percent={percent}
            rawValue={ formatNumber(duration).toUpperCase() }
            maxPercent={100}
          />
        )
      },
    },
    {
      id: columnIds.minutes,
      header: () => <span>Minutes</span>,
      accessorKey: 'duration',
      cell: (info) => <span>{ getTableValueFallback(info.getValue(), formatNumber)}</span>,
      enableSorting: false,
      meta: {
        align: 'right',
        colspan: 0,
      },
    },
  ], []);

  const table = useReactTable({
    data: stats.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination: {
        pageIndex: 0,
        pageSize: 5,
      },
    }
  });
  
  return (
    <Table
      { ...stats }
      table={table}
      horizontalLinesInvisible
      noDataImage={ noDataImage ? noDataImage : <ResolutionIcon/>}
      noDataDescription="No data found"
      skeletonRowCount={5}
    />
  );
}

TopResolutions.propTypes = {
  stats: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        duration: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        percent: PropTypes.number.isRequired,
      })
    ).isRequired,
  }),
};

export default TopResolutions;