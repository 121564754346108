import React from 'react';
import {Button} from "components/Button";
import {Add} from "icons";
import PropTypes from "prop-types";

function CreateTemplateButton({ onClick }) {
    return (
        <>
            <Button
                icon={<Add/>}
                onClick={onClick}
            >
              Create New Template
            </Button>
        </>
    )
}

CreateTemplateButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CreateTemplateButton;