
const fallback = '—';

export default function getTableValueFallback(value, format) {
  if (typeof value === 'string') {
    if (value.trim().length === 0) {
      return fallback;
    }
  }
  if (typeof value === 'number') {
    if (Number.isFinite(value)) {
      if (typeof format === 'function') {
        return format(value);
      }
      return value;
    } else {
      return fallback;
    }
  }
  if (value === null || value === undefined) {
    return fallback;
  }
  return value;
}
