import React from 'react';
import PropTypes from 'prop-types';
import {OptionsMenu} from "components/OptionsMenu";
import {Delete, Rename} from "icons";
import {modals} from 'pages/Streaming/StreamsPage/config'

function MoreRowActions({ handleOpenModal, streamId }) {
    return (
        <div className="cellContainer">
            <OptionsMenu
                menuItems={
                    [
                        {
                            icon: <Rename label="Rename"/>,
                            text: 'Rename',
                            onClick: () => handleOpenModal( modals.rename, streamId ),
                        },
                        {
                            icon: <Delete label="Remove" />,
                            onClick: () => handleOpenModal( modals.delete, streamId ),
                            text: "Delete",
                            danger: true,
                        }
                    ]
                }
            />
        </div>
    )
}

MoreRowActions.propTypes = {
    handleOpenModal: PropTypes.func.isRequired,
    streamId: PropTypes.string.isRequired,
};

export default MoreRowActions;