import React, {useCallback, useState} from 'react';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import pageStyle from './index.module.css';
import useDataLoadingStatus from "hooks/useDataLoadingStatus";
import MoreActionsPopover from "pages/Streaming/StreamProjectsPage/MoreActionsPopover";
import Table from "components/Table";
import globalFilterFn from "components/Table/columnFilters/exactCaseInsensitiveFilter";
import MaskedKey from "components/MaskedKey";
import Search from "components/Search";
import DeleteKeyModal from "pages/Streaming/StreamProjectsPage/DeleteKeyModal";
import Pagination from "components/Table/Pagination";
import TableWrapper from "components/TableWrapper";
import {getProjectList} from "api/qencode-account-api";
import { ReactComponent as ProjectWithCircle } from "assets/images/ProjectWithCircle.svg";
import {modals} from './config';
import RecreateKeyModal from "pages/Streaming/StreamProjectsPage/RecreateKeyModal";
import {Button} from "components/Button";
import {Add} from "icons";
import CreateModal from "pages/Streaming/StreamProjectsPage/CreateModal";
import classNames from "classnames";
import DateFormatted from "components/DateFormatted";

const columnIds = {
  'name': 'name',
  'apiKey': 'apiKey',
  'dateCreated': 'dateCreated',
  'moreActions': 'moreActions',
};

export default function StreamingProjectsPage() {
  const [openedModal, setOpenedModal] = useState();
  const [selectedSigningKey, setSelectedSigningKey] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const func = useCallback((props) => {
    return getProjectList({
      ...props,
      type: 'live',
    })
  }, []);
  
  const loadingStatus = useDataLoadingStatus( func );
  const {
    data,
    refresh,
  } = loadingStatus;
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);

  function handleOpenModal( modalName, apiKey ) {
    setOpenedModal( modalName );
    setSelectedSigningKey( apiKey );
  }

  function closeModalAndFinish() {
    setOpenedModal();
    setSelectedSigningKey();
    refresh();
  }

  function handleCloseModal(e) {
    e?.preventDefault();
    setOpenedModal();
  }
  
  const columns = React.useMemo(
    () => [
      {
        id: columnIds.name,
        accessorKey: 'name',
        cell: info => (
          <div className="cellContainer">
            <span className={ classNames(pageStyle.projectName) }>
              { info.getValue() }
            </span>
          </div>
        ),
        header: () => <span>Project Name</span>,
        meta: {
          className: pageStyle.nameColumn,
        }
      },
      {
        id: columnIds.apiKey,
        accessorKey: 'api_key',
        cell: (info) => (
          <MaskedKey rawKey={ info.getValue() } withCopy={true} />
        ),
        enableSorting: false,
        header: () => <span>Api Key</span>,
      },
      {
        id: columnIds.dateCreated,
        accessorKey: 'create_datetime',
        sortingFn: 'datetime',
        cell: info => <DateFormatted date={ info.getValue() }/>,
        header: () => <span>Date Created</span>,
        meta: {
          className: pageStyle.dateCreatedColumn,
        },
      },
      {
        id: columnIds.moreActions,
        enableSorting: false,
        cell: ({ row }) => (
          <MoreActionsPopover
            selectedKey={ row.original }
            onSuccessfulFinish={ refresh }
            handleOpenModal={ handleOpenModal }
          />
        ),
        meta: {
          className: pageStyle.moreActionsColumn,
        }
      }
    ],
    [refresh]
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    /* Sorting */
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    /* Filtering */
    globalFilterFn,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    
    state: {
      sorting,
      globalFilter,
      pagination,
    },
  });

  return (
    <TableWrapper>
      <div className={pageStyle.toolbar}>
        <Search setGlobalFilter={setGlobalFilter} globalFilter={globalFilter}/>
        <CreateButton onClick={() => setOpenedModal(modals.creation)}/>
      </div>
      <Table
        { ...loadingStatus }
        table={table}
        minWidth={700}
        getEmptyComponent={() => <EmptyMessage setOpenedModal={setOpenedModal} />}
      />
      <Pagination table={ table } />
      <CreateModal
        open={ openedModal === modals.creation }
        handleCancel={ handleCloseModal }
        onSuccessfulFinish={refresh}
      />
      <DeleteKeyModal
        open={ openedModal === modals.deletion }
        handleCancel={ handleCloseModal }
        keyId={ selectedSigningKey?.id }
        keyName={ selectedSigningKey?.name }
        onSuccessfulFinish={ closeModalAndFinish }
      />
      <RecreateKeyModal
        handleCancel={ handleCloseModal }
        open={ openedModal === modals.recreateApiKey }
        projectId={ selectedSigningKey?.id }
        onSuccessfulFinish={ closeModalAndFinish }
      />
    </TableWrapper>
  );
}

function CreateButton(props) {
  return (
    <Button
      {...props}
      icon={<Add/>}
    >
      Create Streaming Project
    </Button>
  );
}

function EmptyMessage({ setOpenedModal }) {
  return (
    <div className="tableNodata">
      <div className="tableNodataImage">
        <ProjectWithCircle/>
      </div>
      <h3 className="tableNodataTitle">
        You don't have any Stream Projects yet
      </h3>
      <p className="tableNodataDescription">
        Start by creating a stream project
      </p>
      <CreateButton onClick={() => setOpenedModal(modals.creation)}/>
    </div>
  );
}
