import React from 'react';
import PropTypes from 'prop-types';
import FilterSection from "components/FilterSidebar/FilterSection";
import OptionList from "components/OptionList";
import MultiSelectWithSearch from "components/MultiSelectWithSearch";
import {FilterSectionProps} from "components/FilterSidebar/useFilter";


function MultiSelectFilter({
  clearFilter,
  columnId,
  columnToFilterExpanded,
  defaultExpanded,
  getFilterValue,
  handleCheck,
  nothingFoundText,
  search,
  options,
  title,
  }) {
  const expandedFromActiveFilterTime = columnToFilterExpanded[columnId];

  return (
    <FilterSection
      title={ title }
      expandedFromActiveFilterTime={expandedFromActiveFilterTime}
      selectedCount={ getFilterValue(columnId).length }
      handleClear={() => clearFilter(columnId)}
      defaultExpanded={defaultExpanded}
      >
      {
        search ? (
          <MultiSelectWithSearch
            options={options}
            handleCheck={(value) => handleCheck(columnId, value)}
            nothingFoundText={nothingFoundText}
          />
        ) : (
          <OptionList
            options={options}
            handleCheck={(value) => handleCheck(columnId, value)}
          />
        )
      }
    </FilterSection>
  );
}

MultiSelectFilter.propTypes = {
    columnId: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
    })).isRequired,
    title: PropTypes.string.isRequired,
    defaultExpanded: PropTypes.bool,
    search: PropTypes.bool,
    nothingFoundText: PropTypes.string,
    ...FilterSectionProps,
};

export default MultiSelectFilter;