import React from "react";
import {Delete} from "icons";
import PropTypes from "prop-types";
import {OptionsMenu} from "components/OptionsMenu";


function MoreActionsPopover({ domain, handleStartDeletion }) {
  return (
    <div className="cellContainer">
      <OptionsMenu
        menuItems={
        [
          {
            icon: <Delete label="Remove" />,
            onClick: () => handleStartDeletion( domain ),
            text: "Delete",
            danger: true,
          }
        ]
        }
      />
    </div>
  );
}

MoreActionsPopover.propTypes = {
  domain: PropTypes.object.isRequired,
  onSuccessfulFinish: PropTypes.func,
};

export default MoreActionsPopover;