import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from "components/Modal";
import {Button} from "components/Button";
import {TextArea} from "components/TextArea";
import style from './style.module.css';
import {Copy} from "icons";
import Tooltip from "components/Tooltip";
import useCopyToClipboard from "components/CopyToClipboard/useCopyToClipboard";

function PrivateKeyModal({ open, privateKey, handleCancel }) {
  const {
    copy,
    copied,
  } = useCopyToClipboard(privateKey);
  
  return (
    <Modal
      isOpen={open}
      onClose={handleCancel}
      alert='Please copy the Private Key and save it safety on your end. For security reasons Qencode does not store your private keys.'
      header={
        <>
          <h3>Signing Private Key</h3>
        </>
      }
      footer={
        <>
          <Button
            type="secondary"
            onClick={ handleCancel }
          >
            Close
          </Button>
          <Tooltip
            text="Copied!"
            visible={copied}
            showOnHover={false}
          >
            <Button
              icon={ <Copy/> }
              onClick={copy}
            >
              Copy Key
            </Button>
          </Tooltip>
        </>
      }
    >
    <TextArea
      className={ style.text }
      inputText={ privateKey }
    />
    </Modal>
  );
}

PrivateKeyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  privateKey: PropTypes.string,
};

export default PrivateKeyModal;