import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Modal} from "components/Modal";
import {InputField} from "components/InputField";
import {Button} from "components/Button";
import style from './style.module.css';
import {Checkbox} from "components/Checkbox";
import {editPlayer} from "api/qencode-account-api";
import {VideoPlayerType} from "pages/Player/VideoPlayerListPage/config";
import {useAuth} from "context/AuthContext";

function EditModal({
   open,
   handleCancel,
   onSuccessfulFinish,
   selected,
 }) {
  const { getToken } = useAuth();
  const [submitting, setSubmitting] = useState(false);
  const [limitActive, setLimitActive] = useState(false);
  const [playerName, setPlayerName] = useState("");
  const [playerNameError, setPlayerNameError] = useState("");
  const [domainName, setDomainName] = useState("");
  const [domainNameError, setDomainNameError] = useState("");

  async function handleConfirmSubmit(e) {
    e.preventDefault();
    let domainForBackend = '*';
    
    if (limitActive) {
      if (domainName) {
        domainForBackend = domainName;
      } else {
        setDomainNameError('Please enter domain name');
      }
    }
    
    setSubmitting(true);
    const accessToken = await getToken();
    const {
      success,
      details,
    } = await editPlayer({
      accessToken,
      id: selected?.id,
      name: playerName,
      domain: domainForBackend,
    });
    if (success) {
      clearFormAndClose();
      onSuccessfulFinish?.();
    } else {
      if (Array.isArray(details)) {
        for (const fieldError of details) {
          const fieldName = fieldError['field_name']
          const error = fieldError.error;
          if (fieldName === 'name') {
            setPlayerNameError(error);
          }
          else if (fieldName === 'domain') {
            setDomainNameError(error);
          }
        }
      } else if (typeof details === 'string') {
        setPlayerNameError(details);
      }
    }
    setSubmitting(false);
  }
  
  function handlePlayerNameChange(e) {
    setPlayerName(e.target.value);
    setPlayerNameError('');
  }
  
  function handleDomainNameChange(e) {
    setDomainName(e.target.value);
    setDomainNameError('');
  }
  
  function clearFormAndClose() {
    handleCancel();
    setPlayerName('');
    setPlayerNameError('');
    setDomainName('');
    setDomainNameError('');
    setLimitActive(false);
  }
  
  function onCheckLimit(e) {
    const { checked } = e.target;
    setLimitActive( checked );
    if (!checked) {
      setDomainName('');
      setDomainNameError('');
    }
  }

  return (
    <Modal
      isOpen={open}
      onClose={handleCancel}
      header={
        <>
          <h3>Edit Player</h3>
        </>
      }
      footer={
        <>
          <Button
            variant="modalFooter"
            onClick={ clearFormAndClose }
            type="secondary"
          >
            Cancel
          </Button>
          <Button
            variant="modalFooter"
            state={ submitting ? "disabled" : "default" }
            onClick={ handleConfirmSubmit }
          >
            Update
          </Button>
        </>
      }
    >
      <div className="form">
        <form onSubmit={handleConfirmSubmit} className={ style.form }>
          <div className="row">
            <InputField
              label="Player Name"
              onChange={handlePlayerNameChange}
              inputText={playerName}
              name={'player-name'}
              state={playerNameError ? 'error' : 'default'}
              hint={playerNameError ? playerNameError : null}
            />
          </div>
          <div className={`row ${style.domain}`}>
            <Checkbox
              label="Limit license key to the domain"
              checked={limitActive}
              onChange={onCheckLimit}
            />
            <InputField
              onChange={handleDomainNameChange}
              inputText={domainName}
              name={"domain-name"}
              state={domainNameError ?  "error" : ( limitActive ? "default" : "disabled" )}
              hint={domainNameError ? domainNameError : ""}
            />
            <p className={style.domainHint}>
              This is highly recommended to prevent unauthorized use of your video player.
            </p>
          </div>
        </form>
      </div>
    </Modal>
  )
}

EditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  onSuccessfulFinish: PropTypes.func,
  selected: PropTypes.shape( VideoPlayerType ),
};

export default EditModal;