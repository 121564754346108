import React, { useState } from 'react';

import { TextArea } from '../components/TextArea'
import { Checkbox } from '../components/Checkbox'
import { Radio } from '../components/Radio'
import { Toggle } from '../components/Toggle'

import ItemList from '../components/ItemList'
import * as icons from 'icons/index';

const TestPage = () => {

  const [inputText, setInputText] = useState('efew');             // for TextArea
  const [checked, setChecked] = useState(false);                  // for Checkbox
  const [selectedValue, setSelectedValue] = useState('option1');  // for Radio
  const [isToggled, setIsToggled] = useState(false);              // for Toggle

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputText(value);
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setChecked(checked);
  };  

  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleToggleChange = (e) => {
    setIsToggled(e.target.checked);
  };

  return (
      <div>
        <h1>Test Page</h1>
        <p>This is Test Page.</p>

        <div style={{margin: '20px 0'}}>
          <Toggle checked={isToggled} label="toggle" onChange={handleToggleChange} />
          <p>The switch is {isToggled ? 'ON' : 'OFF'}.</p>
        </div>

        <div style={{margin: '20px 0'}}>
          <div style={{margin: '10px 0'}}>
            <Radio 
              label="Option 1" 
              name="testRadio" 
              value="option1" 
              checked={selectedValue === 'option1'} 
              onChange={handleRadioChange}
            />            
          </div>
          <div style={{margin: '10px 0'}}>
            <Radio 
              label="Option 2" 
              name="testRadio" 
              value="option2" 
              checked={selectedValue === 'option2'} 
              onChange={handleRadioChange}
            />            
          </div>
          <div style={{margin: '10px 0'}}>
            <Radio 
              label="Option 3" 
              name="testRadio" 
              value="option3" 
              checked={selectedValue === 'option3'} 
              onChange={handleRadioChange}
              disabled
            />            
          </div>
        </div>

        <div style={{margin: '20px 0'}}>
          <TextArea inputText={inputText} onChange={handleInputChange}/>
        </div>

        <div style={{margin: '20px 0'}}> 
          <Checkbox checked={checked} onChange={handleCheckboxChange}/>
        </div>

        <div style={{margin: '20px 0'}}>
          <ItemList />
        </div>

        <div style={{margin: '20px 0'}}>
          { Object.entries(icons).map(([name, Component]) => {
            return (
              <div>
                <Component/>
                { name }
              </div>

            )
          }) }
        </div>
      </div>
  );
};

export default TestPage;