// display time in UTC
// handle date as object - returned by S3
// 2024-09-05T11:46:48.076Z => Sep 5, 2024, 11:46 AM
// hadle date as string - returned by qencode api
// 2024-09-10 07:43:05 => Sep 10, 2024, 7:43 AM
export const formatDateTime = (dateString) => {
    //console.log(typeof(dateString)); // To see what type of input is being passed

    if (!dateString) {
        return ""; // Return a placeholder if dateString is null or undefined
    }

    // If it's a string and doesn't have 'Z', treat it as UTC by appending 'Z'
    if (typeof dateString === 'string' && !dateString.endsWith('Z')) {
        dateString = dateString + 'Z'; // Appending 'Z' to treat the string as UTC
    }

    const options = {
        year: 'numeric', 
        month: 'short', 
        day: 'numeric', 
        hour: 'numeric', 
        minute: 'numeric', 
        hour12: true, 
        timeZone: 'UTC' // Ensure the time is displayed in UTC
    };
    
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
        return "Invalid date"; // Check if the date is valid
    }

    return date.toLocaleString('en-US', options);
};



// 2023-06-21 13:00:23 => Jun 21, 2023
// export const formatDate = (inputDate) => {
//     const date = new Date(inputDate);
//     if (isNaN(date.getTime())) {
//         return "";  // Return an empty string or "Invalid date" if the date is not valid
//     }

//     const options = { month: 'short', day: 'numeric', year: 'numeric' };
//     return date.toLocaleDateString('en-US', options);
// }

export const formatDate = (inputDate) => {
    if (!inputDate) {
        return "";  // Return a placeholder if inputDate is null or undefined
    }

    // If the inputDate is a string and doesn't have 'Z', append 'Z' to treat it as UTC
    if (typeof inputDate === 'string' && !inputDate.endsWith('Z')) {
        inputDate = inputDate + 'Z'; // Append 'Z' to make sure it's treated as UTC
    }

    const date = new Date(inputDate);

    if (isNaN(date.getTime())) {
        return "";  // Return an empty string or "Invalid date" if the date is not valid
    }

    // Format the date in 'Month Day, Year' format
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
};



// 2.8 => $2.80
export const formatCurrency = (value) => {
    if (value === null || value === undefined || isNaN(value)) {
        return ""; // Return an empty string or a placeholder like "N/A"
    }

    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(value);
}

// 2023-05 => May 2023
export const formatPeriod = (date) => {
    if (!date || !date.includes('-')) {  // Checks if date is null, undefined, or not correctly formatted
        return "Invalid period";  // Return a placeholder or indication of error
    }

    const [year, month] = date.split('-');
    if (!year || !month) {
        return "Invalid period";  // Additional check if split results are not valid
    }

    const dateObject = new Date(year, month - 1);
    if (isNaN(dateObject.getTime())) {
        return "Invalid period";  // Check if the date object is valid
    }

    const monthName = dateObject.toLocaleString('default', { month: 'short' });
    return `${monthName} ${year}`;
}

// export const formatSize = (size, unit = 'KB') => {
//     // Example usage:
//     // console.log(formatSize('1024')); // Outputs: "1.00 MB"
//     // console.log(formatSize('1024', 'MB')); // Outputs: "1.00 GB"
//     // console.log(formatSize('1024', 'GB')); // Outputs: "1.00 TB"
//     // console.log(formatSize('1048576', 'KB')); // Outputs: "1.00 GB"
//     // console.log(formatSize('5.26486', 'MB')); // Outputs: "5.26 MB"


//     const units = ['KB', 'MB', 'GB', 'TB'];
//     let sizeInKB;

//     // Convert the input size to KB
//     switch (unit) {
//         case 'MB':
//             sizeInKB = parseFloat(size) * 1024;
//             break;
//         case 'GB':
//             sizeInKB = parseFloat(size) * 1024 * 1024;
//             break;
//         case 'TB':
//             sizeInKB = parseFloat(size) * 1024 * 1024 * 1024;
//             break;
//         case 'KB':
//         default:
//             sizeInKB = parseFloat(size);
//     }

//     if (isNaN(sizeInKB)) {
//         return 'Invalid size';
//     }

//     let unitIndex = 0;
//     let sizeInCurrentUnit = sizeInKB;

//     // Convert size to the appropriate unit
//     while (sizeInCurrentUnit >= 1024 && unitIndex < units.length - 1) {
//         sizeInCurrentUnit /= 1024;
//         unitIndex++;
//     }

//     return `${sizeInCurrentUnit.toFixed(2)} ${units[unitIndex]}`;
// };


export const formatSize = (size, unit = 'KB') => {
    const units = ['KB', 'MB', 'GB', 'TB'];
    let sizeInKB;

    // Convert the input size to KB
    switch (unit) {
        case 'bytes':
            sizeInKB = parseFloat(size) / 1024;
            break;
        case 'MB':
            sizeInKB = parseFloat(size) * 1024;
            break;
        case 'GB':
            sizeInKB = parseFloat(size) * 1024 * 1024;
            break;
        case 'TB':
            sizeInKB = parseFloat(size) * 1024 * 1024 * 1024;
            break;
        case 'KB':
        default:
            sizeInKB = parseFloat(size);
    }

    if (isNaN(sizeInKB)) {
        return 'Invalid size';
    }

    let unitIndex = 0;
    let sizeInCurrentUnit = sizeInKB;

    // Convert size to the appropriate unit
    while (sizeInCurrentUnit >= 1024 && unitIndex < units.length - 1) {
        sizeInCurrentUnit /= 1024;
        unitIndex++;
    }

    return `${sizeInCurrentUnit.toFixed(2)} ${units[unitIndex]}`;
};



export const formatBitrate = (bitrate) => {
    // Usage example:
    // const formattedBitrate = formatBitrate(320000); // "320.00 Kbps"
    // console.log(formattedBitrate);

    if (typeof bitrate !== 'number') return 'Invalid bitrate';

    const units = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps'];
    let unitIndex = 0;

    while (bitrate >= 1000 && unitIndex < units.length - 1) {
        bitrate /= 1000;
        unitIndex++;
    }

    return `${bitrate.toFixed(2)} ${units[unitIndex]}`;
};

export const formatDuration = (durationInSeconds) => {
    // Example usage:
    // console.log(formatDuration('128.73')); // Outputs: "00:02:08"
    // console.log(formatDuration('3600'));   // Outputs: "01:00:00"
    // console.log(formatDuration('3725.5')); // Outputs: "01:02:05"

    // Parse the input to a floating-point number
    const totalSeconds = parseFloat(durationInSeconds);

    // Return '00:00:00' if the input is not a valid number
    if (isNaN(totalSeconds)) {
        return '00:00:00';
    }

    // Calculate hours, minutes, and seconds
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    // Format the values to ensure two digits
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    // Return the formatted duration
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const formatToPercentage = (number) => {
    // Example usage:
    // const validNumber = 15.0248335;
    // const invalidNumber = 'invalid';
    // console.log(formatToPercentage(validNumber)); // Output: "15 %"
    // console.log(formatToPercentage(invalidNumber)); // Output: "Invalid input"

    if (typeof number !== 'number' || isNaN(number)) {
        return 'Invalid input';
    }
    return `${Math.round(number)} %`;
};
