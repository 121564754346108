import PropTypes from "prop-types";
import React from "react";

export const Key = ({ className, color, label }) => {
  
  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;
  
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="#fff"
      className={className}
      role="img"
      aria-label={label}
      style={style}
    >
      <path d="M10.02 3.3a.35.35 0 0 0-.5 0L7.6 5.22a.35.35 0 0 0 .27.6l3.58-.24c.3-.02.44-.39.23-.6L10.02 3.3z" fill="currentColor" />
      <path
        fillRule="evenodd"
        d="M8.75 1.2a2.75 2.75 0 0 1 2.05 0c.33.13.62.35.87.6l2.54 2.53a2.52 2.52 0 0 1 .8 1.88 2.57 2.57 0 0 1-.8 1.9l-1.86 1.86a2.7 2.7 0 0 1-.86.6 2.75 2.75 0 0 1-1.04.19c-.35 0-.7-.07-1.02-.2a1.8 1.8 0 0 1-.44-.3l-.13-.1-4.24 4.24c-.34.34-.8.56-1.3.6h-.98A1.4 1.4 0 0 1 1 13.76v-.95c0-.48.18-.93.5-1.32l.1-.11.3-.29a.63.63 0 0 1 .44-.2h.78v-.77c0-.35.29-.64.64-.64h.77V8.7a.63.63 0 0 1 .2-.45l1.1-1.12-.1-.13a2.35 2.35 0 0 1-.3-.45 2.75 2.75 0 0 1 0-2.05 2.7 2.7 0 0 1 .6-.86L7.9 1.8a2.7 2.7 0 0 1 .86-.6zm1.57 1.2a1.38 1.38 0 0 0-.53-.12 1.49 1.49 0 0 0-.55.11 1.3 1.3 0 0 0-.45.29L6.92 4.55a1.3 1.3 0 0 0-.29.45 1.49 1.49 0 0 0-.1.55c0 .18.03.36.1.53s.16.31.29.44l.22.22c.25.25.25.66 0 .9L5.8 8.96v1.17c0 .35-.28.64-.64.64H4.4v.77c0 .35-.29.64-.64.64H2.61l-.15.16a.74.74 0 0 0-.18.48v.84c0 .02 0 .04.02.05l.03.01h.92c.18 0 .34-.08.47-.21l4.63-4.64a.64.64 0 0 1 .9 0l.23.22c.13.13.27.22.44.29a1.38 1.38 0 0 0 .53.1 1.49 1.49 0 0 0 .55-.1c.17-.07.31-.16.44-.29l1.88-1.87c.13-.13.22-.28.29-.45a1.49 1.49 0 0 0 .1-.55 1.38 1.38 0 0 0-.1-.53 1.3 1.3 0 0 0-.29-.44l-2.56-2.56a1.3 1.3 0 0 0-.44-.29z"
        fill="currentColor"/>
        />
    </svg>
  );
};

Key.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Key.defaultProps = {
  color: 'currentColor', // Or any default color
};