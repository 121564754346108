import React from 'react';
import PropTypes from 'prop-types';
import styles from './MultiValidation.module.css'; // Make sure to create the corresponding CSS module

import {Dot, Tick, Close} from 'icons'

export const MultiValidation = ({ validations }) => {
    return (
        <div className={styles.validationContainer}>
            {validations.map((validation, index) => (
                <div
                    key={index}
                    className={`${styles.validationMessage} ${
                        validation.isValid === null ? styles.initial : validation.isValid ? styles.valid : styles.invalid
                    }`}
                >
                    <div className={styles.iconContainer}>
                        {validation.isValid === null && <Dot />}
                        {validation.isValid === true && <Tick />}
                        {validation.isValid === false && <Close />}
                    </div>

                    <div className={styles.text}>
                        {validation.message}    
                    </div>
                    
                </div>
            ))}
        </div>
    );
};

MultiValidation.propTypes = {
    validations: PropTypes.arrayOf(
        PropTypes.shape({
            message: PropTypes.string.isRequired,
            isValid: PropTypes.bool, // null for initial state, true for valid, false for invalid
        })
    ).isRequired,
};