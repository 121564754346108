import PropTypes from "prop-types";
import React from "react";

export const MoreVert = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path d="M8 1.5C8.82837 1.5 9.5 2.17163 9.5 3C9.5 3.82837 8.82837 4.5 8 4.5C7.17163 4.5 6.5 3.82837 6.5 3C6.5 2.17163 7.17163 1.5 8 1.5Z" fill="currentColor"/>
      <path d="M8 6.5C8.82837 6.5 9.5 7.17163 9.5 8C9.5 8.82837 8.82837 9.5 8 9.5C7.17163 9.5 6.5 8.82837 6.5 8C6.5 7.17163 7.17163 6.5 8 6.5Z" fill="currentColor"/>
      <path d="M9.5 13C9.5 12.1716 8.82837 11.5 8 11.5C7.17163 11.5 6.5 12.1716 6.5 13C6.5 13.8284 7.17163 14.5 8 14.5C8.82837 14.5 9.5 13.8284 9.5 13Z" fill="currentColor"/>
    </svg>    
  );
};

MoreVert.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

MoreVert.defaultProps = {
  color: 'currentColor', // Or any default color
};