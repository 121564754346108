import PropTypes from "prop-types";
import React from "react";

export const Globe2 = ({ className, color, label }) => {
  
  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;
  
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      className={className}
      role="img"
      aria-label={label}
      style={style}
    >
      <path fillRule="evenodd"
            d="M8.5 1.4a.6.6 0 0 0-.2 0h-.6a.6.6 0 0 0-.2 0A6.6 6.6 0 0 0 1.4 8a6.6 6.6 0 0 0 2 4.7 6.6 6.6 0 0 0 4.1 1.9.6.6 0 0 0 .2 0h.6a.6.6 0 0 0 .2 0 6.6 6.6 0 0 0 4.2-2A6.6 6.6 0 0 0 14.6 8a6.6 6.6 0 0 0-6.1-6.6zm-2 1.4a5.4 5.4 0 0 0-3.2 2.6h2.3c.2-.9.5-1.8 1-2.6zM7 5.4c.2-1 .6-2 1.1-2.8.5.9.9 1.8 1.1 2.8H7zM5.4 6.6H2.8a5.4 5.4 0 0 0 0 2.8h2.6V6.6zm1.2 2.8V6.6h2.8v2.8H6.6zm-1 1.2H3.3A5.4 5.4 0 0 0 5.9 13a5.4 5.4 0 0 0 .6.2c-.4-.8-.7-1.7-.9-2.6zM8 13.4c-.5-.9-.9-1.8-1.1-2.8H9c-.2 1-.6 2-1.1 2.8zm1.5-.2a5.4 5.4 0 0 0 .6-.2 5.4 5.4 0 0 0 2.6-2.4h-2.3a12 12 0 0 1-1 2.6zm1-3.8h2.7a5.4 5.4 0 0 0 0-2.8h-2.6v2.8zm-.1-4h2.3a5.4 5.4 0 0 0-3.2-2.6 12 12 0 0 1 .9 2.6z"
            fill="currentColor"/>
    </svg>
  );
};

Globe2.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Globe2.defaultProps = {
  color: 'currentColor', // Or any default color
};