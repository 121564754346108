import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import Card from 'pages/StatisticsPage/components/Card';
import Heading from "pages/StatisticsPage/components/Heading";
import GbReadable, {gigabytesToReadable} from "components/Table/Cells/GbReadable";
import {getCoreRowModel, getPaginationRowModel, useReactTable} from "@tanstack/react-table";
import Table from "components/Table";
import UnifiedTwoCells from "components/Table/Cells/UnifiedTwoCells";
import TopTotal from "pages/StatisticsPage/components/TopTotal";
import {ReactComponent as MediaStorage2} from "assets/images/MediaStorage2.svg";

const columnIds = {
  name: 'name',
  total: 'total',
};

function TopStorage({
    stats,
    total,
  }) {
  const columns = useMemo(() => [
    {
      id: columnIds.name,
      accessorKey: 'bucket_name',
      header: () => <span>Top buckets</span>,
      meta: {
        colspan: 2,
      },
      enableSorting: false,
      cell: (info) => <UnifiedTwoCells>
        <span className="oneLineEllipsis">{ info.getValue() }</span>
        <GbReadable value={ info?.row.original?.['storage_gb_month'] } />
      </UnifiedTwoCells>,
    },
    {
      id: columnIds.total,
      header: () => <span>Total</span>,
      accessorKey: 'storage_gb_month',
      enableSorting: false,
      meta: {
        align: 'right',
        colspan: 0,
      },
    },
  ], []);

  const table = useReactTable({
    data: stats.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination: {
        pageIndex: 0,
        pageSize: 5,
      },
    },
  });

  return (
    <Card>
      <Heading
        title="Storage"
        href={`?tab=storage`}
      />
      <TopTotal
        title="GB Stored"
        total={ gigabytesToReadable(total) }
        loading={stats.loading}
      />
      <Table
        { ...stats }
        table={table}
        lastLineInvisible
        noDataImage={<MediaStorage2/>}
        noDataDescription="No data found"
        skeletonRowCount={5}
      />
    </Card>
  )
}

TopStorage.propTypes = {
  stats: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
};

export default TopStorage;