import React from 'react';
import {useParams} from "react-router-dom";

function StreamDetailPage() {
  const { id } = useParams();

  return (
    <div>{ id }</div>
  )
}

StreamDetailPage.propTypes = {};

export default StreamDetailPage;