import PropTypes from "prop-types";
import React from "react";

export const Calendar = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;
  
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      className={className}
      aria-label={label}
      role="img"
      style={style}
    >
      <path fillRule="evenodd"
            d="M11 .7a.75.75 0 0 1 .75.75v.95a2.85 2.85 0 0 1 2.85 2.85v6.5a2.85 2.85 0 0 1-2.85 2.85h-7.5a2.85 2.85 0 0 1-2.85-2.85v-6.5A2.85 2.85 0 0 1 4.25 2.4v-.95a.75.75 0 1 1 1.5 0v.95h4.5v-.95A.75.75 0 0 1 11 .7zM2.6 11.75V6.4h10.8v5.35a1.65 1.65 0 0 1-1.65 1.65h-7.5a1.65 1.65 0 0 1-1.65-1.65zM7.27 7.9a.6.6 0 1 0 0 1.2h.33v2.2a.6.6 0 0 0 1.2 0V8.5a.6.6 0 0 0-.6-.6h-.93z"
            fill="currentColor"/>
    </svg>
  );
};

Calendar.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Calendar.defaultProps = {
  color: 'currentColor', // Or any default color
};