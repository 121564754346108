import PropTypes from "prop-types";
import React from "react";

export const Clock = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M2.59992 7.99997C2.59992 5.01764 5.01762 2.59999 8 2.59999C10.9824 2.59999 13.4001 5.01764 13.4001 7.99997C13.4001 10.9823 10.9824 13.3999 8 13.3999C5.01762 13.3999 2.59992 10.9823 2.59992 7.99997ZM8 1.39999C4.35487 1.39999 1.3999 4.3549 1.3999 7.99997C1.3999 11.645 4.35487 14.5999 8 14.5999C11.6451 14.5999 14.6001 11.645 14.6001 7.99997C14.6001 4.3549 11.6451 1.39999 8 1.39999ZM7.72187 4.99998C7.72187 4.61338 8.03528 4.29998 8.42188 4.29998C8.80849 4.29998 9.12189 4.61338 9.12189 4.99998V8.41439C9.12189 8.69023 8.9599 8.94034 8.70819 9.05316L6.28616 10.1387C5.93337 10.2969 5.51919 10.1391 5.36107 9.78627C5.20294 9.43349 5.36075 9.01932 5.71354 8.86119L7.72187 7.96105V4.99998Z" fill="currentColor"/>  
    </svg>    
  );
};

Clock.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Clock.defaultProps = {
  color: 'currentColor', // Or any default color
};