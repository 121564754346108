import React from 'react';
import PropTypes from 'prop-types';
import style from "pages/StatisticsPage/components/BriefPanel/style.module.css";
import classNames from "classnames";
import bytesToReadable from "helpers/bytesToReadable";
import {gigabytesToReadable} from "components/Table/Cells/GbReadable";
import MoneyRounded from "components/Table/Cells/MoneyRounded";
import formatNumber from "helpers/formatNumber";

function BriefItem({
  loading,
  heading,
  value,
  value2,
  type,
  valueAppearance,
  precision = 2,
}) {
  return (
    <div className={style.item}>
      { loading ? <div className={style.skeleton}></div> : (
        <dd className={classNames( style.value, {
          [style.skeleton]: loading,
          [valueAppearance]: typeof valueAppearance === 'string',
        })}>
          <Value
            value={value}
            type={type}
            precision={precision}
          />
          <span className={style.value2}>
            {value2}
          </span>
        </dd>
      )}
      <dt className={style.heading}>
        { heading }
      </dt>
    </div>
  )
}

BriefItem.propTypes = {
  loading: PropTypes.bool.isRequired,
  type: PropTypes.oneOf([
    'byte',
    'money',
    'gigabyte'
  ]),
  precision: PropTypes.number,
};

function Value({
  value,
  type,
  precision,
}) {
  const valueNumeric = parseFloat(value);
  
  if (typeof valueNumeric === 'number' && Number.isFinite(valueNumeric)) {
    if (type === 'byte') {
      return <>{ bytesToReadable(valueNumeric) }</>;
    }
    if (type === 'money') {
      return <MoneyRounded value={valueNumeric}/>;
    }
    if (type === 'gigabyte') {
      return `${ gigabytesToReadable(valueNumeric) }`;
    }
    return <>{ formatNumber( Number(parseFloat(valueNumeric)).toFixed(precision) )  }</>;
  }
  return value;
}


export default BriefItem;