import PropTypes from "prop-types";
import React from "react";

export const Refresh = ({ className, color, label }) => {
  
  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;
  
  return (
    <svg
      width="12"
      height="12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      aria-label={label}
      style={style}
    >
      <path
        d="m1.59 1.73-.66-.66a.55.55 0 0 0-.93.38v2.43c0 .3.24.54.55.54h2.42a.55.55 0 0 0 .39-.93l-1-.99A5.56 5.56 0 0 1 6 1.1c2.52 0 4.6 1.9 4.88 4.35a.55.55 0 1 0 1.08-.12A6 6 0 0 0 6 0C4.4 0 2.84.62 1.59 1.73ZM.52 6.06c.3-.03.57.19.6.49A4.91 4.91 0 0 0 6 10.9a5.9 5.9 0 0 0 3.51-1.23l-.87-.87a.55.55 0 0 1 .39-.93h2.42c.3 0 .55.24.55.54v2.43a.55.55 0 0 1-.93.38l-.78-.77A7 7 0 0 1 6 12 6 6 0 0 1 .04 6.67a.55.55 0 0 1 .48-.6Z"
        fill="currentColor"/>
    </svg>
  );
};

Refresh.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Refresh.defaultProps = {
  color: 'currentColor', // Or any default color
};