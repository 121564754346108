import React from 'react';
import PropTypes from 'prop-types';
import {flexRender} from "@tanstack/react-table";
import style from './style.module.css';
import {Checkbox} from "components/Checkbox";
import classNames from "classnames";

function TableBodyRow({
    row,
    loading,
    rowClass,
    selected, /* Enables custom, not library-based row selection. */
    rowSelectionAllowed, /* Enables built-in checkbox that's using standard react-table library functionality. */
    onClick,
  }) {
  return (
    <tr
      key={row.id}
      className={classNames( 'tableRow', {
        [rowClass]: true,
        tableRowClickable: !!onClick,
        tableRowSelected: selected,
        tableRowExpanded: row.getIsExpanded(),
      })}
      onClick={() => {
        if (row.getCanExpand()) {
          row.getToggleExpandedHandler()?.();
        } else {
          onClick?.(row);
        }
      }}
    >
      {/* eslint-disable-next-line array-callback-return */}
      {row?.getVisibleCells().map((cell, index) => {
        const cellContent = Object.keys(cell).length ? flexRender(
          cell?.column?.columnDef?.cell,
          cell?.getContext?.()
        ) : null;
        const colspan = cell.column?.columnDef?.meta?.colspan;
        if (colspan === 0) {
          /* This space intentionally left blank. */
        } else {
          return (
            <td
              key={cell.id}
              colSpan={ colspan > 1 ? colspan : null }
            >
              {
                <div className={ classNames(style.placeholder, {
                  [style.loaded]: !loading,
                }) }>
                  {
                    (!loading && index === 0 && rowSelectionAllowed) ? (
                      <div className={style.checkboxAndContentWrapper}>
                        <Checkbox
                          checked={row.getIsSelected()}
                          onChange={row.getToggleSelectedHandler()}
                        />
                        { cellContent }
                      </div>
                    ) : cellContent
                  }
                </div>
              }
            </td>
          )
        }
      } )}
    </tr>
  );
}

TableBodyRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onClick: PropTypes.func
};

export default TableBodyRow;