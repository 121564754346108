import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable} from "@tanstack/react-table";
import Table from "components/Table";
import Region from "components/Region";
import bytesToReadable from "helpers/bytesToReadable";
import Card from "pages/StatisticsPage/components/Card";
import MoneyRounded from "components/Table/Cells/MoneyRounded";
import Columns from "components/Columns";
import {ReactComponent as RegionIcon} from "assets/images/Region.svg";
import style from './style.module.css';
import formatNumber from "helpers/formatNumber";
import getTableValueFallback from "helpers/getTableValueFallback";

const columnIds = {
  region: 'region',
  bandwidth: 'bandwidth',
  requests: 'requests',
  cost: 'cost',
};

function TopRegionsStorage({
  stats,
}) {
  
  const dataMediaStorage = useMemo(() => stats.data?.ms || [], [stats.data?.ms]) ;
  const dataLiveStreaming = useMemo(() => stats.data?.ls || [], [stats.data?.ls]);
  const columns = useMemo(() => [
    {
      id: columnIds.region,
      accessorKey: 'region',
      cell: (info) => <Region regionCode={info.getValue()}/>,
    },
    {
      id: columnIds.bandwidth,
      accessorKey: 'bandwidth',
      cell: (info) => <span>{ bytesToReadable(info.getValue()) }</span>,
      meta: {
        className: style.bandwidthColumn,
      },
    },
    {
      id: columnIds.requests,
      accessorKey: 'requests',
      cell: (info) => <span>{ getTableValueFallback(info.getValue(), formatNumber)}</span>,
      meta: {
        className: style.requestsColumn,
      },
    },
    {
      id: columnIds.cost,
      accessorKey: 'amount',
      meta: {
        align: 'right',
        className: style.amountColumn,
      },
      cell: (info) => (
        <div className={style.amountValueWrapper}>
          <MoneyRounded value={info.getValue()} semiBold />
        </div>),
    }
  ], []);
  const commonProps = {
    columns,
    stats,
    noDataImage: <RegionIcon/>,
    noDataDescription: 'No data found',
    lastLineInvisible: true,
  };

  return (
    <Columns>
      <MediaStorage
        data={dataMediaStorage}
        {...commonProps}
      />
      <LiveStreams
        data={dataLiveStreaming}
        {...commonProps}
      />
    </Columns>
  );
}

function MediaStorage({
  columns,
  data,
  stats,
  ...rest
}) {
  const [sorting, setSorting] = useState([{
    id: columnIds.bandwidth,
    desc: true,
  }]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    /* Sorting */
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      pagination: {
        pageIndex: 0,
        pageSize: 5,
      }
    },
  });
  
  return (
    <Card title="Top Delivery Regions from Storage">
      <Table
        table={table}
        skeletonRowCount={5}
        minWidth={450}
        { ...stats }
        { ...rest }
      />
    </Card>
  );
}

function LiveStreams({
   columns,
   data,
   stats,
   ...rest
 }) {
  const [sorting, setSorting] = useState([{
    id: columnIds.bandwidth,
    desc: true,
  }]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    /* Sorting */
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      pagination: {
        pageIndex: 0,
        pageSize: 5,
      },
    },
  });
  
  return (
    <Card title="Top Delivery Regions from Live Streaming">
      <Table
        table={table}
        skeletonRowCount={5}
        minWidth={450}
        { ...stats }
        { ...rest }
      />
    </Card>
  );
}

TopRegionsStorage.propTypes = {
  stats: PropTypes.object.isRequired,
};

MediaStorage.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  stats: PropTypes.object.isRequired,
};

LiveStreams.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  stats: PropTypes.object.isRequired,
};

export default TopRegionsStorage;