import PropTypes from "prop-types";
import React from "react";

export const ArrowRight = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path d="M14.2178 9.06872C14.5941 8.76764 14.5941 8.23236 14.2178 7.93128L10.7899 4.68845C10.2556 4.26091 9.4194 4.6079 9.4194 5.25716V7.55526H3.53297C2.96247 7.55526 2.5 7.97818 2.5 8.49988C2.5 9.02157 2.96247 9.44449 3.53297 9.44449H9.4194V11.7428C9.4194 12.3921 10.2556 12.7391 10.7899 12.3116L14.2178 9.06872Z" fill="currentColor"/>
    </svg>    
  );
};

ArrowRight.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

ArrowRight.defaultProps = {
  color: 'currentColor', // Or any default color
};