import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'components/Select';
import { Button } from 'components/Button'

import { qencodeRegions } from 'helpers/qencodeRegions';

import styles from '../index.module.css';

import { ReactComponent as Bucket } from 'assets/images/Bucket.svg';
import { Add } from 'icons';

export const QencodeStorageForm = ({ 
    buckets, currentBucket, onBucketChange, 
    handleCreateNewBucket, 
}) => {
    
    const [bucketsOptions, setBucketsOptions] = useState([]);

    console.log("QencodeStorageForm currentBucket: ", currentBucket)

    useEffect(() => {
        //console.log("QencodeStorageForm currentBucket: ", currentBucket)
        if (buckets.length > 0) {
            const options = buckets.map((bucket, index) => {
                const region = qencodeRegions.find(region => region.value === bucket.region);
                return {
                    value: `${bucket.name}-${bucket.region}-${index}`, // Create a unique value
                    label: bucket.name,
                    extraData: region ? region.label : bucket.region, // Use the region label if found, otherwise fallback to bucket.region
                    data: bucket, // Store the whole bucket object in the option
                    checked: bucket === currentBucket
                };
            });
            setBucketsOptions(options);
        }
    }, [buckets, currentBucket]);    


    const handleBucketChange = (e) => {
        const { value } = e.target;
        const selectedOption = bucketsOptions.find(option => option.value === value)?.data;
        
        if (selectedOption) {
            onBucketChange(selectedOption);
            
            const updatedOptions = bucketsOptions.map(option => ({
                ...option,
                checked: option.value === value
            }));

            setBucketsOptions(updatedOptions);
        }
    };


    return (
        <div className={styles.qencodeStorageForm}>

            {
                currentBucket
                ?
                <div className={styles.formBody}>

                    <div className={styles.formRow}>
                        <Select
                            label='Choose Bucket'
                            name='bucket'
                            placeholder='Select Bucket'
                            options={bucketsOptions}
                            value={currentBucket ? `${currentBucket.name}-${currentBucket.region}-${buckets.findIndex(bucket => bucket === currentBucket)}` : ''}
                            onChange={handleBucketChange}
                            link={{
                                text: 'Create Bucket',
                                onClick: handleCreateNewBucket
                            }}
                        />
                    </div>

                </div>                
                :
                <div className={styles.noBucketsForm}>
                    <div className={styles.noBucketsFormBody}>
                        <Bucket />
                        <div className={styles.noBucketsFormMessage}>You don't have any buckets yet</div>
                    </div>
                    <Button onClick={handleCreateNewBucket} size="sm" icon={<Add />}>Create Bucket</Button>
                </div>                
            }
            
        </div>
    );
};

QencodeStorageForm.propTypes = {
    buckets: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            region: PropTypes.string.isRequired,
        })
    ).isRequired,
    currentBucket: PropTypes.shape({
        name: PropTypes.string,
        region: PropTypes.string,
    }),
    onBucketChange: PropTypes.func.isRequired,
};

QencodeStorageForm.defaultProps = {
    currentBucket: null,
};


