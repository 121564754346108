import PropTypes from "prop-types";
import React from "react";

export const Tick = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M14.3137 3.26897C14.6874 3.63531 14.6963 4.23825 14.3336 4.61569L6.55505 12.7109C6.37749 12.8957 6.13341 13 5.87847 13C5.62353 13 5.37946 12.8957 5.2019 12.7109L1.66618 9.03128C1.30351 8.65384 1.31242 8.05089 1.68608 7.68456C2.05975 7.31822 2.65666 7.32722 3.01934 7.70466L5.87847 10.6802L12.9805 3.28907C13.3431 2.91163 13.9401 2.90264 14.3137 3.26897Z" fill="currentColor"/>   
    </svg>    
  );
};

Tick.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Tick.defaultProps = {
  color: 'currentColor', // Or any default color
};