import React from 'react';
import PropTypes from 'prop-types';
import styles from './Radio.module.css'; 

export const Radio = React.forwardRef(({ label, name, value, checked, disabled, onChange }, ref) => {
  return (
    <label className={`${styles.radioContainer} ${checked ? styles.checked : ''} ${disabled ? styles.disabled : ''}`}>
      <span className={styles.radio}>
        <input 
          ref={ref} 
          type="radio" 
          name={name}
          value={value}
          checked={checked} 
          disabled={disabled} 
          onChange={onChange}
        />
      </span>
      {label && <span className={styles.label}>{label}</span>}
    </label>
  );
});

Radio.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

Radio.defaultProps = {
  label: '',
  checked: false,
  disabled: false,
  onChange: () => {},
};
