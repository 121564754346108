export const methodEnum = {
  'GET': 'GET',
  'POST': 'POST',
  'PUT': 'PUT',
  'DELETE': 'DELETE',
  'HEAD': 'HEAD',
};

export const httpHeaders = {
  allowOrigin: 'Access-Control-Allow-Origin',
  allowMethods: 'Access-Control-Allow-Methods',
  allowHeaders: 'Access-Control-Allow-Headers',
  maxAge: 'Access-Control-Max-Age',
  credentials: 'Access-Control-Allow-Credentials',
};
