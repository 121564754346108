import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputField } from 'components/InputField';
import { Button } from 'components/Button';
import styles from '../../index.module.css'

export const SFTPForm = ({ storageCredentials, onChange, onSave, onCancel }) => {
    const { sftp_endpoint, sftp_username, sftp_password } = storageCredentials.sftp;

    const [validationErrors, setValidationErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        onChange({
            target: {
                name: name,
                value: value
            }
        })

        const [, key] = name.split('.');

        // Clear validation errors when input changes
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [key]: null,
        }));
    };

    const handleSave = () => {
        const errors = {};

        if (!sftp_endpoint) {
            errors.sftp_endpoint = 'FTP endpoint is required';
        }
        if (!sftp_username) {
            errors.sftp_username = 'Username is required';
        }
        if (!sftp_password) {
            errors.sftp_password = 'Password is required';
        }

        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
        } else {
            onSave();
        }
    };

    // enable button when all input fields are set
    const isSaveDisabled = !sftp_endpoint || !sftp_username || !sftp_password;

    return (
        <div className={styles.storageFormContainer}>
            <div className={styles.formRow}>
                <InputField
                    inputText={sftp_endpoint}
                    onChange={handleInputChange}
                    name="sftp.sftp_endpoint"
                    placeholder="mydomain.com"
                    label="Endpoint"
                    state={validationErrors.sftp_endpoint ? 'error' : 'default'}
                    hint={validationErrors.sftp_endpoint || ''}
                />
            </div>

            <div className={styles.formRow}>
                <InputField
                    inputText={sftp_username}
                    onChange={handleInputChange}
                    name="sftp.sftp_username"
                    placeholder=""
                    label="Username"
                    autocomplete="off"
                    state={validationErrors.sftp_username ? 'error' : 'default'}
                    hint={validationErrors.sftp_username || ''}
                />
                <InputField
                    inputText={sftp_password}
                    onChange={handleInputChange}
                    name="sftp.sftp_password"
                    placeholder=""
                    label="Password"
                    type="password"
                    autocomplete="new-password"
                    state={validationErrors.sftp_password ? 'error' : 'default'}
                    hint={validationErrors.sftp_password || ''}
                />
            </div>

            <div className={styles.formFooter}>
                <Button 
                    size="sm"
                    type="secondary"
                    onClick={onCancel}
                >
                    Cancel
                </Button>                 
                <Button size="sm" onClick={handleSave} state={isSaveDisabled ? "disabled" : undefined}>
                    Save
                </Button>
            </div>
        </div>
    );
};

SFTPForm.propTypes = {
    storageCredentials: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

