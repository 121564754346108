import TableBodyRow from "components/Table/Body/Row";
import React from "react";
import style from './style.module.css';
import ReactTableLikeRow from "components/Table/Body/ReactTableLikeRow";

function getSkeletonRowsTable(columns, pageSize) {
  const skeletonTable = [];
  const obj = Object.fromEntries( columns.map((_, index) => [index, index]) );
  const objCol = Object.values(obj).map((i) => ({
    id: i,
    cell() {
      return '';
    }
  }));
  
  for (let i = 0; i < pageSize; i += 1) {
    skeletonTable.push(new ReactTableLikeRow(obj, objCol, i));
  }
  return skeletonTable;
}

function TableBody({
   table,
   loading,
   rowClass,
   onRowClick,
   skeletonRowCount,
   rowSelectionAllowed,
   topRowsComponent,
}) {
  const pageSize = skeletonRowCount || table.options.state.pagination.pageSize || 10;
  const skeletonTable = getSkeletonRowsTable(table.options.columns, pageSize);
  const topPinnedRows = table.getTopRows();
  const notPinnedRows = loading ? skeletonTable : table.getCenterRows();

  return (
    <tbody className={ style.body }>
    {
      topPinnedRows.map((row, index) => {
        const handleClick = (loading || !onRowClick) ? undefined : () => onRowClick(row.original);
        return (
          <TableBodyRow
            { ...getRowProps({ row, index, handleClick, loading, rowClass }) }
          />
        );
      })
    }
    { topRowsComponent }
    {
      notPinnedRows.map((row, index) => {
        // const handleClick = onRowClick ? () => onRowClick(row.original) : undefined;
        // make sure there is no click when loading
        const handleClick = (loading || !onRowClick) ? undefined : () => onRowClick(row.original);
        return (
          <TableBodyRow
            { ...getRowProps({ row, index, handleClick, loading, rowClass, rowSelectionAllowed }) }
          />
        );
      })
    }
    </tbody>
  )
}

function getRowProps({ row, index, handleClick, loading, rowClass, rowSelectionAllowed }) {
  return {
    key: row?.original?.id ?? row.id ?? index,
    row,
    index,
    loading,
    rowClass,
    selected: row?.original?.selected,
    onClick: handleClick,
    rowSelectionAllowed,
  };
}

export default TableBody;