import {Button} from "components/Button";
import style from "pages/MediaStorage/BucketSettingsModal/style.module.css";
import {ChevronLeft} from "icons";
import PropTypes from "prop-types";
import React from "react";

export default function Footer({
    corsData,
    onSubmit,
    handleDelete,
    handleClose,
    submitting,
  }) {
  const CancelButton = (
    <Button
      type="secondary"
      className={style.cancel}
      onClick={handleClose}
    >
      Cancel
    </Button>
  );
  const SaveButton = (
    <Button
      onClick={onSubmit}
      state={ submitting ? 'disabled': 'default' }
    >
      Save
    </Button>
  );
  if (corsData.corsScreenActive) {
    return (
      <div className={style.footer}>
        <Button
          type="secondary"
          onClick={corsData.goToBucketSettings}
          icon={<ChevronLeft/>}
        >
          Back to Bucket Settings
        </Button>
        { CancelButton }
        { SaveButton }
      </div>
    );
  } else {
    return (
      <div className={style.footer}>
        <Button
          variant="danger"
          onClick={handleDelete}
        >
          Delete
        </Button>
        { CancelButton }
        { SaveButton }
      </div>
    );
  }
}

Footer.propTypes = {
  corsData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};
