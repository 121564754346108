import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { 
    useNavigate,
    useLocation
} from 'react-router-dom';

// import { validateFields } from './validation';
import { validateFields } from '../../helpers/validation';

import { Button } from '../../components/Button';
import { InputField } from '../../components/InputField'

import { ReactComponent as Logo } from '../../assets/images/QencodeLogo.svg';

import styles from './index.module.css';

// password-set?token=<token>&secret=<secret>

const SetNewPasswordPage = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const secret = queryParams.get('secret');

    const [validationErrors, setValidationErrors] = useState({});

    const [credentials, setCredentials] = useState({ 
        token: token,
        secret: secret,
        password: '',
        password_confirm: ''
    });

    const { 
        setNewPassword, authError, setAuthError, logout
    } = useAuth();

    const handleSetNewPassword = async (e) => {
        // clear error
        if (authError) {
            setAuthError(null);
        }

        e.preventDefault();

        // do input validation on the client side 1st
        const fieldsToValidate = ['password', 'password_confirm']; 
        const { isValid, errors } = validateFields(credentials, fieldsToValidate);
        setValidationErrors(errors); // Update the validation errors state
      
        if (!isValid) {
          console.log("Validation errors", errors);
          return; // Stop the submission if validation fails
        }      


        setIsSubmitting(true); // Disable the button
        const success = await setNewPassword(credentials); // Call the setNewPassword function from the context
        if (success) {
            // log data in AuthContext
            //console.log("New password set")
            navigate('/login') // redirect to login page
        }else {
            // log data in AuthContext
            //console.log("Signup complete failure, show message, etc.")
        }      
        setIsSubmitting(false); // Re-enable the button
    };

    const handleInputChange = (e) => {
        // Reset error when the user starts typing
        if (authError) {
            setAuthError(null);
        }

        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
    };

    // New useEffect to logout any existing user session
    useEffect(() => {
        logout(); // Ensure no existing session interferes with the signup process
    }, [logout]);        

    useEffect(() => {
        // This function runs when the component mounts
        setAuthError(null); // Clear the login error state
        // Optionally, if you want to clear any signup errors when navigating to the login page:
        // setSignupError(null);
      
        // This function runs when the component unmounts
        return () => {
            setAuthError(null); // Clear the login error state again
          // Optionally, clear the signup errors again:
          // setSignupError(null);
        };
    }, [setAuthError, /* setSignupError */]);

    return (
        <div className={styles.container}>
            <Logo className={styles.logo} />

            <div className={styles.authWindow}>

                <div className={styles.title}>Create new Password?</div>

                <div className={styles.form}>
                    
                    <div className={styles.row}>             
                        <InputField
                            inputText={credentials.password}
                            onChange={handleInputChange}
                            name="password"
                            placeholder="Password"
                            size="lg"
                            type='password'
                            label="Password"
                            state={validationErrors.password ? 'error' : 'default'}
                            hint={validationErrors.password ? validationErrors.password : undefined}
                        />
                    </div>

                    <div className={styles.row}>             
                        <InputField
                            inputText={credentials.password_confirm}
                            onChange={handleInputChange}
                            name="password_confirm"
                            placeholder="Password"
                            size="lg"
                            type='password'
                            label="Confirm Password"
                            state={validationErrors.password_confirm ? 'error' : 'default'}
                            hint={validationErrors.password_confirm ? validationErrors.password_confirm : undefined}
                        />
                    </div>                    

                    <div className={styles.formFooter}>
                        <Button 
                            onClick={handleSetNewPassword} 
                            className={styles.formFooterBtn} state={isSubmitting ? "disabled" : undefined} fullWidth={true} size='lg'>
                            Reset Password
                        </Button>                                
                    </div>

                    {authError && <div className={styles.errorMessage}>{authError}</div>}
                </div>
            </div>  

        </div>
    );
};

export default SetNewPasswordPage;

