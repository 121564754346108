import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as VideoPlayer } from 'assets/images/VideoPlayer.svg';
import CreatePlayerButton from "pages/Player/VideoPlayerListPage/CreatePlayerButton";

function EmptyMessage({ onClick }) {
  return (
    <div className="tableNodata">
      <div className="tableNodataImage">
        <VideoPlayer/>
      </div>
      <h3 className="tableNodataTitle">
        No Video Players created yet
      </h3>
      <p className="tableNodataDescription">
        Start by creating a new video player
      </p>
      <CreatePlayerButton onClick={ onClick} />
    </div>
  );
}

EmptyMessage.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default EmptyMessage;