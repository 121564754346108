import React, {useState, useEffect } from 'react';

import { 
  generate2FAQRCode,
  delete2FAQRCode
} from '../api/qencode-account-api';

import { useAuth } from '../context/AuthContext';
// generate2FAQRCode

const DashboardPage = () => {

  const [qrCode, setQrCode] = useState(null);

  const { 
    getAccessToken
  } = useAuth();

  const handleEnable2FA = async () => {
    const token = getAccessToken()
    console.log("token: ", token)

    const {success, qrDataUrl} = await generate2FAQRCode(token);
    if (success && qrDataUrl) {
      console.log("generate2FAQRCode worked")
      setQrCode(qrDataUrl);
    } else {
      console.log("Handle generate2FAQRCode failure, show message, etc.")
    }
  }

  const handleDisable2FA = async () => {
    const token = getAccessToken()
    console.log("token: ", token)

    const success = await delete2FAQRCode(token);
    if (success) {
      console.log("delete2FAQRCode worked")
      setQrCode(null);
    } else {
        console.log("Handle delete2FAQRCode failure, show message, etc.")
    }
  }


  useEffect(() => {
    // This is the cleanup function that React will run when the component unmounts
    return () => {
      if (qrCode) {
        URL.revokeObjectURL(qrCode);
        setQrCode(null); // Optionally, clear the QR code state
      }
    };
  }, [qrCode]); // This effect depends on the qrCode state

  return (
      <div>
        <h1>Dashboard</h1>
        <p>You are logged in and can view your dashboard information here.</p>
        <button onClick={handleEnable2FA}>Enable 2FA</button>
        <button onClick={handleDisable2FA}>Disable 2FA</button>

        <div>
          {qrCode && <img src={qrCode} alt="2FA QR Code" />}
        </div>
      </div>
  );
};

export default DashboardPage;
