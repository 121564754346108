import PropTypes from "prop-types";
import React from "react";

export const LiveSteaming = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M5 3.25C3.48122 3.25 2.25 4.48122 2.25 6V13.6617C2.25 15.1804 3.48122 16.4117 5 16.4117H11.0333C12.5521 16.4117 13.7833 15.1804 13.7833 13.6617V12.9887C13.7833 12.6766 13.9766 12.3971 14.2687 12.287C14.5607 12.1768 14.8905 12.2591 15.0966 12.4935L17.0788 14.7479C17.1516 14.8306 17.2565 14.8781 17.3667 14.8781C17.5784 14.8781 17.75 14.7064 17.75 14.4947V5.50527C17.75 5.39505 17.7026 5.29017 17.6198 5.21739C17.4608 5.0776 17.2186 5.09316 17.0788 5.25215L15.0966 7.50652C14.8905 7.74092 14.5607 7.82317 14.2687 7.71304C13.9766 7.6029 13.7833 7.3234 13.7833 7.01128V6C13.7833 4.48122 12.5521 3.25 11.0333 3.25H5ZM0.75 6C0.75 3.65279 2.65279 1.75 5 1.75H11.0333C13.0806 1.75 14.7898 3.19758 15.1932 5.12504L15.9523 4.26166C16.6391 3.48054 17.8291 3.4041 18.6103 4.09092C19.0169 4.44848 19.25 4.96378 19.25 5.50527V14.4947C19.25 15.5349 18.4068 16.3781 17.3667 16.3781C16.8252 16.3781 16.3099 16.145 15.9523 15.7383L15.1287 14.8016C14.6305 16.5953 12.9857 17.9117 11.0333 17.9117H5C2.65279 17.9117 0.75 16.0089 0.75 13.6617V6ZM5.46659 7.11537C6.09251 7.11537 6.59992 6.59878 6.59992 5.96152C6.59992 5.32427 6.09251 4.80768 5.46659 4.80768C4.84066 4.80768 4.33325 5.32427 4.33325 5.96152C4.33325 6.59878 4.84066 7.11537 5.46659 7.11537ZM12.1627 12.8846H3.85524C3.54228 12.8846 3.28857 13.1383 3.28857 13.4513V13.6731V13.7162C3.28857 14.6551 4.04969 15.4162 4.98857 15.4162H11.0294C11.9683 15.4162 12.7294 14.6551 12.7294 13.7162V13.6731V13.4513C12.7294 13.1383 12.4757 12.8846 12.1627 12.8846Z" fill="currentColor"/>
    </svg>    
  );
};

LiveSteaming.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

LiveSteaming.defaultProps = {
  color: 'currentColor', // Or any default color
};