import React from 'react';
import PropTypes from 'prop-types';
import {OptionsMenu} from "components/OptionsMenu";
import {Delete, Rename, Key} from "icons";
import {modals} from 'pages/MediaStorage/AccessKeysPage/config'

function MoreActions({ handleOpenModal, accessKeyProp }) {
  return (
    <OptionsMenu
      menuItems={
        [
          {
            text: 'Rename',
            icon: <Rename label="Rename"/>,
            onClick: () => handleOpenModal(accessKeyProp, modals.rename ),
          },
          {
            text: 'Regenerate Key',
            onClick: () => handleOpenModal(accessKeyProp, modals.regenerate ),
            icon: <Key label="Key"/>,
          },
          {
            icon: <Delete label="Remove" />,
            onClick: () => handleOpenModal(accessKeyProp, modals.delete ),
            text: "Delete",
            danger: true,
          }
        ]
      }
    />
  )
}

MoreActions.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
  accessKeyProp: PropTypes.object.isRequired,
};

export default MoreActions;