// const protocol = 'https'
// const endpoint = 'auth-qa.qencode.com'
// const api_version = 'v1'

//const base_api_url = `${protocol}://${endpoint}/${api_version}`
const server_prefix = process.env.REACT_APP_SERVER_PREFIX || '/backend';
//const server_prefix = '/backend' // for prod
//const server_prefix = 'http://localhost:8002/backend' // for local


export const loginUser = async ({email, password}) => {
  const path = '/auth/login';

  try {
    const response = await fetch(`${server_prefix}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password
      }),
    });

    const responseBody = await response.json();

    if (!response.ok) {
      // Convert detail if it's an array of objects
      const detailMessage = Array.isArray(responseBody.detail)
        ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
        : responseBody.detail || `HTTP error: ${response.status} ${response.statusText}`;

      return {
        success: false,
        error: responseBody.error || 'unknown_error',
        detail: detailMessage,
      };
    }

    if (responseBody.error && responseBody.error !== 0) {
      const detailMessage = Array.isArray(responseBody.detail)
        ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
        : responseBody.detail || 'An error occurred';

      return {
        success: false,
        error: responseBody.error,
        detail: detailMessage,
      };
    }

    // If the response is successful and contains no error code
    return {
      success: true,
      access_token: responseBody.access_token,
      token_expire: responseBody.token_expire,
      refresh_token: responseBody.refresh_token,
      refresh_token_expire: responseBody.refresh_token_expire,
      two_fa_required: responseBody.two_fa_required,
      detail: responseBody.detail
    };
  } catch (error) {
    return {
      success: false,
      error: 'network_error',
      detail: error.message || 'Failed to complete request due to a network error'
    };
  }
};


// authorize using oauth2
export const getOauth2Url = ({provider}) => {

  // Map provider to OAuth2Provider value
  const providerMap = {
    'google': 'google-oauth2',
    'github': 'github',
  };

  const OAuth2Provider = providerMap[provider] || '';

  //return `${base_api_url}/oauth2/${OAuth2Provider}/authorize`
  return `/oauth2/${OAuth2Provider}/authorize`
};

export const verifyUser2FA = async ({ accessToken, code }) => {
  try {
    const parsedCode = parseInt(code, 10); // Base-10 number system

    if (isNaN(parsedCode)) {
      return { success: false, error: 'validation_error', detail: 'Invalid code format' };
    }

    const path = '/auth/verify-2fa';

    const response = await fetch(`${server_prefix}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify({ code: parsedCode }),
    });

    const responseBody = await response.json();

    if (!response.ok) {
      // Convert detail if it's an array of objects
      const detailMessage = Array.isArray(responseBody.detail)
        ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
        : responseBody.detail || `HTTP error: ${response.status} ${response.statusText}`;

      return {
        success: false,
        error: responseBody.error || 'unknown_error',
        detail: detailMessage,
      };
    }

    if (responseBody.error && responseBody.error !== 0) {
      const detailMessage = Array.isArray(responseBody.detail)
        ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
        : responseBody.detail || 'An error occurred';

      return {
        success: false,
        error: responseBody.error,
        detail: detailMessage,
      };
    }

    // If the response is successful and contains no server-reported error code
    return {
      success: true,
      access_token: responseBody.access_token,
      token_expire: responseBody.token_expire,
      refresh_token: responseBody.refresh_token,
      refresh_token_expire: responseBody.refresh_token_expire,
      detail: responseBody.detail
    };
  } catch (error) {
    return {
      success: false,
      error: 'network_error',
      detail: error.message || 'Failed to complete request due to a network error'
    };
  }
};

export const signupUser = async ({ email }) => {
  try {
    const path = '/auth/signup';

    const response = await fetch(`${server_prefix}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    const responseBody = await response.json();

    if (!response.ok) {
      // Convert detail if it's an array of objects
      const detailMessage = Array.isArray(responseBody.detail)
        ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
        : responseBody.detail || `HTTP error: ${response.status} ${response.statusText}`;

      return {
        success: false,
        error: responseBody.error || 'unknown_error',
        detail: detailMessage,
      };
    }

    if (responseBody.error && responseBody.error !== 0) {
      const detailMessage = Array.isArray(responseBody.detail)
        ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
        : responseBody.detail || 'An error occurred';

      return {
        success: false,
        error: responseBody.error,
        detail: detailMessage,
      };
    }

    // If the response is successful and contains no server-reported error code
    return {
      success: true,
      detail: responseBody.detail // assuming there might be a success message or relevant details
    };
  } catch (error) {
    return {
      success: false,
      error: 'network_error',
      detail: error.message || 'Failed to complete request due to a network error'
    };
  }
};

export const completeUserSignup = async (data) => {
  const path = '/auth/complete/signup';
  try {
    const response = await fetch(`${server_prefix}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const responseBody = await response.json();

    if (!response.ok) {
      // Convert detail if it's an array of objects
      const detailMessage = Array.isArray(responseBody.detail)
        ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
        : responseBody.detail || `HTTP error: ${response.status} ${response.statusText}`;

      return {
        success: false,
        error: responseBody.error || 'unknown_error',
        detail: detailMessage,
      };
    }

    if (responseBody.error && responseBody.error !== 0) {
      const detailMessage = Array.isArray(responseBody.detail)
        ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
        : responseBody.detail || 'An error occurred';

      return {
        success: false,
        error: responseBody.error,
        detail: detailMessage,
      };
    }

    // If the response is successful and contains no error code
    return {
      success: true,
      access_token: responseBody.access_token,
      token_expire: responseBody.token_expire,
      refresh_token: responseBody.refresh_token,
      refresh_token_expire: responseBody.refresh_token_expire,
      detail: responseBody.detail
    };
  } catch (error) {
    return {
      success: false,
      error: 'network_error',
      detail: error.message || 'Failed to complete request due to a network error'
    };
  }
};

export const completeUserInvite = async (data) => {
  const path = '/auth/complete/invite';
  try {
    const response = await fetch(`${server_prefix}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const responseBody = await response.json();

    if (!response.ok) {
      // Convert detail if it's an array of objects
      const detailMessage = Array.isArray(responseBody.detail)
        ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
        : responseBody.detail || `HTTP error: ${response.status} ${response.statusText}`;

      return {
        success: false,
        error: responseBody.error || 'unknown_error',
        detail: detailMessage,
      };
    }

    if (responseBody.error && responseBody.error !== 0) {
      const detailMessage = Array.isArray(responseBody.detail)
        ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
        : responseBody.detail || 'An error occurred';

      return {
        success: false,
        error: responseBody.error,
        detail: detailMessage,
      };
    }

    // If the response is successful and contains no error code
    return {
      success: true,
      access_token: responseBody.access_token,
      token_expire: responseBody.token_expire,
      refresh_token: responseBody.refresh_token,
      refresh_token_expire: responseBody.refresh_token_expire,
      detail: responseBody.detail
    };
  } catch (error) {
    return {
      success: false,
      error: 'network_error',
      detail: error.message || 'Failed to complete request due to a network error'
    };
  }
};



// complete using oauth2
// export const completeUserSignupOauth2 = async ({
//   provider,
//   token,
//   first_name,
//   last_name,
//   organization,
//   phone
// }) => {

//   // Map provider to OAuth2Provider value
//   const providerMap = {
//     'google': 'google-oauth2',
//     'github': 'github',
//   };

//   const OAuth2Provider = providerMap[provider] || '';

//   try {

//     const path = `/oauth2/${OAuth2Provider}/complete`

//     const response = await fetch(`${path}`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         token,
//         first_name,
//         last_name,
//         organization,
//         phone
//       }),
//     });

//     const { 
//       // success, detail, timestamp, 
//       error, detail, access_token, refresh_token, token_expire, refresh_token_expire
//     } = await response.json();


//       // Check if the response is OK and the 'error' key is not present or is 0
//       if (error === 0 && access_token && token_expire && refresh_token && refresh_token_expire) {
//         return { success: true, access_token, token_expire, refresh_token, refresh_token_expire };
//     }

//     // Handle error response
//     if (error) {
//         return { success: false, error, detail };
//     }

//     return { success: false, error: 'Failed to complete signup' };
//   } catch (error) {
//     return { success: false, error: error.message || 'Failed to complete signup'};
//   }
// };

// export const completeUserSignupOauth2 = async ({
//   provider,
//   token,
//   first_name,
//   last_name,
//   organization,
//   phone
// }) => {

//   // Map provider to OAuth2Provider value
//   const providerMap = {
//     'google': 'google-oauth2',
//     'github': 'github',
//   };

//   const OAuth2Provider = providerMap[provider] || '';

//   try {

//     const path = `/oauth2/${OAuth2Provider}/complete`;

//     const response = await fetch(`${server_prefix}${path}`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         token,
//         first_name,
//         last_name,
//         organization,
//         phone
//       }),
//     });

//     const responseBody = await response.json();

//     if (!response.ok) {
//       // Convert detail if it's an array of objects
//       const detailMessage = Array.isArray(responseBody.detail)
//         ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
//         : responseBody.detail || `HTTP error: ${response.status} ${response.statusText}`;

//       return {
//         success: false,
//         error: responseBody.error || 'unknown_error',
//         detail: detailMessage,
//       };
//     }

//     if (responseBody.error && responseBody.error !== 0) {
//       const detailMessage = Array.isArray(responseBody.detail)
//         ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
//         : responseBody.detail || 'An error occurred';

//       return {
//         success: false,
//         error: responseBody.error,
//         detail: detailMessage,
//       };
//     }

//     // If the response is successful and contains no error code
//     return {
//       success: true,
//       access_token: responseBody.access_token,
//       token_expire: responseBody.token_expire,
//       refresh_token: responseBody.refresh_token,
//       refresh_token_expire: responseBody.refresh_token_expire,
//       detail: responseBody.detail
//     };

//   } catch (error) {
//     return {
//       success: false,
//       error: 'network_error',
//       detail: error.message || 'Failed to complete signup due to a network error'
//     };
//   }
// };

export const completeUserSignupOauth2 = async (data) => {

  const {provider} = data

  // Map provider to OAuth2Provider value
  const providerMap = {
    'google': 'google-oauth2',
    'github': 'github',
  };

  const OAuth2Provider = providerMap[provider] || '';

  try {

    const path = `/oauth2/${OAuth2Provider}/complete`;

    const response = await fetch(`${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    // const response = await fetch(`${server_prefix}${path}`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(data),
    // });

    const responseBody = await response.json();

    if (!response.ok) {
      // Convert detail if it's an array of objects
      const detailMessage = Array.isArray(responseBody.detail)
        ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
        : responseBody.detail || `HTTP error: ${response.status} ${response.statusText}`;

      return {
        success: false,
        error: responseBody.error || 'unknown_error',
        detail: detailMessage,
      };
    }

    if (responseBody.error && responseBody.error !== 0) {
      const detailMessage = Array.isArray(responseBody.detail)
        ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
        : responseBody.detail || 'An error occurred';

      return {
        success: false,
        error: responseBody.error,
        detail: detailMessage,
      };
    }

    // If the response is successful and contains no error code
    return {
      success: true,
      access_token: responseBody.access_token,
      token_expire: responseBody.token_expire,
      refresh_token: responseBody.refresh_token,
      refresh_token_expire: responseBody.refresh_token_expire,
      detail: responseBody.detail
    };

  } catch (error) {
    return {
      success: false,
      error: 'network_error',
      detail: error.message || 'Failed to complete signup due to a network error'
    };
  }
};


export const resendActivationEmail = async ({ email }) => {
  try {
    const path = '/auth/signup/resend';

    const response = await fetch(`${server_prefix}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        email 
      }),
    });

    const responseBody = await response.json();

    if (!response.ok) {
      // Convert detail if it's an array of objects
      const detailMessage = Array.isArray(responseBody.detail)
        ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
        : responseBody.detail || `HTTP error: ${response.status} ${response.statusText}`;

      return {
        success: false,
        error: responseBody.error || 'unknown_error',
        detail: detailMessage,
      };
    }

    if (responseBody.error && responseBody.error !== 0) {
      const detailMessage = Array.isArray(responseBody.detail)
        ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
        : responseBody.detail || 'An error occurred';

      return {
        success: false,
        error: responseBody.error,
        detail: detailMessage,
      };
    }

    // If the response is successful and contains no server-reported error code
    return {
      success: true,
      detail: responseBody.detail // assuming there might be a success message or relevant details
    };
  } catch (error) {
    return {
      success: false,
      error: 'network_error',
      detail: error.message || 'Failed to resend activation email due to a network error'
    };
  }
};

export const sendPasswordResetEmail = async ({ email }) => {
  try {
    const path = '/auth/password-reset';

    const response = await fetch(`${server_prefix}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    const responseBody = await response.json();

    if (!response.ok) {
      // Convert detail if it's an array of objects
      const detailMessage = Array.isArray(responseBody.detail)
        ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
        : responseBody.detail || `HTTP error: ${response.status} ${response.statusText}`;

      return {
        success: false,
        error: responseBody.error || 'unknown_error',
        detail: detailMessage,
      };
    }

    if (responseBody.error && responseBody.error !== 0) {
      const detailMessage = Array.isArray(responseBody.detail)
        ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
        : responseBody.detail || 'An error occurred';

      return {
        success: false,
        error: responseBody.error,
        detail: detailMessage,
      };
    }

    // If the response is successful and contains no server-reported error code
    return {
      success: true,
      detail: responseBody.detail // assuming there might be a success message or relevant details
    };
  } catch (error) {
    return {
      success: false,
      error: 'network_error',
      detail: error.message || 'Failed to send password reset email due to a network error'
    };
  }
};

export const setNewUserPassword = async ({token, secret, password, password_confirm}) => {
  try {
    const path = '/auth/password-set';

    const response = await fetch(`${server_prefix}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token,
        secret,
        password,
        password_confirm
      }),
    });

    const responseBody = await response.json();

    if (!response.ok) {
      // Convert detail if it's an array of objects
      const detailMessage = Array.isArray(responseBody.detail)
        ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
        : responseBody.detail || `HTTP error: ${response.status} ${response.statusText}`;

      return {
        success: false,
        error: responseBody.error || 'unknown_error',
        detail: detailMessage,
      };
    }

    if (responseBody.error && responseBody.error !== 0) {
      const detailMessage = Array.isArray(responseBody.detail)
        ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
        : responseBody.detail || 'An error occurred';

      return {
        success: false,
        error: responseBody.error,
        detail: detailMessage,
      };
    }

    // If the response is successful and contains no server-reported error code
    return {
      success: true,
      detail: responseBody.detail // Assuming there might be a success message or relevant details
    };
  } catch (error) {
    return {
      success: false,
      error: 'network_error',
      detail: error.message || 'Failed to set new password due to a network error'
    };
  }
};


// /backend/refresh_token 
export const refreshAccessToken = async ({ refreshTokenParam }) => {
  try {
      const path = '/auth/refresh-token';
      const response = await fetch(`${server_prefix}${path}`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              refresh_token: refreshTokenParam
          }),
      });

      const responseBody = await response.json();

      if (!response.ok) {
          // Convert detail if it's an array of objects
          const detailMessage = Array.isArray(responseBody.detail)
              ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
              : responseBody.detail || `HTTP error: ${response.status} ${response.statusText}`;

          return {
              success: false,
              error: responseBody.error || 'unknown_error',
              detail: detailMessage,
          };
      }

      if (responseBody.error && responseBody.error !== 0) {
          const detailMessage = Array.isArray(responseBody.detail)
              ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
              : responseBody.detail || 'An error occurred';

          return {
              success: false,
              error: responseBody.error,
              detail: detailMessage,
          };
      }

      // If the response is successful and contains all necessary tokens
      if (responseBody.access_token && responseBody.token_expire && responseBody.refresh_token && responseBody.refresh_token_expire) {
          return {
              success: true,
              access_token: responseBody.access_token,
              token_expire: responseBody.token_expire,
              refresh_token: responseBody.refresh_token,
              refresh_token_expire: responseBody.refresh_token_expire
          };
      }

      // If some expected token data is missing
      return { success: false, error: 'Incomplete token data', detail: 'Failed to refresh access token due to incomplete data' };

  } catch (error) {
      return {
          success: false,
          error: 'network_error',
          detail: error.message || 'Failed to refresh access token due to a network error'
      };
  }
};

export async function enableMediaStorage({
  accessToken,
}) {
  const genericError = 'Failed to enable media storage';
  try {
    const path = '/storage/enable';
    const response = await fetch(`${server_prefix}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    });
    if (response.ok) {
      return {
        success: true,
      }
    }
    
    return {
      success: false,
      error: genericError,
    }
  } catch (error) {
    return {
      success: false,
      error: genericError,
    }
  }
}

export const verifyAccessToken = async (accessToken) => {
  try {
      const path = '/auth/verify-token';
      const response = await fetch(`${server_prefix}${path}`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
          }
      });

      const responseBody = await response.json();

      if (!response.ok) {
          // Convert detail if it's an array of objects
          const detailMessage = Array.isArray(responseBody.detail)
              ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
              : responseBody.detail || `HTTP error: ${response.status} ${response.statusText}`;

          return {
              success: false,
              error: responseBody.error || 'unknown_error',
              detail: detailMessage,
          };
      }

      if (responseBody.error && responseBody.error !== 0) {
          const detailMessage = Array.isArray(responseBody.detail)
              ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
              : responseBody.detail || 'An error occurred';

          return {
              success: false,
              error: responseBody.error,
              detail: detailMessage,
          };
      }

    // If the response is successful and contains no server-reported error code
    return {
      success: true,
      detail: responseBody?.detail 
    };

  } catch (error) {
      return {
          success: false,
          error: 'network_error',
          detail: error.message || 'Failed to verify access token due to a network error'
      };
  }
};