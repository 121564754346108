import PropTypes from "prop-types";
import React from "react";

export const FolderAdd = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 16 16" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M3.2 1.40002C1.98497 1.40002 1 2.385 1 3.60002V4.40002V11.6C1 13.2569 2.34315 14.6 4 14.6H7.65629C7.40097 14.2231 7.20273 13.8191 7.06158 13.4H4C3.00589 13.4 2.2 12.5941 2.2 11.6V4.40002V3.60002C2.2 3.04774 2.64771 2.60002 3.2 2.60002H7.14151C7.47586 2.60002 7.78809 2.76713 7.97356 3.04533L8.86108 4.3766C9.12073 4.76608 9.55785 5.00002 10.0259 5.00002H11.6C12.5941 5.00002 13.4 5.80591 13.4 6.80002V7.0618C13.819 7.20296 14.2231 7.4012 14.6 7.65653V6.80002C14.6 5.14317 13.2569 3.80002 11.6 3.80002H10.0259C9.95908 3.80002 9.89663 3.7666 9.85954 3.71096L8.97202 2.37969C8.56399 1.76765 7.87708 1.40002 7.14151 1.40002H3.2ZM3.75986 3.20004C3.31804 3.20004 2.95986 3.55821 2.95986 4.00004V4.80004C2.95986 5.02095 3.13895 5.20004 3.35986 5.20004H7.69343C8.00434 5.20004 8.19639 4.86085 8.03642 4.59424L7.43294 3.58844C7.28837 3.34748 7.02796 3.20004 6.74695 3.20004H3.75986ZM14.5153 9.08467C13.0156 7.58506 10.5843 7.58506 9.08467 9.08467C7.58506 10.5843 7.58506 13.0156 9.08467 14.5153C10.5843 16.0149 13.0156 16.0149 14.5153 14.5153C16.0149 13.0156 16.0149 10.5843 14.5153 9.08467ZM12.28 9.98977C12.28 9.72467 12.0651 9.50977 11.8 9.50977C11.5349 9.50977 11.32 9.72467 11.32 9.98977V11.4298H9.87997C9.61488 11.4298 9.39997 11.6447 9.39997 11.9098C9.39997 12.1749 9.61488 12.3898 9.87997 12.3898L11.32 12.3898V13.8298C11.32 14.0949 11.5349 14.3098 11.8 14.3098C12.0651 14.3098 12.28 14.0949 12.28 13.8298V12.3898H13.72C13.9851 12.3898 14.2 12.1749 14.2 11.9098C14.2 11.6447 13.9851 11.4298 13.72 11.4298H12.28V9.98977Z" fill="currentColor"/>
    </svg>    
  );
};

FolderAdd.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

FolderAdd.defaultProps = {
  color: 'currentColor', // Or any default color
};