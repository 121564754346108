import PropTypes from "prop-types";
import React from "react";

export const Logout = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M8.44759 2.40002C5.01219 2.40002 2.19995 5.1162 2.19995 8.5C2.19995 11.8838 5.01219 14.6 8.44759 14.6C9.75877 14.6 10.9783 14.205 11.9851 13.5286C12.2601 13.3439 12.3333 12.9711 12.1485 12.696C11.9638 12.4209 11.591 12.3478 11.3159 12.5325C10.5024 13.079 9.51502 13.4 8.44759 13.4C5.64479 13.4 3.39996 11.1913 3.39996 8.5C3.39996 5.80869 5.64479 3.60002 8.44759 3.60002C9.51349 3.60002 10.4996 3.9201 11.3124 4.46513C11.5876 4.64968 11.9604 4.57618 12.1449 4.30096C12.3295 4.02573 12.256 3.65302 11.9807 3.46846C10.9748 2.79391 9.75689 2.40002 8.44759 2.40002ZM14.5932 8.93489L12.7317 10.7477C12.2425 11.2242 11.4061 10.8867 11.4061 10.213V9.03594H7.38466C7.05151 9.03594 6.78144 8.77293 6.78144 8.4485C6.78144 8.12406 7.05151 7.86104 7.38466 7.86104H11.4061V6.68453C11.4061 6.01078 12.2425 5.67337 12.7317 6.14979L14.5932 7.96262C14.8689 8.2311 14.8689 8.6664 14.5932 8.93489Z" fill="currentColor"/>
    </svg>    
  );
};

Logout.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Logout.defaultProps = {
  color: 'currentColor', // Or any default color
};