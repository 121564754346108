import {clearTypes} from "components/FilterSidebar/useFilter";
import {useEffect} from "react";

export default function useValueClearing(columnId, lastClearFilterId, lastClearType, clearFn) {
  const cleared = lastClearType?.startsWith(clearTypes.clearAll) ? true : (lastClearFilterId === columnId);
  
  useEffect(() => {
    if (cleared) {
      if ( lastClearType?.startsWith(clearTypes.clearAll) ||
           lastClearType?.startsWith(clearTypes.clearOneFilterAndInputs)
      ) {
        clearFn();
      }
    }
  }, [clearFn, lastClearType, cleared]);
}
