import React from 'react';
import PropTypes from 'prop-types';
import styles from './BucketDetailsHeader.module.css';

import { ChevronLeft, Dot, Calendar } from 'icons'

import { Button } from 'components/Button'

import { formatDateTime } from 'helpers/formatting';

import { getQencodeRegionLabel } from 'helpers/qencodeRegions'

export const BucketDetailsHeader = ({ bucket, onBackClick, controls, size }) => {
  return (
    <div className={`${styles.container}  ${size ? styles[size] : ''}`}>
        {
            onBackClick &&
            <div className={styles.header}>
                <Button
                    className={styles.backButton}
                    icon={<ChevronLeft label="Back" />}
                    onClick={onBackClick}
                    variant="link"
                >
                    Back to buckets
                </Button>                
            </div>            
        }
        <div className={styles.mainContainer}>
            <div className={styles.body}>
                {bucket?.name && <div className={styles.name}>{bucket?.name}</div>}
                <div className={styles.details}>
                    {
                        bucket?.region && 
                        <>
                            <div className={styles.region}>{getQencodeRegionLabel(bucket?.region)}</div>   
                            <Dot className={styles.delimeter} /> 
                        </>
                        
                    }
                    <div className={styles.dateContainer}>
                        <Calendar className={styles.icon}/> 
                        <div className={styles.date}>Created on {bucket?.created && <>{formatDateTime(bucket?.created)}</>}</div>
                    </div>
                </div>
            </div>  
            { controls && <div className={styles.controlsContainer}>{controls}</div>}
        </div>
    </div>
  );
};

BucketDetailsHeader.propTypes = {
    bucket: PropTypes.object,
    onBackClick: PropTypes.func,
    controls: PropTypes.node,
    size: PropTypes.string
};

BucketDetailsHeader.defaultProps = {
    bucket: null,
    onBackClick: null,
    controls: null,
    size: null
};