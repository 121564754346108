export default async function getItemsAndSize({
  getTotalSizeOfItemsInBucket,
  getNumberOfItemsInBucket,
  name,
  config,
}) {
  const {
    success: successItemCount,
    itemCount,
  } = await getNumberOfItemsInBucket( name, config );
  
  const {
    success: successTotalSize,
    totalSize,
  } = await getTotalSizeOfItemsInBucket( name, config );
  
  return {
    success: successItemCount || successTotalSize,
    totalSize,
    itemCount,
  };
}
