import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useAWS} from "api/useAWS";
import {Button} from "components/Button";
import getItemsAndSize from "pages/MediaStorage/BucketsPage/getItemsAndSize";
import {columnIds} from "pages/MediaStorage/BucketsPage/config";

function CalculateItems({
  info,
  mediaStorageData,
  columnId,
}) {
  const {
    getTotalSizeOfItemsInBucket,
    getNumberOfItemsInBucket,
  } = useAWS();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const config = {
    ...mediaStorageData,
    region: info.row.original.region,
  };
  
  async function calculate() {
    setLoading(true);
    const {
      success,
      itemCount,
      totalSize,
      } = await getItemsAndSize({
      getTotalSizeOfItemsInBucket,
      getNumberOfItemsInBucket,
      name: info.row.original.name,
      config,
    });
    
    setLoading(false);
    if (success) {
      info.table.options.meta?.updateData(info.row.index, columnIds.items, itemCount);
      info.table.options.meta?.updateData(info.row.index, columnIds.size, totalSize);
    }
    setError(!success);
  }
  
  if (error) {
    return (
      <span>Error</span>
    )
  }

  if (info.row?.original?.[columnId] === undefined) {
    return (
      <Button
        variant="link"
        state={ loading ? "disabled" : "default" }
        onClick={() => calculate(info)}
      >
        {
          loading ? "Calculating" : "Calculate"
        }
      </Button>
    );
  } else {
    return (
      <span>{ info.row?.original?.[columnId] }</span>
    )
  }
}

CalculateItems.propTypes = {
  info: PropTypes.object.isRequired,
  mediaStorageData: PropTypes.object.isRequired,
  columnId: PropTypes.string.isRequired,
};

export default CalculateItems;