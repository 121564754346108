import PropTypes from "prop-types";
import React from "react";

export const Stripe = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="36" height="16" viewBox="0 0 36 16" fill="none"
    >
      <path d="M19.4154 2.87735L17.0309 3.38401V1.46994L19.4154 0.972656V2.87735Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M24.3744 3.93759C23.4434 3.93759 22.8449 4.3692 22.5124 4.66944L22.3889 4.08771H20.2989V15.028L22.6739 14.5307L22.6834 11.8754C23.0254 12.1193 23.5289 12.4665 24.3649 12.4665C26.0654 12.4665 27.6139 11.1154 27.6139 8.14105C27.6044 5.42006 26.0369 3.93759 24.3744 3.93759ZM23.8044 10.4023C23.2439 10.4023 22.9114 10.2052 22.6834 9.9613L22.6739 6.48031C22.9209 6.20821 23.2629 6.02056 23.8044 6.02056C24.6689 6.02056 25.2674 6.97759 25.2674 8.20673C25.2674 9.46401 24.6784 10.4023 23.8044 10.4023Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M35.0999 8.23488C35.0999 5.8329 33.9219 3.93759 31.6704 3.93759C29.4094 3.93759 28.0414 5.8329 28.0414 8.21611C28.0414 11.0403 29.6564 12.4665 31.9744 12.4665C33.1049 12.4665 33.9599 12.2131 34.6059 11.8566V9.98006C33.9599 10.2991 33.2189 10.4961 32.2784 10.4961C31.3569 10.4961 30.5399 10.1771 30.4354 9.06994H35.0809C35.0809 9.01823 35.0843 8.90074 35.0883 8.76539C35.0936 8.58149 35.0999 8.36459 35.0999 8.23488ZM30.4069 7.34352C30.4069 6.28327 31.0624 5.84228 31.6609 5.84228C32.2404 5.84228 32.8579 6.28327 32.8579 7.34352H30.4069Z" fill="currentColor"/>
      <path d="M19.4154 4.0971H17.0309V12.307H19.4154V4.0971Z" fill="currentColor"/>
      <path d="M14.3234 4.09711L14.4754 4.79143C15.0359 3.77809 16.1474 3.98451 16.4514 4.09711V6.25513C16.1569 6.15192 15.2069 6.02056 14.6464 6.74303V12.307H12.2714V4.09711H14.3234Z" fill="currentColor"/>
      <path d="M9.7254 2.06105L7.4074 2.54895L7.3979 10.0645C7.3979 11.4532 8.4524 12.4759 9.8584 12.4759C10.6374 12.4759 11.2074 12.3351 11.5209 12.1662V10.2615C11.2169 10.3835 9.7159 10.8151 9.7159 9.42649V6.09562H11.5209V4.0971H9.7159L9.7254 2.06105Z" fill="currentColor"/>
      <path d="M4.1109 5.97364C3.6074 5.97364 3.3034 6.11438 3.3034 6.48031C3.3034 6.87984 3.82659 7.05559 4.47566 7.27363C5.53381 7.62908 6.92651 8.09693 6.9324 9.82994C6.9324 11.5094 5.5739 12.4759 3.5979 12.4759C2.7809 12.4759 1.8879 12.3164 1.0044 11.941V9.70796C1.8024 10.1396 2.8094 10.4586 3.5979 10.4586C4.1299 10.4586 4.5099 10.3178 4.5099 9.88623C4.5099 9.44368 3.94276 9.24139 3.25806 8.99718C2.2153 8.62526 0.899902 8.15609 0.899902 6.5929C0.899902 4.93216 2.1824 3.93759 4.1109 3.93759C4.8994 3.93759 5.6784 4.05957 6.4669 4.3692V6.57413C5.7449 6.18944 4.8329 5.97364 4.1109 5.97364Z" fill="currentColor"/>      
    </svg>    
  );
};

Stripe.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Stripe.defaultProps = {
  color: 'currentColor', // Or any default color
};