import React from 'react';
import PropTypes from 'prop-types';
import pageStyle from "pages/MediaStorage/AccessKeysPage/style.module.css";
import MaskedKey from "components/MaskedKey";

function KeyCell({ rawKey, secret }) {
  return (
    <div className={ secret ? pageStyle.secret : '' }>
      <MaskedKey
        rawKey={ rawKey }
        withCopy={true}
      />
      { secret ? (
        <div className={pageStyle.secretDisclosure}>
          <span>
            Secret:
          </span>
          <MaskedKey rawKey={ secret } withCopy={true}/>
        </div>
      ) : null }
    </div>
  )
}

KeyCell.propTypes = {
  rawKey: PropTypes.string.isRequired,
  secret: PropTypes.string,
};

export default KeyCell;