import React from 'react';

function VideoPlayerDetailPage() {
  return (
    <></>
  )
}

VideoPlayerDetailPage.propTypes = {};

export default VideoPlayerDetailPage;