import React, { useState, useEffect } from 'react';
import { Modal } from 'components/Modal';

import { Button } from 'components/Button'

import styles from './index.module.css';

export const NotMovedFilesModal = ({ files, open, handleClose }) => {

    const [notMovedFiles, setNotMovedFiles] = useState([]); // folders

    // when modal opens set initial states
    useEffect(() => {
        if(open && files.length > 0){
            setNotMovedFiles(files)             
        }
    }, [open, files])        

    return (
        <Modal isOpen={open} onClose={handleClose}
            header={
                <>
                    {
                        notMovedFiles && notMovedFiles.length === 1 &&
                        <>
                            <h3>File Not Moved</h3>           
                            <p>The following file was not moved because it already exist in the target directory</p>                           
                        </>
                    }

                    {
                        notMovedFiles && notMovedFiles.length > 1 &&
                        <>
                            <h3>Files Not Moved</h3>           
                            <p>The following files were not moved because they already exist in the target directory</p>                           
                        </>
                    }     
                </>
            }
            footer={
                <>
                    <Button
                        variant="modalFooter"
                        onClick={handleClose}
                    >
                        Close
                    </Button>                     
                </>
            }
        >
            <>
                {notMovedFiles.map((item, index) => (
                    <div key={index}><span className={styles.itemName}><strong>{item.displayName}</strong></span></div>
                ))}
            </>  
        </Modal>           
    );
}
