import config from 'config';

// handle url like s3://ams2.digitaloceanspaces.com/bucket_name/fil/e.webm
export const parseDestinationUrl = (url) => {
    //console.log("parseDestinationUrl: ", url);

    // If no URL is provided, return an empty object
    if (!url || typeof url !== 'string') {
        return {};
    }

    const qencodePattern = /qencode\.com/;
    const backblazePattern = /backblaze\.com/;
    const digitalOceanPattern = /digitaloceanspaces\.com/;
    const amazonPattern = /s3\.([^.]+)\.amazonaws\.com/;
    const googlePattern = /storage\.googleapis\.com/;
    const azurePattern = /^azblob:\/\/([^/]+)\//; // Extract host for Azure
    const genericS3Pattern = /^s3:\/\/([^/]+)\/([^/]+)\//; // Detect generic S3 and extract endpoint and bucket
    const ftpPattern = /^ftp:\/\/([^/]+)\//; // Detect FTP and extract endpoint
    const sftpPattern = /^sftp:\/\/([^/]+)\//; // Detect SFTP and extract endpoint

    let parsedData = {};

    const getBaseUrlAndPath = (baseUrl, url) => {
        const path = url.replace(baseUrl, '');
        return { baseUrl, path };
    };

    // s3://us-west.s3-qa.qencode.com/qa-2022-12-29/video.mp4
    // s3://us-west.qencode.com/qa-2022-12-29/video.mp4 - I was creating this by mistake

    if (qencodePattern.test(url)) {
        const regionMatch = url.match(/^s3:\/\/([^.]+)\./);
        const bucketMatch = url.match(/\.qencode.com\/([^/]+)/);

        const region = regionMatch ? regionMatch[1] : null;
        const bucket = bucketMatch ? bucketMatch[1] : null;

        //const baseUrl = `s3://${region}.qencode.com/${bucket}/`;
        // let baseUrl;
        // if (url.includes('s3-qa.qencode.com')) {
        //     baseUrl = `s3://${region}.s3-qa.qencode.com/${bucket}/`;
        // } else {
        //     baseUrl = `s3://${region}.qencode.com/${bucket}/`;
        // }

        const baseUrl = `s3://${region}.${config.s3Subdomain}.${config.domain}/${bucket}/`;

        const { path } = getBaseUrlAndPath(baseUrl, url);

        parsedData = {
            type: 'qencode_storage',
            region,
            bucket,
            baseUrl,
            path
        };
    } else {
        const bucketMatch = url.match(/[^/]+\/([^/]+)/);
        const bucket = bucketMatch ? bucketMatch[1] : null;

        if (backblazePattern.test(url)) {
            const baseUrl = `b2://${url.split('/').slice(2, 4).join('/')}/`;
            const { path } = getBaseUrlAndPath(baseUrl, url);
            parsedData = {
                type: 'third_party_storage',
                provider: 'backblaze',
                bucket,
                baseUrl,
                path
            };
        } else if (digitalOceanPattern.test(url)) {
            const regionMatch = url.match(/^s3:\/\/([^.]+)\./);
            const region = regionMatch ? regionMatch[1] : null;
            const baseUrl = `s3://${region}.digitaloceanspaces.com/${bucket}/`;
            const { path } = getBaseUrlAndPath(baseUrl, url);
            parsedData = {
                type: 'third_party_storage',
                provider: 'digitalocean',
                bucket,
                region,
                baseUrl,
                path
            };
        } else if (amazonPattern.test(url)) {
            const regionMatch = url.match(amazonPattern);
            const region = regionMatch ? regionMatch[1] : null;
            const baseUrl = `s3://${region}.amazonaws.com/${bucket}/`;
            const { path } = getBaseUrlAndPath(baseUrl, url);
            parsedData = {
                type: 'third_party_storage',
                provider: 'amazon',
                bucket,
                region,
                baseUrl,
                path
            };
        } else if (googlePattern.test(url)) {
            const baseUrl = `s3://storage.googleapis.com/${bucket}/`;
            const { path } = getBaseUrlAndPath(baseUrl, url);
            parsedData = {
                type: 'third_party_storage',
                provider: 'google',
                bucket,
                baseUrl,
                path
            };
        } else if (azurePattern.test(url)) {
            const hostMatch = url.match(azurePattern);
            const host = hostMatch ? hostMatch[1] : null;
            const baseUrl = `azblob://${host}/${bucket}/`;
            const { path } = getBaseUrlAndPath(baseUrl, url);
            parsedData = {
                type: 'third_party_storage',
                provider: 'azure',
                bucket,
                host,
                baseUrl,
                path
            };
        } else if (genericS3Pattern.test(url)) {
            const match = url.match(/^s3:\/\/([^/]+)\/([^/]+)/);
            const endpoint = match ? match[1] : null;                
            const baseUrl = `s3://${endpoint}/${bucket}/`;
            const { path } = getBaseUrlAndPath(baseUrl, url);
            parsedData = {
                type: 'third_party_storage',
                provider: 'generic_s3',
                bucket,
                endpoint,
                baseUrl,
                path
            };
        } else if (ftpPattern.test(url)) {
            const match = url.match(ftpPattern);
            const endpoint = match ? match[1] : null;
            const baseUrl = `ftp://${endpoint}/`;
            const { path } = getBaseUrlAndPath(baseUrl, url);
            parsedData = {
                type: 'third_party_storage',
                provider: 'ftp',
                bucket,
                endpoint,
                baseUrl,
                path
            };
        } else if (sftpPattern.test(url)) {
            const match = url.match(sftpPattern);
            const endpoint = match ? match[1] : null;
            const baseUrl = `sftp://${endpoint}/`;
            const { path } = getBaseUrlAndPath(baseUrl, url);
            parsedData = {
                type: 'third_party_storage',
                provider: 'sftp',
                bucket,
                endpoint,
                baseUrl,
                path
            };
        } else {
            const baseUrl = url.split('/').slice(0, -1).join('/') + '/';
            const { path } = getBaseUrlAndPath(baseUrl, url);
            parsedData = {
                type: 'third_party_storage',
                provider: '',
                bucket,
                baseUrl,
                path
            };
        }
    }

    //console.log("parsedData: ", parsedData)

    return parsedData;
};



// to handle urls like s3://ams2.digitaloceanspaces.com/bucket_name
// export const parseDestinationUrl = (url) => {
//     console.log("parseDestinationUrl: ", url);

//     // If no URL is provided, return an empty object
//     if (!url || typeof url !== 'string') {
//         return {};
//     }

//     const qencodePattern = /qencode\.com/;
//     const backblazePattern = /backblaze\.com/;
//     const digitalOceanPattern = /digitaloceanspaces\.com/;
//     const amazonPattern = /s3\.([^.]+)\.amazonaws\.com/;
//     const googlePattern = /storage\.googleapis\.com/;
//     const azurePattern = /^azblob:\/\/([^/]+)\//; // Extract host for Azure
//     const genericS3Pattern = /^s3:\/\/([^/]+)\/([^/]+)\//; // Detect generic S3 and extract endpoint and bucket
//     const ftpPattern = /^ftp:\/\/([^/]+)\//; // Detect FTP and extract endpoint
//     const sftpPattern = /^sftp:\/\/([^/]+)\//; // Detect SFTP and extract endpoint

//     let parsedData = {};

//     if (qencodePattern.test(url)) {
//         const regionMatch = url.match(/^s3:\/\/([^.]+)\./);
//         const bucketMatch = url.match(/\.qencode.com\/([^/]+)/);

//         const region = regionMatch ? regionMatch[1] : null;
//         const bucket = bucketMatch ? bucketMatch[1] : null;

//         parsedData = {
//             type: 'qencode_storage',
//             region,
//             bucket
//         };
//     } else {
//         const bucketMatch = url.match(/[^/]+\/([^/]+)/);
//         const bucket = bucketMatch ? bucketMatch[1] : null;

//         if (backblazePattern.test(url)) {
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'backblaze',
//                 bucket
//             };
//         } else if (digitalOceanPattern.test(url)) {
//             const regionMatch = url.match(/^s3:\/\/([^.]+)\./);
//             const region = regionMatch ? regionMatch[1] : null;
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'digitalocean',
//                 bucket,
//                 region
//             };
//         } else if (amazonPattern.test(url)) {
//             const regionMatch = url.match(amazonPattern);
//             const region = regionMatch ? regionMatch[1] : null;
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'amazon',
//                 bucket,
//                 region
//             };
//         } else if (googlePattern.test(url)) {
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'google',
//                 bucket
//             };
//         } else if (azurePattern.test(url)) {
//             const hostMatch = url.match(azurePattern);
//             const host = hostMatch ? hostMatch[1] : null;
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'azure',
//                 bucket,
//                 host
//             };
//         } else if (genericS3Pattern.test(url)) {
//             const match = url.match(/^s3:\/\/([^/]+)\/([^/]+)/);
//             const endpoint = match ? match[1] : null;                
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'generic_s3',
//                 bucket,
//                 endpoint
//             };
//         } else if (ftpPattern.test(url)) {
//             const match = url.match(ftpPattern);
//             const endpoint = match ? match[1] : null;
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'ftp',
//                 bucket,
//                 endpoint
//             };
//         } else if (sftpPattern.test(url)) {
//             const match = url.match(sftpPattern);
//             const endpoint = match ? match[1] : null;
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'sftp',
//                 bucket,
//                 endpoint
//             };
//         } else {
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: '',
//                 bucket
//             };
//         }
//     }

//     return parsedData;
// };



// ORIGINAL
// export const parseDestinationUrl = (url) => {
//     console.log("parseDestinationUrl: ", url)

//     // If no URL is provided, return an empty object
//     if (!url || typeof url !== 'string') {
//         return {};
//     }

//     const qencodePattern = /qencode\.com/;
//     const backblazePattern = /backblaze\.com/;
//     const digitalOceanPattern = /digitaloceanspaces\.com/;
//     const amazonPattern = /s3\.([^.]+)\.amazonaws\.com/;
//     const googlePattern = /storage\.googleapis\.com/;
//     const azurePattern = /^azblob:\/\/([^/]+)\//; // Extract host for Azure
//     const genericS3Pattern = /^s3:\/\/([^/]+)\/([^/]+)\//; // Detect generic S3 and extract endpoint and bucket
//     const ftpPattern = /^ftp:\/\/([^/]+)\//; // Detect FTP and extract endpoint
//     const sftpPattern = /^sftp:\/\/([^/]+)\//; // Detect SFTP and extract endpoint

//     let parsedData = {};

//     if (qencodePattern.test(url)) {
//         const regionMatch = url.match(/^s3:\/\/([^.]+)\./);
//         const bucketMatch = url.match(/\.qencode.com\/([^/]+)\//);

//         const region = regionMatch ? regionMatch[1] : null;
//         const bucket = bucketMatch ? bucketMatch[1] : null;

//         parsedData = {
//             type: 'qencode_storage',
//             region,
//             bucket
//         };
//     } else {
//         const bucketMatch = url.match(/[^/]+\/([^/]+)\//);
//         const bucket = bucketMatch ? bucketMatch[1] : null;

//         if (backblazePattern.test(url)) {
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'backblaze',
//                 bucket
//             };
//         } else if (digitalOceanPattern.test(url)) {
//             const regionMatch = url.match(/^s3:\/\/([^.]+)\./);
//             const region = regionMatch ? regionMatch[1] : null;
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'digitalocean',
//                 bucket,
//                 region
//             };
//         } else if (amazonPattern.test(url)) {
//             const regionMatch = url.match(amazonPattern);
//             const region = regionMatch ? regionMatch[1] : null;
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'amazon',
//                 bucket,
//                 region
//             };
//         } else if (googlePattern.test(url)) {
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'google',
//                 bucket
//             };
//         } else if (azurePattern.test(url)) {
//             const hostMatch = url.match(azurePattern);
//             const host = hostMatch ? hostMatch[1] : null;
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'azure',
//                 bucket,
//                 host
//             };
//         } else if (genericS3Pattern.test(url)) {
//             const match = url.match(/^s3:\/\/([^/]+)\/([^/]+)\//);
//             const endpoint = match ? match[1] : null;                
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'generic_s3',
//                 bucket,
//                 endpoint
//             };
//         } else if (ftpPattern.test(url)) {
//             const match = url.match(ftpPattern);
//             const endpoint = match ? match[1] : null;
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'ftp',
//                 bucket,
//                 endpoint
//             };
//         } else if (sftpPattern.test(url)) {
//             const match = url.match(sftpPattern);
//             const endpoint = match ? match[1] : null;
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'sftp',
//                 bucket,
//                 endpoint
//             };
//         } else {
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: '',
//                 bucket
//             };
//         }
//     }

//     return parsedData;
// };







// this will also get path
// export const parseDestinationUrl = (url) => {
//     console.log("parseDestinationUrl: ", url);

//     // If no URL is provided, return an empty object
//     if (!url || typeof url !== 'string') {
//         return {};
//     }

//     const qencodePattern = /qencode\.com/;
//     const backblazePattern = /backblaze\.com/;
//     const digitalOceanPattern = /digitaloceanspaces\.com/;
//     const amazonPattern = /s3\.([^.]+)\.amazonaws\.com/;
//     const googlePattern = /storage\.googleapis\.com/;
//     const azurePattern = /^azblob:\/\/([^/]+)\//; // Extract host for Azure
//     const genericS3Pattern = /^s3:\/\/([^/]+)\/([^/]+)\//; // Detect generic S3 and extract endpoint and bucket
//     const ftpPattern = /^ftp:\/\/([^/]+)\//; // Detect FTP and extract endpoint
//     const sftpPattern = /^sftp:\/\/([^/]+)\//; // Detect SFTP and extract endpoint

//     const filePathPattern = /[^/]+\/[^/]+\/(.+)/; // Extracts the file path after bucket
//     const filePathMatch = url.match(filePathPattern);
//     const path = filePathMatch ? filePathMatch[1] : null;

//     let parsedData = {};

//     if (qencodePattern.test(url)) {
//         const regionMatch = url.match(/^s3:\/\/([^.]+)\./);
//         const bucketMatch = url.match(/\.qencode.com\/([^/]+)\//);

//         const region = regionMatch ? regionMatch[1] : null;
//         const bucket = bucketMatch ? bucketMatch[1] : null;

//         parsedData = {
//             type: 'qencode_storage',
//             region,
//             bucket,
//             path
//         };
//     } else {
//         const bucketMatch = url.match(/[^/]+\/([^/]+)\//);
//         const bucket = bucketMatch ? bucketMatch[1] : null;

//         if (backblazePattern.test(url)) {
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'backblaze',
//                 bucket,
//                 path
//             };
//         } else if (digitalOceanPattern.test(url)) {
//             const regionMatch = url.match(/^s3:\/\/([^.]+)\./);
//             const region = regionMatch ? regionMatch[1] : null;
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'digitalocean',
//                 bucket,
//                 region,
//                 path
//             };
//         } else if (amazonPattern.test(url)) {
//             const regionMatch = url.match(amazonPattern);
//             const region = regionMatch ? regionMatch[1] : null;
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'amazon',
//                 bucket,
//                 region,
//                 path
//             };
//         } else if (googlePattern.test(url)) {
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'google',
//                 bucket,
//                 path
//             };
//         } else if (azurePattern.test(url)) {
//             const hostMatch = url.match(azurePattern);
//             const host = hostMatch ? hostMatch[1] : null;
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'azure',
//                 bucket,
//                 host,
//                 path
//             };
//         } else if (genericS3Pattern.test(url)) {
//             const match = url.match(genericS3Pattern);
//             const endpoint = match ? match[1] : null;
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'generic_s3',
//                 bucket,
//                 endpoint,
//                 path
//             };
//         } else if (ftpPattern.test(url)) {
//             const match = url.match(ftpPattern);
//             const endpoint = match ? match[1] : null;
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'ftp',
//                 bucket,
//                 endpoint,
//                 path
//             };
//         } else if (sftpPattern.test(url)) {
//             const match = url.match(sftpPattern);
//             const endpoint = match ? match[1] : null;
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: 'sftp',
//                 bucket,
//                 endpoint,
//                 path
//             };
//         } else {
//             parsedData = {
//                 type: 'third_party_storage',
//                 provider: '',
//                 bucket,
//                 path
//             };
//         }
//     }

//     return parsedData;
// };




