import React from 'react';
import PropTypes from 'prop-types';
import style from "pages/StatisticsPage/components/Chart/Tooltip/style.module.css";
import {regionCodeToColor} from "pages/StatisticsPage/config";
import classNames from "classnames";
import {gigabytesToReadable} from "components/Table/Cells/GbReadable";
import Region from "components/Region";

function StorageTooltipContent(props) {
  const {
    data,
    keys,
  } = props;
  const totalAmount = keys.reduce((sum, id) => sum + (data[id] || 0), 0);
  
  return (
    <table className="standardTable">
      <thead>
        <tr>
          <th>Type</th>
          <th className={style.bandwidthColumn}>Bandwidth</th>
        </tr>
      </thead>
      <tbody>
      { keys
          .filter((id) => data[id] > 1e-6)
          .map((id) => {
        return (
          <tr key={id}>
            <td className={ classNames(style.cell, style.mainCell) }>
              <div className={style.colorSample} style={{ 'backgroundColor': regionCodeToColor[id] }}></div>
              <span>
                <Region regionCode={id}/>
              </span>
            </td>
            <td className={style.cell}>
              { gigabytesToReadable(data[id]) }
            </td>
          </tr>
        )
      }) }
      </tbody>
      <tfoot>
        <tr>
          <td className={ classNames(style.cell, style.mainCell) }>Total</td>
          <td className={ classNames(style.cell, style.total) }>
            { gigabytesToReadable(totalAmount) }
          </td>
        </tr>
      </tfoot>
    </table>
  )
}

StorageTooltipContent.propTypes = {
  keys: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.object,
};

export default StorageTooltipContent;