import React, {useState, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';
import style from './style.module.css';
import {Settings} from 'icons';
import classNames from "classnames";
import {Checkbox} from "components/Checkbox";
import {useOutsideClick} from "hooks/useOutsideClick";
import {getTranscodingReportStatus, startGeneratingTranscodingReport} from "api/qencode-account-api";
import {useAuth} from "context/AuthContext";

async function downloadCSV(url) {
  try {
    const filename = url.split('/').pop();
    const response = await fetch(url);
    const data = await response.text();
    const blob = new Blob([data], { type: 'text/csv' });
    const blobUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = blobUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(blobUrl);
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
}


const backendStatuses = {
  completed: 'completed',
  inProgress: 'in_progress', // ???
  queued: 'queued',
}

const frontendStatuses = {
  ...backendStatuses,
  notRequested: 'not_requested',
  requested: 'requested',
}

function ExportData({
  datepicker,
}) {
  const { getToken } = useAuth();
  const dropdownRef = useRef();
  const [dropdown, setDropdown] = useState(false);
  const [includeSource, setIncludeSource] = useState(false);
  const [includeOutput, setIncludeOutput] = useState(true);
  const [sendEmail, setSendEmail] = useState(true);
  const [percent, setPercent] = useState(0);
  const [status, setStatus] = useState(frontendStatuses.notRequested);
  
  useOutsideClick(dropdownRef, () => {
    setDropdown(false);
  });
  
  const getTranscodingStatus = useCallback(async (token) => {
    const accessToken = await getToken();
    const {
      success,
      data,
    } = await getTranscodingReportStatus({
      accessToken,
      token,
    });
    if (success) {
      setStatus(data.status);
      if (data.status === backendStatuses.completed) {
        setPercent(100);
        downloadCSV(data.download_url);
      } else if ([backendStatuses.queued, backendStatuses.inProgress].includes(data.status)) {
        setPercent(data.percent);
        setTimeout(() => getTranscodingStatus(token), 3000);
      }
    } else {
      console.error(data);
    }
  }, [getToken]);
  
  const onSubmit = useCallback(async () => {
    const accessToken = await getToken();

    const postBody = {
      include_output_data: includeOutput ? 1 : 0,
      include_source_data: includeSource ? 1 : 0,
      send_email: sendEmail ? 1 : 0,
      usage_period_start_date: datepicker.params.get('start_date'),
      usage_period_end_date: datepicker.params.get('end_date'),
    }
    setStatus(frontendStatuses.requested);
    setPercent(0);
    const response = await startGeneratingTranscodingReport({
      accessToken,
      postBody,
    });
    if (response.success) {
      getTranscodingStatus(response.token);
    }
    
  }, [datepicker.params, getToken, getTranscodingStatus, includeOutput, includeSource, sendEmail]);

  let buttonText = 'Download CSV';
  let submitting = false;
  if (status === backendStatuses.inProgress) {
    submitting = true;
    buttonText = `Creating CSV ${percent}%`;
  } else if (status === backendStatuses.queued) {
    submitting = true;
    buttonText = `Generating report`;
  } else if (status === backendStatuses.completed) {
    submitting = false;
    buttonText = 'Download CSV';
  }
  
  return (
    <div className={style.container} ref={dropdownRef}>
      <button
        className={style.leftButton}
        onClick={onSubmit}
        disabled={submitting}
      >
        { buttonText }
      </button>
      <button
        className={style.rightButton}
        onClick={() => setDropdown(open => !open)}
        disabled={submitting}
      >
        <Settings className={style.settingsIcon}/>
      </button>
      <div
        className={  classNames(style.dropdown, {
          [style.open]: dropdown,
        }) }
      >
        <div className={style.wrapper}>
          <Checkbox
            label="Include source video data"
            checked={includeSource}
            onChange={(e) => setIncludeSource(e?.target?.checked)}
          />
        </div>
        <div className={style.wrapper}>
          <Checkbox
            checked={includeOutput}
            onChange={(e) => setIncludeOutput(e?.target?.checked)}
            label="Include output video data"
          />
        </div>
        <div className={style.wrapper}>
          <Checkbox
            label="Send me an email with link to CSV"
            checked={sendEmail}
            onChange={(e) => setSendEmail(e?.target?.checked)}
          />
        </div>
      </div>
    </div>
  );
}

ExportData.propTypes = {
  datepicker: PropTypes.object.isRequired,
};

export default ExportData;