import {useCallback, useEffect, useState} from "react";
import {useAuth} from "context/AuthContext";
import {disableCdnService, enableCdnService} from "api/qencode-account-api";


export default function useEnableCdn(bucket) {
  const { getToken } = useAuth();
  const [enableCdnCounter, setEnableCdnCounter] = useState(0);
  const [enableCdnInitial, setEnableCdnInitial] = useState();
  const [enableCdn, setEnableCdn] = useState();
  const [submitting, setSubmitting] = useState(false);
  
  useEffect(() => {
    const value = !!bucket?.cdn_service;
    setEnableCdn(value);
    setEnableCdnInitial(value);
  }, [bucket]);
  
  const handleSubmit = useCallback(async (bucket) => {
    const accessToken = await getToken();
    let submittedCdnService = false;
    if (enableCdnInitial !== enableCdn) {
      const props = {
        accessToken,
        bucketName: bucket.name,
        region: bucket.region,
      };
      setSubmitting(true);
      if (enableCdn) {
        await enableCdnService(props);
      } else {
        await disableCdnService(props);
      }
      setEnableCdnInitial(enableCdn);
      submittedCdnService = true;
      setSubmitting(false);
    }
    return {
      submitted: submittedCdnService,
      enableCdn,
    };
  }, [enableCdn, enableCdnInitial, getToken]);
  
  const clearForm = useCallback(() => {
    setEnableCdn(enableCdnInitial);
  }, [enableCdnInitial]);
  
  return {
    handleSubmit,
    submitting,
    enableCdn,
    setEnableCdn,
    clearForm,
    enableCdnInitial,
    enableCdnCounter,
    setEnableCdnCounter,
  };
}
