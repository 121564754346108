import React, {useMemo, useState} from "react";
import {
  getCoreRowModel, getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import globalFilterFn from "components/Table/columnFilters/exactCaseInsensitiveFilter";
import Table from "components/Table";
import style from './style.module.css';
import Search from "components/Search";
import ExpandableCellWithMeter from "pages/StatisticsPage/components/ExpandableCellWithMeter";
import MoneyRounded from "components/Table/Cells/MoneyRounded";
import getTableValueFallback from "helpers/getTableValueFallback";
import Controls from "components/Controls";
import VerticalGapsLayout from "components/VerticalGapsLayout";
import { ReactComponent as ProjectWithCircle } from "assets/images/ProjectWithCircle.svg";
import Pagination from "components/Table/Pagination";
import formatNumber from "helpers/formatNumber";


const columnIds = {
  projectName: 'projectName',
  jobsCompleted: 'jobsCompleted',
  inputMinutes: 'inputMinutes',
  outputMinutes: 'outputMinutes',
  transcodingCost: 'transcodingCost',
};

export default function ProjectsSection({
  loadingStatus,
}) {
  const [sorting, setSorting] = useState([{
    id: columnIds.transcodingCost,
    desc: true,
  }]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [expanded, setExpanded] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const {
    data,
  } = loadingStatus;
  const columns = useMemo(() => [
    {
      id: columnIds.projectName,
      accessorKey: 'name',
      header: () => <span>Project Name</span>,
      cell: (info) => (
        <ExpandableCellWithMeter
          info={info}
          dataProperty="duration"
        />
      ),
    },
    {
      id: columnIds.jobsCompleted,
      accessorKey: 'task_count',
      header: () => <span>Jobs Completed</span>,
      meta: {
        className: style.jobsCompletedColumn,
      },
    },
    {
      id: columnIds.inputMinutes,
      accessorKey: 'source_duration',
      header: () => <span>Input Minutes</span>,
      cell: (info) => <span>{ getTableValueFallback(info.getValue(), formatNumber)}</span>,
      meta: {
        className: style.inputColumn,
      },
    },
    {
      id: columnIds.outputMinutes,
      accessorKey: 'duration',
      header: () => <span>Output Minutes</span>,
      cell: (info) => <span>{ getTableValueFallback(info.getValue(), formatNumber)}</span>,
      meta: {
        className: style.outputColumn,
      },
    },
    {
      id: columnIds.transcodingCost,
      accessorKey: 'amount',
      header: () => <span>Transcoding Cost</span>,
      cell: (info) => <MoneyRounded value={info.getValue()} semiBold/>,
      meta: {
        className: style.costColumn,
      },
    }
  ], []);

  const table = useReactTable({
    data,
    columns,
    defaultColumn: {
      cell: (info) => (<span>{ getTableValueFallback(info.getValue()) }</span>),
    },
    getCoreRowModel: getCoreRowModel(),
    /* Sorting */
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    /* Filtering */
    globalFilterFn,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    maxLeafRowFilterDepth: 0, /* Do not search sub-rows. Otherwise row couldn't be expanded (most of the time, because sub-rows will be filtered out) */
    /* Expanded */
    getSubRows: row => row?.tariffs || [],
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: setExpanded,
    paginateExpandedRows: false,
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      globalFilter,
      expanded,
      pagination,
    },
  });

  return (
    <VerticalGapsLayout>
      <Controls
        title="Projects"
        rightContent={
          <Search
            setGlobalFilter={setGlobalFilter}
            globalFilter={globalFilter}
          />
        }
      />
      <Table
        { ...loadingStatus }
        table={table}
        minWidth={880}
        noDataImage={<ProjectWithCircle/>}
        noDataTitle="No projects found"
        noDataDescription="Start by creating a new project"
        skeletonRowCount={5}
      />
      <Pagination table={table}/>
    </VerticalGapsLayout>
  )
}

