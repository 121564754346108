import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import Table from "components/Table";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import Search from "components/Search";
import globalFilterFn from "components/Table/columnFilters/exactCaseInsensitiveFilter";
import Controls from "components/Controls";
import VerticalGapsLayout from "components/VerticalGapsLayout";
import {ReactComponent as VideoPlayer} from "assets/images/VideoPlayer.svg";
import style from "./style.module.css";
import Pagination from "components/Table/Pagination";
import formatNumber from "helpers/formatNumber";
import getTableValueFallback from "helpers/getTableValueFallback";

const columnIds = {
  name: 'name',
  impressions: 'impressions',
  plays: 'plays',
  minutesPlayed: 'minutesPlayed',
};

function PlayersTable({ loadingStatus }) {
  const [sorting, setSorting] = useState([{
    id: columnIds.minutesPlayed,
    desc: true,
  }]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = useMemo(() => [
    {
      id: columnIds.name,
      accessorKey: 'name',
      header: () => <span>Player Name</span>,
      cell: (info) => <span className="oneLineEllipsis semiBold">{ info.getValue() }</span>,
    },
    {
      id: columnIds.impressions,
      accessorKey: 'impressions',
      header: () => <span>Impressions</span>,
      cell: (info) => <span>{ getTableValueFallback(info.getValue(), formatNumber)}</span>,
      meta: {
        className: style.impressionsColumn,
      },
    },
    {
      id: columnIds.plays,
      accessorKey: 'plays',
      header: () => <span>Plays</span>,
      cell: (info) => <span>{ getTableValueFallback(info.getValue(), formatNumber)}</span>,
      meta: {
        className: style.playsColumn,
      },
    },
    {
      id: columnIds.minutesPlayed,
      accessorKey: 'minutes_played',
      header: () => <span>Minutes Played</span>,
      cell: (info) => <span className={style.minutesPlayedValue}>{ info.getValue() }</span>,
      meta: {
        className: style.minutesPlayed,
      },
    }
  ], []);
  const table = useReactTable({
    data: loadingStatus.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    /* Sorting */
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    /* Filtering */
    globalFilterFn,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      globalFilter,
      pagination,
    },
  });
  
  return (
    <VerticalGapsLayout>
      <Controls
        title="Players"
        rightContent={
          <Search
            setGlobalFilter={setGlobalFilter}
            globalFilter={globalFilter}
          />
        }
      />
      <Table
        { ...loadingStatus }
        table={table}
        noDataImage={<VideoPlayer/>}
        noDataTitle="No players found"
        noDataDescription="Start by creating a new player"
        skeletonRowCount={5}
      />
      <Pagination table={table}/>
    </VerticalGapsLayout>
  )
}

PlayersTable.propTypes = {
  loadingStatus: PropTypes.object.isRequired,
};

export default PlayersTable;