import React from 'react';
import PropTypes from 'prop-types';
import styles from './PaymentMethodItem.module.css';

import { OptionsMenu } from '../OptionsMenu'

import { ReactComponent as VisaLogo } from '../../assets/images/Visa.svg';
import { ReactComponent as MastercardLogo } from '../../assets/images/Mastercard.svg'
import { ReactComponent as PayPalLogo } from '../../assets/images/Paypal.svg'
import { ReactComponent as CardLogo } from '../../assets/images/Card.svg';

import { EyeOpen, Delete } from '../../icons'

export const PaymentMethodItem = ({ paymentMethod, onMakeDefault, onRemove }) => {

    // Initialize an empty array for menu items
    const menuItems = [];

    // Conditionally add the "Remove" menu item if the type is not "card" or it's not the default card
    if (!(paymentMethod.type === 'card' && paymentMethod.default === 1)) {
        menuItems.push({ icon: <Delete label="Remove" />, text: "Remove", onClick: () => onRemove(paymentMethod) });
    }

    // Conditionally add the "Make Default" menu item if the type is "card" and it's not already the default
    if (paymentMethod.type === 'card' && paymentMethod.default !== 1) {
        menuItems.unshift({ icon: <EyeOpen label="View" />, text: "Make Default", onClick: () => onMakeDefault(paymentMethod) });
    }


    const getBrandLogo = (brand) => {
        switch (brand) {
          case 'Visa': return <VisaLogo />;
          case 'MasterCard': return <MastercardLogo />;
          // Add more cases for other card brands if necessary
          default: return <CardLogo className={styles.cardLogo} />;
        //   default: return null;
        };
    };    

    return (
        <div className={styles.item}>
            {paymentMethod.type === 'card' && (
                <div className={styles.details}>
                    <div className={styles.icon}>{getBrandLogo(paymentMethod.brand)}</div>
                    <div className={styles.data}>
                
                            <div className={styles.title}>{`${paymentMethod.brand} ending in ${paymentMethod.last4}`} {paymentMethod.default === 1 && <div className={styles.default}><span>DEFAULT</span></div>}</div>
                            <div className={styles.info}>{`Expiry ${paymentMethod.exp_month}/${paymentMethod.exp_year}`}</div>                        
                                    
                    </div>
                </div>
            )}
            {paymentMethod.type === 'paypal' && (
                <div className={styles.details}>
                    <div className={styles.icon}><PayPalLogo /></div>
                    <div className={styles.data}>
                
                            <div className={styles.title}>PayPal {paymentMethod.default === 1 && <div className={styles.default}><span>DEFAULT</span></div>}</div>
                            <div className={styles.info}>{paymentMethod.paypal_email}</div>
                    
                    </div>                
                </div>
            )}
            {menuItems.length > 0 && <OptionsMenu menuItems={menuItems} />}
            {/* <OptionsMenu menuItems={menuItems} /> */}
      </div>
    );
};

PaymentMethodItem.propTypes = {
    paymentMethod: PropTypes.shape({
      type: PropTypes.string.isRequired,
      default: PropTypes.number,
      brand: PropTypes.string,
      country: PropTypes.string,
      create_datetime: PropTypes.string,
      last4: PropTypes.string,
      exp_month: PropTypes.number,
      exp_year: PropTypes.number,
      paypal_email: PropTypes.string,
    }).isRequired,
    onMakeDefault: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
  };

PaymentMethodItem.defaultProps = {

};