import React from 'react';
import style from './style.module.css';

function VerticalGapsLayout({ children }) {
  return (
    <div className={style.content}>
      { children }
    </div>
  );
}

export default VerticalGapsLayout;