import React, { useState, useEffect, useRef } from 'react';
import { useAWS } from 'api/useAWS';
import { Modal } from 'components/Modal';
import { Button } from 'components/Button';
import { FileDropZone } from 'components/FileDropZone';
import { FileCard } from 'components/FileCard';

import { ReactComponent as ItemsUploadCancelImage } from 'assets/images/ItemsUploadCancelImage.svg';

import styles from './index.module.css';

export const UploadModal = ({ currentBucket, configData, locationName, initialItems, open, handleCancel, onSuccessfulFinish }) => {

    const { uploadFileToQencodeS3 } = useAWS();

    const [currentFiles, setCurrentFiles] = useState([]);

    const [files, setFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const fileUploadSignals = useRef({});

    const [uploadDirectory, setUploadDirectory] = useState(null); 

    const [cancelUploadTriggered, setCancelUploadTriggered] = useState(false);

    useEffect(() => {
        // console.log("locationName: ", locationName)
        if (open) {
            // filter out folders as I only need to check vs files
            const files = initialItems.filter(item => item.type === 'file');
            setCurrentFiles(files)

            setFiles([]);
            setUploadDirectory(locationName || '')
        }
    }, [open, locationName, initialItems]);

    useEffect(() => {
        if (cancelUploadTriggered) {
            //console.log("Now close upload window")
            handleCancel()
        }
    }, [cancelUploadTriggered, handleCancel]);    

    // Prepare the config for S3 operations
    const getConfig = () => ({
        access_id: configData.access_id,
        secret_key: configData.secret_key,
        region: currentBucket.region,
    });

    // const handleFilesUpload = (selectedFiles) => {
    //     console.log("selectedFiles: ", selectedFiles)
    //     const newFiles = selectedFiles.map((file) => ({
    //         file,
    //         progress: 0,
    //         isUploading: true,
    //         isUploadComplete: false,
    //         error: null,
    //     }));

    //     setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    //     startUpload(newFiles);
    // };

    const handleFilesUpload = (selectedFiles) => {
        //console.log("selectedFiles: ", selectedFiles);
    
        // Filter out files that already exist in the current files state
        const existingFileNames = files.map(f => f.file.name);
        const newFiles = selectedFiles
            .filter(file => !existingFileNames.includes(file.name)) // Only add files that don't already exist
            .map((file) => ({
                file,
                progress: 0,
                isUploading: true,
                isUploadComplete: false,
                error: null,
            }));
    
        if (newFiles.length > 0) {
            setFiles((prevFiles) => [...prevFiles, ...newFiles]);
            startUpload(newFiles);
        }
    };    


    // refactored upload and retry with helper function

    // Helper function to handle the upload of a single file
    const uploadSingleFile = async (fileItem, signal) => {

        // Check if the file already exists in currentFiles based on displayName
        const fileExists = currentFiles.some(currentFile => currentFile.displayName === fileItem.file.name);

        if (fileExists) {
            // If the file exists, trigger an upload error and stop further processing
            handleUploadError(fileItem.file.name, 'File already exists');
            setIsUploading(files.some(file => file.isUploading));
            return;
        }

        try {
            const result = await uploadFileToQencodeS3(
                currentBucket.name,
                fileItem.file,
                getConfig(),
                (progress) => handleProgressUpdate(fileItem.file.name, progress),
                signal.signal,
                `${uploadDirectory}${fileItem.file.name}`
            );

            if (result.success) {
                handleUploadComplete(fileItem.file.name);
            } else {
                handleUploadError(fileItem.file.name, result.error);
            }
        } catch (error) {
            handleUploadError(fileItem.file.name, error.message);
        } finally {
            setIsUploading(files.some(file => file.isUploading));
        }
    };

    // Start uploading multiple files
    const startUpload = (newFiles) => {
        setIsUploading(true);

        newFiles.forEach((fileItem) => {
            const signal = new AbortController();
            fileUploadSignals.current[fileItem.file.name] = signal;
            uploadSingleFile(fileItem, signal); // Call the helper function
        });
    };

    // Retry uploading a single file
    const handleRetryUpload = async (fileItem) => {
        const signal = new AbortController();
        fileUploadSignals.current[fileItem.file.name] = signal;

        setFiles((prevFiles) => {
            const updatedFiles = prevFiles.map((file) =>
                file.file.name === fileItem.file.name
                    ? { ...file, isUploading: true, progress: 0, error: null }
                    : file
            );

            setIsUploading(true); // Set uploading to true since we're starting a new upload
            return updatedFiles;
        });

        // Call the helper function
        uploadSingleFile(fileItem, signal);
    };

    const handleProgressUpdate = (fileName, progress) => {
        setFiles((prevFiles) =>
            prevFiles.map((file) =>
                file.file.name === fileName ? { ...file, progress } : file
            )
        );
    };

    const handleUploadComplete = (fileName) => {
        setFiles((prevFiles) => {
            const updatedFiles = prevFiles.map((file) =>
                file.file.name === fileName
                    ? { ...file, isUploading: false, isUploadComplete: true }
                    : file
            );
    
            setIsUploading(updatedFiles.some(file => file.isUploading)); // Check if any file is still uploading
            return updatedFiles;
        });
    };    

    const handleUploadError = (fileName, error) => {
        setFiles((prevFiles) => {
            const updatedFiles = prevFiles.map((file) =>
                file.file.name === fileName ? { ...file, error, isUploading: false } : file
            );
    
            setIsUploading(updatedFiles.some(file => file.isUploading)); // Check if any file is still uploading
            return updatedFiles;
        });
    };    

    const handleCancelUpload = (fileName) => {
        const signal = fileUploadSignals.current[fileName];
        if (signal) signal.abort();
    
        // setFiles((prevFiles) =>
        //     prevFiles.map((file) =>
        //         file.file.name === fileName
        //             ? { ...file, progress: 0, isUploading: false }
        //             : file
        //     )
        // );

        setFiles((prevFiles) =>
            prevFiles.map((file) =>
                file.file.name === fileName
                    ? { ...file, isUploading: false } // don't reset progress
                    : file
            )
        );        
    };    

    // const handleDelete = (fileName) => {
    //     console.log(`Delete file: ${fileName}`);
    //     // Placeholder function, does nothing for now
    // };

    const handleCancelModal = () => {
        if (files.some((file) => file.isUploading)) {
            setShowCancelModal(true);
            //handleCancel(true) // indicate there are uploads in progress
        } else {
            closeUploadModal(); // close nornally
        }
    };

    const confirmCancel = () => {
        Object.values(fileUploadSignals.current).forEach((signal) => signal.abort());
        setFiles((prevFiles) =>
            prevFiles.filter((file) => file.isUploadComplete)
        );
        setShowCancelModal(false);
        //handleCancel();

        setCancelUploadTriggered(true)

        // setTimeout(() => {
        //     closeUploadModal(); 
        //   }, 500);    
    };

    const closeUploadModal = () => {
        handleCancel();
    };

    // This function gets called when the confirm modal has finished closing
    // const handleConfirmModalClose = () => {
    //     closeUploadModal(); // Close the main upload modal after the confirm modal is closed
    // };    

    return (
        <>
            <Modal isOpen={open} onClose={handleCancelModal} 
                header={<h3>Upload Files</h3>}
                footer={
                    <>
                        <Button 
                            variant="modalFooter" 
                            onClick={handleCancelModal}
                            type="secondary"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="modalFooter"
                            onClick={onSuccessfulFinish}
                            // state={isUploading ? "disabled" : undefined}
                            state={isUploading || files.length === 0 ? "disabled" : undefined} // Disable if uploading or no files
                        >
                            {/* {isUploading ? "Uploading..." : "Done"} */}
                            Done
                        </Button>                     
                    </>
                }                
            >
                <div className={styles.fileUploadContainer}>
                    <FileDropZone multiple={true} onFileSelect={handleFilesUpload} />
                    {
                        files?.length > 0 &&
                        <div className={styles.uploadFilesList}>
                            {files.map((fileItem, index) => (
                                <FileCard
                                    // key={fileItem.file.name}
                                    key={index}
                                    file={fileItem.file}
                                    progress={fileItem.progress}
                                    isUploading={fileItem.isUploading}
                                    isUploadComplete={fileItem.isUploadComplete}
                                    error={fileItem.error}
                                    onRetry={() => handleRetryUpload(fileItem)} 
                                    onCancel={() => handleCancelUpload(fileItem.file.name)}
                                    //onDelete={() => handleDelete(fileItem.file.name)}
                                />
                            ))}
                        </div>                        
                    }

                </div>
            </Modal>

            {showCancelModal && (
                <Modal isOpen={showCancelModal} 
                    onClose={() => setShowCancelModal(false)} 
    
                    variant='popUp'
                    icon={<ItemsUploadCancelImage />}
                    header={
                        <>
                            <h3>Cancel incomplete uploads?</h3>        
                            <p>Are you sure you want to cancel all uploads that are incomplete?</p>
                        </>
                    }                  
                    footer={
                        <>
                            <Button type="secondary" onClick={() => setShowCancelModal(false)}>
                                Cancel
                            </Button>
                            <Button type="danger" onClick={confirmCancel}>
                                Confirm
                            </Button>                        
                        </>                        
                    }      
                >
                </Modal>
            )}
        </>
    );
};
