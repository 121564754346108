import React from 'react';
import PropTypes from 'prop-types';
import styles from './FileCard.module.css';

import { formatSize } from 'helpers/formatting';

import { Dot, VideoFile, Delete, Retry, Issue, Error, Status, CheckLine, Stopped2 as StoppedIcon } from 'icons';

import { qencodeRegions } from 'helpers/qencodeRegions'

export const FileCard = ({ file, bucket, path, error, progress, isUploading, isUploadComplete, onDelete, onCancel, onRetry }) => {

    //console.log({error, progress, isUploading, isUploadComplete})

    //console.log("onDelete: ", onDelete)

    const getRegionLabel = (region) => {
        const foundRegion = qencodeRegions.find(qencodeRegion => qencodeRegion.value === region);
        return foundRegion ? foundRegion.label : region;
    };

    return (
        <div className={styles.container}>
            <div className={styles.iconContainer}><VideoFile className={styles.icon} /></div>

            <div className={styles.body}>
                <div className={styles.main}>
                    <div className={styles.fileDetails}>
                        <div className={styles.fileInfo}>
                            <div className={styles.fileName}>{file.name}</div>
                            <Dot className={styles.delimeter} />
                            <div className={styles.fileSize}>{formatSize(file.size, 'bytes')}</div>
                        </div>            

                        {/* {
                            isUploading && 
                            <>
                                <div className={styles.progressContainer}>
                                    <div className={styles.progressBar}>
                                        <div className={styles.progressFill} style={{width: `${Math.round(progress)}%`}}></div>
                                    </div>
                                    <div className={styles.progressNumber} >
                                        {Math.round(progress)}%    
                                    </div>
                                </div>
                                <div className={styles.statusContainer}>
                                    <Status className={styles.statusIcon} />
                                    <div className={styles.statusMessage}>Uploading</div>
                                </div>
                            </>
                        } */}

                        {
                            isUploading &&
                            <>
                                <div className={styles.progressContainer}>
                                    <div className={styles.progressBar}>
                                        <div className={styles.progressFill} style={{width: `${Math.round(progress)}%`}}></div>
                                    </div>
                                    <div className={styles.progressNumber} >
                                        {Math.round(progress)}%    
                                    </div>
                                </div>
                                <div className={styles.statusContainer}>
                                    <Status className={styles.statusIcon} />
                                    <div className={styles.statusMessage}>Uploading</div>
                                </div>  
                            </>
                        }                    

                        {
                            error === 'Stopped' &&
                            <>
                                <div className={styles.progressContainer}>
                                    <div className={styles.progressBar}>
                                        <div className={error ? `${styles.progressFill} ${styles.error}` : styles.progressFill} style={{width: `${Math.round(progress)}%`}}></div>
                                    </div>
                                    <div className={styles.progressNumber} >
                                        {Math.round(progress)}%    
                                    </div>
                                </div>
                            </>
                        }                              

                        {isUploadComplete && 
                            <div className={styles.statusContainer}>
                                <CheckLine className={styles.completeIcon} />
                                <div className={styles.statusMessage}>Completed</div>
                            </div>
                        }                   

                        {error && 
                            <div className={styles.statusContainer}>
                                {error === 'Stopped' ? <StoppedIcon className={styles.errorIcon} /> : <Issue className={styles.errorIcon} /> }
                                <div className={styles.statusMessage}>{error}</div>
                            </div>
                        }                                        

                        {/* {error && 
                            <div className={styles.statusContainer}>
                                <Issue className={styles.errorIcon} />
                                <div className={styles.statusMessage}>{error}</div>
                            </div>
                        }                                        */}
                    </div>

                    <div className={styles.actionButtonsContainer}>

                        {
                            isUploading &&
                            <div className={styles.actionButton} onClick={onCancel}>
                                <Error className={styles.actionIcon} />
                            </div>
                        }

                        {
                            error && 
                            <div className={styles.actionButton} onClick={onRetry}>
                                <Retry className={styles.actionIcon} />
                            </div>
                        }

                        {/* don't show if delete function not provided */}
                        {
                            !isUploading && onDelete &&
                            <div className={styles.actionButton} onClick={onDelete}>
                                <Delete className={styles.actionIcon} />
                            </div>                             
                        }
                   
                    </div>


                </div>

                {
                    isUploadComplete && bucket && path &&
                    <div className={styles.extra}>
                        <div className={styles.extraRow}>
                            <div className={styles.extraLabel}>BUCKET:</div>
                            {/* <div className={styles.extraValue}>{bucket?.name} <Dot className={styles.delimeter} /> <span className={styles.extraRegionName}>{bucket?.region}</span></div> */}
                            <div className={styles.extraValue}>{bucket?.name} <Dot className={styles.delimeter} /> <span className={styles.extraRegionName}>{getRegionLabel(bucket?.region)}</span></div>
                        </div>
                        <div className={styles.extraRow}>
                            <div className={styles.extraLabel}>PATH:</div>
                            <div className={styles.extraValue}>{path}</div>
                        </div>
                    </div>                    
                }

             
            </div>
        </div>
    );
};

FileCard.propTypes = {
    file: PropTypes.shape({
        name: PropTypes.string.isRequired,
        size: PropTypes.number.isRequired,
    }).isRequired,
    bucket: PropTypes.shape({
        name: PropTypes.string,
        region: PropTypes.string,
    }),
    path: PropTypes.string,
    // error: PropTypes.string,
    progress: PropTypes.number,
    isUploading: PropTypes.bool,
    isUploadComplete: PropTypes.bool,
    onDelete: PropTypes.func,
    onCancel: PropTypes.func,
    onRetry: PropTypes.func,
};

FileCard.defaultProps = {
    bucket: null,
    path: '',
    // error: '',
    progress: 0,
    isUploading: false,
    isUploadComplete: false,
    // onDelete: () => {},
    onDelete: null,
    onCancel: () => {},
    onRetry: () => {},
};


// import React from 'react';
// import PropTypes from 'prop-types';
// import styles from './FileCard.module.css';

// import { formatSize } from 'helpers/formatting';

// import { Dot, VideoFile, Delete, Retry, Issue, Error, Status, CheckLine } from 'icons';

// import { qencodeRegions } from 'helpers/qencodeRegions'

// export const FileCard = ({ file, bucket, path, error, progress, isUploading, isUploadComplete, onDelete, onCancel, onRetry }) => {


//     //console.log("onDelete: ", onDelete)

//     const getRegionLabel = (region) => {
//         const foundRegion = qencodeRegions.find(qencodeRegion => qencodeRegion.value === region);
//         return foundRegion ? foundRegion.label : region;
//     };

//     return (
//         <div className={styles.container}>
//             <div className={styles.iconContainer}><VideoFile className={styles.icon} /></div>

//             <div className={styles.body}>
//                 <div className={styles.main}>
//                     <div className={styles.fileDetails}>
//                         <div className={styles.fileInfo}>
//                             <div className={styles.fileName}>{file.name}</div>
//                             <Dot className={styles.delimeter} />
//                             <div className={styles.fileSize}>{formatSize(file.size, 'bytes')}</div>
//                         </div>            

//                         {
//                             isUploading && 
//                             <>
//                                 <div className={styles.progressContainer}>
//                                     <div className={styles.progressBar}>
//                                         <div className={styles.progressFill} style={{width: `${Math.round(progress)}%`}}></div>
//                                     </div>
//                                     <div className={styles.progressNumber} >
//                                         {Math.round(progress)}%    
//                                     </div>
//                                 </div>
//                                 <div className={styles.statusContainer}>
//                                     <Status className={styles.statusIcon} />
//                                     <div className={styles.statusMessage}>Uploading</div>
//                                 </div>
//                             </>
//                         }

//                         {isUploadComplete && 
//                             <div className={styles.statusContainer}>
//                                 <CheckLine className={styles.completeIcon} />
//                                 <div className={styles.statusMessage}>Completed</div>
//                             </div>
//                         }                              

//                         {error && 
//                             <div className={styles.statusContainer}>
//                                 <Issue className={styles.errorIcon} />
//                                 <div className={styles.statusMessage}>{error}</div>
//                             </div>
//                         }                                       
//                     </div>

//                     <div className={styles.actionButtonsContainer}>

//                         {
//                             isUploading &&
//                             <div className={styles.actionButton} onClick={onCancel}>
//                                 <Error className={styles.actionIcon} />
//                             </div>
//                         }

//                         {
//                             error && 
//                             <div className={styles.actionButton} onClick={onRetry}>
//                                 <Retry className={styles.actionIcon} />
//                             </div>
//                         }

//                         {/* don't show if delete function not provided */}
//                         {
//                             !isUploading && onDelete &&
//                             <div className={styles.actionButton} onClick={onDelete}>
//                                 <Delete className={styles.actionIcon} />
//                             </div>                             
//                         }
                   
//                     </div>


//                 </div>

//                 {
//                     isUploadComplete && bucket && path &&
//                     <div className={styles.extra}>
//                         <div className={styles.extraRow}>
//                             <div className={styles.extraLabel}>BUCKET:</div>
//                             {/* <div className={styles.extraValue}>{bucket?.name} <Dot className={styles.delimeter} /> <span className={styles.extraRegionName}>{bucket?.region}</span></div> */}
//                             <div className={styles.extraValue}>{bucket?.name} <Dot className={styles.delimeter} /> <span className={styles.extraRegionName}>{getRegionLabel(bucket?.region)}</span></div>
//                         </div>
//                         <div className={styles.extraRow}>
//                             <div className={styles.extraLabel}>PATH:</div>
//                             <div className={styles.extraValue}>{path}</div>
//                         </div>
//                     </div>                    
//                 }

             
//             </div>
//         </div>
//     );
// };

// FileCard.propTypes = {
//     file: PropTypes.shape({
//         name: PropTypes.string.isRequired,
//         size: PropTypes.number.isRequired,
//     }).isRequired,
//     bucket: PropTypes.shape({
//         name: PropTypes.string,
//         region: PropTypes.string,
//     }),
//     path: PropTypes.string,
//     // error: PropTypes.string,
//     progress: PropTypes.number,
//     isUploading: PropTypes.bool,
//     isUploadComplete: PropTypes.bool,
//     onDelete: PropTypes.func,
//     onCancel: PropTypes.func,
//     onRetry: PropTypes.func,
// };

// FileCard.defaultProps = {
//     bucket: null,
//     path: '',
//     // error: '',
//     progress: 0,
//     isUploading: false,
//     isUploadComplete: false,
//     // onDelete: () => {},
//     onDelete: null,
//     onCancel: () => {},
//     onRetry: () => {},
// };