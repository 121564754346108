import PropTypes from "prop-types";

export const columnIds = {
  playerName: 'playerName',
  licenseKey: 'licenseKey',
  domainName: 'domainName',
  impressions: 'impressions',
  plays: 'plays',
  minutesPlayed: 'minutesPlayed',
  dateCreated: 'dateCreated',
  moreActions: 'moreActions',
};

export const columnIdToReadableName = {
  [columnIds.playerName]: 'Video Player Name',
  [columnIds.licenseKey]: 'License Key',
  [columnIds.domainName]: 'Domain name',
  [columnIds.impressions]: 'Impressions',
  [columnIds.plays]: 'Plays',
  [columnIds.dateCreated]: 'Date Created',
  [columnIds.minutesPlayed]: 'Minutes Played',
  [columnIds.moreActions]: '',
};

export const modals = {
  create: 'create',
  edit: 'edit',
  clone: 'clone',
  delete: 'delete',
};

export const VideoPlayerType = {
  domain: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  // created: PropTypes,
  stats: PropTypes.shape({
    impressions: PropTypes.number.isRequired,
    'minutes_played': PropTypes.number.isRequired,
    plays: PropTypes.number.isRequired,
  }),
};
