import PropTypes from "prop-types";
import React from "react";

export const Tutorial = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M10.3223 4.75396C10.1172 4.85166 9.87948 4.84979 9.67774 4.75396C9.6164 4.72483 9.5584 4.68701 9.50565 4.64067C7.80273 3.19886 5.94261 2.59487 3.8891 2.78364L3.88756 2.78378C3.24425 2.84158 2.75103 3.38029 2.75 4.0264V13.1806V13.1809C2.75069 14.3076 3.58471 15.2602 4.70158 15.4098C6.67754 15.6735 8.44771 16.2424 10 17.1275C11.5523 16.2424 13.3225 15.6735 15.2984 15.4098C16.4153 15.2602 17.2493 14.3076 17.25 13.1809V13.1806V4.0264C17.249 3.38029 16.7558 2.84158 16.1124 2.78378L16.1109 2.78364C14.0574 2.59487 12.1973 3.19886 10.4944 4.64067C10.4416 4.68701 10.3836 4.72483 10.3223 4.75396ZM10.4087 18.6291C11.8467 17.7401 13.5377 17.158 15.4972 16.8966L15.4976 16.8965C17.359 16.6472 18.7491 15.0594 18.75 13.1813V13.1809V4.02586V4.02491C18.7482 2.60345 17.6633 1.41748 16.2474 1.28987C13.9619 1.07994 11.87 1.70017 10 3.11536C8.13005 1.70017 6.03814 1.07994 3.75256 1.28987C2.33666 1.41748 1.2518 2.60345 1.25 4.02491V4.02586V13.1809V13.1813C1.25086 15.0594 2.64099 16.6472 4.50244 16.8965L4.50282 16.8966C6.46229 17.158 8.15332 17.7401 9.5913 18.6291C9.62187 18.6489 9.65372 18.6664 9.68657 18.6815C9.88087 18.7706 10.11 18.7753 10.3134 18.6814C10.3463 18.6664 10.3781 18.6489 10.4087 18.6291ZM4.20407 14.2905C3.98762 14.2063 3.84498 13.998 3.8448 13.7658L3.83765 4.53189C3.83688 4.09006 4.19443 3.73127 4.63625 3.7305C4.65076 3.73047 4.66527 3.73084 4.67977 3.73147C5.56829 3.77547 6.36742 3.95954 7.07713 4.28367C7.97359 4.69309 8.66334 5.23502 9.14639 5.90946C9.38011 6.2376 9.3939 6.67396 9.17939 7.01498L4.76658 14.1031C4.64854 14.2927 4.41224 14.3714 4.20407 14.2905Z" fill="currentColor"/>
    </svg>    
  );
};

Tutorial.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Tutorial.defaultProps = {
  color: 'currentColor', // Or any default color
};