import React, { useState, useCallback, useEffect } from 'react';

import { useApi } from '../../../api/useApi'
import { useBackend } from 'api/useBackend';
import { useAWS } from 'api/useAWS';

import { Modal } from '../../../components/Modal';

import { Button } from '../../../components/Button'

import { NoData } from 'components/NoData';

import { formatDateTime } from 'helpers/formatting';

import { ReactComponent as Folder2 } from 'assets/images/Folder2.svg';

import styles from '../index.module.css';

// table related
import {getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable} from "@tanstack/react-table";
import globalFilterFn from "components/Table/columnFilters/exactCaseInsensitiveFilter";
import Table from "components/Table";
//import DateFormatted from "components/DateFormatted";
import Search from "components/Search";
// import {rowModals} from "pages/Transcoding/ProjectsPage/MoreRowActions";
// import {PlusIcon} from "icons";

import { Breadcrumbs } from 'components/Breadcrumbs'
import { BucketDetailsHeader } from 'components/BucketDetailsHeader'

import { Check, FolderAlt, VideoFile } from "icons";

export const QencodeStorageModal = ({ open, handleCancel, onSuccessfulFinish }) => {

    const { 
        getBucketsListData
    } = useApi();

    const {
        getS3config
    } = useBackend();

    const {
        getItemsForQencodeBucket,
        //getNumberOfItemsInBucket,
        //getTotalSizeOfItemsInBucket 
    } = useAWS();

    // buckets
    const [loadingBuckets, setLoadingBuckets] = useState(true);
    const [bucketsLoadedSuccessfully, setBucketsLoadedSuccessfully] = useState(false);
    const [bucketsError, setBucketsError] = useState(null);

    const [bucketsListData, setBucketsListData] = useState([]);

    // s3 config
    const [loadingS3config, setLoadingS3config] = useState(true);
    const [configError, setConfigError] = useState(null);

    const [configData, setConfigData] = useState({});    

    // handle in table file selection tracking current bucket
    const [currentBucket, setCurrentBucket] = useState(null);

    // items in the bucket
    const [currentItems, setCurrentItems] = useState([]);
    const [loadingCurrentItems, setLoadingCurrentItems] = useState(true);
    const [currentItemsLoadedSuccessfully, setCurrentItemsLoadedSuccessfully] = useState(false);
    const [currentItemsError, setCurrentItemsError] = useState(null);

    const [selectedFile, setSelectedFile] = useState('');

    const [breadcrumbPath, setBreadcrumbPath] = useState([]);

    const [showItemHideBuckets, setShowItemHideBuckets] = useState(false);

    const fetchBucketsData = useCallback(async () => {
        try {
            setLoadingBuckets(true);
            const { success, data, detail } = await getBucketsListData();
            if (success && data) {
                console.log("buckets data: ", data);

                setBucketsListData(data)
                setBucketsError(null);
                setBucketsLoadedSuccessfully(true)
            } else {
                setBucketsError(detail || "Failed to get buckets data");
                console.error(detail || "Failed to get buckets list data");
            }
        } catch (error) {
            setBucketsError(error.message);
            console.error(error); // Here, replace with more advanced error handling if necessary
        } finally {
            setLoadingBuckets(false); // Ensures loading is set to false regardless of the outcome
        }
    }, [getBucketsListData]);

    const fetchConfigData = useCallback(async () => {
        try {
            setLoadingS3config(true);
            const { success, data, detail } = await getS3config();
            if (success && data) {
                console.log("config data: ", data);

                setConfigData(data)
                setConfigError(null);
            } else {
                setConfigError(detail || "Failed to get S3 config data");
                console.error(detail || "Failed to get S3 config data");
            }
        } catch (error) {
            setConfigError(error.message);
            console.error(error); // Here, replace with more advanced error handling if necessary
        } finally {
            setLoadingS3config(false); // Ensures loading is set to false regardless of the outcome
        }
    }, [getS3config]);    

    useEffect(() => {
        fetchBucketsData();
        fetchConfigData();
    }, [fetchBucketsData, fetchConfigData]);    
  
    // buckets table
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const columns = React.useMemo(() => [
    {
      id: '1',
      accessorKey: 'name',
      header: () => <span>Bucket Name</span>,
      cell: (info) => {
        return (
          <div className='cellContainer'> <div className='cellLimited cellHighlighted'>{ info.getValue() }</div></div>
        );
      },
    },
    {
      id: '2',
      accessorKey: 'region',
      header: () => <span>Region</span>,
      cell: (info) => (
        <div>{ info.getValue() }</div>
        // <div onClick={() => console.log(info.row.original)}>{ info.getValue() }</div>
      )
    },
    {
      id: '3',
      accessorKey: 'created',
      header: () => <span>Date Created</span>,
      cell: (info) => {
        return (
            <div className={styles.tableDateCell}>{ formatDateTime(info.getValue()) }</div> 
        );
      },
    }
  ],[]);
  const table = useReactTable({
    data: bucketsListData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    /* Sorting */
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    /* Filtering */
    globalFilterFn,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    state: {
      sorting,
      globalFilter,
    }
  })

    // bucket items table
    const [globalFilterItems, setGlobalFilterItems] = useState('');
    const [sortingItems, setSortingItems] = useState([]);
    const columnsItems = React.useMemo(() => [
        {
          id: '1',
          accessorKey: 'displayName',
          header: () => <span>Name</span>,
          cell: (info) => {
            return (
               <div className='cellContainer'> 
                    { info.row.original?.type && info.row.original?.type === "folder" && <FolderAlt className={styles.bucketItemIcon} />}
                    { info.row.original?.type && info.row.original?.type === "file" && <VideoFile className={styles.bucketItemIcon} />}
                    <div className='cellLimited cellHighlighted cellFullWidth'>
                        { info.getValue() }
                    </div>
                    { info.row.original?.selected && <Check className={styles.selectedIcon} />}
                </div>
            );
          },
        },
        // {
        //   id: '2',
        //   accessorKey: 'selected',
        //   enableSorting: false,
        //   header: () => <span></span>,
        //   cell: (info) => (
        //     <div className={styles.selectedIconContainer}>
        //         { info.getValue() && <Check className={styles.selectedIcon} />}
        //     </div>
        //   )
        // },
    ],[]);
    const tableItems = useReactTable({
        data: currentItems,
        columns: columnsItems,
        getCoreRowModel: getCoreRowModel(),
        /* Sorting */
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSortingItems,
        /* Filtering */
        globalFilterFn,
        getFilteredRowModel: getFilteredRowModel(),
        onGlobalFilterChange: setGlobalFilterItems,
        state: {
            sorting: sortingItems,
            globalFilter: globalFilterItems
        }
    })

    // show buckets table
    const showBucketsList = () => {
        setShowItemHideBuckets(false)
        
        // clear selected file
        setSelectedFile('')

    }

    const onBucketRowClick = async  (bucket) => {
        console.log("Clicked bucket row: ", bucket)

        // show items list
        setShowItemHideBuckets(true)

        setCurrentBucket(bucket)

        // clear selected file
        setSelectedFile('')

        // new desing removed bucket name from path
        //setBreadcrumbPath([bucket.name]);
        setBreadcrumbPath([]);

        const config = {
            access_id: configData.access_id,
            secret_key: configData.secret_key,
            region: bucket.region,
        };


        fetchItems(bucket.name, config);      
    }

    const fetchItems = async (bucketName, config, prefix = '') => {

        // clear items list
        setCurrentItems([])

        setLoadingCurrentItems(true)
        setCurrentItemsLoadedSuccessfully(false)

        const { success, items, error } = await getItemsForQencodeBucket(bucketName, config, prefix);
        if (success) {
            console.log("items: ", items)
            setCurrentItems(items);
            setCurrentItemsLoadedSuccessfully(true);
            setCurrentItemsError(null);
            setLoadingCurrentItems(false)
        } else {
            setCurrentItems([]);
            setCurrentItemsError(error);
            setLoadingCurrentItems(false)
        }   

        // get number of items in bucket
        // const { itemCount } = await getNumberOfItemsInBucket(bucketName, config);
        // if(itemCount){
        //     console.log("itemCount: ", itemCount)
        // }

        // get total size of all items in bucket
        // const { totalSize } = await getTotalSizeOfItemsInBucket(bucketName, config);
        // if(totalSize){
        //     console.log("totalSize: ", totalSize)
        // }        
    };    

    const handleConfirmClick = () => {
        // this is what I get
        // https://hello-00012.us-west.s3-qa.qencode.com/video_1_eye.webm
        
        // and this is what I need
        // https://hello-00012.media-storage.us-west.qencode.com/video_1_eye.webm
        onSuccessfulFinish(selectedFile?.sourceUrl);
    };

    const onItemRowClick = async (item) => {
        if (item.type === 'folder') {
            const config = {
                access_id: configData.access_id,
                secret_key: configData.secret_key,
                region: currentBucket.region,
            };

            console.log("Get more for ", item)

            fetchItems(currentBucket.name, config, item.name);
            setBreadcrumbPath(prev => [...prev, item.displayName]);

            // clear selected file
            setSelectedFile('')

            // reset search 
            setGlobalFilterItems('')
        } else if (item.type === 'file') {
            const updatedItems = currentItems.map(it => 
                it.name === item.name ? { ...it, selected: true } : { ...it, selected: false }
            );
            setCurrentItems(updatedItems);
            setSelectedFile(item);
            console.log("Selected file: ", item);
        }
    };

    const onBreadcrumbClick = async (index) => {
        //console.log("index: ", index)
        // reset search 
        setGlobalFilterItems('')

        const config = {
            access_id: configData.access_id,
            secret_key: configData.secret_key,
            region: currentBucket.region,
        };
    
        // // Slice the path excluding the bucket name
        // let path = breadcrumbPath.slice(1, index + 1).join('/');

        // there is no bucket name at the start any more
        // Slice the path up to the current index
        let path = breadcrumbPath.slice(0, index + 1).join('/');        

        //console.log("path: ", path)

        // Ensure the path ends with a /
        if (path && !path.endsWith('/')) {
            path += '/';
        }

        //console.log("path: ", path)

    
        fetchItems(currentBucket.name, config, path);
        setBreadcrumbPath(breadcrumbPath.slice(0, index + 1));

        // clear selected file
        setSelectedFile('')
    };

    return (
        <Modal
            className={styles.fileSelectionModal}
            isOpen={open}
            onClose={handleCancel}
            // header={<h3>Choose Video File for Transcoding</h3>}
            footer={
                <>
                    <Button 
                        size="sm"
                        onClick={handleCancel} 
                        type='secondary'
                    >
                        Cancel
                    </Button> 
                    <Button 
                        size="sm"
                        onClick={handleConfirmClick} 
                        state={!selectedFile ? "disabled" : undefined} 
                    >
                        Choose File
                    </Button>  
                </>
            }
        >
            <div>
                <div>
                    <div className={styles.modalHeader}>
                        <h3 className={styles.modalTitle}>Choose Video File for Transcoding</h3>                    
                    </div>
                    <div>

                        {/* <div>Selected file: {selectedFile?.sourceUrl}</div> */}

                        {
                            showItemHideBuckets
                            ?
                            <div className={styles.itemsContainer}>
                                { currentBucket && <BucketDetailsHeader bucket={currentBucket} onBackClick={showBucketsList}/>}

                                <div className={styles.itemsBody}>
                                    <div className={styles.modalControls}>
                                        {/* { breadcrumbPath?.length > 0 && <Breadcrumbs path={breadcrumbPath} onNavigate={onBreadcrumbClick} />} */}
                                        <Breadcrumbs path={breadcrumbPath} onNavigate={onBreadcrumbClick} />
                                        
                                        <div className={styles.modalFiltersContainer}>
                                            <Search setGlobalFilter={setGlobalFilterItems} globalFilter={globalFilterItems}/>    
                                        </div>                                    
                                    </div>

                                    {/* <div className={styles.modalTableContainerBucketItems}> */}
                                    <div className={`${styles.modalTableContainerBucketItems} ${currentBucket ? styles.showBucketHeader : ''}`}>
                                        <Table
                                            data={currentItems}
                                            table={tableItems}
                                            globalFilter={globalFilterItems}
                                            resetFilter={() => setGlobalFilterItems('')}
                                            loading={loadingCurrentItems}
                                            loadedSuccessfully={currentItemsLoadedSuccessfully}
                                            errorMessage={currentItemsError}
                                            getEmptyComponent={() => <NoData
                                                image={<Folder2 />}
                                                title="This folder is empty"
                                                description="There's nothing in your folder yet."
                                            />}
                                            onRowClick={onItemRowClick}
                                        />                            
                                    </div>
                                </div>

                                {/* {loadingCurrentItems && <div>Loading items...</div>} */}

                                {/* {currentItemsLoadedSuccessfully && <div>Items loaded</div>} */}

                                {/* <div>                                                            
                                    {currentItems.length > 0 && (
                                        <table variant="simple">
                                            <thead>
                                                <tr>
                                                    <th>Type</th>
                                                    <th>Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems.map((item, index) => (
                                                    <tr 
                                                        key={index} 
                                                        onClick={() => onItemRowClick(item)}
                                                        style={{ cursor: 'pointer', backgroundColor: item.selected ? 'lightgray' : 'white' }}
                                                    >
                                                        <td>{item.type}</td>
                                                        <td>{item.displayName}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}                            
                                </div>                             */}
                            </div>     
                            :
                            <div className={styles.bucketsContainer}>
                                <div className={styles.modalFiltersContainer}>
                                    <Search setGlobalFilter={setGlobalFilter} globalFilter={globalFilter}/>    
                                </div>

                                <div className={styles.modalTableContainerBucketsList}>
                                    <Table
                                        data={bucketsListData}
                                        table={table}
                                        globalFilter={globalFilter}
                                        resetFilter={() => setGlobalFilter('')}
                                        loading={loadingBuckets}
                                        loadedSuccessfully={bucketsLoadedSuccessfully}
                                        errorMessage={bucketsError}
                                        getEmptyComponent={() => <NoData
                                            title="No buckets"
                                            description="No buckets found"
                                        />}
                                        // onRowClick={onBucketRowClick}
                                        // allow click on row only if config loaded without errors
                                        onRowClick={!loadingS3config && !configError && onBucketRowClick}
                                    />                            
                                </div>
                            </div>                                                   
                        }
                        


                    </div>
                </div>


                {/* <div>
                    File selection table
                </div> */}

            </div>
        </Modal>
    );
}

// in case there is onClick on the row
// cell: (info) => (
//     <div onClick={(e) => {
//         e.stopPropagation(); // Stop click event from bubbling up to the row
//         console.log(info.row.original);
//     }}>
//         {info.getValue()}
//     </div>
// )