import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.css';

function Meter(props) {
  const newProps = {
    ...props,
    variant: props.variant || 'vertical',
  }

  // if ( Object(props.percent) === props.percent ) {
  //   newProps.percentNumber = (parseFloat(props.percent.fraction) * 100) / parseFloat(props.percent.total);
  // } else if (typeof props.percent === 'string') {
  //   newProps.percentNumber = parseFloat(props.percent);
  // } else if (typeof props.percent === 'number' && Number.isFinite(props.percent)) {
  //   newProps.percentNumber = props.percent;
  // }
  //
  // if (typeof newProps.percentNumber === 'number' && Number.isFinite(newProps.percentNumber)) {
  //   if (newProps.percentNumber < .01) {
  //     newProps.percentFormatted = '< 0.01';
  //   }
  //   else {
  //     newProps.percentFormatted = parseFloat(Number(newProps.percentNumber).toFixed(2));
  //   }
  // }
  
  return (
    newProps.variant === 'vertical' ? (
      <Vertical { ...newProps }/>
    ) : (
      <Horizontal { ...newProps }/>
    )
  );
}

function Vertical({
  title,
  rawValue,
  progressbarFillPercent,
  percentFormatted,
}) {
  return (
    <div className={style.verticalContent}>
      <div className={style.data}>
        <span className={style.title}>
          {title}
        </span>
        <span className={style.percent}>
          ({ percentFormatted }%)
        </span>
        <span className={style.rawValue}>
          {rawValue}
        </span>
      </div>
      <Fill percent={progressbarFillPercent}/>
    </div>
  )
}

function Horizontal({
    progressbarFillPercent,
    percentFormatted,
  }) {
  return (
    <div className={style.horizontalWrapper}>
      <div className={style.horizontalContent}>
        <Fill percent={progressbarFillPercent}/>
          <span className={style.percent}>
            { percentFormatted }%
          </span>
      </div>
    </div>
  )
}

function Fill( {percent} ) {
  return (
    <div className={style.fillAll}>
      <div
        className={style.fill}
        style={{width: `${percent}%`}}
      />
    </div>
  )
}

Meter.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.oneOf([
    'vertical',
    'horizontal',
  ]),
};

export default Meter;