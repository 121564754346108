import PropTypes from "prop-types";
import React from "react";

export const Issue = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M8 2.60002C5.01762 2.60002 2.59992 5.01767 2.59992 8C2.59992 10.9823 5.01762 13.4 8 13.4C10.9824 13.4 13.4001 10.9823 13.4001 8C13.4001 5.01767 10.9824 2.60002 8 2.60002ZM1.3999 8C1.3999 4.35493 4.35487 1.40002 8 1.40002C11.6451 1.40002 14.6001 4.35493 14.6001 8C14.6001 11.6451 11.6451 14.6 8 14.6C4.35487 14.6 1.3999 11.6451 1.3999 8ZM8.00086 4.00001C8.57729 4.00001 9.04458 4.46729 9.04458 5.04371C9.04458 5.09212 9.04121 5.14048 9.0345 5.18842L8.52499 8.82773C8.51116 8.92651 8.42666 9 8.32692 9H7.67481C7.57506 9 7.49057 8.92651 7.47674 8.82773L6.96723 5.18842C6.8873 4.61757 7.28529 4.09001 7.85615 4.01009C7.9041 4.00338 7.95245 4.00001 8.00086 4.00001ZM8 9.99999C8.55229 9.99999 9.00001 10.4477 9.00001 11C9.00001 11.5523 8.55229 12 8 12C7.44771 12 6.99999 11.5523 6.99999 11C6.99999 10.4477 7.44771 9.99999 8 9.99999Z" fill="currentColor"/>
    </svg>    
  );
};

Issue.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Issue.defaultProps = {
  color: 'currentColor', // Or any default color
};