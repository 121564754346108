import React, { useState, useEffect, useRef, useCallback } from 'react';
import { CardButton } from '../../../components/CardButton';

import { 
    Sample as SampleVideoIcon, 
    // UploadFile as UploadFileIcon,
    // VideoURL as VideoURLIcon,
    ChevronLeft,
    QencodeStorage as QencodeStorageIcon,
    Warning as WarningIcon,
    Info as InfoIcon,
    Link as LinkIcon,
    Upload as UploadIcon
} from '../../../icons'

import { ReactComponent as QencodeLogo } from 'assets/images/QencodeLogo.svg'

//import { ReactComponent as MediaStorageConfig } from 'assets/images/MediaStorageConfig.svg'

import { InputField } from '../../../components/InputField'
import { Button } from '../../../components/Button'
import { Select } from 'components/Select'
import { Tabs } from 'components/Tabs'  
import { MultiValidation } from 'components/MultiValidation';

import { validateFields } from '../../../helpers/validation';

import { qencodeRegions } from 'helpers/qencodeRegions';
import { sampleVideos } from 'helpers/sampleVideos';

import { QencodeStorageModal } from './QencodeStorageModal'
import { QencodeStorageForm } from './QencodeStorageForm'

import { EnableMediaStorage } from '../EnableMediaStorage';

import { FileUpload } from './FileUpload';

import { useAWS } from 'api/useAWS';
import { useApi } from 'api/useApi'
// import { useBackend } from 'api/useBackend';

//import { v4 as uuidv4 } from 'uuid';

import styles from '../index.module.css';

export const SourceVideoSelector = ({ onVideoSrcUpdate, videoSrc, templateChanged, setTemplateChanged, setDirectUploadFile, configData, onEnableSuccess, onEnableFail}) => {

    // dealing with buckets and filename 
    const { 
        getBucketsListData,
        createBucket 
    } = useApi();

    // const {
    //     getS3config
    // } = useBackend();

    // s3 config - get it from parent
    // const [loadingS3config, setLoadingS3config] = useState(true);
    // const [configError, setConfigError] = useState(null);
    // const [configData, setConfigData] = useState({});   

    // Local states to manage incoming props
    const [localConfigData, setLocalConfigData] = useState(configData);
    
    // Sync local state with incoming props
    useEffect(() => {
        setLocalConfigData(configData);
    }, [configData]);   

    // buckets
    const [buckets, setBuckets] = useState([]);
    const [currentBucket, setCurrentBucket] = useState(null);
    const [fileName, setFileName] = useState('');
    const [creatingBucket, setCreatingBucket] = useState(false);

    // forms
    const [activeBlock, setActiveBlock] = useState('');
    const [activeEditForm, setActiveEditForm] = useState('');

    // track visible block
    const initialBlocksVisibility = {
        video_url: true,
        upload_file: true,
        sample_video: true        
    }
    const [blocksVisibility, setBlocksVisibility] = useState(initialBlocksVisibility);

    // Function to set all blocks to visible
    const showAllBlocks = () => {
        setBlocksVisibility({
            video_url: true,
            upload_file: true,
            sample_video: true
        });
    };

    // Function to set only one block to visible and the rest to false
    const showOneBlock = (visibleBlock) => {
        setBlocksVisibility({
            video_url: visibleBlock === 'video_url',
            upload_file: visibleBlock === 'upload_file',
            sample_video: visibleBlock === 'sample_video'
        });
    };  


    // handle video url card
    const [videoUrlCardValue, setVideoUrlCardValue] = useState('');

    // handle upload file card
    const [uploadFileCardValue, setUploadFileCardValue] = useState('');
    const [uploadFileCardExtraData, setUploadFileCardExtraData] = useState('');

    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadedFileUrl, setUploadedFileUrl] = useState(null);
    const [uploadedFilePath, setUploadedFilePath] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isUploadComplete, setIsUploadComplete] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const abortController = useRef(null);


    // this also must be in state 
    // const s3config = {
    //     "access_id": "",
    //     "secret_key": ""
    // }

    // const fetchConfigData = useCallback(async () => {
    //     try {
    //         setLoadingS3config(true);
    //         const { success, data, detail } = await getS3config();
    //         if (success && data) {
    //             console.log("config data: ", data);

    //             setConfigData(data)
    //             setConfigError(null);
    //         } else {
    //             setConfigError(detail || "Failed to get S3 config data");
    //             console.error(detail || "Failed to get S3 config data");
    //         }
    //     } catch (error) {
    //         setConfigError(error.message);
    //         console.error(error); // Here, replace with more advanced error handling if necessary
    //     } finally {
    //         setLoadingS3config(false); // Ensures loading is set to false regardless of the outcome
    //     }
    // }, [getS3config]);        

    // useEffect(() => {
    //     fetchConfigData();
    // }, [fetchConfigData]);       

    const {
        uploadFileToQencodeS3
    } = useAWS();

    const [sampleVideoCardValue, setSampleVideoCardValue] = useState('');

    const [sampleVideosOptions, setSampleVideosOptions] = useState([]);
    const [selectedSampleVideo, setSelectedSampleVideo] = useState(null); // State to manage the selected sample video

    useEffect(() => {
        const options = sampleVideos.map((video, index) => ({
          value: `${video.name}-${index}`, // Create a unique value
          label: video.name,
          labelExtra: <span className={styles.quality}>{video.quality}</span>,
          extraData: video.info,
          data: video,
          //checked: index === 0 // Set the first item as checked by default // set checked on save
        }));
    
        setSampleVideosOptions(options);
        setSelectedSampleVideo(options[0].data); // Set the first item as the selected sample video by default
    }, []);

    const handleSampleVideoChange = (e) => {
        const video = sampleVideosOptions.find(option => option.value === e.target.value)?.data;
        setSelectedSampleVideo(video);
        const updatedOptions = sampleVideosOptions.map(option => ({
            ...option,
            //checked: video.name === option.data.name // checked only set when save
        }));
        setSampleVideosOptions(updatedOptions);
    };      


    // handle Video Url field
    const [videoUrlCredentials, setVideoUrlCredentials] = useState({
        video_url: "",
    });    

    const [validationErrors, setValidationErrors] = useState({});
    
    const [changedVideoUrlFields, setChangedVideoUrlFields] = useState({});   


    ///////////////////////////////////////////////
    // handle create new bucket form

    // to manage multiline validation
    const bucketNameValidationRules = [
        {
            message: 'The bucket name must be from 6 to 63 characters max.',
            test: (value) => value.length >= 6 && value.length <= 63
        },        
        {
            message: 'Must start and end with a lowercase letter or number',
            // test: (value) => /^[a-z0-9]([a-z0-9-]*[a-z0-9])?$/.test(value)
            test: (value) => /^[a-z0-9].*[a-z0-9]$/.test(value)
        },
        {
            message: 'Only Latin lower-case characters, number, and dashes',
            test: (value) => /^[a-z0-9-]+$/.test(value)
        },
        {
            message: 'No space',
            test: (value) => !/\s/.test(value)
        }
    ];


    const [bucketNameValidations, setbucketNameValidations] = useState(bucketNameValidationRules.map(rule => ({ message: rule.message, isValid: null })));

    const validateBucketName = (value) => {
        if (value === '') {
            // Reset all validations to neutral state
            setbucketNameValidations(bucketNameValidationRules.map(rule => ({ message: rule.message, isValid: null })));
            return;
        }

        const newValidations = bucketNameValidationRules.map(rule => ({
            message: rule.message,
            isValid: rule.test(value)
        }));

        setbucketNameValidations(newValidations);
    };    

    const [newBucketError, setNewBucketError] = useState(false);

    const [newBucketCredentials, setNewBucketCredentials] = useState({
        qencode_bucket_name: "",
        qencode_bucket_region: qencodeRegions[0]?.value || ''  // set 1st region on load by default
    });

    const handleInputChangeNewBucket = (e) => {
        if (newBucketError) {
            setNewBucketError(null);
        }
    
        const { name, value } = e.target;
        // Check if the value is an empty string and set it to null if true
        const newValue = value === '' ? '' : value;
    
        const newCredentials = { ...newBucketCredentials, [name]: newValue };
        setNewBucketCredentials(newCredentials);
    
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: null
        }));

        // run validation for MultiValidation block
        // when typing bucket name
        if(name === 'qencode_bucket_name'){
            validateBucketName(value);
        }
    };     

    // make sure Create bucket is disabled if there is any error on input
    const isBucketNameValid = bucketNameValidations.every(validation => validation.isValid);
    const isBucketNameEmpty = newBucketCredentials?.qencode_bucket_name?.length === 0;
    ///////////////////////////////////////////////

    
    
    // loading buckets list and setting 1st bucket as default
    const fetchBucketsData = useCallback(async () => {
        try {
            const { success, data, detail } = await getBucketsListData();
            if (success && data) {
                console.log("buckets data: ", data);
                setBuckets(data);

                // Check if the provided bucket is in the list
                // const selectedBucket = data.find(b => b.name === bucket?.name && b.region === bucket?.region);

                // if (selectedBucket) {
                //     setCurrentBucket(selectedBucket);
                // } else if (data.length > 0) {
                //     // Set the first bucket as the selected one if there are buckets available and the provided bucket is not found
                //     setCurrentBucket(data[0]);
                // }                

                // Set the first bucket as the selected one if there are buckets available
                if (data.length > 0) {
                    setCurrentBucket(data[0]);
                }
            } else {
                console.error(detail || "Failed to get buckets list data");
            }
        } catch (error) {
            console.error(error.message); // Handle the error as needed
        }
    }, [getBucketsListData]);

    useEffect(() => {
        fetchBucketsData();
    }, [fetchBucketsData]);    

    const handleBucketChange = (bucket) => {
        setCurrentBucket(bucket)
        // reset file upload component to initial state when displaying selected file
        setUploadError(null)
        setIsUploading(false);
        setUploadProgress(0);
        setIsUploadComplete(false)  
    }


    // useEffect(() => {
    //     console.log({videoSrc, templateChanged})
    //     if (videoSrc && templateChanged) {
    //         setActiveBlock('video_url'); // Set 'video_url' as active if videoSrc exists and template was changed
    //         setVideoUrlCardValue(videoSrc)
    //         setActiveEditForm('');
    //         setVideoUrlCredentials({
    //             video_url: videoSrc
    //         })
    //         setChangedVideoUrlFields({});
    //         setValidationErrors({});

    //         // need to reset values of all cards
    //         console.log("Reset all cards")

    //         console.log("Clear upload file card values")
    //         // clear upload card value
    //         setUploadFileCardValue('')
    //         setUploadFileCardExtraData('')
    //         setFile(null)
    //         setDirectUploadFile(null)
    //         setUploadError(null)
    //         setIsUploading(false);
    //         setUploadProgress(0);
    //         setIsUploadComplete(false) 

    //         console.log("Clear sample videos card values")
    //         // clear sample videos card value
    //         setSampleVideoCardValue('')
    //     }
    // }, [videoSrc, templateChanged, setDirectUploadFile]);

    useEffect(() => {
        console.log({videoSrc, templateChanged})
        if (templateChanged) {

            if(videoSrc){
                setActiveBlock('video_url'); // Set 'video_url' as active if videoSrc exists and template was changed

                showOneBlock('video_url')

                setVideoUrlCardValue(videoSrc)
                setActiveEditForm('');
                setVideoUrlCredentials({
                    video_url: videoSrc
                })
                setChangedVideoUrlFields({});
                setValidationErrors({});                
            } else {
                // clear video url card
                setVideoUrlCardValue('')
                setVideoUrlCredentials({
                    video_url: ''
                })
                setChangedVideoUrlFields({});                
            }


            // need to reset values of all cards
            console.log("Reset all cards")

            console.log("Clear upload file card values")
            // clear upload card value
            setUploadFileCardValue('')
            setUploadFileCardExtraData('')
            setFile(null)
            setDirectUploadFile(null)
            setUploadError(null)
            setIsUploading(false);
            setUploadProgress(0);
            setIsUploadComplete(false) 

            console.log("Clear sample videos card values")
            // clear sample videos card value
            setSampleVideoCardValue('')
        }
    }, [videoSrc, templateChanged, setDirectUploadFile]);    

    // handlers for cars
    const handleCardClick = (name) => {
        setActiveEditForm(name);
    };

    const handleEditClick = (name) => {
        setActiveEditForm(name);
    };

    const handleClearClick = (name) => {
        // show all blocks
        showAllBlocks()

        if(name === "video_url"){
            setVideoUrlCardValue('')
            setVideoUrlCredentials({
                video_url: ''
            })
            setChangedVideoUrlFields({});
        }  

        if(name === "upload_file"){
            setUploadFileCardValue('')
            setUploadFileCardExtraData('')

            setFile(null)
            setDirectUploadFile(null)
            setUploadError(null)
        }  

        if(name === "sample_video"){
            setSampleVideoCardValue('')

            // Set the first item as the selected sample video by default
            setSelectedSampleVideo(sampleVideosOptions[0].data); 

            // make sure no option is selected
            const updatedOptions = sampleVideosOptions.map(option => ({
                ...option,
                checked: false
            }));
            setSampleVideosOptions(updatedOptions);            
            
        }  

        setValidationErrors({}); 
        onVideoSrcUpdate('')
        setTemplateChanged(false)

        setActiveBlock('');
        setActiveEditForm('');

    };

    // handlers for forms
    const handleUpdateCardValue = (name, value, extraData) => {
        // clear values for cards
        setVideoUrlCardValue('')

        // clear sample video card value
        setSampleVideoCardValue('')

        // close opened form and show card
        setActiveEditForm('');
        setActiveBlock(name);

        showOneBlock(name)

        // this doesn't work now
        if(name === "video_url"){

            // Validate input fields that have changed
            const fieldsToValidate = Object.keys(changedVideoUrlFields); // Only validate changed fields
            const { isValid, errors } = validateFields(changedVideoUrlFields, fieldsToValidate);
            
            // Update validation errors
            setValidationErrors(errors);
            if (!isValid) {
                console.log("Validation errors", errors);
                return; // Exit if validation fails
            }

            setVideoUrlCardValue(value)
            onVideoSrcUpdate(value)
            setTemplateChanged(false)           
            
            console.log("Clear upload file card values")
            // clear upload card value
            setUploadFileCardValue('')
            setUploadFileCardExtraData('')
            setFile(null)
            setDirectUploadFile(null)
            setUploadError(null)
            setIsUploading(false);
            setUploadProgress(0);
            setIsUploadComplete(false)      
                
        }  

        if(name === "upload_file"){

            // set file for direct upload
            if(uploadOnLaunch){
                setDirectUploadFile(file)
            }

            console.log("Save ", value)

            const fileSize = `${(file.size / 1024 / 1024).toFixed(2)} MB`

            setUploadFileCardValue(value)
            // setUploadFileCardExtraData(extraData)
            setUploadFileCardExtraData(fileSize)
            onVideoSrcUpdate(value)
            setTemplateChanged(false)

            // reset state
            // setIsUploading(false);
            // setUploadProgress(0);
            // setIsUploadComplete(false)

            setVideoUrlCredentials({
                video_url: ''
            })
        }  

        if(name === "sample_video"){
            setSampleVideoCardValue(value)

            // update selected option
            const video = sampleVideosOptions.find(option => option.label === value)?.data;
            if(video){
                //setSelectedSampleVideo(video);
                const updatedOptions = sampleVideosOptions.map(option => ({
                    ...option,
                    checked: video.name === option.data.name
                }));
                setSampleVideosOptions(updatedOptions);                
            }            

            //onVideoSrcUpdate(value)
            onVideoSrcUpdate(selectedSampleVideo.url)
            setTemplateChanged(false)

            setVideoUrlCredentials({
                video_url: ''
            })

            // clear upload card value
            setUploadFileCardValue('')
            setUploadFileCardExtraData('')
            setFile(null)
            setDirectUploadFile(null)
            setUploadError(null)
            setIsUploading(false);
            setUploadProgress(0);
            setIsUploadComplete(false)                
        }  
    };

    const handleFormBackClick = (name) => {
        if(name === "video_url"){
            setVideoUrlCredentials({
                video_url: videoUrlCardValue // reset input value to card value
            })
            setChangedVideoUrlFields({});
        }  

        if(name === "upload_file"){
            // setFile(null)

            // setIsUploading(false);
            // setUploadProgress(0);
            // setIsUploadComplete(false)

            // can't actually reset, since can't restore file
            
            // if there is no uploadFileCardValue, it means Save was not clicked
            // so in this case need to reset states
            console.log("uploadFileCardValue: ", uploadFileCardValue)
            if(!uploadFileCardValue){
                setFile(null)
                setDirectUploadFile(null)
                setUploadError(null)

                setIsUploading(false);
                setUploadProgress(0);
                setIsUploadComplete(false)      
            }
        }  

        if(name === "sample_video"){
            // console.log("selectedSampleVideo: ", selectedSampleVideo)
            // console.log("sampleVideoCardValue: ", sampleVideoCardValue)
            
            // set selected option to sampleVideoCardValue
            //console.log("sampleVideosOptions: ", sampleVideosOptions)

            const video = sampleVideosOptions.find(option => option.label === sampleVideoCardValue)?.data;
            //console.log("video: ", video)

            if(video){
                setSelectedSampleVideo(video);
                const updatedOptions = sampleVideosOptions.map(option => ({
                    ...option,
                    checked: video.name === option.data.name
                }));
                setSampleVideosOptions(updatedOptions);                
            } else {
                setSelectedSampleVideo(sampleVideosOptions[0].data); // Set the first item as the selected sample video by default
            }


        }  

        setActiveEditForm('')
        setValidationErrors({});
    };

    /////////////////////////////////////////////////
    // 1st card form
    const handleVideoUrlInput = (e) => {

        const { name, value } = e.target;

        const newCredentials = { ...videoUrlCredentials, [name]: value };
        setVideoUrlCredentials(newCredentials);

        // Check if the field's new value is different from videoUrlCardValue's value
        if (videoUrlCardValue !== value) {
            setChangedVideoUrlFields({ ...changedVideoUrlFields, [name]: value });
        } else {
            // If the field's value is reverted to the original, remove it from changedFields
            const updatedChangedFields = { ...changedVideoUrlFields };
            delete updatedChangedFields[name];
            setChangedVideoUrlFields(updatedChangedFields);
        }


        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: null
        }));
    };  

    // need to remake to use handleUpdateCardValue?
    const handleUpdateVideoUrlValue = (e) => {
        e.preventDefault();

        // Validate input fields that have changed
        const fieldsToValidate = Object.keys(changedVideoUrlFields); // Only validate changed fields
        const { isValid, errors } = validateFields(changedVideoUrlFields, fieldsToValidate);
        
        // Update validation errors
        setValidationErrors(errors);
        if (!isValid) {
            console.log("Validation errors", errors);
            return; // Exit if validation fails
        }

        // clear values for all cards
        setVideoUrlCardValue('')

        // clear upload card value
        setUploadFileCardValue('')
        setUploadFileCardExtraData('')
        setFile(null)
        setDirectUploadFile(null)
        setUploadError(null)
        setIsUploading(false);
        setUploadProgress(0);
        setIsUploadComplete(false)   

        // clear sample videos card value
        setSampleVideoCardValue('')

        setActiveEditForm('');
        setActiveBlock('video_url');

        showOneBlock('video_url')

        setVideoUrlCardValue(changedVideoUrlFields.video_url)
        onVideoSrcUpdate(changedVideoUrlFields.video_url)
        setTemplateChanged(false)  

        setChangedVideoUrlFields({});
    };    

    // 1st card - select file from Qencode storage using modal
    const [isQencodeStorageModalOpen, setQencodeStorageModalOpen] = useState(false);

    const handleOpenQencodeStorageModal= () => {
        setQencodeStorageModalOpen(true);
    };

    const handleCloseQencodeStorageModal = () => {
        setQencodeStorageModalOpen(false);
    };

    const handleSuccessfulFileSelectFromQencodeStorage = (url) => {
        console.log('File selected in modal: ', url);
        setQencodeStorageModalOpen(false); // Close modal on success

        // imitate e.target linee in input field
        const videoUrlObject = {
            target: {
                name: 'video_url',
                value: url
            }
        }

        handleVideoUrlInput(videoUrlObject)
    };
    /////////////////////////////////////////////////


    /////////////////////////////////////////////////
    // 2nd card

    // const handleFileSelect = (selectedFile) => {
    //     setFile(selectedFile);
    //     setUploadProgress(0);
    //     setUploadError(null);
    //     setIsUploading(false);
    // };

    const handleFileSelect = (selectedFile) => {
        setFile(selectedFile);
        
        setFileName(selectedFile?.name) // used in QencodeStorageForm
        // clear valudation error for "key_name" input field
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            key_name: null
        }));

        setUploadProgress(0);
        setIsUploading(false);
        setIsUploadComplete(false);
        setUploadedFileUrl(null);
        setUploadedFilePath(null)
    };

    const handleUpload = async () => {

        if (file) {

            // reset before start upload
            setUploadError(null)
            setUploadProgress(0);
            setIsUploadComplete(false) 


            // const config = {
            //     access_id: configData.access_id,
            //     secret_key: configData.secret_key,
            //     region: currentBucket.region
            // };

            const config = {
                access_id: localConfigData.access_id,
                secret_key: localConfigData.secret_key,
                region: currentBucket.region
            };

            const bucketName = currentBucket.name;

            abortController.current = new AbortController();
            const signal = abortController.current.signal;

            //const fileName = "test_files/planet_001.mp4"

            setIsUploading(true);
            const { success, error, sourceUrl, path } = await uploadFileToQencodeS3(bucketName, file, config, setUploadProgress, signal, fileName);
            setIsUploading(false);
            setIsUploadComplete(success);

            if (success) {
                setUploadedFileUrl(sourceUrl);
                setUploadedFilePath(path);
            } else {
                console.error('Upload failed:', error);
                setUploadError(error || "Upload failed")
            }
        }
    };

    const handleFileNameUpdate = (name) => {

        setFileName(name)

        // clear valudation error for "key_name" input field
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            key_name: null
        }));
    }

    const validateAndUpload = () => {
        console.log("Validate: ", fileName)


        const fieldsToValidate = ['key_name']; 
        const { isValid, errors } = validateFields({key_name: fileName}, fieldsToValidate);
        setValidationErrors(errors); // Update the validation errors state
      
        if (!isValid) {
          console.log("Validation errors", errors);
          return; // Stop the submission if validation fails
        }   

        handleUpload()
    }
  
    const handleCancelUpload = () => {
        if (abortController.current) {
            abortController.current.abort();
        }
        setIsUploading(false);
        //setUploadProgress(0);
    };

    const handleDelete = () => {
        setFile(null);
        setDirectUploadFile(null)
        setUploadError(null)

        //setUploadProgress(0);
        //setIsUploading(false);
        //setIsUploadComplete(false);
        //setUploadedFileUrl(null);
        //setUploadedFilePath(null)
        //setUploadError(null)
    };




    // tabs
    const [uploadOnLaunch, setUploadOnLaunch] = useState(false)

    const [activeSourceTab, setActiveSourceTab] = useState('qencode_storage');

    const handleSourceTabClick = (tabName) => {
        setActiveSourceTab(tabName);
        if(tabName==="direct_upload"){
            setUploadOnLaunch(true)
        } else {
            setUploadOnLaunch(false)
        }
    };

    const handleCancelCreateNewBucket = () => {
        console.log("Cancel create new bucket")
        setActiveEditForm('upload_file')
    }

    const handleShowCreateNewBucketForm = () => {
        console.log("Show Create New Bucket Form")

        // clear errors and reset values

        setNewBucketError(null)

        setNewBucketCredentials ({
            qencode_bucket_name: "",
            qencode_bucket_region: qencodeRegions[0]?.value || ''  // set 1st region on load by default
        });        

        // Reset all validations to neutral state
        setbucketNameValidations(bucketNameValidationRules.map(rule => ({ message: rule.message, isValid: null })));

        // clear valudation error for "qencode_bucket_name" input field
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            qencode_bucket_name: null
        }));
        
        setActiveEditForm('create_bucket')
    }

    const handleCreateNewBucket = async (e) => {
        e.preventDefault()

        // Clear previous errors
        if (newBucketError) {
            setNewBucketError(null);
        }

        // validate bucket name field 
        const fieldsToValidate = ['qencode_bucket_name']; 
        const { isValid, errors } = validateFields(newBucketCredentials, fieldsToValidate);
        setValidationErrors(errors); // Update the validation errors state
      
        if (!isValid) {
          console.log("Validation errors", errors);
          return; // Stop the submission if validation fails
        }   

        console.log("Handle create new bucket")

        // const bucketName = 'bucket03-1234567'
        // const region = 'eu-central'

        // createBucket

        // const settings = {
        //     name: bucketName,
        //     region: region
        // }

        const settings = {
            name: newBucketCredentials.qencode_bucket_name.trim(), // trim whitespaces
            region: newBucketCredentials.qencode_bucket_region
        }

        setCreatingBucket(true); // Set creatingBucket to true

        // try {
        //     const { success, data, detail } = await createBucket(settings);
        //     if (success && data) {
        //         console.log("bucket data: ", data);

        //         // fetch buckets list and pass create bucket data to set it as currentBucket
        //         // that is slow request and afer bucket is created it's not available from the list yet
        //         //fetchBucketsData(data);

        //         // instead add created bucket on top of the list
        //         setBuckets(prevBuckets => [data, ...prevBuckets]);

        //         // and set this bucket as current bucket
        //         setCurrentBucket(data)
                

        //         // close create bucket form and show upload file form 
        //         setActiveEditForm('upload_file')
        //     } else {
        //         console.error(detail || "Failed to create new bucket");
        //         setNewBucketError(detail || "Failed to create new bucket")
        //     }
        // } catch (error) {
        //     console.error(error.message); // Handle the error as needed
        //     setNewBucketError(error.message)
        // }        

        try {
            const { success, data, detail } = await createBucket(settings);
            if (success && data) {
                console.log("bucket data: ", data);
    
                // Fetch buckets list and pass create bucket data to set it as currentBucket
                // that is slow request and after bucket is created it's not available from the list yet
                //fetchBucketsData(data);
    
                // Instead, add created bucket on top of the list
                setBuckets(prevBuckets => [data, ...prevBuckets]);
    
                // And set this bucket as current bucket
                setCurrentBucket(data);
    
                // Close create bucket form and show upload file form
                setActiveEditForm('upload_file');
            } else {
                console.error(detail || "Failed to create new bucket");
                setNewBucketError(detail || "Failed to create new bucket");
            }
        } catch (error) {
            console.error(error.message); // Handle the error as needed
            setNewBucketError(error.message);
        } finally {
            setCreatingBucket(false); // Set creatingBucket to false
        }        
      
    }

    const sourceTabs = [
        { 
            name: 'qencode_storage', 
            label: 'Qencode Storage', 
            // component: <QencodeStorageForm onBucketChange={(bucket) => console.log(bucket)} /> 
            component: 
                <>
                    {
                        // !loadingS3config && !configError 
                        localConfigData?.access_id && localConfigData?.secret_key
                        ?
                        <QencodeStorageForm
                            key="qencode_storage_form"  // Ensure unique key
                            buckets={buckets}
                            currentBucket={currentBucket}
                            onBucketChange={handleBucketChange}
                            fileName={fileName}
                            //onFileNameChange={setFileName}
                            onFileNameChange={handleFileNameUpdate}
                            handleCreateNewBucket={handleShowCreateNewBucketForm}
                            validationErrors={validationErrors}
                            setValidationErrors={setValidationErrors}
                            //handleUpload={handleUpload}
                        />                           
                        :
                        <EnableMediaStorage onEnableSuccess={onEnableSuccess} onEnableFail={onEnableFail}/>
                        // <div className={styles.mediaStorageNotReady}>
                        //     <MediaStorageConfig />
                        //     <div className={styles.mediaStorageNotReadyMessage}>Please wait a few minutes while your Media Storage environment is being configured.</div>
                        // </div>
                    }
                </>
        },
        { 
            name: 'direct_upload', 
            label: 'Direct Upload', 
            component: 
                <div key="direct_upload">
                    <div className={styles.directUploadAlert}>
                        <InfoIcon className={styles.directUploadAlertIcon}/>
                        <div className={styles.directUploadAlertText}>Your file will be uploaded when transcoding is launched.</div>
                    </div>
                </div> 
        },
    ];    


    return (
        <div>

            {
                !activeBlock && !activeEditForm 
                ? 
                <div className={styles.workflowInit}>
                    <div className={styles.workflowSelector} onClick={() => handleCardClick('video_url')}>
                        <LinkIcon className={styles.workflowSelectorIcon} />
                        <div className={styles.workflowSelectorBody}>
                            <div className={styles.workflowSelectorTitle}>File URL</div>
                            <div className={styles.workflowSelectorDescr}>Use URL of a media file</div>
                        </div>                    
                    </div>
                    <div className={styles.workflowSelector} onClick={() => handleCardClick('upload_file')}>
                        <UploadIcon className={styles.workflowSelectorIcon} />
                        <div className={styles.workflowSelectorBody}>
                            <div className={styles.workflowSelectorTitle}>Upload File</div>
                            <div className={styles.workflowSelectorDescr}>Direct upload media file</div>
                        </div>                    
                    </div>
                    <div className={styles.workflowSelector} onClick={() => handleCardClick('sample_video')}>
                        <SampleVideoIcon className={styles.workflowSelectorIcon} />
                        <div className={styles.workflowSelectorBody}>
                            <div className={styles.workflowSelectorTitle}>Sample Videos</div>
                            <div className={styles.workflowSelectorDescr}>Use a sample video file</div>
                        </div>                    
                    </div>                                
                </div>           
                :
                <div className={styles.workflowContainer}>

                    {
                        blocksVisibility.video_url &&
                        <div>
                            {
                                !activeEditForm &&
                                <CardButton 
                                    icon={<LinkIcon />}
                                    title='Video URL'
                                    name='video_url'
                                    description="Use a URL for video file"
                                    value={videoUrlCardValue}
                                    active={videoUrlCardValue && activeBlock === 'video_url'} // Set active based on activeBlock state
                                    onClick={() => handleCardClick('video_url')}
                                    onEdit={() => handleEditClick('video_url')}
                                    onClear={() => handleClearClick('video_url')}
                                />                         
                            }
                
                            {activeEditForm === 'video_url' && (
                                <div>
                                    <div className={styles.form}>
                                        <div className={styles.formTitle}>Video File URL</div>

                                        <div className={styles.formBody}>
                                            <div className={styles.formRow}>
                                                <InputField
                                                    inputText={videoUrlCredentials.video_url}
                                                    onChange={handleVideoUrlInput}
                                                    name="video_url"
                                                    placeholder="http://example.com/video.mov"
                                                    label="Enter URL for source file"
                                                    state={validationErrors.video_url ? 'error' : 'default'}
                                                    hint={validationErrors.video_url ? validationErrors.video_url : undefined}
                                                />  
                                            </div>                                    
                                        </div>

                                        <div className={styles.formFooter}>
                                            <div className={styles.formMainExtraButtons}>
                                                {
                                                    // !loadingS3config && !configError &&
                                                    // localConfigData &&
                                                    // only show this button if there is s3 config
                                                    localConfigData?.access_id && localConfigData?.secret_key &&
                                                    <Button
                                                        icon={<QencodeStorageIcon label="Qencode Storage" />}
                                                        onClick={handleOpenQencodeStorageModal}
                                                        variant="link"
                                                    >
                                                        Choose from Qencode storage
                                                    </Button>                                             
                                                }
                                            </div>
                                            <div className={styles.formMainButtons}>
                                                <Button 
                                                    size="sm"
                                                    onClick={() => handleFormBackClick('video_url')}
                                                    type="secondary"
                                                >
                                                    Cancel
                                                </Button>                                           
                                                <Button 
                                                    size="sm"
                                                    onClick={handleUpdateVideoUrlValue} 
                                                    state={Object.keys(changedVideoUrlFields).length === 0 ? "disabled" : undefined} 
                                                >
                                                    Save
                                                </Button>   
                                            </div>
                                        </div>
                                    
                                    </div>

                                    { isQencodeStorageModalOpen && (
                                        <QencodeStorageModal
                                            open={isQencodeStorageModalOpen}
                                            handleCancel={handleCloseQencodeStorageModal}
                                            onSuccessfulFinish={handleSuccessfulFileSelectFromQencodeStorage}
                                        />
                                    )}

                                </div>
                            )}      
                        </div>                    
                    }

                    {
                        blocksVisibility.upload_file &&
                        <div>
                            {
                                !activeEditForm &&
                                <CardButton 
                                    icon={<UploadIcon />}
                                    title='Upload File'
                                    name='upload_file'
                                    description="Direct upload video file"
                                    value={uploadFileCardValue}
                                    extraData={uploadFileCardExtraData}
                                    active={uploadFileCardValue && activeBlock === 'upload_file'} // Set active based on activeBlock state
                                    onClick={() => handleCardClick('upload_file')}
                                    onEdit={() => handleEditClick('upload_file')}
                                    onClear={() => handleClearClick('upload_file')}
                                />                          
                            }
        
                            {activeEditForm === 'upload_file' && (
                                <div>
                                    <div className={styles.form}>
                                        <div className={styles.formTitle}>Upload File</div>

                                        <div className={styles.fileUplodContainer}>
                                            <FileUpload 
                                                selectedFile={file}
                                                bucket={currentBucket}
                                                path={uploadedFilePath}
                                                onFileSelect={handleFileSelect} 
                                                isUploading={isUploading} 
                                                uploadProgress={uploadProgress} 
                                                isUploadComplete={isUploadComplete} 
                                                error={uploadError}
                                                handleDelete={handleDelete}
                                                handleCancelUpload={handleCancelUpload}
                                                handleRetry={handleUpload}
                                            />      

                                            {
                                                isUploading &&
                                                <div className={styles.warningContainer}>
                                                    <WarningIcon className={styles.warningIcon}/>
                                                    <div className={styles.warningMessage}>Please don't leave the page while your file is uploading.</div>
                                                </div>
                                            }                                                      
                                            
                                            {
                                                file && !isUploading && !isUploadComplete &&
                                                <Tabs 
                                                    tabs={sourceTabs} 
                                                    activeTab={activeSourceTab} 
                                                    onTabClick={handleSourceTabClick} 
                                                />                                          
                                            }

                                        </div>

                                        <div className={styles.formFooter}>
                                            <div className={styles.formMainExtraButtons}></div>
                                            <div className={styles.formMainButtons}>

                                                <Button 
                                                    size="sm"
                                                    onClick={() => handleFormBackClick('upload_file')}
                                                    type="secondary"
                                                >
                                                    Cancel
                                                </Button>              

                                                {!file &&
                                                    <Button 
                                                        size="sm"
                                                        state='disabled'
                                                    >
                                                        Upload
                                                    </Button>                                          
                                                }                                                                  

                                                {/* only upload if uploading to Qencode Storage */}
                                                {/* show only if there is any bucket selected */}
                                                {file && !isUploading && !isUploadComplete && !uploadOnLaunch && currentBucket && (
                                                    <Button 
                                                        size="sm"
                                                        //onClick={handleUpload} 
                                                        onClick={validateAndUpload}
                                                    >
                                                        Upload
                                                    </Button>  
                                                )}
                        
                                                            

                                                {/* save when uploaded to Qencode Storage and have uploaded file url */}
                                                {file && isUploadComplete && uploadedFileUrl && !uploadOnLaunch && (
                                                    <Button 
                                                        size="sm"
                                                        onClick={() => handleUpdateCardValue('upload_file', uploadedFileUrl)}
                                                    >
                                                        Save
                                                    </Button>  
                                                )}

                                                {/* if file will be uploaded at launch */}
                                                {file && uploadOnLaunch && (
                                                    <Button 
                                                        size="sm"
                                                        onClick={() => handleUpdateCardValue('upload_file', file.name)}
                                                    >
                                                        Save
                                                    </Button>  
                                                )}

                                            </div>
                                        </div>


                                    </div>

                                </div>
                            )}  

                            {activeEditForm === 'create_bucket' && (
                                <div>
                                    <div className={styles.form}>

                                        <div className={styles.qencodeStorageHeader}>
                                            <QencodeLogo className={styles.qencodeStorageHeaderLogo}/>
                                            <div className={styles.qencodeStorageHeaderText}>Storage</div>
                                        </div>

                                        <div className={styles.formTitle}>Create Bucket</div>

                                        <div className={styles.formBody}>
                                            <div className={styles.formRow}>
                                                <Select
                                                    onChange={handleInputChangeNewBucket}
                                                    name="qencode_bucket_region"
                                                    label="Region"
                                                    help={{
                                                        no_header: true,
                                                        title: 'Region',
                                                        content: 'Qencode media storage region.'                                            
                                                    }}                                            
                                                    options={qencodeRegions}
                                                    value={newBucketCredentials.qencode_bucket_region}
                                                />   
                                            </div>
                                            <div className={styles.formRow}>
                                                <InputField
                                                    inputText={newBucketCredentials.qencode_bucket_name}
                                                    onChange={handleInputChangeNewBucket}
                                                    name="qencode_bucket_name"
                                                    placeholder=""
                                                    label="Bucket Name"
                                                    help={{
                                                        no_header: true,
                                                        title: 'Bucket Name',
                                                        content: 'Your bucket in Qencode media storage'                                            
                                                    }}  
                                                    //state={newBucketError || validationErrors.qencode_bucket_name ? 'error' : 'default'}
                                                    state={
                                                        newBucketError || 
                                                        bucketNameValidations.some(validation => validation.isValid === false) || 
                                                        (validationErrors.qencode_bucket_name && newBucketCredentials.qencode_bucket_name.length > 0) ? 
                                                        'error' : 
                                                        'default'
                                                    }
                                                    hint={(newBucketError ? newBucketError : undefined) || (validationErrors.qencode_bucket_name ? validationErrors.qencode_bucket_name : undefined)}
                                                />   
                                                
                                                {/* <MultiValidation validations={bucketNameValidations} /> */}
                                            </div> 
                                            <MultiValidation validations={bucketNameValidations} />
                                        </div>

                                        <div className={styles.formFooter}>
                                            <div className={styles.formMainExtraButtons}>
                                                <Button 
                                                    className={styles.backBtn}
                                                    size="sm"
                                                    type="secondary"
                                                    onClick={handleCancelCreateNewBucket}
                                                    icon={<ChevronLeft className={styles.backBtnIcon} />}
                                                >
                                                    Back
                                                </Button>                                             
                                            </div>
                                            <div className={styles.formMainButtons}>

                                                <Button 
                                                    size="sm"
                                                    type="secondary"
                                                    // onClick={handleCancelCreateNewBucket}
                                                    onClick={() => handleFormBackClick('upload_file')}
                                                >
                                                    Cancel
                                                </Button> 

                                                <Button 
                                                    size="sm"
                                                    onClick={handleCreateNewBucket}
                                                    //state={!isBucketNameValid || isBucketNameEmpty ? "disabled" : undefined}
                                                    state={!isBucketNameValid || isBucketNameEmpty || creatingBucket ? "disabled" : undefined}
                                                >
                                                    Create
                                                </Button> 

                                            </div>
                                        </div>


                                    </div>

                                </div>
                            )}                 
                        </div>                    
                    }

                    {
                        blocksVisibility.sample_video && 
                        <div>
                            {
                                !activeEditForm &&
                                <CardButton 
                                    icon={<SampleVideoIcon />}
                                    title='Sample Videos'
                                    name='sample_video'
                                    description="Use a sample video file"
                                    value={sampleVideoCardValue}
                                    active={sampleVideoCardValue && activeBlock === 'sample_video'} // Set active based on activeBlock state
                                    onClick={() => handleCardClick('sample_video')}
                                    onEdit={() => handleEditClick('sample_video')}
                                    onClear={() => handleClearClick('sample_video')}
                                />                            
                            }
        
                            {activeEditForm === 'sample_video' && (
                                <div>
                                    <div className={styles.form}>
                                        <div className={styles.formTitle}>Sample Videos</div>

                                        <div className={styles.formBody}>

                                            <div className={styles.formRow}>
                                                <Select
                                                    onChange={handleSampleVideoChange}
                                                    name="sample_videos"
                                                    options={sampleVideosOptions}
                                                    value={`${selectedSampleVideo.name}-${sampleVideosOptions.findIndex(option => option.data === selectedSampleVideo)}`}
                                                    size="xl"
                                                />   
                                            </div>                                    

                                        </div>

                                        <div className={styles.formFooter}>
                                            <div className={styles.formMainExtraButtons}></div>
                                            <div className={styles.formMainButtons}>
                                                <Button 
                                                    size="sm"
                                                    onClick={() => handleFormBackClick('sample_video')}
                                                    type="secondary"
                                                >
                                                    Cancel
                                                </Button>                                           
                                                <Button 
                                                    size="sm"
                                                    onClick={() => handleUpdateCardValue('sample_video', selectedSampleVideo.name)}
                                                >
                                                    Save
                                                </Button>   
                                            </div>
                                        </div>
                                    </div>                            


                                </div>
                            )}           
                        </div>                    
                    }
                                                        
                </div>                      
            }


        </div>
    );
};


// file upload
// По протоколу tus. Под каждый фреймворк есть библиотека tus.js (или что-то похожее)
// URL для загрузки возвращает метод create_task (https://docs.qencode.com/api-reference/transcoding/#creating-a-task)
// Пример реализации есть в файле qencode/web/js/angular/controllers/user/request_builder_controller_v6.js (edited) 

// нажимаем Launch Transcoding
// выполняем запрос на создание задачи, create_task (https://docs.qencode.com/api-reference/transcoding/#creating-a-task)
// получаем url  для загрузки файла
// загружаем файл по tus
// в поле  source указываем следующее "source": "tus:<file_uid>"  (https://docs.qencode.com/api-reference/transcoding/#direct-video-upload)
// запускаем задачу start_encode (https://docs.qencode.com/api-reference/transcoding/#starting-a-task)

// там два способа загрузить файл, сорри забыл сразу, первый direct тот что я описал выше и второй это  по протоколу s3 в бакет

// т.е. если выбрать direct upload - файл грузится после запуска таска?
