import React from 'react';
import PropTypes from 'prop-types';
import {ReactComponent as Key} from "assets/images/Key1.svg";
import CreateKeyButton from "pages/MediaStorage/AccessKeysPage/CreateKeyButton";

function NoData({ onSuccess }) {
  return (
    <div className="tableNodata">
      <div className="tableNodataImage">
        <Key/>
      </div>
      <h3 className="tableNodataTitle">
        No Access Keys created yet
      </h3>
      <p className="tableNodataDescription">
        Start by generating a new key
      </p>
      <CreateKeyButton onSuccess={onSuccess}/>
    </div>
  );
}

NoData.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default NoData;