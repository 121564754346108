import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './WarningMessage.module.css';

import { Warning as WarningIcon, ChevronBottom, ChevronTop } from 'icons';

export const WarningMessage = ({ message, details, tag }) => {

    console.log("tag: ", tag)

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(prevState => !prevState);
    };

    return (
        <>
            {
                details
                ?
                <div {...(tag ? { id: `warning-tag-${tag}` } : {})} className={`${styles.container} ${message ? '' : styles.noMessage} ${isExpanded ? styles.expanded : ''} `}>
                    <div className={styles.body}>
                        <div className={styles.messageWrapper}>
                            <WarningIcon className={styles.icon}/>
                            <div className={styles.message}>{message}</div>                            
                        </div>
                        <div className={styles.details}>{details}</div>
                    </div>
                    <div className={styles.toggleButton} onClick={toggleExpand}>
                        <span className={styles.toggleText}>{isExpanded ? <>Less</> : <>More</>}</span>
                        {isExpanded ? <ChevronTop className={styles.toggleIcon}/>:<ChevronBottom className={styles.toggleIcon}/>}
                    </div>
                </div>      
                :
                <div {...(tag ? { id: `warning-tag-${tag}` } : {})} className={styles.container}>
                    <div className={styles.messageWrapper}>
                        <WarningIcon className={styles.icon}/>
                        <div className={styles.message}>{message}</div>                            
                    </div>
                    <div className={styles.details}>{details}</div>
                </div>
            }
        
        </>
    )
};

WarningMessage.propTypes = {
    message: PropTypes.string,
    details: PropTypes.string,
    tag: PropTypes.string,
};