import PropTypes from "prop-types";
import React from "react";

export const VideoFile = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M4.8999 2.7C3.90579 2.7 3.0999 3.50589 3.0999 4.5V12.5C3.0999 13.4941 3.90579 14.3 4.8999 14.3H12.0999C13.094 14.3 13.8999 13.4941 13.8999 12.5V7.02097C13.8999 6.57629 13.7353 6.14735 13.4378 5.81683L11.169 3.29586C10.8276 2.91658 10.3413 2.7 9.83103 2.7H4.8999ZM1.8999 4.5C1.8999 2.84315 3.24305 1.5 4.8999 1.5H9.83103C10.6815 1.5 11.492 1.86096 12.0609 2.49311L14.3298 5.01407C14.8256 5.56495 15.0999 6.27984 15.0999 7.02097V12.5C15.0999 14.1569 13.7568 15.5 12.0999 15.5H4.8999C3.24305 15.5 1.8999 14.1569 1.8999 12.5V4.5ZM10.5103 3.47921C10.2112 3.15025 9.69985 3.38323 9.69985 3.84846V5.64522C9.69985 6.30796 10.2372 6.84522 10.8999 6.84522H11.7746H12.4242C12.8471 6.84522 13.0589 6.28274 12.7599 5.95378L10.5103 3.47921ZM7.29995 8.81844V11.5142C7.29995 11.6901 7.44249 11.8326 7.61832 11.8326C7.67602 11.8326 7.73263 11.8169 7.78211 11.7872L10.0286 10.4393C10.1794 10.3489 10.2283 10.1533 10.1378 10.0025C10.1109 9.95773 10.0734 9.92022 10.0286 9.89334L7.78211 8.54544C7.63134 8.45498 7.43578 8.50387 7.34532 8.65464C7.31563 8.70412 7.29995 8.76074 7.29995 8.81844Z" fill="currentColor"/>
    </svg>    
  );
};

VideoFile.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

VideoFile.defaultProps = {
  color: 'currentColor', // Or any default color
};