import PropTypes from "prop-types";
import React from "react";

export const Teams = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M7.03925 3.2065C6.03634 3.2065 5.22332 4.01952 5.22332 5.02243C5.22332 6.02534 6.03634 6.83836 7.03925 6.83836C8.04216 6.83836 8.85517 6.02534 8.85517 5.02243C8.85517 4.01952 8.04216 3.2065 7.03925 3.2065ZM4.01682 5.02243C4.01682 3.35319 5.37001 2 7.03925 2C8.70849 2 10.0617 3.35319 10.0617 5.02243C10.0617 6.69167 8.70849 8.04486 7.03925 8.04486C5.37001 8.04486 4.01682 6.69167 4.01682 5.02243ZM10.1088 2.55275C10.2338 2.24393 10.5855 2.09492 10.8944 2.21992C12.0009 2.66784 12.7832 3.75305 12.7832 5.02243C12.7832 6.29181 12.0009 7.37702 10.8944 7.82493C10.5855 7.94994 10.2338 7.80093 10.1088 7.49211C9.98383 7.18328 10.1328 6.83159 10.4417 6.70658C11.1083 6.43675 11.5768 5.78357 11.5768 5.02243C11.5768 4.26128 11.1083 3.60811 10.4417 3.33827C10.1328 3.21327 9.98383 2.86157 10.1088 2.55275Z" fill="currentColor"/>
      <path d="M5.22332 5.02243C5.22332 4.01952 6.03634 3.2065 7.03925 3.2065C8.04216 3.2065 8.85517 4.01952 8.85517 5.02243C8.85517 6.02534 8.04216 6.83836 7.03925 6.83836C6.03634 6.83836 5.22332 6.02534 5.22332 5.02243Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.03911 10.464C4.86714 10.464 3.1064 12.2248 3.1064 14.3968C3.1064 14.7299 2.83632 15 2.50315 15C2.16999 15 1.8999 14.7299 1.8999 14.3968C1.8999 11.5584 4.2008 9.25754 7.03911 9.25754C9.87742 9.25754 12.1783 11.5584 12.1783 14.3968C12.1783 14.7299 11.9082 15 11.5751 15C11.2419 15 10.9718 14.7299 10.9718 14.3968C10.9718 12.2248 9.21109 10.464 7.03911 10.464Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.24 10.6656C12.24 10.8848 12.3521 11.0864 12.523 11.2236C13.4207 11.9444 13.9955 13.051 13.9955 14.2919C13.9955 14.6251 14.2656 14.8952 14.5987 14.8952C14.9319 14.8952 15.202 14.6251 15.202 14.2919C15.202 12.6002 14.3845 11.0993 13.1231 10.1629C12.7379 9.87692 12.24 10.1859 12.24 10.6656Z" fill="currentColor"/>
    </svg>    
  );
};

Teams.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Teams.defaultProps = {
  color: 'currentColor', // Or any default color
};