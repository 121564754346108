import React, {useMemo, useState} from 'react';
import {ReactComponent as ProjectIcon} from "assets/images/Project.svg";
import {getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable} from "@tanstack/react-table";
import Table from "components/Table";
import Card from "pages/StatisticsPage/components/Card";
import UnifiedTwoCells from "components/Table/Cells/UnifiedTwoCells";
import formatNumber from "helpers/formatNumber";
import getTableValueFallback from "helpers/getTableValueFallback";
import PropTypes from "prop-types";

const columnIds = {
  project: 'project',
  minutes: 'minutes',
};

function TopProjects({
  stats,
}) {
  const [sorting, setSorting] = useState([{
    id: columnIds.minutes,
    desc: true,
  }]);
  const columns = useMemo(() => [
    {
      id: columnIds.project,
      header: () => <span>Project</span>,
      accessorKey: 'name',
      enableSorting: false,
      meta: {
        colspan: 2,
      },
      cell: (info) => {
        return (
          <UnifiedTwoCells>
            <span className="oneLineEllipsis semiBold">{info.getValue()}</span>
            <span className="semiBold">{ formatNumber(info.row.original.duration).toUpperCase() }</span>
          </UnifiedTwoCells>
        )
      },
    },
    {
      id: columnIds.minutes,
      header: () => <span>Minutes</span>,
      accessorKey: 'duration',
      cell: (info) => <span>{ getTableValueFallback(info.getValue(), formatNumber)}</span>,
      // enableSorting: false,
      meta: {
        align: 'right',
        colspan: 0,
      },
    },
  ], []);

  const table = useReactTable({
    data: stats.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    /* Sorting */
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      pagination: {
        pageIndex: 0,
        pageSize: 5,
      },
    },
  });
  
  return (
    <Card title="Top Projects">
      <Table
        { ...stats }
        table={table}
        lastLineInvisible
        noDataImage={<ProjectIcon/>}
        noDataDescription="No data found"
        skeletonRowCount={5}
      />
    </Card>
  );
}

TopProjects.propTypes = {
  stats: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        duration: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        percent: PropTypes.number.isRequired,
      })
    ),
  }),
};

export default TopProjects;