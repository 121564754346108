// dynamically calculate selected height and adjust dropdown position based on that

import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Select.module.css';

import { ChevronDown, Dot } from "../../icons";
import { Label } from '../Label';
import { Dropdown } from '../Dropdown';

export const Select = ({
    state = "default",
    name,
    className,
    icon,
    overrideIcon,
    wrappedIcon,
    variant,
    dropUp = false,
    options,
    hideExtraInSelected,
    label = false,
    menuItems,
    link,
    hint,
    placeholder = 'Select item',
    help,
    onChange,
    onFocus,
    onBlur,
    value,
    size,
    disabled,
    extraDataDelimeter,
    dropdownMinWidth, // Pass any CSS value as a string
    dropdownAlignment,
}) => {
    const variantClassName = styles[variant];

    const [isOpen, setIsOpen] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [dropdownStyle, setDropdownStyle] = useState({ top: '36px' });
    const dropdownRef = useRef(null);
    const selectContainerRef = useRef(null);

    const getIconComponent = (icon) => {
        if (wrappedIcon) {
            return (
                <div className={styles.wrappedIconContainer}>
                    {React.cloneElement(icon, { className: styles.icon })}
                </div>
            );
        } else return React.cloneElement(icon, { className: styles.icon });
    };

    // Find the selected option
    const [selectedOption, setSelectedOption] = useState(undefined);

    useEffect(() => {
        if (value && options) {
            const foundSelectedOption = options.find(option => option.value === value);
            setSelectedOption(foundSelectedOption);
        }
    }, [value, options]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (selectContainerRef.current && !selectContainerRef.current.contains(event.target)) {
                setIsOpen(false);
                setIsFocused(false);

                if (onBlur) {
                    onBlur();
                } // Notify parent component
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [selectContainerRef, onBlur]);

    useLayoutEffect(() => {
        if (isOpen) {
            const dropdownHeight = dropdownRef.current ? dropdownRef.current.scrollHeight : 0;
            const selectContainerHeight = selectContainerRef.current ? selectContainerRef.current.clientHeight : 36;
            if (dropUp) {
                setDropdownStyle({ top: `-${dropdownHeight + 4 + 2}px` });
            } else {
                setDropdownStyle({ top: `${selectContainerHeight + 4 + 2}px` });
            }
        }
    }, [isOpen, dropUp]);

    const handleOptionClick = (value) => {
        // Simulate an event object
        const event = {
            target: {
                name: name,
                value: value,
            }
        };
        onChange(event); // Pass the simulated event object
        setIsOpen(false);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleFocus = () => {
        setIsFocused(true);
        if (onFocus) onFocus(); // Notify parent component
    };

    const handleBlur = () => {
        setIsFocused(false);
        if (onBlur) {
            onBlur();
        }; // Notify parent component
    };

    return (
        <div className={`${styles.container} ${wrappedIcon ? styles.hasWrappedIcon : ''} ${styles[size]} ${className || ''} ${variantClassName || ''}`}>
            {label && <Label text={label} help={help} size={size} link={link} menuItems={menuItems}/>}
            <div
                className={`${styles.selectContainer} ${isFocused ? styles.focused : ''} ${disabled ? styles.disabled : ''} ${styles[state]}`}
                tabIndex="0"
                onFocus={handleFocus}
                onBlur={handleBlur}
                onClick={toggleDropdown}
                ref={selectContainerRef}
            >
                {selectedOption?.icon
                    ? getIconComponent(selectedOption.icon) // if the option has an icon show this icon
                    :
                    <>
                        {
                            icon && getIconComponent(icon) // if option has no icon check if there is an icon in param and show it
                        }
                    </>
                }

                <div className={styles.textWrapper}>
                    {
                        selectedOption?.value
                            ?
                            <div className={styles.selectedValue}>
                                {selectedOption?.label || selectedOption?.value}
                            </div>
                            : <div className={styles.placeholder}>{placeholder}</div>
                    }

                    {
                        (selectedOption?.extraData && hideExtraInSelected !== true) &&
                        <>
                            <Dot className={styles.delimeter} />
                            <div className={styles.extraData}>{selectedOption?.extraData}</div>
                        </>

                    }
                </div>
                {isOpen && (
                    <div
                        className={styles.dropdownContainer}
                        ref={dropdownRef}
                        // style={dropdownStyle}
                        style={{
                            ...dropdownStyle,
                            minWidth: dropdownMinWidth || undefined, // Use the string directly
                            left: dropdownAlignment === 'left' ? '0' : undefined,
                            right: dropdownAlignment === 'left' ? 'initial' : undefined,
                        }}
                    >
                        <Dropdown
                            className={styles.dropdownContent}
                            options={options}
                            onOptionClick={handleOptionClick}
                            size={size}
                            selectedOption={selectedOption}
                            icon={(variant !== 'menu' && overrideIcon !== false) ? icon : undefined} // don't pass icon if this is 'menu' type of select
                            wrappedIcon={wrappedIcon}
                            extraDataDelimeter={extraDataDelimeter}
                        />
                    </div>
                )}
                <ChevronDown className={styles.icon} />
            </div>
            {hint && <div className={styles.hint}>{hint}</div>}
        </div>
    );
};

Select.propTypes = {
    name: PropTypes.string.isRequired,
    state: PropTypes.oneOf(['default', 'focused', 'hover', 'error']),
    className: PropTypes.string,
    disabled: PropTypes.bool,
    extraDataDelimeter: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        selected: PropTypes.bool
    })),
    label: PropTypes.string,
    menuItems: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        onClick: PropTypes.func,
        icon: PropTypes.node,
        danger: PropTypes.bool,
    })),    
    placeholder: PropTypes.string,
    help: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    onChange: PropTypes.func,
    value: PropTypes.string,
    size: PropTypes.string,
    hint: PropTypes.string,
    variant: PropTypes.string,
    dropUp: PropTypes.bool,
    dropdownMinWidth: PropTypes.string,
};

Select.defaultProps = {
    name: '',
    state: 'default',
    className: '',
    disabled: false,
    extraDataDelimeter: true,
    options: [],
    label: '',
    menuItems: null,
    placeholder: 'Select item',
    help: null,
    onChange: () => { },
    value: '',
    size: 'md',
    hint: '',
    variant: null,
    dropUp: false,
    dropdownMinWidth: null,
    dropdownAlignment: null,
};



// with opening up, hardcoded 36
// import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
// import PropTypes from 'prop-types';
// import styles from './Select.module.css';

// import { ChevronDown, Dot } from "../../icons";
// import { Label } from '../Label';
// import { Dropdown } from '../Dropdown';

// export const Select = ({
//     state = "default",
//     name,
//     className,
//     icon,
//     overrideIcon,
//     wrappedIcon,
//     variant,
//     dropUp = false,
//     options,
//     hideExtraInSelected,
//     label = false,
//     link,
//     hint,
//     placeholder = 'Select item',
//     help,
//     onChange,
//     onFocus,
//     onBlur,
//     value,
//     size,
//     disabled
// }) => {
//     const variantClassName = styles[variant];

//     const [isOpen, setIsOpen] = useState(false);
//     const [isFocused, setIsFocused] = useState(false);
//     const [dropdownStyle, setDropdownStyle] = useState({ top: '36px' });
//     const dropdownRef = useRef(null);
//     const selectContainerRef = useRef(null);

//     const getIconComponent = (icon) => {
//         if (wrappedIcon) {
//             return (
//                 <div className={styles.wrappedIconContainer}>
//                     {React.cloneElement(icon, { className: styles.icon })}
//                 </div>
//             );
//         } else return React.cloneElement(icon, { className: styles.icon });
//     };

//     // Find the selected option
//     const [selectedOption, setSelectedOption] = useState(undefined);

//     useEffect(() => {
//         if (value && options) {
//             const foundSelectedOption = options.find(option => option.value === value);
//             setSelectedOption(foundSelectedOption);
//         }
//     }, [value, options]);

//     useEffect(() => {
//         function handleClickOutside(event) {
//             if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//                 setIsOpen(false);
//                 setIsFocused(false);

//                 if (onBlur) {
//                     onBlur();
//                 } // Notify parent component
//             }
//         }
//         document.addEventListener("mousedown", handleClickOutside);
//         return () => {
//             document.removeEventListener("mousedown", handleClickOutside);
//         };
//     }, [dropdownRef, onBlur]);

//     useLayoutEffect(() => {
//         if (isOpen) {
//             const dropdownHeight = dropdownRef.current ? dropdownRef.current.scrollHeight : 0;
//             if (dropUp) {
//                 setDropdownStyle({ top: `-${dropdownHeight + 4}px` });
//             } else {
//                 setDropdownStyle({ top: `${36 + 4}px` });
//             }
//         }
//     }, [isOpen, dropUp]);

//     const handleOptionClick = (value) => {
//         // Simulate an event object
//         const event = {
//             target: {
//                 name: name,
//                 value: value,
//             }
//         };
//         onChange(event); // Pass the simulated event object
//         setIsOpen(false);
//     };

//     const toggleDropdown = () => {
//         setIsOpen(!isOpen);
//     };

//     const handleFocus = () => {
//         setIsFocused(true);
//         if (onFocus) onFocus(); // Notify parent component
//     };

//     const handleBlur = () => {
//         setIsFocused(false);
//         if (onBlur) {
//             onBlur();
//         }; // Notify parent component
//     };

//     return (
//         <div className={`${styles.container} ${wrappedIcon ? styles.hasWrappedIcon : ''} ${styles[size]} ${className || ''} ${variantClassName || ''}`}>
//             {label && <Label text={label} help={help} size={size} link={link} />}
//             <div
//                 className={`${styles.selectContainer} ${isFocused ? styles.focused : ''} ${disabled ? styles.disabled : ''} ${styles[state]}`}
//                 tabIndex="0"
//                 onFocus={handleFocus}
//                 onBlur={handleBlur}
//                 onClick={toggleDropdown}
//                 ref={selectContainerRef}
//             >
//                 {selectedOption?.icon
//                     ? getIconComponent(selectedOption.icon) // if the option has an icon show this icon
//                     :
//                     <>
//                         {
//                             icon && getIconComponent(icon) // if option has no icon check if there is icon in param and show it
//                         }
//                     </>
//                 }

//                 <div className={styles.textWrapper}>
//                     {
//                         selectedOption?.value
//                             ?
//                             <div className={styles.selectedValue}>
//                                 {selectedOption?.label || selectedOption?.value}
//                             </div>
//                             : <div className={styles.placeholder}>{placeholder}</div>
//                     }

//                     {
//                         (selectedOption?.extraData && hideExtraInSelected !== true) &&
//                         <>
//                             <Dot className={styles.delimeter} />
//                             <div className={styles.extraData}>{selectedOption?.extraData}</div>
//                         </>

//                     }
//                 </div>
//                 {isOpen && (
//                     <div
//                         className={styles.dropdownContainer}
//                         ref={dropdownRef}
//                         style={dropdownStyle}
//                     >
//                         <Dropdown
//                             className={styles.dropdownContent}
//                             options={options}
//                             onOptionClick={handleOptionClick}
//                             size={size}
//                             selectedOption={selectedOption}
//                             icon={(variant !== 'menu' && overrideIcon !== false) ? icon : undefined} // don't pass icon if this is 'menu' type of select
//                             wrappedIcon={wrappedIcon}
//                         />
//                     </div>
//                 )}
//                 <ChevronDown className={styles.icon} />
//             </div>
//             {hint && <div className={styles.hint}>{hint}</div>}
//         </div>
//     );
// };

// Select.propTypes = {
//     name: PropTypes.string.isRequired,
//     state: PropTypes.oneOf(['default', 'focused', 'hover', 'error']),
//     className: PropTypes.string,
//     disabled: PropTypes.bool,
//     options: PropTypes.arrayOf(PropTypes.shape({
//         value: PropTypes.string.isRequired,
//         label: PropTypes.string.isRequired,
//         selected: PropTypes.bool
//     })),
//     label: PropTypes.string,
//     placeholder: PropTypes.string,
//     help: PropTypes.oneOfType([
//         PropTypes.string,
//         PropTypes.object
//     ]),
//     onChange: PropTypes.func,
//     value: PropTypes.string,
//     size: PropTypes.string,
//     hint: PropTypes.string,
//     variant: PropTypes.string,
//     dropUp: PropTypes.bool // Add dropUp prop type
// };

// Select.defaultProps = {
//     name: '',
//     state: 'default',
//     className: '',
//     disabled: false,
//     options: [],
//     label: '',
//     placeholder: 'Select item',
//     help: null,
//     onChange: () => { },
//     value: '',
//     size: 'md',
//     hint: '',
//     variant: null,
//     dropUp: false // Default to false
// };




// ORIGINAL
// import React, { useState, useEffect, useRef } from 'react';
// import PropTypes from 'prop-types';
// import styles from './Select.module.css';

// import { ChevronDown, Dot } from "../../icons";
// import { Label } from '../Label';
// import { Dropdown } from '../Dropdown';

// export const Select = ({ state = "default", name, className, icon, overrideIcon, wrappedIcon, variant, dropUp = false, options, hideExtraInSelected, label = false, link, hint, placeholder = 'Select item', help, onChange, onFocus, onBlur, value, size, disabled }) => {
    
//     const variantClassName = styles[variant];

//     const [isOpen, setIsOpen] = useState(false);
//     const [isFocused, setIsFocused] = useState(false);
//     const dropdownRef = useRef(null);


//     const getIconComponent = (icon) => {
//         if(wrappedIcon){
//             return ( 
//                 <div className={styles.wrappedIconContainer}>
//                     {React.cloneElement(icon, { className: styles.icon })}
//                 </div>                
//             )
//         } else return React.cloneElement(icon, { className: styles.icon });
//     };

//     // Find the selected option
//     const [selectedoption, setSelectedoption] = useState(undefined);

//     useEffect(() => {
//         if(value && options){
//             const foundSelectedoption = options.find(option => option.value === value);
//             setSelectedoption(foundSelectedoption)
//         }
//     }, [value, options]);

//     useEffect(() => {
//         function handleClickOutside(event) {
//             if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//                 setIsOpen(false);
//                 setIsFocused(false);
                
//                 if (onBlur) {
//                     onBlur()
//                 }; // Notify parent component
//             }
//         }
//         document.addEventListener("mousedown", handleClickOutside);
//         return () => {
//             document.removeEventListener("mousedown", handleClickOutside);
//         };
//     }, [dropdownRef, onBlur]);

//     const handleOptionClick = (value) => {
//         // Simulate an event object
//         const event = {
//             target: {
//                 name: name,
//                 value: value,
//             }
//         };
//         onChange(event); // Pass the simulated event object
//         setIsOpen(false);
//     };
        

//     const toggleDropdown = () => {
//         setIsOpen(!isOpen)
//     }

//     const handleFocus = () => {
//         setIsFocused(true);
//         if (onFocus) onFocus(); // Notify parent component
//     };

//     const handleBlur = () => {
//         setIsFocused(false);
//         if (onBlur) {
//             onBlur()
//         }; // Notify parent component
//     };

//     return (
//         <div className={`${styles.container} ${wrappedIcon ? styles.hasWrappedIcon : ''} ${styles[size]} ${className || ''} ${variantClassName || ''}`}>
//             {label && <Label text={label} help={help} size={size} link={link} />}
//             <div 
//                 className={`${styles.selectContainer} ${isFocused ? styles.focused : ''} ${disabled ? styles.disabled : ''} ${styles[state]}`} 
//                 tabIndex="0"
//                 onFocus={handleFocus}
//                 onBlur={handleBlur}
//                 onClick={toggleDropdown} 
//                 ref={dropdownRef}
//             >

//                 { selectedoption?.icon 
//                 ? getIconComponent(selectedoption.icon) // if the option has an icon show this icon
//                 : 
//                     <>
//                         {
//                             icon && getIconComponent(icon) // if optiion has no icon check if there is icon in param and show it
//                         }
//                     </>
//                 }

                

//                 <div className={styles.textWrapper}>
//                     {
//                         selectedoption?.value
//                         ? 
//                         <div className={styles.selectedValue}>
//                             {selectedoption?.label || selectedoption?.value}                            
//                         </div> 
//                         : <div className={styles.placeholder}>{placeholder}</div> 
//                     }

//                     {
//                         (selectedoption?.extraData && hideExtraInSelected !== true) &&
//                         <>
//                             <Dot className={styles.delimeter} />        
//                             <div className={styles.extraData}>{selectedoption?.extraData}</div>                
//                         </>

//                     }

//                     {/* {
//                         selectedoption?.extraData &&
//                         <div className={styles.extraData}>{selectedoption?.extraData}</div>
//                     } */}

//                 </div>
//                 {isOpen && (
//                     <div className={styles.dropdownContainer}>
//                         <Dropdown 
//                             className={styles.dropdownContent}
//                             options={options} 
//                             onOptionClick={handleOptionClick} 
//                             size={size}
//                             selectedOption={selectedoption}
//                             // icon={icon}
//                             //icon={variant !== 'menu' ? icon : undefined} // don't pass icon if this is 'menu' type of select
//                             icon={(variant !== 'menu' && overrideIcon !== false) ? icon : undefined} // don't pass icon if this is 'menu' type of select
//                             wrappedIcon={wrappedIcon}
//                         />
//                     </div>
//                 )}      
//                 <ChevronDown className={styles.icon} />    
//             </div> 
//             {hint && <div className={styles.hint}>{hint}</div>}
//         </div>
//     );  
// };

// Select.propTypes = {
//     name: PropTypes.string.isRequired,
//     state: PropTypes.oneOf(['default', 'focused', 'hover', 'error']),
//     className: PropTypes.string,
//     disabled: PropTypes.bool,
//     options: PropTypes.arrayOf(PropTypes.shape({
//         value: PropTypes.string.isRequired,
//         label: PropTypes.string.isRequired,
//         selected: PropTypes.bool
//     })),
//     label: PropTypes.string,
//     placeholder: PropTypes.string,
//     help: PropTypes.oneOfType([
//         PropTypes.string,
//         PropTypes.object
//     ]),
//     onChange: PropTypes.func,
//     value: PropTypes.string,
//     size: PropTypes.string,
//     hint: PropTypes.string,
//     variant: PropTypes.string,
// };

// Select.defaultProps = {
//     name: '',
//     state: 'default',
//     className: '',
//     disabled: false,
//     options: [],
//     label: '',
//     placeholder: 'Select item',
//     help: null,
//     onChange: () => {},
//     value: '',
//     size: 'md',
//     hint: '',
//     variant: null
// };
