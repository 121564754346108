import PropTypes from "prop-types";
import React from "react";

export const Warning = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path d="M8.50002 2.8121C8.86059 2.8121 9.19323 3.00255 9.37606 3.31487L14.8608 12.6593C15.0462 12.9742 15.0462 13.3627 14.8659 13.6775C14.6856 13.9924 14.3479 14.1879 13.9848 14.1879H3.01525C2.65214 14.1879 2.31442 13.9924 2.13413 13.6775C1.95384 13.3627 1.95638 12.9716 2.13921 12.6593L7.62398 3.31487C7.8068 3.00255 8.13944 2.8121 8.50002 2.8121ZM8.50002 6.06234C8.1623 6.06234 7.8906 6.33404 7.8906 6.67175V9.51571C7.8906 9.85343 8.1623 10.1251 8.50002 10.1251C8.83774 10.1251 9.10944 9.85343 9.10944 9.51571V6.67175C9.10944 6.33404 8.83774 6.06234 8.50002 6.06234ZM9.31257 11.7502C9.31257 11.5347 9.22697 11.3281 9.07458 11.1757C8.9222 11.0233 8.71552 10.9377 8.50002 10.9377C8.28451 10.9377 8.07784 11.0233 7.92545 11.1757C7.77307 11.3281 7.68746 11.5347 7.68746 11.7502C7.68746 11.9657 7.77307 12.1724 7.92545 12.3248C8.07784 12.4772 8.28451 12.5628 8.50002 12.5628C8.71552 12.5628 8.9222 12.4772 9.07458 12.3248C9.22697 12.1724 9.31257 11.9657 9.31257 11.7502Z" fill="currentColor"/>
    </svg>    
  );
};

Warning.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Warning.defaultProps = {
  color: 'currentColor', // Or any default color
};