import PropTypes from "prop-types";
import React from "react";

export const Save = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M3.00007 5.29995C3.00007 4.23638 3.6919 3.3344 4.65009 3.01946V4.10007C4.65009 4.76281 5.18736 5.30007 5.85011 5.30007H8.20015C8.8629 5.30007 9.40017 4.76281 9.40017 4.10007V2.89995H9.5275C10.0702 2.89995 10.587 3.13203 10.9476 3.53766L12.5703 5.36315C12.8795 5.71095 13.0502 6.1601 13.0502 6.62544V10.8C13.0502 11.6249 12.634 12.3526 12.0002 12.7846V10.8001C12.0002 9.64026 11.06 8.70006 9.90018 8.70006H6.15012C4.9903 8.70006 4.05008 9.64026 4.05008 10.8001V12.7846C3.41624 12.3526 3.00007 11.6249 3.00007 10.8V5.29995ZM5.2501 13.1954C5.29971 13.1985 5.34973 13.2 5.4001 13.2H10.6502C10.7006 13.2 10.7506 13.1985 10.8002 13.1954V10.8001C10.8002 10.303 10.3972 9.90006 9.90018 9.90006H6.15012C5.65305 9.90006 5.2501 10.303 5.2501 10.8001V13.1954ZM1.80005 5.29995C1.80005 3.31173 3.41185 1.69995 5.4001 1.69995H9.5275C10.413 1.69995 11.2562 2.07861 11.8445 2.74042L13.4672 4.56592C13.9716 5.13337 14.2502 5.86621 14.2502 6.62544V10.8C14.2502 12.7882 12.6384 14.4 10.6502 14.4H5.4001C3.41185 14.4 1.80005 12.7883 1.80005 10.8V5.29995Z" fill="currentColor"/>
    </svg>    
  );
};

Save.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Save.defaultProps = {
  color: 'currentColor', // Or any default color
};