// UserContext.js
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { getUserData, updateUserData, verifyUserPassword, disable2FA, generate2FAQRCode, get2FAData, setNewUserPassword } from '../api/qencode-account-api';
import { useAuth } from './AuthContext'; // import useAuth if it's a custom hook

export const UserContext = createContext(null);

// Export useUser hook for easy import
export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [userError, setUserError] = useState(null);
  // const [userData, setUserData] = useState({});
  const [userData, setUserData] = useState(null);
  const { 
    isAuthenticated, 
    //getAccessToken,
    getToken 
  } = useAuth(); // Assuming useAuth is a hook

  const fetchUserData = useCallback(async () => {
    if (isAuthenticated) {
      setLoading(true);
      //const token = getAccessToken();
      const token = await getToken()

      if (!token) {
          setUserError("Failed to retrieve access token");
          console.log("Failed to retrieve access token");
          setLoading(false);
          return; // Exit early if the token is not defined
      }

      const { success, data } = await getUserData(token);
      if (success && data) {
        setUserData(data);
        setUserError(null);
        //console.log("Got user data in UserContext")
      } else {
        setUserError("Failed to get user data");
        console.log("Failed to get user data");
      }
      setLoading(false);
    } else {
      // setUserData({});
      setUserData(null);
      setUserError(null);
      setLoading(false);
    }
  }, [isAuthenticated, getToken]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);  

  const updateUserAccount = async (credentials) => {
    //const token = getAccessToken();
    const token = await getToken()
    const response = await updateUserData(token, credentials);
    const {success, detail, data} = response;
    if (success && data) {
      setUserError(null);
      // You might want to show a success message or perform some action
      setUserData(data);
      // return {success, data};
      return true
    } else {
      setUserError(detail || "Failed to update user account");
      // Show an error message to the user
      return false;
    }
  };
  
  const verifyPassword = async (credentials) => {
    // const token = getAccessToken();
    const token = await getToken()
    const response = await verifyUserPassword(token, credentials);
    const {success, detail} = response;
    if (success) {
      return { success: true };
    } else {
      // Show an error message to the user
      return { success: false, detail };
    }
  };

  const setNewPassword = async (credentials) => {
    // const token = getAccessToken();
    const token = await getToken()
    const response = await setNewUserPassword(token, credentials);
    const {success, detail} = response;
    if (success) {
      return { success: true };
    } else {
      // Show an error message to the user
      return { success: false, detail };
    }
  };

  const disableUser2FA = async () => {
    // const token = getAccessToken()
    const token = await getToken()

    const response = await disable2FA(token);
    const {success, detail} = response;
    if (success) {
      setUserError(null);
      await fetchUserData();
      return true
    } else {
      setUserError(detail || "Failed to disable 2FA");
      // Show an error message to the user
      return false;
    }
  }

  const get2FaQrCode = async () => {
    // const token = getAccessToken()
    const token = await getToken()

    const response = await generate2FAQRCode(token)
    const {success, qrDataUrl, error} = response
    if (success) {
      return { success: true, qrDataUrl };
    } else {
      // Show an error message to the user
      return { success: false, detail: error };
    }
  }

  const get2FaDetails = async () => {
    // const token = getAccessToken()
    const token = await getToken()

    const response = await get2FAData(token)
    const {success, detail, secret, uri} = response
    if (success) {
      return { success: true, secret, uri };
    } else {
      // Show an error message to the user
      return { success: false, detail };
    }
  }  


  return (
    <UserContext.Provider value={{ 
      userData, setUserData, userError, setUserError, loading, updateUserAccount, 
      disableUser2FA, verifyPassword, get2FaQrCode, get2FaDetails, fetchUserData,
      setNewPassword 
    }}>
      {children}
    </UserContext.Provider>
  );
};
