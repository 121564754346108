import PropTypes from "prop-types";
import {useCallback, useState} from 'react';

export const clearTypes = {
  clearAll: 'clearAll', // clear all column filters, all draft column filters, and any connected inputs.
  clearDraftAndActiveFilter: 'clearDraftAndActiveFilter', // clear specific column filter and draft column filter. Do not clear inputs.
  clearDraftFilter: 'clearDraftFilter', // clear draft filter only. Do not clear active column filters or inputs.
  clearOneFilterAndInputs: 'clearOneFilterAndInputs', // clear draft and active filter and inputs
}

function getNewCheckboxListValue(filter, listItem) {
  if (filter?.value?.includes?.(listItem)) {
    return filter.value.filter((i) => i !== listItem);
  } else {
    return [ ...(filter?.value || []), listItem ];
  }
}

/* We need draft column filters since user has to hit "apply" button in filters.
 * Format of draft filters array is the same as active filters.
 * Filter value can be
 * 1. A list of checkboxes. If checked, it will be in array.
 * 2. A range - array of 2 elements.
 */
 
function useFilter({ setPagination } = {}) {
  const [columnToError, setColumnToError] = useState({});
  const [lastClearType, setLastClearType] = useState();
  const [lastClearFilterId, setLastClearFilterId] = useState();
  const [applyFilterCount, setApplyFilterCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState([]);
  const [draftColumnFilters, setDraftColumnFilters] = useState([ /* id: <id of a column being filtered>, value: [] */ ]);
  const [columnToFilterExpanded, setColumnToFilterExpanded] = useState({ /* [id of a column being filtered]: true/false */ }); /* Each section of sidebar has open/closed state. */
  const [visible, setVisible] = useState(false); /* Is sidebar open. */
  
  const expandFilterSidebarAndFilterSection = useCallback(function expandFilterSidebarAndFilterSection(columnId) {
    setColumnToFilterExpanded((state) => ({
      ...state,
      [ columnId ]: (new Date()).getTime(), /* Recording time to prioritize internal expanded state IF it's more recent. */
    }));
    setVisible(true);
  }, []);
  
  const clearError = useCallback(function clearError(filterId) {
    setColumnToError((state) => {
      const newState = { ...state };
      delete newState[filterId];
      return newState;
    });
  }, []);
  
  /*
    A bug can happen if BE request is made each time filter applied.
    If previous pagination is active, BE request will have unintended pagination and will likely return 0 results.
    Anyway, the same problem happens when all data is on FE.
    So we're clearing pagination on any column filter or global filter change.
    This should be centralized place where this happens.
  */
  const setColumnFiltersAndClearPagination = useCallback(function setColumnFiltersAndClearPagination(newFilters) {
    setColumnFilters(newFilters);
    setPagination?.((previousState) => ({
      ...previousState,
      pageIndex: 0,
    }));
  }, [setPagination]);
  
  const applyDraftColumnFilters = useCallback(function applyDraftColumnFilters() {
    setApplyFilterCount((count) => count + 1);
    setColumnFiltersAndClearPagination(draftColumnFilters);
  }, [draftColumnFilters, setColumnFiltersAndClearPagination]);
  
  const clearAllFilters = useCallback(function clearAllFilters() {
    setLastClearType(clearTypes.clearAll + (new Date()).getTime());
    setLastClearFilterId();
    if (columnFilters.length) {
      setColumnFiltersAndClearPagination( [] );
    }
    /* Do not clear pagination when clearing draft filters that weren't applied. */
    setDraftColumnFilters([]);
    setColumnToError({});
  }, [columnFilters.length, setColumnFiltersAndClearPagination]);
  
  const clearFilter = useCallback(function clearFilter(filterId, type = clearTypes.clearOneFilterAndInputs) {
    if (filterId) {
      const updater = (oldFilters) => {
        return [...oldFilters.filter((myOldFilter) => myOldFilter.id !== filterId )];
      };
      setLastClearType(type + (new Date()).getTime());
      setLastClearFilterId(filterId);
      if (type !== clearTypes.clearDraftFilter) {
        setColumnFiltersAndClearPagination(updater);
      }
      setDraftColumnFilters(updater);
      clearError(filterId);
    }
  }, [clearError, setColumnFiltersAndClearPagination]);
  
  const setNewDraft = useCallback(function setNewDraft(columnId, newFilterValue) {
    /* Do not allow setting empty array newFilterValue as a way of clearing filter */
    if (typeof columnId === 'string') {
      if (Array.isArray(newFilterValue) && newFilterValue.length > 0) {
        let draftFiltersCopy = [...draftColumnFilters];
        const indexToUpdate = draftColumnFilters.findIndex(({ id }) => id === columnId);
        const newFilter = {
          id: columnId,
          value: newFilterValue,
        }
        
        if (indexToUpdate === -1) {
          draftFiltersCopy.push(newFilter)
        }
        else {
          draftFiltersCopy[ indexToUpdate ] = newFilter;
        }
        
        const newDraftFilters = draftFiltersCopy.filter((filter) => filter.value.length > 0);
        setDraftColumnFilters(newDraftFilters);
      } else {
        clearFilter(columnId);
      }
    } else {
      console.error('Incorrect arguments of setNewDraft');
    }
  }, [clearFilter, draftColumnFilters]);

  /* Add/remove an item to/from any list filter. */
  const handleCheck = useCallback(function handleCheck(columnId, listItem) {
    const indexToUpdate = draftColumnFilters.findIndex(({ id }) => id === columnId);
    const filter = draftColumnFilters[indexToUpdate];
    const newValue = getNewCheckboxListValue(filter, listItem);
    setNewDraft(columnId, newValue);
  }, [draftColumnFilters, setNewDraft]);
  
  const getFilterValue = useCallback(function getFilterValue(columnId) {
    return draftColumnFilters?.find?.((anyFilter) => anyFilter.id === columnId)?.value || [];
  }, [draftColumnFilters]);
  
  /* Add boolean "checked" property to list of options to indicate option is in draft filter. */
  const addCheckedStatus = useCallback(function addCheckedStatus(columnId, options) {
    const filterValue = getFilterValue(columnId);
    return options.map((option) => ({
      ...option,
      checked: filterValue.includes(option.value),
    }));
  }, [getFilterValue]);

  return {
    visible,
    setVisible,
    columnFilters,
    applyDraftColumnFilters,
    setColumnFiltersAndClearPagination,
    expandFilterSidebarAndFilterSection,
    clearAllFilters,
    setColumnToError,
    /* Props relevant to individual filter sections. */
    filterSectionProps: {
      columnToFilterExpanded, // [columnId]: <timestamp> - time when last expanded.
      handleCheck,
      clearFilter,
      setNewDraft,
      addCheckedStatus,
      getFilterValue,
      lastClearType,
      lastClearFilterId,
      applyFilterCount,
      columnToError,
      clearError,
    },
  };
}

export const FilterSectionProps = {
  clearFilter: PropTypes.func.isRequired,
  columnToFilterExpanded: PropTypes.object.isRequired,
  handleCheck: PropTypes.func.isRequired,
  setNewDraft: PropTypes.func.isRequired,
  addCheckedStatus: PropTypes.func.isRequired,
  getFilterValue: PropTypes.func.isRequired,
  lastClearType: PropTypes.string,
  lastClearFilterId: PropTypes.string,
  applyFilterCount: PropTypes.number,
  columnToError: PropTypes.object.isRequired,
  clearError: PropTypes.func.isRequired,
};

export const FilterType = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  columnFilters: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(PropTypes.any).isRequired,
  })).isRequired,
  applyDraftColumnFilters: PropTypes.func.isRequired,
  setColumnFiltersAndClearPagination: PropTypes.func.isRequired,
  expandFilterSidebarAndFilterSection: PropTypes.func.isRequired,
  clearAllFilters: PropTypes.func.isRequired,
  filterSectionProps: PropTypes.shape(FilterSectionProps),
  setColumnToError: PropTypes.func,
};


export default useFilter;