import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import style from './style.module.css';
import {isValid} from 'date-fns';
import FilterSection from "components/FilterSidebar/FilterSection";
import Calendar from "components/Calendar";
import DateRange from "components/FilterSidebar/DateRangeFilter/DateRange";
import useDays from './useDays';
import {FilterSectionProps} from "components/FilterSidebar/useFilter";

const types = {
  'start': 'start',
  'end': 'end',
};

function DateRangeFilter({
  columnId,
  title,
  getFilterValue,
  columnToFilterExpanded,
  defaultExpanded,
  clearFilter,
  setNewDraft,
  lastClearType,
  lastClearFilterId,
  }) {
  const filterValue = getFilterValue(columnId);
  const expandedFromActiveFilterTime = columnToFilterExpanded[columnId];
  const rootRef = useRef();
  const [openCalendarType, setOpenCalendarType] = useState();
  const dateRangeRef = useRef();
  
  const {
    start,
    end,
    setDate,
    setStartDateDatepicker,
    setEndDateDatepicker,
  } = useDays({
    columnId,
    setOpenCalendarType,
    setNewDraft,
    lastClearType,
    lastClearFilterId,
    });
  
  function onFocus(dropdownType) {
    setOpenCalendarType(dropdownType);
    setTimeout(() => {
      rootRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      } );
    }, 200);
  }

  const handleClickOutside = useCallback((event) => {
    if (
      !dateRangeRef?.current?.contains(event.target)
      && !rootRef?.current?.contains(event.target)
    ) {
      setOpenCalendarType();
    }
  }, [rootRef]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);
  
  return (
    <FilterSection
      title={ title }
      expandedFromActiveFilterTime={expandedFromActiveFilterTime}
      handleClear={() => clearFilter(columnId)}
      selectedCount={ filterValue.length > 0 ? 1 : 0 }
      defaultExpanded={defaultExpanded}
      >
      <DateRange
        wrapperRef={dateRangeRef}
        start={{
          ref: start.ref,
          invalid: start.invalid,
        }}
        end={{
          ref: end.ref,
          invalid: end.invalid,
        }}
        setDate={setDate}
        onFocusStart={() => onFocus(types.start)}
        onFocusEnd={() => onFocus(types.end)}
      />
      <div ref={rootRef} className={ style.calendars }>
        <DropdownWithCalendar
          key={start.dateKey}
          openCalendarType={openCalendarType}
          day={start}
          calendarType={types.start}
          setDate={setStartDateDatepicker}
          invalid={start.invalid}
        />
        <DropdownWithCalendar
          key={end.dateKey}
          openCalendarType={openCalendarType}
          day={end}
          calendarType={types.end}
          setDate={setEndDateDatepicker}
          invalid={end.invalid}
        />
      </div>
    </FilterSection>
  );
}

function DropdownWithCalendar({ openCalendarType, calendarType, day, setDate }) {
  return (
    <div
      className={`${style.calendarWrapper} ${openCalendarType === calendarType && (!day.date || isValid( day.date )) ? '' : style.hidden}`}>
      <Calendar
        defaultMonth={day.date}
        selected={day.date}
        onSelect={ setDate }
      />
    </div>
  );
}

DropdownWithCalendar.propTypes = {
  openCalendarType: PropTypes.string,
  calendarType: PropTypes.string.isRequired,
  day: PropTypes.object,
  setDate: PropTypes.func.isRequired,
};

DateRangeFilter.propTypes = {
  title: PropTypes.string.isRequired,
  columnId: PropTypes.string.isRequired,
  ...FilterSectionProps,
};

export default DateRangeFilter;