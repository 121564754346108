import React from 'react';

function JobPage() {
  return (
    <></>
  )
}

JobPage.propTypes = {

};

export default JobPage;