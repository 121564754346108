import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styles from './Checkbox.module.css'; 

export const Checkbox = React.forwardRef(({ label, indeterminate, checked, disabled, onChange }, ref) => {
  useEffect(() => {
    if (ref?.current) {
      ref.current.indeterminate = indeterminate;
    }
  }, [indeterminate, ref]);

  return (
    <label className={`${styles.checkboxContainer} ${checked ? styles.checked : ''} ${disabled ? styles.disabled : ''}  ${indeterminate ? styles.indeterminate : ''}`}>
      <span className={styles.checkbox}>
        <input 
          ref={ref} 
          type="checkbox" 
          checked={checked} 
          disabled={disabled} 
          onChange={onChange}
        />
        
        { checked && !indeterminate &&
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
            <path d="M1.5 6L5.20588 9L10.5 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>   
        }

        { indeterminate && 
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="2" viewBox="0 0 10 2" fill="none">
            <path d="M1 1H9" stroke="white" strokeWidth="2" strokeLinecap="round"/>
          </svg>
        }
      </span>
      {label && <span className={styles.label}>{label}</span>}
    </label>
  );
});

Checkbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  label: '',
  checked: false,
  indeterminate: false,
  disabled: false,
  onChange: () => {},
};

