import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import style from "./style.module.css";
import {Checkbox} from "components/Checkbox";
import classNames from "classnames";

function OptionList({
  options,
  setOptions,
  horizontal = false,
  skeleton = false,
  handleCheck, /* Only for backwards-compatibility. */
  mediumVariant,
  }) {
  
  const onChange = useCallback((value) => {
    if (handleCheck) {
      handleCheck(value);
    }
    else if (setOptions) {
      setOptions((optionsCopy) => {
        return optionsCopy.map((i) => ({
          ...i,
          checked: i.value === value ? (!i.checked) : i.checked,
        }));
      });
    }
  }, [handleCheck, setOptions])
  
  return (
    <ul className={classNames(style.checkboxes, {
      [style.horizontal]: horizontal,
      [style.mediumVariant]: mediumVariant,
    })}>
      {
        skeleton ? <>
            <li className={style.skeletonItem}></li>
            <li className={style.skeletonItem}></li>
            <li className={style.skeletonItem}></li>
            <li className={style.skeletonItem}></li>
          </>
          :
          options.map( ({ value, label, checked }) => {
        return (
          <li key={value} className={ style.listItem }>
            <Checkbox
              key={ value }
              onChange={() => onChange(value)}
              label={ label }
              checked={ checked }
            />
          </li>
        );
      }) }
    </ul>
  )
}

OptionList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool,
  })),
  setOptions: PropTypes.func,
  handleCheck: PropTypes.func,
};

export default OptionList;