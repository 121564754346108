import React from 'react';
import PropTypes from 'prop-types';
import {OptionsMenu} from "components/OptionsMenu";
import {Delete, Edit} from "icons";
import {Clone} from "icons/Clone";
import {modals} from "pages/Player/VideoPlayerListPage/config";

function MoreActions({ handleOpenModal, player }) {

  return (
    <div className="cellContainer">
      <OptionsMenu
        menuItems={
          [
            {
              icon: <Edit label="Edit"/>,
              text: 'Edit',
              onClick: () => handleOpenModal( modals.edit, player ),
            },
            {
              icon: <Clone label="Clone"/>,
              text: 'Clone',
              onClick: () => handleOpenModal( modals.clone, player ),
            },
            {
              icon: <Delete label="Remove" />,
              text: 'Delete',
              danger: true,
              onClick: () => handleOpenModal( modals.delete, player ),
            }
          ]
        }
      />
    </div>
  )
}

MoreActions.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
  player: PropTypes.object.isRequired,
};

export default MoreActions;