import React from 'react';
import PropTypes from 'prop-types';
import style from "pages/MediaStorage/BucketSettingsModal/style.module.css";
import InputList from "pages/MediaStorage/InputList";
import {Checkbox} from "components/Checkbox";
import {methodEnum} from "pages/MediaStorage/config";
import {InputField} from "components/InputField";
import ErrorMessage from "components/ErrorMessage";

function CorsContent({
                           allowCredentials,
                           allowedMethods = [],
                           checkMethod,
                           corsLoadError,
                           maxAge,
                           maxAgeError,
                           originList = [],
                           setAllowCredentials,
                           setMaxAge,
                           setOriginList,
                     }) {
  return (
    <div>
      {corsLoadError && <ErrorMessage>{corsLoadError}</ErrorMessage>}
      <div className={style.section}>
        <h4 className={style.heading}>
          ALLOWED ORIGIN
        </h4>
        <InputList
          addButtonText="Add Origin"
          placeholder="https://example.com"
          list={originList}
          disabled={ Boolean(corsLoadError) }
          setList={setOriginList}
          firstItemHeading="Origin"
        />
      </div>
      <div className={style.section}>
        <h4 className={style.heading}>
          ALLOWED METHODS
        </h4>
        <div className={style.methods}>
          <Checkbox
            label="Get"
            disabled={ Boolean(corsLoadError) }
            checked={allowedMethods.includes( methodEnum.GET )}
            onChange={() => checkMethod( methodEnum.GET )}
          />
          <Checkbox
            label="Put"
            disabled={ Boolean(corsLoadError) }
            checked={allowedMethods.includes( methodEnum.PUT )}
            onChange={() => checkMethod( methodEnum.PUT )}
          />
          <Checkbox
            label="Post"
            disabled={ Boolean(corsLoadError) }
            checked={allowedMethods.includes( methodEnum.POST )}
            onChange={() => checkMethod( methodEnum.POST )}
          />
          <Checkbox
            label="Head"
            disabled={ Boolean(corsLoadError) }
            checked={allowedMethods.includes( methodEnum.HEAD )}
            onChange={() => checkMethod( methodEnum.HEAD )}
          />
          <Checkbox
            label="Delete"
            disabled={ Boolean(corsLoadError) }
            checked={allowedMethods.includes( methodEnum.DELETE )}
            onChange={() => checkMethod( methodEnum.DELETE )}
          />
        </div>
      </div>
      <div className={style.section}>
        <h4 className={style.heading}>
          ALLOW CREDENTIALS
        </h4>
        <Checkbox
          label="Allow"
          disabled={ Boolean(corsLoadError) }
          checked={allowCredentials}
          onChange={( e ) => setAllowCredentials( e.target.checked )}
        />
      </div>
      <div className={style.section}>
        <h4 className={style.heading}>
          ACCESS CONTROL MAX. AGE
        </h4>
        <InputField
          label="Seconds"
          className={style.seconds}
          inputText={maxAge}
          state={ corsLoadError ? 'disabled' : 'default' }
          onChange={( e ) => setMaxAge( e.target.value )}
        />
        { maxAgeError && <ErrorMessage>{ maxAgeError }</ErrorMessage> }
      </div>
    </div>
  )
}

CorsContent.propTypes = {
  allowCredentials: PropTypes.bool,
  allowedMethods: PropTypes.arrayOf(PropTypes.string),
  checkMethod: PropTypes.func,
  corsLoadError: PropTypes.string,
  maxAge: PropTypes.string,
  maxAgeError: PropTypes.string,
  originList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.string,
  })),
  setAllowCredentials: PropTypes.func,
  setMaxAge: PropTypes.func,
  setOriginList: PropTypes.func,
};

export default CorsContent;