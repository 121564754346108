import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import BriefPanel from "pages/StatisticsPage/components/BriefPanel";
import TopResolutions from "pages/StatisticsPage/TranscodingTab/TopResolutions";
import TopFormats from "pages/StatisticsPage/TranscodingTab/TopFormats";
import TopCodecs from "pages/StatisticsPage/TranscodingTab/TopCodecs";
import TopProjects from "pages/StatisticsPage/TranscodingTab/TopProjects";
import ProjectsSection from "pages/StatisticsPage/TranscodingTab/ProjectsSection/ProjectsSection";
import useLineChartDataset from "pages/StatisticsPage/components/Chart/useLineChartDataset";
import OptionList from "components/OptionList";
import Controls from 'components/Controls';
import Columns from "components/Columns";
import Card from "pages/StatisticsPage/components/Card";
import VerticalGapsLayout from "components/VerticalGapsLayout";
import Chart from "pages/StatisticsPage/components/Chart";
import {transcodingLonglistToShortlist, TranscodingType} from "pages/StatisticsPage/config";
import {transcodingTypeToColor} from "pages/StatisticsPage/config";
import BriefItem from "pages/StatisticsPage/components/BriefPanel/BriefItem";
import TranscodingTooltipContent from "pages/StatisticsPage/TranscodingTab/TranscodingTooltipContent";
import NoChartData from "pages/StatisticsPage/components/Chart/NoData";


function TranscodingTab({
  transcoding,
  datepicker,
}) {
  const {
    options,
    setOptions,
    chartData,
    shortlistCheckedIds,
    quantity,
    amountSums,
  } = useLineChartDataset({
    data: transcoding.stats.status.data,
    longlistToShortlist: transcodingLonglistToShortlist,
    chartDataProperty: 'duration',
    quantityDataProperty: 'amount',
    longlistProperty: 'tariff_name',
    dates: datepicker.dates,
  });
  
  const noDataInSelected = useMemo(() => {
    const sum = shortlistCheckedIds.reduce((acc, selectedId) => acc + (amountSums[selectedId] || 0), 0);
    return typeof sum === 'number' && Number.isFinite(sum) && sum === 0;
  }, [shortlistCheckedIds, amountSums]);

  return (
    <VerticalGapsLayout>
      <Controls
        title="Usage"
        rightContent={
          <OptionList
            options={options}
            setOptions={setOptions}
            horizontal={true}
            skeleton={transcoding.stats.status.loading}
            mediumVariant
          />
        }
      />
      <Chart
        chartData={chartData}
        loading={transcoding.stats.status.loading}
        keyToColor={transcodingTypeToColor}
        dataKeys={shortlistCheckedIds}
        legend="Minutes"
        quantity={quantity}
        contentComponent={TranscodingTooltipContent}
      >
        {
          noDataInSelected ? (
            <NoChartData
              startDate={datepicker.startDate}
              endDate={datepicker.endDate}
            />
          ) : null
        }
      </Chart>
      <BriefPanel>
        <BriefItem
          heading="Per-Title Encoding Savings"
          type='gigabyte'
          value={ transcoding.total.status.data?.per_title_encoding?.size }
          value2={ `(${ transcoding.total.status.data?.per_title_encoding?.percent }%)` }
          loading={transcoding.stats.status.loading}
        />
        <BriefItem
          heading="Total Minutes"
          value={ transcoding.total.status.data.duration || 0 }
          loading={transcoding.stats.status.loading}
        />
        <BriefItem
          heading="Total Cost"
          value={transcoding.total.status.data.amount || 0}
          loading={transcoding.stats.status.loading}
          type="money"
        />
      </BriefPanel>
      <Columns minWidth={250}>
        <Card title="Top Resolutions">
          <TopResolutions
            stats={ transcoding.topResolution.status }
          />
        </Card>
        <TopFormats
          stats={ transcoding.topFormat.status }
        />
        <TopCodecs
          stats={ transcoding.topCodec.status }
        />
        <TopProjects
          stats={ transcoding.topProject.status }
        />
      </Columns>
      <ProjectsSection loadingStatus={ transcoding.statsByProject.status }/>
    </VerticalGapsLayout>
  )
}

TranscodingTab.propTypes = {
  transcoding: TranscodingType,
  datepicker: PropTypes.object.isRequired,
};

export default TranscodingTab;