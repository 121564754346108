import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.css';
import {Calendar} from "icons";
import DateFormatted from "components/DateFormatted";
import {regionCodeToName} from "pages/MediaStorage/BucketsPage/config";

function BucketInfo({ title, regionCode, created }) {
  return (
    <div className={ style.container }>
      <h3 className={ style.title }>
        { title }
      </h3>
      <div className={ style.details }>
        <Calendar className={ style.icon }/>
        <span>
          Created on{' '}
          <DateFormatted date={created}/>
        </span>
        <div className={ style.circle }></div>
        <span>
          { regionCodeToName[ regionCode ] }
        </span>
      </div>
    </div>
  )
}

BucketInfo.propTypes = {
  title: PropTypes.string,
  regionCode: PropTypes.string,
};

export default BucketInfo;