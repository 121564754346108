import React, { useState, useRef } from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { Copy } from 'icons';
import PropTypes from 'prop-types';
import styles from './CopyButton.module.css';

export const CopyButton = ({ contentToCopy, className, tooltipText = 'Copy', textOnSuccess = 'Copied!' }) => {
    const [tooltipContent, setTooltipContent] = useState(tooltipText);
    const tippyInstance = useRef(null);

    const handleCopy = () => {
        navigator.clipboard.writeText(contentToCopy)
            .then(() => {
                //console.log('Copied to clipboard:', contentToCopy);

                setTooltipContent(textOnSuccess);
                if (tippyInstance.current) {
                    tippyInstance.current.show();
                }
                setTimeout(() => {
                    setTooltipContent(tooltipText);
                }, 1000);
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };

    return (
        <Tippy
            content={tooltipContent}
            onCreate={(instance) => (tippyInstance.current = instance)}
            appendTo={() => document.getElementById('tooltip-portal')} // see public/index.html - append there to make sure it's visible
        >
            <div className={`${styles.copyBtn} ${className}`} onClick={handleCopy}>
                <Copy label="Copy" />
            </div>
        </Tippy>
    );
};

CopyButton.propTypes = {
    contentToCopy: PropTypes.string.isRequired,
    className: PropTypes.string,
    tooltipText: PropTypes.string,
    textOnSuccess: PropTypes.string,
};
