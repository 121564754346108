import PropTypes from "prop-types";
import React from "react";

export const Check = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M8.5 2.5C5.18629 2.5 2.5 5.18629 2.5 8.5C2.5 11.8137 5.18629 14.5 8.5 14.5C11.8137 14.5 14.5 11.8137 14.5 8.5C14.5 5.18629 11.8137 2.5 8.5 2.5ZM12.1254 6.24727C12.354 5.90185 12.2593 5.43652 11.9139 5.20792C11.5685 4.97933 11.1031 5.07405 10.8746 5.41947L7.80077 10.0643L5.9719 8.58377C5.64995 8.32315 5.17769 8.37286 4.91707 8.69481C4.65644 9.01675 4.70616 9.48902 5.0281 9.74964L7.49869 11.7496C7.663 11.8827 7.87583 11.9402 8.08477 11.908C8.29371 11.8758 8.47937 11.7569 8.59604 11.5806L12.1254 6.24727Z" fill="currentColor"/>
    </svg>    
  );
};

Check.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Check.defaultProps = {
  color: 'currentColor', // Or any default color
};