import React from 'react';
import {Active, ClockDashed, Error, Download, FolderAlt, InProgress} from "icons";
import style from "./style.module.css";
import PropTypes from "prop-types";

export const jobStatus = {
  queued: 'queued',
  downloading: 'downloading',
  encoding: 'encoding',
  saving: 'saving',
  completed: 'completed',
  error: 'error',
};

export const jobStatusToReadableName = {
  [jobStatus.queued]: 'Queued',
  [jobStatus.downloading]: 'Downloading',
  [jobStatus.encoding]: 'Encoding',
  [jobStatus.saving]: 'Saving',
  [jobStatus.completed]: 'Completed',
  [jobStatus.error]: 'Error',
};


const statusToIcon = {
  [jobStatus.completed]: <Active className={style.active}/>,
  [jobStatus.queued]: <ClockDashed className={style.queued}/>,
  [jobStatus.downloading]: <Download className={style.downloading}/>,
  [jobStatus.encoding]: <InProgress className={style.encoding}/>,
  [jobStatus.saving]: <FolderAlt className={style.saving}/>,
  [jobStatus.error]: <Error className={style.error}/>,
}

function JobStatus({ status, error }) {
  if (error === 1) {
    return (
      <span className={style.wrapper}>
        { statusToIcon[ jobStatus.error ] }
        <span>
          { jobStatusToReadableName[ jobStatus.error ] }
        </span>
      </span>
    );
  } else {
    return (
      <span className={style.wrapper}>
        {statusToIcon[status]}
        <span>
          { jobStatusToReadableName[status] }
        </span>
      </span>
    );
  }
}

JobStatus.propTypes = {
  status: PropTypes.string,
  error: PropTypes.number,
};

export default JobStatus;