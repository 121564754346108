import {useCallback, useEffect, useState} from 'react';
import {useBackend} from "api/useBackend";
import {useAuth} from "context/AuthContext";
import {enableMediaStorage} from "api/qencode-auth-api";

function useMediaStorageTokens() {
  const [tokens, setTokens] = useState({});
  const [refreshCount, setRefreshCount] = useState(0);
  const [initialized, setInitialized] = useState(false);
  const { getS3config } = useBackend();
  
  const { getToken } = useAuth();
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const handleSubmit = useCallback(async function handleSubmit() {
    const accessToken = await getToken();
    setSubmitting(true);
    const {success, error} = await enableMediaStorage({
      accessToken,
    });
    setSubmitting(false);
    if (success) {
      setRefreshCount((count) => count + 1);
    } else {
      setErrorMessage(error);
    }
  }, [getToken]);

  useEffect(() => {
    async function init() {
      setInitialized(false);
      const configResponse = await getS3config();
      if (configResponse.success) {
        setInitialized(true)
        setTokens(configResponse.data);
      }
    }
    
    init();
  }, [getS3config, refreshCount]);
  const enabled = typeof tokens?.['access_id'] === 'string'
    && typeof tokens?.['secret_key'] === 'string';

  return {
    tokens,
    enabled,
    initialized,
    handleSubmit,
    submitting,
    enableButtonVisible: initialized && !enabled,
    errorMessage,
  };
}

useMediaStorageTokens.propTypes = {};

export default useMediaStorageTokens;