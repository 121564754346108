// has error on downloading - http://localhost:3000/my_encodings/e732d0f735261fdd8a09c90da4d4c43d
// no errors completed - http://localhost:3000/my_encodings/be462fdabff6e5fccb777cd4db4d3313

import React, { 
    // useState, 
    useEffect, 
    // useCallback, 
    useRef 
} from 'react';

import { highlightJson } from 'helpers/highlightJson'

import { CopyButton } from 'components/CopyButton'

import styles from './index.module.css';

export const InputParameters = ({queryObject, loading, error}) => {
    const codeRef = useRef(null);

    useEffect(() => {
        if (codeRef.current) {
            codeRef.current.innerHTML = highlightJson(queryObject, false);
        }
    }, [queryObject]);    

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div className={styles.errorMessage}>{error}</div>;
    }

    if (!queryObject) {
        return null;
    }

    const queryObjectJson = JSON.stringify(queryObject, null, 2);

    return (
        <div className={styles.inputWrapper}>
            <div className={styles.inputParamsContainer}>
                <pre className={styles.json}>
                    <code ref={codeRef}></code>
                </pre>    
            </div>
            <div className={styles.inputParamsActions}>
                <CopyButton contentToCopy={queryObjectJson} />      
            </div>        
        </div>
    );
};

