import PropTypes from "prop-types";
import React from "react";

export const User = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path d="M8.49986 9.84376C6.96928 9.84376 5.54197 10.4195 4.48242 11.3623C5.54703 12.7855 6.88618 13.4971 8.49986 13.4971C10.1135 13.4971 11.4434 12.7855 12.4894 11.3623C11.4296 10.4183 10.0316 9.84376 8.49986 9.84376Z" fill="currentColor"/>
      <path d="M6.32006 7.0798C6.32006 5.87594 7.29598 4.90002 8.49981 4.90002C9.70369 4.90002 10.6796 5.87594 10.6796 7.0798C10.6796 8.28364 9.70369 9.25957 8.49981 9.25957C7.29598 9.25957 6.32006 8.28364 6.32006 7.0798Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M8.4999 1.90002C4.85482 1.90002 1.8999 4.85494 1.8999 8.50002C1.8999 12.1451 4.85482 15.1 8.4999 15.1C12.145 15.1 15.0999 12.1451 15.0999 8.50002C15.0999 4.85494 12.145 1.90002 8.4999 1.90002ZM3.0999 8.50002C3.0999 5.51769 5.51756 3.10002 8.4999 3.10002C11.4822 3.10002 13.8999 5.51769 13.8999 8.50002C13.8999 11.4824 11.4822 13.9 8.4999 13.9C5.51756 13.9 3.0999 11.4824 3.0999 8.50002Z" fill="currentColor"/>
    </svg>    
  );
};

User.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

User.defaultProps = {
  color: 'currentColor', // Or any default color
};