import PropTypes from "prop-types";
import React from "react";

export const Backblaze = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  // const style = color !== 'currentColor' ? { color } : undefined;
  const style = color !== 'currentColor' ? { color } : { color: '#E21E29' };

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"
    >
      <path d="M7.73346 1.34766C7.92534 1.74482 8.1565 2.32851 8.1565 3.03625C8.1565 5.76371 5.21484 6.50626 5.21484 8.98918C5.21484 10.2172 6.10823 11.5908 7.53889 11.5908C8.82765 11.5908 9.54254 10.7947 9.54254 9.63981C9.54254 8.68573 9.09272 7.94585 9.09272 7.28809C9.09272 6.27868 9.95487 5.64501 9.95487 4.63114C9.95576 3.04607 8.40729 1.76802 7.73346 1.34766Z" fill="currentColor"/>
      <path d="M14.0811 11.9594C14.0811 13.7203 12.7432 14.4798 11.7481 14.4798C10.911 14.4798 9.85156 13.947 9.85156 12.4788C9.85156 11.2677 10.6004 11.2302 10.6004 9.88703C10.6004 9.1418 10.0711 8.26894 10.0711 7.46213C10.0711 6.40185 11.1653 5.4567 11.7383 5.18359C11.5196 5.4201 11.3117 6.05734 11.3117 6.40452C11.3108 8.37068 14.0811 9.46042 14.0811 11.9594Z" fill="currentColor"/>
      <path d="M10.0988 15.046C8.53163 14.1955 8.97699 12.7104 8.76725 12.5363C8.55752 12.3614 7.8105 12.8817 6.4789 12.4292C5.39363 12.0588 5.00272 11.0343 4.93578 11.0352C4.85813 11.0352 4.82422 11.5974 4.82422 11.9196C4.82422 14.3775 6.75022 16.6516 9.47589 16.6516C11.6464 16.6516 13.0485 15.0942 12.9709 15.0282C12.8843 14.9541 11.343 15.7207 10.0988 15.046Z" fill="currentColor"/>
    </svg>    
  );
};

Backblaze.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Backblaze.defaultProps = {
  color: 'currentColor', // Or any default color
};