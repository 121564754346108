import { useCallback } from 'react';
import { useAuth } from '../context/AuthContext';

const server_prefix = process.env.REACT_APP_SERVER_PREFIX || '/backend';

// A utility function to make fetch calls with the access token
async function fetchWithToken(url, token, options = {}) {
    try {
        const response = await fetch(`${server_prefix}/${url}`, {
            ...options,
            headers: {
                ...options.headers,
                Authorization: `Bearer ${token}`,
            },
        });

        const responseBody = await response.json(); // Parse the JSON body upfront for any type of response

        if (!response.ok) {
            // Convert detail if it's an array of objects
            const detailMessage = Array.isArray(responseBody.detail)
                ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
                : responseBody.detail || `HTTP error: ${response.status} ${response.statusText}`;

            return {
                success: false,
                error: responseBody.error || 'unknown_error',
                detail: detailMessage,
            };
        }

        if (responseBody.error && responseBody.error !== 0) {
            // Assume responseBody.detail is well-formed here; include check if necessary
            const detailMessage = Array.isArray(responseBody.detail)
                ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
                : responseBody.detail || 'An error occurred';

            return {
                success: false,
                error: responseBody.error,
                detail: detailMessage,
            };
        }

        // If the response is successful and contains no server-reported error code
        return { success: true, data: responseBody.data, detail: responseBody.detail };

    } catch (error) {
        return {
            success: false,
            error: 1, // Using '1' as a generic error code for exceptions
            detail: error.message || 'Failed to complete request due to an unexpected error',
        };
    }
}

export function useBackend() {
    const { getToken } = useAuth();
    
    const getS3config = useCallback(async () => {
      const token = await getToken();
      return fetchWithToken(`config/s3`, token);
    }, [getToken]);   

    const enableMediaStorage = useCallback(async () => {
        const token = await getToken();
        return fetchWithToken(`storage/enable`, token, { method: 'POST' });
    }, [getToken]);      
  
    return {
        getS3config,
        enableMediaStorage
    };
}