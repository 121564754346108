import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputField } from 'components/InputField';
import { FormCheckbox } from 'components/FormCheckbox';
import { Button } from 'components/Button';
import { Select } from 'components/Select';
import styles from '../../index.module.css'

const digitalOceanRegionOptions = [
    { label: 'Amsterdam 2 (the Netherlands)', value: 'ams2' },
    { label: 'Amsterdam 3 (the Netherlands)', value: 'ams3' },
    { label: 'Bangalore (India)', value: 'blr1' },
    { label: 'Frankfurt (Germany)', value: 'fra1' },
    { label: 'London (United Kingdom)', value: 'lon1' },
    { label: 'New York City 1 (USA)', value: 'nyc1' },
    { label: 'New York City 3 (USA)', value: 'nyc3' },
    { label: 'San Francisco 1 (USA)', value: 'sfo1' },
    { label: 'San Francisco 2 (USA)', value: 'sfo2' },
    { label: 'Singapore', value: 'sgp1' },
    { label: 'Toronto (Canada)', value: 'tor1' }
];  

export const DigitaloceanForm = ({ storageCredentials, onChange, onSave, onCancel }) => {
    const { 
        bucket_name, region_name, access_key_id, secret_access_key, public_permissions 
    } = storageCredentials.digitalocean;

    const [validationErrors, setValidationErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        onChange({
            target: {
                name: name,
                value: value
            }
        })

        const [, key] = name.split('.');

        // Clear validation errors when input changes
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [key]: null,
        }));
    };

    const handleSave = () => {
        const errors = {};

        if (!bucket_name) {
            errors.bucket_name = 'Bucket name is required';
        }

        if (!region_name) {
            errors.region_name = 'Region name is required';
        }

        if (!access_key_id) {
            errors.access_key_id = 'Access Key ID is required';
        }
        if (!secret_access_key) {
            errors.secret_access_key = 'Secret Access Key is required';
        }

        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
        } else {
            onSave();
        }
    };

    // enable button when all input fields are set
    const isSaveDisabled = !bucket_name || !region_name || !access_key_id || !secret_access_key;

    return (
        <div className={styles.storageFormContainer}>

            <div className={styles.formRow}>
                <InputField
                    inputText={bucket_name}
                    onChange={handleInputChange}
                    name="digitalocean.bucket_name"
                    placeholder=""
                    label="Bucket Name"
                    state={validationErrors.bucket_name ? 'error' : 'default'}
                    hint={validationErrors.bucket_name || ''}
                />  
                <Select
                    onChange={handleInputChange}                                        
                    name="digitalocean.region_name"
                    label="Region name"
                    placeholder='Select region'
                    options={digitalOceanRegionOptions}
                    value={region_name}
                    state={validationErrors.region_name ? 'error' : 'default'}
                    hint={validationErrors.region_name || ''}                    
                />                                               
            </div>    

            <div className={styles.formRow}>
                <InputField
                    inputText={access_key_id}
                    onChange={handleInputChange}
                    name="digitalocean.access_key_id"
                    placeholder=""
                    label="Access Key ID"
                    autocomplete="off"
                    state={validationErrors.access_key_id ? 'error' : 'default'}
                    hint={validationErrors.access_key_id || ''}
                />  
                <InputField
                    inputText={secret_access_key}
                    onChange={handleInputChange}
                    name="digitalocean.secret_access_key"
                    placeholder=""
                    label="Secret Access Key"
                    type="password"
                    autocomplete="new-password"
                    state={validationErrors.secret_access_key ? 'error' : 'default'}
                    hint={validationErrors.secret_access_key || ''}
                />                                              
            </div>        

            <div className={styles.formRow}>
                <FormCheckbox
                    name="digitalocean.public_permissions"
                    checked={public_permissions || false}
                    label="Public permissions"
                    onChange={handleInputChange}
                />
            </div>   


            <div className={styles.formFooter}>
                <Button 
                    size="sm"
                    type="secondary"
                    onClick={onCancel}
                >
                    Cancel
                </Button>                 
                <Button size="sm" onClick={handleSave} state={isSaveDisabled ? "disabled" : undefined}>
                    Save
                </Button>
            </div>
        </div>
    );
};

DigitaloceanForm.propTypes = {
    storageCredentials: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};

