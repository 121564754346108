import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputField } from 'components/InputField';
import { Button } from 'components/Button';
import styles from '../../index.module.css'

export const AzureForm = ({ storageCredentials, onChange, onSave, onCancel }) => {
    const { bucket_name, access_key_id, secret_access_key, azure_host } = storageCredentials.azure;

    const [validationErrors, setValidationErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        onChange({
            target: {
                name: name,
                value: value
            }
        })

        const [, key] = name.split('.');

        // Clear validation errors when input changes
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [key]: null,
        }));
    };

    const handleSave = () => {
        const errors = {};

        if (!bucket_name) {
            errors.bucket_name = 'Bucket name is required';
        }
        if (!access_key_id) {
            errors.access_key_id = 'Access Key ID is required';
        }
        if (!secret_access_key) {
            errors.secret_access_key = 'Secret Access Key is required';
        }
        if (!azure_host) {
            errors.azure_host = 'Host is required';
        }

        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
        } else {
            onSave();
        }
    };

    // enable button when all input fields are set
    const isSaveDisabled = !bucket_name || !access_key_id || !secret_access_key || !azure_host;

    return (
        <div className={styles.storageFormContainer}>
            <div className={styles.formRow}>
                <InputField
                    inputText={bucket_name}
                    onChange={handleInputChange}
                    name="azure.bucket_name"
                    placeholder=""
                    label="Bucket Name"
                    state={validationErrors.bucket_name ? 'error' : 'default'}
                    hint={validationErrors.bucket_name || ''}
                />
                <InputField
                    inputText={azure_host}
                    onChange={handleInputChange}
                    name="azure.azure_host"
                    placeholder=""
                    label="Host"
                    state={validationErrors.azure_host ? 'error' : 'default'}
                    hint={validationErrors.azure_host ? validationErrors.azure_host : undefined}
                />                  
            </div>

            <div className={styles.formRow}>
                <InputField
                    inputText={access_key_id}
                    onChange={handleInputChange}
                    name="azure.access_key_id"
                    placeholder=""
                    label="Access Key ID"
                    autocomplete="off"
                    state={validationErrors.access_key_id ? 'error' : 'default'}
                    hint={validationErrors.access_key_id || ''}
                />
                <InputField
                    inputText={secret_access_key}
                    onChange={handleInputChange}
                    name="azure.secret_access_key"
                    placeholder=""
                    label="Secret Access Key"
                    type="password"
                    autocomplete="new-password"
                    state={validationErrors.secret_access_key ? 'error' : 'default'}
                    hint={validationErrors.secret_access_key || ''}
                />
            </div>


            <div className={styles.formFooter}>
                <Button 
                    size="sm"
                    type="secondary"
                    onClick={onCancel}
                >
                    Cancel
                </Button>                 
                <Button size="sm" onClick={handleSave} state={isSaveDisabled ? "disabled" : undefined}>
                    Save
                </Button>
            </div>
        </div>
    );
};

AzureForm.propTypes = {
    storageCredentials: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};



