import React from 'react';
import styles from './index.module.css';
import Team from './Team';
import AccountSettings from './AccountSettings';
import Organization from './Organization';
import { Tabs } from 'components/Tabs'
import { User, Company, Teams } from 'icons'; // Assuming you have these icons

// Define a mapping of roles to accessible tabs
const roleBasedTabs = {
    owner: ['account', 'organization', 'team'], // Example: owner has access to all tabs
    admin: ['account', 'organization', 'team'],
    developer: ['account'],
    billing: ['account']
};

// Define the settings tabs
const settingsTabs = [
    { name: 'account', icon: <User />, label: 'Account', component: <AccountSettings /> },
    { name: 'organization', icon: <Company />, label: 'Organization', component: <Organization /> },
    { name: 'team', icon: <Teams />, label: 'Team', component: <Team /> },
];

// SettingsPage component
export const SettingsPage = ({ userRole }) => {
    // Filter tabs based on user role
    const accessibleTabs = settingsTabs.filter(tab => 
        roleBasedTabs[userRole]?.includes(tab.name)
    );

    return (
        <div className={styles.contentContainer}>
            <Tabs tabs={accessibleTabs} basePath="/settings" />
        </div>
    );
};


// import React from 'react';

// import styles from './index.module.css';

// import {  User, Company, Teams } from '../../icons'

// import { Tabs } from '../../components/Tabs';

// import AccountSettings from './AccountSettings'
// import Organization from './Organization'
// import Team from './Team'

// const settingsTabs = [
//     { name: 'account', icon: <User />, label: 'Account', component: <AccountSettings /> },
//     { name: 'organization', icon: <Company />, label: 'Organization', component: <Organization /> },
//     { name: 'team', icon: <Teams />, label: 'Team', component: <Team /> },
// ];

// export const SettingsPage = ({userRole}) => {

//   return (
//       <div className={styles.contentContainer}>
//         <Tabs tabs={settingsTabs} basePath="/settings" />
//       </div>
//   );
// };