import React from 'react';
import PropTypes from 'prop-types';
import style from "pages/StatisticsPage/components/Chart/style.module.css";
import {ReactComponent as ChartImage} from 'assets/images/Chart.svg';
import {format} from "date-fns";
import {NoData} from "components/NoData";


const verticalValues = [
  '0.00',
  '10.00',
  '20.00',
  '30.00',
  '40.00',
  '50.00',
].reverse();

function NoChartData({
  startDate,
  endDate,
}) {
  return (
    <div className={style.wrapper}>
      <div className={style.vertical}>
          <span>
          
          </span>
        <div className={style.verticalControls}>
          {verticalValues.map( ( item ) => <span key={item} className={style.verticalLabel}>{item}</span> )}
        </div>
      </div>
      <div className={style.horizontal}>
        <span>{format( startDate, 'MMM dd, yyyy' ).toUpperCase()}</span>
        <span>{format( endDate, 'MMM dd, yyyy' ).toUpperCase()}</span>
      </div>
      <div className={style.content}>
        <NoData
          image={<ChartImage/>}
          title="No data found"
          description="There is no usage data for the selected period."
        />
      </div>
    </div>
  )
}

NoChartData.propTypes = {
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
};

export default NoChartData;