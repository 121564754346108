
// this works but doesn't hide 
import React, { useEffect, useRef, useState } from 'react';
//import { Select } from 'components/Select';
import { Checkbox } from 'components/Checkbox';
import { 
    //Save, Launch, 
    Copy, Check 
} from 'icons';
import styles from '../index.module.css';

// import Tippy from '@tippyjs/react';
// import 'tippy.js/dist/tippy.css';

import { Button } from 'components/Button'; 

import { highlightJson } from 'helpers/highlightJson'

export const OutputJSON = ({ 
    queryObject, 
    //handleSaveTemplate, handleLaunchTranscoding, templateMenuOptions, selectedProject, videoSrc 
}) => {
    const codeRef = useRef(null);
    const [minify, setMinify] = useState(false);
    // const [tooltipContent, setTooltipContent] = useState('Copy');
    //const tooltipContent = 'Copy';
    const [copied, setCopied] = useState(false);
    //const tippyInstance = useRef(null);
    //const buttonRef = useRef(null); // Reference for the button

    useEffect(() => {
        //console.log("queryObject: ", queryObject);
        if (codeRef.current && queryObject) {
            codeRef.current.innerHTML = highlightJson(queryObject, minify);
        }
    }, [queryObject, minify]);

    const handleCheckboxChange = (event) => {
        setMinify(event.target.checked);
    };

    const handleCopyClick = () => {
        if (codeRef.current) {
          const range = document.createRange();
          range.selectNodeContents(codeRef.current);
          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(range);
          try {
            const json = codeRef.current.innerText;
            navigator.clipboard.writeText(json)
              .then(() => {
                console.log("JSON copied to clipboard");
                //setTooltipContent('Copied!');
                setCopied(true);
                // if (tippyInstance.current) {
                //   tippyInstance.current.show();
                // }
                setTimeout(() => {
                    //setTooltipContent('Copy');
                    setCopied(false);
                    // if (tippyInstance.current) {
                    //     tippyInstance.current.hide();
                    // }
                }, 1000); // Reset after 1 seconds                
              })
              .catch((err) => {
                console.error('Failed to copy JSON:', err);
              });
          } catch (err) {
            console.error('Failed to copy JSON:', err);
          }
          selection.removeAllRanges(); // Deselect the text after copying
        }
    };  

    return (
        <div className={styles.jsonContainer}>
            <div className={styles.jsonHeader}>
                <div className={styles.jsonTitle}>
                    Your JSON
                </div>
                <div className={styles.jsonControls}>
                    <Checkbox
                        label="Minify JSON"
                        checked={minify}
                        onChange={handleCheckboxChange}
                    />
                    <div className={styles.jsonTemplateControls}>


                        {/* <Tippy
                            content={tooltipContent}
                            onCreate={(instance) => (tippyInstance.current = instance)}
                            //visible={true} // Keep the tooltip always visible
                        >
                            <Button              
                                //ref={buttonRef} // Set the button ref
                                className={styles.jsonCopyBtn}              
                                //icon={<Copy label="Copy" />}
                                icon={copied ? <Check label="Copied" className={styles.copiedIcon} /> : <Copy label="Copy" className={styles.copyIcon}/>}
                                onClick={handleCopyClick}
                                type='secondary'
                                size='sm'
                            >
                                {copied ? <>Copied</> : <>Copy</>}
                            </Button>   
                        </Tippy> */}

                        <Button              
                            //ref={buttonRef} // Set the button ref
                            className={styles.jsonCopyBtn}              
                            //icon={<Copy label="Copy" />}
                            icon={copied ? <Check label="Copied" className={styles.copiedIcon} /> : <Copy label="Copy" className={styles.copyIcon}/>}
                            onClick={handleCopyClick}
                            type='secondary'
                            size='sm'
                        >
                            {copied ? <>Copied</> : <>Copy</>}
                        </Button>                           


                        {/* <Select                        
                            className={styles.transcodingControl}
                            variant='menu'
                            icon={<Save />}
                            placeholder='Save Template'
                            onChange={handleSaveTemplate}
                            options={templateMenuOptions}
                            size='sm'
                        /> */}
                        {/* <Button
                            className={styles.transcodingControl}
                            icon={<Launch label="Launch Job" />}
                            onClick={handleLaunchTranscoding}
                            state={!selectedProject?.api_key || !videoSrc ? "disabled" : undefined}
                            size='sm'
                        >
                            Launch Job
                        </Button>                           */}
                    </div>
                </div>
            </div>
            <div className={styles.jsonBody}>
                <pre className={styles.json}>
                    <code ref={codeRef}></code>
                </pre>
            </div>          
        </div>
    );
};





// hiding secret but json is not updated to latest
// import React, { useEffect, useRef, useState } from 'react';
// import { Select } from 'components/Select';
// import { Checkbox } from 'components/Checkbox';
// import { Save, Launch, Copy, Check } from 'icons';
// import styles from '../index.module.css';

// import Tippy from '@tippyjs/react';
// import 'tippy.js/dist/tippy.css';
// import { Button } from 'components/Button'; // Ensure to import your Button correctly

// const highlightJson = (json, minify = false) => {
//     if (typeof json !== 'string') {
//         json = minify ? JSON.stringify(json) : JSON.stringify(json, undefined, 2);
//     }
//     json = json.replace(/&/g, '&').replace(/</g, '<').replace(/>/g, '>');
//     return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d*\.?\d+(?:[eE][+-]?\d+)?)/g, function (match) {
//         let cls = styles.number;
//         if (/^"/.test(match)) {
//             if (/:$/.test(match)) {
//                 cls = styles.key;
//             } else {
//                 cls = styles.string;
//             }
//         } else if (/true|false/.test(match)) {
//             cls = styles.boolean;
//         } else if (/null/.test(match)) {
//             cls = styles.null;
//         }
//         return `<span class="${cls}">${match}</span>`;
//     });
// };

// const maskSecrets = (jsonObj) => {
//     const clonedObj = JSON.parse(JSON.stringify(jsonObj));
    
//     const maskField = (obj) => {
//         for (let key in obj) {
//             if (obj.hasOwnProperty(key)) {
//                 if (typeof obj[key] === 'object' && obj[key] !== null) {
//                     maskField(obj[key]);
//                 } else if (key === 'secret') {
//                     obj[key] = '***********';
//                 }
//             }
//         }
//     };

//     maskField(clonedObj);
//     return clonedObj;
// };

// export const OutputJSON = ({ queryObject, handleSaveTemplate, handleLaunchTranscoding, templateMenuOptions, selectedProject }) => {
//     const codeRef = useRef(null);
//     const [minify, setMinify] = useState(false);
//     const [tooltipContent, setTooltipContent] = useState('Click to Copy');
//     const [copied, setCopied] = useState(false);
//     const tippyInstance = useRef(null);
//     const originalJsonRef = useRef(queryObject); // Store the original JSON

//     useEffect(() => {
//         console.log("queryObject: ", queryObject);
//         if (codeRef.current) {
//             const maskedJson = maskSecrets(queryObject);
//             codeRef.current.innerHTML = highlightJson(maskedJson, minify);
//         }
//     }, [queryObject, minify]);

//     const handleCheckboxChange = (event) => {
//         setMinify(event.target.checked);
//     };

//     const handleCopyClick = () => {
//         if (codeRef.current) {
//             try {
//                 const originalJson = minify ? JSON.stringify(originalJsonRef.current) : JSON.stringify(originalJsonRef.current, undefined, 2);
//                 navigator.clipboard.writeText(originalJson)
//                     .then(() => {
//                         console.log("JSON copied to clipboard");
//                         setTooltipContent('Copied!');
//                         setCopied(true);
//                         if (tippyInstance.current) {
//                             tippyInstance.current.show();
//                         }
//                         setTimeout(() => {
//                             setTooltipContent('Click to Copy');
//                             setCopied(false);
//                         }, 1000); // Reset after 1 seconds
//                     })
//                     .catch((err) => {
//                         console.error('Failed to copy JSON:', err);
//                     });
//             } catch (err) {
//                 console.error('Failed to copy JSON:', err);
//             }
//         }
//     };

//     return (
//         <div className={styles.jsonContainer}>
//             <div className={styles.jsonHeader}>
//                 <div className={styles.jsonTitle}>
//                     Your JSON
//                 </div>
//                 <div className={styles.jsonControls}>
//                     <Checkbox
//                         label="Minify JSON"
//                         checked={minify}
//                         onChange={handleCheckboxChange}
//                     />
//                     <div className={styles.jsonTemplateControls}>
//                         <Tippy
//                             content={tooltipContent}
//                             onCreate={(instance) => (tippyInstance.current = instance)}
//                         >
//                             <Button
//                                 className={styles.jsonCopyBtn}
//                                 icon={copied ? <Check label="Copied" className={styles.copiedIcon} /> : <Copy label="Copy" />}
//                                 onClick={handleCopyClick}
//                                 type='secondary'
//                                 size='sm'
//                             />
//                         </Tippy>
//                         <Select
//                             className={styles.transcodingControl}
//                             variant='menu'
//                             icon={<Save />}
//                             placeholder='Save Template'
//                             onChange={handleSaveTemplate}
//                             options={templateMenuOptions}
//                             size='sm'
//                         />
//                         <Button
//                             className={styles.transcodingControl}
//                             icon={<Launch label="Launch Job" />}
//                             onClick={handleLaunchTranscoding}
//                             state={!selectedProject?.api_key ? "disabled" : undefined}
//                             size='sm'
//                         >
//                             Launch Job
//                         </Button>
//                     </div>
//                 </div>
//             </div>
//             <div className={styles.jsonBody}>
//                 <pre className={styles.json}>
//                     <code ref={codeRef}></code>
//                 </pre>
//             </div>
//         </div>
//     );
// };







// import React, { useEffect, useRef, useState } from 'react';
// import { Select } from 'components/Select';
// import { Button } from 'components/Button';
// import { Checkbox } from 'components/Checkbox';
// import { Save, Launch, Copy } from 'icons';
// import styles from '../index.module.css';

// import Tippy from '@tippyjs/react';
// import 'tippy.js/dist/tippy.css';

// const highlightJson = (json, minify = false) => {
//     if (typeof json !== 'string') {
//         json = minify ? JSON.stringify(json) : JSON.stringify(json, undefined, 2);
//     }
//     json = json.replace(/&/g, '&').replace(/</g, '<').replace(/>/g, '>');
//     return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d*\.?\d+(?:[eE][+-]?\d+)?)/g, function (match) {
//         let cls = styles.number;
//         if (/^"/.test(match)) {
//             if (/:$/.test(match)) {
//                 cls = styles.key;
//             } else {
//                 cls = styles.string;
//             }
//         } else if (/true|false/.test(match)) {
//             cls = styles.boolean;
//         } else if (/null/.test(match)) {
//             cls = styles.null;
//         }
//         return `<span class="${cls}">${match}</span>`;
//     });
// };

// export const OutputJSON = ({ queryObject, handleSaveTemplate, handleLaunchTranscoding, templateMenuOptions, selectedProject }) => {
//     const codeRef = useRef(null);
//     const [minify, setMinify] = useState(false);
//     const [tooltipContent, setTooltipContent] = useState('Click to Copy');

//     useEffect(() => {
//         console.log("queryObject: ", queryObject)
//         if (codeRef.current) {
//             codeRef.current.innerHTML = highlightJson(queryObject, minify);
//         }
//     }, [queryObject, minify]);
 
//     const handleCheckboxChange = (event) => {
//         setMinify(event.target.checked);
//     };

//     // const handleCopyClick = () => {
//     //     if (codeRef.current) {
//     //         const range = document.createRange();
//     //         range.selectNodeContents(codeRef.current);
//     //         const selection = window.getSelection();
//     //         selection.removeAllRanges();
//     //         selection.addRange(range);
//     //         try {
//     //             const json = codeRef.current.innerText;
//     //             navigator.clipboard.writeText(json)
//     //                 .then(() => {
//     //                     console.log("JSON copied to clipboard");
//     //                 })
//     //                 .catch((err) => {
//     //                     console.error('Failed to copy JSON:', err);
//     //                 });
//     //         } catch (err) {
//     //             console.error('Failed to copy JSON:', err);
//     //         }
//     //         selection.removeAllRanges(); // Deselect the text after copying
//     //     }
//     // };

//     const handleCopyClick = () => {
//         if (codeRef.current) {
//             const range = document.createRange();
//             range.selectNodeContents(codeRef.current);
//             const selection = window.getSelection();
//             selection.removeAllRanges();
//             selection.addRange(range);
//             try {
//                 const json = codeRef.current.innerText;
//                 navigator.clipboard.writeText(json)
//                     .then(() => {
//                         console.log("JSON copied to clipboard");
//                         setTooltipContent('Copied!');
//                         setTimeout(() => setTooltipContent('Click to Copy'), 2000); // Reset after 2 seconds
//                     })
//                     .catch((err) => {
//                         console.error('Failed to copy JSON:', err);
//                     });
//             } catch (err) {
//                 console.error('Failed to copy JSON:', err);
//             }
//             selection.removeAllRanges(); // Deselect the text after copying
//         }
//     };    

//     return (
//         <div className={styles.jsonContainer}>
//             <div className={styles.jsonHeader}>
//                 <div className={styles.jsonTitle}>
//                     Your JSON
//                 </div>
//                 <div className={styles.jsonControls}>
//                     <Checkbox
//                         label="Minify JSON"
//                         checked={minify}
//                         onChange={handleCheckboxChange}
//                     />
//                     <div className={styles.jsonTemplateControls}>                     
//                         {/* <Button              
//                             className={styles.jsonCopyBtn}              
//                             icon={<Copy label="Copy" />}
//                             onClick={handleCopyClick}
//                             type='secondary'
//                         /> */}
//                         <Tippy content={tooltipContent}>
//                             <Button              
//                                 className={styles.jsonCopyBtn}              
//                                 icon={<Copy label="Copy" />}
//                                 onClick={handleCopyClick}
//                                 type='secondary'
//                             />
//                         </Tippy>                        
//                         <Select                        
//                             className={styles.transcodingControl}
//                             variant='menu'
//                             icon={<Save />}
//                             placeholder='Save Template'
//                             onChange={handleSaveTemplate}
//                             options={templateMenuOptions}
//                         />
//                         <Button
//                             className={styles.transcodingControl}
//                             icon={<Launch label="Launch Job" />}
//                             onClick={handleLaunchTranscoding}
//                             state={!selectedProject?.api_key ? "disabled" : undefined}
//                         >
//                             Launch Job
//                         </Button>                          
//                     </div>
//                 </div>
//             </div>
//             <div className={styles.jsonBody}>
//                 <pre className={styles.json}>
//                     <code ref={codeRef}></code>
//                 </pre>
//             </div>          
//         </div>
//     );
// };

