// https://docs.qencode.com/tutorials/media-storage/qencode-s3/using-qencode-s3/
// https://docs.aws.amazon.com/sdk-for-javascript/v3/developer-guide/welcome.html
// https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/

import { useCallback } from 'react';
import { Upload } from "@aws-sdk/lib-storage";
import { 
    S3Client, 
    ListObjectsV2Command, 
    CreateMultipartUploadCommand, 
    UploadPartCommand, 
    CompleteMultipartUploadCommand, 
    AbortMultipartUploadCommand,
    CreateBucketCommand,
    DeleteObjectCommand,
    DeleteObjectsCommand,
    CopyObjectCommand,
    HeadObjectCommand,
    UploadPartCopyCommand
    //PutObjectCommand  
} from "@aws-sdk/client-s3";

import config from 'config';

// const s3url = process.env.REACT_APP_S3_URL || 's3-qa.qencode.com';
const { s3Subdomain, mediaStorageSubdomain, domain } = config;
const s3url = `${s3Subdomain}.${domain}`;

const getBaseName = (path) => {
    const parts = path.split('/');
    return parts[parts.length - 1] || parts[parts.length - 2];
};

// Function to create a customized S3 client for Qencode
const createQencodeS3Client = (config) => {
    return new S3Client({
        region: config.region, // 'us-east-1' or other regions as per Qencode's setup
        credentials: {
            accessKeyId: config.access_id,
            secretAccessKey: config.secret_key,
        },
        //endpoint: config.endpoint, // Qencode S3 endpoint
        endpoint: `https://${config.region}.${s3url}`, // Base URL only, without bucket name
        // forcePathStyle: true // Important for some S3 compatible services
        forcePathStyle: false // Set to false to prevent bucket name being appended as a path
    });
};

export function useAWS() {

    // https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/client/s3/command/ListObjectsV2Command/
    // use ContinuationToken for pagination since it only returns up to 1000 objects
    // also check NextContinuationToken

    // this is GOOD - filtering empty folder in files
    // https://hello-00012.media-storage.us-west.qencode.com/video_1_eye.webm
    const getItemsForQencodeBucket = useCallback(async (bucketName, config, prefix = '', delimiter = '/') => {
        const s3Client = createQencodeS3Client(config);
        let continuationToken = null;
        let allFiles = [];
        let allFolders = [];

        do {
            const command = new ListObjectsV2Command({
                Bucket: bucketName,
                Prefix: prefix,
                Delimiter: delimiter,
                ContinuationToken: continuationToken
            });

            try {
                const data = await s3Client.send(command);

                // Check for empty folder condition and map items accordingly
                // if ends with '/' and has size 0 - it's an empty folder, so filter it out
                const files = (data.Contents || []).filter(item => !(item.Key.endsWith('/') && item.Size === 0)).map(item => ({
                    name: item.Key,
                    displayName: getBaseName(item.Key),
                    prefix: prefix,
                    type: 'file',
                    size: item.Size,
                    lastModified: item.LastModified,
                    //sourceUrl: `https://${bucketName}.${config.region}.${s3url}/${item.Key}`
                    sourceUrl: `https://${bucketName}.${mediaStorageSubdomain}.${config.region}.${domain}/${item.Key}`
                }));                

                const folders = (data.CommonPrefixes || []).map(folder => ({
                    name: folder.Prefix,
                    displayName: getBaseName(folder.Prefix),
                    prefix: prefix,
                    type: 'folder'
                }));

                allFiles = allFiles.concat(files);
                allFolders = allFolders.concat(folders);

                // Check if there is a next page of results
                continuationToken = data.IsTruncated ? data.NextContinuationToken : null;
            } catch (error) {
                console.error('Error fetching items from Qencode S3:', error);
                return { success: false, error: error?.message || String(error) };
            }
        } while (continuationToken);

        const allItems = allFiles.concat(allFolders);
        return { success: true, items: allItems.length > 0 ? allItems : [] };
    }, []); // Dependencies array can be empty if config and bucketName are static

    // multipart upload with chunks no less than 5MB, about 30 chunks, each chunk no more tha 1.6GB, file size less 50GB
    const uploadFileToQencodeS3 = useCallback(async (bucketName, file, config, onProgress, signal, fileName) => {
        const s3Client = createQencodeS3Client(config);
        let uploadId = null;

        const key = fileName || file.name;
        //const sourceUrl = `https://${bucketName}.${config.region}.${s3url}/${key}`;
        const sourceUrl = `https://${bucketName}.${mediaStorageSubdomain}.${config.region}.${domain}/${key}`

        const maxPartSize = 1.6 * 1024 * 1024 * 1024; // 1.6GB
        const minPartSize = 5 * 1024 * 1024; // 5MB
        const maxFileSize = 50 * 1024 * 1024 * 1024; // 50GB

        if (file.size > maxFileSize) {
            return { success: false, error: 'File size is too big' };
        }

        // Determine part size to aim for around 30 parts
        const targetPartSize = Math.ceil(file.size / 30);
        const partSize = Math.min(Math.max(targetPartSize, minPartSize), maxPartSize);

        const createMultipartUpload = new CreateMultipartUploadCommand({
            Bucket: bucketName,
            Key: key,
            ContentType: file.type
        });

        try {
            const multipartUploadResponse = await s3Client.send(createMultipartUpload);
            uploadId = multipartUploadResponse.UploadId;
            const totalParts = Math.ceil(file.size / partSize);
            let uploadedSize = 0;
            const parts = [];

            for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
                const start = (partNumber - 1) * partSize;
                const end = Math.min(start + partSize, file.size);
                const part = file.slice(start, end);

                const uploadPart = new UploadPartCommand({
                    Bucket: bucketName,
                    Key: key,
                    PartNumber: partNumber,
                    UploadId: uploadId,
                    Body: part
                });

                const { ETag } = await s3Client.send(uploadPart, { abortSignal: signal });
                parts.push({ PartNumber: partNumber, ETag });

                uploadedSize += part.size;
                const percentage = Math.min(99, Math.round((uploadedSize / file.size) * 100));
                if (onProgress) {
                    onProgress(percentage);
                }

                if (signal.aborted) {
                    throw new Error('Upload aborted');
                }
            }

            const completeMultipartUpload = new CompleteMultipartUploadCommand({
                Bucket: bucketName,
                Key: key,
                UploadId: uploadId,
                MultipartUpload: { Parts: parts }
            });

            await s3Client.send(completeMultipartUpload);

            if (onProgress) {
                onProgress(100); // Ensure 100% progress is reported once the upload is fully complete
            }

            return { success: true, sourceUrl, path: key };

        } catch (error) {
            if (uploadId) {
                const abortMultipartUpload = new AbortMultipartUploadCommand({
                    Bucket: bucketName,
                    Key: key,
                    UploadId: uploadId
                });
                await s3Client.send(abortMultipartUpload);
            }
            if (error.name === 'AbortError') {
                console.log('Upload aborted');
                return { success: false, error: 'Stopped' };
            } else {
                console.error('Error uploading file to Qencode S3:', error);
                return { success: false, error: error?.message || String(error) };
            }
        }
    }, []);

    // don't need this since bucket is created via Qencode API
    const createNewQencodeBucket = useCallback(async (bucketName, region, config) => {
        const s3Client = createQencodeS3Client(config);

        const input = {
            Bucket: bucketName,
            CreateBucketConfiguration: {
                LocationConstraint: region,
            }
        };

        const createBucketCommand = new CreateBucketCommand(input);

        try {
            const data = await s3Client.send(createBucketCommand);
            return { success: true, data };
        } catch (error) {
            console.error('Error creating new bucket in Qencode S3:', error);
            return { success: false, error: error?.message || String(error) };
        }
    }, []);


    // Function to get the number of items in a bucket
    const getNumberOfItemsInBucket = useCallback(async (bucketName, config, prefix = '') => {
        const s3Client = createQencodeS3Client(config);
        let continuationToken = null;
        let itemCount = 0;

        do {
            const command = new ListObjectsV2Command({
                Bucket: bucketName,
                Prefix: prefix,
                ContinuationToken: continuationToken
            });

            try {
                const data = await s3Client.send(command);
                itemCount += data.KeyCount;

                // Check if there is a next page of results
                continuationToken = data.IsTruncated ? data.NextContinuationToken : null;
            } catch (error) {
                console.error('Error fetching items from Qencode S3:', error);
                return { success: false, error: error?.message || String(error) };
            }
        } while (continuationToken);

        return { success: true, itemCount };
    }, []); // Dependencies array can be empty if config and bucketName are static    

    // Function to get the total size of items in a bucket
    const getTotalSizeOfItemsInBucket = useCallback(async (bucketName, config, prefix = '') => {
        const s3Client = createQencodeS3Client(config);
        let continuationToken = null;
        let totalSize = 0;

        do {
            const command = new ListObjectsV2Command({
                Bucket: bucketName,
                Prefix: prefix,
                ContinuationToken: continuationToken
            });

            try {
                const data = await s3Client.send(command);
                totalSize += (data.Contents || []).reduce((sum, item) => sum + item.Size, 0);

                // Check if there is a next page of results
                continuationToken = data.IsTruncated ? data.NextContinuationToken : null;
            } catch (error) {
                console.error('Error fetching items from Qencode S3:', error);
                return { success: false, error: error?.message || String(error) };
            }
        } while (continuationToken);

        return { success: true, totalSize };
    }, []); // Dependencies array can be empty if config and bucketName are static    


    // Funtion to create new folder
    const createNewFolderInQencodeBucket = useCallback(async (bucketName, folderName, config) => {
        const s3Client = createQencodeS3Client(config);
    
        try {
            // Ensure the folder name ends with a slash
            const key = folderName.endsWith('/') ? folderName : `${folderName}/`;
    
            // Use the Upload class from @aws-sdk/lib-storage
            const upload = new Upload({
                client: s3Client,
                params: {
                    Bucket: bucketName,
                    Key: key,
                    Body: '', // Empty body for folder creation
                },
            });
    
            await upload.done(); // Wait for the upload to complete
    
            return { success: true, message: 'Folder created successfully' };
        } catch (error) {
            console.error('Error creating new folder in Qencode S3:', error);
            return { success: false, error: error?.message || String(error) };
        }
    }, []);

    // Function to delete file
    const deleteFileFromQencodeBucket = useCallback(async (bucketName, key, config) => {
        const s3Client = createQencodeS3Client(config);

        try {
            const deleteCommand = new DeleteObjectCommand({
                Bucket: bucketName,
                Key: key,
            });

            await s3Client.send(deleteCommand);
            return { success: true, message: 'File deleted successfully' };
        } catch (error) {
            console.error('Error deleting file from Qencode S3:', error);
            return { success: false, error: error?.message || String(error) };
        }
    }, []);    

    // Function to delete folder
    const deleteFolderFromQencodeBucket = useCallback(async (bucketName, folderName, config) => {
        const s3Client = createQencodeS3Client(config);
    
        try {
            let continuationToken = null;
    
            do {
                // List all objects under the folder
                const listCommand = new ListObjectsV2Command({
                    Bucket: bucketName,
                    Prefix: folderName,
                    ContinuationToken: continuationToken
                });
    
                const data = await s3Client.send(listCommand);
    
                // Handle case where the folder does not exist
                if (!data.Contents) {
                    return { success: false, error: 'Folder does not exist.' };
                }
    
                // If the folder has contents (or is an "empty folder"), delete them
                const objectsToDelete = data.Contents.map(item => ({ Key: item.Key }));
    
                if (objectsToDelete.length > 0) {
                    const deleteCommand = new DeleteObjectsCommand({
                        Bucket: bucketName,
                        Delete: {
                            Objects: objectsToDelete,
                        },
                    });
    
                    await s3Client.send(deleteCommand);
                }
    
                // Check if there is a next page of results
                continuationToken = data.IsTruncated ? data.NextContinuationToken : null;
            } while (continuationToken);
    
            return { success: true, message: 'Folder and its contents deleted successfully' };
        } catch (error) {
            console.error('Error deleting folder from Qencode S3:', error);
            return { success: false, error: error?.message || String(error) };
        }
    }, []);
    
    // Function to rename file
    // const renameFileInQencodeBucket = useCallback(async (bucketName, oldKey, newKey, config) => {
    //     const s3Client = createQencodeS3Client(config);
    
    //     try {
    //         // Properly URL-encode the old key
    //         const encodedOldKey = encodeURIComponent(oldKey);
    //         const encodedCopySource = `${bucketName}/${encodedOldKey}`;

    //         // Step 1: Copy the file to the new key (rename it)
    //         const copyCommand = new CopyObjectCommand({
    //             Bucket: bucketName,
    //             //CopySource: `${bucketName}/${oldKey}`, // Source is the existing file
    //             CopySource: encodedCopySource, // Source is the existing file, properly URL-encoded
    //             Key: newKey, // Destination is the new file name
    //         });
    
    //         await s3Client.send(copyCommand);
    
    //         // Step 2: Delete the old file
    //         const deleteCommand = new DeleteObjectCommand({
    //             Bucket: bucketName,
    //             Key: oldKey,
    //         });
    
    //         await s3Client.send(deleteCommand);
    
    //         return { success: true, message: 'File renamed successfully' };
    
    //     } catch (error) {
    //         console.error('Error renaming file in Qencode S3:', error);
    //         return { success: false, error: error?.message || String(error)};
    //     }
    // }, []);

    const renameFileInQencodeBucket = useCallback(async (bucketName, oldKey, newKey, config) => {
        const s3Client = createQencodeS3Client(config);
    
        try {
            // Get the file size using HeadObjectCommand
            const headObjectCommand = new HeadObjectCommand({
                Bucket: bucketName,
                Key: oldKey,
            });
            const headObjectResponse = await s3Client.send(headObjectCommand);
            const fileSize = headObjectResponse.ContentLength;
    
            const maxSingleCopySize = 5 * 1024 * 1024 * 1024; // 5 GB
    
            if (fileSize <= maxSingleCopySize) {
                // Simple copy for files <= 5 GB
                const encodedOldKey = encodeURIComponent(oldKey);
                const copyCommand = new CopyObjectCommand({
                    Bucket: bucketName,
                    CopySource: `${bucketName}/${encodedOldKey}`,
                    Key: newKey,
                });
                await s3Client.send(copyCommand);
            } else {
                // Multipart copy for files > 5 GB
                const uploadId = await initiateMultipartUpload(bucketName, newKey, s3Client);
                await multipartCopyFileParts(bucketName, oldKey, newKey, uploadId, fileSize, s3Client);
            }
    
            // Delete the old file
            const deleteCommand = new DeleteObjectCommand({
                Bucket: bucketName,
                Key: oldKey,
            });
            await s3Client.send(deleteCommand);
    
            return { success: true, message: 'File renamed successfully' };
    
        } catch (error) {
            console.error('Error renaming file in Qencode S3:', error);
            return { success: false, error: error?.message || String(error) || error };
        }
    }, []);
    
    const initiateMultipartUpload = async (bucketName, newKey, s3Client) => {
        const createMultipartUploadCommand = new CreateMultipartUploadCommand({
            Bucket: bucketName,
            Key: newKey,
        });
        const multipartUpload = await s3Client.send(createMultipartUploadCommand);
        return multipartUpload.UploadId;
    };
    
    const multipartCopyFileParts = async (bucketName, oldKey, newKey, uploadId, fileSize, s3Client) => {
        const partSize = 5 * 1024 * 1024 * 1024; // 5 GB
        const numParts = Math.ceil(fileSize / partSize);
        const copyParts = [];
    
        for (let partNumber = 1; partNumber <= numParts; partNumber++) {
            const start = (partNumber - 1) * partSize;
            const end = Math.min(partNumber * partSize, fileSize) - 1;
    
            const uploadPartCopyCommand = new UploadPartCopyCommand({
                Bucket: bucketName,
                CopySource: `${bucketName}/${encodeURIComponent(oldKey)}`,
                Key: newKey,
                PartNumber: partNumber,
                UploadId: uploadId,
                CopySourceRange: `bytes=${start}-${end}`,
            });
    
            const uploadPartCopyResult = await s3Client.send(uploadPartCopyCommand);
            copyParts.push({
                ETag: uploadPartCopyResult.CopyPartResult.ETag,
                PartNumber: partNumber,
            });
        }
    
        const completeMultipartUploadCommand = new CompleteMultipartUploadCommand({
            Bucket: bucketName,
            Key: newKey,
            MultipartUpload: { Parts: copyParts },
            UploadId: uploadId,
        });
    
        await s3Client.send(completeMultipartUploadCommand);
    };

    // function to move file
    const moveFileInQencodeBucket = useCallback(async (bucketName, oldKey, newKey, config) => {
        return renameFileInQencodeBucket(bucketName, oldKey, newKey, config);
    }, [renameFileInQencodeBucket]);    

    // function to move folder and its content
    const moveFolderInQencodeBucket = useCallback(async (bucketName, oldPrefix, newPrefix, config) => {
        const s3Client = createQencodeS3Client(config);
    
        try {
            let continuationToken = null;
    
            do {
                // List all objects under the folder
                const listCommand = new ListObjectsV2Command({
                    Bucket: bucketName,
                    Prefix: oldPrefix,
                    ContinuationToken: continuationToken,
                });
    
                const data = await s3Client.send(listCommand);
    
                if (!data.Contents || data.Contents.length === 0) {
                    return { success: false, error: 'Folder does not exist or is empty.' };
                }
    
                // Move each object to the new prefix
                for (const item of data.Contents) {
                    const oldKey = item.Key;
                    const newKey = newPrefix + oldKey.substring(oldPrefix.length);
    
                    const renameResult = await renameFileInQencodeBucket(bucketName, oldKey, newKey, config);
                    if (!renameResult.success) {
                        return { success: false, error: `Failed to move ${oldKey}: ${renameResult.error}` };
                    }
                }
    
                // Check if there is a next page of results
                continuationToken = data.IsTruncated ? data.NextContinuationToken : null;
            } while (continuationToken);
    
            return { success: true, message: 'Folder and its contents moved successfully' };
    
        } catch (error) {
            console.error('Error moving folder in Qencode S3:', error);
            return { success: false, error: error?.message || String(error) || error};
        }
    }, [renameFileInQencodeBucket]); // Add dependencies as needed
    
    // function to remame folder
    const renameFolderInQencodeBucket = useCallback(async (bucketName, oldPrefix, newPrefix, config) => {
        const s3Client = createQencodeS3Client(config);
    
        try {
            let continuationToken = null;
    
            do {
                // List all objects under the folder
                const listCommand = new ListObjectsV2Command({
                    Bucket: bucketName,
                    Prefix: oldPrefix,
                    ContinuationToken: continuationToken,
                });
    
                const data = await s3Client.send(listCommand);
    
                if (!data.Contents || data.Contents.length === 0) {
                    return { success: false, error: 'Folder does not exist or is empty.' };
                }
    
                // Rename each object to the new prefix
                for (const item of data.Contents) {
                    const oldKey = item.Key;
                    const newKey = newPrefix + oldKey.substring(oldPrefix.length);
    
                    const renameResult = await renameFileInQencodeBucket(bucketName, oldKey, newKey, config);
                    if (!renameResult.success) {
                        return { success: false, error: `Failed to rename ${oldKey}: ${renameResult.error}` };
                    }
                }
    
                // Check if there is a next page of results
                continuationToken = data.IsTruncated ? data.NextContinuationToken : null;
            } while (continuationToken);
    
            return { success: true, message: 'Folder renamed successfully' };
    
        } catch (error) {
            console.error('Error renaming folder in Qencode S3:', error);
            return { success: false, error: error?.message || String(error) || error };
        }
    }, [renameFileInQencodeBucket]); 
        
    // function to copy file
    const copyFileInQencodeBucket = useCallback(async (bucketName, oldKey, newKey, config) => {
        const s3Client = createQencodeS3Client(config);
    
        try {
            // Get the file size using HeadObjectCommand
            const headObjectCommand = new HeadObjectCommand({
                Bucket: bucketName,
                Key: oldKey,
            });
            const headObjectResponse = await s3Client.send(headObjectCommand);
            const fileSize = headObjectResponse.ContentLength;
    
            const maxSingleCopySize = 5 * 1024 * 1024 * 1024; // 5 GB
    
            if (fileSize <= maxSingleCopySize) {
                // Simple copy for files <= 5 GB
                const encodedOldKey = encodeURIComponent(oldKey);
                const copyCommand = new CopyObjectCommand({
                    Bucket: bucketName,
                    CopySource: `${bucketName}/${encodedOldKey}`,
                    Key: newKey,
                });
                await s3Client.send(copyCommand);
            } else {
                // Multipart copy for files > 5 GB
                const uploadId = await initiateMultipartUpload(bucketName, newKey, s3Client);
                await multipartCopyFileParts(bucketName, oldKey, newKey, uploadId, fileSize, s3Client);
            }
    
            return { success: true, message: 'File copied successfully' };
    
        } catch (error) {
            console.error('Error copying file in Qencode S3:', error);
            return { success: false, error: error?.message || String(error) || error };
        }
    }, []); 
    

    return {
        // builder
        getItemsForQencodeBucket,
        uploadFileToQencodeS3,
        createNewQencodeBucket,
        getNumberOfItemsInBucket,
        getTotalSizeOfItemsInBucket,

        // bucket details
        createNewFolderInQencodeBucket,
        deleteFileFromQencodeBucket,
        deleteFolderFromQencodeBucket,
        renameFileInQencodeBucket,
        moveFileInQencodeBucket,
        moveFolderInQencodeBucket,
        renameFolderInQencodeBucket,
        copyFileInQencodeBucket
    };
}