import PropTypes from "prop-types";
import React from "react";

export const Subtract = ({ className, color, label }) => {

    // Apply inline style only if color prop is not currentColor
    const style = color !== 'currentColor' ? { color } : undefined;

    return (
        <svg
            className={className}
            role="img"
            aria-label={label}
            style={style}
            xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
        >
            <path d="M9 7.63H3.87a.87.87 0 0 0 0 1.74h9.26a.87.87 0 0 0 0-1.74H9.37Z" stroke="transparent" fill="currentColor"/>
        </svg>
    );
};

Subtract.propTypes = {
    color: PropTypes.string,
    label: PropTypes.string,
};

Subtract.defaultProps = {
    color: 'currentColor', // Or any default color
};
