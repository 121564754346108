import React, { useState, useEffect } from 'react';

import { useUser } from '../../context/UserContext' 

import { useApi } from '../../api/useApi'

import { useAuth } from 'context/AuthContext';

import { useNavigate } from 'react-router-dom';

import { InputField } from '../../components/InputField'
import { Button } from '../../components/Button';
import { Select } from '../../components/Select';
import { Toggle } from '../../components/Toggle';

import { Modal } from '../../components/Modal';

// import { validateFields } from './validation';
import { validateFields } from '../../helpers/validation';

import { ReactComponent as Success } from '../../assets/images/Success.svg';
import { ReactComponent as DeleteAccount } from '../../assets/images/DeleteAccount.svg';

import { 
    Delete as DeleteIcon,
    Google, Github 
} from 'icons';

import styles from './index.module.css';

import { timeZones } from '../../helpers/timezones';

const AccountSettings = () => {
    const { 
        deleteUser
    } = useApi();

    const { logout } = useAuth();
    const navigate = useNavigate();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [isSubmittingModal, setIsSubmittingModal] = useState(false);
    const [passwordCheckError, setPasswordCheckError] = useState(null);

    const [qrCodeUrl, setQrCodeUrl] = useState(null);

    const [isDisable2FaModalOpen, setDisable2FaModalOpen] = useState(false);
    const [isEnable2FaModalOpen, setEnable2FaModalOpen] = useState(false);
    const [isQRImageModalOpen, setQRImageModalOpen] = useState(false);
    const [isChangePassModalOpen, setChangePassModalOpen] = useState(false);
    const [isChangePassSuccessModalOpen, setChangePassSuccessModalOpen] = useState(false);

    const [isDeleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);
    const [emailCheckError, setEmailCheckError] = useState(null);

    const { 
        userData, userError, setUserError, loading, 
        updateUserAccount, disableUser2FA, verifyPassword, 
        get2FaQrCode, fetchUserData, setNewPassword 
    } = useUser(); 

    const [twoFaEnabled, setTwoFaEnabled] = useState(false);

    //console.log("userData in Account settings: ", userData)

    const [validationErrors, setValidationErrors] = useState({});

    // Initialize credentials with userData if it exists or provide a fallback
    const [credentials, setCredentials] = useState({
        email: "",
        role: "",
        first_name: "",
        last_name: "",
        phone: "",
        timezone: ''        
    });

    // New state to track changes
    const [changedFields, setChangedFields] = useState({});

    const [credentials2FA, setCredentials2FA] = useState({});

    const [credentialsNewPass, setCredentialsNewPass] = useState({});

    const [credentialsConfirmEmail, setCredentialsConfirmEmail] = useState({});

    // const handleInputChange = (e) => {
    //     if (userError) {
    //         setUserError(null);
    //     }

    //     const { name, value } = e.target;
    //     const newCredentials = { ...credentials, [name]: value };
    //     setCredentials(newCredentials);

    //     // Check if the field's new value is different from userData's value
    //     if (userData[name] !== value) {
    //         setChangedFields({ ...changedFields, [name]: value });
    //     } else {
    //         // If the field's value is reverted to the original, remove it from changedFields
    //         const updatedChangedFields = { ...changedFields };
    //         delete updatedChangedFields[name];
    //         setChangedFields(updatedChangedFields);
    //     }

    //     setValidationErrors(prevErrors => ({
    //         ...prevErrors,
    //         [name]: null
    //     }));
    // };    

    const handleLogout = () => {
        logout();
        navigate('/');
    };  

    const handleInputChange = (e) => {
        if (userError) {
            setUserError(null);
        }
    
        const { name, value } = e.target;
        // Check if the value is an empty string and set it to null if true
        const newValue = value === '' ? '' : value;
    
        const newCredentials = { ...credentials, [name]: newValue };
        setCredentials(newCredentials);
    
        // Check if the field's new value is different from userData's value
        if (userData[name] !== newValue) {
            setChangedFields({ ...changedFields, [name]: newValue });
        } else {
            // If the field's value is reverted to the original, remove it from changedFields
            const updatedChangedFields = { ...changedFields };
            delete updatedChangedFields[name];
            setChangedFields(updatedChangedFields);
        }
    
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: null
        }));
    };    

    const handleInputChange2FA = (e) => {
        // Reset the passwordCheckError when the user starts typing
        if (passwordCheckError) {
            setPasswordCheckError(null);
        }

        // Reset the userError when the user starts typing
        if (userError) {
            setUserError(null);
        }

        const { name, value } = e.target;
        setCredentials2FA({ ...credentials2FA, [name]: value });

        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: null
        }));
    };

    const handleInputChangeNewPass = (e) => {
        // Reset the passwordCheckError when the user starts typing
        if (passwordCheckError) {
            setPasswordCheckError(null);
        }

        // Reset the userError when the user starts typing
        if (userError) {
            setUserError(null);
        }

        const { name, value } = e.target;
        setCredentialsNewPass({ ...credentialsNewPass, [name]: value });

        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: null
        }));
    };
    
    const handleUpdateUserAccount = async (e) => {
        // clear error
        if (userError) {
            setUserError(null);
        }

        e.preventDefault();
        // const fieldsToValidate = Object.keys(changedFields); // Validate only changed fields
        // Only include fields that are not null in the fieldsToValidate array
        // all fields in this form are optional, so do validation only if there is some value
        const fieldsToValidate = Object.keys(changedFields).filter(key => changedFields[key] !== null);
        const { isValid, errors } = validateFields(changedFields, fieldsToValidate);
        
        setValidationErrors(errors);
        if (!isValid) {
            console.log("Validation errors", errors);
            return;
        }

        setIsSubmitting(true);
        const success = await updateUserAccount(changedFields);
        setIsSubmitting(false);

        if (success) {
            console.log("User account updated");
            // Consider resetting changedFields here if successful
            setChangedFields({});
        } else {
            console.log("Account update failure, show message, etc.");
        }
    };    

    const handleInputChangeConfirmEmail = (e) => {
        // Reset the passwordCheckError when the user starts typing
        if (emailCheckError) {
            setEmailCheckError(null);
        }

        // Reset the userError when the user starts typing
        if (userError) {
            setUserError(null);
        }

        const { name, value } = e.target;
        setCredentialsConfirmEmail({ ...credentialsConfirmEmail, [name]: value });

        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: null
        }));
    };    

    // Update the local state when userData changes
    useEffect(() => {
        if (userData) {
            // console.log("call setCredentials")
            setCredentials({
                email: userData.email || '',
                role: userData.role || '',
                first_name: userData.first_name || '',
                last_name: userData.last_name || '',
                phone: userData.phone || '',
                timezone: userData.timezone || '',
            });

            setTwoFaEnabled(!!userData.is_2fa); 

            setCredentials2FA({
                email: userData.email || '',
                password: ''
            });

            setCredentialsConfirmEmail({
                current_email: userData.email || '',
                email_confirm: ''
            });
        }
    }, [userData]);

    const closeDisable2FaModal = () => {
        setDisable2FaModalOpen(false);
        // clear password from input
        setCredentials2FA(currentCredentials => ({
            ...currentCredentials,
            password: ''
        }));
        // reset error
        if (passwordCheckError) {
            setPasswordCheckError(null);
        }
        // clear validation errors
        setValidationErrors(prevErrors => {
            return Object.keys(prevErrors).reduce((acc, key) => {
                acc[key] = null; // Set each error to null
                return acc;
            }, {});
        });
    };  
    
    const closeEnable2FaModal = () => {
        setEnable2FaModalOpen(false);
        // clear password from input
        setCredentials2FA(currentCredentials => ({
            ...currentCredentials,
            password: ''
        }));
        // reset error
        if (passwordCheckError) {
            setPasswordCheckError(null);
        }
        // clear validation errors
        setValidationErrors(prevErrors => {
            return Object.keys(prevErrors).reduce((acc, key) => {
                acc[key] = null; // Set each error to null
                return acc;
            }, {});
        });
    };  
    
    const closeQRImageModal = () => {
        setQRImageModalOpen(false);
        // reload user data
        fetchUserData()
    }; 
    
    const closeChangePassModal = () => {
        setChangePassModalOpen(false);
        // clear password from input
        setCredentialsNewPass({});
        // reset error
        if (passwordCheckError) {
            setPasswordCheckError(null);
        }        
        // clear validation errors
        setValidationErrors(prevErrors => {
            return Object.keys(prevErrors).reduce((acc, key) => {
              acc[key] = null; // Set each error to null
              return acc;
            }, {});
        });

    }; 

    const closeChangePassSuccessModal = () => {
        setChangePassSuccessModalOpen(false);      
    }; 

    const closeDeleteAccountModal = () => {
        setDeleteAccountModalOpen(false);   
        
        // clear email_confirm from input
        setCredentialsConfirmEmail(prevState => ({
            ...prevState,
            email_confirm: ''
        }));
        // reset error
        if (emailCheckError) {
            setEmailCheckError(null);
        }        
        // clear validation errors
        setValidationErrors(prevErrors => {
            return Object.keys(prevErrors).reduce((acc, key) => {
              acc[key] = null; // Set each error to null
              return acc;
            }, {});
        });        
    };     

    // Start 2FA enable or disable
    const handleToggle2FA = () => {
        // if 2FA was enabled just disabled it
        if(!twoFaEnabled){
            setEnable2FaModalOpen(true)
        } else {
            // show modal window where user must enter password to confirm
            setDisable2FaModalOpen(true);
        }
    }   


    // function simulateApiRequest(duration = 1000) {
    //     // Return a new promise that resolves after the given duration
    //     return new Promise(resolve => setTimeout(resolve, duration));
    // }
      

    const handlePassConfirmation = async (e) => {
        e.preventDefault();

        // do input validation on the client side 1st
        const fieldsToValidate = ['password']; 
        const { isValid, errors } = validateFields(credentials2FA, fieldsToValidate, '2FA');
        setValidationErrors(errors); // Update the validation errors state
      
        if (!isValid) {
          console.log("Validation errors", errors);
          return; // Stop the submission if validation fails
        }      

        setIsSubmittingModal(true); // Disable the button

        // verify password
        const result = await verifyPassword(credentials2FA)
        const {success, detail} = result

        if(success){
            // if pass verified continue
            const success = await disableUser2FA()
            if(success){
                console.log("2FA disabled")
            } else {
                console.log("Error disabling 2FA")
            }
            // close modal
            closeDisable2FaModal()
        } else {
            // set error
            setPasswordCheckError(detail || "Failed to verify password")
        }
   
        setIsSubmittingModal(false); // Re-enable the button
    };    

    const handlePassConfirmationInEnable2FA = async (e) => {
        e.preventDefault();

        // do input validation on the client side 1st
        const fieldsToValidate = ['password']; 
        const { isValid, errors } = validateFields(credentials2FA, fieldsToValidate, '2FA');
        setValidationErrors(errors); // Update the validation errors state
      
        if (!isValid) {
          console.log("Validation errors", errors);
          return; // Stop the submission if validation fails
        }      

        setIsSubmittingModal(true); // Disable the button

        // verify password
        const result = await verifyPassword(credentials2FA)
        const {success, detail} = result

        if(success){
            // this workflow use when generating new QR code
            // there can be error - Duplicate entry. 2FA key is exists.
            const result = await get2FaQrCode()
            const {success, qrDataUrl, detail} = result
            if(success){
                console.log("show QA to user: ", qrDataUrl)
                setQrCodeUrl(qrDataUrl)
                // show modal window with QR
                setQRImageModalOpen(true)
            } else {
                console.log("Error getting QR code for 2FA: ", detail)                
            }

            // close modal
            closeEnable2FaModal()
        } else {
            // set error
            setPasswordCheckError(detail || "Failed to verify password")
        }
   
        setIsSubmittingModal(false); // Re-enable the button
    };        

    const handlePassChangeConfirm = async (e) => {
        e.preventDefault();

        // do input validation on the client side 1st
        const fieldsToValidate = ['current_password', 'password', 'password_confirm']; 
        const { isValid, errors } = validateFields(credentialsNewPass, fieldsToValidate);
        setValidationErrors(errors); // Update the validation errors state
      
        if (!isValid) {
          console.log("Validation errors", errors);
          return; // Stop the submission if validation fails
        }      

        setIsSubmittingModal(true); // Disable the button

        // verify password
        const result = await verifyPassword({
            password: credentialsNewPass.current_password
        })
        const {success, detail} = result

        if(success){
            // try to set new password
            console.log("Setting new password")

            const result = await setNewPassword(credentialsNewPass); // Call the setNewPassword function from the context
            const {success} = result
            if (success) {
                console.log("New password set")                    
                // close this button
                closeChangePassModal()
                // show success modal
                setChangePassSuccessModalOpen(true)
            }else {
                console.log("Failed to set new password")
                setPasswordCheckError("Failed to set new password")
            }   


        } else {
            // set error
            setPasswordCheckError(detail || "Failed to verify password")
        }
   
        setIsSubmittingModal(false); // Re-enable the button

    };     
 


    const handleAccountDeleteConfirm = async (e) => {
        e.preventDefault();

        // do input validation on the client side 1st
        const fieldsToValidate = ['email_confirm']; 
        const { isValid, errors } = validateFields(credentialsConfirmEmail, fieldsToValidate);
        setValidationErrors(errors); // Update the validation errors state
      
        if (!isValid) {
          console.log("Validation errors", errors);
          return; // Stop the submission if validation fails
        }      

        setIsSubmittingModal(true); // Disable the button

        try {
            const { success, detail } = await deleteUser();
    
            if (success) {
                console.log("Account deleted");
                closeDeleteAccountModal()
                // logout user
                handleLogout()
            } else {
                setEmailCheckError(detail || "Failed to delete account");
                console.error(detail || "Failed to delete account");
            }
        } catch (error) {
            setEmailCheckError(error.message || "An unexpected error occurred while deleting account");
            console.error(error);
        } finally {
            setIsSubmittingModal(false);  // Ensure submission state is always reset
        }

    };      


    if (loading) {
        return <div>Loading...</div>; // Show a loading state
    }

    return (
        <div className={styles.formContainer}>

            <div className={styles.form}>

                <h2>General</h2>

                <div className={styles.formBlock}>
                    <div className={styles.row}>
                        <InputField
                            inputText={credentials.email}
                            onChange={handleInputChange}
                            name="email"
                            placeholder=""
                            label="Email"
                            state="disabled"
                            trailingIcon={userData?.provider === "google" ? <Google /> : userData?.provider === "github" ? <Github /> : null}
                        />   
                        <InputField
                            inputText={credentials.role}
                            onChange={handleInputChange}
                            name="role"
                            placeholder=""
                            label="Role"
                            state="disabled"
                        />    
                    </div>
                    <div className={styles.row}>
                        <InputField
                            inputText={credentials.first_name}
                            onChange={handleInputChange}
                            name="first_name"
                            placeholder=""
                            label="First name"
                            state={validationErrors.first_name ? 'error' : 'default'}
                            hint={validationErrors.first_name ? validationErrors.first_name : undefined}
                        />   
                        <InputField
                            inputText={credentials.last_name}
                            onChange={handleInputChange}
                            name="last_name"
                            placeholder=""
                            label="Last name"
                            state={validationErrors.last_name ? 'error' : 'default'}
                            hint={validationErrors.last_name ? validationErrors.last_name : undefined}
                        />    
                    </div>
                    <div className={styles.row}>
                        <InputField
                            inputText={credentials.phone}
                            onChange={handleInputChange}
                            name="phone"
                            placeholder=""
                            label="Mobile phone"
                            type="tel"
                            state={validationErrors.phone ? 'error' : 'default'}
                            hint={validationErrors.phone ? validationErrors.phone : undefined}
                        />   
                        <Select
                            onChange={handleInputChange}
                            name="timezone"
                            label="Time zone"
                            options={timeZones}
                            value={credentials.timezone}
                            extraDataDelimeter={false}
                        />   
                    </div>                    
                </div>

                {/* do not show this if user authorized via Google or Github */}
                {/* provider: "email" */}                
                { userData && userData?.provider === "email" &&
                    <>
                        <h2>Security</h2>
                    
                        <div className={styles.formBlock}>
                            
                            <div className={styles.securityRow}>
                                <div className={styles.titleWithControls}>
                                    <h3>Enable 2FA</h3> <Toggle checked={twoFaEnabled} onChange={handleToggle2FA} />
                                </div>
                                <p>
                                    Protect your account with Two-Factor Authentication
                                </p>                            
                            </div>    

                            <div className={styles.securityRow}>
                                <div className={styles.titleWithControls}>
                                    <h3>Password</h3> 
                                    <Button
                                        onClick={() => setChangePassModalOpen(true)}
                                        type="secondary"
                                    >
                                        Change Password
                                    </Button>
                                </div>                     
                            </div>
                        </div>      
                    </>              
                }

 



                <div className={styles.formFooter}>
                    <Button 
                        onClick={handleUpdateUserAccount} 
                        className={styles.formFooterBtn} 
                        state={isSubmitting || Object.keys(changedFields).length === 0 ? "disabled" : undefined} 
                    >
                        Save Changes
                    </Button>                    
                </div>

                <div className={styles.deleteAccountContainer}>
                    <h2>Delete Account</h2>
                    <p>
                        This will permanently delete your account and all of it’s associated data.
                    </p>   
                    <Button 
                        type="danger"
                        onClick={() => setDeleteAccountModalOpen(true)}
                    >
                        Delete Account
                    </Button>                       
                </div>

                {userError && <div className={styles.errorMessage}>{userError}</div>}
            </div>


   
            <Modal isOpen={isDisable2FaModalOpen} onClose={closeDisable2FaModal}
                header={
                    <>
                        <h3>Disable 2FA</h3>
                        <p>Before we can disable two-factor authentication, please verify your account</p>
                    </>
                }
                footer={
                    <>
                        <Button
                            variant="modalFooter"
                            onClick={closeDisable2FaModal}
                            type="secondary"
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="modalFooter"
                            onClick={handlePassConfirmation} 
                            state={isSubmittingModal ? "disabled" : undefined}
                        >
                            Confirm
                        </Button> 
                    </>
                }
            >
                <div className="form">
                    <div className="row">
                        <InputField
                            inputText={credentials2FA.password}
                            onChange={handleInputChange2FA}
                            name="password"
                            placeholder="Password"
                            type='password'
                            label="Password"
                            // state={validationErrors.password ? 'error' : 'default'}
                            // hint={validationErrors.password ? validationErrors.password : undefined}

                            state={passwordCheckError || validationErrors.password ? 'error' : 'default'}
                            hint={passwordCheckError ? passwordCheckError : validationErrors.password ? validationErrors.password : undefined}
                        />                           
                    </div>
                </div>   
            </Modal>             

            <Modal isOpen={isEnable2FaModalOpen} onClose={closeEnable2FaModal}
                header={
                    <>
                        <h3>Enable 2FA</h3>
                        <p>Before we can enable two-factor authentication, please verify your account</p>                    
                    </>
                }
                footer={
                    <>
                        <Button
                            variant="modalFooter"
                            onClick={closeEnable2FaModal}
                            type="secondary"
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="modalFooter"
                            onClick={handlePassConfirmationInEnable2FA} 
                            state={isSubmittingModal ? "disabled" : undefined}
                        >
                            Confirm
                        </Button>                       
                    </>
                }
            >
                <div className="form">
                    <div className="row">
                        <InputField
                            inputText={credentials2FA.password}
                            onChange={handleInputChange2FA}
                            name="password"
                            placeholder="Password"
                            type='password'
                            label="Password"
                            // state={validationErrors.password ? 'error' : 'default'}
                            // hint={validationErrors.password ? validationErrors.password : undefined}

                            state={passwordCheckError || validationErrors.password ? 'error' : 'default'}
                            hint={passwordCheckError ? passwordCheckError : validationErrors.password ? validationErrors.password : undefined}
                        />                           
                    </div>
                </div>  
            </Modal>               

            <Modal isOpen={isQRImageModalOpen} onClose={closeQRImageModal}
                header={
                    <>
                        <h3>Enable 2FA</h3>
                        <ul>
                            <li>Using the authentication app, scan the QR code</li>
                        </ul>                    
                    </>
                }
                footer={
                    <>
                        <Button 
                            variant="modalFooter"
                            onClick={closeQRImageModal} 
                        >
                            Close
                        </Button>                     
                    </>
                }
            >        
                { qrCodeUrl && 
                    <img className={styles.qrImage} src={qrCodeUrl} alt="2FA QR Code" />
                }      
            </Modal>                

            <Modal isOpen={isChangePassModalOpen} onClose={closeChangePassModal}
                header={
                    <>
                        <h3>Change password</h3>
                        <p>Please complete the form below to change your password.</p>                    
                    </>
                }
                footer={
                    <>
                        <Button
                            variant="modalFooter"
                            onClick={closeChangePassModal}
                            type="secondary"
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="modalFooter"
                            onClick={handlePassChangeConfirm} 
                            state={isSubmittingModal ? "disabled" : undefined}
                        >
                            Change Password
                        </Button>                       
                    </>
                }
            >
                <div className="form">
                    <div className="row">
                        <InputField
                            inputText={credentialsNewPass.current_password}
                            onChange={handleInputChangeNewPass}
                            name="current_password"
                            placeholder="Password"
                            type='password'
                            label="Current password"
                            state={passwordCheckError || validationErrors.current_password ? 'error' : 'default'}
                            hint={passwordCheckError ? passwordCheckError : validationErrors.current_password ? validationErrors.current_password : undefined}
                        />                           
                    </div>
                    <div className="row">             
                        <InputField
                            inputText={credentialsNewPass.password}
                            onChange={handleInputChangeNewPass}
                            name="password"
                            placeholder="Password"
                            type='password'
                            label="Password"
                            state={validationErrors.password ? 'error' : 'default'}
                            hint={validationErrors.password ? validationErrors.password : undefined}
                        />
                    </div>

                    <div className="row">             
                        <InputField
                            inputText={credentialsNewPass.password_confirm}
                            onChange={handleInputChangeNewPass}
                            name="password_confirm"
                            placeholder="Password"
                            type='password'
                            label="Confirm Password"
                            state={validationErrors.password_confirm ? 'error' : 'default'}
                            hint={validationErrors.password_confirm ? validationErrors.password_confirm : undefined}
                        />
                    </div>                           
                </div>    
            </Modal>              

            <Modal isOpen={isChangePassSuccessModalOpen} onClose={closeChangePassSuccessModal}
                variant="popUp"
                icon={<Success />}
                header={
                    <>
                        <h3>Successfully Changed</h3>
                        <p>Your password has been changed successfully.</p>                          
                    </>
                }
                footer={
                    <Button 
                        variant="modalFooter"
                        onClick={closeChangePassSuccessModal} 
                    >
                        Done
                    </Button>                     
                }
            >
            </Modal>                

            <Modal isOpen={isDeleteAccountModalOpen} onClose={closeDeleteAccountModal}
                variant="popUp"
                icon={<DeleteAccount />}
                header={
                    <>
                        <h3>Delete your Account?</h3>
                        <p>Are you sure you want to delete your account? This action is permanent.</p>                          
                    </>
                }
                footer={
                    <>
                        <Button 
                            variant="modalFooter"
                            onClick={closeDeleteAccountModal} 
                            type="secondary"
                        >
                            Cancel
                        </Button>           
                        <Button 
                             variant="modalFooter"
                             onClick={handleAccountDeleteConfirm} 
                             type="danger"
                             icon={<DeleteIcon />}
                             //state={isSubmittingModal ? "disabled" : undefined}
                             state={isSubmittingModal || !credentialsConfirmEmail.email_confirm ? "disabled" : undefined}
                        >
                            Delete
                        </Button>                     
                    </>
                    
                }
            >
                <div className="form">
                    <div className="row">
                        <InputField
                            inputText={credentialsConfirmEmail.email_confirm}
                            onChange={handleInputChangeConfirmEmail}
                            name="email_confirm"
                            label="To confirm, enter your email address"
                            state={emailCheckError || validationErrors.email_confirm ? 'error' : 'default'}
                            hint={emailCheckError ? emailCheckError : validationErrors.email_confirm ? validationErrors.email_confirm : undefined}                            
                            // state={validationErrors.email_confirm ? 'error' : 'default'}
                            // hint={validationErrors.email_confirm ? validationErrors.email_confirm : undefined}
                        />                           
                    </div>                      
                </div>                    
            </Modal>               

        </div>
    );
};

export default AccountSettings;