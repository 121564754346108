import React, { 
    useState, useEffect, 
    useCallback, 
    useMemo 
} from 'react';
  
import { 
    Link, 
    useNavigate,
    useLocation
} from 'react-router-dom';
  
import { useApi } from '../../api/useApi'
import { useQencodeApi } from '../../api/useQencodeApi'
import { useBackend } from 'api/useBackend';
  
import { Select } from '../../components/Select';
import { Button } from 'components/Button'
import { InputField } from 'components/InputField'

import { Modal } from 'components/Modal';

import { validateFields } from '../../helpers/validation';

import { parseDestinationUrl } from 'helpers/urlParser';

import { getFileExtension, getFileNameWithoutExtension } from './OutputSettings/helpers'

import { 
    //FolderAlt, 
    VideoFile, Save, Launch, Project,
    Warning as WarningIcon, 
} from '../../icons'

import { ReactComponent as Step1Image } from '../../assets/images/Step1.svg';
import { ReactComponent as Step2Image } from '../../assets/images/Step2.svg';
import { ReactComponent as Step3Image } from '../../assets/images/Step3.svg';
import { ReactComponent as Step4Image } from '../../assets/images/Step4.svg';
//import { ReactComponent as Step5Image } from '../../assets/images/Step5.svg';
import { ReactComponent as StepComlete } from '../../assets/images/StepComplete.svg';

import { ReactComponent as Success } from '../../assets/images/Success.svg';

import { SourceVideoSelector } from './SourceVideoSelector';
import { DestinationSelector } from './DestinationSelector';
import { OutputSettings } from './OutputSettings';
import { AdditionalSettings } from './AdditionalSettings';

import { OutputJSON } from './OutputJSON';

import { formatDate } from 'helpers/formatting'

// import tus from 'tus-js-client';
import { Upload } from 'tus-js-client';

import { getFormatLabel } from './constantsMap'

import { FileCard } from 'components/FileCard';

import styles from './index.module.css';

// Initial steps status object
const initialStepsStatus = {
    selectProject: { step: 'Step 1: Select Project', complete: false },
    selectTemplate: { step: 'Step 2: Select Template', complete: false },
    sourceVideo: { step: 'Step 3: Source Video', complete: false },
    selectDestination: { step: 'Step 4: Select Destination', complete: false },
    outputSettings: { step: 'Step 5: Output Settings', complete: true },
    additionalSettings: { step: 'Step 6: More Settings', complete: true },
};   

const useStepsStatus = () => {
    const [stepsStatus, setStepsStatus] = useState(initialStepsStatus);

    const updateStepStatus = useCallback((stepKey, isComplete) => {
        setStepsStatus(prevStepsStatus => ({
            ...prevStepsStatus,
            [stepKey]: { ...prevStepsStatus[stepKey], complete: isComplete }
        }));
    }, []);

    return [stepsStatus, updateStepStatus];
};
  
export const TranscodingPage = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const { 
        getProjectsData,
        getTemplatesData,
        //getBucketsListData,
        addTemplate,
        updateTemplate
    } = useApi();

    const {
        getS3config
    } = useBackend();    

    const { getAccessToken, createTask, startTranscoding } = useQencodeApi();

    // {"error": 0, "detail": null, "timestamp": 1719571889.190852, "data": null}
    // https://portal-new-qa.qencode.com/backend/config/s3

    const searchParams = new URLSearchParams(location.search);
    const templateId = searchParams.get('templateId');
    console.log("templateId: ", templateId)


    // Initial visible steps
    const initialVisibleSteps = {
        step1: true,
        step2: true,
        step3: false,
        step4: false,
        step5: false,
    };       

    // const allStepsVisible = {
    //     step1: true,
    //     step2: true,
    //     step3: true,
    //     step4: true,
    //     step5: true,
    // };        

    // Memoized steps visibility
    const allStepsVisible = useMemo(() => ({
        step1: true,
        step2: true,
        step3: true,
        step4: true,
        step5: true,
    }), []);

    const [visibleSteps, setVisibleSteps] = useState(initialVisibleSteps);

    // steps monitor
    const [stepsStatus, updateStepStatus] = useStepsStatus();


    // s3 config
    const [loadingS3config, setLoadingS3config] = useState(true);
    const [configError, setConfigError] = useState(null);
    const [configData, setConfigData] = useState({});      

    // for Projects selector
    const [selectedProject, setSelectedProject] = useState('');
    const [projectOptions, setProjectOptions] = useState([]);

    // for Templates selector
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [templateOptions, setTemplateOptions] = useState([]);

    // list of Qencode buckets used in child components
    //const [buckets, setBuckets] = useState([]);

    // if videoSrc is in template, need to get it from there
    const [videoSrc, setVideoSrc] = useState('');
    const [templateChanged, setTemplateChanged] = useState(false);

    // if Destination object is in template, need to get it from there
    const [destination, setDestination] = useState(null);


    const initialQueryObject = {
        query:{
            format:[
                {
                    // initial format is empty object because added format selector tabs
                    // output:"mp4",
                    // video_codec:"libx264",
                    // height:2160,
                    // quality:20
                }
            ],
            encoder_version:"2",
            //source:""
        }
    }

    const [queryObject, setQueryObject] = useState(initialQueryObject);    

    // New state for formats
    // const [formats, setFormats] = useState([]);
    const [formats, setFormats] = useState(initialQueryObject.query.format || []);
    const [initialFormats, setInitialFormats] = useState([]);


    // saving template
    const [isSaveTemplateModalOpen, setSaveTemplateModalOpen] = useState(false);
    const [isSaveTemplateSuccessModalOpen, setSaveTemplateSuccessModalOpen] = useState(false);
    const [isSavingTemplate, setIsSavingTemplate] = useState(false);
    const [savingNewTemplateError, setSavingNewTemplateError] = useState(null);
    const [savingTemplateError, setSavingTemplateError] = useState(null);

    const [validationErrors, setValidationErrors] = useState({});

    // Initialize credentials
    const [credentials, setCredentials] = useState({
        transcoding_template_name: '',       
    });    

    // Transcoding
    // when start transcoding need to show modal window to show different statuses of transcoding initiation
    // and if direct upload - also show file upload status
    const [isStartTranscodingModalOpen, setStartTranscodingModalOpen] = useState(false);

    const [directUploadFile, setDirectUploadFile] = useState(null); // in case this is direct file upload

    // dealing with direct file upload
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploadComplete, setIsUploadComplete] = useState(false);
    const [uploadError, setUploadError] = useState(null);
    const [currentUpload, setCurrentUpload] = useState(null);

    // whent transcoding started
    const [fileUploadUrl, setFileUploadUrl] = useState('');
    const [taskToken, setTaskToken] = useState('');

    // tracking transcoding status and error
    const [transcodingStatus, setTranscodingStatus] = useState('');
    const [transcodingError, setTranscodingError] = useState(null);
    const [isTranscodingStarted, setIsTranscodingStarted] = useState(false);    

    const fetchConfigData = useCallback(async () => {
        try {
            setLoadingS3config(true);
            const { success, data, detail } = await getS3config();
            if (success && data) {
                console.log("config data: ", data);

                setConfigData(data)
                setConfigError(null);
            } else {
                setConfigError(detail || "Failed to get S3 config data");
                console.error(detail || "Failed to get S3 config data");
            }
        } catch (error) {
            setConfigError(error.message);
            console.error(error); // Here, replace with more advanced error handling if necessary
        } finally {
            setLoadingS3config(false); // Ensures loading is set to false regardless of the outcome
        }
    }, [getS3config]);   
    
    const updateConfigData = useCallback( (data) => {
        console.log("config data: ", data);
        setConfigData(data)
        setConfigError(null);
    },[])

    // const fetchProjectsData = useCallback(async () => {
    //     try {
    //         //setLoading(true);
    //         const settings = {type: "vod"}
    //         const { success, data, detail } = await getProjectsData(settings);
    //         if (success && data) {
    //             console.log("projects data: ", data);

    //             const options = data.map(project => ({
    //                 value: project.id,
    //                 label: project.name,
    //                 extraData: formatDate(project.create_datetime),                  
    //                 data: project // store the whole project object in the option
    //             }));
    //             setProjectOptions(options);

    //             // setOrganizationData(data);
    //             // setOrganizationError(null);
    //         } else {
    //             //setOrganizationError(detail || "Failed to get projects data");
    //             console.error(detail || "Failed to get projects data");
    //         }
    //     } catch (error) {
    //         //setOrganizationError(error.message);
    //         console.error(error); // Here, replace with more advanced error handling if necessary
    //     } finally {
    //         //setLoading(false); // Ensures loading is set to false regardless of the outcome
    //     }
    // }, [getProjectsData]);


    const fetchProjectsData = useCallback(async () => {
        try {
            //setLoading(true);
            const settings = {type: "vod"}
            const { success, data, detail } = await getProjectsData(settings);
            if (success && data) {
                console.log("projects data: ", data);

                // Load selected project ID from local storage
                const savedProjectId = localStorage.getItem('selectedProjectId');
                let selectedProject = data.find(project => project.id === savedProjectId);

                // If the saved project ID is not found, fallback to the first project
                if (!selectedProject) {
                    selectedProject = data[0];
                    // Save the first project's ID to local storage
                    if (selectedProject && selectedProject.id) {
                        localStorage.setItem('selectedProjectId', selectedProject.id);
                    }
                }

                // Set the selected project
                setSelectedProject(selectedProject);

                // Create options and mark the first project as checked
                const options = data.map(project => ({
                    value: project.id,
                    label: project.name,
                    extraData: formatDate(project.create_datetime),                  
                    data: project, // store the whole project object in the option
                    checked: project.id === selectedProject.id
                }));
                setProjectOptions(options);

                // Update step status for 'selectProject'
                updateStepStatus('selectProject', !!selectedProject);

                console.log("Selected project: ", selectedProject);                

                // setOrganizationData(data);
                // setOrganizationError(null);
            } else {
                //setOrganizationError(detail || "Failed to get projects data");
                console.error(detail || "Failed to get projects data");
            }
        } catch (error) {
            //setOrganizationError(error.message);
            console.error(error); // Here, replace with more advanced error handling if necessary
        } finally {
            //setLoading(false); // Ensures loading is set to false regardless of the outcome
        }
    }, [getProjectsData, updateStepStatus]);


    const fetchTemplatesData = useCallback(async () => {
        try {
            //setLoading(true);
            const { success, data, detail } = await getTemplatesData();
            if (success && data) {
                console.log("templates data: ", data);

                // if there is templateId, need to set it as checked, find that 
                console.log("Load template: ",  templateId)

                const options = data.map(template => ({
                    value: template.id,
                    label: template.name,            
                    // extraData: template.query.query?.format.map((f, index) => (
                    //     <span className={styles.output} key={`${f.output}-${index}`}>{getFormatLabel(f.output)}</span>
                    // )),    
                    extraData: template.query.query?.format.map((f, index) => 
                        f.output ? (
                            <span className={styles.output} key={`${f.output}-${index}`}>
                                {getFormatLabel(f.output)}
                            </span>
                        ) : null
                    ),                              
                    data: template, // store the whole template object in the option
                    checked: template.id === templateId
                }));

                setTemplateOptions(options);

                // Find and set the selected template if templateId is present in the URL
                if (templateId) {                
                    const template = options.find(option => option.value === templateId)?.data;
                    //setSelectedTemplate(template);
                    if(template){
                        // Call handleTemplateChange with a synthetic event
                        //handleTemplateChange({ target: { value: templateId } });

                        // basically run same as in handleTemplateChange

                        setSelectedTemplate(template);
                        console.log("Change selected template to: ", template);
                
                        // when template loaded show all steps
                        setVisibleSteps(allStepsVisible)
                
                        // update status for select template step
                        updateStepStatus('selectTemplate', !!template);
                
                        // set query object based on selected template data
                        setQueryObject(template?.query)
                        console.log("template?.query: ", template?.query)
                
                        // update formats
                        // formats are updated in useEffect when selectedTemplate changes
                        updateStepStatus('outputSettings', true);
                
                
                        // Extract the source from the selected template
                        const source = template?.query?.query?.source || '';
                        // Set the source as the video source URL
                        setVideoSrc(source);
                        updateStepStatus('sourceVideo', source);
                        // update source block
                
                
                        // use parseDestinationUrl function to get baseUrl and path
                        const destinationFromTemplate = template?.query?.query?.format?.[0]?.destination || null;  
                        const { baseUrl, path } = parseDestinationUrl(template?.query?.query?.format?.[0]?.destination?.url)
                        console.log({baseUrl, path})
       
                
                        if(destinationFromTemplate){
                            setDestination({
                                ...destinationFromTemplate,
                                url: baseUrl
                            })
                        } else {
                            // make sure to set destination to undefined if it was removed in Destination section
                            setDestination(undefined)
                        }        
                        // set destination step as checked when template changed 
                        updateStepStatus('selectDestination', true);
                
                        // indicate that video src was changed by changing template
                        setTemplateChanged(true)
                
                    }
                }                

                // setOrganizationData(data);
                // setOrganizationError(null);
            } else {
                //setOrganizationError(detail || "Failed to get templates data");
                console.error(detail || "Failed to get templates data");
            }
        } catch (error) {
            //setOrganizationError(error.message);
            console.error(error); // Here, replace with more advanced error handling if necessary
        } finally {
            //setLoading(false); // Ensures loading is set to false regardless of the outcome
        }
    }, [getTemplatesData, templateId, allStepsVisible, updateStepStatus]); // Verify that `getTemplatesData` doesn't need additional dependencies






    // loading buckets list and setting 1st bucket as default
    // const fetchBucketsData = useCallback(async () => {
    //     try {
    //         const { success, data, detail } = await getBucketsListData();
    //         if (success && data) {
    //             console.log("buckets data: ", data);
    //             setBuckets(data);
    //         } else {
    //             console.error(detail || "Failed to get buckets list data");
    //         }
    //     } catch (error) {
    //         console.error(error.message); // Handle the error as needed
    //     }
    // }, [getBucketsListData]);    
  

    useEffect(() => {
        fetchProjectsData();
        fetchTemplatesData();
        //fetchBucketsData();
        fetchConfigData();
    }, [fetchProjectsData, fetchTemplatesData, fetchConfigData]);

    useEffect(() => {
        console.log("templateChanged: ", templateChanged)
    }, [templateChanged]);  


    const handleProjectChange = (e) => {
        // Find the full project data based on the selected option value
        const project = projectOptions.find(option => option.value === e.target.value)?.data;
        setSelectedProject(project);
        console.log("Change selected project to: ", project);

        // Save selected project ID to local storage
        if (project && project.id) {
            localStorage.setItem('selectedProjectId', project.id);
        }        

        const updatedOptions = projectOptions.map(option => ({
            ...option,
            // Assuming 'checked' is used for UI logic to visually indicate a match
            checked: project.id === option.value // there is a case where project has no id
        }));                  

        setProjectOptions(updatedOptions);      
        
        updateStepStatus('selectProject', !!project)
    };


    const handleTemplateChange = (e) => {
        // Find the full project data based on the selected option value
        const template = templateOptions.find(option => option.value === e.target.value)?.data;
        setSelectedTemplate(template);
        console.log("Change selected template to: ", template);

        // when template loaded show all steps
        if(template){
            setVisibleSteps(allStepsVisible)
        }

        // update status for select template step
        updateStepStatus('selectTemplate', !!template);

        // set query object based on selected template data
        setQueryObject(template?.query)
        console.log("template?.query: ", template?.query)

        // update formats
        //setFormats(template?.query?.format || initialQueryObject.query.format) // if template has no formats set initial
        updateStepStatus('outputSettings', true);
        // formats are set in useEffect for selectedTemplate


        // Extract the source from the selected template
        const source = template?.query?.query?.source || '';
        // Set the source as the video source URL
        setVideoSrc(source);
        updateStepStatus('sourceVideo', source);
        // update source block


        // use parseDestinationUrl function to get baseUrl and path
        const destinationFromTemplate = template?.query?.query?.format?.[0]?.destination || null;  
        const { baseUrl, path } = parseDestinationUrl(template?.query?.query?.format?.[0]?.destination?.url)
        console.log({baseUrl, path})
        // setDestination({
        //     ...destinationFromTemplate,
        //     url: baseUrl
        // })        

        if(destinationFromTemplate){
            setDestination({
                ...destinationFromTemplate,
                url: baseUrl
            })
            // updateStepStatus('selectDestination', true);
        } else {
            // make sure to set destination to undefined if it was removed in Destination section
            setDestination(undefined)
            //updateStepStatus('selectDestination', false);
        }        
        // set destination step as checked when template changed 
        updateStepStatus('selectDestination', true);

        // indicate that video src was changed by changing template
        setTemplateChanged(true)

        const updatedOptions = templateOptions.map(option => ({
            ...option,
            // Assuming 'checked' is used for UI logic to visually indicate a match
            checked: template?.id === option.value
        }));            

        setTemplateOptions(updatedOptions);            

        // Update the URL with the new templateId
        const newUrl = new URL(window.location);
        newUrl.searchParams.set('templateId', template?.id);
        window.history.pushState({}, '', newUrl);        
    };

    const handleVideoSrcUpdate = (src) => {
        setVideoSrc(src); // Update video source URL in parent component state
    
        // Update source in query and formats
        const updatedFormats = destination ? formats.map(format => {
            // Extract baseUrl from destination or set to empty string if destination is undefined
            const { baseUrl } = parseDestinationUrl(destination.url);
            const fileName = getFileNameWithoutExtension(src);
    
            //let newUrl = `${baseUrl}${fileName}${getFileExtension(format?.output)}`;
            //console.log("newUrl: ", newUrl);
            // if this is repack file extention depends on file_extension and not on output
            let newUrl = ''
            if(format?.output === 'repack'){
                newUrl = `${baseUrl}${fileName}${getFileExtension(format?.file_extension)}`;
            } else if (format?.output === 'thumbnail'){
                newUrl = `${baseUrl}${fileName}${getFileExtension(format?.image_format)}`;
            } else {
                newUrl = `${baseUrl}${fileName}${getFileExtension(format?.output)}`;
            }              
    
            return {
                ...format,
                destination: {
                    ...format.destination,
                    url: newUrl
                }
            };
        }) : formats;
    
        setFormats(updatedFormats);
    
        setQueryObject(prevQueryObject => ({
            ...prevQueryObject,
            query: {
                ...prevQueryObject.query,
                format: updatedFormats,
                source: src
            }
        }));

        updateStepStatus('sourceVideo', !!src);
        
        // regardles if src has any value or not show next step and never close it
        setVisibleSteps(prevSteps => ({
            ...prevSteps,
            step3: true
        }));        
    };
    
    


    // const handleDestinationUpdate = (destination) => {
    //     console.log("destination: ", destination)
    //     setDestination(destination); // Update destination in parent component state    

    //     // here need to update formats        
    // };

    const handleDestinationUpdate = (newDestination) => {
        console.log("destination: ", newDestination);

        //setDestination(newDestination); // Update destination in parent component state    
        // make sure new destination only has baseUrl
        const { baseUrl } = parseDestinationUrl(newDestination?.url)

        console.log("baseUrl: ", baseUrl)

        if(newDestination){
            setDestination({
                ...newDestination,
                url: baseUrl
            })

            // updateStepStatus('selectDestination', true);
        } else {
            // make sure to set destination to undefined if it was removed in Destination section
            setDestination(undefined)

            // updateStepStatus('selectDestination', false);
        }

        updateStepStatus('selectDestination', true);
    
        const updatedFormats = formats.map(format => {
            if (newDestination) {
                const currentUrl = format?.destination?.url;
                // const filename = getFileNameFromUrl(currentUrl) || '';
    
                // const baseUrl = newDestination.url.endsWith('/') ? newDestination.url.slice(0, -1) : newDestination.url;
                // const newUrl = `${baseUrl}/${filename}`;          

                const { path } = parseDestinationUrl(currentUrl)
                //const { baseUrl } = parseDestinationUrl(newDestination?.url)
                let newUrl = `${baseUrl}${path}`;  
                
                if(!path){
                    // if there is no path
                    const fileName = getFileNameWithoutExtension(videoSrc) || '';
                    //const extension = getFileExtension(format?.output) || ''
                    let extension = ''
                    if(format?.output === 'repack'){
                        extension = getFileExtension(format?.file_extension) || ''
                    } else if(format?.output === 'thumbnail'){
                        extension = getFileExtension(format?.image_format) || ''
                    } else {
                        extension = getFileExtension(format?.output) || ''
                    }
                    newUrl = `${baseUrl}${fileName}${extension}`;                     
                }
    
                return {
                    ...format,
                    destination: {
                        ...newDestination,
                        url: newUrl
                    }
                };
            } else {
                // remove destination field from format if it's not defined
                const { destination, ...rest } = format;
                return rest;
            }
        });

        // this will update formats and query object
        handleFormatsUpdate(updatedFormats)

        // regardles if destination has any value or not show next step and never close it
        setVisibleSteps(prevSteps => ({
            ...prevSteps,
            step4: true,
            step5: true
        }));         
    };    

    // dealing with formats
    useEffect(() => {
        if (selectedTemplate) {
            console.log("UPDATING FORMATS.....")
            setFormats(selectedTemplate.query.query?.format || []);
            setInitialFormats(selectedTemplate.query.query?.format || [])

            // input field when saving template as new template
            setCredentials({
                transcoding_template_name: selectedTemplate.name || ''
            });
        }
    }, [selectedTemplate]);


    const handleFormatsUpdate = useCallback((updatedFormats) => {
        //console.log("updatedFormats: ", updatedFormats)
        setFormats(updatedFormats);
        // You may also need to update the template or some other state here

        // update formats field
        setQueryObject(prevQueryObject => ({
            ...prevQueryObject,
            query: {
                ...prevQueryObject.query,
                format: updatedFormats
            }
        }));        
    }, []);

    const handleAdditionalSettingsUpdate = (name, value) => {
        const fieldsToDelete = [
            'upscale', 'use_subtask_callback', 'callback_url', 'retry_on_error'
        ];
    
        setQueryObject(prevQueryObject => {
            const updatedQuery = {
                ...prevQueryObject,
                query: {
                    ...prevQueryObject.query,
                    [name]: value
                }
            };
    
            // Delete fields with specific conditions
            for (const field of fieldsToDelete) {
                if (updatedQuery.query[field] === 0 || updatedQuery.query[field] === '' || !updatedQuery.query[field]) {
                    delete updatedQuery.query[field];
                }
            }
    
            // Delete encoder_version if value is 1
            if (updatedQuery.query.encoder_version === '1') {
                delete updatedQuery.query.encoder_version;
            }

            // Delete use_subtask_callback field if callback_url is not set
            if (!updatedQuery.query.callback_url) {
                delete updatedQuery.query.use_subtask_callback;
            }
    
            return updatedQuery;
        });
    };


    // saving template
    const closeSaveTemplateModal = () => {
        setSaveTemplateModalOpen(false)

        // clear template name from input
        setCredentials(currentCredentials => ({
            ...currentCredentials,
            transcoding_template_name: ''
        }));

        // reset error
        if (savingNewTemplateError) {
            setSavingNewTemplateError(null);
        }

        // clear validation errors
        setValidationErrors(prevErrors => {
            return Object.keys(prevErrors).reduce((acc, key) => {
                acc[key] = null; // Set each error to null
                return acc;
            }, {});
        });
    };  

    const closeSaveTemplateSuccessModal = () => {
        setSaveTemplateSuccessModalOpen(false)
    }

    const handleInputChangeTemplate = (e) => {
        // Reset the error when the user starts typing
        if (savingNewTemplateError) {
            setSavingNewTemplateError(null)
        }

        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });

        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: null
        }));
    };    

    const saveNewTemplate = useCallback(async (name, query) => {

        setIsSavingTemplate(true)

        const settings = {
            name: name,
            query: query
        }

        try {
            const { success, data, detail } = await addTemplate(settings);
            if (success && data) {
                console.log("template data: ", data);

                // Add the new template to the top of the list and set it as checked
                const newOption = {
                    value: data.id,
                    label: data.name,
                    extraData: data.query.query?.format.map((f, index) => (
                        <span className={styles.output} key={`${f.output}-${index}`}>{f.output}</span>
                    )),
                    data: data,
                    checked: true // Mark new option as checked
                };
                setTemplateOptions(prevOptions => [
                    newOption,
                    ...prevOptions.map(option => ({ ...option, checked: false }))
                ]);

                // set this new template as selected
                setSelectedTemplate(data);

                setSavingNewTemplateError(null)

                // close modal window
                setSaveTemplateModalOpen(false)

                // show success modal window
                setSaveTemplateSuccessModalOpen(true)
            } else {
                setSavingNewTemplateError(detail || "Failed to save new template")
                console.error(detail || "Failed to save new template");
            }
        } catch (error) {
            setSavingNewTemplateError(error.message)
            console.error(error); // Here, replace with more advanced error handling if necessary
        } finally {
            setIsSavingTemplate(false)
        }
    }, [addTemplate]);

    const saveCurrentTemplate = useCallback(async (id, name, query) => {

        const settings = {
            name: name,
            query: query
        }

        try {
            const { success, data, detail } = await updateTemplate(id, settings);
            if (success && data) {
                console.log("template data: ", data);

                // Update the existing template in the list
                const updatedOptions = templateOptions.map(option => 
                    option.value === id ? {
                        value: data.id,
                        label: data.name,
                        extraData: data.query.query?.format.map((f, index) => (
                            <span className={styles.output} key={`${f.output}-${index}`}>{f.output}</span>
                        )),
                        data: data,
                        checked: true // Mark new option as checked
                    } : option
                );
                setTemplateOptions(updatedOptions);                

                // update selected template
                setSelectedTemplate(data);

                setSavingTemplateError(null)

                // show success modal window
                setSaveTemplateSuccessModalOpen(true)
            } else {
                setSavingTemplateError(detail || "Failed to save template");
                console.error(detail || "Failed to save template");
            }
        } catch (error) {
            setSavingTemplateError(error.message);
            console.error(error); // Here, replace with more advanced error handling if necessary
        } finally {
            //setLoading(false); // Ensures loading is set to false regardless of the outcome
        }
    }, [updateTemplate, templateOptions]);    

    const handleSaveNewTemplate = () => {

        // do input validation on the client side 1st
        const fieldsToValidate = ['transcoding_template_name']; 
        const { isValid, errors } = validateFields(credentials, fieldsToValidate);
        setValidationErrors(errors); // Update the validation errors state
      
        if (!isValid) {
          console.log("Validation errors", errors);
          return; // Stop the submission if validation fails
        }            

        saveNewTemplate(credentials.transcoding_template_name, queryObject)
    }
    
    const handleSaveTemplate = (e) => {
        const {value} = e.target
        //console.log({name, value})

        // clear errors
        setSavingNewTemplateError(null)
        setSavingTemplateError(null)
     
        if(value === 'save_as'){
            // add new templat
            console.log("Save as...")
            console.log("queryObject: ", queryObject)

            // input field when saving template as new template
            setCredentials({
                transcoding_template_name: selectedTemplate.name || ''
            });            

            // show modal
            setSaveTemplateModalOpen(true)
        }

        if(value === 'save'){
            // update existing template
            console.log("Save...")
            console.log("selectedTemplate ", selectedTemplate)

            saveCurrentTemplate(selectedTemplate.id, selectedTemplate.name, queryObject)
        }
    }

    // Construct the options array conditionally
    const templateMenuOptions = [
        ...(selectedTemplate ? [{ label: 'Save', value: 'save' }] : []),
        { label: 'Save Template As', value: 'save_as' }
    ];



    // LAUNCH TRANSCODING
    useEffect(() => {
        if(!directUploadFile){
            //console.log("Reset uploading status and clear errors")
            setIsUploading(false)
            setIsUploading(false)
            setUploadProgress(0)
            setIsUploadComplete(false)
            setUploadError(null)
        }
    }, [directUploadFile]);  

    const closeStartTranscodingModal = () => {
        setStartTranscodingModalOpen(false)
    }

    const handleLaunchTranscoding = async () => {
        console.log("Launch Transcoding");
        // reset errors and statuses
        setIsUploading(false);
        setUploadProgress(0);
        setIsUploadComplete(false);
        setUploadError(null);           
        setIsTranscodingStarted(false)
    
        // show modal window that will have transcoding job status and file upload progress if this is direct upload
        setStartTranscodingModalOpen(true);
        setTranscodingStatus("Getting Access Token");
    
        const { api_key } = selectedProject;
    
        console.log("api_key: ", api_key);
    
        const { token, detail: accessTokenMessage } = await getAccessToken(api_key);
        if (token) {
            console.log('Access Token:', token);
            setTranscodingStatus("Creating Task");
    
            // create task
            const { task_token, upload_url, detail: createTaskMessage } = await createTask(token);
            if (task_token && upload_url) {
                console.log({ task_token, upload_url });
    
                const fileUploadUrl = `${upload_url}/${task_token}`;
    
                // save task token and upload url so can use them when retry upload
                setTaskToken(task_token);
                setFileUploadUrl(fileUploadUrl);
    
                if (directUploadFile) {
                    console.log("Upload file directly: ", directUploadFile);
                    setTranscodingStatus("Uploading File");
                    // basically here need to show modal window with file upload progress and a message not to leave the page
                    // const fileUploadUrl = `${upload_url}/${task_token}`
                    uploadFile(directUploadFile, fileUploadUrl, task_token);
                } else {
                    console.log("Start transcoding");
                    setTranscodingStatus("Starting Transcoding");
    
                    let queryJSON = JSON.stringify(queryObject);
                    // startTranscoding
                    const { status_url, detail: startTranscodingMessage } = await startTranscoding(task_token, queryJSON);
                    if (status_url) {
                        console.log({ status_url });
                        setIsTranscodingStarted(true);
                        setTranscodingStatus("Transcoding Started");
                    } else {
                        console.error(startTranscodingMessage || 'Failed to start transcoding');
                        setTranscodingError(startTranscodingMessage || 'Failed to start transcoding');
                        setTranscodingStatus("Transcoding Failed");
                    }
                }
    
            } else {
                console.error(createTaskMessage || 'Failed to create task');
                setTranscodingError(createTaskMessage || 'Failed to create task');
            }
    
        } else {
            console.error(accessTokenMessage || 'Failed to fetch access token');
            setTranscodingError(accessTokenMessage || 'Failed to fetch access token');
        }
    };
    

    const handleRetry = () => {
        // reset errors and statuses
        setIsUploading(false);
        setUploadProgress(0);
        setIsUploadComplete(false);
        setUploadError(null);        

        uploadFile(directUploadFile, fileUploadUrl, taskToken)
    };      

  
    const handleCancelUpload = () => {
        if (currentUpload) {
            currentUpload.abort();
            setIsUploading(false);
            //setUploadProgress(0);
            setIsUploadComplete(false);
            setUploadError('Stopped');
            console.log("Upload canceled");
        }
    };    

    const uploadFile = (file, uploadUrl, task_token) => {
        setIsUploading(true);
        const upload = new Upload(file, {
            endpoint: uploadUrl,
            retryDelays: [0, 1000, 3000, 5000],
            metadata: {
                filename: file.name,
                filetype: file.type,
            },
            onError: (error) => {
                const {message} = error
                setUploadError(message || 'Failed to upload file');
                setIsUploading(false);
                console.error("Failed to upload file:", error);
            },
            onProgress: (bytesUploaded, bytesTotal) => {
                const percentage = (bytesUploaded / bytesTotal) * 100;
                setUploadProgress(percentage);
                console.log(`Uploaded ${bytesUploaded} of ${bytesTotal} bytes (${percentage.toFixed(2)}%)`);
            },
            onSuccess: () => {
                setIsUploading(false);
                setUploadProgress(0);
                setIsUploadComplete(true)
                //setUploadUrl(upload.url);
                console.log("upload: ", upload)
                console.log("Upload complete:", upload.url);

                // Extract file UUID from the URL
                const fileUUID = upload.url.split('/').pop();
                const source = `tus:${fileUUID}`

                // Proceed with transcoding using the uploaded file URL
                const updatedQueryObject = {
                    ...queryObject,
                    query: {
                        ...queryObject.query,
                        source: source
                    }
                };

                let queryJSON = JSON.stringify(updatedQueryObject);

                startTranscodingAfterUpload(task_token, queryJSON);      
                
                // update query object
                setQueryObject(updatedQueryObject)
            }
        });

        setCurrentUpload(upload); // Store the upload instance
        upload.start();
    };

    const startTranscodingAfterUpload = async (task_token, queryJSON) => {
        const { status_url, detail: startTranscodingMessage } = await startTranscoding(task_token, queryJSON);
        if (status_url) {
            console.log({ status_url });
            // basically here can redirect to a page of this transcoding task to track status there
            setIsTranscodingStarted(true);
            setTranscodingStatus("Transcoding Started");
        } else {
            console.error(startTranscodingMessage || 'Failed to start transcoding');
            setTranscodingError(startTranscodingMessage || 'Failed to start transcoding');
            setTranscodingStatus("Transcoding Failed");
        }
    };

    const goToTranscodingJobPage = () => {
        //console.log(taskToken)
        navigate(`/my_encodings/${taskToken}`, { state: { projectName: selectedProject?.name } });
    }

    return (
        <div className={styles.pageContainer}>
            <div className={styles.container}>

                <div className={styles.left}>

                    <div className={styles.transcodingSteps}>

                        {/* {
                            visibleSteps.step1 &&
                            <div className={styles.stepContainer}>

                                <div className={styles.stepHeader}>
                                    <div className={styles.stepIndex}>
                                        { stepsStatus.selectProject.complete ? <StepComlete /> : <Step1Image />}
                                    </div>
                                    <div className={styles.stepTitle}>Project</div>
                                </div>

                                <div className={styles.stepDescription}>Choose project for API key and select templates</div>

                                <div className={styles.stepBody}>
                                    <div className={styles.stepRow}>
                                        <Select            
                                            className={styles.dropdownSelector}                
                                            onChange={handleProjectChange}
                                            label='Project'
                                            link={{to: '/project/vod_projects', text: 'Manage'}}                      
                                            name='project'
                                            placeholder='Select Project'
                                            options={projectOptions}
                                            value={selectedProject ? selectedProject.id : ''}
                                            //variant="withExtraData"
                                            // icon={<FolderAlt />}
                                            icon={<Project />}
                                            wrappedIcon={true}
                                            size="xl"
                                        />    
                                        <Select                       
                                            className={styles.dropdownSelector}        
                                            onChange={handleTemplateChange}
                                            label='Templates'
                                            link={{to: '/transcoding_templates', text: 'Manage'}}                      
                                            name='template'
                                            placeholder='Select Template'
                                            options={templateOptions}
                                            value={selectedTemplate ? selectedTemplate.id : ''}
                                            //variant="withExtraData"
                                            icon={<VideoFile />}
                                            wrappedIcon={true}
                                            size="xl"
                                        /> 
                                    </div>


                                </div>

                                <div className={styles.stepFooter}>

                                </div>



                            </div>
                        } */}

                        {
                            visibleSteps.step2 &&
                            <div className={styles.stepContainer}>

                                <div className={styles.stepHeader}>
                                    {/* <div className={styles.stepIndex}><Step2Image /></div> */}
                                    <div className={styles.stepIndex}>
                                        { stepsStatus.sourceVideo.complete ? <StepComlete /> : <Step1Image />}
                                    </div>                            
                                    <div className={styles.stepTitle}>Select Media</div>
                                </div>

                                {/* <div className={styles.stepDescription}>Choose video file(s) you want to transcode</div> */}

                                <div className={styles.stepBody}>
                                    
                                    <SourceVideoSelector 
                                        onVideoSrcUpdate={handleVideoSrcUpdate} 
                                        videoSrc={videoSrc}
                                        templateChanged={templateChanged}
                                        setTemplateChanged={setTemplateChanged}
                                        setDirectUploadFile={setDirectUploadFile}
                                        loadingS3config={loadingS3config}   // may need to use it dealing with buckets
                                        configError={configError}           // may need to use it dealing with buckets
                                        configData={configData}
                                        onEnableSuccess={updateConfigData}
                                        onEnableFail={setConfigError}
                                    />

                                </div>

                                <div className={styles.stepFooter}>
                                </div>



                            </div>                
                        }

                        {
                            visibleSteps.step3 &&
                            <div className={styles.stepContainer}>

                                <div className={styles.stepHeader}>
                                    <div className={styles.stepIndex}>
                                        { stepsStatus.selectDestination.complete ? <StepComlete /> : <Step2Image />}
                                    </div>   
                                    <div className={styles.stepTitle}>Choose Destination</div>
                                </div>

                                {/* <div className={styles.stepDescription}>Choose destination to store output files</div> */}

                                <div className={styles.stepBody}>
                                    
                                    <DestinationSelector 
                                        onDestinationUpdate={handleDestinationUpdate} 
                                        destination={destination}
                                        templateChanged={templateChanged}
                                        setTemplateChanged={setTemplateChanged}
                                        loadingS3config={loadingS3config}   // may need to use it dealing with buckets
                                        configError={configError}           // may need to use it dealing with buckets
                                        configData={configData}
                                        onEnableSuccess={updateConfigData}
                                        onEnableFail={setConfigError}
                                        updateStepStatus={updateStepStatus}
                                    />

                                </div>

                                <div className={styles.stepFooter}>
                                </div>



                            </div>   
                        }

                        {
                            visibleSteps.step4 &&
                            <div className={styles.stepContainer}>

                                <div className={styles.stepHeader}>
                                    <div className={styles.stepIndex}>
                                        { stepsStatus.outputSettings.complete ? <StepComlete /> : <Step3Image />}
                                    </div>   
                                    <div className={styles.stepTitle}>Output Settings</div>
                                </div>

                                {/* <div className={styles.stepDescription}>Choose formats, codecs and transcoding settings</div> */}

                                <div className={styles.stepBody}>
                                    
                                    <OutputSettings 
                                        formats={formats} 
                                        onFormatsUpdate={handleFormatsUpdate} 
                                        initialFormats={initialFormats}
                                        videoSrc={videoSrc}
                                        destination={destination}
                                    />

                                </div>

                                <div className={styles.stepFooter}>
                                </div>



                            </div>         
                        }      

                        {
                            visibleSteps.step5 &&
                            <div className={styles.stepContainer}>

                                <div className={styles.stepHeader}>
                                    <div className={styles.stepIndex}>
                                        { stepsStatus.additionalSettings.complete ? <StepComlete /> : <Step4Image />}
                                    </div>   
                                    <div className={styles.stepTitle}>More Settings</div>
                                </div>

                                <div className={styles.stepBody}>
                                    
                                    <AdditionalSettings
                                        callbackUrl={queryObject.query?.callback_url || ''}
                                        encoderVersion={queryObject.query?.encoder_version || '1'}
                                        useSubtaskCallback={queryObject.query?.use_subtask_callback || 0}
                                        upscale={queryObject.query?.upscale || 0}
                                        retryOnError={queryObject.query?.retry_on_error || 0}
                                        onUpdate={handleAdditionalSettingsUpdate}
                                    />

                                </div>

                                <div className={styles.stepFooter}>
                                </div>



                            </div>        
                        }

                    </div>

                    {
                        visibleSteps.step5 &&
                        <div className={styles.transcodingFooter}>
                            <Select                        
                                className={styles.transcodingControl}
                                variant='menu'
                                icon={<Save />}
                                placeholder='Save Template'
                                onChange={handleSaveTemplate}
                                options={templateMenuOptions}
                                dropUp={true}
                            />
                            <Button
                                className={styles.transcodingControl}
                                icon={<Launch label="Launch Transcoding" />}
                                onClick={handleLaunchTranscoding}
                                state={!selectedProject?.api_key || !videoSrc ? "disabled" : undefined}
                            >
                                Launch Transcoding
                            </Button>   
                        </div>                        
                    }



                    {/* <div className={styles.transcodingFooter}>
                        <Select                        
                            className={styles.transcodingControl}
                            variant='menu'
                            icon={<Save />}
                            placeholder='Save Template'
                            onChange={handleSaveTemplate}
                            options={templateMenuOptions}
                        />
                        <Button
                            className={styles.transcodingControl}
                            icon={<Launch label="Launch Transcoding" />}
                            onClick={handleLaunchTranscoding}
                            state={!selectedProject?.api_key || !videoSrc ? "disabled" : undefined}
                        >
                            Launch Transcoding
                        </Button>          


                        <Modal isOpen={isSaveTemplateModalOpen} onClose={closeSaveTemplateModal}
                            header={
                                <>
                                    <h3>Save as New Template</h3>
                                </>
                            }
                            footer={
                                <>
                                    <Button
                                        variant="modalFooter"
                                        onClick={closeSaveTemplateModal}
                                        type="secondary"
                                    >
                                        Cancel
                                    </Button>
                                    <Button 
                                        variant="modalFooter"
                                        onClick={handleSaveNewTemplate}
                                        // onClick={()=>saveNewTemplate(credentials.transcoding_template_name, queryObject)} 
                                        state={isSavingTemplate ? "disabled" : undefined}
                                    >
                                        Save as New
                                    </Button> 
                                </>
                            }
                        >
                            <div className="form">
                                <div className="row">
                                    <InputField
                                        inputText={credentials.transcoding_template_name}
                                        onChange={handleInputChangeTemplate}
                                        name="transcoding_template_name"
                                        label="New Template Name"


                                        state={savingNewTemplateError || validationErrors.transcoding_template_name ? 'error' : 'default'}
                                        hint={savingNewTemplateError ? savingNewTemplateError : validationErrors.transcoding_template_name ? validationErrors.transcoding_template_name : undefined}
                                    />                           
                                </div>
                            </div>   
                        </Modal>       
                        
                        <Modal isOpen={isSaveTemplateSuccessModalOpen} onClose={closeSaveTemplateSuccessModal}
                            variant="popUp"
                            icon={<Success />}
                            header={
                                <>
                                    <h3>Template Saved</h3>
                                    <p>The transcoding template is successfully saved.</p>                          
                                </>
                            }
                            footer={
                                <Button 
                                    variant="modalFooter"
                                    type="secondary"
                                    onClick={closeSaveTemplateSuccessModal} 
                                >
                                    Close
                                </Button>                     
                            }
                        >
                        </Modal>      

                        <Modal isOpen={isStartTranscodingModalOpen} onClose={closeStartTranscodingModal}
                            header={
                                <>
                                    <h3>Transcoding Job</h3>
                                    <p>Don't leave this page until Transcoding Job started</p>   
                                </>
                            }
                            footer={
                                <>
                                    <Button
                                        variant="modalFooter"
                                        onClick={closeStartTranscodingModal}
                                        type="secondary"
                                    >
                                        Close
                                    </Button>
                                    <Button 
                                        variant="modalFooter"
                                        // onClick={() => console.log("Redirect to transcoding job page: ", taskToken)}
                                        onClick={goToTranscodingJobPage}
                                        //state={isSavingTemplate ? "disabled" : undefined}
                                        // Should be disabled till trancoding job started, aslo disable if there is no task token
                                        state={!isTranscodingStarted && !taskToken ? "disabled" : undefined}
                                    >
                                        See Transcoding Job
                                    </Button> 
                                </>
                            }
                        >
                            <div className={styles.transcodingWindowContent}>

                                <div className={styles.transcodingStatusContainer}>
                                    <div>Status: <strong><span>{transcodingStatus}</span></strong></div>   
                                    {
                                        isTranscodingStarted && taskToken && 
                                        <div>
                                            See Transcoding Job details at <Link 
                                                to={`/my_encodings/${taskToken}`}
                                                state={{ projectName: selectedProject?.name }}
                                                //target="_blank"                                            
                                            >
                                                {taskToken}
                                            </Link>   

                                        </div>                                      
                                    }                               
                                    {transcodingError && <div className={styles.errorMessage}>{transcodingError}</div>}                            
                                </div>     

                                {
                                    directUploadFile && 
                                    <div className={styles.fileUploadContainer}>
                                        <FileCard 
                                            file={directUploadFile} 
                                            //onDelete={handleDelete} // disable ability to delete here since delete should be done in Source Video selector
                                            onRetry={handleRetry} 
                                            onCancel={handleCancelUpload}
                                            error={uploadError} 
                                            progress={uploadProgress}
                                            isUploading={isUploading}
                                            isUploadComplete={isUploadComplete}
                                        />      

                                        {
                                            isUploading &&
                                            <div className={styles.warningContainer}>
                                                <WarningIcon className={styles.warningIcon}/>
                                                <div className={styles.warningMessage}>Please don't leave the page while your file is uploading.</div>
                                            </div>
                                        }                                       
                                    </div>       
                                }    
                                                

                            </div>   
                        </Modal>                                                                 
                    </div>        */}

                    {configError && 
                        <div className={styles.templateErrorsContainer}>
                            <div className={styles.errorMessage}>{configError}</div>
                        </div>
                    }   
                    
                    {savingTemplateError && 
                        <div className={styles.templateErrorsContainer}>
                            <div className={styles.errorMessage}>{savingTemplateError}</div>
                        </div>
                    }    

                    {/* {isUploading && (
                        <div>
                            <p>Uploading file... {uploadProgress.toFixed(2)}%</p>
                            {uploadError && <p>Error: {uploadError}</p>}
                        </div>
                    )}               */}

                    {/* {
                        directUploadFile && 
                        <div>
                            <FileCard 
                                file={directUploadFile} 
                                //onDelete={handleDelete} // disable ability to delete here since delete should be done in Source Video selector
                                onRetry={handleRetry} 
                                onCancel={handleCancelUpload}
                                error={uploadError} 
                                progress={uploadProgress}
                                isUploading={isUploading}
                                isUploadComplete={isUploadComplete}
                            />      

                            {
                                isUploading &&
                                <div className={styles.warningContainer}>
                                    <WarningIcon className={styles.warningIcon}/>
                                    <div className={styles.warningMessage}>Please don't leave the page while your file is uploading.</div>
                                </div>
                            }                                       
                        </div>       
                    }                     */}
                    

                </div>

                <div className={styles.right}>

                    <div className={styles.rightBackground}></div>

                    <div>
                        <div className={styles.stepRow}>
                            <Select            
                                className={styles.dropdownSelector}                
                                onChange={handleProjectChange}
                                label='Project'
                                // link={{to: '/project/vod_projects', text: 'Manage'}}                      
                                name='project'
                                placeholder='Select Project'
                                options={projectOptions}
                                value={selectedProject ? selectedProject.id : ''}
                                icon={<Project />}
                                overrideIcon={false}
                                wrappedIcon={true}
                                hideExtraInSelected={true}
                                menuItems = {[
                                    {
                                        icon: <Project label="Projectt" />,
                                        onClick: () => navigate(`/project/vod_projects`),
                                        text: "Manage Projects",
                                    }
                                  ]    
                                }                               
                            />    
                            <Select                       
                                className={styles.dropdownSelector}        
                                onChange={handleTemplateChange}
                                label='Template'
                                //link={{to: '/transcoding_templates', text: 'Manage'}}                      
                                name='template'
                                placeholder='None'
                                options={templateOptions}
                                value={selectedTemplate ? selectedTemplate.id : ''}
                                icon={<VideoFile />}
                                overrideIcon={false}
                                wrappedIcon={true}
                                hideExtraInSelected={true}
                                menuItems = {[
                                    {
                                        icon: <VideoFile label="Templates" />,
                                        onClick: () => navigate(`/transcoding_templates`),
                                        text: "Manage Templates",
                                    }
                                  ]    
                                }                                   
                            /> 
                        </div>                    
                    </div>

                    <OutputJSON 
                        queryObject={queryObject} // there is a bug here when switching templates
                        //queryObject={selectedTemplate?.query || initialQueryObject}
                        handleSaveTemplate={handleSaveTemplate}
                        handleLaunchTranscoding={handleLaunchTranscoding}
                        templateMenuOptions={templateMenuOptions}
                        selectedProject={selectedProject}
                        videoSrc={videoSrc}
                    />
                </div>

            </div>

            {/* this footer is sticky to the bottom */}
            {/* <div className={styles.footer}>
                <Select                        
                    className={styles.transcodingControl}
                    variant='menu'
                    icon={<Save />}
                    placeholder='Save Template'
                    onChange={handleSaveTemplate}
                    options={templateMenuOptions}
                    dropUp={true}
                />
                <Button
                    className={styles.transcodingControl}
                    icon={<Launch label="Launch Transcoding" />}
                    onClick={handleLaunchTranscoding}
                    state={!selectedProject?.api_key || !videoSrc ? "disabled" : undefined}
                >
                    Launch Transcoding
                </Button>          

            </div> */}

            {/* keep all modals here */}

            <Modal isOpen={isSaveTemplateModalOpen} onClose={closeSaveTemplateModal}
                header={
                    <>
                        <h3>Save as New Template</h3>
                    </>
                }
                footer={
                    <>
                        <Button
                            variant="modalFooter"
                            onClick={closeSaveTemplateModal}
                            type="secondary"
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="modalFooter"
                            onClick={handleSaveNewTemplate}
                            // onClick={()=>saveNewTemplate(credentials.transcoding_template_name, queryObject)} 
                            state={isSavingTemplate ? "disabled" : undefined}
                        >
                            Save as New
                        </Button> 
                    </>
                }
            >
                <div className="form">
                    <div className="row">
                        <InputField
                            inputText={credentials.transcoding_template_name}
                            onChange={handleInputChangeTemplate}
                            name="transcoding_template_name"
                            label="New Template Name"


                            state={savingNewTemplateError || validationErrors.transcoding_template_name ? 'error' : 'default'}
                            hint={savingNewTemplateError ? savingNewTemplateError : validationErrors.transcoding_template_name ? validationErrors.transcoding_template_name : undefined}
                        />                           
                    </div>
                </div>   
            </Modal>       
            
            <Modal isOpen={isSaveTemplateSuccessModalOpen} onClose={closeSaveTemplateSuccessModal}
                variant="popUp"
                icon={<Success />}
                header={
                    <>
                        <h3>Template Saved</h3>
                        <p>The transcoding template is successfully saved.</p>                          
                    </>
                }
                footer={
                    <Button 
                        variant="modalFooter"
                        type="secondary"
                        onClick={closeSaveTemplateSuccessModal} 
                    >
                        Close
                    </Button>                     
                }
            >
            </Modal>      

            <Modal isOpen={isStartTranscodingModalOpen} onClose={closeStartTranscodingModal}
                header={
                    <>
                        <h3>Transcoding Job</h3>
                        <p>Don't leave this page until Transcoding Job started</p>   
                    </>
                }
                footer={
                    <>
                        <Button
                            variant="modalFooter"
                            onClick={closeStartTranscodingModal}
                            type="secondary"
                        >
                            Close
                        </Button>
                        <Button 
                            variant="modalFooter"
                            // onClick={() => console.log("Redirect to transcoding job page: ", taskToken)}
                            onClick={goToTranscodingJobPage}
                            //state={isSavingTemplate ? "disabled" : undefined}
                            // Should be disabled till trancoding job started, aslo disable if there is no task token
                            state={!isTranscodingStarted && !taskToken ? "disabled" : undefined}
                        >
                            See Transcoding Job
                        </Button> 
                    </>
                }
            >
                <div className={styles.transcodingWindowContent}>

                    <div className={styles.transcodingStatusContainer}>
                        <div>Status: <strong><span>{transcodingStatus}</span></strong></div>   
                        {
                            isTranscodingStarted && taskToken && 
                            <div>
                                See Transcoding Job details at <Link 
                                    to={`/my_encodings/${taskToken}`}
                                    state={{ projectName: selectedProject?.name }}
                                    //target="_blank"                                            
                                >
                                    {taskToken}
                                </Link>   

                            </div>                                      
                        }                               
                        {transcodingError && <div className={styles.errorMessage}>{transcodingError}</div>}                            
                    </div>     

                    {
                        directUploadFile && 
                        <div className={styles.fileUploadContainer}>
                            <FileCard 
                                file={directUploadFile} 
                                //onDelete={handleDelete} // disable ability to delete here since delete should be done in Source Video selector
                                onRetry={handleRetry} 
                                onCancel={handleCancelUpload}
                                error={uploadError} 
                                progress={uploadProgress}
                                isUploading={isUploading}
                                isUploadComplete={isUploadComplete}
                            />      

                            {
                                isUploading &&
                                <div className={styles.warningContainer}>
                                    <WarningIcon className={styles.warningIcon}/>
                                    <div className={styles.warningMessage}>Please don't leave the page while your file is uploading.</div>
                                </div>
                            }                                       
                        </div>       
                    }    
                                    

                </div>   
            </Modal> 


        </div>
    );
};

