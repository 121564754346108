import PropTypes from "prop-types";
import React from "react";

export const VideoPlayer = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M2.75 6.25C2.75 4.63801 4.15874 3.25 6 3.25H14C15.8413 3.25 17.25 4.63801 17.25 6.25V13.75C17.25 15.362 15.8413 16.75 14 16.75H6C4.15874 16.75 2.75 15.362 2.75 13.75V6.25ZM6 1.75C3.42298 1.75 1.25 3.71986 1.25 6.25V13.75C1.25 16.2801 3.42298 18.25 6 18.25H14C16.577 18.25 18.75 16.2801 18.75 13.75V6.25C18.75 3.71986 16.577 1.75 14 1.75H6ZM7.6001 12.4356V7.56449C7.6001 7.47006 7.62382 7.37713 7.66908 7.29423C7.8185 7.02059 8.16169 6.91976 8.43562 7.06902L12.9056 9.50459C13.0008 9.55648 13.0791 9.63466 13.131 9.7298C13.2804 10.0034 13.1795 10.3463 12.9056 10.4955L8.43562 12.9311C8.35263 12.9763 8.25961 13 8.16508 13C7.85305 13 7.6001 12.7473 7.6001 12.4356Z" fill="currentColor"/>
    </svg>    
  );
};

VideoPlayer.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

VideoPlayer.defaultProps = {
  color: 'currentColor', // Or any default color
};