import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import BriefPanel from "pages/StatisticsPage/components/BriefPanel";
import TopRegions from "pages/StatisticsPage/StorageTab/TopRegions";
import TopBuckets from "pages/StatisticsPage/StorageTab/TopBuckets";
import UsageBy from "pages/StatisticsPage/StorageTab/UsageBy";
import Controls from 'components/Controls';
import Columns from "components/Columns";
import VerticalGapsLayout from "components/VerticalGapsLayout";
import Chart from "pages/StatisticsPage/components/Chart";
import style from './style.module.css';
import {Select} from "components/Select";
import {regionCodeToColor, regionCodeToName} from "pages/StatisticsPage/config";
import BriefItem from "pages/StatisticsPage/components/BriefPanel/BriefItem";
import StorageTooltipContent from "pages/StatisticsPage/StorageTab/StorageTooltipContent";
import NoChartData from "pages/StatisticsPage/components/Chart/NoData";

function StorageTab({
  datepicker,
  storage,
}) {
  const [selectedRegion, setSelectedRegion] = useState('all');
  
  const totalStorage = storage.total.status.data?.['total_storage']?.value;
  const totalCost = storage.total.status.data?.['total_cost']?.value;
  
  const {
    statsByRegion,
    regionOptions,
    statsByDays,
    dataEmpty,
  } = useMemo(() => {
    const statsByRegion = {};
    const statsByDays = Object.fromEntries(datepicker.dates.map((date) => [
      date,
      {
        date,
      }
    ]));
    const regions = {};
    let dataEmpty = true;

    for (let obj of storage.stats.status.data) {
      const value = obj.storage_gb_month;
      if (typeof value === 'number' && Number.isFinite(value) && value > 0) {
        regions[obj.region] = 0;
        
        statsByDays[obj.date] = statsByDays[obj.date] || {
          date: obj.date,
        };
        dataEmpty = false;
        statsByDays[obj.date][obj.region] = (statsByDays[obj.date][obj.region] || 0) + value;
        statsByRegion[obj.region] = (statsByRegion[obj.region] || 0) + value;
      }
    }
    const uniqueRegions = Object.keys(regions);
    const regionOptions = [
      uniqueRegions.length > 0 ? [{
        label: 'All Regions',
        value: 'all',
      }] : [],
      uniqueRegions.map((regionCode) => ({
        value: regionCode,
        label: regionCodeToName[regionCode],
      }))].flat();
    
    return {
      dataEmpty,
      uniqueRegions,
      regionOptions,
      statsByRegion,
      statsByDays: Object.values(statsByDays).map((i) => ({
        ...regions,
        ...i,
      })),
    };
  }, [datepicker.dates, storage.stats.status.data]);

  const selectedRegionsArray = useMemo(() => {
    if (selectedRegion === 'all') {
      return regionOptions.filter((i) => i.value !== 'all').map((i) => i.value);
    } else {
      return [selectedRegion];
    }
  }, [regionOptions, selectedRegion]);
  
  const noDataInSelected = useMemo(() => {
    if (dataEmpty) {
      return true;
    } else {
      const sum = selectedRegionsArray.reduce((acc, selectedRegion) => acc + (statsByRegion[selectedRegion] || 0), 0);
      return typeof sum === 'number' && Number.isFinite(sum) && sum === 0;
    }
  }, [dataEmpty, selectedRegionsArray, statsByRegion]);

  return (
    <VerticalGapsLayout>
      <Controls
        title="Bucket Usage"
        rightContent={
          <div>
            <Select
              options={regionOptions}
              className={ style.regionSelect }
              onChange={( e ) => setSelectedRegion( e?.target?.value )}
              value={selectedRegion}
              disabled={storage.stats.status.loading || regionOptions.length === 0}
            />
          </div>
        }
      />
      <Chart
        chartData={statsByDays}
        loading={storage.stats.status.loading}
        keyToColor={regionCodeToColor}
        dataKeys={selectedRegionsArray}
        contentComponent={StorageTooltipContent}
        legend="GB"
      >
        {
          noDataInSelected ? (
            <NoChartData
              startDate={datepicker.startDate}
              endDate={datepicker.endDate}
            />
          ) : null
        }
      </Chart>
      <BriefPanel>
        <BriefItem
          heading="New Storage"
          value={storage.total.status.data?.['new_storage']?.value}
          loading={storage.total.status.loading}
          type="gigabyte"
        />
        <BriefItem
          heading="Total Storage"
          value={totalStorage}
          loading={storage.total.status.loading}
          type="gigabyte"
        />
        <BriefItem
          heading="Total Cost"
          value={totalCost}
          type="money"
          loading={storage.total.status.loading}
        />
      </BriefPanel>
      <Columns>
        <TopRegions
          stats={ storage.topRegion.status }
        />
        <TopBuckets
          stats={ storage.topBucket.status }
        />
      </Columns>
      <UsageBy stats={ storage.byBuckets.status }/>
    </VerticalGapsLayout>
  )
}

StorageTab.propTypes = {
  datepicker: PropTypes.object.isRequired,
};

export default StorageTab;