import React from 'react';
import PropTypes from 'prop-types';
// import { Warning } from './Warning';
import { WarningMessage } from 'components/WarningMessage'
import styles from './index.module.css';

export const Warnings = ({ warnings = [] }) => {
    return (
        <div className={styles.warningsContainer}>
            {warnings.map((warning, index) => (
                <WarningMessage
                    key={index}
                    message={warning?.message}
                    details={warning?.details}
                    tag={warning?.tag}
                />
            ))}
        </div>
    );
};

Warnings.propTypes = {
    warnings: PropTypes.arrayOf(PropTypes.shape({
        details: PropTypes.string,
        message: PropTypes.string,
        tag: PropTypes.string,
    })),
};
