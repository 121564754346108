import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'components/Button';
import { ReactComponent as MediaStorage } from 'assets/images/MediaStorage.svg';
import { ReactComponent as MediaStorage2 } from 'assets/images/MediaStorage2.svg';
import style from './style.module.css';
import {mediaStorageAnimation, transcodingAnimation} from './animations';
import Lottie from "lottie-react";
import ErrorMessage from "components/ErrorMessage";

function MediaStorageEnabledStatus({
  variant = 'buckets',
  mediaStorageTokens,
}) {
  const button = <Button onClick={ mediaStorageTokens.handleSubmit }>Enable Media Storage</Button>;
  const errorMessage = mediaStorageTokens.errorMessage ? <ErrorMessage>{ mediaStorageTokens.errorMessage }</ErrorMessage> : null;
  
  if (mediaStorageTokens.initialized) {
    if (mediaStorageTokens.submitting) {
      let message = 'Please wait a few minutes while your Media Storage environment is being configured.';
      if (variant === 'buckets') {
        return (
          <div className={style.wrapper}>
            <Lottie animationData={mediaStorageAnimation} loop={true} />
            <p className={ `${ style.description } ${ style.bucketsDescriptionAfterSubmit }` }>{ message }</p>
          </div>
        )
      } else {
        return (
          <div className={style.wrapper}>
            <Lottie animationData={transcodingAnimation} loop={true} />
            <p className={ `${ style.description } ${ style.miniDescriptionAfterSubmit }` }>{ message }</p>
          </div>
        )
      }
    } else {
      if (variant === 'buckets') {
        return (
          <div className={style.wrapper}>
            <MediaStorage/>
            <h4 className={style.heading}>Account not enabled for Media Storage</h4>
            <p className={ `${ style.description } ${ style.bucketsDescription }` }>Please enable your account for Media Storage to get started.</p>
            { errorMessage }
            { button }
          </div>
        )
      } else if (variant === 'buttonOnly') {
        return (
          <div>
            { button }
            { errorMessage }
          </div>
        );
      }
      else {
        return (
          <div className={style.wrapper}>
            <MediaStorage2/>
            <p className={ `${ style.description } ${ style.miniDescription }`  }>Account not enabled for Media Storage</p>
            { errorMessage }
            { button }
          </div>
        );
      }
    }
  }
}

MediaStorageEnabledStatus.propTypes = {
  variant: PropTypes.string,
  initialSubmitted: PropTypes.bool,
};

export default MediaStorageEnabledStatus;
