import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {AccountShield, ChevronDown, Globe, PlaybackId as PlaybackIdIcon} from "icons";
import CopyToClipboard from "components/CopyToClipboard";
import style from './style.module.css';
import Tooltip from "components/Tooltip";
import CellDetails from "components/CellDetails";
import MiniTable from "components/Table/MiniTable";

const statusToIcon = {
  public: Globe,
  authenticated: AccountShield,
};
const statusToReadable = {
  public: 'Public',
  authenticated: 'Authenticated',
};

function PlaybackId({ playbackIdList }) {
  const firstItem = playbackIdList[0];
  const {
    playback_url: playbackUrl,
  } = firstItem;

  return (
    <div className={style.wrapper}>
      {
        (playbackIdList.length > 1)
        ?
        (
          <MultiplePlaybackId playbackIdList={ playbackIdList } />
        )
        :
        (
          <>
            <Status
              playbackId={ firstItem }
              tooltip={true}
            >
              { playbackUrl }
            </Status>
            <CopyToClipboard text={playbackUrl} />
          </>
        )
      }
    </div>
  );
}

PlaybackId.propTypes = {
  playbackIdList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    policy: PropTypes.oneOf( Object.keys(statusToReadable) ),
    playback_url: PropTypes.string.isRequired,
  })),
}

function MultiplePlaybackId({ playbackIdList }) {
  const dropdownButtonRef = useRef();
  const [open, setOpen] = useState(false);

  return (
    <>
      <div>
        <PlaybackIdIcon className={ style.playbackIcon }/>
      </div>
      <span className={ style.playbackText }>
        { ` ${ playbackIdList.length }\u00A0Playback\u00A0URLs` }
      </span>
      <button
        className={style.dropdownButton}
        onClick={() => setOpen(!open)}
        aria-haspopup="true"
        aria-expanded={open}
        type="button"
        ref={dropdownButtonRef}
      >
        <ChevronDown className={ style.icon }/>
      </button>
      <CellDetails
        open={open}
        hideMenu={() => setOpen(false)}
        dropdownWidth={360}
        dropdownButtonRef={dropdownButtonRef}
      >
        <MiniTable
          headings={[
            'Playback Url',
            'Policy',
          ]}
        >
          { playbackIdList.map(( playbackId ) => (
            <tr key={ playbackId.id }>
              <td>
                <div className={ style.cellContainer }>
                    <span className={ style.cellLimited }>
                      { playbackId['playback_url'] }
                    </span>
                    <CopyToClipboard text={ playbackId['playback_url'] } />
                </div>
              </td>
              <td>
                <Status playbackId={ playbackId }>
                  { statusToReadable[ playbackId.policy ] }
                </Status>
              </td>
            </tr>
          )) }
          </MiniTable>
      </CellDetails>
    </>
  );
}

MultiplePlaybackId.propTypes = {
  playbackIdList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    policy: PropTypes.oneOf( Object.keys(statusToReadable) ),
  })),
}

function Status({ playbackId, children, tooltip }) {
  const { policy } = playbackId;
  const Icon = statusToIcon[policy];

  return (
    <div className={ style.statusContainer }>
      {
        tooltip ? (
          <Tooltip text={ statusToReadable[ policy ] || '' }>
            { Icon && ( <Icon className={style.icon}/> ) }
          </Tooltip>
        ) : (
          <div>
            { Icon && ( <Icon className={style.icon}/> ) }
          </div>
        )
      }
      <span className={ style.cellLimited }>
        { children }
      </span>
    </div>
  );
}

Status.propTypes = {
  playbackId: PropTypes.shape({
    id: PropTypes.string.isRequired,
    policy: PropTypes.oneOf( Object.keys(statusToReadable) ),
  }),
}

Status.propTypes = {
  playbackIdList: PropTypes.array,
};

export default PlaybackId;
