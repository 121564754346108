import React from 'react';
import PropTypes from 'prop-types';

export function ChevronDown2({ className, color, label }) {
  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg
      className={className}
      role="img"
      aria-label={label}
      style={style}
      width="12"
      height="7"
      fill="none"
      >
      <path
        d="M10.13 1.63 6 5.75 1.87 1.62"
        stroke="currentColor"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
        />
    </svg>
  );
}

ChevronDown2.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

ChevronDown2.defaultProps = {
  color: 'currentColor', // Or any default color
};
