import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable} from "@tanstack/react-table";
import Table from "components/Table";
import Region from "components/Region";
import Card from "pages/StatisticsPage/components/Card";
import GbReadable from "components/Table/Cells/GbReadable";
import getTableValueFallback from "helpers/getTableValueFallback";
import MoneyRounded from "components/Table/Cells/MoneyRounded";
import {ReactComponent as BucketImage} from 'assets/images/Bucket.svg';
import style from './style.module.css';

const columnIds = {
  name: 'name',
  region: 'region',
  new: 'new',
  total: 'total',
  cost: 'cost',
};

function TopBuckets({
  stats,
}) {
  
  const [sorting, setSorting] = useState([{
    id: columnIds.total,
    desc: true,
  }]);
  
  const columns = useMemo(() => [
    {
      id: columnIds.name,
      accessorKey: 'bucket_name',
      header: () => <span>Bucket Name</span>,
      cell: (info) => <span className="oneLineEllipsis semiBold">{ info.getValue() }</span>,
    },
    {
      id: columnIds.region,
      accessorKey: 'region',
      cell: (info) => <Region regionCode={info.getValue()}/>,
      header: () => <span>Region</span>,
      meta: {
        className: style.regionColumn,
      },
    },
    {
      id: columnIds.new,
      header: () => <span>New</span>,
      accessorKey: 'new_storage_gb_month',
      cell: (info) => {
        const value = info.getValue();
        if (typeof value === 'number' && Number.isFinite(value)) {
          return <GbReadable value={info.getValue()}/>;
        }
        return getTableValueFallback(null);
      },
      meta: {
        className: style.newColumn,
      },
    },
    {
      id: columnIds.total,
      header: () => <span>Total</span>,
      accessorKey: 'storage_gb_month',
      cell: (info) => <GbReadable value={info.getValue()}/>,
      meta: {
        className: style.totalColumn,
      },
    },
    {
      id: columnIds.cost,
      accessorKey: 'amount',
      header: () => <span>Cost</span>,
      cell: (info) => <div className={style.costValueWrapper}><MoneyRounded value={info.getValue()} semiBold /></div> ,
      meta: {
        className: style.costColumn,
        align: 'right',
      },
    }
  ], []);

  const table = useReactTable({
    data: stats.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    /* Sorting */
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      pagination: {
        pageIndex: 0,
        pageSize: 5,
      },
    },
  });

  return (
    <Card title="Top Buckets">
      <Table
        { ...stats }
        table={table}
        lastLineInvisible
        noDataImage={<BucketImage/>}
        noDataDescription="No data found"
        skeletonRowCount={5}
        minWidth={500}
      />
    </Card>
  )
}

TopBuckets.propTypes = {
  stats: PropTypes.object.isRequired,
};

export default TopBuckets;