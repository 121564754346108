import React from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { Link } from 'react-router-dom';
import styles from './Label.module.css';
import { Help } from "../../icons";
import { DocsTooltip } from '../DocsTooltip';

import { OptionsMenu } from 'components/OptionsMenu';

export const Label = ({ text, help, size = "md", link, variant, menuItems }) => {

  const variantClassName = styles[variant];

  // Conditionally render the help icon with either a simple or interactive tooltip
  const renderHelp = () => {
    if (typeof help === 'string') {
      return (
        <Tippy content={help}>
          <div className={styles.iconContainer}><Help className={styles.icon} /></div>
        </Tippy>
      );
    } else if (typeof help === 'object') {
      return (
        <DocsTooltip tooltipProps={help}>
          <div className={styles.iconContainer}><Help className={styles.icon} /></div>
        </DocsTooltip>
      );
    }
  };

  return (
    <div className={`${styles.label} ${styles[size]} ${variantClassName || ''}`}>
      <div className={styles.textWrapper}>
        <div className={styles.text}>{text}</div>
        {help && renderHelp()}
      </div>
      {link && link?.to && link?.text && (
        <Link to={link.to} className={styles.link}>
          {link.text}
        </Link>
      )}
      {link && link?.onClick && link?.text && (
        <span className={styles.link} onClick={link.onClick}>
          {link.text}
        </span>
      )}
      {
        menuItems && menuItems.length > 0 &&
        <OptionsMenu menuItems={menuItems} size={size} />
      }
    </div>
  );
};

Label.propTypes = {
  text: PropTypes.string.isRequired,
  help: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  size: PropTypes.oneOf(["sm", "md", "lg", "xl"]),
  link: PropTypes.shape({
    to: PropTypes.string,
    text: PropTypes.string.isRequired
  })
};

Label.defaultProps = {
  help: null,
  size: "md",
  text: '',
};



// import React from 'react';
// import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import styles from './Label.module.css'; // Ensure you have a corresponding CSS module file for Label
// import { Help } from "../../icons";

// export const Label = ({ text, help, size = "md", link }) => {
//   return (
//     <div className={`${styles.label} ${styles[size]}`}>
//       <div className={styles.textWrapper}>
//         <div className={styles.text}>{text}</div>
//         {help && <Help className={styles.icon} />}        
//       </div>
//       {
//         link && link?.to && link?.text && 
//         <Link to={link.to} className={styles.link}>{link.text}</Link>
//       }
//     </div>
//   );
// };

// Label.propTypes = {
//   text: PropTypes.string.isRequired,
//   size: PropTypes.oneOf(["sm", "md", "lg"]),
//   help: PropTypes.bool
// };

// Label.defaultProps = {
//   help: false,
//   size: "md",
//   text: '',
// };



// import React from 'react';
// import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import styles from './Label.module.css'; // Ensure you have a corresponding CSS module file for Label
// import { Help } from "../../icons";

// export const Label = ({ text, help, size = "md", link }) => {
//   return (
//     <div className={`${styles.label} ${styles[size]}`}>
//       <div className={styles.textWrapper}>
//         <div className={styles.text}>{text}</div>
//         {help && <Help className={styles.icon} />}        
//       </div>
//       {
//         link && link?.to && link?.text && 
//         <Link to={link.to} className={styles.link}>{link.text}</Link>
//       }
//     </div>
//   );
// };

// Label.propTypes = {
//   text: PropTypes.string.isRequired,
//   size: PropTypes.oneOf(["sm", "md", "lg"]),
//   help: PropTypes.bool
// };

// Label.defaultProps = {
//   help: false,
//   size: "md",
//   text: '',
// };
