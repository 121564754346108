import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from "components/Button";
import {Delete} from "icons";
import {Modal} from "components/Modal";
import {ReactComponent as TemplateWithWarning} from "assets/images/TemplateWithWarning.svg";
import {deleteTemplate} from "api/qencode-account-api";
import {useAuth} from "context/AuthContext";
import ErrorMessage from "components/ErrorMessage";
import style from './style.module.css';

function DeleteModal({
   open,
   handleCancel,
   templateId,
   onSuccessfulFinish,
   name,
}) {
    const { getToken } = useAuth();
    const [errorMessage, setErrorMessage] = useState();
    const [submitting, setSubmitting] = useState(false);

    async function handleConfirmDeletion() {
        const accessToken = await getToken();
        setSubmitting(true);
        const {success, error} = await deleteTemplate({
            accessToken,
            id: templateId,
        });
        if (success) {
            clearFormAndClose();
            onSuccessfulFinish?.();
        } else {
            setErrorMessage(error);
        }
        setSubmitting(false);
    }

    function clearFormAndClose() {
      setErrorMessage();
      handleCancel();
    }

    return (
        <Modal
            isOpen={open}
            variant="popUp"
            onClose={clearFormAndClose}
            header={
                <>
                    <TemplateWithWarning/>
                    <h3>
                        Delete this Transcoding Template!
                    </h3>
                    <p>
                        Are you sure you want to delete transcoding template <span className={ style.name }>{ name }</span>?
                    </p>
                    { errorMessage && <ErrorMessage>{ errorMessage }</ErrorMessage> }
                </>
            }
            footer={
                <>
                    <Button
                        type="secondary"
                        onClick={clearFormAndClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        icon={ <Delete/> }
                        type='danger'
                        onClick={handleConfirmDeletion}
                        state={ submitting ? 'disabled' : 'default' }
                    >
                        Delete
                    </Button>
                </>
            }
        >
        </Modal>
    )
}

DeleteModal.propTypes = {
    open: PropTypes.bool,
    handleCancel: PropTypes.func,
    templateId: PropTypes.string,
    onSuccessfulFinish: PropTypes.func,
};

export default DeleteModal;