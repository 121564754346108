import { Navigate } from 'react-router-dom';
// import HomePage from './pages/HomePage';
import DashboardPage from './pages/DashboardPage';
import { SettingsPage } from './pages/SettingsPage';
import { BillingPage } from './pages/BillingPage';
import { TranscodingPage } from './pages/TranscodingPage';
import { TranscodingJobPage } from 'pages/TranscodingJobPage';
import TemplatePage from './pages/TemplatePage';
import TestPage from './pages/TestPage';
// import TestPage2 from './pages/TestPage2';
import { LoginPage, SignupPage, CompleteSignupPage, SetNewPasswordPage } from './pages/AuthPages';

//import Layout from './components/Layout';

import { AuthLayout, MainLayout } from './layouts';
import DomainsPage from "./pages/DomainsPage";
import SigningKeysPage from "./pages/SigningKeysPage";
import TranscodingProjectsPage from "pages/Transcoding/ProjectsPage";
import TemplatesPage from "pages/Transcoding/TemplatesPage";
import JobHistory from "pages/Transcoding/JobHistoryPage";
import StreamsPage from "pages/Streaming/StreamsPage";
import JobPage from "pages/Transcoding/JobPage";
import StreamDetailPage from "pages/Streaming/StreamDetailPage";
import VideoPlayerListPage from "pages/Player/VideoPlayerListPage";
import VideoPlayerDetailPage from "pages/Player/VideoPlayerDetailPage";
import AccessKeysPage from "pages/MediaStorage/AccessKeysPage";
import BucketsPage from "pages/MediaStorage/BucketsPage";
import BucketDetailPage from "pages/MediaStorage/BucketDetailPage/intex";
import StatisticsPage from "pages/StatisticsPage";
import StreamProjectsPage from "pages/Streaming/StreamProjectsPage";

import { BucketDetailsPage } from 'pages/BucketDetailsPage';

const routeConfig = [
//   { path: "/", component: HomePage, pageName: "Home" },

  // starting route
  { path: "/", component: () => <Navigate to="/dashboard" replace />, pageName: "Home", layout: MainLayout },

  // auth pages
  { path: "/login", component: LoginPage, pageName: "Login", layout: AuthLayout },
  { path: "/signup", component: SignupPage, pageName: "Signup", layout: AuthLayout },
  { path: "/signup/:token", component: CompleteSignupPage, pageName: "Complete Signup", layout: AuthLayout },
  { path: "/password-set", component: SetNewPasswordPage, pageName: "Set New Password", layout: AuthLayout },

  // protected pages
  { path: "/dashboard", component: DashboardPage, pageName: "Dashboard", isProtected: true, restrictedRoles: ['billing'], layout: MainLayout },

  // Template Page
  { path: "/template", component: TemplatePage, pageName: "Template", layout: MainLayout },

  // For testing
  { path: "/test", component: TestPage, pageName: "Test Page", layout: MainLayout },

  // pages in user dropdown menu
  { path: "/settings", component: SettingsPage, pageName: "Account Settings", isProtected: true, layout: MainLayout },
  { path: "/billing", component: BillingPage, pageName: "Billing", isProtected: true, restrictedRoles: ['developer'], layout: MainLayout },

  // sibebar pages
  // SERVICES
  // Transcoding
  { path: "/request_builder", component: TranscodingPage, pageName: "Transcode a video", isProtected: true, restrictedRoles: ['billing'], layout: MainLayout },
  // { path: "/transcoding-jobs/:taskToken", component: TranscodingJobPage, pageName: "Transcoding Job", isProtected: true, layout: MainLayout },
  { path: "/my_encodings/:taskToken", component: TranscodingJobPage, pageName: "Transcoding Job", isProtected: true, restrictedRoles: ['billing'], layout: MainLayout },
  { path: "/my_encodings", component: JobHistory, pageName: "Job History", isProtected: true, restrictedRoles: ['billing'], layout: MainLayout },
  { path: "/encoding/:id", component: JobPage, pageName: "Job Detail", isProtected: true, restrictedRoles: ['billing'], layout: MainLayout },
  { path: "/project/vod_projects", component: TranscodingProjectsPage, pageName: "Video Transcoding Projects", isProtected: true, restrictedRoles: ['billing'], layout: MainLayout },
  { path: "/transcoding_templates", component: TemplatesPage, pageName: "Transcoding Templates", isProtected: true, restrictedRoles: ['billing'], layout: MainLayout },
  // Live Streaming
  { path: "/stream/list", component: StreamsPage, pageName: "Stream List", isProtected: true, restrictedRoles: ['billing'], layout: MainLayout },
  { path: "/stream/:id", component: StreamDetailPage, pageName: "Stream Detail", isProtected: true, restrictedRoles: ['billing'], layout: MainLayout },
  { path: "/project/live_projects", component: StreamProjectsPage, pageName: "Streaming Projects", isProtected: true, restrictedRoles: ['billing'], layout: MainLayout },
  // Media Storage
  { path: "/storage/list", component: BucketsPage, pageName: "Media Storage Buckets", isProtected: true, restrictedRoles: ['billing'], layout: MainLayout },
  { path: "/storage/:region/:bucket", component: BucketDetailPage, pageName: "Media Storage Buckets", isProtected: true, restrictedRoles: ['billing'], layout: MainLayout },
  { path: "/storage/list/bucket", component: BucketDetailsPage, pageName: "Media Storage Buckets", isProtected: true, restrictedRoles: ['billing'], layout: MainLayout },
  { path: "/storage/credentials", component: AccessKeysPage, pageName: "Media Storage Access Keys", isProtected: true, restrictedRoles: ['billing'], layout: MainLayout },
  // Content Delivery
  { path: "/delivery/domains", component: DomainsPage, pageName: "Domains", isProtected: true, restrictedRoles: ['billing'], layout: MainLayout },
  { path: "/delivery/signing-keys", component: SigningKeysPage, pageName: "Signing Keys", isProtected: true, restrictedRoles: ['billing'], layout: MainLayout },
  // Video Player
  { path: "/players", component: VideoPlayerListPage, pageName: "Video Player List", isProtected: true, restrictedRoles: ['billing'], layout: MainLayout },
  { path: "/player/:id", component: VideoPlayerDetailPage, pageName: "Player Settings", isProtected: true, restrictedRoles: ['billing'], layout: MainLayout },
  // Statistics
  { path: "/usage_stats", component: StatisticsPage, pageName: "Usage Statistics", isProtected: true, layout: MainLayout },
];

export default routeConfig;