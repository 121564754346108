import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {backendParamToColumn, columnIds} from "./config";
import useFilter from "components/FilterSidebar/useFilter";
import {
  getCoreRowModel,
  useReactTable
} from "@tanstack/react-table";
import style from './style.module.css';
import Table from "components/Table";
import {columnIdToReadableName} from "./config";
import DateFormatted from "components/DateFormatted";
import {useAuth} from "context/AuthContext";
import {listTasks} from "api/qencode-account-api";
import Pagination from "components/Table/Pagination";
import JobStatus from "pages/Transcoding/JobHistoryPage/JobStatus";
import {secondsToDuration} from "helpers/duration";
import Search from "pages/Transcoding/JobHistoryPage/Search";
import {Link} from "react-router-dom";
import FilterSidebar from "pages/Transcoding/JobHistoryPage/FilterSidebar";
import buildRequestParams from "pages/Transcoding/JobHistoryPage/buildRequestParams";
import CopyToClipboard from "components/CopyToClipboard";
import EmptyMessage from "pages/Transcoding/JobHistoryPage/EmptyMessage";
import TranscodeButton from "pages/Transcoding/JobHistoryPage/TranscodeButton";
import bytesToReadable from "helpers/bytesToReadable";
import Controls from "components/Controls";
import TableWrapper from "components/TableWrapper";

function JobHistory() {
  const {getToken} = useAuth();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadedSuccessfully, setLoadedSuccessfully] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [rowCount, setRowCount] = useState(0);
  const columns = useMemo(
    () => [
      {
        id: columnIds.jobId,
        accessorKey: 'token',
        enableSorting: false,
        cell: (info) => (
          <div className="cellContainer">
            <Link
              to={ `/my_encodings/${ info.getValue() }` }
              className={ `linkEllipsis` }
            >
              { info.getValue() }
            </Link>
            <CopyToClipboard text={info.getValue()}/>
          </div>
        ),
      },
      {
        id: columnIds.project,
        accessorKey: 'project_name',
        enableSorting: false,
        cell: (info) => (
          <span className={ style.projectName }>{ info.getValue() }</span>
        ),
      },
      {
        id: columnIds.videoLength,
        accessorKey: 'duration',
        cell: (info) => <span className={ 'cellLimited' }> { secondsToDuration(info.getValue()) }</span>,
      },
      {
        id: columnIds.size,
        accessorKey: 'source_size',
        cell: (info) => {
          return <span>{ bytesToReadable( info.getValue() * 1024 * 1024 ) }</span>;
        },
      },
      {
        id: columnIds.dateCreated,
        accessorKey: 'created',
        sortingFn: 'datetime',
        header: () => <span>{ columnIdToReadableName[columnIds.dateCreated] }</span>,
        cell: (info) => <span><DateFormatted utcString={info.getValue()}/></span>,
      },
      {
        id: columnIds.status,
        accessorKey: 'status',
        cell: (info) => (
          <JobStatus
            status={ info.getValue() }
            error={ info.row?.original?.error }
          />
        ),
      },
    ], []
  );

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  
  const [sorting, setSorting] = useState([{
    id: columnIds.dateCreated,
    desc: true,
  }]);
  const filter = useFilter({ setPagination });
  
  const list = useCallback(async function list(params) {
    const accessToken = await getToken();
    setLoading(true);
    
    const response = await listTasks({
      accessToken,
      params: params ? params : buildRequestParams(pagination, filter.columnFilters, sorting),
    });
    setLoading(false);
    setLoadedSuccessfully(response.success);
    setData(response.data || []);
    setRowCount(response.totalCount);
    
    if (typeof response.error === 'string') {
      setErrorMessage(response.error);
    } else if (Array.isArray(response.error)) {
      for (let error of response.error) {
        const columnId = backendParamToColumn[error['field_name']];
        filter.setColumnToError((state) => {
          return {
            ...state,
            [columnId]: error.error,
          }
        });
      }
    }
  }, [filter, getToken, pagination, sorting]);
  
  const table = useReactTable({
    data,
    columns,
    defaultColumn: {
      header: ({ column }) => <span>{ columnIdToReadableName[column.id] }</span>,
    },
    getCoreRowModel: getCoreRowModel(),
    /* Sorting */
    onSortingChange: setSorting,
    manualPagination: true, /* turn off client-side pagination */
    manualFiltering: true,
    manualSorting: true,
    rowCount, /* Manually setting this because BE doesn't send all results at once */
    onPaginationChange: setPagination,
    onColumnFiltersChange: filter.setColumnFiltersAndClearPagination,
    
    state: {
      sorting,
      pagination,
      columnFilters: filter.columnFilters,
    },
  });

  useEffect(() => {
    list();
    // eslint-disable-next-line
  }, [pagination, sorting]);
  
  const onSearch = useCallback((...arg) => {
    setData([]);
    filter.clearAllFilters();
    setRowCount(0);
    list(...arg);
  }, [filter, list]);

  return (
    <TableWrapper>
      <Controls
        leftContent={
          <>
            <Search list={onSearch} />
            <FilterSidebar filter={filter}/>
          </>
        }
        rightContent={
          <TranscodeButton/>
        }
      />
      <Table
        table={table}
        keepShowingContentOnLoad
        minWidth={700}
        errorMessage={errorMessage}
        loading={loading}
        loadedSuccessfully={ loadedSuccessfully }
        getEmptyComponent={() => <EmptyMessage onStart={() => {}}/>}
        clearAllFilters={filter.clearAllFilters}
        columnIdToClassName={{
          [columnIds.videoLength]: style.lengthColumn,
          [columnIds.size]: style.sizeColumn,
          [columnIds.status]: style.statusColumn,
          [columnIds.dateCreated]: style.dateCreated,
        }}
      />
      <Pagination table={table}/>
    </TableWrapper>
  );
}

export default JobHistory;