import React from 'react';
import PropTypes from 'prop-types';

export function Filter({ className, color, label }) {
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg
      width="14"
      height="14"
      className={className}
      role="img"
      aria-label={label}
      style={style}
      viewBox="0 0 14 14"
      >
    <path d="M6.45 13.4c-.3 0-.58-.08-.83-.23a1.6 1.6 0 0 1-.8-1.27l-.1-1.44-.2-2.57a20.63 20.63 0 0 1-3.53-3.3c-.28-.33-.4-.6-.47-1.05C.37 2.43.27 1.7.8 1.14 1.3.6 2.02.6 3.21.6h7.57c1.2 0 1.9 0 2.42.54.53.57.43 1.25.28 2.39-.06.44-.18.72-.46 1.05-.83.95-1.97 2.17-3.55 3.32l-.11 1.4c-.08.87-.15 1.68-.21 2.2a1.57 1.57 0 0 1-.94 1.28l-1.07.47c-.21.1-.45.15-.68.15ZM5.33 6.77c.32.23.51.59.55.98.07.86.15 1.77.2 2.6l.12 1.42c0 .1.07.17.11.2a.3.3 0 0 0 .25 0l1.06-.48c.08-.03.13-.1.13-.17.06-.51.13-1.3.2-2.16l.14-1.44c.04-.4.23-.75.55-.97a19.66 19.66 0 0 0 3.32-3.11c.09-.11.1-.14.13-.33.07-.5.15-1.14.08-1.25-.1-.1-.8-.1-1.4-.1H3.2c-.6 0-1.3 0-1.4.1-.08.12 0 .75.07 1.26.02.18.04.2.14.32.77.89 1.85 2.04 3.32 3.1v.03Z" fill="currentColor"/>
    </svg>
  );
}

Filter.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
};
