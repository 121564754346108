import React, { useState, useCallback, useEffect } from 'react';

import { useAWS } from 'api/useAWS';

import { Modal } from 'components/Modal';

import { Button } from 'components/Button'

import { NoData } from 'components/NoData';

import { ReactComponent as Folder2 } from 'assets/images/Folder2.svg';

import styles from './index.module.css';

// table related
import {getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable} from "@tanstack/react-table";
import globalFilterFn from "components/Table/columnFilters/exactCaseInsensitiveFilter";
import Table from "components/Table";
import Search from "components/Search";

import { Breadcrumbs } from 'components/Breadcrumbs'
// import { BucketDetailsHeader } from 'components/BucketDetailsHeader'

import { Check, FolderAlt, VideoFile } from "icons";

// Need to remove everything related to selectedFile

export const MoveToModal = ({ currentBucket, configData, itemsToMove, initialItems, initialPath, open, handleCancel, onSuccessfulFinish }) => {

    //console.log("initialPath: ", initialPath)
    // console.log("initialItems: ", initialItems)
    // console.log("itemsToMove: ", itemsToMove)

    const {
        getItemsForQencodeBucket,
        moveFileInQencodeBucket
    } = useAWS();

    // items in the bucket
    const [currentItems, setCurrentItems] = useState([]); // folders
    //const [currentFiles, setCurrentFiles] = useState([]); // folders
    const [loadingCurrentItems, setLoadingCurrentItems] = useState(true);
    const [currentItemsLoadedSuccessfully, setCurrentItemsLoadedSuccessfully] = useState(false);
    const [currentItemsError, setCurrentItemsError] = useState(null);

    //const [selectedFile, setSelectedFile] = useState('');

    const [selectedFolder, setSelectedFolder] = useState('');

    // const [breadcrumbPath, setBreadcrumbPath] = useState([currentBucket.name]); 
    const [breadcrumbPath, setBreadcrumbPath] = useState([]); // bucket name removed from path in new design    

    const [isMoving, setIsMoving] = useState(false);
    const [moveError, setMoveError] = useState(null); // Track any errors during deletion    

    const [filteredItemsToMove, setFilteredItemsToMove] = useState([]); // only files that are not in target dir will be moved

    const [existingFilesToMove, setExistingFilesToMove] = useState([]);
  
    // bucket items table
    const [globalFilterItems, setGlobalFilterItems] = useState('');
    const [sortingItems, setSortingItems] = useState([]);
    const columnsItems = React.useMemo(() => [
        {
          id: '1',
          accessorKey: 'displayName',
          header: () => <span>Name</span>,
          cell: (info) => {
            return (
               <div className='cellContainer'> 
                    { info.row.original?.type && info.row.original?.type === "folder" && <FolderAlt className={styles.bucketItemIcon} />}
                    { info.row.original?.type && info.row.original?.type === "file" && <VideoFile className={styles.bucketItemIcon} />}
                    <div className='cellLimited cellHighlighted cellFullWidth'>
                        { info.getValue() }
                    </div>
                    { info.row.original?.selected && <Check className={styles.selectedIcon} />}
                </div>
            );
          },
        },
    ],[]);
    const tableItems = useReactTable({
        data: currentItems,
        columns: columnsItems,
        getCoreRowModel: getCoreRowModel(),
        /* Sorting */
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSortingItems,
        /* Filtering */
        globalFilterFn,
        getFilteredRowModel: getFilteredRowModel(),
        onGlobalFilterChange: setGlobalFilterItems,
        state: {
            sorting: sortingItems,
            globalFilter: globalFilterItems
        }
    })

    const fetchItems = useCallback(async (bucketName, config, prefix = '') => {

        // clear items list
        setCurrentItems([])

        setLoadingCurrentItems(true)
        setCurrentItemsLoadedSuccessfully(false)

        const { success, items, error } = await getItemsForQencodeBucket(bucketName, config, prefix);
        if (success) {
            console.log("items: ", items)

            // filter out files as I only need to show folders
            const folders = items.filter(item => item.type === 'folder');

            // setCurrentItems(items);
            setCurrentItems(folders);

            setCurrentItemsLoadedSuccessfully(true);
            setCurrentItemsError(null);
            setLoadingCurrentItems(false)



            // filter out folders as I only need to check vs files
            const files = items.filter(item => item.type === 'file');
            //setCurrentFiles(files)

            // Filter out files that already exist in the current directory from itemsToMove
            const validFilesToMove = itemsToMove.filter(
                (item) => !files.some((file) => file.displayName === item.displayName)
            );

            //console.log("validFilesToMove: ", validFilesToMove)

            // Set the valid files that will be moved
            setFilteredItemsToMove(validFilesToMove);

            // List of files that already exist in the directory
            const existingFiles = itemsToMove.filter(item =>
                files.some(file => file.displayName === item.displayName)
            );

            setExistingFilesToMove(existingFiles); // Track the files that already exist            
        } else {
            setCurrentItems([]);
            setCurrentItemsError(error);
            setLoadingCurrentItems(false)

            setFilteredItemsToMove([])
            setExistingFilesToMove([])
        }   
    }, [getItemsForQencodeBucket, itemsToMove]); 

    // const handleConfirmClick = () => {
    //     console.log("selectedFolder: ", selectedFolder)
    //     onSuccessfulFinish(selectedFolder);
    // };

    const onItemRowClick = async (item) => {
        if (item.type === 'folder') {
            const config = {
                access_id: configData.access_id,
                secret_key: configData.secret_key,
                region: currentBucket.region,
            };

            console.log("Get more for ", item)

            fetchItems(currentBucket.name, config, item.name);
            setBreadcrumbPath(prev => [...prev, item.displayName]);

            // set selected folder
            setSelectedFolder(item.name)
            console.log("item: ", item)

            // reset search 
            setGlobalFilterItems('')

            // clear selected file
            //setSelectedFile('')
        } 
        // else if (item.type === 'file') {
        //     const updatedItems = currentItems.map(it => 
        //         it.name === item.name ? { ...it, selected: true } : { ...it, selected: false }
        //     );
        //     setCurrentItems(updatedItems);
        //     setSelectedFile(item);
        //     console.log("Selected file: ", item);
        // }
    };

    const onBreadcrumbClick = async (index) => {
        // reset search 
        setGlobalFilterItems('')

        const config = {
            access_id: configData.access_id,
            secret_key: configData.secret_key,
            region: currentBucket.region,
        };
    
        // // Slice the path excluding the bucket name
        // let path = breadcrumbPath.slice(1, index + 1).join('/');

        // there is no bucket name at the start any more
        // Slice the path up to the current index
        let path = breadcrumbPath.slice(0, index + 1).join('/');              


        // Ensure the path ends with a /
        if (path && !path.endsWith('/')) {
            path += '/';
        }

    
        fetchItems(currentBucket.name, config, path);
        setBreadcrumbPath(breadcrumbPath.slice(0, index + 1));

        // clear selected file
        //setSelectedFile('')

        // set selected folder to clicked path
        setSelectedFolder(path)
    };     

    // when modal opens set initial states
    useEffect(() => {
        if(open){
            const prefix = initialPath
            const breadcrumbSegments = prefix.split('/').filter(segment => segment !== '');
            setBreadcrumbPath(breadcrumbSegments);    

            setSelectedFolder(initialPath || '');
            
            // filter out files as I only need to show folders
            const folders = initialItems.filter(item => item.type === 'folder');
            setCurrentItems(folders)   

            setLoadingCurrentItems(false)
            setCurrentItemsLoadedSuccessfully(true)            
        }
    }, [open, initialItems, initialPath])    


    const closeMoveModal = () => {
        // reset error
        if (moveError) {
            setMoveError(null);
        }       

        handleCancel()
    };      


    const handleMoveItems = useCallback(async (items) => {
        console.log("Move items: ", items) 

        setIsMoving(true); // Disable confirm button and show progress
    
        try {
            const bucketName = currentBucket.name;
            const config = {
                access_id: configData.access_id,
                secret_key: configData.secret_key,
                region: currentBucket.region,
            };
    
            // Iterate over each item and delete it
            for (const item of items) {
                let result;
    
                if (item.type === 'file') {
                    const oldKey = item.name;
                    const newKey = `${selectedFolder}${item.displayName}`; // new key must be selected folder + file display name
                    result = await moveFileInQencodeBucket(bucketName, oldKey, newKey, config);
                } else {
                    // make file move in future
                    // const folderName = item.name;
                    result = {
                        success: false
                    } // just set fake result to skip
                }
    
                if (!result.success) {
                    console.error(result.error || "Error while moving the item.");
                    setMoveError(result.error || "Error while moving the item.");
                } 
            }
    
            // Close current modal and fetch updated items after all movings are complete
            //onSuccessfulFinish()
    
        } catch (error) {
            console.error('An error occurred during the miving process:', error.message);
            setMoveError(error.message || "An unexpected error occurred during moving.");
        } finally {
            // Reset the moving state
            setIsMoving(false);

            // Close current modal and fetch updated items after all movings are complete
            // if there were existingFilesToMove - show modal with files that were not moved
            onSuccessfulFinish(existingFilesToMove)            
        }
    }, [currentBucket, selectedFolder, configData, moveFileInQencodeBucket, onSuccessfulFinish, existingFilesToMove]);       


    return (
        <Modal
            className={styles.fileSelectionModal}
            isOpen={open}
            onClose={closeMoveModal}
            // header={<h3>Choose Video File for Transcoding</h3>}
            footer={
                <>
                    <Button 
                        onClick={closeMoveModal} 
                        type='secondary'
                        variant="modalFooter"
                    >
                        Cancel
                    </Button> 
                    <Button 
                        // onClick={() => handleMoveItems(itemsToMove)} 
                        onClick={() => handleMoveItems(filteredItemsToMove)} 
                        state={((initialPath === selectedFolder) || isMoving) ? "disabled" : undefined} 
                        variant="modalFooter"
                    >
                        {isMoving ? "Moving..." : "Move"}
                    </Button>  
                </>
            }
        >
            <div>
                <div>
                    <div className={styles.modalHeader}>
                        <h3 className={styles.modalTitle}>Move {itemsToMove?.length} files to</h3>                    
                    </div>
                    <div>

                        {/* <div>Selected file: {selectedFile?.sourceUrl}</div> */}

                        <div className={styles.itemsContainer}>

                            {/* { currentBucket && <BucketDetailsHeader bucket={currentBucket}/>} */}

                            <div className={styles.itemsBody}>

                                <div className={styles.modalControls}>

                                    {/* { breadcrumbPath?.length > 0 && <Breadcrumbs path={breadcrumbPath} onNavigate={onBreadcrumbClick} />} */}

                                    <Breadcrumbs path={breadcrumbPath} onNavigate={onBreadcrumbClick} />
                                    
                                    <div className={styles.modalFiltersContainer}>
                                        <Search setGlobalFilter={setGlobalFilterItems} globalFilter={globalFilterItems}/>    
                                    </div>

                                </div>

                                <div className={styles.modalTableContainerBucketItems}>
                                {/* <div className={`${styles.modalTableContainerBucketItems} ${currentBucket ? styles.showBucketHeaderSmall : ''}`}> */}
                                    <Table
                                        data={currentItems}
                                        table={tableItems}
                                        globalFilter={globalFilterItems}
                                        resetFilter={() => setGlobalFilterItems('')}
                                        loading={loadingCurrentItems}
                                        loadedSuccessfully={currentItemsLoadedSuccessfully}
                                        errorMessage={currentItemsError}
                                        getEmptyComponent={() => <NoData
                                            image={<Folder2 />}
                                            title="No folders"
                                            description="There are no folders in this directory."
                                        />}
                                        onRowClick={onItemRowClick}
                                    />                            
                                </div>

                            </div>


                        </div>      
                        


                    </div>
                </div>


                {/* <div>
                    File selection table
                </div> */}

            </div>
        </Modal>    
    );
}
