import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'components/Select';
import { InputField } from 'components/InputField';
import { Button } from 'components/Button'

import { qencodeRegions } from 'helpers/qencodeRegions';

import { QencodePathModal } from './QencodePathModal'

// import { ReactComponent as Bucket } from 'assets/images/Bucket.svg';
import { ReactComponent as Bucket } from 'assets/images/BucketSmall.svg';
import { Add } from 'icons';

import styles from '../index.module.css';

export const QencodeStorageForm = ({ 
    buckets, currentBucket, onBucketChange, fileName, 
    validationErrors,
    onFileNameChange, handleCreateNewBucket, 
    //handleUpload 
}) => {
    
    const [bucketsOptions, setBucketsOptions] = useState([]);

    useEffect(() => {
        if (buckets.length > 0) {
            const options = buckets.map((bucket, index) => {
                const region = qencodeRegions.find(region => region.value === bucket.region);
                return {
                    value: `${bucket.name}-${bucket.region}-${index}`, // Create a unique value
                    label: bucket.name,
                    extraData: region ? region.label : bucket.region, // Use the region label if found, otherwise fallback to bucket.region
                    data: bucket, // Store the whole bucket object in the option
                    checked: bucket === currentBucket
                };
            });
            setBucketsOptions(options);
        }
    }, [buckets, currentBucket]);    


    const handleBucketChange = (e) => {
        const { value } = e.target;
        const selectedOption = bucketsOptions.find(option => option.value === value)?.data;
        
        if (selectedOption) {
            onBucketChange(selectedOption);
            
            const updatedOptions = bucketsOptions.map(option => ({
                ...option,
                checked: option.value === value
            }));

            setBucketsOptions(updatedOptions);
        }
    };

    const handleInputChange = (e) => {
        const { value } = e.target;
        // Clear validation errors when input changes

        onFileNameChange(value); // Call the parent's onFileNameChange function
    };

    // deal with modal to choose path

    const [isQencodeStorageModalOpen, setQencodeStorageModalOpen] = useState(false);

    const handleOpenQencodeStorageModal= () => {
        setQencodeStorageModalOpen(true);
    };

    const handleCloseQencodeStorageModal = () => {
        setQencodeStorageModalOpen(false);
    };

    const handleSuccessfulFileSelectFromQencodeStorage = (path) => {
        console.log('Path selected in modal: ', path);
        setQencodeStorageModalOpen(false); // Close modal on success

        const fileNameFromInput = fileName.split('/').pop();

        // imitate e.target linee in input field
        const videoUrlObject = {
            target: {
                name: 'key_name',
                value: `${path}${fileNameFromInput}`
            }
        }

        handleInputChange(videoUrlObject)
    };    



    return (
        <div className={styles.qencodeStorageForm}>

            {
                currentBucket 
                ?
                <div className={styles.formBody}>

                    <div className={styles.formRow}>
                        <Select
                            label='Choose Bucket'
                            name='bucket'
                            placeholder='Select Bucket'
                            options={bucketsOptions}
                            value={currentBucket ? `${currentBucket.name}-${currentBucket.region}-${buckets.findIndex(bucket => bucket === currentBucket)}` : ''}
                            onChange={handleBucketChange}
                            link={{
                                text: 'Create Bucket',
                                onClick: handleCreateNewBucket
                            }}
                        />
                    </div>

                    <div className={styles.formRow}>
                        <InputField
                            inputText={fileName}
                            onChange={handleInputChange}
                            name="key_name"
                            label="Choose Path"
                            state={validationErrors?.key_name ? 'error' : 'default'}
                            hint={validationErrors?.key_name ? validationErrors?.key_name : undefined}
                            browseLink={handleOpenQencodeStorageModal}
                        />  

                        { isQencodeStorageModalOpen && (
                            <QencodePathModal
                                open={isQencodeStorageModalOpen}
                                handleCancel={handleCloseQencodeStorageModal}
                                onSuccessfulFinish={handleSuccessfulFileSelectFromQencodeStorage}
                                currentBucket={currentBucket}
                            />
                        )}
                    </div>  

                </div>                
                :
                <div className={styles.noBucketsForm}>
                    <div className={styles.noBucketsFormBody}>
                        <Bucket />
                        <div className={styles.noBucketsFormMessage}>You don't have any buckets yet</div>
                    </div>
                    <Button onClick={handleCreateNewBucket} size="sm" icon={<Add />}>Create Bucket</Button>
                </div>
            }




        </div>
    );
};

QencodeStorageForm.propTypes = {
    buckets: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            region: PropTypes.string.isRequired,
        })
    ).isRequired,
    currentBucket: PropTypes.shape({
        name: PropTypes.string,
        region: PropTypes.string,
    }),
    onBucketChange: PropTypes.func.isRequired,
    fileName: PropTypes.string.isRequired,
    onFileNameChange: PropTypes.func.isRequired,
    //handleUpload: PropTypes.func,
};

QencodeStorageForm.defaultProps = {
    currentBucket: null,
};




// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { Select } from 'components/Select';
// import { InputField } from 'components/InputField';
// //import { Button } from 'components/Button'

// import { qencodeRegions } from 'helpers/qencodeRegions';

// import styles from '../index.module.css';

// export const QencodeStorageForm = ({ 
//     buckets, currentBucket, onBucketChange, fileName, onFileNameChange, handleCreateNewBucket, 
//     //handleUpload 
// }) => {
    
//     const [bucketsOptions, setBucketsOptions] = useState([]);

//     const [credentials, setCredentials] = useState({
//         key_name: fileName || "",        
//     });

//     const [validationErrors, setValidationErrors] = useState({});

//     useEffect(() => {
//         if (buckets.length > 0) {
//             const options = buckets.map((bucket, index) => {
//                 const region = qencodeRegions.find(region => region.value === bucket.region);
//                 return {
//                     value: `${bucket.name}-${bucket.region}-${index}`, // Create a unique value
//                     label: bucket.name,
//                     extraData: region ? region.label : bucket.region, // Use the region label if found, otherwise fallback to bucket.region
//                     data: bucket, // Store the whole bucket object in the option
//                     checked: bucket === currentBucket
//                 };
//             });
//             setBucketsOptions(options);
//         }
//     }, [buckets, currentBucket]);    


//     const handleBucketChange = (e) => {
//         const { value } = e.target;
//         const selectedOption = bucketsOptions.find(option => option.value === value)?.data;
        
//         if (selectedOption) {
//             onBucketChange(selectedOption);
            
//             const updatedOptions = bucketsOptions.map(option => ({
//                 ...option,
//                 checked: option.value === value
//             }));

//             setBucketsOptions(updatedOptions);
//         }
//     };

//     const handleInputChange = (e) => {

//         const { name, value } = e.target;
//         // Check if the value is an empty string and set it to null if true
//         const newValue = value === '' ? '' : value;
    
//         const newCredentials = { ...credentials, [name]: newValue };
//         setCredentials(newCredentials);

//         setValidationErrors(prevErrors => ({
//             ...prevErrors,
//             [name]: null
//         }));
//     };

//     return (
//         <div className={styles.qencodeStorageForm}>
//             <div className={styles.formBody}>
//                 {/* <div>Bucket: {currentBucket?.name}</div>
//                 <div>Filename: {fileName}</div>

//                 <div>Buckets</div> */}

//                 <div className={styles.formRow}>
//                     <Select
//                         label='Choose Bucket'
//                         name='bucket'
//                         placeholder='Select Bucket'
//                         options={bucketsOptions}
//                         value={currentBucket ? `${currentBucket.name}-${currentBucket.region}-${buckets.findIndex(bucket => bucket === currentBucket)}` : ''}
//                         onChange={handleBucketChange}
//                         link={{
//                             text: 'Create Bucket',
//                             onClick: handleCreateNewBucket
//                         }}
//                     />
//                 </div>


//                 <div className={styles.formRow}>
//                     <InputField
//                         inputText={credentials.key_name}
//                         onChange={handleInputChange}
//                         name="key_name"
//                         //placeholder="http://example.com/video.mov"
//                         label="Choose Path"
//                         state={validationErrors.key_name ? 'error' : 'default'}
//                         hint={validationErrors.key_name ? validationErrors.key_name : undefined}
//                         browseLink={() => console.log("Browse folder")}
//                     />  
//                 </div>  

//             </div>
//             {/* <div className={styles.formFooter}>
//                 <div className={styles.formMainExtraButtons}></div>
//                 <div className={styles.formMainButtons}>
//                     <Button 
//                         size="sm"
//                         onClick={handleUpload} 
//                     >
//                         Upload
//                     </Button>  
//                 </div>
//             </div> */}
//         </div>
//     );
// };

// QencodeStorageForm.propTypes = {
//     buckets: PropTypes.arrayOf(
//         PropTypes.shape({
//             name: PropTypes.string.isRequired,
//             region: PropTypes.string.isRequired,
//         })
//     ).isRequired,
//     currentBucket: PropTypes.shape({
//         name: PropTypes.string,
//         region: PropTypes.string,
//     }),
//     onBucketChange: PropTypes.func.isRequired,
//     fileName: PropTypes.string.isRequired,
//     onFileNameChange: PropTypes.func.isRequired,
//     //handleUpload: PropTypes.func,
// };

// QencodeStorageForm.defaultProps = {
//     currentBucket: null,
// };



