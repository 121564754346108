import React from "react";
import style from './style.module.css';
import TableHeadingCell from "components/Table/Heading/Cell";

export default function TableHeading({ table, rowSelectionAllowed }) {
  return (
    <thead>
    { table.getHeaderGroups().map(headerGroup => {
      return (
        <tr
          key={headerGroup.id}
          className={style.headingRow}
        >
          { headerGroup.headers.map((header) => {
            return (
              <TableHeadingCell
                key={header.id}
                header={header}
                table={table}
                rowSelectionAllowed={rowSelectionAllowed}
              />
            )
          }) }
        </tr>
      )
    }) }
    </thead>
  )
}
