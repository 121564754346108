import PropTypes from "prop-types";


export function APIRegenerate({ color }) {
  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg
      width="16"
      height="16"
      style={style}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.58911 3.72681L2.93115 3.06885C2.77515 2.91285 2.54054 2.86619 2.33672 2.95061C2.1329 3.03504 2 3.23393 2 3.45455V5.87879C2 6.18003 2.24421 6.42424 2.54545 6.42424H4.9697C5.19031 6.42424 5.38921 6.29135 5.47363 6.08752C5.55806 5.8837 5.51139 5.64909 5.35539 5.49309L4.36182 4.49952C5.41155 3.58338 6.70476 3.09091 8 3.09091C10.5246 3.09091 12.6047 4.99703 12.8785 7.44828C12.9119 7.74766 13.1817 7.96325 13.4811 7.92981C13.7805 7.89637 13.9961 7.62656 13.9626 7.32718C13.6279 4.32992 11.0864 2 8 2C6.40116 2 4.83631 2.61529 3.58911 3.72681Z"
            fill="currentColor"/>
      <path d="M2.51881 8.06389C2.81824 8.03079 3.0878 8.24669 3.1209 8.54611C3.39216 11.0001 5.47351 12.9091 8 12.9091C9.24954 12.9091 10.4954 12.4513 11.5141 11.6795L10.6446 10.8099C10.4886 10.6539 10.4419 10.4193 10.5264 10.2155C10.6108 10.0117 10.8097 9.87879 11.0303 9.87879H13.4545C13.7558 9.87879 14 10.123 14 10.4242V12.8485C14 13.0691 13.8671 13.268 13.6633 13.3524C13.4595 13.4368 13.2248 13.3902 13.0689 13.2342L12.2916 12.4569C11.0725 13.4203 9.5518 14 8 14C4.91126 14 2.36828 11.6665 2.03659 8.66597C2.00349 8.36655 2.21939 8.09699 2.51881 8.06389Z" 
            fill="currentColor"/>
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M8.0009 6.40918C7.78594 6.40918 7.61169 6.57335 7.61169 6.77585V9.22423C7.61169 9.42674 7.78594 9.59091 8.0009 9.59091C8.21586 9.59091 8.39011 9.42674 8.39011 9.22423V8.67277H8.97548C9.25102 8.67277 9.51528 8.56964 9.71011 8.38609C9.90495 8.20254 10.0144 7.95358 10.0144 7.694V7.38795C10.0144 7.12836 9.90495 6.87941 9.71011 6.69585C9.51528 6.5123 9.25102 6.40918 8.97548 6.40918H8.0009ZM8.97548 7.93942H8.39011V7.14253H8.97548C9.04457 7.14253 9.11083 7.16838 9.15968 7.21441C9.20854 7.26044 9.23598 7.32286 9.23598 7.38795V7.694C9.23598 7.75909 9.20854 7.82151 9.15968 7.86753C9.11083 7.91356 9.04457 7.93942 8.97548 7.93942Z" 
            fill="currentColor"/>
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M4.81783 7.54106C4.81783 7.24089 4.9444 6.95302 5.1697 6.74076C5.395 6.52851 5.70057 6.40927 6.01919 6.40927C6.33781 6.40927 6.64338 6.52851 6.86868 6.74076C7.09398 6.95302 7.22055 7.24089 7.22055 7.54106V9.22432C7.22055 9.42683 7.0463 9.591 6.83134 9.591C6.61638 9.591 6.44213 9.42683 6.44213 9.22432V8.67285H5.59625V9.22432C5.59625 9.42683 5.422 9.591 5.20704 9.591C4.99208 9.591 4.81783 9.42683 4.81783 9.22432V7.54106ZM6.44213 7.9395V7.54106C6.44213 7.43539 6.39757 7.33404 6.31825 7.25932C6.23893 7.1846 6.13136 7.14262 6.01919 7.14262C5.90702 7.14262 5.79944 7.1846 5.72013 7.25932C5.64081 7.33404 5.59625 7.43539 5.59625 7.54106V7.9395H6.44213Z"
            fill="currentColor"/>
      <path d="M11.1815 6.77585C11.1815 6.57335 11.0072 6.40918 10.7922 6.40918C10.5773 6.40918 10.403 6.57335 10.403 6.77585V9.22423C10.403 9.42674 10.5773 9.59091 10.7922 9.59091C11.0072 9.59091 11.1815 9.42674 11.1815 9.22423V6.77585Z" 
            fill="currentColor"/>
    </svg>
  );
}

APIRegenerate.propTypes = {
  color: PropTypes.string,
};
