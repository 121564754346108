import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './Breadcrumbs.module.css';

import { Home, ChevronRight } from 'icons';

export const Breadcrumbs = ({ path, onNavigate }) => {
  //console.log("path: ", path)
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollLeft = containerRef.current.scrollWidth;
    }
  }, [path]);

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.homeContainer} onClick={() => onNavigate(-1)}>
        <Home className={styles.icon}/>
      </div>
      {path.length > 0 && <ChevronRight className={styles.icon} />}
      {path.map((folder, index) => (
        <div key={index} className={`${styles.item} ${index === path.length - 1 ? styles.selected : ''}`}>
          <div 
            className={styles.text}
            // onClick={() => onNavigate(index)} 
            onClick={index === path.length - 1 ? null : () => onNavigate(index)}  // Disable click for the last item
          >
            {folder}
          </div>
          {index < path.length - 1 && <ChevronRight className={styles.icon} />}
        </div>
      ))}
    </div>
  );
};

Breadcrumbs.propTypes = {
  path: PropTypes.arrayOf(PropTypes.string),
  onNavigate: PropTypes.func,
};

Breadcrumbs.defaultProps = {
  path: [],
  onNavigate: () => {},
};






// import React from 'react';
// import PropTypes from 'prop-types';
// import styles from './Breadcrumbs.module.css';

// import { Home, ChevronRight } from 'icons'

// export const Breadcrumbs = ({ path, onNavigate }) => {
//   return (
//     <div className={styles.container}>
//       <Home className={styles.icon} />
//       {path.map((folder, index) => (
//           <div key={index} className={`${styles.item} ${index === path.length - 1 ? styles.selected : ''}`}>
//               <div 
//                   className={styles.text}
//                   onClick={() => onNavigate(index)} 
//               >
//                   {folder}
//               </div>
//               {index < path.length - 1 && <ChevronRight className={styles.icon} />}
//           </div>
//       ))}
//     </div>
//   );
// };

// Breadcrumbs.propTypes = {
//   path: PropTypes.arrayOf(PropTypes.string),
//   onNavigate: PropTypes.func
// };

// Breadcrumbs.defaultProps = {
//   path: [],
//   onClick: () => {}
// };