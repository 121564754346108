import React, { useState } from 'react';

import { addCard } from '../../../api/qencode-account-api'

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';

import { 
  Lock as LockIcon,
  Stripe as StripeIcon,
} from '../../../icons'

// https://github.com/Qencode-Corp/qencode-account-api/blob/main/sample/stripe.html#L128

import { Button } from '../../../components/Button';
import { InputField } from '../../../components/InputField';
import { Label } from '../../../components/Label';

import { useAuth } from '../../../context/AuthContext'

import styles from '../index.module.css';

const CARD_ELEMENT_OPTIONS = {
  style: {
      base: {
          color: `var(--Gray-900, #060E1E)`,
          fontFamily: '"Basis Grotesque Pro", "Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '14px',
          '::placeholder': {
              // color: `var(--Gray-400, #A1ABB5)`,
              color: '#d4d9dd'
          },
          fontWeight: '400'
      },
      invalid: {
          color: `var(--Error-100, #FC3C3C)`,
          iconColor: `var(--Error-100, #FC3C3C)`
      }
  }
};

const AddCardForm = ({ onCloseModal, onAddCard }) => {

  const { 
    //getAccessToken,
    getToken 
  } = useAuth();

  const [isSubmittingModal, setIsSubmittingModal] = useState(false);

  const [paymentMethodError, setPaymentMethodError] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  const [stripeErrors, setStripeErrors] = useState({
    cardNumber: null,
    cardExpiry: null,
    cardCvc: null
  });

  const [name, setName] = useState('');

  const handleNameChange = (event) => {
    // clear error
    if(paymentMethodError){
      setPaymentMethodError(null)
    }

    setName(event.target.value);
  };


  const handleStripeElementChange = (event) => {
    const { elementType, error } = event;
    // Dynamically set the error based on the element type
    setStripeErrors(prevErrors => ({
      ...prevErrors,
      [elementType]: error ? error.message : null
    }));
  };

  // https://docs.stripe.com/payments/accept-a-payment-charges?client=react#web-create-token
  const handleAddPaymentMethod = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);

    setIsSubmittingModal(true);

    // 1st try to create tokenized card at Stripe
    const { token, error } = await stripe.createToken(cardNumberElement, {
      name: name,
    });

    if (error) {
      console.log('Stripe error: ', error);
    } else {
      console.log('token: ', token);
      // Here you can call your API to save the payment method ID to your database

      const credentials = {
        card_id: token?.card?.id,
        card_token: token?.id,
        card_name: token?.card?.name
      }

      // const accessToken = getAccessToken();
      const accessToken = await getToken()

      const response = await addCard(accessToken, credentials);

      const {success, data, detail} = response

      if (success && data) {
          console.log("Card payment method data: ", data)
  
          // will call function to add payment method to the top of the list
          onAddCard(data)
  
          // Сlose the modal:
          onCloseModal();
      } else {
          setPaymentMethodError(detail || "Failed to add Card");
          console.log(detail || "Failed to add Card");
      }   


    }

    setIsSubmittingModal(false);

  };

  const handleCancel = () => {
    // Optionally reset state or perform other actions on cancel
    onCloseModal(); // Simply close the modal
  };

  return (
    <div>
      <div className="form">
        {/* <CardElement /> */}

        <div className="row">             
            <InputField
                inputText={name}
                onChange={handleNameChange}
                name="name"
                placeholder="Cardholder Name"
                label="Name on card"
            />
        </div>      

        <div className="row">
          <div className={styles.inputElementContainer}>
            <Label text="Card number" />
            <CardNumberElement options={CARD_ELEMENT_OPTIONS} onChange={handleStripeElementChange} />
            {stripeErrors.cardNumber && <div className={styles.cardErrorMessage}>{stripeErrors.cardNumber}</div>}
          </div>
        </div>

        <div className="row">
          <div className={styles.inputElementContainer}>
            <Label text="Expiry date" />
            <CardExpiryElement options={CARD_ELEMENT_OPTIONS} onChange={handleStripeElementChange} />
            {stripeErrors.cardExpiry && <div className={styles.cardErrorMessage}>{stripeErrors.cardExpiry}</div>}
          </div>
          <div className={styles.inputElementContainer}>
            <Label text="CVV" />
            <CardCvcElement options={CARD_ELEMENT_OPTIONS} onChange={handleStripeElementChange} />
            {stripeErrors.cardCvc && <div className={styles.cardErrorMessage}>{stripeErrors.cardCvc}</div>}
          </div>
        </div>        

        <div className={styles.stripeInfoContainer}>
          <div className={styles.stripeInfoLeft}>
            <LockIcon className={styles.lockIcon}/>
            <div>Billing information secured and projected.</div>
          </div>
          <div className={styles.stripeInfoRight}>
            <div>Powered by</div>
            <StripeIcon className={styles.stripeIcon}/>
          </div>
        </div>

        {paymentMethodError && <div className={styles.errorMessage}>{paymentMethodError}</div>}

      </div>
      <div className={styles.addPaymentModalFooter}>
         <Button variant="modalFooter" onClick={handleCancel} type="secondary">Cancel</Button>
         <Button 
          variant="modalFooter" 
          onClick={handleAddPaymentMethod} 
          state={!stripe || isSubmittingModal ? "disabled" : undefined}
        >
          Save
        </Button>        
      </div>      
      
    </div>
  );
};

export default AddCardForm;
