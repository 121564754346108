import PropTypes from "prop-types";
import React from "react";

export const Link = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M10.1839 4.47146L9.77036 4.95016C9.42939 5.34489 8.83299 5.38847 8.43826 5.0475C8.04353 4.70653 7.99995 4.11013 8.34092 3.7154L8.77823 3.20915C8.79325 3.19175 8.80891 3.17491 8.82517 3.15866C9.88806 2.09591 11.3296 1.49893 12.8326 1.49904C14.3357 1.49914 15.7771 2.09633 16.8399 3.15922C17.9026 4.22211 18.4996 5.66364 18.4995 7.16669C18.4994 8.66974 17.9022 10.1112 16.8393 11.1739C16.8238 11.1895 16.8076 11.2045 16.791 11.219L16.2866 11.6572C15.8929 11.9993 15.2964 11.9575 14.9543 11.5638C14.6121 11.17 14.654 10.5735 15.0477 10.2314L15.5257 9.81603C16.2206 9.10969 16.6105 8.15824 16.6106 7.16656C16.6107 6.16448 16.2127 5.20341 15.5041 4.49477C14.7956 3.78614 13.8346 3.388 12.8325 3.38793C11.8413 3.38786 10.8902 3.77728 10.1839 4.47146ZM13.5012 6.49901C13.8701 6.86784 13.8701 7.46583 13.5012 7.83466L7.8342 13.5017C7.46537 13.8705 6.86738 13.8705 6.49855 13.5017C6.12972 13.1329 6.12972 12.5349 6.49855 12.166L12.1656 6.49901C12.5344 6.13018 13.1324 6.13018 13.5012 6.49901ZM4.95846 9.76431C5.34934 9.41894 5.38623 8.82209 5.04085 8.43121C4.69548 8.04033 4.09863 8.00344 3.70775 8.34882L3.21283 8.78612C3.19959 8.79782 3.18669 8.80988 3.17413 8.82229C2.64361 9.34687 2.22243 9.97147 1.93498 10.66C1.64752 11.3484 1.49951 12.0871 1.49951 12.8332C1.49951 13.5792 1.64752 14.3179 1.93498 15.0064C2.22243 15.6948 2.6436 16.3194 3.17411 16.844C4.24701 17.905 5.69504 18.5 7.20393 18.5C8.71283 18.5 10.1609 17.905 11.2338 16.844C11.2677 16.8104 11.2991 16.7743 11.3276 16.7359L11.7026 16.2316C12.0138 15.813 11.9267 15.2214 11.5081 14.9102C11.0895 14.599 10.4979 14.686 10.1867 15.1046L9.85693 15.5482C9.14283 16.2299 8.19288 16.6111 7.20393 16.6111C6.19234 16.6111 5.22156 16.2122 4.50226 15.5009L4.50223 15.5009C4.14937 15.152 3.86924 14.7365 3.67804 14.2786C3.48685 13.8207 3.38841 13.3294 3.38841 12.8332C3.38841 12.3369 3.48685 11.8456 3.67804 11.3877C3.86602 10.9375 4.13998 10.5283 4.48451 10.1831L4.95846 9.76431Z" fill="currentColor"/>
    </svg>    
  );
};

Link.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Link.defaultProps = {
  color: 'currentColor', // Or any default color
};