import React from 'react';
import PropTypes from 'prop-types';
import {Delete, Rename} from "icons";
import {OptionsMenu} from "components/OptionsMenu";
import {Duplicate} from "icons/Duplicate";

export const modals = {
    'rename': 'rename',
    'deletion': 'deletion',
    'duplicate': 'duplicate',
};

function MoreRowActions({ handleOpenModal, templateId }) {
    return (
        <div className="cellContainer">
            <OptionsMenu
                menuItems={
                    [
                        {
                            icon: <Rename label="Rename"/>,
                            text: 'Rename',
                            onClick: () => handleOpenModal( modals.rename, templateId ),
                        },
                        {
                            icon: <Duplicate label="Duplicate"/>,
                            text: 'Duplicate',
                            onClick: () => handleOpenModal( modals.duplicate, templateId ),
                        },
                        {
                            icon: <Delete label="Remove" />,
                            onClick: () => handleOpenModal( modals.deletion, templateId ),
                            text: "Delete",
                            danger: true,
                        }
                    ]
                }
            />
        </div>
    )
}

MoreRowActions.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
  templateId: PropTypes.string.isRequired,
};

export default MoreRowActions;