import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // default Tippy CSS
import { TooltipTemplate } from './TooltipTemplate';

//import styles from './DocsTooltip.module.css';

// potal is also using https://portal-qa.qencode.com/doc.json

// HOW TO USE
// Get data from docs located in transcoding.json and so on in https://docs.qencode.com/

// copy data into props adding url
// can find url by searthing for parameter by name at https://docs.qencode.com/

// const tooltipProps =                                         {
//   "name": "key_url",
//   "order": 30,
//   "required": true,
//   "data_type": "string",
//   "short_description": "Encryption key URL",
//   "description": "<p>URL, pointing to 128-bit encryption key in binary format.</p>",
//   "url": "https://docs.qencode.com/api-reference/transcoding/#start_encode2___query__attributes--format__attributes--encryption__attributes--key_url__attributes"
// }

// <Label text="Label Text" help={tooltipProps} />

export const DocsTooltip = ({ children, tooltipProps, trigger = 'click' }) => {

    //console.log("tooltipProps: ", tooltipProps)

    // const { name, required, data_type, short_description, description, url } = tooltipProps;

    // this variant is using SEARCH

    const { name, data_type, optional, title, content, link, no_header } = tooltipProps;

    return (
        <Tippy
          content={
            <TooltipTemplate
              name={name}
              data_type={data_type}
              optional={optional === 'optional' ? true : false}
              method="API"
              title={title}
              content={content}
              link={link}
              no_header={no_header}
            />
          }
          interactive={true}
          //trigger="mouseenter focus"  // Tooltip shows on hover and focus
          trigger={trigger}
          theme="qencode" // Ensure this theme is defined in your CSS
          offset={[160, 12]}
          maxWidth={500}
          allowHTML={true}
        >
          {children}
        </Tippy>
    );
};



// import React from 'react';
// import Tippy from '@tippyjs/react';
// import 'tippy.js/dist/tippy.css'; // default Tippy CSS
// import { TooltipTemplate } from './TooltipTemplate';

// //import styles from './DocsTooltip.module.css';

// // this is to work with https://portal-qa.qencode.com/transcoding.json
// // and https://portal-qa.qencode.com/doc.json

// export const DocsTooltip = ({ children, tooltipProps }) => {

// //   const tooltipProps = {
// //     "name": "min_rate",
// //     "order": 220,
// //     "required": false,
// //     "data_type": "integer",
// //     "short_description": "",
// //     "description": "<p>Sets min bitrate tolerance (in bits/s). Most useful in setting up a CBR encode. It is of little use elsewise.</p> <p>For libx264 min_rate is specified in Mbps. For other codecs - in kbps.</p>",
// //     "url": "https://docs.qencode.com/api-reference/transcoding/#start_encode2___query__attributes--format__attributes--video_codec_parameters__attributes--min_rate__attributes"
// // }

// // const tooltipProps = {
// //     name: 'width',
// //     data_type: 'integer',
// //     optional: 'optional',
// //     method: 'api',
// //     title: 'Width',
// //     content: `<p>Output video frame (or thumbnail) width in pixels. If specified without "height" attribute, frame height is calculated proportionally to original height.</p> 
// //     <p>For HLS or DASH output specify this parameter on stream object level.</p>`,
// //     link: { url: 'https://docs.qencode.com/api-reference/transcoding/#start_encode2___query__attributes--format__attributes--video_codec_parameters__attributes--min_rate__attributes', text: 'Show API' }
// // };  

//     const { name, required, data_type, short_description, description, url } = tooltipProps;

//     const link = {
//       url: url,
//       text: "Show API"
//     }

//     return (
//         <Tippy
//           content={
//             <TooltipTemplate
//               name={name}
//               data_type={data_type}
//               optional={required}
//               method="API"
//               title={name.replace(/_/g, ' ')}
//               content={description || short_description}
//               link={link}
//             />
//           }
//           interactive={true}
//           trigger="mouseenter focus"  // Tooltip shows on hover and focus
//           theme="qencode" // Ensure this theme is defined in your CSS
//           offset={[160, 12]}
//           maxWidth={500}
//           allowHTML={true}
//         >
//           {children}
//         </Tippy>
//     );
// };




// OLD variant

// import React from 'react';
// import Tippy from '@tippyjs/react';
// import 'tippy.js/dist/tippy.css'; // default Tippy CSS
// import { TooltipTemplate } from './TooltipTemplate';

// //import styles from './DocsTooltip.module.css';

// // this is to work with https://portal-qa.qencode.com/transcoding.json
// // and https://portal-qa.qencode.com/doc.json

// export const DocsTooltip = ({ children, tooltipProps }) => {

//     const { name, data_type, optional, method, title, content, link } = tooltipProps;

//     return (
//         <Tippy
//           content={
//             <TooltipTemplate
//               name={name}
//               data_type={data_type}
//               optional={optional}
//               method={method}
//               title={title}
//               content={content}
//               link={link}
//             />
//           }
//           interactive={true}
//           trigger="mouseenter focus"  // Tooltip shows on hover and focus
//           theme="qencode" // Ensure this theme is defined in your CSS
//           offset={[160, 12]}
//           maxWidth={500}
//           allowHTML={true}
//         >
//           {children}
//         </Tippy>
//     );
// };