import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";

export function getRoundedAmount(parsedLongFloatNumber, precision = 2) {
  if (parsedLongFloatNumber > .01) {
    return Math.ceil(parsedLongFloatNumber * Math.pow(10, precision)) / Math.pow(10, precision);
  } else if (parsedLongFloatNumber === 0) {
    return parsedLongFloatNumber;
  }
  return .01;
}

function MoneyRounded({
  value,
  precision = 2,
  semiBold,
  }) {
  const parsedLongFloatNumber = parseFloat(value);
  if (typeof parsedLongFloatNumber === 'number' && Number.isFinite(parsedLongFloatNumber)) {
    const rounded = getRoundedAmount(parsedLongFloatNumber, precision).toFixed(2);
    return (
      <span className={classNames({
        semiBold,
      })}>${ rounded }</span>
    );
  } else {
    return <span>—</span>
  }
}

MoneyRounded.propTypes = {
  value: PropTypes.number,
  precision: PropTypes.number,
  semiBold: PropTypes.bool,
};

export default MoneyRounded;