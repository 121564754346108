import React, { 
    useState, 
    //useEffect, useRef 
} from 'react';
import PropTypes from 'prop-types';
import { InputField } from 'components/InputField';
import { FormCheckbox } from 'components/FormCheckbox';
import { Button } from 'components/Button';
import styles from '../../index.module.css'

export const GoogleForm = ({ storageCredentials, onChange, onSave, onCancel }) => {
    const { bucket_name, access_key_id, secret_access_key, public_permissions } = storageCredentials.google;

    const [validationErrors, setValidationErrors] = useState({});

    // const initialData = useRef({}); // Use useRef to keep a snapshot of the initial data

    // useEffect(() => {
    //     // Take a snapshot of the initial data when the component mounts
    //     initialData.current = {
    //         bucket_name,
    //         access_key_id,
    //         secret_access_key,
    //         public_permissions,
    //     };
    //     // We intentionally ignore the dependencies here to avoid the effect running on every render
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []); // Empty dependency array ensures this runs only once when the component mounts


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        onChange({
            target: {
                name: name,
                value: value
            }
        })

        const [, key] = name.split('.');

        // Clear validation errors when input changes
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [key]: null,
        }));
    };

    const handleSave = () => {
        const errors = {};

        if (!bucket_name) {
            errors.bucket_name = 'Bucket name is required';
        }
        if (!access_key_id) {
            errors.access_key_id = 'Access Key ID is required';
        }
        if (!secret_access_key) {
            errors.secret_access_key = 'Secret Access Key is required';
        }

        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
        } else {
            onSave();
        }
    };

    // Handle cancel: restore the initial data
    // const handleCancel = () => {
    //     onChange({
    //         target: {
    //             name: 'google.bucket_name',
    //             value: initialData.current.bucket_name
    //         }
    //     });
    //     onChange({
    //         target: {
    //             name: 'google.access_key_id',
    //             value: initialData.current.access_key_id
    //         }
    //     });
    //     onChange({
    //         target: {
    //             name: 'google.secret_access_key',
    //             value: initialData.current.secret_access_key
    //         }
    //     });
    //     onChange({
    //         target: {
    //             name: 'google.public_permissions',
    //             value: initialData.current.public_permissions
    //         }
    //     });

    //     onCancel(); // Notify parent component
    // };    

    // enable button when all input fields are set
    const isSaveDisabled = !bucket_name || !access_key_id || !secret_access_key;

    return (
        <div className={styles.storageFormContainer}>
            <div className={styles.formRow}>
                <InputField
                    inputText={bucket_name}
                    onChange={handleInputChange}
                    name="google.bucket_name"
                    placeholder=""
                    label="Bucket Name"
                    state={validationErrors.bucket_name ? 'error' : 'default'}
                    hint={validationErrors.bucket_name || ''}
                />
            </div>

            <div className={styles.formRow}>
                <InputField
                    inputText={access_key_id}
                    onChange={handleInputChange}
                    name="google.access_key_id"
                    placeholder=""
                    label="Access Key ID"
                    autocomplete="off"
                    state={validationErrors.access_key_id ? 'error' : 'default'}
                    hint={validationErrors.access_key_id || ''}
                />
                <InputField
                    inputText={secret_access_key}
                    onChange={handleInputChange}
                    name="google.secret_access_key"
                    placeholder=""
                    label="Secret Access Key"
                    type="password"
                    autocomplete="new-password"
                    state={validationErrors.secret_access_key ? 'error' : 'default'}
                    hint={validationErrors.secret_access_key || ''}
                />
            </div>

            <div className={styles.formRow}>
                <FormCheckbox
                    name="google.public_permissions"
                    checked={public_permissions || false}
                    label="Public permissions"
                    onChange={handleInputChange}
                />
            </div>

            <div className={styles.formFooter}>
                <Button 
                    size="sm"
                    type="secondary"
                    onClick={onCancel}
                    //onClick={handleCancel}
                >
                    Cancel
                </Button>                 
                <Button size="sm" onClick={handleSave} state={isSaveDisabled ? "disabled" : undefined}>
                    Save
                </Button>
            </div>
        </div>
    );
};

GoogleForm.propTypes = {
    storageCredentials: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};



