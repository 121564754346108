import React from 'react';

import styles from './AuthLayout.module.css';

export const AuthLayout = ({ children, pageName }) => {

  return (
    <div className={styles.container}>    
      <div className={styles.contentContainer}>
        <main className={styles.content}>{children}</main>
      </div>      
    </div>
  );
};


