import React from 'react';
import PropTypes from 'prop-types';
import Controls from "components/Controls";
import {Link} from "react-router-dom";
import style from 'pages/StatisticsPage/components/Heading/style.module.css';

function Heading({
  title,
  href,
}) {
  return (
    <Controls
      title={ title }
      rightContent={
        <Link to={ href } className={style.link}>
          Details
        </Link>
      }
    />
  )
}

Heading.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
};

export default Heading;