import React from 'react';
import PropTypes from 'prop-types';
import Meter from "components/Meter";

/**
* Use this component if percentage is already calculated.
*/

function MeterForPercentage({
  title,
  percent,
  maxPercent,
  rawValue,
  ...rest
}) {
  let progressbarFillPercent = (percent * 100) / maxPercent;
  if ( !(typeof progressbarFillPercent === 'number' && Number.isFinite(progressbarFillPercent)) ) {
    progressbarFillPercent = 0;
  }
  
  let percentFormatted = '';
  
  if (percent < .01) {
    percentFormatted = '< 0.01';
  }
  else {
    percentFormatted = parseFloat(Number(percent).toFixed(2));
  }

  return (
    <Meter
      title={title}
      progressbarFillPercent={progressbarFillPercent}
      percentFormatted={percentFormatted}
      rawValue={rawValue}
      {...rest}
    />
  );
}

MeterForPercentage.propTypes = {
  title: PropTypes.string,
  rawValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]) ,
  percent: PropTypes.number,
};

export default MeterForPercentage;