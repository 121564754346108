import PropTypes from "prop-types";
import React from "react";

export const Active = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path d="M8 13.65C4.87929 13.65 2.35 11.1207 2.35 8C2.35 4.87929 4.87929 2.35 8 2.35C11.1207 2.35 13.65 4.87929 13.65 8C13.65 11.1207 11.1207 13.65 8 13.65Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.0837 7.0448L7.47949 9.64897L5.91699 8.08647" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>    
  );
};

Active.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Active.defaultProps = {
  color: 'currentColor', // Or any default color
};