import React from 'react';
import PropTypes from 'prop-types';
import {Button} from "components/Button";
import {InputField} from "components/InputField";
import {Modal} from "components/Modal";
import {updateTemplate} from "api/qencode-account-api";
import useSingleFieldForm from "helpers/useSingleFieldForm";

function RenameModal({
     open,
     handleCancel,
     templateId,
     onSuccessfulFinish,
}) {
  const {
    newName,
    submitting,
    errorMessage,
    handleNameInput,
    clearFormAndClose,
    handleConfirmSubmit,
  } = useSingleFieldForm({
    handleCancel,
    onSuccessfulFinish,
    apiFunction: (({ ...rest }) => updateTemplate({
      id: templateId,
      ...rest,
    })) ,
  });

    return (
        <Modal
            isOpen={open}
            onClose={ clearFormAndClose }
            header={
                <h3>
                    Rename Transcoding Template
                </h3>
            }
            footer={
                <>
                    <Button
                        variant="modalFooter"
                        onClick={ clearFormAndClose }
                        type="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="modalFooter"
                        state={ submitting ? "disabled" : "default" }
                        onClick={ handleConfirmSubmit }
                    >
                        Save
                    </Button>
                </>
            }
        >
            <form
                className="form"
                onSubmit={ handleConfirmSubmit }
            >
                <div className="row">
                    <InputField
                        placeholder=""
                        onChange={ handleNameInput }
                        label="New Template Name"
                        name="new-name"
                        inputText={newName}
                        state={errorMessage ? 'error' : 'default'}
                        hint={errorMessage}
                    />
                </div>
            </form>
        </Modal>
    )
}

RenameModal.propTypes = {
    open: PropTypes.bool,
    handleCancel: PropTypes.func,
    templateId: PropTypes.string,
    onSuccessfulFinish: PropTypes.func,
};

export default RenameModal;