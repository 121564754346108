import PropTypes from "prop-types";
import React from "react";

export const Edit2 = ({ className, color, label }) => {
  
  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;
  
  return (
    <svg
      className={className}
      role="img"
      aria-label={label}
      style={style}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M1.6 5A3.4 3.4 0 0 1 5 1.6h1.5a.6.6 0 1 0 0-1.2H5A4.6 4.6 0 0 0 .4 5v4A4.6 4.6 0 0 0 5 13.6h4A4.6 4.6 0 0 0 13.6 9V7.5a.6.6 0 1 0-1.2 0V9A3.4 3.4 0 0 1 9 12.4H5A3.4 3.4 0 0 1 1.6 9V5Zm9.58-3.12-.83.85.96.96.83-.84a.36.36 0 0 0 0-.5l-.45-.47v-.01a.36.36 0 0 0-.51 0Zm.54-.14a.47.47 0 0 0-.67 0l-4.42 4.4 1.21 1.2 4.42-4.4a.47.47 0 0 0 0-.67l-.54-.53ZM6.8 7.99l-.8-.8-.29 1.1L6.8 8ZM10.2.89c.65-.65 1.7-.65 2.36 0l.54.53c.65.65.65 1.72 0 2.37L8.29 8.6c-.2.2-.45.35-.73.42l-1.94.55A.96.96 0 0 1 4.43 8.4l.52-1.96c.08-.29.23-.55.44-.76l4.82-4.8Z" fill="currentColor"/>
    </svg>
  );
};

Edit2.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Edit2.defaultProps = {
  color: 'currentColor', // Or any default color
};
