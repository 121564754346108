import PropTypes from "prop-types";
import React from "react";

export const Reload = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path d="M3.58911 3.72681L2.93115 3.06885C2.77515 2.91285 2.54054 2.86619 2.33672 2.95061C2.1329 3.03504 2 3.23393 2 3.45455V5.87879C2 6.18003 2.24421 6.42424 2.54545 6.42424H4.9697C5.19031 6.42424 5.38921 6.29135 5.47363 6.08752C5.55806 5.8837 5.51139 5.64909 5.35539 5.49309L4.36182 4.49952C5.41155 3.58338 6.70476 3.09091 8 3.09091C10.5246 3.09091 12.6047 4.99703 12.8785 7.44828C12.9119 7.74766 13.1817 7.96325 13.4811 7.92981C13.7805 7.89637 13.9961 7.62656 13.9626 7.32718C13.6279 4.32992 11.0864 2 8 2C6.40116 2 4.83631 2.61529 3.58911 3.72681Z" fill="currentColor"/>
      <path d="M2.51881 8.06389C2.81824 8.03079 3.0878 8.24669 3.1209 8.54611C3.39216 11.0001 5.47351 12.9091 8 12.9091C9.24954 12.9091 10.4954 12.4513 11.5141 11.6795L10.6446 10.8099C10.4886 10.6539 10.4419 10.4193 10.5264 10.2155C10.6108 10.0117 10.8097 9.87879 11.0303 9.87879H13.4545C13.7558 9.87879 14 10.123 14 10.4242V12.8485C14 13.0691 13.8671 13.268 13.6633 13.3524C13.4595 13.4368 13.2248 13.3902 13.0689 13.2342L12.2916 12.4569C11.0725 13.4203 9.5518 14 8 14C4.91126 14 2.36828 11.6665 2.03659 8.66597C2.00349 8.36655 2.21939 8.09699 2.51881 8.06389Z" fill="currentColor"/>
    </svg>    
  );
};

Reload.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Reload.defaultProps = {
  color: 'currentColor', // Or any default color
};