import React from 'react';
import PropTypes from 'prop-types';
import {Button} from "components/Button";
import {Add} from "icons";
import {modals} from 'pages/MediaStorage/BucketsPage/config';

function CreateBucketButton({ openModal }) {
  return (
    <Button
      icon={ <Add/> }
      onClick={ () => openModal(modals.create) }
    >
      Create Bucket
    </Button>
  )
}

CreateBucketButton.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default CreateBucketButton;