import getErrorMessage from "helpers/getErrorMessage";
import {httpHeaders} from "pages/MediaStorage/config";

// const origin = 'https://api-cdn-qa.qencode.com'
// const api_version = 'v1'

//const base_api_url = `${origin}/${api_version}`

import config from 'config';

const { protocol, apiVersion, cdnApiSubdomain, domain } = config;

// The base URL for API
const base_api_url = `${protocol}://${cdnApiSubdomain}.${domain}/${apiVersion}`;

export async function listDomains({
  accessToken,
  filterLiveOnly,
  }) {
  try {
    const response = await fetch(`${base_api_url}/domains${ filterLiveOnly ? '/type/live' : '' }`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`
      },
    });

    const json = await response.json();

    if (!response.ok) {
      let error = `HTTP error ${ response.status }`;
      try {
        error = JSON.parse(json.error).detail;
      } catch (error) {}

      return {
        success: false,
        error,
      };
    }


    return {
      success: true,
      data: json.domains,
    }
  } catch (error) {
    return {
      success: false,
      error: 'An error has happened when loading list of domains.',
    };
  }
}

export async function listLiveDomains(options) {
  return listDomains({
    ...options,
    filterLiveOnly: true,
  });
}

export async function createDomain({
  accessToken,
  name,
  type,
  bucket,
  }) {
  try {
    const body = {
      name,
      type,
    };
    if (bucket) {
      body['bucket_name'] = bucket;
    }
    const response = await fetch(`${base_api_url}/domains`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify( body ),
    });

    const json = await response.json();

    if (!response.ok) {
      let error = `HTTP error ${ response }`;
      try {
        error = JSON.parse(json.error).detail
      } catch (e) {
        if (typeof json.error === 'string') {
          error = json.error;
        }
      }

      return {
        success: false,
        error,
      };
    }

    if (json.domain) {
      const domainId = json.domain.id;

      return {
        success: response.ok,
        data: {
          ...json.domain,
          tlsSubscription: (await createTlsSubscription({ accessToken, domainId })).subscription,
        },
      };
    }

    return {
      success: false,
    };
  } catch (error) {
    return {
      success: false,
      error: 'An error happened, can\'t create new domain.',
    };
  }
}

export async function deleteDomain({
  accessToken,
  domainId,
  }) {
  try {
    const response = await fetch(`${base_api_url}/domains/${domainId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      }
    });

    const json = await response.json();

    if (!response.ok) {
      let error = `HTTP error ${ response.status }`;
      try {
        error = JSON.parse(json.error).detail;
      } catch (error) {}

      return {
        success: false,
        error,
      };
    }

    return {
      success: true,
    };
  }
  catch (error) {
    return {
      success: false,
      error: 'Error, could not delete domain.'
    };
  }
}

export async function listSigningKeys({
    accessToken,
  }) {
  try {
    const response = await fetch(`${base_api_url}/signing-keys`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`
      },
    });

    const json = await response.json();

    if (!response.ok) {
      let error = `HTTP error ${ response.status }`;
      try {
        error = JSON.parse(json.error).detail;
      } catch (error) {}
      return {
        success: false,
        error,
      };
    }


    if (Array.isArray(json['signing_keys'])) {
      return {
        success: true,
        data: json['signing_keys'].map((i) => ({
          ...i,
          name: i.name || '-', /* Replacing null with '' is necessary for sorting to work (@tanstack/react-table) */
        })),
      };
    } else {
      return {
        success: false,
        error: 'Error in response data: missing property "signing_keys"',
      }
    }
  } catch (error) {
    return {
      success: false,
      error: 'An error happened when loading list of signing keys.',
    };
  }
}

export async function listSigningKeysWithDomains(options) {
  const [domains, signingKeys] = await Promise.allSettled([
    listDomains(options),
    listSigningKeys(options),
  ])
  if (signingKeys.status === 'fulfilled' && signingKeys.value.success) {
    if (domains.status === 'fulfilled' && domains.value.success) {
      const idToDomain = {};
      for (let domain of domains.value?.data) {
        idToDomain[domain.id] = domain;
      }

      return {
        ...signingKeys.value,
        data: signingKeys.value.data.map((signingKey) => {
          if (signingKey['bucket_name']) {
            return {
              ...signingKey,
              typeColumnValue: 'Media Storage Bucket',
              keyEntityColumnValue: signingKey?.['bucket_name'] || '-',
            };
          } else if (signingKey['stream_id']) {
            return {
              ...signingKey,
              typeColumnValue: 'Live Streaming',
              keyEntityColumnValue: signingKey?.['stream_id'] || '-',
            };
          } else if (signingKey['domain_id']) {
            const domain = idToDomain[signingKey['domain_id']];
            return {
              ...signingKey,
              typeColumnValue: 'Live Stream',
              keyEntityColumnValue: domain?.['domain_name'] || '-',
            }
          } else {
            return {
              ...signingKey,
              typeColumnValue: '-',
              keyEntityColumnValue: '-',
            };
          }
        }),
      }
    } else {
      return signingKeys.value;
    }
  }
  return {
    data: [],
    success: false,
  };
}

export async function renameKey({
  accessToken,
  id,
  name,
  }) {
  try {
    const response = await fetch(`${base_api_url}/signing-keys/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        name,
      }),
    });
    const json = await response.json();

    if (!response.ok) {
      const error = getErrorMessage(json);

      return {
        success: false,
        error,
      };
    }

    return {
      success: true,
    }
  } catch (error) {
    return {
      success: false,
      error: 'An error happened when updating signing key name.',
    };
  }
}

export async function deleteKey({
    accessToken,
    id,
  }) {
  try {
    const response = await fetch(`${base_api_url}/signing-keys/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });
    const json = await response.json();

    if (!response.ok) {
      let error = `HTTP error ${ response.status }`;
      try {
        error = JSON.parse(json.error).detail;
      } catch (error) {}

      return {
        success: false,
        error,
      };
    }

    return {
      success: true,
      data: json,
    }
  } catch (error) {
    return {
      success: false,
      error: 'An error happened when updating signing key name.',
    };
  }
}

export async function createKey(
  {
    accessToken,
    ...body
  }
) {
  try {
    const response = await fetch(`${base_api_url}/signing-keys`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify( body ),
    });
    const json = await response.json();

    if (!response.ok) {
      const error = getErrorMessage(json);

      return {
        success: false,
        error,
      };
    }

    return {
      success: true,
      data: json?.['signing_key'],
    }
  } catch (error) {
    return {
      success: false,
      error: 'An error happened when updating signing key name.',
    };
  }
}

export async function listSubscription({
    accessToken,
  }) {
  try {
    const response = await fetch(`${base_api_url}/tls/subscriptions`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`
      },
    });
    
    const json = await response.json();
    
    if (!response.ok) {
      let error = `HTTP error ${ response.status }`;
      try {
        error = JSON.parse(json.error).detail;
      } catch (error) {}
      
      return {
        success: false,
        error,
      };
    }

    return {
      success: true,
      data: json,
    }
  } catch (error) {
    return {
      success: false,
      error: 'And error has happened when loading list of subscriptions.',
    };
  }
}

export async function createTlsSubscription({
  accessToken,
  domainId,
}) {
  try {
    const subscriptionResponse = await fetch( `${base_api_url}/tls/subscriptions` , {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        'domain_id': domainId,
      }),
    });

    const subscriptionJson = await subscriptionResponse.json();

    return {
      success: subscriptionResponse.ok,
      subscription: subscriptionJson['tls_subscription'],
    }
  } catch (error) {
    return {
      success: false,
    };
  }
}

export async function listDomainsAndSubscriptions(options) {
  const [domains, subscription] = await Promise.allSettled([
    listDomains(options),
    listSubscription(options),
  ])
  if (domains.status === 'fulfilled' && domains.value.success) {
    if (subscription.status === 'fulfilled' && subscription.value.success) {
      
      return {
        ...domains.value,
        data: domains.value.data.map((domain) => {
          const tlsSubscription = subscription.value?.data?.['tls_subscriptions']
            ?.find((subscription) => subscription['domain_id'] === domain.id) || { status: '-' };

          return {
            ...domain,
            tlsStatus: tlsSubscription.status,
            tlsSubscription,
          }
        })
      }
    } else {
      return domains.value;
    }
  }
  return {
    data: [],
    success: false,
  }
}

export async function getCorsPolicy({
  bucketName,
  accessToken,
}) {
  // const genericError = 'Could not load CORS policy data';
  try {
    const response = await fetch(`${base_api_url}/buckets/${bucketName}/cors`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      }
    });
    const json = await response.json();
    if (json.error) {
      return {
        success: false,
        error: json.error,
      }
    } else {
      json[httpHeaders.allowOrigin] = (json[httpHeaders.allowOrigin] ?? '')?.split(',').map((origin, index) => ({
        id: index,
        value: origin,
      }))
      json[httpHeaders.allowMethods] = Array.from(
        (json[httpHeaders.allowMethods] ?? '')?.matchAll(/([A-Z])\w+/g)
        ).map((item) => item[0]);
      
      return {
        success: true,
        data: json,
      };
    }
    
  } catch (error) {
    return {
      success: false,
      error: error?.message,
    };
  }
}

export async function setCorsPolicy({
    bucketName,
    accessToken,
    body,
  }) {
  try {
    const response = await fetch(`${base_api_url}/buckets/${bucketName}/cors`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify(body),
    });
    console.log(response)
  } catch (error) {
    return {
      success: false,
    };
  }
}

export async function deleteCorsHeader({
    bucketName,
    accessToken,
    headerName,
  }) {
  try {
    const response = await fetch(`${base_api_url}/buckets/${bucketName}/cors/${headerName}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      }
    });
    console.log(response)
  } catch (error) {
    return {
      success: false,
    };
  }
}

export async function purgeCdnCache({
    accessToken,
    bucketName,
  }) {
  try {
    const response = await fetch(`${base_api_url}/buckets/${bucketName}/cache/purge_all`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      }
    });
    const json = await response.json();
    if (json.status === 'ok') {
      return {
        success: true,
      }
    }
    return {
      success: false,
    }
  } catch (error) {
    return {
      success: false,
    };
  }
}

export async function getAccessPolicy({
    accessToken,
    bucketName,
  }) {
  const genericErrorMessage = 'Error loading access policy';
  try {
    const response = await fetch(`${base_api_url}/buckets/${bucketName}/policy`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      }
    });
    const json = await response.json();

    if (typeof json.policy === 'string') {
      return {
        success: true,
        policy: json.policy,
      }
    }
    if (typeof json.error === 'string') {
      return {
        success: false,
        error: json.error,
      }
    }
    
    return {
      success: false,
      error: genericErrorMessage,
    }
  } catch (error) {
    return {
      success: false,
      error: genericErrorMessage,
    };
  }
}

export async function setAccessPolicy({
    accessToken,
    bucketName,
    policy,
  }) {
  const genericErrorMessage = 'Error updating access policy';
  try {
    const response = await fetch(`${base_api_url}/buckets/${bucketName}/policy/${policy}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      }
    });
    const json = await response.json();
    
    if (json.error) {
      return {
        success: false,
        error: json.error,
      };
    }
    return {
      success: true,
    }
  } catch (error) {
    return {
      success: false,
      error: genericErrorMessage,
    };
  }
}
