import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import BriefPanel from "pages/StatisticsPage/components/BriefPanel";
import TopRegions from "pages/StatisticsPage/CdnTab/TopRegions";
import UsageBy from "pages/StatisticsPage/CdnTab/UsageBy";
import Controls from "components/Controls";
import VerticalGapsLayout from "components/VerticalGapsLayout";
import Chart from "pages/StatisticsPage/components/Chart";
import style from './style.module.css';
import {Select} from "components/Select";
import {regionCodeToName, regionToColor} from "pages/StatisticsPage/config";
import BriefItem from "pages/StatisticsPage/components/BriefPanel/BriefItem";
import CdnTooltipContent from "pages/StatisticsPage/CdnTab/CdnTooltipContent";
import NoChartData from "pages/StatisticsPage/components/Chart/NoData";


const usageByIds = {
  all: 'all',
  storage: 'storage',
  liveStreamDelivery: 'liveStreamDelivery',
};


function CdnTab({
  cdn,
  datepicker,
}) {
  const [selectedRegion, setSelectedRegion] = useState('all');
  
  const defaultUsageByOptions = useMemo(() => {
    let list = [];
    let count = 0;
    if (Array.isArray(cdn.stats.status.data.ms) && cdn.stats.status.data.ms.length > 0) {
      count += 1;
      list.push({
        label: 'Storage',
        value: usageByIds.storage,
      });
    }
    if (Array.isArray(cdn.stats.status.data.ls) && cdn.stats.status.data.ls.length > 0) {
      count += 1;
      list.push({
        label: 'Live stream delivery',
        value: usageByIds.liveStreamDelivery,
      });
    }
    if (count >= 2) {
      list = [{
        label: 'All',
        value: usageByIds.all,
      }, ...list];
    }
    return list
  }, [cdn.stats.status.data]);

  const [usageBy, setUsageBy] = useState(defaultUsageByOptions[0]?.value);
  
  useEffect(() => {
    setUsageBy( defaultUsageByOptions[0]?.value );
  }, [defaultUsageByOptions])
  
  const [totalAmount, totalBandwidth] = useMemo(() => {
    const amount = Object.values(cdn.total.status.data).reduce((acc, item) => acc + item.amount, 0);
    const bandwidth = Object.values(cdn.total.status.data).reduce((acc, item) => acc + item.bandwidth, 0);
    return [
      amount,
      bandwidth,
    ];
  }, [cdn.total]);
  
  const categoriesData = useMemo(() => {
    if (usageBy === usageByIds.all) {
      return Object.values(cdn.stats.status.data).flat();
    } else if (usageBy === usageByIds.storage) {
      return cdn.stats.status.data.ms;
    } else if (usageBy === usageByIds.liveStreamDelivery) {
      return cdn.stats.status.data.ls;
    } else {
      return [];
    }
  }, [cdn.stats.status.data, usageBy]);
  
  const uniqueRegions = useMemo(() => Array.from(new Set( categoriesData.map((i) => i.region) )), [categoriesData]);

  const {
    dataEmpty,
    statsByDate,
    statsByRegion,
  } = useMemo(() => {
    const statsByRegion = {};
    const stats = Object.fromEntries(datepicker.dates.map((date) => [
      date,
      {
        date,
      }
    ]));
    let dataEmpty = true;

    for (let obj of categoriesData) {
      const value = obj.bandwidth || 0;
      
      if (typeof value === 'number' && Number.isFinite(value) && value > 0 && typeof obj.date === 'string') {
        dataEmpty = false;
        stats[obj.date] = stats[obj.date] || {
          date: obj.date,
        };
        
        stats[obj.date][obj.region] = (stats[obj.date][obj.region] || 0) + value;
        statsByRegion[obj.region] = (statsByRegion[obj.region] || 0) + value;
      }
    }
    const statsByDate = Object.values(stats).map((item) => {
      const itemCopy = {
        ...item,
      }
      for (let key of uniqueRegions) {
        const value = item[key];

        if (typeof value === 'number' && Number.isFinite(value)) {
          itemCopy[key] = value / Math.pow(1024, 3);
        } else {
          itemCopy[key] = 0;
        }
      }
      return itemCopy;
    });
    
    return {
      dataEmpty,
      statsByDate,
      statsByRegion,
    }
  }, [categoriesData, datepicker.dates, uniqueRegions]);
  
  const regionOptions = useMemo(() => {
    return [
      uniqueRegions.length > 0 ? [{
        label: 'All Regions',
        value: 'all',
      }] : [],
      uniqueRegions.map((regionCode) => ({
        value: regionCode,
        label: regionCodeToName[regionCode] || regionCode,
      }))].flat()
  }, [uniqueRegions]);

  const selectedRegionsArray = useMemo(() => {
    if (selectedRegion === 'all') {
      return regionOptions.filter((i) => i.value !== 'all').map((i) => i.value);
    } else {
      return [selectedRegion];
    }
  }, [regionOptions, selectedRegion]);
  
  const noDataInSelected = useMemo(() => {
    if (dataEmpty) {
      return true;
    } else {
      const sum = selectedRegionsArray.reduce((acc, selectedRegion) => acc + (statsByRegion[selectedRegion] || 0), 0);
      return typeof sum === 'number' && Number.isFinite(sum) && sum === 0;
    }
  }, [dataEmpty, selectedRegionsArray, statsByRegion]);
  
  function onUsageBySelect(e) {
    setUsageBy(e?.target?.value);
    setSelectedRegion('all');
  }

  return (
    <VerticalGapsLayout>
      <Controls
        title="Usage by"
        leftContent={
          <Select
            className={style.usageBySelect}
            options={defaultUsageByOptions}
            value={usageBy}
            onChange={onUsageBySelect}
            disabled={cdn.stats.status.loading || defaultUsageByOptions.length === 0}
          />
        }
        rightContent={
          <div>
            <Select
              options={regionOptions}
              onChange={( e ) => setSelectedRegion( e?.target?.value )}
              className={style.regionSelect}
              value={selectedRegion}
              disabled={cdn.stats.status.loading || regionOptions.length === 0}
            />
          </div>
        }
      />
      <Chart
        chartData={statsByDate}
        loading={cdn.stats.status.loading}
        keyToColor={regionToColor}
        legend="Bandwidth, GB"
        dataKeys={selectedRegionsArray}
        contentComponent={CdnTooltipContent}
      >
        {
          noDataInSelected ? (
            <NoChartData
              startDate={datepicker.startDate}
              endDate={datepicker.endDate}
            />
          ) : null
        }
      </Chart>
      <BriefPanel>
        <BriefItem
          heading="Total Delivery"
          loading={cdn.stats.status.loading}
          type="byte"
          value={totalBandwidth}
        />
        <BriefItem
          heading="Total Cost"
          loading={cdn.stats.status.loading}
          type="money"
          value={totalAmount}
        />
      </BriefPanel>
      <TopRegions stats={cdn.topRegion.status} />
      <UsageBy stats={ cdn.usage.status } />
    </VerticalGapsLayout>
  )
}

CdnTab.propTypes = {
  datepicker: PropTypes.object.isRequired,
};

export default CdnTab;