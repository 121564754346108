import React from 'react';
import PropTypes from 'prop-types';
import CopyToClipboard from "components/CopyToClipboard";
import style from './style.module.css';

function MaskedKey({ rawKey, withCopy }) {
  if (withCopy) {
    return (
      <div className={ style.signingKeyCellContent }>
        <span className={ style.signingKeyId }>
          <Key rawKey={ rawKey }/>
        </span>
        <CopyToClipboard text={ rawKey }/>
      </div>
    );
  }
  return (
    <Key rawKey={rawKey}/>
  );
}

function Key({ rawKey }) {
  return (
    <>
      <span className={ style.maskedDigit }>* * * * </span>
      <span>
        { rawKey.slice(-4) }
      </span>
    </>
  );
}

MaskedKey.propTypes = {
  rawKey: PropTypes.string.isRequired,
  withCopy: PropTypes.bool,
};

export default MaskedKey;