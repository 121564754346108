import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from "components/Modal";
import BucketInfo from "pages/MediaStorage/BucketInfo";
import style from './style.module.css';
import {Button} from "components/Button";
import {InputField} from "components/InputField";
import { Copy} from 'icons';
import Footer from './Footer';
import Tooltip from "components/Tooltip";
import useCopyToClipboard from "components/CopyToClipboard/useCopyToClipboard";
import useCorsSection from "pages/MediaStorage/BucketSettingsModal/useCorsSection";
import useAccessPolicy from "pages/MediaStorage/BucketSettingsModal/useAccessPolicy";
import useEnableCdn from "pages/MediaStorage/BucketSettingsModal/useEnableCdn";
import EnableCdnFields from "pages/MediaStorage/BucketSettingsModal/EnableCdnFields";
import CorsContent from "pages/MediaStorage/BucketSettingsModal/CorsContent";


function BucketSettingsModal({ open, bucket, handleClose, goToDeleteModal, refreshTable }) {
  const enableCdnData = useEnableCdn(bucket);
  const corsData = useCorsSection(bucket, enableCdnData, open);
  const accessPolicyData = useAccessPolicy(bucket, enableCdnData, open);

  const {
    copy,
    copied,
  } = useCopyToClipboard(bucket?.endpoint);
  
  function clearForm() {
    enableCdnData?.clearForm?.();
    corsData?.clearForm?.();
    accessPolicyData?.clearForm?.();
  }
  
  function clearFormAndClose() {
    clearForm?.();
    corsData.goToBucketSettings();
    handleClose?.();
  }

  function clearFormAndDelete() {
    clearForm?.();
    goToDeleteModal?.();
  }
  
  async function onSubmit() {
    const {
      submitted: submittedCdnService,
      } = await enableCdnData.handleSubmit(bucket);
    const submittedAccessPolicy = await accessPolicyData.handleSubmit(bucket);
    const submittedCors = await corsData.handleSubmit(bucket);
    if (submittedCdnService || submittedAccessPolicy || submittedCors) {
      if (submittedCdnService) {
        /* Access Policy and CORS are going to be loaded on modal reopen anyway. */
        refreshTable?.();
      }
      clearFormAndClose();
    }
  }
  // console.log('corsData', corsData)
  return (
    <Modal
      isOpen={open}
      onClose={clearFormAndClose}
      header={
        corsData.corsScreenActive ? (
          <>
            <h3>CORS Configuration</h3>
            <p>Set permission settings for access to this bucket.</p>
          </>
        ) : (
          <>
            <h3>Bucket Settings</h3>
            <p>Update bucket configuration and access settings.</p>
          </>
        )
      }
      footer={
        <Footer
          corsData={corsData}
          onSubmit={onSubmit}
          handleClose={clearFormAndClose}
          handleDelete={clearFormAndDelete}
          submitting={enableCdnData.submitting || accessPolicyData.submitting || corsData.submitting}
        />
      }
    >
      <BucketInfo
        title={bucket?.name}
        regionCode={bucket?.region}
        created={bucket?.created}
      />
      {
        corsData.corsScreenActive ? (
          <CorsContent { ...corsData } />
        ) : (
          <div className={style.main}>
            <div className={style.section}>
              <div className={style.urlField}>
                <InputField
                  state="disabled"
                  label="Endpoint"
                  inputText={bucket?.endpoint}
                  hint=""
                />
                <Tooltip
                  text={copied ? 'Copied!' : 'Copy'}
                  visible={copied}
                  showOnHover={false}
                >
                  <Button
                    type="secondary"
                    state={ bucket?.endpoint ? 'default' : 'disabled' }
                    onClick={copy}
                  >
                    <Copy className={style.copy}/>
                    Copy
                  </Button>
                </Tooltip>
              </div>
              <p className={style.hint}>
                Copy the endpoint URL to access this bucket
              </p>
            </div>
            <EnableCdnFields
              bucket={bucket}
              enableCdnData={enableCdnData}
              corsData={corsData}
              accessPolicyData={accessPolicyData}
            />
          </div>
        )
      }
    </Modal>
  );
}


BucketSettingsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  bucket: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  goToDeleteModal: PropTypes.func.isRequired,
  refreshTable: PropTypes.func.isRequired,
};

export default BucketSettingsModal;