import React, { useState, useEffect } from 'react';

import { useAWS } from 'api/useAWS';

import { ReactComponent as ItemDeleteImage } from 'assets/images/ItemDelete.svg';

import { 
    Delete as DeleteIcon, 
} from 'icons';

import styles from './index.module.css';

import { Modal } from 'components/Modal';

import { Button } from 'components/Button'

export const DeleteModal = ({ currentBucket, configData, itemsToDelete, open, handleCancel, onSuccessfulFinish }) => {

    // console.log("itemToRename: ", itemToRename)

    const {
        deleteFileFromQencodeBucket,
        deleteFolderFromQencodeBucket
    } = useAWS();

    const [deleteItems, setDeleteItems] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteError, setDeleteError] = useState(null); // Track any errors during deletion
  
    // when modal opens set initial states
    useEffect(() => {
        if(open){
            setDeleteItems(itemsToDelete)
        }
    }, [open, itemsToDelete])    

    const closeDeleteModal = () => {
        // reset error
        if (deleteError) {
            setDeleteError(null);
        }      

        handleCancel()
    };  
      
    const handleDeleteItems = async (items) => {
        console.log("Delete items: ", items)

        setIsDeleting(true); // Disable confirm button and show progress
    
        try {
            const bucketName = currentBucket.name;
            const config = {
                access_id: configData.access_id,
                secret_key: configData.secret_key,
                region: currentBucket.region,
            };
    
            // Iterate over each item and delete it
            for (const item of items) {
                let result;
    
                if (item.type === 'file') {
                    const fileKey = item.name;
                    result = await deleteFileFromQencodeBucket(bucketName, fileKey, config);
                } else {
                    const folderName = item.name;
                    result = await deleteFolderFromQencodeBucket(bucketName, folderName, config);
                }
    
                if (!result.success) {
                    console.error(result.error || "Error while deleting the item.");
                    setDeleteError(result.error || "Error while deleting the item.");
                    //setIsDeleteErrorModalOpen(true); // Show error modal - replace with alert
                } 
            }
    
            // Fetch updated items after all deletions are complete
            //onSuccessfulFinish() 
    
        } catch (error) {
            console.error('An error occurred during the deletion process:', error.message);
            setDeleteError(error.message || "An unexpected error occurred during deletion.");
            //setIsDeleteErrorModalOpen(true); // Show error modal - replace with alert
        } finally {
            // Reset the deletion state
            setIsDeleting(false);

            // Fetch updated items after all deletions are complete
            onSuccessfulFinish()             
        }
    }

    return (
        <Modal isOpen={open} onClose={closeDeleteModal}
            variant='popUp'
            icon={<ItemDeleteImage />}
            header={
                <>
                    {
                        (deleteItems && deleteItems.length === 1 && deleteItems[0].type === 'folder') &&
                        <>
                            <h3> Delete this Folder?</h3>
                            <p>
                                Are you sure you want to delete folder <span className={styles.itemName}><strong>{deleteItems[0].displayName}</strong></span>?
                            </p>                            
                        </>
                    }

                    {
                        (deleteItems && deleteItems.length === 1 && deleteItems[0].type === 'file') &&
                        <>
                            <h3> Delete this File?</h3>
                            <p>
                                Are you sure you want to delete file <span className={styles.itemName}><strong>{deleteItems[0].displayName}</strong></span>?
                            </p>                            
                        </>
                    }

                    {
                        (deleteItems && deleteItems.length > 1) &&
                        <>
                            <h3> Delete these Items?</h3>
                            <p>
                                Are you sure you want to delete these items?
                            </p>                            
                        </>                            
                    }
                </>
            }                   
            footer={
                <>
                    <Button
                        variant="modalFooter"
                        onClick={closeDeleteModal}
                        type="secondary"
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="modalFooter"
                        type="danger"
                        icon={<DeleteIcon />}
                        onClick={() => handleDeleteItems(deleteItems)} 
                        state={isDeleting ? "disabled" : undefined}
                    >
                        {isDeleting ? "Deleting..." : "Delete"}
                    </Button>                       
                </>
            }                
        >
            {
                deleteItems && deleteItems.length > 1 &&
                <>
                    {deleteItems.map((item, index) => (
                        <div key={index}><span className={styles.itemName}><strong>{item.displayName}</strong></span></div>
                    ))}
                </>
            }      

            {/* { 
                deleteError && 
                <div className={styles.errorMessage}>Error: {deleteError}</div>
            } */}
        </Modal>
    );
}
