import {useState} from 'react';
import {useAuth} from "context/AuthContext";

export default function useSingleFieldForm({
  handleCancel,
  onSuccessfulFinish,
  apiFunction,
}) {
  const { getToken } = useAuth();
  const [submitting, setSubmitting] = useState(false);
  const [newName, setNewName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  
  async function handleConfirmSubmit(e) {
    e?.preventDefault();
    if ( (newName || '').length < 1 ) {
      return;
    }
    const accessToken = await getToken();
    setSubmitting(true);
    
    const {
      success,
      error,
    } = await apiFunction({
      name: newName,
      accessToken,
    });
    if (success) {
      clearFormAndClose();
      onSuccessfulFinish?.();
    } else {
      setErrorMessage(error);
    }
    setSubmitting(false);
  }
  
  function handleNameInput(e) {
    setNewName(e.target.value);
    setErrorMessage();
  }
  
  function clearFormAndClose() {
    setNewName('');
    handleCancel();
    setErrorMessage();
  }
  
  return {
    newName,
    submitting,
    errorMessage,
    handleNameInput,
    clearFormAndClose,
    handleConfirmSubmit,
  }
}
