import PropTypes from "prop-types";
import React from "react";

// color controlled by class from parent and/or by color as parameter
// <Help className={styles.helpIcon} color="var(--Primary-500, #5B3EF5)" />
export const Help = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}    
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M8.5 3.09999C5.51762 3.09999 3.09992 5.51764 3.09992 8.49997C3.09992 11.4823 5.51762 13.8999 8.5 13.8999C11.4824 13.8999 13.9001 11.4823 13.9001 8.49997C13.9001 5.51764 11.4824 3.09999 8.5 3.09999ZM1.8999 8.49997C1.8999 4.8549 4.85487 1.89999 8.5 1.89999C12.1451 1.89999 15.1001 4.8549 15.1001 8.49997C15.1001 12.145 12.1451 15.0999 8.5 15.0999C4.85487 15.0999 1.8999 12.145 1.8999 8.49997ZM6.56696 7.05499C6.56696 5.98695 7.43227 5.12166 8.50033 5.12166C9.56838 5.12166 10.4337 5.98695 10.4337 7.05499C10.4337 7.90366 9.87703 8.34957 9.50971 8.59717L9.50898 8.59766C9.35776 8.69927 9.25736 8.78409 9.19267 8.87127C9.13798 8.94497 9.10033 9.03104 9.10033 9.16631V9.33298C9.10033 9.66435 8.8317 9.93298 8.50033 9.93298C8.16895 9.93298 7.90032 9.66435 7.90032 9.33298V9.16631C7.90032 8.30653 8.46324 7.85464 8.83929 7.60191C8.98659 7.50259 9.08342 7.42045 9.14557 7.33666C9.19777 7.26627 9.23367 7.18446 9.23367 7.05499C9.23367 6.64969 8.90563 6.32166 8.50033 6.32166C8.09502 6.32166 7.76698 6.64969 7.76698 7.05499C7.76698 7.38636 7.49835 7.65499 7.16697 7.65499C6.8356 7.65499 6.56696 7.38636 6.56696 7.05499ZM9.10001 11.2783C9.10001 10.9469 8.83138 10.6783 8.5 10.6783C8.16862 10.6783 7.89999 10.9469 7.89999 11.2783V11.4449C7.89999 11.7763 8.16862 12.0449 8.5 12.0449C8.83138 12.0449 9.10001 11.7763 9.10001 11.4449V11.2783Z" fill="currentColor"/>
    </svg>    

  );
};

Help.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Help.defaultProps = {
  color: 'currentColor', // Or any default color
};