import React, { useState, useCallback, useEffect } from 'react';

import { useBackend } from 'api/useBackend';
import { useAWS } from 'api/useAWS';

import { Modal } from '../../../components/Modal';

import { Button } from '../../../components/Button'

import { NoData } from 'components/NoData';

import { ReactComponent as Folder2 } from 'assets/images/Folder2.svg';

import styles from '../index.module.css';

// table related
import {getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable} from "@tanstack/react-table";
import globalFilterFn from "components/Table/columnFilters/exactCaseInsensitiveFilter";
import Table from "components/Table";
import Search from "components/Search";

import { Breadcrumbs } from 'components/Breadcrumbs'
import { BucketDetailsHeader } from 'components/BucketDetailsHeader'

import { Check, FolderAlt, VideoFile } from "icons";

// Need to remove everything related to selectedFile

export const QencodePathModal = ({ currentBucket, open, handleCancel, onSuccessfulFinish }) => {

    const {
        getS3config
    } = useBackend();

    const {
        getItemsForQencodeBucket
    } = useAWS();

    // s3 config
    const [loadingS3config, setLoadingS3config] = useState(true);
    const [configError, setConfigError] = useState(null);

    const [configData, setConfigData] = useState({});    

    // items in the bucket
    const [currentItems, setCurrentItems] = useState([]);
    const [loadingCurrentItems, setLoadingCurrentItems] = useState(true);
    const [currentItemsLoadedSuccessfully, setCurrentItemsLoadedSuccessfully] = useState(false);
    const [currentItemsError, setCurrentItemsError] = useState(null);

    //const [selectedFile, setSelectedFile] = useState('');

    const [selectedFolder, setSelectedFolder] = useState('');

    // const [breadcrumbPath, setBreadcrumbPath] = useState([currentBucket.name]); 
    const [breadcrumbPath, setBreadcrumbPath] = useState([]); // bucket name removed from path in new design

    const fetchConfigData = useCallback(async () => {
        try {
            setLoadingS3config(true);
            const { success, data, detail } = await getS3config();
            if (success && data) {
                console.log("config data: ", data);

                setConfigData(data)
                setConfigError(null);
            } else {
                setConfigError(detail || "Failed to get S3 config data");
                console.error(detail || "Failed to get S3 config data");
            }
        } catch (error) {
            setConfigError(error.message);
            console.error(error); // Here, replace with more advanced error handling if necessary
        } finally {
            setLoadingS3config(false); // Ensures loading is set to false regardless of the outcome
        }
    }, [getS3config]);        
  
    // bucket items table
    const [globalFilterItems, setGlobalFilterItems] = useState('');
    const [sortingItems, setSortingItems] = useState([]);
    const columnsItems = React.useMemo(() => [
        {
          id: '1',
          accessorKey: 'displayName',
          header: () => <span>Name</span>,
          cell: (info) => {
            return (
               <div className='cellContainer'> 
                    { info.row.original?.type && info.row.original?.type === "folder" && <FolderAlt className={styles.bucketItemIcon} />}
                    { info.row.original?.type && info.row.original?.type === "file" && <VideoFile className={styles.bucketItemIcon} />}
                    <div className='cellLimited cellHighlighted cellFullWidth'>
                        { info.getValue() }
                    </div>
                    { info.row.original?.selected && <Check className={styles.selectedIcon} />}
                </div>
            );
          },
        },
    ],[]);
    const tableItems = useReactTable({
        data: currentItems,
        columns: columnsItems,
        getCoreRowModel: getCoreRowModel(),
        /* Sorting */
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSortingItems,
        /* Filtering */
        globalFilterFn,
        getFilteredRowModel: getFilteredRowModel(),
        onGlobalFilterChange: setGlobalFilterItems,
        state: {
            sorting: sortingItems,
            globalFilter: globalFilterItems
        }
    })

    const fetchItems = useCallback(async (bucketName, config, prefix = '') => {

        // clear items list
        setCurrentItems([])

        setLoadingCurrentItems(true)
        setCurrentItemsLoadedSuccessfully(false)

        const { success, items, error } = await getItemsForQencodeBucket(bucketName, config, prefix);
        if (success) {
            console.log("items: ", items)

            // filter out files as I only need to show folders
            const folders = items.filter(item => item.type === 'folder');

            // setCurrentItems(items);
            setCurrentItems(folders);

            setCurrentItemsLoadedSuccessfully(true);
            setCurrentItemsError(null);
            setLoadingCurrentItems(false)
        } else {
            setCurrentItems([]);
            setCurrentItemsError(error);
            setLoadingCurrentItems(false)
        }   
    }, [getItemsForQencodeBucket]); 

    const handleConfirmClick = () => {
        console.log("selectedFolder: ", selectedFolder)
        onSuccessfulFinish(selectedFolder);
    };

    const onItemRowClick = async (item) => {
        if (item.type === 'folder') {
            const config = {
                access_id: configData.access_id,
                secret_key: configData.secret_key,
                region: currentBucket.region,
            };

            console.log("Get more for ", item)

            fetchItems(currentBucket.name, config, item.name);
            setBreadcrumbPath(prev => [...prev, item.displayName]);

            // set selected folder
            setSelectedFolder(item.name)
            console.log("item: ", item)

            // reset search 
            setGlobalFilterItems('')

            // clear selected file
            //setSelectedFile('')
        } 
        // else if (item.type === 'file') {
        //     const updatedItems = currentItems.map(it => 
        //         it.name === item.name ? { ...it, selected: true } : { ...it, selected: false }
        //     );
        //     setCurrentItems(updatedItems);
        //     setSelectedFile(item);
        //     console.log("Selected file: ", item);
        // }
    };

    const onBreadcrumbClick = async (index) => {
        // reset search 
        setGlobalFilterItems('')

        const config = {
            access_id: configData.access_id,
            secret_key: configData.secret_key,
            region: currentBucket.region,
        };
    
        // // Slice the path excluding the bucket name
        // let path = breadcrumbPath.slice(1, index + 1).join('/');

        // there is no bucket name at the start any more
        // Slice the path up to the current index
        let path = breadcrumbPath.slice(0, index + 1).join('/');              


        // Ensure the path ends with a /
        if (path && !path.endsWith('/')) {
            path += '/';
        }

    
        fetchItems(currentBucket.name, config, path);
        setBreadcrumbPath(breadcrumbPath.slice(0, index + 1));

        // clear selected file
        //setSelectedFile('')

        // set selected folder to clicked path
        setSelectedFolder(path)
    };

    useEffect(() => {
        fetchConfigData();
    }, [fetchConfigData]);   
    
    useEffect(() => {
        if(configData?.access_id && currentBucket?.region){
            console.log("Load bucket items on load")
            console.log("currentBucket: ", currentBucket)

            const config = {
                access_id: configData.access_id,
                secret_key: configData.secret_key,
                region: currentBucket.region,
            };
    
            fetchItems(currentBucket.name, config);  
        }
    }, [configData, fetchItems, currentBucket]);   

    return (
        <Modal
            className={styles.fileSelectionModal}
            isOpen={open}
            onClose={handleCancel}
            // header={<h3>Choose Video File for Transcoding</h3>}
            footer={
                <>
                    <Button 
                        size="sm"
                        onClick={handleCancel} 
                        type='secondary'
                    >
                        Cancel
                    </Button> 
                    <Button 
                        size="sm"
                        onClick={handleConfirmClick} 
                        // state={!selectedFolder ? "disabled" : undefined} 
                    >
                        Choose Folder
                    </Button>  
                </>
            }
        >
            <div>
                <div>
                    <div className={styles.modalHeader}>
                        <h3 className={styles.modalTitle}>Choose Path</h3>                    
                    </div>
                    <div>

                        {/* <div>Selected file: {selectedFile?.sourceUrl}</div> */}

                        <div className={styles.itemsContainer}>

                            { currentBucket && <BucketDetailsHeader bucket={currentBucket}/>}

                            <div className={styles.itemsBody}>

                                <div className={styles.modalControls}>

                                    {/* { breadcrumbPath?.length > 0 && <Breadcrumbs path={breadcrumbPath} onNavigate={onBreadcrumbClick} />} */}

                                    <Breadcrumbs path={breadcrumbPath} onNavigate={onBreadcrumbClick} />
                                    
                                    <div className={styles.modalFiltersContainer}>
                                        <Search setGlobalFilter={setGlobalFilterItems} globalFilter={globalFilterItems}/>    
                                    </div>

                                </div>

                                {/* <div className={styles.modalTableContainerBucketItems}> */}
                                <div className={`${styles.modalTableContainerBucketItems} ${currentBucket ? styles.showBucketHeaderSmall : ''}`}>
                                    <Table
                                        data={currentItems}
                                        table={tableItems}
                                        globalFilter={globalFilterItems}
                                        resetFilter={() => setGlobalFilterItems('')}
                                        loading={loadingS3config || loadingCurrentItems}
                                        loadedSuccessfully={currentItemsLoadedSuccessfully}
                                        errorMessage={configError || currentItemsError}
                                        getEmptyComponent={() => <NoData
                                            image={<Folder2 />}
                                            title="No folders"
                                            description="There are no folders in this directory."
                                        />}
                                        onRowClick={onItemRowClick}
                                    />                            
                                </div>

                            </div>


                        </div>      
                        


                    </div>
                </div>


                {/* <div>
                    File selection table
                </div> */}

            </div>
        </Modal>
    );
}

// in case there is onClick on the row
// cell: (info) => (
//     <div onClick={(e) => {
//         e.stopPropagation(); // Stop click event from bubbling up to the row
//         console.log(info.row.original);
//     }}>
//         {info.getValue()}
//     </div>
// )