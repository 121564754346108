import PropTypes from "prop-types";
import React from "react";

export const Add = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg
      className={className}
      role="img"
      aria-label={label}
      style={style}
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path d="M9.37 3.87a.87.87 0 0 0-1.74 0v3.76H3.87a.87.87 0 0 0 0 1.74h3.76v3.76a.87.87 0 0 0 1.74 0V9.37h3.76a.87.87 0 0 0 0-1.74H9.37V3.87Z" fill="currentColor"/>
    </svg>    
  );
};

Add.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Add.defaultProps = {
  color: 'currentColor', // Or any default color
};