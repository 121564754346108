import React from 'react';
import PropTypes from 'prop-types';
import ProjectsFilterSection from "pages/Transcoding/JobHistoryPage/ProjectsFilterSection";
import {columnIds, columnIdToReadableName} from "pages/Transcoding/JobHistoryPage/config";
import DateRangeFilter from "components/FilterSidebar/DateRangeFilter";
import MultiSelectFilter from "components/FilterSidebar/MultiSelectFilter";
import NumberRangeFilter from "components/FilterSidebar/NumberRangeFilter";
import DurationRangeFilter from "components/FilterSidebar/DurationRangeFilter";
import FilterSidebar from "components/FilterSidebar";
import {jobStatusToReadableName} from "pages/Transcoding/JobHistoryPage/JobStatus";
import {FilterType} from "components/FilterSidebar/useFilter";

function FiltersJobHistory({ filter }) {
  const options = filter.filterSectionProps.addCheckedStatus(
    columnIds.status,
    Object.entries(jobStatusToReadableName).map(([ optionId, statusName ]) => ({
      value: optionId,
      label: statusName,
    }))
    );

  return (
    <FilterSidebar filter={filter}>
      <ProjectsFilterSection
        columnId={columnIds.project}
        title={ columnIdToReadableName[ columnIds.project ] }
        defaultExpanded={ true }
        nothingFoundText="No projects found"
        { ...filter.filterSectionProps }
      />
      <DateRangeFilter
        columnId={ columnIds.dateCreated }
        title={ columnIdToReadableName[columnIds.dateCreated] }
        defaultExpanded={ true }
        { ...filter.filterSectionProps }
      />
      <MultiSelectFilter
        columnId={ columnIds.status }
        title={columnIdToReadableName[ columnIds.status ]}
        options={ options }
        defaultExpanded={ true }
        { ...filter.filterSectionProps }
      />
      <NumberRangeFilter
        placeholder1='Size in MB'
        placeholder2='Size in MB'
        columnId={ columnIds.size }
        title={ columnIdToReadableName[ columnIds.size ] }
        onSubmit={ filter.applyDraftColumnFilters }
        defaultExpanded={ true }
        max={1048576}
        { ...filter.filterSectionProps }
      />
      <DurationRangeFilter
        columnId={ columnIds.videoLength }
        title={ columnIdToReadableName[ columnIds.videoLength ] }
        onSubmit={ filter.applyDraftColumnFilters }
        defaultExpanded={ true }
        max={ 24 * 60 * 60 }
        { ...filter.filterSectionProps }
      />
    </FilterSidebar>
  )
}

FiltersJobHistory.propTypes = {
  filter: PropTypes.shape(FilterType),
};

export default FiltersJobHistory;