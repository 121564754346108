import React, { 
    // useState, 
    //useEffect, 
    // useCallback, 
    //useRef 
} from 'react';


import styles from './index.module.css';

export const InputParametersLoading = () => {


    return (
        <div className={styles.inputWrapper}>
            <div className={styles.inputParamsContainer}>
                <div className={`${styles.block_Loading_60} skeleton-loading`}></div>  
                <div className={`${styles.block_Loading_120} skeleton-loading`}></div>    
                <div className={`${styles.block_Loading_320} skeleton-loading`}></div>   
                <div className={`${styles.block_Loading_420} skeleton-loading`}></div>  
                <div className={`${styles.block_Loading_240} skeleton-loading`}></div>  
                <div className={`${styles.block_Loading_120} skeleton-loading`}></div>  
                <div className={`${styles.block_Loading_60} skeleton-loading`}></div> 
            </div>      
        </div>
    );
};