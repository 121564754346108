import React from 'react';
import PropTypes from 'prop-types';
import style from 'pages/StatisticsPage/components/Card/style.module.css';
import Controls from "components/Controls";


function Card({
  title,
  children,
}) {
  return (
    <div className={style.card}>
      {
        title ? (
          <Controls title={title}/>
        ) : null
      }
      { children }
    </div>
  )
}

Card.propTypes = {
  title: PropTypes.string,
};

export default Card;