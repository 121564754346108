import PropTypes from "prop-types";


export function CircleArrow({ color }) {
  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg width="16"
         height="16"
         viewBox="0 0 16 16"
         fill="none"
         style={style}
         xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd"
            clipRule="evenodd"
            d="M4.47879 6.75C4.72296 6.75 4.9209 6.93316 4.9209 7.1591C4.9209 7.24543 4.89138 7.32955 4.83657 7.39942L3.12506 9.58119C2.98162 9.76404 2.70516 9.80467 2.50756 9.67194C2.47362 9.64914 2.44335 9.62199 2.41768 9.59129L0.592468 7.40952C0.443004 7.23086 0.478361 6.97391 0.671439 6.8356C0.748907 6.78011 0.844099 6.75 0.942066 6.75H4.47879Z"
            fill="currentColor"/>
      <path d="M5.07788 12.8073C6.06153 13.5564 7.2827 14 8.60567 14C11.8612 14 14.5004 11.3137 14.5004 8C14.5004 4.68629 11.8612 2 8.60567 2C5.3501 2 2.71094 4.68629 2.71094 8"
            stroke="currentColor"
            strokeWidth="1.2"
            strokeLinecap="round"
      />
    </svg>
  );
}

CircleArrow.propTypes = {
  color: PropTypes.string,
};
