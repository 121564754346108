import React from 'react';
import PropTypes from 'prop-types';
import style from 'pages/StatisticsPage/components/TopTotal/style.module.css';
import formatNumber from "helpers/formatNumber";

function TopTotal({
  title,
  total,
  loading,
  fixedPrecision,
}) {
  return (
    <div className={style.wrapper}>
      { loading ? <Skeleton/> : <TopTotalInner total={total} fixedPrecision={fixedPrecision}/> }
      <span className={style.title}>{ title }</span>
    </div>
  )
}

function TopTotalInner({ total, fixedPrecision }) {
  let formattedTotal = total;
  if (typeof total === 'number' && Number.isFinite(total)) {
    if (typeof fixedPrecision === 'number' && Number.isFinite(fixedPrecision)) {
      formattedTotal = Number(total).toFixed(2);
    } else {
      formattedTotal = formatNumber(total).toUpperCase();
    }
  } else if (typeof total === 'string') {
    formattedTotal = total;
  }
  else {
    formattedTotal = '—';
  }
  
  return (
    <span className={style.total}>
      {
        formattedTotal
      }
    </span>
  )
}

function Skeleton() {
  return (
    <div className={style.skeleton}></div>
  )
}

TopTotal.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
};

export default TopTotal;