import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import Table from "components/Table";
import style from "./style.module.css";
import Search from "components/Search";
import Region from "components/Region";
import GbReadable from "components/Table/Cells/GbReadable";
import MoneyRounded from "components/Table/Cells/MoneyRounded";
import Controls from "components/Controls";
import getGlobalFilterWithValueMap from "components/Table/columnFilters/getGlobalFilterWithValueMap";
import VerticalGapsLayout from "components/VerticalGapsLayout";
import { ReactComponent as BucketWithCircle } from 'assets/images/BucketWithCircle.svg';
import Pagination from "components/Table/Pagination";
import {Select} from "components/Select";
import {regionCodeToName} from "pages/StatisticsPage/config";

const columnIds = {
  name: 'name',
  bandwidth: 'bandwidth',
  region: 'region',
  cost: 'cost',
};


const globalFilterFn = getGlobalFilterWithValueMap({
  [columnIds.region]: regionCodeToName,
});

function UsageBy({
  stats,
}) {
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([{
    id: columnIds.bandwidth,
    desc: true,
  }]);
  const [selectedRegion, setSelectedRegion] = useState('all');
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const filteredData = useMemo(() => stats.data.filter((item) => {
    if (selectedRegion === 'all') {
      return true;
    }
    
    return selectedRegion === item.region;
  }), [stats.data, selectedRegion]);
  
  const uniqueRegionOptions = useMemo(() => {
    return [
      {
        label: 'All',
        value: 'all',
      },
      ...Array.from( new Set( stats.data.map((i) => i.region) ) ).map((i) => ({
        value: i,
        label: regionCodeToName[ i ],
      })),
    ]
  }, [stats.data]);

  const columns = useMemo(() => [
    {
      id: columnIds.name,
      accessorKey: 'bucket_name',
      header: () => <span>Bucket Name</span>,
      cell: (info) => <span className="oneLineEllipsis">{ info.getValue() }</span>,
    },
    {
      id: columnIds.duration,
      accessorKey: 'region',
      header: () => <span>Region</span>,
      cell: (info) => <Region regionCode={info.getValue()}/>,
      meta: {
        className: style.regionColumn,
      },
    },
    {
      id: columnIds.bandwidth,
      accessorKey: 'storage_gb_month',
      header: () => <span>Gb Stored</span>,
      cell: (info) => <GbReadable value={info.getValue()}/>,
      meta: {
        className: style.bandwidthColumn,
      },
    },
    {
      id: columnIds.cost,
      accessorKey: 'amount',
      header: () => <span>Cost</span>,
      cell: (info) => <MoneyRounded value={info.getValue()} semiBold />,
      meta: {
        className: style.costColumn,
      },
    }
  ], []);
  
  const table = useReactTable({
    data: filteredData,
    columns,
    defaultColumn: {
      cell: (info) => <span>{ info.getValue() || '—' }</span>,
    },
    getCoreRowModel: getCoreRowModel(),
    /* Sorting */
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    /* Filtering */
    globalFilterFn,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      globalFilter,
      pagination,
    },
  });
  
  return (
    <VerticalGapsLayout>
      <Controls
        title="Buckets"
        rightContent={
          <div className={style.searchAndRegion}>
            <Search
              className={style.search}
              setGlobalFilter={setGlobalFilter}
              globalFilter={globalFilter}
            />
            <Select
              value={selectedRegion}
              options={uniqueRegionOptions}
              onChange={( e ) => setSelectedRegion( e?.target?.value )}
            />
          </div>
        }
      />
      <Table
        { ...stats }
        table={table}
        noDataImage={<BucketWithCircle/>}
        noDataTitle="No buckets found"
        noDataDescription="Start by creating a new bucket"
        skeletonRowCount={5}
      />
      <Pagination table={table}/>
    </VerticalGapsLayout>
  );
}

UsageBy.propTypes = {
  stats: PropTypes.object.isRequired,
};

export default UsageBy;