import { codecCodeToReadable } from './videoFormats';

export default function getCodecReadable(format) {
  switch (format?.output) {
    case 'mp4': {
      const videoCodec = format['video_codec'];
      if (typeof videoCodec === 'string' && Object.hasOwn(codecCodeToReadable, videoCodec)) {
        return codecCodeToReadable[videoCodec];
      } else {
        return 'H.264';
      }
    }
    case 'webm': {
      const videoCodec = format['video_codec'];
      if (typeof videoCodec === 'string' && Object.hasOwn(codecCodeToReadable, videoCodec)) {
        return codecCodeToReadable[videoCodec];
      } else {
        return codecCodeToReadable.vp9;
      }
    }
    case 'advanced_hls':
    case 'advanced_dash': {
      const { stream } = format;
      if (Array.isArray(stream) && stream.length > 0) {
        const videoCodec = stream[0]['video_codec'];
        if (typeof videoCodec === 'string' && Object.hasOwn(codecCodeToReadable, videoCodec)) {
          return codecCodeToReadable[videoCodec];
        }
      }
      
      return 'H.264';
    }
    case 'webm-dash': {
      const { stream } = format;
      if (Array.isArray(stream) && stream.length > 0) {
        const videoCodec = stream[0]['video_codec'];
        if (typeof videoCodec === 'string' && Object.hasOwn(codecCodeToReadable, videoCodec)) {
          return codecCodeToReadable[videoCodec];
        }
      }
      
      return codecCodeToReadable.vp9;
    }
    case 'mp3':
      return codecCodeToReadable.mp3;
    case 'hls_audio':
      const audioCodec = format['audio_codec']
      if (typeof audioCodec === 'string' && Object.hasOwn(codecCodeToReadable, audioCodec)) {
        return codecCodeToReadable[audioCodec];
      } else {
        return codecCodeToReadable.aac
      }
    case 'gif':
      const framerate = format.framerate;
      if (typeof framerate === 'string') {
        return `${ framerate }fps`;
      } else {
        return '5fps';
      }
    case 'thumbnail':
    case 'thumbnails':
      const imageFormat = format['image_format'];
      if (typeof imageFormat === 'string') {
        return imageFormat.toUpperCase();
      } else {
        return 'PNG';
      }
    case 'metadata':
      return '-';
    case 'speech_to_text':
      const language = format.language;
      if (typeof language === 'string') {
        return language.toUpperCase();
      } else {
        return 'Auto';
      }
    default:
      return '';
  }
}
