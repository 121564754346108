import React, {useState} from 'react';
import PropTypes from 'prop-types';
import ValueRange from "components/FilterSidebar/ValueRange";
import FilterSection from "components/FilterSidebar/FilterSection";
import {durationStringToSeconds} from "helpers/duration";
import useValueRange from "components/FilterSidebar/useValueRange";
import {clearTypes, FilterSectionProps} from "components/FilterSidebar/useFilter";


function DurationRangeFilter({
   columnId,
   title,
   columnToFilterExpanded,
   onSubmit,
   clearFilter,
   setNewDraft,
   defaultExpanded,
   getFilterValue,
   lastClearType,
   lastClearFilterId,
   applyFilterCount,
   columnToError,
   clearError,
   max,
}) {
  const [invalid, setInvalid] = useState(false);
  const [touched, setTouched] = useState(0);
  const filterValue = getFilterValue(columnId);
  const {
    startRef,
    endRef,
  } = useValueRange(columnId, lastClearFilterId, lastClearType);
  const expandedFromActiveFilterTime = columnToFilterExpanded[columnId];
  const visibleInvalid = (columnToError[columnId] || invalid) && applyFilterCount > touched;
  const commonProps = {
    placeholder: '00:00:00',
    mask: '99:99:99',
    maskChar: '',
    inputMode: 'numeric',
    onSubmit,
    onChange,
    'aria-invalid': visibleInvalid,
  };

  function onChange() {
    setTouched(applyFilterCount);
    const start = startRef.current?.getInputValue();
    const end = endRef.current?.getInputValue();
    
    const startSet = start?.length === 8;
    const endSet = end?.length === 8;
    setInvalid(false);
    clearError(columnId);
    
    if (startSet && endSet) {
      let startSeconds = durationStringToSeconds(start);
      let endSeconds = durationStringToSeconds(end);
      if (max) {
        startSeconds = startSeconds > max ? max : startSeconds;
        endSeconds = endSeconds > max ? max : endSeconds;
      }
      
      if (end >= start) {
        setNewDraft(columnId, [startSeconds, endSeconds]);
      } else {
        clearFilter(columnId, clearTypes.clearDraftFilter);
        setInvalid(true);
      }
    } else {
      clearFilter(columnId, clearTypes.clearDraftFilter);
    }
  }

  return (
    <FilterSection
      title={ title }
      expandedFromActiveFilterTime={expandedFromActiveFilterTime}
      handleClear={() => clearFilter(columnId)}
      selectedCount={ filterValue.length > 0 ? 1 : 0 }
      defaultExpanded={defaultExpanded}
      error={columnToError[columnId]}
    >
    <ValueRange
      commonProps={commonProps}
      startProps={{
        ref: startRef,
      }}
      endProps={{
        ref: endRef,
      }}
    />
    </FilterSection>
  )
}

DurationRangeFilter.propTypes = {
  columnId: PropTypes.string,
  title: PropTypes.string,
  defaultExpanded: PropTypes.bool,
  max: PropTypes.number,
  ...FilterSectionProps,
};

export default DurationRangeFilter;