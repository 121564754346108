import React from 'react';

import { Button } from './Button';

import { getOauth2Url } from '../api/qencode-auth-api'

import { Google, Github } from '../icons'

import styles from './Oauth2.module.css';

const Oauth2 = () => {

    const handleOauth2Login = (provider) => {
        const redirectUrl = getOauth2Url({provider})
        //window.location.assign(redirectUrl);
        window.open(redirectUrl, '_self');
    };    

    return (
        <div className={styles.authButtonsContainer}>
            <div className={styles.authButton}>
                <Button onClick={() => handleOauth2Login('google')} icon={<Google />} type='secondary' size='lg' fullWidth={true}>Google</Button>  
            </div>
            <div className={styles.authButton}>
                <Button onClick={() => handleOauth2Login('github')} icon={<Github />} type='secondary' size='lg' fullWidth={true}>Github</Button>   
            </div>
        </div>
    );
};

export default Oauth2;