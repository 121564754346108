import React from 'react';
import PropTypes from 'prop-types';

export function Calculator({ className, color, label }) {
  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;
  
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      className={className}
      role="img"
      aria-label={label}
      style={style}
    >
      <path
        fillRule="evenodd"
        d="M12.9 5a2.4 2.4 0 0 0-2.4-2.4h-5A2.4 2.4 0 0 0 3.1 5v6a2.4 2.4 0 0 0 2.4 2.4h5a2.4 2.4 0 0 0 2.4-2.4V5zm-2.4-3.6A3.6 3.6 0 0 1 14.1 5v6a3.6 3.6 0 0 1-3.6 3.6h-5A3.6 3.6 0 0 1 1.9 11V5a3.6 3.6 0 0 1 3.6-3.6h5zM12 4.7v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1zM5.2 9.5a1 1 0 1 1 0-2 1 1 0 1 1 0 2zm-1 1.7a.9.9 0 0 0 1 .9H8a.9.9 0 1 0 0-1.8H5a.9.9 0 0 0-.8.9zM8 9.5a1 1 0 1 1 0-2 1 1 0 1 1 0 2zm1.8-1a1 1 0 1 0 2 0 1 1 0 1 0-2 0zm1 3.7a1 1 0 1 1 0-2 1 1 0 1 1 0 2z"
        fill="currentColor"
      />
    </svg>
  );
}

Calculator.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Calculator.defaultProps = {
  color: 'currentColor',
}

export default Calculator;