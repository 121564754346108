import {useEffect, useState} from 'react';

export default function useCopyToClipboard(text) {
  const [copied, setCopied] = useState(false);
  
  async function copy() {
    await navigator.clipboard.writeText(text);
    setCopied(true);
  }
  
  useEffect(() => {
    let timeout;
    if (copied) {
      timeout = setTimeout(() => {
        setCopied(false);
      }, 1000);
    }
    
    return () => {
      clearTimeout(timeout);
    };
  }, [copied]);
  
  return {
    copied,
    copy,
  };
}