import React from 'react';
import PropTypes from 'prop-types';
import style from "pages/StatisticsPage/components/Chart/Tooltip/style.module.css";
import {transcodingTypeToColor} from "pages/StatisticsPage/config";
import classNames from "classnames";
import MoneyRounded from "components/Table/Cells/MoneyRounded";

function StreamsTooltipContent(props) {
  const {
    data,
    indexValue,
    quantity,
    keys,
  } = props;
  const totalAmount = keys.reduce((sum, id) => sum + (quantity[indexValue][id] || 0), 0);
  const totalQuantity = keys.reduce((sum, id) => sum + (data[id] || 0), 0);

  return (
    <table className="standardTable">
      <thead>
        <tr>
          <th>Type</th>
          <th>Quantity</th>
          <th className={style.bandwidthColumn}>Cost</th>
        </tr>
      </thead>
      <tbody>
      { keys
          .filter((id) => data[id] > 1e-6)
          .map((id) => {

        return (
          <tr key={id}>
            <td className={ classNames(style.cell, style.mainCell) }>
              <div className={style.colorSample} style={{ 'backgroundColor': transcodingTypeToColor[id] }}></div>
              <span>
                { id }
              </span>
            </td>
            <td className={style.cell}>
              { Number(data[id] || 0).toFixed(2) }
            </td>
            <td className={style.cell}>
              <MoneyRounded value={quantity[indexValue][id]}/>
            </td>
          </tr>
        )
      }) }
      </tbody>
      <tfoot>
        <tr>
          <td className={ classNames(style.cell, style.mainCell) }>Total</td>
          <td className={classNames(style.cell, style.quantityTotal)}>
            { Number(totalQuantity).toFixed(2) }
          </td>
          <td className={ classNames(style.cell, style.total) }>
            <MoneyRounded value={totalAmount}/>
          </td>
        </tr>
      </tfoot>
    </table>
  )
}

StreamsTooltipContent.propTypes = {
  keys: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.object,
  indexValue: PropTypes.string.isRequired,
  quantity: PropTypes.object.isRequired,
};

export default StreamsTooltipContent;