import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.css';

function Columns({
  count,
  minWidth,
  children,
}) {
  if (typeof count === 'number' && Number.isFinite(count)) {
    return (
      <div className={style.fixedColumnWrapper} style={{ '--column-count': count }}>
        { children }
      </div>
    );
  }
  if (typeof minWidth === 'number' && Number.isFinite(minWidth)) {
    return (
      <div className={style.flexibleColumnWrapper} style={{ '--min-width': `${ minWidth }px` }}>
        { children }
      </div>
    );
  }
  
  return (
    <div className={style.fixedColumnWrapper} style={{'--column-count': children.length}}>
      {children}
    </div>
  );
}

Columns.propTypes = {
  count: PropTypes.number,
  minWidth: PropTypes.number,
};

export default Columns;