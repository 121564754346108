// EXPERIMENTAL 

import { useCallback } from 'react';
import { useAuth } from '../context/AuthContext';

import config from 'config';

const { protocol, apiVersion, accountApiSubdomain, domain } = config;

// The base URL for API
const apiBaseUrl = `${protocol}://${accountApiSubdomain}.${domain}/${apiVersion}`;

// const protocol = 'https';
// const endpoint = 'account-api-qa.qencode.com';
// const api_version = 'v1';

// // The base URL for API
// const apiBaseUrl = `${protocol}://${endpoint}/${api_version}`;

// A utility function to make fetch calls with the access token
async function fetchWithToken(url, token, options = {}) {
    try {
        const response = await fetch(`${apiBaseUrl}/${url}`, {
            ...options,
            headers: {
                ...options.headers,
                Authorization: `Bearer ${token}`,
            },
        });

        const responseBody = await response.json(); // Parse the JSON body upfront for any type of response

        if (!response.ok) {
            // Convert detail if it's an array of objects
            const detailMessage = Array.isArray(responseBody.detail)
                ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
                : responseBody.detail || `HTTP error: ${response.status} ${response.statusText}`;

            return {
                success: false,
                error: responseBody.error || 'unknown_error',
                detail: detailMessage,
            };
        }

        if (responseBody.error && responseBody.error !== 0) {
            // Assume responseBody.detail is well-formed here; include check if necessary
            const detailMessage = Array.isArray(responseBody.detail)
                ? responseBody.detail.map(d => `${d.field_name}: ${d.error}`).join(', ')
                : responseBody.detail || 'An error occurred';

            return {
                success: false,
                error: responseBody.error,
                detail: detailMessage,
            };
        }

        // If the response is successful and contains no server-reported error code
        return { success: true, data: responseBody.data, detail: responseBody.detail };

    } catch (error) {
        return {
            success: false,
            error: 1, // Using '1' as a generic error code for exceptions
            detail: error.message || 'Failed to complete request due to an unexpected error',
        };
    }
}

// Your custom hook that uses the `useAuth` hook to get the access token
export function useApi() {
  const { getToken } = useAuth();

  // handle Account in Settings

  const deleteUser = useCallback(async () => {
    const token = await getToken();
    return fetchWithToken(`user`, token, {
      method: 'DELETE',
    });
  }, [getToken]);   

  // handle Organization in Settings

  const getOrganizationData = useCallback(async () => {
    const token = await getToken();
    return fetchWithToken('organization', token);
  }, [getToken]);

  const updateOrganizationData = useCallback(async (data) => {
    const token = await getToken();
    return fetchWithToken('organization', token, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
  }, [getToken]);


  // handle Team in Settings

  const getTeamData = useCallback(async () => {
    const token = await getToken();
    return fetchWithToken('organization/team', token);
  }, [getToken]);

  const updateTeam = useCallback(async (data) => {
    const token = await getToken();
    return fetchWithToken('organization/team', token, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
  }, [getToken]);  

  const addTeamMember = useCallback(async (data) => {
    const token = await getToken();
    return fetchWithToken('organization/team', token, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
  }, [getToken]); 

  const removeTeamMember = useCallback(async (data) => {
    const {uuid} = data
    const token = await getToken();
    return fetchWithToken(`organization/team/${uuid}`, token, {
      method: 'DELETE',
    });
  }, [getToken]); 

  const transferOrganization = useCallback(async (data) => {
    const {id} = data
    const token = await getToken();
    return fetchWithToken(`organization/transfer/${id}`, token, {
      method: 'PUT',
    });
  }, [getToken]); 

  // Transcoding Page aka Request Builder

  const getProjectsData = useCallback(async (data) => {
    const {type} = data
    const token = await getToken();
    return fetchWithToken(`project/${type}`, token);
  }, [getToken]); 

  const getTemplatesData = useCallback(async () => {
    const token = await getToken();
    return fetchWithToken(`transcoding/template`, token);
  }, [getToken]); 

  const getBucketsListData = useCallback(async () => {
    const token = await getToken();
    return fetchWithToken(`storage/bucket`, token);
  }, [getToken]); 

  const createBucket = useCallback(async (data) => {
    const token = await getToken();
    return fetchWithToken('storage/bucket', token, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
  }, [getToken]); 

  const addTemplate = useCallback(async (data) => {
    const token = await getToken();
    return fetchWithToken('transcoding/template', token, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
  }, [getToken]); 

  const updateTemplate = useCallback(async (id, data) => {
    const token = await getToken();
    return fetchWithToken(`transcoding/template/${id}`, token, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
  }, [getToken]);   

  const resubmitTask = useCallback(async (task_token) => {
    const token = await getToken();
    return fetchWithToken(`transcoding/task/${task_token}/resubmit`, token, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    });
  }, [getToken]);     

  const getBucketInfo = useCallback(async (region, bucketName) => {
      const token = await getToken();
      return fetchWithToken(`storage/bucket/${region}/${bucketName}`, token);
  }, [getToken]);

  return {
    // settinds account
    deleteUser,
    // settings organization
    getOrganizationData,
    updateOrganizationData,
    // settings team
    getTeamData,
    updateTeam,
    addTeamMember,
    removeTeamMember,
    transferOrganization,
    
    // transcoding builder
    getProjectsData,
    getTemplatesData,
    getBucketsListData,
    createBucket,
    addTemplate,
    updateTemplate,

    // transcoding job
    resubmitTask,

    // media storage
    getBucketInfo,

  };
}

