import PropTypes from "prop-types";
import React from "react";

export const Check2 = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2ZM11.6254 5.74727C11.854 5.40185 11.7593 4.93652 11.4139 4.70792C11.0685 4.47933 10.6031 4.57405 10.3746 4.91947L7.30077 9.56429L5.4719 8.08377C5.14995 7.82315 4.67769 7.87286 4.41707 8.19481C4.15644 8.51675 4.20616 8.98902 4.5281 9.24964L6.99869 11.2496C7.163 11.3827 7.37583 11.4402 7.58477 11.408C7.79371 11.3758 7.97937 11.2569 8.09604 11.0806L11.6254 5.74727Z" fill="currentColor"/>
    </svg>    
  );
};

Check2.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Check2.defaultProps = {
  color: 'currentColor', // Or any default color
};