import React from 'react';
import PropTypes from 'prop-types';

export function PlaybackId({ className, color, label }) {
    // Apply inline style only if color prop is not currentColor
    const style = color !== 'currentColor' ? { color } : undefined;

    return (
        <svg
            className={className}
            role="img"
            aria-label={label}
            width="16"
            height="16"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
            viewBox="388.37 609.4 13.27 13.25">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M398.23 611.143c.76 0 1.499.245 2.108.699a2.442 2.442 0 0 0-2.442-2.442h-5.794a2.442 2.442 0 0 0-2.442 2.442 3.537 3.537 0 0 1 2.111-.699h6.459Zm-.23 1.979a2.4 2.4 0 0 1 2.4 2.4v3.5a2.4 2.4 0 0 1-2.4 2.4h-6a2.4 2.4 0 0 1-2.4-2.4v-3.5a2.4 2.4 0 0 1 2.4-2.4h6Zm3.6 2.4a3.6 3.6 0 0 0-3.6-3.6h-6a3.6 3.6 0 0 0-3.6 3.6v3.5a3.6 3.6 0 0 0 3.6 3.6h6a3.6 3.6 0 0 0 3.6-3.6v-3.5Z"
                fill="currentColor"
            />
            <path
                d="m393.232 615.687-.182 1.113.868-.71.449.777-1.049.403 1.049.403-.449.778-.868-.72.182 1.123h-.897l.181-1.123-.868.72-.448-.778 1.049-.403-1.049-.403.448-.777.868.71-.181-1.113h.897ZM397.665 615.687l-.181 1.113.868-.71.448.777-1.049.403 1.049.403-.448.778-.868-.72.181 1.123h-.897l.182-1.123-.868.72-.449-.778 1.05-.403-1.05-.403.449-.777.868.71-.182-1.113h.897Z"
                fill="currentColor"/>
        </svg>
    )
}

PlaybackId.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.string,
};

PlaybackId.defaultProps = {
    color: 'currentColor', // Or any default color
};
