import {Rename} from "icons/Rename";
import {Delete} from "icons";
import {OptionsMenu} from "components/OptionsMenu";
import React from "react";
import PropTypes from "prop-types";
import {APIRegenerate} from "icons/APIRegenerate";

export const rowModals = {
  'deletion': 'deletion',
  'rename': 'rename',
  'recreateApiKey': 'recreateApiKey',
  'create': 'create',
};


function MoreRowActions({
    projectId,
    handleOpenModal,
  }) {
  return (
    <div className="cellContainer">
      <OptionsMenu
        menuItems={
          [
            {
              icon: <Rename label="Rename"/>,
              text: 'Rename',
              onClick: () => handleOpenModal( rowModals.rename, projectId ),
            },
            {
              icon: <APIRegenerate label="Regenerate"/>,
              text: 'Recreate API Key',
              onClick: () => handleOpenModal( rowModals.recreateApiKey, projectId ),
            },
            {
              icon: <Delete label="Remove" />,
              onClick: () => handleOpenModal( rowModals.deletion, projectId ),
              text: "Delete",
              danger: true,
            }
          ]
        }
      />
    </div>
  )
}

MoreRowActions.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
};

export default MoreRowActions;