import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Modal} from "components/Modal";
import {Button} from "components/Button";
import {Select} from "components/Select";
import {regionCodeToNameOptions} from "pages/MediaStorage/BucketsPage/config";
import {InputField} from "components/InputField";
import style from './style.module.css';
import {useAuth} from "context/AuthContext";
import {createBucket} from "api/qencode-account-api";

function CreateBucketModal({ open, handleClose, onSuccessfulFinish }) {
  const { getToken } = useAuth();
  const [region, setRegion] = useState();
  const [name, setName] = useState('');
  const [regionError, setRegionError] = useState();
  const [nameError, setNameError] = useState();
  const [errorsVisible, setErrorsVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  
  function handleRegionChange(e) {
    setRegion(e.target.value);
    setRegionError();
  }
  
  function handleNameChange(e) {
    const name = e.target.value.trim();
    setErrorsVisible(false);
    setName(name);
    setNameError();
  }
  
  function checkErrors() {
    setErrorsVisible(true);
  }
  
  function clearFormAndClose() {
    handleClose();
    setRegion();
    setName('');
    setRegionError();
    setNameError();
    setErrorsVisible(false);
  }
  
  async function handleConfirmSubmit(e) {
    e.preventDefault();
    setErrorsVisible(true);
    
    if (!region) {
      setRegionError('Please choose region.');
      return;
    }
    
    if (!invalid) {
      const accessToken = await getToken();
      setSubmitting(true);
      const result = await createBucket({
        accessToken,
        region,
        name,
      });
      setSubmitting(false);
      if (result.success) {
        clearFormAndClose();
        onSuccessfulFinish?.(result.data);
      } else if (typeof result.error === "string") {
        setNameError(result.error);
      }
    }
  }
  
  const hasSpaces = !!name.includes(' ');
  const hasUppercase = /[^a-z0-9-]+/.test(name); // Only lower-case characters, numbers, and dashes
  const invalidLength = (name.length < 6 || name.length > 63);
  let invalidStartAndEnd = false
  if (/[^a-z0-9]/.test(name[0]) || /[^a-z0-9]/.test(name[name.length - 1])) {
    invalidStartAndEnd = true
  }
  const invalid = hasSpaces || hasUppercase || invalidLength || invalidStartAndEnd;

  return (
    <Modal
      header={
        <h3>
          Create Bucket
        </h3>
      }
      onClose={clearFormAndClose}
      isOpen={open}
      footer={
        <>
          <Button
            onClick={clearFormAndClose}
            type="secondary"
            variant="modalFooter"
          >
            Cancel
          </Button>
          <Button
            variant="modalFooter"
            onClick={handleConfirmSubmit}
            state={ submitting ? "disabled" : "default" }
          >
            Create
          </Button>
        </>
      }
    >
      <form className="form" onSubmit={handleConfirmSubmit}>
        <div className="row">
          <Select
            label="Region"
            options={regionCodeToNameOptions}
            value={region}
            name="region"
            onChange={handleRegionChange}
            state={ (regionError) ? 'error' : 'default' }
            hint={ regionError }
          />
        </div>
        <div className="row">
          <InputField
            label="Bucket Name"
            name="bucket-name"
            state={ nameError ? 'error' : 'default' }
            hint={ nameError }
            onChange={handleNameChange}
            inputText={name}
            onBlur={checkErrors}
          />
        </div>
        <ul className={style.list}>
          <li className={(errorsVisible && invalidStartAndEnd) ? style.error : ''}>
            Must start and end with a lowercase letter or number.
          </li>
          <li className={(errorsVisible && hasUppercase) ? style.error : ''}>
            Only Latin lower-case characters, number, and dashes.
          </li>
          <li className={(errorsVisible && hasSpaces) ? style.error : ''}>
            No space.
          </li>
          <li className={(errorsVisible && invalidLength) ? style.error : ''}>
            Use from 6 to 63 characters.
          </li>
        </ul>
      </form>
    </Modal>
  )
}

CreateBucketModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSuccessfulFinish: PropTypes.func,
};

export default CreateBucketModal;