import {Select} from "components/Select";
import style from './style.module.css';
import {Button} from "components/Button";
import {ChevronRight} from "icons/ChevronRight";
import {VerticalDots} from "icons";

const pageSizes = [
  '10',
  '15',
  '20',
  '25',
  '30',
  '40',
  '50',
];

function isPaginationVisible( table ) {
  if (table.options.manualPagination) {
    return table.getPageCount() > 1;
  }

  const minRowsForPagination = table.options.state.pagination.pageSize || 10;
  const { globalFilter, columnFilters } = table.getState();
  const filteredRowCount = table.getRowCount();
  const totalRowCount = table.options.data.length;

  if (globalFilter || columnFilters?.length || table.getIsSomeRowsExpanded()) {
    return filteredRowCount > minRowsForPagination;
  } else {
    return totalRowCount > minRowsForPagination;
  }
}

function Pagination({
  table,
}) {
  const { pagination: { pageIndex } } = table.getState();

  if ( !isPaginationVisible( table ) ) {
    return;
  }
  
  const lastPageIndex = table.getPageCount() - 1;
  const canPreviousPage = pageIndex > 0;
  const canNextPage = pageIndex !== lastPageIndex;
  const hasSkippedPagesBefore = table.getCanPreviousPage() && pageIndex - 1 !== 0;
  const hasSkippedPagesAhead = canNextPage && pageIndex + 1 !== lastPageIndex;


  return (
    <div className={ style.wrapper }>
      <div className={ style.pageSize }>
        <Select
          className={ style.customSelect }
          options={pageSizes.map((size) => ({
            label: size,
            value: size,
          }))}
          value={ String(table.getState().pagination.pageSize) }
          onChange={e => {
            table.setPageSize(Number(e.target.value))
          }}
        >
        </Select>
        <label className={style.sizeSelectorLabel}>
          Rows per page
        </label>
      </div>

      <div className={ style.paginationButtons }>
        <Button
          type="secondary"
          className={ style.chevronLeft }
          icon={<ChevronRight />}
          onClick={() => table.previousPage()}
          state={ canPreviousPage ? 'default' : 'disabled' }
        >
        </Button>
        { pageIndex > 0 && (
          <Button
            type="secondary"
            className={style.neighbor}
            onClick={ () => table.firstPage() }
          >
            { 1 }
          </Button>
        ) }
        <CurrentPage
          table={table}
          lastPageIndex={lastPageIndex}
          pageIndex={ pageIndex }
          hasSkippedPagesBefore={hasSkippedPagesBefore}
          hasSkippedPagesAhead={hasSkippedPagesAhead}
        />
        { (canNextPage) && (
          <Button
            type="secondary"
            className={style.neighbor}
            onClick={ () => table.lastPage() }
          >
            { lastPageIndex + 1 }
          </Button>
        ) }
        <Button
          type="secondary"
          className={style.chevronRight}
          icon={<ChevronRight/>}
          onClick={() => table.nextPage()}
          state={ canNextPage ? 'default' : 'disabled' }
        >
        </Button>
      </div>
    </div>
  );
}

function CurrentPage({
  table,
  lastPageIndex,
  pageIndex,
  hasSkippedPagesBefore,
  hasSkippedPagesAhead,
}) {
  let before, after;

  if (hasSkippedPagesBefore) {
    if (pageIndex === 2) {
      before = (
        <Button
          type="secondary"
          className={style.neighbor}
          onClick={ () => table.previousPage() }
        >
          { 2 }
        </Button>
      )
    } else {
      before = <InBetweenPages visible={true}/>;
    }
  }

  if (hasSkippedPagesAhead) {
    const nextPage = pageIndex + 2;
    if ( (lastPageIndex - pageIndex) === 2) {
      after = (
        <Button
          type="secondary"
          className={style.neighbor}
          onClick={ () => table.nextPage() }
        >
          { nextPage }
        </Button>
      )
    } else {
      after = <InBetweenPages visible={true}/>;
    }
  }

  return (
    <>
      { before }
      <Button className={style.current}>
        { pageIndex + 1 }
      </Button>
      { after }
    </>
  );
}

function InBetweenPages({ visible }) {
  if (visible) {
    return (
      <button
        type="secondary"
        className={style.inBetweenPage}
      >
        <VerticalDots className={style.dots}/>
      </button>
    );
  }
}

export default Pagination;