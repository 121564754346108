import React from 'react';
import PropTypes from 'prop-types';
import styles from './Dropdown.module.css'; // Ensure you have a corresponding CSS module file for Label

import { ListItem } from '../ListItem';

export const Dropdown = ({ className, options, onOptionClick, size, selectedOption, icon, wrappedIcon, extraDataDelimeter }) => {
    return (
        <div className={`${styles.container} ${className || ''}`}>
            {options.map((option, index) => (
                <ListItem 
                    text={option.label} 
                    //key={option.value}
                    key={`${option.value}-${index}`} // Ensure unique key by combining value and index
                    onClick={() => onOptionClick(option.value)}
                    size={size}
                    icon={option.icon || icon} // if there is no icon in option check if there is icon passed via params
                    danger={option.danger}
                    wrappedIcon={wrappedIcon}
                    state={selectedOption?.value === option?.value ? "active" : null}
                    checked={option.checked}
                    extraData={option.extraData}
                    extraDataDelimeter={extraDataDelimeter}
                />
            ))}
        </div>
    );
};

Dropdown.propTypes = {
    className: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    selectedOption: PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    }),
    size: PropTypes.string,
    onOptionClick: PropTypes.func.isRequired
};

Dropdown.defaultProps = {
    className: '',
    options: [],
    selectedOption: null,
    size: 'md',
    onOptionClick: () => {} // Default no-op function
};


