import React from 'react';
import PropTypes from 'prop-types';
import bytesToReadable from "helpers/bytesToReadable";

export function gigabytesToReadable(value) {
  return bytesToReadable( parseFloat( value ) * Math.pow(1024, 3) );
}

function GbReadable({ value }) {
  return (
    <span>{ gigabytesToReadable(value) }</span>
  );
}

GbReadable.propTypes = {
  value: PropTypes.number.isRequired,
};

export default GbReadable;