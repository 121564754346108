import PropTypes from "prop-types";
import React from "react";

export const All = ({ className, color, label }) => {
  
  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;
  
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      className={className}
      role="img"
      aria-label={label}
      style={style}
    >
      <path fillRule="evenodd"
            d="M2.1 5c0-1.28 1.14-2.4 2.65-2.4h6.5c1.51 0 2.65 1.12 2.65 2.4v6c0 1.28-1.14 2.4-2.65 2.4h-6.5c-1.51 0-2.65-1.12-2.65-2.4V5zm2.65-3.6C2.67 1.4.9 2.97.9 5v6c0 2.03 1.77 3.6 3.85 3.6h6.5c2.08 0 3.85-1.57 3.85-3.6V5c0-2.03-1.77-3.6-3.85-3.6h-6.5zm.54 7.54-.35.94a.47.47 0 0 1-.28.26.58.58 0 0 1-.4.02.49.49 0 0 1-.3-.28.52.52 0 0 1-.03-.4L5.4 5.5a.72.72 0 0 1 .74-.5h.04a.72.72 0 0 1 .74.5L8.4 9.48a.52.52 0 0 1-.03.4.49.49 0 0 1-.3.28.58.58 0 0 1-.4-.02.47.47 0 0 1-.28-.26l-.35-.94H5.29zM6.67 8l-.52-1.78L5.64 8h1.04zm3.28 1.73V5.47c0-.13-.06-.24-.16-.33S9.58 5 9.44 5s-.26.04-.37.14a.44.44 0 0 0-.15.31v4.27c0 .12.05.23.15.32a.54.54 0 0 0 .37.14c.14 0 .26-.05.36-.14s.16-.2.16-.32zm1.94 0V5.47c0-.12-.05-.23-.16-.32S11.52 5 11.38 5s-.26.05-.37.14a.44.44 0 0 0-.15.32v4.26c0 .12.06.23.15.32a.54.54 0 0 0 .37.14c.14 0 .26-.05.36-.14s.16-.2.16-.32z"
            fill="currentColor"/>
    </svg>
  );
};

All.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

All.defaultProps = {
  color: 'currentColor', // Or any default color
};