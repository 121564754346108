import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.css';

export const Button = forwardRef(function ButtonComponent(
  { icon, trailingIcon, variant, size = 'md', type = 'primary', children, state = 'default', fullWidth = false, className, onClick },
  ref
) {
  const variantClassName = styles[variant];

  const getIconComponent = (icon) => {
    return React.cloneElement(icon, { className: `${styles.icon} ${icon?.props?.className || ''}` });
  };

  const getTrailingIconComponent = (icon) => {
    return React.cloneElement(icon, { className: `${styles.trailingIcon} ${icon?.props?.className || ''}` });
  };

  // const getIconComponent = (icon) => {
  //   return React.cloneElement(icon, { className: styles.icon });
  // };

  // const getTrailingIconComponent = (icon) => {
  //   return React.cloneElement(icon, { className: styles.trailingIcon });
  // };

  return (
    <button
      ref={ref}
      className={`${styles.button} ${styles[size]} ${styles[type]} ${styles[state]} ${icon ? styles.hasIcon : ''} ${trailingIcon ? styles.hasTrailingIcon : ''}  ${icon && !children ? styles.iconOnly : ''} ${fullWidth ? styles.fullWidth : ''} ${className || ''} ${variantClassName || ''}`}
      onClick={onClick}
    >
      {icon && getIconComponent(icon)}
      {children && <div className={styles.text}>{children}</div>}
      {trailingIcon && getTrailingIconComponent(trailingIcon)}
    </button>
  );
});

Button.displayName = 'Button';

Button.propTypes = {
  type: PropTypes.oneOf(['primary', 'danger', 'secondary']),
  state: PropTypes.oneOf(['default', 'hover', 'disabled']),
  icon: PropTypes.element,
  trailingIcon: PropTypes.element,
  size: PropTypes.oneOf(['md', 'lg', 'sm']),
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func
};

Button.defaultProps = {
  size: 'md',
  type: 'primary',
  state: 'default',
  icon: null,
  trailingIcon: null,
  fullWidth: false,
  className: '',
  onClick: () => {}
};




// with refs

// import React, { forwardRef } from 'react';
// import PropTypes from 'prop-types';
// import styles from './Button.module.css';

// export const Button = forwardRef(({ icon, trailingIcon, variant, size = 'md', type = 'primary', children, state = 'default', fullWidth = false, className, onClick }, ref) => {

//   const variantClassName = styles[variant];

//   const getIconComponent = (icon) => {
//     return React.cloneElement(icon, { className: styles.icon });
//   };

//   const getTrailingIconComponent = (icon) => {
//     return React.cloneElement(icon, { className: styles.trailingIcon });
//   };

//   return (
//     <button 
//       ref={ref}
//       className={`${styles.button} ${styles[size]} ${styles[type]} ${styles[state]} ${icon ? styles.hasIcon : ''} ${trailingIcon ? styles.hasTrailingIcon : ''}  ${icon && !children ? styles.iconOnly : ''} ${fullWidth ? styles.fullWidth : ''} ${className || ''} ${variantClassName || ''}`}
//       onClick={onClick}
//     >
//       { icon && getIconComponent(icon) }
//       { children && <div className={styles.text}>{children}</div> }
//       { trailingIcon && getTrailingIconComponent(trailingIcon) }
//     </button>
//   );
// });

// Button.propTypes = {
//   type: PropTypes.oneOf(["primary", "danger", "secondary"]),
//   state: PropTypes.oneOf(["default", "hover", "disabled"]),
//   icon: PropTypes.element,
//   trailingIcon: PropTypes.element,
//   size: PropTypes.oneOf(["md", "lg", "sm"]),
//   fullWidth: PropTypes.bool,
//   className: PropTypes.string,
//   onClick: PropTypes.func
// };

// Button.defaultProps = {
//   size: "md",
//   type: "primary",
//   state: "default",
//   icon: null,
//   trailingIcon: null,
//   fullWidth: false,
//   className: '',
//   onClick: () => {}
// };




// original

// import React from 'react';
// import PropTypes from 'prop-types';
// import styles from './Button.module.css';

// export const Button = ({ icon, trailingIcon, variant, size = 'md', type = 'primary', children, state = 'default', fullWidth = false, className, onClick }) => {

//   const variantClassName = styles[variant];

//   const getIconComponent = (icon) => {
//     return React.cloneElement(icon, { className: styles.icon });
//   };

//   const getTrailingIconComponent = (icon) => {
//     return React.cloneElement(icon, { className: styles.trailingIcon });
//   };

//   return (
//     <button 
//         className={`${styles.button} ${styles[size]} ${styles[type]} ${styles[state]} ${icon ? styles.hasIcon : ''} ${trailingIcon ? styles.hasTrailingIcon : ''}  ${icon && !children ? styles.iconOnly : ''} ${fullWidth ? styles.fullWidth : ''} ${className || ''} ${variantClassName || ''}`}
//       onClick={onClick}
//     >
//         { icon && getIconComponent(icon)}
//         { children && <div className={styles.text}>{children}</div>}
//         { trailingIcon && getTrailingIconComponent(trailingIcon)}
//     </button>
//   );
// };

// Button.propTypes = {
//   type: PropTypes.oneOf(["primary", "danger", "secondary"]),
//   state: PropTypes.oneOf(["default", "hover", "disabled"]),
//   icon: PropTypes.element,
//   trailingIcon: PropTypes.element,
//   size: PropTypes.oneOf(["md", "lg", "sm"]),
//   fullWidth: PropTypes.bool,
//   className: PropTypes.string,
//   onClick: PropTypes.func
// };

// Button.defaultProps = {
//   size: "md",
//   type: "primary",
//   state: "default",
//   icon: null,
//   trailingIcon: null,
//   fullWidth: false,
//   className: '',
//   onClick: () => {}
// };