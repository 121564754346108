import PropTypes from "prop-types";
import React from "react";

export const Support = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M7.9999 2.90001C6.56188 2.90001 5.18633 3.45413 4.17495 4.43486C3.1642 5.41498 2.5999 6.74031 2.5999 8.11819V11.3C2.5999 11.6314 2.33127 11.9 1.9999 11.9C1.66853 11.9 1.3999 11.6314 1.3999 11.3V8.11819C1.3999 6.40992 2.09989 4.7755 3.33957 3.57338C4.57863 2.37187 6.25533 1.70001 7.9999 1.70001C9.74448 1.70001 11.4212 2.37187 12.6602 3.57338C13.8999 4.7755 14.5999 6.40992 14.5999 8.11819V11.3C14.5999 11.6314 14.3313 11.9 13.9999 11.9C13.6685 11.9 13.3999 11.6314 13.3999 11.3V8.11819C13.3999 6.74031 12.8356 5.41498 11.8249 4.43486C10.8135 3.45413 9.43793 2.90001 7.9999 2.90001ZM2.9999 10.3C2.9999 9.19544 3.89533 8.30001 4.9999 8.30001C5.55219 8.30001 5.9999 8.74773 5.9999 9.30001V13.3C5.9999 13.8523 5.55219 14.3 4.9999 14.3C3.89533 14.3 2.9999 13.4046 2.9999 12.3V10.3ZM10.9999 8.30001C10.4476 8.30001 9.9999 8.74773 9.9999 9.30001V13.3C9.9999 13.8523 10.4476 14.3 10.9999 14.3C12.1045 14.3 12.9999 13.4046 12.9999 12.3V10.3C12.9999 9.19544 12.1045 8.30001 10.9999 8.30001Z" fill="currentColor"/>
    </svg>    
  );
};

Support.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Support.defaultProps = {
  color: 'currentColor', // Or any default color
};