import React from "react";
import {Button} from "components/Button";
import {Modal} from "components/Modal";
import {InputField} from "components/InputField";
import PropTypes from "prop-types";
import useSingleFieldForm from "helpers/useSingleFieldForm";
import {renameAccessKey} from "api/qencode-account-api";

function RenameModal({
   open,
   handleCancel,
   id,
   onSuccessfulFinish,
 }) {
  const {
    newName,
    submitting,
    errorMessage,
    handleNameInput,
    clearFormAndClose,
    handleConfirmSubmit,
  } = useSingleFieldForm({
    handleCancel,
    onSuccessfulFinish,
    apiFunction: (({ ...rest }) => renameAccessKey({
      id,
      name: newName,
      ...rest,
    })) ,
  });

  return (
    <Modal
      isOpen={open}
      onClose={ clearFormAndClose }
      header={
        <h3>
          Rename Key
        </h3>
      }
      footer={
        <>
          <Button
            variant="modalFooter"
            onClick={ clearFormAndClose }
            type="secondary"
          >
            Cancel
          </Button>
          <Button
            variant="modalFooter"
            state={ submitting ? "disabled" : "default" }
            onClick={ handleConfirmSubmit }
          >
            Save
          </Button>
        </>
      }
    >
      <form
        className="form"
        onSubmit={ handleConfirmSubmit }
      >
        <div className="row">
          <InputField
            placeholder=""
            onChange={ handleNameInput }
            label="New Key Name"
            name="new-name"
            inputText={newName}
            state={errorMessage ? 'error' : 'default'}
            hint={errorMessage}
          />
        </div>
      </form>
    </Modal>
  );
}

RenameModal.propTypes = {
  open: PropTypes.bool,
  handleCancel: PropTypes.func,
  streamId: PropTypes.string,
  onSuccessfulFinish: PropTypes.func,
};

export default RenameModal;