import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Tabs} from "components/Tabs";
import {
  All,
  Transcoding,
  LiveSteaming,
  MediaStorage,
  Globe2,
  VideoPlayer,
} from 'icons';
import AllTab from "pages/StatisticsPage/AllTab";
import TranscodingTab from "pages/StatisticsPage/TranscodingTab";
import LiveStreamsTab from "pages/StatisticsPage/LiveStreamsTab";
import StorageTab from "pages/StatisticsPage/StorageTab";
import CdnTab from "pages/StatisticsPage/CdnTab";
import PlayerTab from "pages/StatisticsPage/PlayerTab";
import DatePicker from "pages/StatisticsPage/components/DatePicker";
import style from './style.module.css';
import useDatePicker from "pages/StatisticsPage/components/DatePicker/useDatePicker";
import {
  getCdnStats,
  getCdnTotal,
  getCdnUsage,
  getPlayerStats,
  getPlayerTotal,
  getStatsByPlayers,
  getStorageByBuckets,
  getStorageStats,
  getStorageTopRegion,
  getStorageTotal,
  getStreamStats,
  getStreamTopProject,
  getStreamTopRegion,
  getStreamTotal,
  getStreamTotalCount,
  getTranscodingStats,
  getTranscodingStatsByProject,
  getTranscodingTopCodec,
  getTranscodingTopFormat,
  getTranscodingTopProject,
  getTranscodingTotal,
  getTranscodingTotalCount
} from "api/qencode-account-api";
import {
  getCdnTopRegion,
  getStorageTopBucket,
  getStreamTopResolution,
  getTranscodingTopResolution
} from "api/qencode-account-api";
import useDataLoadingStatus from "hooks/useDataLoadingStatus";
import { useLocation } from 'react-router-dom';
import ExportData from "pages/StatisticsPage/TranscodingTab/ExportData";


function StatisticsPage() {
  const location = new URLSearchParams(useLocation().search);
  const transcodingActive = location.get('tab') === 'transcoding';
  const streamsActive = location.get('tab') === 'live-streams';
  const storageActive = location.get('tab') === 'storage';
  const cdnActive = location.get('tab') === 'cdn';
  const playerActive = location.get('tab') === 'player';

  const datepicker = useDatePicker();
  const [currentPriority, setCurrentPriority] = useState(0);
  const transcoding = useMemo(() => ({}), []);
  const streaming = useMemo(() => ({}), []);
  const storage = useMemo(() => ({}), []);
  const cdn = useMemo(() => ({}), []);
  const player = useMemo(() => ({}), []);

  transcoding.topResolution = {
    callback: useCallback((properties) => getTranscodingTopResolution({
      ...properties,
      params: datepicker.params,
    }), [ datepicker.params ]),
  };
  transcoding.topResolution.status = useDataLoadingStatus(transcoding.topResolution.callback, currentPriority >= 0);
  
  transcoding.stats = {
    callback: useCallback((properties) => getTranscodingStats({
      ...properties,
      params: datepicker.params,
    }), [ datepicker.params]),
  }
  transcoding.stats.status = useDataLoadingStatus(transcoding.stats.callback, currentPriority >= 0);
  
  transcoding.total = {
    callback: useCallback((properties) => getTranscodingTotal({
      ...properties,
      params: datepicker.params,
    }), [ datepicker.params]),
  }
  transcoding.total.status = useDataLoadingStatus(transcoding.total.callback, currentPriority >= 1 || transcodingActive);
  
  transcoding.totalCount = {
    callback: useCallback((properties) => getTranscodingTotalCount({
      ...properties,
      params: datepicker.params,
    }), [ datepicker.params]),
  }
  transcoding.totalCount.status = useDataLoadingStatus(transcoding.totalCount.callback, currentPriority >= 1 || transcodingActive);
  
  transcoding.statsByProject = {
    callback: useCallback((properties) => getTranscodingStatsByProject({
      ...properties,
      params: datepicker.params,
    }), [ datepicker.params]),
  }
  transcoding.statsByProject.status = useDataLoadingStatus(transcoding.statsByProject.callback, currentPriority >= 1 || transcodingActive);
  
  transcoding.topProject = {
    callback: useCallback((properties) => getTranscodingTopProject({
      ...properties,
      params: datepicker.params,
    }), [ datepicker.params]),
  }
  transcoding.topProject.status = useDataLoadingStatus(transcoding.topProject.callback, currentPriority >= 1 || transcodingActive);
  
  transcoding.topCodec = {
    callback: useCallback((properties) => getTranscodingTopCodec({
      ...properties,
      params: datepicker.params,
    }), [ datepicker.params]),
  }
  transcoding.topCodec.status = useDataLoadingStatus(transcoding.topCodec.callback, currentPriority >= 1 || transcodingActive);
  
  transcoding.topFormat = {
    callback: useCallback((properties) => getTranscodingTopFormat({
      ...properties,
      params: datepicker.params,
    }), [ datepicker.params]),
  }
  transcoding.topFormat.status = useDataLoadingStatus(transcoding.topFormat.callback, currentPriority >= 1 || transcodingActive);
  
  
  
  
  
  
  
  
  streaming.topResolution = {
    callback: useCallback((properties) => getStreamTopResolution({
      ...properties,
      params: datepicker.params,
    }), [ datepicker.params]),
  }
  streaming.topResolution.status = useDataLoadingStatus(streaming.topResolution.callback, currentPriority >= 0);
  
  streaming.stats = {
    callback: useCallback((properties) => getStreamStats({
      ...properties,
      params: datepicker.params,
    }), [ datepicker.params]),
  }
  streaming.stats.status = useDataLoadingStatus(streaming.stats.callback, currentPriority >= 0);
  
  streaming.total = {
    callback: useCallback((properties) => getStreamTotal({
      ...properties,
      params: datepicker.params,
    }), [ datepicker.params]),
  }
  streaming.total.status = useDataLoadingStatus(streaming.total.callback, currentPriority >= 1 || streamsActive);
  
  streaming.totalCount = {
    callback: useCallback((properties) => getStreamTotalCount({
      ...properties,
      params: datepicker.params,
    }), [ datepicker.params]),
  }
  streaming.totalCount.status = useDataLoadingStatus(streaming.totalCount.callback, currentPriority >= 1 || streamsActive);
  
  streaming.topDeliveryRegions = {
    callback: useCallback((properties) => getStreamTopRegion({
      ...properties,
      params: datepicker.params,
    }), [ datepicker.params]),
  }
  streaming.topDeliveryRegions.status = useDataLoadingStatus(streaming.topDeliveryRegions.callback, currentPriority >= 1 || streamsActive);
  
  streaming.topProjectByStream = {
    callback: useCallback((properties) => getStreamTopProject({
      ...properties,
      params: datepicker.params,
      selectedCategory: 'stream',
    }), [ datepicker.params]),
  }
  streaming.topProjectByStream.status = useDataLoadingStatus(streaming.topProjectByStream.callback, currentPriority >= 1 || streamsActive);
  
  streaming.topProjectByProject = {
    callback: useCallback((properties) => getStreamTopProject({
      ...properties,
      params: datepicker.params,
      selectedCategory: 'project',
    }), [ datepicker.params]),
  }
  streaming.topProjectByProject.status = useDataLoadingStatus(streaming.topProjectByProject.callback, currentPriority >= 1 || streamsActive);
  
  
  
  
  
  
  
  
  
  storage.topBucket = {
    callback: useCallback((properties) => getStorageTopBucket({
      ...properties,
      params: datepicker.params,
    }), [ datepicker.params]),
  }
  storage.topBucket.status = useDataLoadingStatus(storage.topBucket.callback, currentPriority >= 0);
  
  storage.stats = {
    callback: useCallback((properties) => getStorageStats({
      ...properties,
      params: datepicker.params,
    }), [ datepicker.params]),
  }
  storage.stats.status = useDataLoadingStatus(storage.stats.callback, currentPriority >= 0);
  
  storage.total = {
    callback: useCallback((properties) => getStorageTotal({
      ...properties,
      params: datepicker.params,
    }), [ datepicker.params]),
  }
  storage.total.status = useDataLoadingStatus(storage.total.callback, currentPriority >= 1 || storageActive);
  
  storage.byBuckets = {
    callback: useCallback((properties) => getStorageByBuckets({
      ...properties,
      params: datepicker.params,
    }), [datepicker.params]),
  }
  storage.byBuckets.status = useDataLoadingStatus(storage.byBuckets.callback, currentPriority >= 1 || storageActive);
  
  storage.topRegion = {
    callback: useCallback((properties) => getStorageTopRegion({
      ...properties,
      params: datepicker.params,
    }), [datepicker.params]),
  }
  storage.topRegion.status = useDataLoadingStatus(storage.topRegion.callback, currentPriority >= 1 || storageActive);
  
  
  
  
  
  
  
  
  cdn.topRegion = {
    callback: useCallback((properties) => getCdnTopRegion({
      ...properties,
      params: datepicker.params,
    }), [ datepicker.params]),
  }
  cdn.topRegion.status = useDataLoadingStatus(cdn.topRegion.callback, currentPriority >= 0);
  
  cdn.stats = {
    callback: useCallback((properties) => getCdnStats({
      ...properties,
      params: datepicker.params,
    }), [ datepicker.params]),
  }
  cdn.stats.status = useDataLoadingStatus(cdn.stats.callback, currentPriority >= 0);
  
  // const cdnAmount = cdnStats.data.reduce((acc, index) => acc + index.amount, 0);
  
  cdn.total = {
    callback: useCallback((properties) => getCdnTotal({
      ...properties,
      params: datepicker.params,
    }), [ datepicker.params]),
  }
  cdn.total.status = useDataLoadingStatus(cdn.total.callback, currentPriority >= 1 || cdnActive);
  
  cdn.usage = {
    callback: useCallback((properties) => getCdnUsage({
      ...properties,
      params: datepicker.params,
    }), [datepicker.params]),
  }
  cdn.usage.status = useDataLoadingStatus(cdn.usage.callback, currentPriority >= 1 || cdnActive);
  
  
  
  
  player.total = {
    callback: useCallback((properties) => getPlayerTotal({
      ...properties,
      params: datepicker.params,
    }), [ datepicker.params]),
  }
  player.total.status = useDataLoadingStatus(player.total.callback, currentPriority >= 1 || playerActive);
  
  player.stats = {
    callback: useCallback((properties) => getPlayerStats({
      ...properties,
      params: datepicker.params,
    }), [datepicker.params]),
  }
  player.stats.status = useDataLoadingStatus(player.stats.callback, currentPriority >= 0);
  
  player.totalByPlayers = {
    callback: useCallback((properties) => getStatsByPlayers({
      ...properties,
      params: datepicker.params,
    }), [ datepicker.params]),
  }
  player.totalByPlayers.status = useDataLoadingStatus(player.totalByPlayers.callback, currentPriority >= 1 || playerActive);
  
  


  useEffect(() => {
    const chapters = [
      transcoding,
      streaming,
      storage,
      cdn,
      player,
    ];
    let startedTimes = 0;
    let finishedTimes = 0;

    for (let item of chapters) {
      for (let subItem of Object.values(item)) {
        startedTimes += subItem.status.startedTimes;
        finishedTimes += subItem.status.finishedTimes;
      }
    }

    if (startedTimes > 0 && (startedTimes <= (finishedTimes + 3))) {
      setCurrentPriority(1);
    }
  }, [
    transcoding.topResolution.status,
    transcoding.stats.status,
    transcoding.total.status,
    transcoding.totalCount.status,
    transcoding.statsByProject.status,
    transcoding.topProject.status,
    transcoding.topCodec.status,
    transcoding.topFormat.status,
    
    streaming.topResolution.status,
    streaming.stats.status,
    streaming.total.status,
    streaming.totalCount.status,
    streaming.topDeliveryRegions.status,
    streaming.topProjectByStream.status,
    streaming.topProjectByProject.status,
    
    storage.topBucket.status,
    storage.stats.status,
    storage.total.status,
    storage.byBuckets.status,
    storage.topRegion.status,
    
    cdn.topRegion.status,
    cdn.stats.status,
    cdn.total.status,
    cdn.usage.status,
    
    player.total.status,
    player.stats.status,
    player.totalByPlayers.status,
    
    transcoding,
    streaming,
    storage,
    cdn,
    player
    ]);

  const tabs = [
    {
      name: 'all',
      icon: <All />,
      label: 'All',
      component: <AllTab
        datepicker={datepicker}
        transcoding={transcoding}
        streaming={streaming}
        storage={storage}
        cdn={cdn}
        player={player}
      />,
    },
    {
      name: 'transcoding',
      icon: <Transcoding/>,
      label: 'Transcoding',
      component: <TranscodingTab
        datepicker={datepicker}
        transcoding={transcoding}
      />,
    },
    {
      name: 'live-streams',
      icon: <LiveSteaming/>,
      label: 'Live streams',
      component: <LiveStreamsTab
        streaming={streaming}
        datepicker={datepicker}
      />,
    },
    {
      name: 'storage',
      icon: <MediaStorage/>,
      label: 'Storage',
      component: <StorageTab
        datepicker={datepicker}
        storage={storage}
      />,
    },
    {
      name: 'cdn',
      icon: <Globe2/>,
      label: 'CDN',
      component: <CdnTab
        datepicker={datepicker}
        cdn={cdn}
      />,
    },
    {
      name: 'player',
      icon: <VideoPlayer/>,
      label: 'Player',
      component: <PlayerTab
        datepicker={datepicker}
        player={player}
      />,
    },
  ];
  
  return (
    <div className={style.content}>
      <Tabs
        basePath="/usage_stats"
        tabs={tabs}
        additionalContent={
          <AdditionalContent
            datepicker={datepicker}
            transcodingActive={transcodingActive}
          />
        }
      />
    </div>
  )
}

function AdditionalContent({
  datepicker,
  transcodingActive,
}) {
  if (transcodingActive) {
    return (
      <div className={style.rightContent}>
        <DatePicker
          { ...datepicker }
          leftContent={ <ExportData datepicker={datepicker}/> }
        />
      </div>
    )
  } else {
    return (
      <DatePicker
        { ...datepicker }
      />
    )
  }
}

export default StatisticsPage;