import React, { useState, useEffect } from 'react';

import { useAWS } from 'api/useAWS';

import { Modal } from 'components/Modal';

import { Button } from 'components/Button'
import { InputField } from 'components/InputField'

import { validateFields } from 'helpers/validation';

import styles from './index.module.css';

export const DuplicateModal = ({ currentBucket, configData, itemToDuplicate, initialItems, open, handleCancel, onSuccessfulFinish }) => {

    // console.log("itemToDuplicate: ", itemToDuplicate)

    const {
        copyFileInQencodeBucket
    } = useAWS();

    const [currentFiles, setCurrentFiles] = useState([]);

    const [validationErrors, setValidationErrors] = useState({});

    const [currentItem, setCurrentItem] = useState(null);
    const [credentialsDuplicate, setCredentialsDuplicate] = useState({
        file_name: "",
    });        
    const [isDuplicating, setIsDuplicating] = useState(false);
    const [duplicateError, setDuplicateError] = useState(null); // Track any errors during duplication    
  
    // when modal opens set initial states
    useEffect(() => {
        if(open && itemToDuplicate && initialItems){
            // filter out folders as I only need to check vs files
            const files = initialItems.filter(item => item.type === 'file');
            setCurrentFiles(files)

            setCurrentItem(itemToDuplicate)

            setCredentialsDuplicate({
                file_name: itemToDuplicate?.displayName || ''
            })
        }
    }, [open, itemToDuplicate, initialItems])    


    const closeDuplicateModal = () => {
        // reset error
        if (duplicateError) {
            setDuplicateError(null);
        }       

        handleCancel()
    };      

    const handleInputChangeDuplicateFile = (e) => {
        if (duplicateError) {
            setDuplicateError(null);
        }
    
        const { name, value } = e.target;
        //console.log({name, value})
        // Check if the value is an empty string and set it to null if true
        const newValue = value === '' ? '' : value;
    
        const newCredentials = { ...credentialsDuplicate, [name]: newValue };
        setCredentialsDuplicate(newCredentials);
    
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: null
        }));
    };     

    const handleDuplicate = async () => {
        if (duplicateError) {
            setDuplicateError(null);
        }

        // Check if this filename already exists in currentFiles
        const existingFile = currentFiles.find(file => file.displayName === credentialsDuplicate.file_name.trim());
        if (existingFile) {
            setDuplicateError('File with the same name already exists in this directory');
            return; // Exit early if file exists
        }        

        const fieldsToValidate = ['file_name']; 
        const { isValid, errors } = validateFields(credentialsDuplicate, fieldsToValidate);
        setValidationErrors(errors); // Update the validation errors state
      
        if (!isValid) {
          console.log("Validation errors", errors);
          return; // Stop the submission if validation fails
        } 

        setIsDuplicating(true) // Disable the button
    
        try {
            const fileName = credentialsDuplicate.file_name;
    
            // Prepare the config for S3 operations
            const config = {
                access_id: configData.access_id,
                secret_key: configData.secret_key,
                region: currentBucket.region,
            };

            // const newKey = currentItem.name.split('/').slice(0, -1).concat(fileName.trim()).join('/');
            const newKey = `${currentItem.prefix}${fileName.trim()}`;

            //console.log("newKey: ", newKey)
         
            // Rename file
            const { success, message, error } = await copyFileInQencodeBucket(currentBucket.name, currentItem.name, newKey, config); 
    
            if (success) {
                console.log(message);

                // Close current modal and fetch updated items after all movings are complete
                onSuccessfulFinish() 

            } else {
                console.log("error: ", error)
                setDuplicateError(error || "Error duplicating file")
                console.error(error || "Error duplicating file");
            }
        } catch (error) {
            setDuplicateError(error.message || "Unexpected error duplicating file");
            console.error(error.message || "Unexpected error duplicating file");
        } finally {
            setIsDuplicating(false); 
            // in case error window will stay open with error message under file name input              
        }
    };   

    return (
        <Modal isOpen={open} onClose={closeDuplicateModal}
            header={
                <>
                    <h3>Duplicate <span className={styles.itemName}>{currentItem?.displayName}</span></h3>
                </>
            }
            footer={
                <>
                    <Button
                        variant="modalFooter"
                        onClick={closeDuplicateModal}
                        type="secondary"
                    >
                        Cancel
                    </Button>
                    <Button 
                        variant="modalFooter"
                        onClick={handleDuplicate}
                        state={(isDuplicating || (credentialsDuplicate.file_name === currentItem?.displayName)) ? "disabled" : undefined}
                    >
                        {isDuplicating ? "Duplicating..." : "Save"}
                    </Button>                       
                </>
            }
        >
        <div className="form">
            <div className="row">
                <InputField
                    inputText={credentialsDuplicate.file_name}
                    onChange={handleInputChangeDuplicateFile}
                    name="file_name"
                    placeholder=""
                    label="New File Name"
                    state={duplicateError || validationErrors.file_name ? 'error' : 'default'}
                    hint={duplicateError ? duplicateError : validationErrors.file_name ? validationErrors.file_name : undefined}                            
                />                           
            </div>                                             
        </div>  
    </Modal>
    );
}
