import PropTypes from "prop-types";
import React from "react";

export const Copy2 = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path d="M10.0899 3.95506C10.0899 3.75838 10.0118 3.56976 9.87269 3.43068C9.73361 3.29161 9.54499 3.21348 9.34831 3.21348H3.95506C3.75838 3.21348 3.56976 3.29161 3.43068 3.43068C3.29161 3.56976 3.21348 3.75838 3.21348 3.95506V9.34831C3.21348 9.54499 3.29161 9.73361 3.43068 9.87269C3.56976 10.0118 3.75838 10.0899 3.95506 10.0899H4V6.4C4 5.07452 5.07452 4 6.4 4H10.0899V3.95506Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M3.95506 2C3.43654 2 2.93927 2.20598 2.57262 2.57262C2.20598 2.93927 2 3.43654 2 3.95506V9.34831C2 9.86683 2.20598 10.3641 2.57262 10.7307C2.93927 11.0974 3.43654 11.3034 3.95506 11.3034H4V10.0899H3.95506C3.75838 10.0899 3.56976 10.0118 3.43068 9.87269C3.29161 9.73361 3.21348 9.54499 3.21348 9.34831V3.95506C3.21348 3.75838 3.29161 3.56976 3.43068 3.43068C3.56976 3.29161 3.75838 3.21348 3.95506 3.21348H9.34831C9.54499 3.21348 9.73361 3.29161 9.87269 3.43068C10.0118 3.56976 10.0899 3.75838 10.0899 3.95506V4H11.3034V3.95506C11.3034 3.43654 11.0974 2.93927 10.7307 2.57262C10.3641 2.20598 9.86683 2 9.34831 2H3.95506ZM4.6966 10.0899V11.3034V12.045C4.6966 12.5635 4.90258 13.0608 5.26923 13.4274C5.63587 13.7941 6.13315 14.0001 6.65166 14.0001H12.0449C12.5634 14.0001 13.0607 13.7941 13.4274 13.4274C13.794 13.0608 14 12.5635 14 12.045V6.65175C14 6.13323 13.794 5.63596 13.4274 5.26931C13.0607 4.90267 12.5634 4.69669 12.0449 4.69669H11.3034H10.0899H6.65166C6.13315 4.69669 5.63587 4.90267 5.26923 5.26931C4.90258 5.63596 4.6966 6.13323 4.6966 6.65175V10.0899ZM5.91009 10.7021L5.91011 10.6966L5.91009 10.6911V6.65175C5.91009 6.45507 5.98822 6.26645 6.12729 6.12738C6.26636 5.9883 6.45498 5.91017 6.65166 5.91017H12.0449C12.2416 5.91017 12.4302 5.9883 12.5693 6.12738C12.7084 6.26645 12.7865 6.45507 12.7865 6.65175V12.045C12.7865 12.2417 12.7084 12.4303 12.5693 12.5694C12.4302 12.7084 12.2416 12.7866 12.0449 12.7866H6.65166C6.45498 12.7866 6.26636 12.7084 6.12729 12.5694C5.98822 12.4303 5.91009 12.2417 5.91009 12.045V10.7021Z" fill="currentColor"/>
    </svg>    
  );
};

Copy2.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Copy2.defaultProps = {
  color: 'currentColor', // Or any default color
};
