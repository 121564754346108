import {ChevronDown2} from "icons";
import React, {useState} from "react";
import style from './style.module.css';
import PropTypes from "prop-types";
import {Button} from "components/Button";
import ErrorMessage from "components/ErrorMessage";

/**
 * expandedFromActiveFilterTime - last time when section is expanded via click on filter.
 * Component's state reflects whatever user's action is most recent: clicking on filter OR manually wrapping/expanding section.
 * */

function shouldBeExpanded(expandedFromActiveFilterTime, internalExpandedTime) {
  return expandedFromActiveFilterTime > Math.abs(internalExpandedTime) || internalExpandedTime > 0;
}

function FilterSection({
  title,
  children,
  expandedFromActiveFilterTime,
  handleClear,
  selectedCount,
  defaultExpanded,
  error,
  }) {
  const [expandedTime, setExpandedTime] = useState( defaultExpanded ? ( (new Date()).getTime() ) : undefined );
  const expanded = shouldBeExpanded(expandedFromActiveFilterTime, expandedTime);

  function expand() {
    setExpandedTime((time) => {
      return ( shouldBeExpanded(expandedFromActiveFilterTime, time) ? -1 : 1) * (new Date()).getTime();
    });
  }

  return (
    <div className={ `${ style.filterSection } ${ expanded ? style.expanded : '' }` }>
      <div className={ style.headerWrapper }>
        <h4 className={ style.filterSectionHeading }>{ title }</h4>
        <div className={ style.buttonWrapper } hidden={ selectedCount < 1 }>
          <Button
            variant="link"
            onClick={ handleClear }
          >
            { `Clear ${ selectedCount ? `(${ selectedCount })` : '' }` }
          </Button>
        </div>
        <button
          className={ style.expandBtn }
          onClick={expand}
        >
          <ChevronDown2 className={ style.expandButton }/>
          <ChevronDown2 className={ style.collapseButton }/>
        </button>
      </div>
      <div className={ `${ style.filterSectionContent }`  }>
        { children }
        { error && <ErrorMessage>{ error }</ErrorMessage> }
      </div>
    </div>
  );
}


FilterSection.propTypes = {
  defaultExpanded: PropTypes.bool,
  expandedFromActiveFilterTime: PropTypes.number,
  title: PropTypes.string.isRequired,
  handleClear: PropTypes.func.isRequired,
  selectedCount: PropTypes.number,
  error: PropTypes.string,
};

export default FilterSection;