import PropTypes from "prop-types";
import React from "react";

export const Company = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M8.67565 4.27459C8.69547 4.31332 8.70581 4.35624 8.70581 4.39979V12.9934C8.70581 13.2203 8.52265 13.4043 8.29671 13.4043H5.61332C5.38738 13.4043 5.20423 13.2203 5.20423 12.9934V6.09278C5.20423 5.88745 5.31854 5.69935 5.50037 5.6055L8.30842 4.15615C8.44239 4.08701 8.60681 4.14003 8.67565 4.27459ZM8.14135 8.46917C8.10777 8.32169 7.96152 8.22947 7.81469 8.26319L6.09058 8.65912C5.90458 8.70183 5.77267 8.86802 5.77267 9.05967V9.7843C5.77267 9.80001 5.77402 9.8157 5.7767 9.83119C5.80249 9.98024 5.94369 10.0801 6.09209 10.0542L7.80915 9.75452C8.00513 9.72031 8.14821 9.54948 8.14821 9.3497V8.53022C8.14821 8.50968 8.1459 8.4892 8.14135 8.46917ZM8.12609 5.82079C8.06667 5.68178 7.9063 5.61746 7.76789 5.67714L6.02038 6.43064C5.87009 6.49544 5.77267 6.64393 5.77267 6.8082V7.34227C5.77267 7.36779 5.77623 7.3932 5.78323 7.41774C5.82473 7.56316 5.97575 7.64727 6.12054 7.60559L7.85182 7.10721C8.02729 7.05671 8.14821 6.89556 8.14821 6.71223V5.92885C8.14821 5.8917 8.14068 5.85493 8.12609 5.82079Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.87472 2.23792C8.3541 1.97489 8.93462 1.97489 9.41401 2.23792L13.039 4.22688C13.5511 4.50784 13.8694 5.04552 13.8694 5.6296V14.5C13.8694 14.8314 13.6007 15.1 13.2694 15.1C12.938 15.1 12.6694 14.8314 12.6694 14.5V5.6296C12.6694 5.48358 12.5898 5.34916 12.4618 5.27892L8.83677 3.28997C8.71692 3.22421 8.5718 3.22421 8.45195 3.28997L4.82695 5.27892C4.69893 5.34916 4.61936 5.48358 4.61936 5.6296V14.5C4.61936 14.8314 4.35073 15.1 4.01936 15.1C3.68799 15.1 3.41936 14.8314 3.41936 14.5V5.6296C3.41936 5.04552 3.73764 4.50784 4.24972 4.22688L7.87472 2.23792Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M1.8999 14.5C1.8999 14.1686 2.16853 13.9 2.4999 13.9H14.4999C14.8313 13.9 15.0999 14.1686 15.0999 14.5C15.0999 14.8314 14.8313 15.1 14.4999 15.1H2.4999C2.16853 15.1 1.8999 14.8314 1.8999 14.5Z" fill="currentColor"/>
    </svg>    
  );
};

Company.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Company.defaultProps = {
  color: 'currentColor', // Or any default color
};