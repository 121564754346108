import React, { 
    // useState, 
    //useEffect, 
    // useCallback, 
    //useRef 
} from 'react';

import { 
    Link, 
    //useNavigate, 
    //useLocation 
} from 'react-router-dom';

import { Stepper } from 'components/Stepper';

import { 
    ChevronLeft, 
    Dot
} from 'icons'

import styles from './index.module.css';

export const StatusLoading = () => {


    return (
        <div className={styles.statusContainer}>
            <div className={styles.statusBlockHeader}>
                <div className={styles.jobDetailsContainer}>
                    <Link to='/my_encodings' className={styles.backLink}>
                        <ChevronLeft label="Back" className={styles.backLinkIcon} />
                        <div className={styles.backLinkText}>Back to Jobs</div>
                    </Link>      
                    <div className={styles.jobDetails}>
                        <div className={styles.jobDetailsHeader}>
                            <div className={`${styles.jobIdContainer_Loading} skeleton-loading`}></div>
                        </div>
                        <div className={styles.jobDetailsBody}>

                            <div className={`${styles.jobDetailsItemIcon_Loading} skeleton-loading`}></div>   
                            <div className={`${styles.block_Loading_160} skeleton-loading`}></div>        
                            <Dot className={styles.delimeter} /> 

                            <div className={`${styles.jobDetailsItemIcon_Loading} skeleton-loading`}></div>   
                            <div className={`${styles.block_Loading_120} skeleton-loading`}></div>        
                            <Dot className={styles.delimeter} /> 

                            <div className={`${styles.block_Loading_60} skeleton-loading`}></div>       
                            <Dot className={styles.delimeter} />       

                            <div className={`${styles.jobDetailsItemIcon_Loading} skeleton-loading`}></div>   
                            <div className={`${styles.block_Loading_160} skeleton-loading`}></div>       
                            <Dot className={styles.delimeter} />            

                            <div className={`${styles.jobDetailsItemIcon_Loading} skeleton-loading`}></div>   
                            <div className={`${styles.block_Loading_120} skeleton-loading`}></div>                                                                         
                          
                        </div>                        
                    </div>              
                </div>
                
                <div className={`${styles.resubmitJobBtn_Loading} skeleton-loading`}></div>  
            </div>

            <div className={styles.statusProgressBlock}>

                <div className={styles.stepsContainer}>
                    <Stepper
                        position="start"
                        loading={true}
                    />
                    <Stepper
                        position="middle"
                        loading={true}
                    />  
                    <Stepper
                        position="middle"
                        loading={true}
                    />      
                    <Stepper
                        position="middle"
                        loading={true}
                    />     
                    <Stepper
                        position="middle"
                        loading={true}
                    />                    
                    <Stepper
                        position="end"
                        loading={true}
                    />                                                               
                </div>


            </div>            
        </div>
    );
};