import {useCallback, useMemo, useState} from "react";
import utcStringToDate from "helpers/utcStringToDate";
import {columnIds} from "pages/MediaStorage/AccessKeysPage/config";

export default function useSecret({
  data,
  setSorting,
}) {
  const [regeneratedKey, setRegeneratedKey] = useState();
  const [generatedKeyList, setGeneratedKeyList] = useState([]);
  
  const dataWithSecret = useMemo(() => {
    const generatedKeysNotInList = []; /* Prevent duplicates as refresh happens. */
    
    for (let generatedKey of generatedKeyList) {
      const keyInList = data.find((item) => item['access_id'] === generatedKey['access_id']);
      if (!keyInList) {
        generatedKeysNotInList.push(generatedKey);
      }
    }
    
    return [...data, ...generatedKeysNotInList];
  }, [data, generatedKeyList]);

  const getSecret = useCallback(function getSecret(
    info,
  ) {
    const originalRow = info?.row?.original;
    let isRegenerated = Boolean(regeneratedKey && ( regeneratedKey?.['access_id'] === originalRow?.['access_id'] ));
    
    let secret = '';
    if (isRegenerated) {
      secret = regeneratedKey['secret_key'];
    } else if (!regeneratedKey) {
      const lastCreatedKey = generatedKeyList[generatedKeyList.length - 1];
      if (lastCreatedKey && (lastCreatedKey?.['access_id'] === originalRow?.['access_id'])) {
        secret = lastCreatedKey?.['secret_key'];
      }
    }
    return {
      secret,
      isRegenerated,
    };
  }, [generatedKeyList, regeneratedKey]);
  
  const handleAfterRegenerate = useCallback(function handleNewSecret(item) {
    setRegeneratedKey(item);
  }, []);
  
  /* After key is generated add added, data for React Table will change and pagination will autoreset. This is what we want. */
  const handleAfterGenerate = useCallback(function handleGenerate(item) {
    setRegeneratedKey();
    setGeneratedKeyList((list) => {
      const newListItem = {
        ...item,
        create_datetime: utcStringToDate(item['created_datetime']), /* Inconsistent BE naming. */
      };
      return [...list, newListItem];
    });
    setSorting?.([{
      id: columnIds.dateCreated,
      desc: true,
    }]);
  }, [setSorting]);
  
  const handleDeleteKey = useCallback(function handleDeleteKey(item) {
    if (item?.['access_key'] === regeneratedKey?.['access_key']) {
      setRegeneratedKey();
    }
    setGeneratedKeyList((oldList) => oldList.filter((oldKey) => oldKey?.['access_key'] !== item?.['access_key']));
  }, [regeneratedKey]);
  
  return {
    dataWithSecret,
    getSecret,
    handleAfterRegenerate,
    handleAfterGenerate,
    handleDeleteKey,
  };
}
