import React, { useState, useRef } from 'react';
import styles from './FileDropZone.module.css';
import { UploadFile } from 'icons';

export const FileDropZone = ({ onFileSelect, multiple = false }) => {
    const [isDragOver, setIsDragOver] = useState(false);
    const fileInputRef = useRef(null); 

    const handleFileChange = (event) => {
        const files = event.target.files;
        if (multiple) {
            onFileSelect(Array.from(files)); // Pass an array of files if multiple is true
        } else {
            onFileSelect(files[0]); // Pass a single file
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (multiple) {
            onFileSelect(Array.from(files)); // Pass an array of files if multiple is true
        } else {
            onFileSelect(files[0]); // Pass a single file
        }
        setIsDragOver(false);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = () => {
        setIsDragOver(false);
    };

    const handleBrowseClick = () => {
        fileInputRef.current.click(); 
    };

    return (
        <div 
            className={`${styles.uploadArea} ${isDragOver ? styles.dragOver : ''}`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
        >
            <div className={styles.uploadIconContainer}>
                <UploadFile className={styles.uploadIcon} />
            </div>
            <div className={styles.uploadMessage}>
                Drag and drop file{multiple ? 's' : ''}, or{' '}
                <span className={styles.browseLink} onClick={handleBrowseClick}>
                    Browse
                </span>
            </div>
            <input 
                type="file" 
                ref={fileInputRef}  
                className={styles.fileInput} 
                onChange={handleFileChange} 
                multiple={multiple} // Add the multiple attribute conditionally
            />
        </div>
    );
};



// using ref for input
// import React, { useState, useRef } from 'react';
// import styles from './FileDropZone.module.css';
// import { UploadFile } from 'icons';

// export const FileDropZone = ({ onFileSelect }) => {
//     const [isDragOver, setIsDragOver] = useState(false);
//     const fileInputRef = useRef(null); // Use React ref instead of document.getElementById

//     const handleFileChange = (event) => {
//         const file = event.target.files[0];
//         onFileSelect(file);
//     };

//     const handleDrop = (event) => {
//         event.preventDefault();
//         const file = event.dataTransfer.files[0];
//         onFileSelect(file);
//         setIsDragOver(false);
//     };

//     const handleDragOver = (event) => {
//         event.preventDefault();
//         setIsDragOver(true);
//     };

//     const handleDragLeave = () => {
//         setIsDragOver(false);
//     };

//     const handleBrowseClick = () => {
//         fileInputRef.current.click(); // Use the ref to access the input element
//     };

//     return (
//         <div 
//             className={`${styles.uploadArea} ${isDragOver ? styles.dragOver : ''}`}
//             onDrop={handleDrop}
//             onDragOver={handleDragOver}
//             onDragLeave={handleDragLeave}
//         >
//             <div className={styles.uploadIconContainer}>
//                 <UploadFile className={styles.uploadIcon} />
//             </div>
//             <div className={styles.uploadMessage}>
//                 Drag and drop file, or{' '}
//                 <span className={styles.browseLink} onClick={handleBrowseClick}>
//                     Browse
//                 </span>
//             </div>
//             <input 
//                 type="file" 
//                 ref={fileInputRef}  // Attach the ref here
//                 className={styles.fileInput} 
//                 onChange={handleFileChange} 
//             />
//         </div>
//     );
// };




// using id for input
// import React, { useState } from 'react';
// import styles from './FileDropZone.module.css';
// import { UploadFile } from 'icons';

// export const FileDropZone = ({ onFileSelect }) => {
//     const [isDragOver, setIsDragOver] = useState(false);

//     const handleFileChange = (event) => {
//         const file = event.target.files[0];
//         onFileSelect(file);
//     };

//     const handleDrop = (event) => {
//         event.preventDefault();
//         const file = event.dataTransfer.files[0];
//         onFileSelect(file);
//         setIsDragOver(false);
//     };

//     const handleDragOver = (event) => {
//         event.preventDefault();
//         setIsDragOver(true);
//     };

//     const handleDragLeave = () => {
//         setIsDragOver(false);
//     };

//     return (
//         <div 
//             className={`${styles.uploadArea} ${isDragOver ? styles.dragOver : ''}`}
//             onDrop={handleDrop}
//             onDragOver={handleDragOver}
//             onDragLeave={handleDragLeave}
//         >
//             <div className={styles.uploadIconContainer}>
//                 <UploadFile className={styles.uploadIcon} />
//             </div>
//             <div className={styles.uploadMessage}>
//                 Drag and drop file, or{' '}
//                 <span className={styles.browseLink} onClick={() => document.getElementById('fileInput').click()}>
//                     Browse
//                 </span>
//             </div>
//             <input 
//                 type="file" 
//                 id="fileInput" 
//                 className={styles.fileInput} 
//                 onChange={handleFileChange} 
//             />
//         </div>
//     );
// };

