import React from 'react';
import styles from './FileUpload.module.css';
import { FileCard } from 'components/FileCard';
import { FileDropZone } from 'components/FileDropZone';

export const FileUpload = ({
    selectedFile,
    bucket,
    path,
    error,
    onFileSelect,
    isUploading,
    uploadProgress,
    isUploadComplete,
    handleDelete,
    handleCancelUpload,
    handleRetry,
}) => {
    return (
        <div className={styles.fileUpload}>
            {!selectedFile ? (
                <FileDropZone onFileSelect={onFileSelect} />
            ) : (
                <FileCard
                    file={selectedFile}
                    onDelete={handleDelete}
                    onRetry={handleRetry}
                    onCancel={handleCancelUpload}
                    error={error}
                    progress={uploadProgress}
                    isUploading={isUploading}
                    isUploadComplete={isUploadComplete}
                    bucket={bucket}
                    path={path}
                />
            )}
        </div>
    );
};



// import React, { useState } from 'react';
// import styles from './FileUpload.module.css';

// import { UploadFile } from 'icons';

// import { FileCard } from 'components/FileCard';

// const FileDropZone = ({ isDragOver, handleDrop, handleDragOver, handleDragLeave, handleFileChange }) => (
//     <div
//         className={`${styles.uploadArea} ${isDragOver ? styles.dragOver : ''}`}
//         onDrop={handleDrop}
//         onDragOver={handleDragOver}
//         onDragLeave={handleDragLeave}
//     >
//         <div className={styles.uploadIconContainer}><UploadFile className={styles.uploadIcon} /></div>
        
//         <div className={styles.uploadMessage}>
//             Drag and drop file, or{' '}
//             <span className={styles.browseLink} onClick={() => document.getElementById('fileInput').click()}>
//                 Browse
//             </span>
//         </div>
//         <input type="file" id="fileInput" className={styles.fileInput} onChange={handleFileChange} />
//     </div>
// );

// export const FileUpload = ({ selectedFile, bucket, path, error, onFileSelect, isUploading, uploadProgress, isUploadComplete, handleDelete, handleCancelUpload, handleRetry }) => {
//     const [isDragOver, setIsDragOver] = useState(false);

//     const handleFileChange = (event) => {
//         const file = event.target.files[0];
//         onFileSelect(file);
//     };

//     const handleDrop = (event) => {
//         event.preventDefault();
//         const file = event.dataTransfer.files[0];
//         onFileSelect(file);
//         setIsDragOver(false);
//     };

//     const handleDragOver = (event) => {
//         event.preventDefault();
//         setIsDragOver(true);
//     };

//     const handleDragLeave = () => {
//         setIsDragOver(false);
//     };

//     return (
//         <div className={styles.fileUpload}>
//             {
//                 !selectedFile 
//                 ? 
//                 <FileDropZone
//                     isDragOver={isDragOver}
//                     handleDrop={handleDrop}
//                     handleDragOver={handleDragOver}
//                     handleDragLeave={handleDragLeave}
//                     handleFileChange={handleFileChange}
//                 />
//                 :
//                 <FileCard 
//                     file={selectedFile} 
//                     onDelete={handleDelete} 
//                     onRetry={handleRetry} 
//                     onCancel={handleCancelUpload}
//                     error={error} 
//                     progress={uploadProgress}
//                     isUploading={isUploading}
//                     isUploadComplete={isUploadComplete}
//                     bucket={bucket} 
//                     path={path} 
//                 />               
//             }


//         </div>
//     );
// };

