import React from 'react';
import PropTypes from 'prop-types';

export function InProgress({ className, color, label }) {
    // Apply inline style only if color prop is not currentColor
    const style = color !== 'currentColor' ? { color } : undefined;

    return (
        <svg
            fill="none"
            viewBox="1044 217 14 14"
            className={className}
            role="img"
            aria-label={label}
            style={style}
            width="16"
            height="16"
            >
            <path d="M1051.94 217.942a.94.94 0 1 0-1.88 0v1.616a.94.94 0 1 0 1.88 0v-1.616ZM1051.94 228.442a.94.94 0 1 0-1.88 0v1.616a.94.94 0 1 0 1.88 0v-1.616ZM1044 224c0-.52.42-.942.94-.942h1.62c.52 0 .94.422.94.942a.94.94 0 0 1-.94.942h-1.62a.94.94 0 0 1-.94-.942ZM1055.44 223.058a.94.94 0 0 0-.94.942c0 .52.42.942.94.942h1.62a.94.94 0 0 0 .94-.942.94.94 0 0 0-.94-.942h-1.62ZM1046.05 219.05a.94.94 0 0 1 1.33 0l1.15 1.142c.36.368.36.965 0 1.333a.951.951 0 0 1-1.34 0l-1.14-1.142a.94.94 0 0 1 0-1.333ZM1054.81 226.475a.951.951 0 0 0-1.34 0 .956.956 0 0 0 0 1.332l1.15 1.143a.94.94 0 0 0 1.33 0 .94.94 0 0 0 0-1.333l-1.14-1.142ZM1055.95 219.05a.94.94 0 0 1 0 1.333l-1.14 1.142a.951.951 0 0 1-1.34 0 .956.956 0 0 1 0-1.332l1.15-1.143a.94.94 0 0 1 1.33 0ZM1048.53 227.808a.958.958 0 0 0 0-1.333.951.951 0 0 0-1.34 0l-1.14 1.142a.94.94 0 0 0 0 1.333.94.94 0 0 0 1.33 0l1.15-1.142Z" fill="currentColor"/>
        </svg>
    );
}

InProgress.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.string,
};

InProgress.defaultProps = {
    color: 'currentColor', // Or any default color
};
