import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import Table from "components/Table";
import {getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable} from "@tanstack/react-table";
import Region from "components/Region";
import Card from "pages/StatisticsPage/components/Card";
import GbReadable from "components/Table/Cells/GbReadable";
import MoneyRounded from "components/Table/Cells/MoneyRounded";
import {ReactComponent as RegionIcon} from "assets/images/Region.svg";
import style from './style.module.css';

const columnIds = {
  region: 'region',
  buckets: 'buckets',
  storage: 'storage',
  cost: 'cost',
}

function TopRegions({
  stats,
}) {
  const [sorting, setSorting] = useState([{
    id: columnIds.storage,
    desc: true,
  }]);
  const columns = useMemo(() => [
    {
      id: columnIds.region,
      accessorKey: 'region',
      header: () => <span>Region</span>,
      cell: (info) => <Region regionCode={info.getValue()}/>,
    },
    {
      id: columnIds.buckets,
      accessorKey: 'bucket_count',
      header: () => <span>Bucket Count</span>,
      meta: {
        className: style.bucketCountColumn,
      },
    },
    {
      id: columnIds.storage,
      header: () => <span>Gb Stored</span>,
      accessorKey: 'storage_gb_month',
      cell: (info) => <GbReadable value={info.getValue()}/>,
      meta: {
        className: style.gbStoredColumn,
      },
    },
    {
      id: columnIds.cost,
      header: () => <span>Cost</span>,
      accessorKey: 'amount',
      cell: (info) => <div className={style.valueWrapper}><MoneyRounded value={info.getValue()} semiBold /></div>,
      meta: {
        className: style.costColumn,
        align: 'right',
      },
    }
  ], [])

  const table = useReactTable({
    data: stats.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    /* Sorting */
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      pagination: {
        pageIndex: 0,
        pageSize: 5,
      },
    },
  });
  
  return (
    <Card title="Top Regions">
      <Table
        { ...stats }
        table={table}
        lastLineInvisible
        noDataImage={<RegionIcon/>}
        noDataDescription="No data found"
        skeletonRowCount={5}
        minWidth={420}
      />
    </Card>
  )
}

TopRegions.propTypes = {
  stats: PropTypes.object.isRequired,
};

export default TopRegions;