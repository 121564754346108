import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from "components/Button";
import {Key} from "icons";
import {useAuth} from "context/AuthContext";
import {createAccessKey} from "api/qencode-account-api";
import ErrorMessage from "components/ErrorMessage";

function CreateKeyButton({ onSuccess }) {
  const { getToken } = useAuth();
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  async function generate() {
    setSubmitting(true);
    const {
      success,
      error,
      data,
    } = await createAccessKey({
      accessToken: await getToken(),
    });
    setSubmitting(false);
    if (success) {
      onSuccess?.(data);
    } else {
      setErrorMessage(error);
    }
  }

  return (
    <div>
      <Button
        icon={ <Key/> }
        onClick={generate}
        state={ submitting ? 'disabled' : 'default' }
      >
        Generate New Key
      </Button>
      { errorMessage && <ErrorMessage>{ errorMessage }</ErrorMessage> }
    </div>
  )
}

CreateKeyButton.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default CreateKeyButton;