import React from 'react';
import style from './style.module.css';

function UnifiedTwoCells({ children }) {
  return (
    <div className={style.wrapper}>
      { children }
    </div>
  )
}

export default UnifiedTwoCells;