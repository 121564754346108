import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
//import reportWebVitals from './reportWebVitals';

// Global styles
import './styles/global.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <App />
);

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// For performance measurement, pass a function to log results
// Learn more: https://bit.ly/CRA-vitals
// if (process.env.NODE_ENV === 'development') {
//   reportWebVitals(console.log);
// }




// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// import './styles/fonts.css';
// import './styles/styleguide.css'; // Import the style guide

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
