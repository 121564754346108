import React from 'react';
import style from './style.module.css';
import PropTypes from "prop-types";

function Tooltip({ children, visible, text, showOnHover = true }) {
  if (text) {
    return (
      <div
        className={ `${ visible ? style.visible : '' } ${ style.container } ${ showOnHover ? style.showOnHover : '' }`  }
      >
        { children }
        <div className={ `${ style.tooltip }` }>
          { text }
        </div>
      </div>
    );
  }
}

Tooltip.propTypes = {
  visible: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

export default Tooltip;