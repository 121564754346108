import PropTypes from "prop-types";
import React from "react";

export const Google = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"
    >
      <path d="M18 9.20455C18 8.56636 17.9416 7.95273 17.833 7.36364H9.18367V10.845H14.1262C13.9133 11.97 13.2662 12.9232 12.2936 13.5614V15.8195H15.2616C16.9981 14.2527 18 11.9455 18 9.20455Z" fill="#4285F4"/>
      <path d="M9.18367 18C11.6633 18 13.7421 17.1941 15.2616 15.8195L12.2936 13.5614C11.4712 14.1014 10.4193 14.4205 9.18367 14.4205C6.79174 14.4205 4.76716 12.8373 4.04499 10.71H0.976809V13.0418C2.48794 15.9832 5.59369 18 9.18367 18Z" fill="#34A853"/>
      <path d="M4.04499 10.71C3.86132 10.17 3.75696 9.59318 3.75696 9C3.75696 8.40682 3.86132 7.83 4.04499 7.29V4.95818H0.97681C0.333952 6.21234 -0.000566571 7.5965 7.20323e-07 9C7.20323e-07 10.4523 0.354824 11.8268 0.976809 13.0418L4.04499 10.71Z" fill="#FBBC05"/>
      <path d="M9.18367 3.57955C10.532 3.57955 11.7426 4.03364 12.6943 4.92545L15.3284 2.34409C13.7379 0.891818 11.6591 0 9.18367 0C5.59369 0 2.48794 2.01682 0.97681 4.95818L4.04499 7.29C4.76716 5.16273 6.79174 3.57955 9.18367 3.57955Z" fill="#EA4335"/>
    </svg>    
  );
};

Google.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Google.defaultProps = {
  color: 'currentColor', // Or any default color
};