import React, {useState } from 'react';
import { useLocation } from 'react-router-dom'; 
import { useAuth } from '../../context/AuthContext';
import Topbar from '../../components/Topbar';
import { Sidebar } from '../../components/Sidebar';

import styles from './MainLayout.module.css';

export const MainLayout = ({ children, pageName, userRole }) => {

  //console.log("userRole in main layoyt: ", userRole)

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const { isAuthenticated } = useAuth();

  const location = useLocation(); // Get current route

  // Define routes that require a different design
  const builderLayoutRoute = '/request_builder'; // use a bit different layout for builder

  // Conditional layout logic based on the route
  const isBuilderLayout = location.pathname === builderLayoutRoute;


  // Construct the class name for the content container
  // show sidebar only when navigation bar is visible
  // check if sidebar is collapsed or not
  const contentContainerClass = `${styles.contentContainer} ${
    isAuthenticated ? styles.showSidebar : ''} ${
    sidebarCollapsed ? styles.sidebarCollapsed : ''}`;

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  return (
    <div className={styles.container}>    

        {/* Render the Sidebar only when the user is authenticated */}
        {isAuthenticated && <Sidebar isCollapsed={sidebarCollapsed} onToggle={toggleSidebar} userRole={userRole}/>}

        {/* Adjust the main content based on whether Sidebar is displayed or not */}
        <div className={contentContainerClass}>
          <Topbar pageName={pageName} userRole={userRole}/>
          {/* Pass userRole to children */}
          {/* <main className={styles.content}>{children}</main> */}

          {/* <main className={styles.content}>
            <div className={styles.contentWrapper}>
              {React.cloneElement(children, { userRole })} 
            </div>
          </main>      */}

          <main className={isBuilderLayout ? styles.builderContent : styles.content}>
            <div className={styles.contentWrapper}>
              {React.cloneElement(children, { userRole })} 
            </div>
          </main>        
        </div>     

    </div>
  );
};



// import React, {useState } from 'react';
// import { useAuth } from '../../context/AuthContext';
// import Topbar from '../../components/Topbar';
// import { Sidebar } from '../../components/Sidebar';

// import styles from './MainLayout.module.css';

// export const MainLayout = ({ children, pageName, userRole }) => {

//   //console.log("userRole in main layoyt: ", userRole)

//   const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

//   const { isAuthenticated } = useAuth();

//   // Construct the class name for the content container
//   // show sidebar only when navigation bar is visible
//   // check if sidebar is collapsed or not
//   const contentContainerClass = `${styles.contentContainer} ${
//     isAuthenticated ? styles.showSidebar : ''} ${
//     sidebarCollapsed ? styles.sidebarCollapsed : ''}`;

//   const toggleSidebar = () => {
//     setSidebarCollapsed(!sidebarCollapsed);
//   };

//   return (
//     <div className={styles.container}>    

//       {/* Render the Sidebar only when the user is authenticated */}
//       {isAuthenticated && <Sidebar isCollapsed={sidebarCollapsed} onToggle={toggleSidebar} userRole={userRole}/>}

//       {/* Adjust the main content based on whether Sidebar is displayed or not */}
//       <div className={contentContainerClass}>
//         <Topbar pageName={pageName} userRole={userRole}/>
//         {/* <main className={styles.content}>{children}</main> */}
//         <main className={styles.content}>
//           {/* Pass userRole to children */}
//           {React.cloneElement(children, { userRole })} 
//         </main>        
//       </div>      

//     </div>
//   );
// };



// import React, {useState } from 'react';
// import { useAuth } from '../../context/AuthContext';
// import Topbar from '../../components/Topbar';
// import { Sidebar } from '../../components/Sidebar';

// import styles from './MainLayout.module.css';

// export const MainLayout = ({ children, pageName }) => {

//   const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

//   const { isAuthenticated } = useAuth();

//   // Construct the class name for the content container
//   // show sidebar only when navigation bar is visible
//   // check if sidebar is collapsed or not
//   const contentContainerClass = `${styles.contentContainer} ${
//     isAuthenticated ? styles.showSidebar : ''} ${
//     sidebarCollapsed ? styles.sidebarCollapsed : ''}`;

//   const toggleSidebar = () => {
//     setSidebarCollapsed(!sidebarCollapsed);
//   };

//   return (
//     <div className={styles.container}>    

//       {/* Render the Sidebar only when the user is authenticated */}
//       {isAuthenticated && <Sidebar isCollapsed={sidebarCollapsed} onToggle={toggleSidebar} />}

//       {/* Adjust the main content based on whether Sidebar is displayed or not */}
//       <div className={contentContainerClass}>
//         <Topbar pageName={pageName} />
//         <main className={styles.content}>{children}</main>
//       </div>      

//     </div>
//   );
// };
