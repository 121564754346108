// don't fetch wallet data if user role is developer
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { getWallet } from '../api/qencode-account-api';
import { useAuth } from 'context/AuthContext';
import { jwtDecode } from 'jwt-decode';

export const WalletContext = createContext(null);

export const useWallet = () => useContext(WalletContext);

export const WalletProvider = ({ children }) => {
  const [walletLoading, setWalletLoading] = useState(true);
  const [walletData, setWalletData] = useState([]);
  const [walletError, setWalletError] = useState(null);

  const { isAuthenticated, getToken } = useAuth();

  const fetchWalletData = useCallback(async () => {
    if (!isAuthenticated) {
      setWalletData([]);
      setWalletError(null);
      setWalletLoading(false);
      return;
    }

    try {
      const token = await getToken();

      if (!token) {
        setWalletError("Failed to retrieve access token");
        console.log("Failed to retrieve access token");
        setWalletLoading(false);
        return;
      }

      const decodedToken = jwtDecode(token);
      const userRole = decodedToken?.role;

      if (userRole === 'developer') {
        // Skip loading wallet data for developer role
        setWalletData([]);
        setWalletError('Access to wallet data is restricted for developers.');
        setWalletLoading(false);
        return;
      }

      setWalletLoading(true);
      const { success, data, error } = await getWallet(token);
      if (success && data) {
        setWalletData(data);
        setWalletError(null);
      } else {
        setWalletError(`Failed to get wallet data: ${error || "No additional error information"}`);
      }
    } catch (error) {
      setWalletError(`Error fetching wallet data: ${error.message}`);
    }
    setWalletLoading(false);
  }, [isAuthenticated, getToken]);

  useEffect(() => {
    fetchWalletData();
  }, [fetchWalletData]);

  return (
    <WalletContext.Provider value={{
      walletData,
      setWalletData,
      walletError,
      setWalletError,
      walletLoading,
      refreshWallet: fetchWalletData  // Expose refresh function for manual refresh
    }}>
      {children}
    </WalletContext.Provider>
  );
};


// import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
// import { getWallet } from '../api/qencode-account-api';
// import { useAuth } from './AuthContext';

// export const WalletContext = createContext(null);

// export const useWallet = () => useContext(WalletContext);

// export const WalletProvider = ({ children }) => {
//   const [walletLoading, setWalletLoading] = useState(true);
//   const [walletData, setWalletData] = useState([]);
//   const [walletError, setWalletError] = useState(null);

//   const { isAuthenticated, getToken } = useAuth();

//   const fetchWalletData = useCallback(async () => {
//     if (!isAuthenticated) {
//       setWalletData([]);
//       setWalletError(null);
//       setWalletLoading(false);
//       return;
//     }
    
//     setWalletLoading(true);
//     try {
//       const token = await getToken();
//       const { success, data, error } = await getWallet(token);
//       if (success && data) {
//         setWalletData(data);
//         setWalletError(null);
//       } else {
//         setWalletError(`Failed to get wallet data: ${error || "No additional error information"}`);
//       }
//     } catch (error) {
//       setWalletError(`Error fetching wallet data: ${error.message}`);
//     }
//     setWalletLoading(false);
//   }, [isAuthenticated, getToken]);

//   useEffect(() => {
//     fetchWalletData();
//   }, [fetchWalletData]);

//   return (
//     <WalletContext.Provider value={{
//       walletData,
//       setWalletData,
//       walletError,
//       setWalletError,
//       walletLoading,
//       refreshWallet: fetchWalletData  // Expose refresh function for manual refresh
//     }}>
//       {children}
//     </WalletContext.Provider>
//   );
// };







// // WalletContext.js WalletContext
// import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
// import { getWallet } from '../api/qencode-account-api';
// import { useAuth } from './AuthContext'; // import useAuth if it's a custom hook

// export const WalletContext = createContext(null);

// // Export useWallet hook for easy import
// export const useWallet = () => useContext(WalletContext);

// export const WalletProvider = ({ children }) => {

//   const [walletLoading, setWalletLoading] = useState(true);

//   const { 
//     isAuthenticated, 
//     //getAccessToken,
//     getToken 
//   } = useAuth();

//   const [walletData, setWalletData] = useState([]);
//   const [walletError, setWalletError] = useState(null);

//   const fetchWalletData = useCallback(async () => {
//       if (isAuthenticated) {
//         setWalletLoading(true);
//         //const token = getAccessToken();
//         console.log("Getting access token...")
//         const token = await getToken()
//         console.log("Got token: ", token)
//         const { success, data } = await getWallet(token);
//         if (success && data) {
//           console.log("wallet data: ", data)
//           setWalletData(data);
//           setWalletError(null);
//         } else {
//           setWalletError("Failed to get wallet data");
//           console.log("Failed to get wallet data");
//         }
//         setWalletLoading(false);
//       } else {
//         setWalletData([]);
//         setWalletError(null);
//         setWalletLoading(false);
//       }
//   }, [isAuthenticated, getToken]);    

//   useEffect(() => {
//       fetchWalletData();
//   }, [fetchWalletData]);     


//   return (
//     <WalletContext.Provider value={{ walletData, setWalletData, walletError, setWalletError, walletLoading, fetchWalletData }}>
//       {children}
//     </WalletContext.Provider>
//   );
// };
