// has error on downloading - http://localhost:3000/my_encodings/e732d0f735261fdd8a09c90da4d4c43d
// no errors completed - http://localhost:3000/my_encodings/be462fdabff6e5fccb777cd4db4d3313

import React, { 
    //useState, 
    // useEffect, 
    // useCallback, 
    //useRef 
} from 'react';

import { useNavigate } from 'react-router-dom';

import { 
    //Copy, 
    ChevronBottom,
    PlayFilled, 
    Download,
    Storage,
    Warning as WarningIcon
} from 'icons';

import { OptionsMenu } from 'components/OptionsMenu'

//import { NoData } from 'components/NoData';

import { formatSize, formatDuration, formatBitrate, 
    //formatToPercentage 
} from 'helpers/formatting'

import { CopyButton } from 'components/CopyButton'

// import { PopupContainer } from 'components/PopupContainer';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import styles from './index.module.css';

// 9f80de06d11bf25a36bb48f88902c027 - multiple output
// 50dfdc78a4b76fbafa854f69733b340a - multiple destination - output video, audio, images - CUSTOM S3
// 68a59bc06528413a9bc22409a0d8d4ff - multiple resolutions HLS - all vidoes have same url
// 06c30efb0d2ccd3c2e418efab3684bf8 - hls_dash/hls

// d0db9f4ce692f160e15d2a37295aebc7 - mp4, mp4, hls, hls

// 90c7e569f32b8d978b6ca3019eff59f0 - mp4, hls, hls, mp4 + multiple destination

// d4a3bff2bf283d0433c7a379cfe1cd37 - hls + webm

// mp4 + hls - http://localhost:3000/my_encodings/8958db387703b21ef481df4413fbff82
// need to download hls as zip

// IN PORTAL - 235cab72b3260c7b884e8230c08dc9c2


export const Output = ({loading, error, audios = [], images = [], texts = [], videos = []}) => {

    // url https://storage-aws-us-east-1.qencode.com/a459d8933242bca4527faa022844229e/speech_to_text/1-0

    // https://storage-aws-us-east-1.qencode.com/a459d8933242bca4527faa022844229e/speech_to_text/1-0/723.txt
    // https://storage-aws-us-east-1.qencode.com/a459d8933242bca4527faa022844229e/speech_to_text/1-0/723.srt
    // to test speech-to-text

    // one url, one name
    // texts = [
    //     {
    //         "tag": "speech_to_text-0-0-0",
    //         "profile": null,
    //         "user_tag": null,
    //         "storage": {
    //             "zip": {
    //                 "region": "us-east-1",
    //                 "bucket": "qencode-temp-us-east-1",
    //                 "host": "stage-us-east-1-1-storage-aws.qencode.com"
    //             },
    //             "format": "speech_to_text",
    //             "host": "storage-aws-us-east-1.qencode.com",
    //             "names": {
    //                 "transcript": "723.txt",
    //             },
    //             "key": "1-0",
    //             "path": "a459d8933242bca4527faa022844229e/speech_to_text/1-0",
    //             //"type": 'local',
    //             "type": "qencode_s3",
    //             "expire": "2024-07-17 17:31:23",
    //             "timestamp": "2024-07-17 11:41:03"
    //         },
    //         "url": "https://storage-aws-us-east-1.qencode.com/a459d8933242bca4527faa022844229e/speech_to_text/1-0",
    //         "meta": {
    //             "language": "en",
    //             "entropy_threshold": 2.8,
    //             "beam_size": 9,
    //             "max_context": 128,
    //             "model": "large",
    //             "mode": "accuracy"
    //         },
    //         "duration": "4.096",
    //         "size": "0.000138283",
    //         "output_format": "speech_to_text",
    //         "error": false,
    //         "error_description": null,
    //         "cost": {
    //             "currency": "USD",
    //             "code": 840,
    //             "amount": "0.0050"
    //         },
    //         "warnings": [
    //             {
    //                 "details": "An error occurred while processing a video output with tag video-0-0-0, but it was ignored due to the soft-fail behavior enabled",
    //                 "message": "video processing error ignored due to soft fail",
    //                 "tag": "video-0-0-0"
    //             }
    //         ]
    //     }
    // ]    

    // one url, multiple names
    // texts = [
    //     {
    //         "tag": "speech_to_text-0-0-0",
    //         "profile": null,
    //         "user_tag": null,
    //         "storage": {
    //             "zip": {
    //                 "region": "us-east-1",
    //                 "bucket": "qencode-temp-us-east-1",
    //                 "host": "stage-us-east-1-1-storage-aws.qencode.com"
    //             },
    //             "format": "speech_to_text",
    //             "host": "storage-aws-us-east-1.qencode.com",
    //             "names": {
    //                 "transcript": "723.txt",
    //                 "srt": "723.srt"
    //             },
    //             "key": "1-0",
    //             "path": "a459d8933242bca4527faa022844229e/speech_to_text/1-0",
    //             //"type": "local",
    //             "type": "qencode_s3",
    //             "expire": "2024-07-17 17:31:23",
    //             "timestamp": "2024-07-17 11:41:03"
    //         },
    //         "url": "https://storage-aws-us-east-1.qencode.com/a459d8933242bca4527faa022844229e/speech_to_text/1-0",
    //         "meta": {
    //             "language": "en",
    //             "entropy_threshold": 2.8,
    //             "beam_size": 9,
    //             "max_context": 128,
    //             "model": "large",
    //             "mode": "accuracy"
    //         },
    //         "duration": "4.096",
    //         "size": "0.000138283",
    //         "output_format": "speech_to_text",
    //         "error": false,
    //         "error_description": null,
    //         "cost": {
    //             "currency": "USD",
    //             "code": 840,
    //             "amount": "0.0050"
    //         }
    //     }
    // ]


    // muliple dest
    // texts = [
    //     {
    //         "tag": "speech_to_text_proxy-1-0-0",
    //         "profile": null,
    //         "user_tag": null,
    //         "storage": [
    //             {
    //                 "format": "speech_to_text",
    //                 "region": "us-west",
    //                 "bucket": "test-qa-2022-11-09",
    //                 "host": "qencode.com",
    //                 "names": {
    //                     "json": "timestamps.json",
    //                     "transcript": "transcript.txt",
    //                     "vtt": "subtitles.vtt",
    //                     "srt": "subtitles.srt"
    //                 },
    //                 "key": "stt-1",
    //                 "error": false,
    //                 "scheme": "https",
    //                 "type": "qencode_s3",
    //                 // "type": 'local',
    //                 "expire": null,
    //                 "timestamp": "2024-07-25 14:37:26"
    //             },
    //             {
    //                 "format": "speech_to_text",
    //                 "region": "us-west",
    //                 "bucket": "test-qa-2022-11-09",
    //                 "host": "qencode.com",
    //                 "names": {
    //                     "json": "timestamps.json",
    //                     "transcript": "transcript.txt",
    //                     "vtt": "subtitles.vtt",
    //                     "srt": "subtitles.srt"
    //                 },
    //                 "key": "stt-2",
    //                 "error": false,
    //                 "scheme": "https",
    //                 "type": "qencode_s3",
    //                 "expire": null,
    //                 "timestamp": "2024-07-25 14:37:26"
    //             }
    //         ],
    //         "url": [
    //             "https://test-qa-2022-11-09.media-storage.us-west.qencode.com/stt-1",
    //             "https://test-qa-2022-11-09.media-storage.us-west.qencode.com/stt-2"
    //         ],
    //         "meta": {
    //             "language": "auto",
    //             "entropy_threshold": 2.8,
    //             "beam_size": 5,
    //             "max_context": 128,
    //             "model": "large",
    //             "mode": "accuracy"
    //         },
    //         "duration": "30",
    //         "size": "0.00347042",
    //         "output_format": "speech_to_text",
    //         "error": false,
    //         "error_description": null,
    //         "cost": {
    //             "currency": "USD",
    //             "code": 840,
    //             "amount": "0.0026"
    //         }
    //     }
    // ]    

    // videos mult dest
    // videos = [
    //     {
    //         "tag": "repack-0-0",
    //         "profile": null,
    //         "user_tag": null,
    //         "storage": [
    //             {
    //                 "format": "mp4",
    //                 "region": "us-west",
    //                 "bucket": "test-qa-2022-11-09",
    //                 "host": "qencode.com",
    //                 "key": "multiple/output-1.mp4",
    //                 "error": false,
    //                 "scheme": "https",
    //                 "type": "qencode_s3",
    //                 "expire": null,
    //                 "timestamp": "2024-07-26 11:59:32"
    //             },
    //             {
    //                 "format": "mp4",
    //                 "region": "us-west",
    //                 "bucket": "test-qa-2022-11-09",
    //                 "host": "qencode.com",
    //                 "key": "multiple/output-2.mp4",
    //                 "error": false,
    //                 "scheme": "https",
    //                 //"type": "custom_s3",
    //                 "type": "qencode_s3",
    //                 "expire": null,
    //                 "timestamp": "2024-07-26 11:59:32"
    //             }
    //         ],
    //         "url": [
    //             "https://test-qa-2022-11-09.media-storage.us-west.qencode.com/multiple/output-1.mp4",
    //             "https://test-qa-2022-11-09.media-storage.us-west.qencode.com/multiple/output-2.mp4"
    //         ],
    //         "bitrate": 944,
    //         "meta": {
    //             "framerate": "24000/1001",
    //             "codec": "h264",
    //             "dar": "16:9",
    //             "aspect_ratio": 1,
    //             "sar": "1:1",
    //             "bitrate": "989378",
    //             "audio_codec": "aac"
    //         },
    //         "duration": "30.0717",
    //         "size": "3.54675",
    //         "output_format": "repack",
    //         "error": false,
    //         "error_description": null,
    //         "cost": {
    //             "currency": "USD",
    //             "code": 840,
    //             "amount": "0.0011"
    //         }
    //     }
    // ]

    // one destination
    // videos = [
    //     {
    //         "tag": "repack-0-0",
    //         "profile": null,
    //         "user_tag": null,
    //         "storage": 
    //             {
    //                 "format": "mp4",
    //                 "region": "us-west",
    //                 "bucket": "test-qa-2022-11-09",
    //                 "host": "qencode.com",
    //                 "key": "multiple/output-1.mp4",
    //                 "error": false,
    //                 "scheme": "https",
    //                 //"type": "custom",
    //                 "type": "qencode_s3",
    //                 "expire": null,
    //                 "timestamp": "2024-07-26 11:59:32"
    //             }
    //         ,
    //         "url": "https://test-qa-2022-11-09.media-storage.us-west.qencode.com/multiple/output-1.mp4",
    //         "bitrate": 944,
    //         "meta": {
    //             "framerate": "24000/1001",
    //             "codec": "h264",
    //             "dar": "16:9",
    //             "aspect_ratio": 1,
    //             "sar": "1:1",
    //             "bitrate": "989378",
    //             "audio_codec": "aac"
    //         },
    //         "duration": "30.0717",
    //         "size": "3.54675",
    //         "output_format": "repack",
    //         "error": false,
    //         "error_description": null,
    //         "cost": {
    //             "currency": "USD",
    //             "code": 840,
    //             "amount": "0.0011"
    //         }
    //     }
    // ]



    const navigate = useNavigate();
    
    const handleDownload = (url) => {

        // это для hls, mpeg-dash и hls_audio если что
        // if (data.storage.type === "qencode_s3" && $scope.download_zip_url_template) {
        //     let path = data.storage.key + '/' + data.storage.playlist + '?zip=1'
        //     url = $scope.download_zip_url_template.replace('<bucket_name>', data.storage.bucket).replace('<region_name>', data.storage.region) + '/' + path
        // }

        // нужно анализировать объект storage  в статусе, там будет массив zip, на основе его нужно сформировать 
        // url для скачивания архива. примерно так:
        // var playlist = data.storage.playlist;
        // var path = data.storage.path;
        // if (data.storage.zip !== undefined && data.storage.zip !== null) {
        //     url = 'https://' + data.storage.zip.host + '/storage/' + data.storage.zip.region + '/' + data.storage.zip.bucket + '/' + path + '/' + playlist + '?zip=1';
        // }

        if (!url) {
            console.warn("No URL provided for download.");
            return;
        }
    
        console.log("Download: ", url);
    
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', url.split('/').pop()); // Use the file name from the URL

        // https://few-files-in-bucket.media-storage.eu-central.qencode.com/test_tags/playlist.m3u8     
        link.target = '_blank'; // Ensure it opens in a new tab
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };
        
    

    const handleViewInStorage = ({storage, url}) => {
        //console.log("storage: ", storage);
        //console.log("url: ", url)
    
        const { region, bucket, key } = storage;
        // const url = `/storage/${region}/${bucket}?itemKey=${key}`;
        // const qencodeMediaStoragePageUrl = `/storage/${region}/${bucket}?itemKey=${key}`;

        // with the key name
        const qencodeMediaStoragePageUrl = `/storage/list/bucket?region=${region}&name=${bucket}&item=${key}`;

        // no key name 
        //const qencodeMediaStoragePageUrl = `/storage/list/bucket?region=${region}&name=${bucket}`;
    
        // Navigate to the constructed URL
        navigate(qencodeMediaStoragePageUrl);

        // in case need to open in new tav
        // window.open(url, '_blank');
    };

    const handlePlay = (url) => {
        console.log("Play: ", url)
    }

    // Define menuItems as a function that takes a member's uid and returns the menu items
    const getMenuItems = ({ url, download_url, storage, onDownload, onView }) => {

        //console.log("download_url: ", download_url)

        const menuItems = [];
    
        // Conditionally add the "Download" menu item if the URL is provided
        // if (url) {
        //     menuItems.push({
        //         icon: <Download label="Download" />,
        //         onClick: () => onDownload(url),
        //         text: "Download"
        //     });
        // }

        if (download_url) {
            menuItems.push({
                icon: <Download label="Download" />,
                onClick: () => onDownload(download_url),
                text: "Download"
            });
        }        
    
        // Conditionally add the "View in Storage" menu item if the storage type is "qencode_s3"
        if (storage?.type === "qencode_s3") {
            menuItems.push({
                icon: <Storage label="View in Storage" />,
                onClick: () => onView({ storage, url }),
                text: "View in Storage"
            });
        }
    
        return menuItems;
    };
    


    // Group videos by URL
    const groupVideosByUrl = (videos) => {
        const groupedVideos = videos.reduce((acc, video) => {
            const url = Array.isArray(video.url) ? video.url.join() : video.url;
            if (!acc[url]) {
                acc[url] = { ...video, multipleResolutions: [], totalSize: 0 };
            }
            acc[url].multipleResolutions.push({
                width: video.meta.width,
                height: video.meta.height,
                size: video.size
            });
            acc[url].totalSize += parseFloat(video.size);
            return acc;
        }, {});
    
        return Object.values(groupedVideos).map(video => {
            const totalSize = video.totalSize.toFixed(2);
            return {
                ...video,
                size: totalSize
            };
        });
    };
    

    const groupedVideos = groupVideosByUrl(videos);    

    //console.log("groupedVideos: ", groupedVideos)
    
    const renderMultipleUrls = ({ urls, type, storage }) => {
        // Convert storage to array if it is not already an array
        const storageArray = Array.isArray(storage) ? storage : [storage];
    
        return (
            <div className={styles.multipleUrlContainer}>
                <table>
                    <thead>
                        <tr>
                            <th>URL</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {urls.map((url, index) => {
                            // Find the corresponding storage item based on the URL
                            const matchedStorage = storageArray.find(item => url.endsWith(item.key));
                            return (
                                <tr key={index}>
                                    <td>
                                        <div className={styles.cellContainer}> 
                                            <div className={styles.linkContainer}>
                                                <a className={styles.link} href={url} target="_blank" rel="noreferrer noopener">
                                                    {url}
                                                </a>
                                            </div>    
                                            <CopyButton contentToCopy={url} />      
                                        </div>
                                    </td>    
                                    <td>
                                        <div className={styles.cellContainer}>
                                            <div className={styles.actionIcons}>
                                                {(type !== 'image' && type !== 'text') && (
                                                    <Tippy content="Play">
                                                        <div onClick={() => handlePlay(url)} className={styles.actionIconContainer}> <PlayFilled label='Play'/> </div>
                                                    </Tippy>
                                                )}
                                                <Tippy content="Download">
                                                    <div onClick={() => handleDownload(url)} className={styles.actionIconContainer}> <Download label='Download'/> </div>
                                                </Tippy>      
                                                {matchedStorage?.type === 'qencode_s3' && (
                                                    <Tippy content="View in Storage">
                                                        <div onClick={() => handleViewInStorage({ storage: matchedStorage, url })} className={styles.actionIconContainer}> <Storage label='View in Storage'/> </div>
                                                    </Tippy>
                                                )}                                                               
                                            </div>  
                                        </div>                        
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>    
            </div>
        );
    };
    


    // used for speech-to-text
    const renderMultipleUrlsSpeechToText = ({ urls, storage }) => {

        const formatMapping = {
            transcript: "TXT",
            srt: "SRT",
            vtt: "VTT",
            json: "JSON"
        };    

        // Convert storage to array if it is not already an array
        const storageArray = Array.isArray(storage) ? storage : [storage];
        // If url is a string, convert it to array
        const urlArray = Array.isArray(urls) ? urls : [urls];
    
        return (
            <div className={styles.multipleUrlContainer}>
                <table>
                    <thead>
                        <tr>
                            <th>FORMAT</th>
                            <th>URL</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {storageArray.map((storageItem, index) => (
                            storageItem.names && Object.keys(storageItem.names).map((key) => {
                                const url = `${urlArray[index]}/${storageItem.names[key]}`;
                                // Find the corresponding storage item based on the URL
                                const matchedStorage = storageArray.find(item => urlArray[index].endsWith(item.key));
                                return (
                                    <tr key={`${index}-${key}`}>
                                        {/* <td className={styles.cellContainer}>{key.toUpperCase()}</td> */}
                                        <td className={styles.cellContainer}>{formatMapping[key] || key.toUpperCase()}</td>
                                        <td>
                                            <div className={styles.cellContainer}>
                                                <div className={styles.linkContainer}>
                                                    <a className={styles.link} href={url} target="_blank" rel="noreferrer noopener">
                                                        {url}
                                                    </a>
                                                </div>
                                                <CopyButton contentToCopy={url}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div className={styles.cellContainer}>
                                                <div className={styles.actionIcons}>
                                                    <Tippy content="Download">
                                                        <div onClick={() => handleDownload(url)} className={styles.actionIconContainer}> <Download label='Download'/> </div>
                                                    </Tippy>      
                                                    {
                                                        matchedStorage?.type === 'qencode_s3' &&
                                                        <Tippy content="View in Storage">
                                                            <div onClick={() => handleViewInStorage({storage: matchedStorage, url})} className={styles.actionIconContainer}> <Storage label='View in Storage'/> </div>
                                                        </Tippy>                                                          
                                                    }                                                              
                                                </div>  
                                            </div>                        
                                        </td>                                        
                                    </tr>
                                );
                            })
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };
    
    const renderMultipleResolutions = (resolutions) => (
        <div className={styles.multipleUrlContainer}>
            <table>
                <thead>
                    <tr>
                        <th>RESOLUTION</th>
                        <th>SIZE</th>
                    </tr>
                </thead>
                <tbody>
                {resolutions.map((resolution, index) => (
                    <tr key={index}>
                        <td>
                            <div className={styles.cellContainer}>
                                <>{resolution?.width && resolution.height ? <>{resolution?.width} x {resolution?.height}</> : <>—</>}</>    
                            </div>                            
                        </td>    
                        <td>
                            <div className={styles.cellContainer}>
                                {formatSize(resolution?.size, 'MB') || '—'}
                            </div>                            
                        </td>
                    </tr>
                ))}                 
                </tbody>
            </table>    
        </div>
    );         
    

    // get two lists of different type of thumbnails
    const individualThumbnails = images.filter(image => image.output_format === 'thumbnail');
    const intervalThumbnails = images.filter(image => image.output_format === 'thumbnails');
    const errorImages = images.filter(image => image.error === true);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div className={styles.errorMessage}>{error}</div>;
    }

    return (
        <div className={styles.outputFilesContainer}>

            {
                (groupedVideos && groupedVideos.length > 0) &&
                <div className={styles.outputBlock}>
                    <div className={styles.outputBlockTitle}>Video</div>
                    <div className="tableContainer">
                        <table className={`standardTable`}>
                            <thead>
                                <tr>
                                    <th>FORMAT</th>
                                    <th className='cellNoWrap'>VIDEO CODEC</th>
                                    <th className='cellNoWrap'>AUDIO CODEC</th>
                                    <th>RESOLUTION</th>
                                    <th>SIZE</th>
                                    <th>DURATION</th>
                                    <th>URL</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {groupedVideos.map((outputVideo, index) => (
                                <tr key={index} className="tableRow">
                                    <td className="cellUppercase">
                                        <div className="cellContainer">
                                            <div className='cellLimited'>{outputVideo?.output_format || '—'}</div>
                                            {
                                                (outputVideo?.warnings && outputVideo.warnings.length > 0) &&
                                                <>
                                                {
                                                    outputVideo.warnings[0]?.tag && 
                                                    <>
                                                        {outputVideo.warnings[0]?.message
                                                            ?
                                                            <Tippy content={outputVideo.warnings[0]?.message} maxWidth={164}>
                                                                <a 
                                                                    className={styles.warningTagLink}
                                                                    href={`#warning-tag-${outputVideo.warnings[0]?.tag}`}
                                                                >
                                                                    {/* {outputVideo.warnings[0]?.tag} */}
                                                                    <WarningIcon className={styles.warningIcon}/>
                                                                </a>
                                                            </Tippy>
                                                            :
                                                            <a 
                                                                className={styles.warningTagLink}
                                                                href={`#warning-tag-${outputVideo.warnings[0]?.tag}`}
                                                            >
                                                                <WarningIcon className={styles.warningIcon}/>
                                                            </a>
                                                        }
                                                    </>
                                                }                                                
                                                </>
                                            }
                                        </div>
                                    </td>                        
                                    <td className="cellUppercase">
                                        <div className="cellContainer">{outputVideo?.meta?.codec || '—'}</div>
                                    </td>
                                    <td className="cellUppercase">
                                        <div className="cellContainer">{outputVideo?.meta?.audio_codec || '—'}</div>
                                    </td>
                                    <td className='cellNoWrap'>
                                        <div className="cellContainer">
                                            {
                                                Array.isArray(outputVideo?.multipleResolutions) && outputVideo?.multipleResolutions.length > 1 
                                                ?
                                                <Tippy
                                                    content={renderMultipleResolutions(outputVideo?.multipleResolutions)}
                                                    interactive={true}
                                                    trigger="click"
                                                    theme="table"
                                                    offset={[-10, 5]}
                                                    maxWidth={360}
                                                    allowHTML={true}
                                                    arrow={false}
                                                    placement="bottom-start"
                                                    appendTo={() => document.getElementById('popup-portal')}
                                                >
                                                    <div className={styles.showMultiple}>
                                                         <span className={styles.showMultipleText}>Multiple</span>
                                                         <ChevronBottom className={styles.showMultipleIcon}/>   
                                                    </div>  
                                                </Tippy>                                                
                                                :
                                                <>{outputVideo?.meta?.width && outputVideo?.meta?.height ? <>{outputVideo?.meta?.width} x {outputVideo?.meta?.height}</> : <>—</>}</>
                                            }
                                        </div>
                                    </td>
                                    <td className='cellNoWrap'>
                                        <div className="cellContainer">{formatSize(outputVideo?.size, 'MB') || '—'}</div>
                                    </td>
                                    <td className="cellNoWrap">
                                        <div className="cellContainer">{formatDuration(outputVideo?.duration) || '—'}</div>
                                    </td>

                                    <td className={styles.linkCell}>
                                        <div className='cellContainer'> 
                                            {
                                                Array.isArray(outputVideo?.url) && outputVideo?.url.length > 0 
                                                ?
                                                <Tippy
                                                    content={renderMultipleUrls({urls: outputVideo?.url, storage: outputVideo?.storage})}
                                                    interactive={true}
                                                    trigger="click"
                                                    theme="table"
                                                    offset={[-10, 5]}
                                                    maxWidth={360}
                                                    allowHTML={true}
                                                    arrow={false}
                                                    placement="bottom-start"
                                                    appendTo={() => document.getElementById('popup-portal')}
                                                >
                                                    <div className={styles.showMultiple}>
                                                         <span className={styles.showMultipleText}>Multiple</span>
                                                         <ChevronBottom className={styles.showMultipleIcon}/>   
                                                    </div>  
                                                </Tippy>
                                                :
                                                <>
                                                    { outputVideo?.url && 
                                                        <>
                                                            <div className='cellLimited'>
                                                                <a  className={styles.link}
                                                                    href={outputVideo?.url} 
                                                                    target="_blank" rel="noreferrer noopener"
                                                                >
                                                                        {outputVideo?.url}
                                                                </a>
                                                            </div>     
                                                            <CopyButton contentToCopy={outputVideo?.url} />      
                                                        </>                                                
                                                    }                                                                                         
                                                </>
                                              
                                            }
                                        </div>
                                    </td>                        

                                    <td className="optionButtonsCell">
                                        <div className="cellContainer">
                                            {
                                                // do not show 3 dots menu if there are multiple urls
                                                Array.isArray(outputVideo?.url) && outputVideo?.url.length > 0 
                                                ?<></> 
                                                :
                                                <>
                                                    { outputVideo?.url &&
                                                        <Tippy content="Play">
                                                            <div onClick={() => handlePlay(outputVideo?.url)} className={styles.actionIconContainer}> <PlayFilled label='Play'/> </div>
                                                        </Tippy>                                                       
                                                    }

                                                    { (outputVideo?.url || outputVideo?.storage) &&
                                                        <OptionsMenu menuItems={getMenuItems({url: outputVideo?.url, storage: outputVideo?.storage, download_url: outputVideo?.download_url, onDownload: handleDownload, onView: handleViewInStorage})} />                                                         
                                                    }
                                                                                
                                                </>
                                                 
                                            }                           
                                        </div>
                                    </td>
                                </tr>
                            ))}                    
                            </tbody>
                        </table>    
                    </div>   
                </div>
            }

            {
                (texts && texts.length > 0) &&
                <div className={styles.outputBlock}>
                    <div className={styles.outputBlockTitle}>Speech-to-text</div>
                    <div className="tableContainer">
                        <table className={`standardTable`}>
                            <thead>
                                <tr>
                                    <th>FORMAT</th>
                                    <th>SIZE</th>
                                    <th>DURATION</th>
                                    <th>URL</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {texts.map((outputText, index) => {
                                    const hasMultipleUrls = Array.isArray(outputText?.url) && outputText?.url.length > 1;
                                    const hasMultipleNames = Object.keys(outputText?.storage?.names || {}).length > 1;
                                    return (
                                        <tr key={index} className="tableRow">
                                            <td>
                                                {/* <div className="cellContainer">Speech-to-Text</div> */}

                                                <div className="cellContainer">
                                                    <div className='cellLimited'>Speech-to-Text</div>
                                                    {
                                                        (outputText?.warnings && outputText.warnings.length > 0) &&
                                                        <>
                                                        {
                                                            outputText.warnings[0]?.tag && 
                                                            <>
                                                                {outputText.warnings[0]?.message
                                                                    ?
                                                                    <Tippy content={outputText.warnings[0]?.message} maxWidth={164}>
                                                                        <a 
                                                                            className={styles.warningTagLink}
                                                                            href={`#warning-tag-${outputText.warnings[0]?.tag}`}
                                                                        >
                                                                            {/* {outputText.warnings[0]?.tag} */}
                                                                            <WarningIcon className={styles.warningIcon}/>
                                                                        </a>
                                                                    </Tippy>
                                                                    :
                                                                    <a 
                                                                        className={styles.warningTagLink}
                                                                        href={`#warning-tag-${outputText.warnings[0]?.tag}`}
                                                                    >
                                                                        <WarningIcon className={styles.warningIcon}/>
                                                                    </a>
                                                                }
                                                            </>
                                                        }                                                
                                                        </>
                                                    }
                                                </div>

                                            </td>
                                            <td className="cellUppercase">
                                                <div className="cellContainer">{formatSize(outputText?.size, 'MB') || '—'}</div>
                                            </td>
                                            <td className="cellNoWrap">
                                                <div className="cellContainer">{formatDuration(outputText?.duration) || '—'}</div>
                                            </td>
                                            <td className={styles.linkCell}>
                                                <div className='cellContainer'>
                                                    {hasMultipleUrls || hasMultipleNames ? (
                                                        <Tippy
                                                            content={renderMultipleUrlsSpeechToText({ urls: outputText?.url, storage: outputText?.storage, type: 'text' })}
                                                            interactive={true}
                                                            trigger="click"
                                                            theme="table"
                                                            offset={[-10, 5]}
                                                            maxWidth={360}
                                                            allowHTML={true}
                                                            arrow={false}
                                                            placement="bottom-start"
                                                            appendTo={() => document.getElementById('popup-portal')}
                                                        >
                                                            <div className={styles.showMultiple}>
                                                                <span className={styles.showMultipleText}>Multiple</span>
                                                                <ChevronBottom className={styles.showMultipleIcon} />
                                                            </div>
                                                        </Tippy>
                                                    ) : (
                                                        <>
                                                            <div className='cellLimited'>
                                                                <a
                                                                    className={styles.link}
                                                                    href={`${outputText?.url}/${Object.values(outputText?.storage?.names || {})[0]}`}
                                                                    target="_blank" rel="noreferrer noopener"
                                                                >
                                                                    {`${outputText?.url}/${Object.values(outputText?.storage?.names || {})[0]}`}
                                                                </a>
                                                            </div>
                                                            <CopyButton contentToCopy={`${outputText?.url}/${Object.values(outputText?.storage?.names || {})[0]}`} />
                                                        </>
                                                    )}
                                                </div>
                                            </td>
                                            <td className="optionButtonsCell">
                                                <div className="cellContainer">
                                                    {!hasMultipleUrls && !hasMultipleNames && (
                                                        <OptionsMenu menuItems={getMenuItems({ url: `${outputText?.url}/${Object.values(outputText?.storage?.names || {})[0]}`, storage: outputText?.storage, onDownload: handleDownload, onView: handleViewInStorage })} />
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>    
                    </div>                         
                </div>
            }


            {
                (audios && audios.length > 0) &&
                <div className={styles.outputBlock}>
                    <div className={styles.outputBlockTitle}>Audio</div>
                    <div className="tableContainer">
                        <table className={`standardTable`}>
                            <thead>
                                <tr>
                                    <th className='cellNoWrap'>SYSTEM TAG</th>
                                    <th>FORMAT</th>
                                    <th>CODEC</th>
                                    <th>BITRATE</th>
                                    <th>SIZE</th>
                                    <th>DURATION</th>
                                    <th>URL</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {audios.map((outputAudio, index) => (
                                <tr key={index} className="tableRow">
                                    <td>
                                        {/* <div className="cellContainer">{outputAudio?.tag || '—'}</div> */}

                                        <div className="cellContainer">
                                            <div className='cellLimited'>{outputAudio?.tag || '—'}</div>
                                            {
                                                (outputAudio?.warnings && outputAudio.warnings.length > 0) &&
                                                <>
                                                {
                                                    outputAudio.warnings[0]?.tag && 
                                                    <>
                                                        {outputAudio.warnings[0]?.message
                                                            ?
                                                            <Tippy content={outputAudio.warnings[0]?.message} maxWidth={164}>
                                                                <a 
                                                                    className={styles.warningTagLink}
                                                                    href={`#warning-tag-${outputAudio.warnings[0]?.tag}`}
                                                                >
                                                                    {/* {outputAudio.warnings[0]?.tag} */}
                                                                    <WarningIcon className={styles.warningIcon}/>
                                                                </a>
                                                            </Tippy>
                                                            :
                                                            <a 
                                                                className={styles.warningTagLink}
                                                                href={`#warning-tag-${outputAudio.warnings[0]?.tag}`}
                                                            >
                                                                <WarningIcon className={styles.warningIcon}/>
                                                            </a>
                                                        }
                                                    </>
                                                }                                                
                                                </>
                                            }
                                        </div>

                                    </td>                                       
                                    <td className="cellUppercase">
                                        <div className="cellContainer">{outputAudio?.output_format || '—'}</div>
                                    </td>                        
                                    <td className="cellUppercase">
                                        <div className="cellContainer">{outputAudio?.meta?.codec || '—'}</div>
                                    </td>
                                    <td>
                                        <div className="cellContainer">{formatBitrate(outputAudio?.meta?.bit_rate) || '—'}</div>
                                    </td>
                                    <td className='cellNoWrap'>
                                        <div className="cellContainer">{formatSize(outputAudio?.size, 'MB') || '—'}</div>
                                    </td>
                                    <td className="cellNoWrap">
                                        <div className="cellContainer">{formatDuration(outputAudio?.duration) || '—'}</div>
                                    </td>

                                    <td className={styles.linkCell}>
                                        <div className='cellContainer'> 
                                            {
                                                Array.isArray(outputAudio?.url) && outputAudio?.url.length > 0 
                                                ?
                                                <Tippy
                                                    content={renderMultipleUrls({urls: outputAudio?.url, storage: outputAudio?.storage})}
                                                    interactive={true}
                                                    trigger="click"
                                                    theme="table"
                                                    offset={[-10, 5]}
                                                    maxWidth={360}
                                                    allowHTML={true}
                                                    arrow={false}
                                                    placement="bottom-start"
                                                    appendTo={() => document.getElementById('popup-portal')}
                                                >
                                                    <div className={styles.showMultiple}>
                                                         <span className={styles.showMultipleText}>Multiple</span>
                                                         <ChevronBottom className={styles.showMultipleIcon}/>   
                                                    </div>  
                                                </Tippy>
                                                :
                                                <>
                                                    <div className='cellLimited'>
                                                        <a  className={styles.link}
                                                            href={outputAudio?.url} 
                                                            target="_blank" rel="noreferrer noopener"
                                                        >
                                                                {outputAudio?.url}
                                                        </a>
                                                    </div>     
                                                    <CopyButton contentToCopy={outputAudio?.url} />                                                                                           
                                                </>
                                              
                                            }
                                        </div>
                                    </td>                        

                                    <td className="optionButtonsCell">
                                        <div className="cellContainer">
                                            {
                                                // do not show 3 dots menu if there are multiple urls
                                                Array.isArray(outputAudio?.url) && outputAudio?.url.length > 0 
                                                ?<></> 
                                                :
                                                <>
                                                    <Tippy content="Play">
                                                        <div onClick={() => handlePlay(outputAudio?.url)} className={styles.actionIconContainer}> <PlayFilled label='Play'/> </div>
                                                    </Tippy>                                                
                                                    <OptionsMenu menuItems={getMenuItems({url: outputAudio?.url, storage: outputAudio?.storage, onDownload: handleDownload, onView: handleViewInStorage})} />  
                                                </>
                                                 
                                            }                           
                                        </div>
                                    </td>
                                </tr>
                            ))}                    
                            </tbody>
                        </table>    
                    </div>                       
                </div>                
            }

            {
                (images && images.length > 0) &&
                <div className={styles.outputBlock}>
                    <div className={styles.outputBlockTitle}>Image</div>

                    {(individualThumbnails && individualThumbnails.length > 0) && 
                        <div>
                            <div className={styles.outputBlockSubTitle}>Individual Thumbnails</div>
                            <div className="tableContainer">
                                <table className={`standardTable`}>
                                    <thead>
                                        <tr>
                                            <th className='cellNoWrap'>SYSTEM TAG</th>
                                            <th>FORMAT</th>
                                            <th>RESOLUTION</th>
                                            <th className='cellNoWrap'>Time, %</th>
                                            <th className='cellNoWrap'>Time, s</th>
                                            <th className='cellNoWrap'>USER TAG</th>
                                            <th>URL</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {individualThumbnails.map((outputImage, index) => (
                                        <tr key={index} className="tableRow">
                                            <td>
                                                {/* <div className="cellContainer">{outputImage?.tag || '—'}</div> */}

                                                <div className="cellContainer">
                                                    <div className='cellLimited'>{outputImage?.tag || '—'}</div>
                                                    {
                                                        (outputImage?.warnings && outputImage.warnings.length > 0) &&
                                                        <>
                                                        {
                                                            outputImage.warnings[0]?.tag && 
                                                            <>
                                                                {outputImage.warnings[0]?.message
                                                                    ?
                                                                    <Tippy content={outputImage.warnings[0]?.message} maxWidth={164}>
                                                                        <a 
                                                                            className={styles.warningTagLink}
                                                                            href={`#warning-tag-${outputImage.warnings[0]?.tag}`}
                                                                        >
                                                                            {/* {outputImage.warnings[0]?.tag} */}
                                                                            <WarningIcon className={styles.warningIcon}/>
                                                                        </a>
                                                                    </Tippy>
                                                                    :
                                                                    <a 
                                                                        className={styles.warningTagLink}
                                                                        href={`#warning-tag-${outputImage.warnings[0]?.tag}`}
                                                                    >
                                                                        <WarningIcon className={styles.warningIcon}/>
                                                                    </a>
                                                                }
                                                            </>
                                                        }                                                
                                                        </>
                                                    }
                                                </div>

                                            </td>                                       
                                            <td className="cellUppercase">
                                                <div className="cellContainer">{outputImage?.meta?.format || '—'}</div>
                                            </td>                        
                                            <td>
                                                <div className="cellContainer">
                                                    <>{outputImage?.meta?.width && outputImage?.meta?.height ? <>{outputImage?.meta?.width} x {outputImage?.meta?.height}</> : <>—</>}</>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="cellContainer">{outputImage?.meta?.time_fraction && Math.round(outputImage?.meta?.time_fraction * 100)}</div>
                                            </td>                                            
                                            <td>
                                                <div className="cellContainer">{outputImage?.meta?.time && Math.round(outputImage?.meta?.time)}</div>
                                                {/* <div className="cellContainer">{formatToPercentage(outputImage?.meta?.time)}</div> */}
                                            </td>
                                            <td className="cellNoWrap">
                                                <div className="cellContainer">{outputImage?.user_tag ? <>{outputImage?.user_tag}</> : <>—</>}</div>
                                            </td>

                                            <td className={styles.linkCell}>
                                                <div className='cellContainer'> 
                                                    {
                                                        Array.isArray(outputImage?.url) && outputImage?.url.length > 0 
                                                        ?
                                                        <Tippy
                                                            content={renderMultipleUrls({urls: outputImage?.url, type: 'image', storage: outputImage?.storage})}
                                                            interactive={true}
                                                            trigger="click"
                                                            theme="table"
                                                            offset={[-10, 5]}
                                                            maxWidth={360}
                                                            allowHTML={true}
                                                            arrow={false}
                                                            placement="bottom-start"
                                                            appendTo={() => document.getElementById('popup-portal')}
                                                        >
                                                            <div className={styles.showMultiple}>
                                                                <span className={styles.showMultipleText}>Multiple</span>
                                                                <ChevronBottom className={styles.showMultipleIcon}/>   
                                                            </div>  
                                                        </Tippy>
                                                        :
                                                        <>
                                                            <div className='cellLimited'>
                                                                <a  className={styles.link}
                                                                    href={outputImage?.url} 
                                                                    target="_blank" rel="noreferrer noopener"
                                                                >
                                                                        {outputImage?.url}
                                                                </a>
                                                            </div>     
                                                            <CopyButton contentToCopy={outputImage?.url} />                                                                                           
                                                        </>
                                                    
                                                    }
                                                </div>
                                            </td>                        

                                            <td className="optionButtonsCell">
                                                <div className="cellContainer">
                                                    {
                                                        // do not show 3 dots menu if there are multiple urls
                                                        Array.isArray(outputImage?.url) && outputImage?.url.length > 0 
                                                        ?<></> 
                                                        :
                                                        <>                                              
                                                            <OptionsMenu menuItems={getMenuItems({url: outputImage?.url, storage: outputImage?.storage, onDownload: handleDownload, onView: handleViewInStorage})} />   
                                                        </>
                                                        
                                                    }                           
                                                </div>
                                            </td>
                                        </tr>
                                    ))}                    
                                    </tbody>
                                </table>    
                            </div>                                
                        </div>
                    }

                    {(intervalThumbnails && intervalThumbnails.length > 0) && 
                        <div>
                            <div className={styles.outputBlockSubTitle}>Interval Thumbnails</div>
                            <div className="tableContainer">
                                <table className={`standardTable`}>
                                    <thead>
                                        <tr>
                                            <th className='cellNoWrap'>SYSTEM TAG</th>
                                            <th>FORMAT</th>
                                            <th>RESOLUTION</th>
                                            <th>INTERVAL</th>
                                            <th>USER TAG</th>
                                            <th>URL</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {intervalThumbnails.map((outputImage, index) => (
                                        <tr key={index} className="tableRow">
                                            <td>
                                                {/* <div className="cellContainer">{outputImage?.tag || '—'}</div> */}

                                                <div className="cellContainer">
                                                    <div className='cellLimited'>{outputImage?.tag || '—'}</div>
                                                    {
                                                        (outputImage?.warnings && outputImage.warnings.length > 0) &&
                                                        <>
                                                        {
                                                            outputImage.warnings[0]?.tag && 
                                                            <>
                                                                {outputImage.warnings[0]?.message
                                                                    ?
                                                                    <Tippy content={outputImage.warnings[0]?.message} maxWidth={164}>
                                                                        <a 
                                                                            className={styles.warningTagLink}
                                                                            href={`#warning-tag-${outputImage.warnings[0]?.tag}`}
                                                                        >
                                                                            {/* {outputImage.warnings[0]?.tag} */}
                                                                            <WarningIcon className={styles.warningIcon}/>
                                                                        </a>
                                                                    </Tippy>
                                                                    :
                                                                    <a 
                                                                        className={styles.warningTagLink}
                                                                        href={`#warning-tag-${outputImage.warnings[0]?.tag}`}
                                                                    >
                                                                        <WarningIcon className={styles.warningIcon}/>
                                                                    </a>
                                                                }
                                                            </>
                                                        }                                                
                                                        </>
                                                    }
                                                </div>
                                                
                                            </td>                                       
                                            <td className="cellUppercase">
                                                <div className="cellContainer">{outputImage?.meta?.format || '—'}</div>
                                            </td>                        
                                            <td>
                                                <div className="cellContainer">
                                                    <>{outputImage?.meta?.width && outputImage?.meta?.height ? <>{outputImage?.meta?.width} x {outputImage?.meta?.height}</> : <>—</>}</>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="cellContainer">{outputImage?.meta?.interval ? <>{outputImage?.meta?.interval} sec.</> : <>—</>}</div>
                                            </td>
                                            <td className="cellNoWrap">
                                                <div className="cellContainer">{outputImage?.user_tag ? <>{outputImage?.user_tag}</> : <>—</>}</div>
                                            </td>

                                            <td className={styles.linkCell}>
                                                <div className='cellContainer'> 
                                                    {
                                                        Array.isArray(outputImage?.url) && outputImage?.url.length > 0 
                                                        ?
                                                        <Tippy
                                                            content={renderMultipleUrls({urls: outputImage?.url, type: 'image', storage: outputImage?.storage})}
                                                            interactive={true}
                                                            trigger="click"
                                                            theme="table"
                                                            offset={[-10, 5]}
                                                            maxWidth={360}
                                                            allowHTML={true}
                                                            arrow={false}
                                                            placement="bottom-start"
                                                            appendTo={() => document.getElementById('popup-portal')}
                                                        >
                                                            <div className={styles.showMultiple}>
                                                                <span className={styles.showMultipleText}>Multiple</span>
                                                                <ChevronBottom className={styles.showMultipleIcon}/>   
                                                            </div>  
                                                        </Tippy>
                                                        :
                                                        <>
                                                            <div className='cellLimited'>
                                                                <a  className={styles.link}
                                                                    href={outputImage?.url} 
                                                                    target="_blank" rel="noreferrer noopener"
                                                                >
                                                                        {outputImage?.url}
                                                                </a>
                                                            </div>     
                                                            <CopyButton contentToCopy={outputImage?.url} />                                                                                           
                                                        </>
                                                    
                                                    }
                                                </div>
                                            </td>                        

                                            <td className="optionButtonsCell">
                                                <div className="cellContainer">
                                                    {
                                                        // do not show 3 dots menu if there are multiple urls
                                                        Array.isArray(outputImage?.url) && outputImage?.url.length > 0 
                                                        ?<></> 
                                                        :
                                                        <>                                              
                                                            <OptionsMenu menuItems={getMenuItems({url: outputImage?.url, storage: outputImage?.storage, onDownload: handleDownload, onView: handleViewInStorage})} />   
                                                        </>
                                                        
                                                    }                           
                                                </div>
                                            </td>
                                        </tr>
                                    ))}                    
                                    </tbody>
                                </table>    
                            </div>                                
                        </div>
                    }                    

                    {(errorImages && errorImages.length > 0) && 
                        <div>
                            <div className="tableContainer">
                                <table className={`standardTable`}>
                                    <thead>
                                        <tr>
                                            <th className='cellNoWrap'>SYSTEM TAG</th>
                                            <th>FORMAT</th>
                                            <th>RESOLUTION</th>
                                            <th>TIME/INTERVAL</th>
                                            <th>USER TAG</th>
                                            <th>URL</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {errorImages.map((outputImage, index) => (
                                        <tr key={index} className="tableRow">

                                            <td className={styles.sysTagColumn}>
                                                {/* <div className="cellContainer">{outputImage?.tag || '—'}</div> */}

                                                <div className="cellContainer">
                                                    <div className='cellLimited'>{outputImage?.tag || '—'}</div>
                                                    {
                                                        (outputImage?.warnings && outputImage.warnings.length > 0) &&
                                                        <>
                                                        {
                                                            outputImage.warnings[0]?.tag && 
                                                            <>
                                                                {outputImage.warnings[0]?.message
                                                                    ?
                                                                    <Tippy content={outputImage.warnings[0]?.message} maxWidth={164}>
                                                                        <a 
                                                                            className={styles.warningTagLink}
                                                                            href={`#warning-tag-${outputImage.warnings[0]?.tag}`}
                                                                        >
                                                                            {/* {outputImage.warnings[0]?.tag} */}
                                                                            <WarningIcon className={styles.warningIcon}/>
                                                                        </a>
                                                                    </Tippy>
                                                                    :
                                                                    <a 
                                                                        className={styles.warningTagLink}
                                                                        href={`#warning-tag-${outputImage.warnings[0]?.tag}`}
                                                                    >
                                                                        <WarningIcon className={styles.warningIcon}/>
                                                                    </a>
                                                                }
                                                            </>
                                                        }                                                
                                                        </>
                                                    }
                                                </div>
                                                
                                            </td>     

                                            <td className="cellUppercase">
                                                <div className="cellContainer">{outputImage?.meta?.format || '—'}</div>
                                            </td>                        
                                            <td>
                                                <div className="cellContainer">
                                                    <>{outputImage?.meta?.width && outputImage?.meta?.height ? <>{outputImage?.meta?.width} x {outputImage?.meta?.height}</> : <>—</>}</>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="cellContainer">{outputImage?.meta?.interval ? <>{outputImage?.meta?.interval} sec.</> : <>—</>}</div>
                                            </td>
                                            <td className="cellNoWrap">
                                                <div className="cellContainer">{outputImage?.user_tag ? <>{outputImage?.user_tag}</> : <>—</>}</div>
                                            </td>

                                            <td className={styles.linkCell}>
                                                <div className='cellContainer'> 
                                                    —
                                                </div>
                                            </td>                        

                                            <td className="optionButtonsCell">
                                                <div className="cellContainer">
                      
                                                </div>
                                            </td>                  

                                        </tr>
                                    ))}                    
                                    </tbody>
                                </table>    
                            </div>                                
                        </div>
                    }    
                     
                </div>              
            }            

        </div>
    );
};


    // <PopupContainer popupContent={renderPopupContent(outputVideo?.url)}>
    //     <div className={styles.showMultiple}>
    //         <span className={styles.showMultipleText}>Multiple</span>
    //         <ChevronBottom className={styles.showMultipleIcon}/>   
    //     </div>                                                    
    // </PopupContainer>


    // const renderPopupContent = (urls) => (
    //     <div className={styles.popupContent}>
    //         {urls.map((url, index) => (
    //             <div key={index}>
    //                 <a href={url} target="_blank" rel="noopener noreferrer" className={styles.link}>
    //                     {url}
    //                 </a>
    //             </div>
    //         ))}
    //     </div>
    // );