import React, { useState, useEffect, useRef } from 'react';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import PropTypes from 'prop-types';
import styles from './InputField.module.css';

import { Label } from '../Label'

import { EyeOpen, EyeClose } from '../../icons'

export const InputField = React.forwardRef(({ 
  className, variant, name, maxLength, type, 
  placeholder, state, size, label, help, icon, trailingIcon,
  inputText, hint, browseLink,
  onChange, onFocus: propOnFocus, onBlur: propOnBlur, 
  autocomplete
}, ref) => {

  const variantClassName = styles[variant];

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [stateProp, setStateProp] = useState(state);

  const localRef = useRef(); // Local ref for the input element
  const inputRef = ref || localRef; // Use the forwarded ref or create a new one

  // focus and blur
  const handleFocus = (e) => {
    if (state !== 'error') {
      setStateProp('focused');
    }
    if (propOnFocus) {
      propOnFocus(e); // Call the parent component's onFocus handler
    }
  };

  const handleBlur = (e) => {
    if (state !== 'error') {
      setStateProp('default');
    }
    if (propOnBlur) {
      propOnBlur(e); // Call the parent component's onBlur handler
    }
  };

  // pass visibility toggle
  const handlePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleBrowseClick = (e) => {
    e.preventDefault(); // Prevent default behavior of the link
    if (inputRef.current) {
        inputRef.current.focus();
    }
  };

  const handleToggleClick = (e) => {
    e.preventDefault(); // Prevent default behavior of the link
    if (inputRef.current) {
        inputRef.current.focus();
    }
  };  

  const inputType = type === 'password' && isPasswordVisible ? 'text' : type;
  const passwordIcon = isPasswordVisible ? <EyeClose className={styles.icon} /> : <EyeOpen className={styles.icon} />;
  const isDisabled = stateProp === 'disabled';

  const getIconComponent = (icon) => {
    return React.cloneElement(icon, { className: styles.icon });
  };

  const getTrailingIconComponent = (icon) => {
    return React.cloneElement(icon, { className: styles.trailingIcon });
  };

  useEffect(() => {
    setStateProp(state)
  }, [state]);

  // Conditionally add autocomplete attribute
  const inputProps = {
    ref: inputRef,
    value: inputText,
    onChange: onChange,
    name: name,
    maxLength: maxLength,
    placeholder: placeholder || '',
    type: inputType,
    onFocus: handleFocus,
    onBlur: handleBlur,
    disabled: isDisabled, // Disable the input if the state is 'disabled'
    ...(autocomplete ? { autoComplete: autocomplete } : {}), // Conditionally add autocomplete
  };  

  // const inputElement = (
  //     <input
  //       //ref={ref} // Forward the ref here
  //       ref={inputRef} // Forward the ref here
  //       value={inputText}
  //       onChange={onChange}
  //       name={name}
  //       maxLength={maxLength}
  //       // placeholder={placeholder || "Enter text"}
  //       placeholder={placeholder || ""}
  //       type={inputType}
  //       onFocus={handleFocus}
  //       onBlur={handleBlur}
  //       disabled={isDisabled} // Disable the input if the state is 'disabled'
  //     />
  // );

  const inputElement = <input {...inputProps} />;

  const onPhoneChange = (value) => {
    const data = {
      target: {
        name: name,
        value: value
      }
    }
    onChange(data)
  }

  const inputElementTel = (
    <PhoneInput
      //ref={ref} // Forward the ref here
      ref={inputRef} // Forward the ref here
      country={'us'}
      value={inputText}
      onChange={(value) => onPhoneChange(value)}
      disabled={isDisabled}
      placeholder={placeholder || ""}
      autocomplete={autocomplete || ""}
      onFocus={handleFocus}
      onBlur={handleBlur}
      inputClass={styles.phone}

      containerClass={styles.phoneInputContainer}
      dropdownClass={styles.phoneInputDropdown}     

      inputProps={{
        name: name,
        ...(autocomplete ? { autoComplete: autocomplete } : {}), // Conditionally add autocomplete
      }}
    />
  );  

  return (
    <div className={`${styles.inputField} ${className} ${variantClassName || ''} ${styles[size]} ${icon ? styles.icon : ''} ${trailingIcon ? styles.trailingIcon : ''}  ${type === 'password' ? styles.hasToggle : ''} ${browseLink ? styles.hasBrowse : ''} ${styles[stateProp]}`}>
      {label && <Label size={size} text={label} help={help} />}

      <div className={styles.inputContainer}>
  
          { icon && getIconComponent(icon)}

          <div className={styles.textWrapper}>
            {/* {inputElement} */}
            {
              inputType === 'tel'
              ? inputElementTel
              : inputElement
            }
          </div>

          {trailingIcon && getTrailingIconComponent(trailingIcon)}

          {browseLink && (
            <div className={styles.browseLink} onMouseDown={handleBrowseClick}>
              <span onClick={browseLink}>
                  Browse
              </span>              
            </div>
          )}

          {type === 'password' && (
            <div className={styles.toggleBtn} onMouseDown={handleToggleClick}>
              <div className={styles.toggleIconContainer} onClick={handlePasswordVisibility}>{passwordIcon}</div>
            </div>
          )}          

          {/* {type === 'password' && (
            <div className={styles.toggleBtn} onClick={handlePasswordVisibility}>
              {passwordIcon}
            </div>
          )} */}
      </div> 

      { hint && <div className={styles.hint}>{hint}</div>}

    </div>
  );  
});

InputField.displayName = 'InputField';

InputField.propTypes = {
  state: PropTypes.oneOf(['default', 'focused', 'hover', 'disabled', 'error']),
  className: PropTypes.string,
  name: PropTypes.string,
  maxLength: PropTypes.number,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['md', 'lg']),
  label: PropTypes.string,
  help: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  icon: PropTypes.element,
  trailingIcon: PropTypes.element,
  // inputText: PropTypes.string,
  inputText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Allow both string and number
  hint: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  browseLink: PropTypes.func, // New prop type for browse link
  autocomplete: PropTypes.string, // Added prop type for autocomplete
};

InputField.defaultProps = {
  state: 'default',
  className: '',
  name: '',
  maxLength: undefined,
  type: 'text',
  // placeholder: 'Enter text',
  placeholder: '',
  size: 'md',
  label: '',
  help: null,
  icon: null,
  trailingIcon: null,
  inputText: '',
  hint: '',
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  browseLink: null, // Default value for browse link
  autocomplete: undefined, // Default value for autocomplete
};



// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import styles from './InputField.module.css';

// import { Label } from '../Label'

// import { EyeOpen, EyeClose } from '../../icons'

// export const InputField = ({ name, maxLength, type, placeholder, state, size = 'md', label, help, icon, inputText, hint, onChange }) => {

//   const [isPasswordVisible, setIsPasswordVisible] = useState(false);

//   const [stateProp, setStateProp] = useState(state);

//   // focus and blur
//   const handleFocus = () => {
//     if (state !== 'error') {
//       setStateProp('focused');
//     }
//   };

//   const handleBlur = () => {
//     if (state !== 'error') {
//       setStateProp('default');
//     }
//   };


//   // pass visibility toggle
//   const handlePasswordVisibility = () => {
//     setIsPasswordVisible(!isPasswordVisible);
//   };

//   const inputType = type === 'password' && isPasswordVisible ? 'text' : type;
//   const passwordIcon = isPasswordVisible ? <EyeClose className={styles.icon} /> : <EyeOpen className={styles.icon} />;
//   const isDisabled = stateProp === 'disabled';

//   const getIconComponent = (icon) => {
//     return React.cloneElement(icon, { className: styles.icon });
//   };

//   useEffect(() => {
//     setStateProp(state)
//   }, [state]);

//   const inputElement = (
//       <input
//         value={inputText}
//         onChange={onChange}
//         name={name}
//         maxLength={maxLength}
//         placeholder={placeholder || "Enter text"}
//         type={inputType}
//         onFocus={handleFocus}
//         onBlur={handleBlur}
//         disabled={isDisabled} // Disable the input if the state is 'disabled'
//       />
//   );

//   return (
//     <div className={`${styles.inputField} ${styles[size]} ${icon ? styles.icon : ''} ${type === 'password' ? styles.hasToggle : ''} ${styles[stateProp]}`}>
//       {label && <Label size={size} text={label} help={help} />}

//       <div className={styles.inputContainer}>
  
//           { icon && getIconComponent(icon)}

//           <div className={styles.textWrapper}>
//             {inputElement}
//           </div>

//           {type === 'password' && (
//           <div className={styles.toggleBtn} onClick={handlePasswordVisibility}>
//             {passwordIcon}
//           </div>
//         )}
//       </div> 

//       { hint && <div className={styles.hint}>{hint}</div>}

//     </div>
//   );  
// };

// InputField.propTypes = {
//   state: PropTypes.oneOf(['default', 'focused', 'hover', 'disabled', 'error']),
//   name: PropTypes.string,
//   maxLength: PropTypes.number,
//   type: PropTypes.string,
//   placeholder: PropTypes.string,
//   size: PropTypes.oneOf(['md', 'lg']),
//   label: PropTypes.string,
//   help: PropTypes.bool,
//   icon: PropTypes.element,
//   inputText: PropTypes.string,
//   hint: PropTypes.string,
//   onChange: PropTypes.func,
// };

// InputField.defaultProps = {
//   state: 'default',
//   name: '',
//   maxLength: undefined,
//   type: 'text',
//   placeholder: 'Enter text',
//   size: 'md',
//   label: '',
//   help: false,
//   icon: null,
//   inputText: '',
//   hint: '',
//   onChange: () => {},
// };