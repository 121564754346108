import React from 'react';
import PropTypes from 'prop-types';
import style from "./style.module.css";
import {ChevronDown2} from "icons";
import classNames from "classnames";


function ExpandableCell({
  info,
  children,
}) {
  const {row} = info;

  return (
    <div className={ classNames(style.expandableCell, {
      [style.indent]: !row.getCanExpand(),
    }) }>
      {row.getCanExpand() ? (
        <button
          className={style.expandButton}
        >
          {row.getIsExpanded() ? <ChevronDown2 /> : <ChevronDown2 className={ style.collapsedIcon }/>}
        </button>
      ) : null }{' '}
      { children }
    </div>
  )
}

ExpandableCell.propTypes = {
  info: PropTypes.object.isRequired,
};

export default ExpandableCell;