import React from 'react';
import PropTypes from 'prop-types';

export function ExpandDown({ className, color, label }) {
    // Apply inline style only if color prop is not currentColor
    const style = color !== 'currentColor' ? { color } : undefined;

    return (
        <svg
            fill="none"
            viewBox="1317.26 721.74 7.48 12"
            className={className}
            role="img"
            aria-label={label}
            style={style}
            width="14"
            height="14"
        >
            <path d="M1321 721.738C1321.41 721.738 1321.74 722.069 1321.74 722.477V726.5H1324.15C1324.65 726.5 1324.93 727.099 1324.59 727.481L1321.45 730.445C1321.21 730.714 1320.79 730.714 1320.55 730.445L1317.41 727.481C1317.07 727.099 1317.35 726.5 1317.85 726.5H1320.26V722.477C1320.26 722.069 1320.59 721.738 1321 721.738Z"
                fill="currentColor"
            />
            <path
                d="M1318.04 732.26C1317.64 732.26 1317.3 732.591 1317.3 732.999C1317.3 733.407 1317.64 733.738 1318.04 733.738H1323.96C1324.36 733.738 1324.7 733.407 1324.7 732.999C1324.7 732.591 1324.36 732.26 1323.96 732.26H1318.04Z"
                fill="currentColor"
            />
        </svg>
    );
}

ExpandDown.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.string,
};

ExpandDown.defaultProps = {
    color: 'currentColor', // Or any default color
};
