import React from 'react';
import {Transcoding} from "icons";
import {Button} from "components/Button";
import { useNavigate } from 'react-router-dom';

function TranscodeButton() {
  const navigate = useNavigate();
  
  return (
    <Button
      icon={<Transcoding/>}
      onClick={() => navigate('/request_builder')}
    >
      Transcode Video
    </Button>
  )
}

TranscodeButton.propTypes = {};

export default TranscodeButton;