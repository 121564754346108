import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from "components/Button";
import {InputField} from "components/InputField";
import {Select} from "components/Select";
import domainTypes from "helpers/domainTypes";
import {Modal} from "components/Modal";
import {validateFields} from "helpers/validation";
import {createDomain} from "api/qencode-content-delivery-api";
import useDataLoadingStatus from "hooks/useDataLoadingStatus";
import {getBucketList} from "api/qencode-account-api";
import {useAuth} from "context/AuthContext";
import ErrorMessage from "components/ErrorMessage";

const securityTypes = [
  { value: 'tls', label: "TLS Subscription" }
];

function CreateDomainModal({ open, handleCancel, onSuccessfulFinish }) {
  const { getToken } = useAuth();
  const [domainName, setDomainName] = useState('');
  const [domainType, setDomainType] = useState('');
  const [securityType, setSecurityType] = useState(securityTypes[0].value);
  const [submitting, setSubmitting] = useState(false);
  const [bucket, setBucket] = useState();
  const [errors, setErrors] = useState({});
  const {
    data: buckets,
    errorMessage,
  } = useDataLoadingStatus( getBucketList );
  const bucketsOptions = buckets.map(({
    'bucket_id': value,
    name: label,
  }) => ({
    label,
    value: label,
  }));

  function handleDomainNameInputChange(e) {
    setDomainName(e.target.value);
    setErrors({});
  }

  function handleDomainTypeChange(e) {
    setDomainType(e.target.value);
    setErrors({});
  }

  function handleBucket(e) {
    setBucket(e.target.value);
    setErrors({});
  }

  function handleSecurityType(e) {
    setSecurityType(e.target.value);
  }

  function clearFormAndCloseModal(e) {
    e?.preventDefault();
    setDomainName('');
    setDomainType('');
    setBucket();
    setSecurityType(securityTypes[0].value);
    setErrors({});
    handleCancel();
  }

  async function handleSubmit(e) {
    e?.preventDefault();
    const fields = {
      domainName,
      domainType,
    };
    const fieldName = [
      'domainName',
      'domainType',
    ];
    if (domainType === 'vod') {
      fields.bucket = bucket;
      fieldName.push('bucket');
    }

    const {
      isValid,
      errors: validationErrors,
    } = validateFields(fields, fieldName);

    if (!isValid) {
      setErrors(validationErrors);
      return;
    }
    const accessToken = await getToken();
    
    setSubmitting(true);
    const result = await createDomain({
      accessToken,
      name: domainName,
      type: domainType,
      bucket,
    });
    
    setSubmitting(false);
    
    if (result.success) {
      clearFormAndCloseModal();
      onSuccessfulFinish?.( result.data );
    }
    if (result.error) {
      let error = result.error;
      let field = 'domainName';
      if ( result.error.includes('bucket') ) {
        field = 'bucket';
      }
      if (error.toLowerCase().includes('valid domain name')) {
        error = 'Not a valid domain name';
      }
      setErrors({
        [ field ]: error,
      });
    }
  }

  return (
    <Modal
      isOpen={open}
      onClose={handleCancel}
      header={
        <h3>
          Create Domain
        </h3>
      }
      footer={
        <>
          <Button
            onClick={handleCancel}
            type="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={ handleSubmit }
            state={ submitting ? "disabled" : "default" }
          >
            Create
          </Button>
        </>
      }
    >
      <form
        className="form"
        onSubmit={ handleSubmit }
      >
        { errorMessage && <ErrorMessage>{ errorMessage }</ErrorMessage> }
        <div className="row">
          <InputField
            placeholder=""
            onChange={handleDomainNameInputChange}
            label="Domain Name"
            name="domain-name"
            inputText={domainName}
            state={errors.domainName ? 'error' : 'default'}
            hint={errors.domainName}
          />
        </div>
        <div className="row">
          <Select
            options={domainTypes}
            label="Domain Type"
            onChange={handleDomainTypeChange}
            value={domainType}
            state={ errors.domainType ? 'error' : 'default' }
            hint={ errors.domainType }
          />
        </div>
        { domainType === 'vod' && (
          <div className="row">
            <Select
              options={bucketsOptions}
              label="Select a bucket"
              onChange={handleBucket}
              value={bucket}
              state={ errors.bucket ? 'error' : 'default' }
              hint={ errors.bucket }
            />
          </div>
        ) }
        <div className="row">
          <Select
            options={securityTypes}
            label="TLS type"
            onChange={handleSecurityType}
            value={securityType}
          />
        </div>
      </form>
    </Modal>
  )
}

CreateDomainModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  onSuccessfulFinish: PropTypes.func.isRequired,
};

export default CreateDomainModal;