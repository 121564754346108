import style from 'pages/StatisticsPage/components/BriefPanel/style.module.css';

function BriefPanel({
  children,
}) {
  return (
    <dl className={style.container}>
      { children }
    </dl>
  );
}

export default BriefPanel;