import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import useDataLoadingStatus from "hooks/useDataLoadingStatus";
import {getProjectList} from "api/qencode-account-api";
import MultiSelectFilter from "components/FilterSidebar/MultiSelectFilter";
import {FilterSectionProps} from "components/FilterSidebar/useFilter";

function ProjectsFilterSection(props) {
  const loadingStatus = useDataLoadingStatus(useCallback((properties) => getProjectList({
      ...properties,
      type: 'vod',
    }), [])
  );
  const uniqueNames = [];
  const options = props.addCheckedStatus(props.columnId, loadingStatus.data
    .filter(({name}) => {
      const includes = !uniqueNames.includes(name)
      uniqueNames.push(name);
      return includes;
    })
    .map(({id, name}) => ({
      value: name,
      label: name,
    })))

  return (
    <MultiSelectFilter
      search={true}
      nothingFoundText="No projects found"
      { ...props }
      options={ options }
    />
  );
}

ProjectsFilterSection.propTypes = {
  columnId: PropTypes.string,
  defaultExpanded: PropTypes.bool,
  ...FilterSectionProps,
};

export default ProjectsFilterSection;