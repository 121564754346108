// export const timeZones = [
//     { value: "GMT +0:00", label: "GMT +0:00", extraData: "Abidjan, Ivory Coast" },
//     { value: "GMT -9:00", label: "GMT -9:00", extraData: "Adak, Hawaii, United States" },
//     { value: "GMT -8:00", label: "GMT -8:00", extraData: "Adamstown, Pitcairn" },
//     { value: "GMT +10:30", label: "GMT +10:30", extraData: "Adelaide, Australia" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Aleppo, Syria" },
//     { value: "GMT +1:00", label: "GMT +1:00", extraData: "Algiers, Algeria" },
//     { value: "GMT +6:00", label: "GMT +6:00", extraData: "Almaty, Kazakhstan" },
//     { value: "GMT -11:00", label: "GMT -11:00", extraData: "Alofi, Niue" },
//     { value: "GMT +3:00", label: "GMT +3:00", extraData: "Baghdad, Iraq" },
//     { value: "GMT +4:00", label: "GMT +4:00", extraData: "Baku, Azerbaijan" },
//     { value: "GMT +7:00", label: "GMT +7:00", extraData: "Bangkok, Thailand" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Beirut, Lebanon" },
//     { value: "GMT -3:00", label: "GMT -3:00", extraData: "Buenos Aires, Argentina" },
//     { value: "GMT +1:00", label: "GMT +1:00", extraData: "Copenhagen, Denmark" },
//     { value: "GMT -4:00", label: "GMT -4:00", extraData: "Caracas, Venezuela" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Cairo, Egypt" },
//     { value: "GMT +3:30", label: "GMT +3:30", extraData: "Tehran, Iran" },
//     { value: "GMT -3:00", label: "GMT -3:00", extraData: "Santiago, Chile" },
//     { value: "GMT +3:00", label: "GMT +3:00", extraData: "Moscow, Russia" },
//     { value: "GMT +9:00", label: "GMT +9:00", extraData: "Tokyo, Japan" },
//     { value: "GMT +5:30", label: "GMT +5:30", extraData: "New Delhi, India" },
//     { value: "GMT +12:00", label: "GMT +12:00", extraData: "Auckland, New Zealand" },
//     { value: "GMT -5:00", label: "GMT -5:00", extraData: "New York, United States" },
//     { value: "GMT +8:00", label: "GMT +8:00", extraData: "Perth, Australia" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Pretoria, South Africa" },
//     { value: "GMT +1:00", label: "GMT +1:00", extraData: "Berlin, Germany" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Athens, Greece" },
//     { value: "GMT +9:30", label: "GMT +9:30", extraData: "Darwin, Australia" },
//     { value: "GMT +8:00", label: "GMT +8:00", extraData: "Singapore, Singapore" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Jerusalem, Israel" },
//     { value: "GMT -8:00", label: "GMT -8:00", extraData: "Los Angeles, United States" },
//     { value: "GMT -7:00", label: "GMT -7:00", extraData: "Denver, United States" },
//     { value: "GMT -6:00", label: "GMT -6:00", extraData: "Chicago, United States" },
//     { value: "GMT +11:00", label: "GMT +11:00", extraData: "Solomon Islands" },
//     { value: "GMT -3:00", label: "GMT -3:00", extraData: "Buenos Aires, Argentina" },
//     { value: "GMT -4:00", label: "GMT -4:00", extraData: "Santiago, Chile" },
//     { value: "GMT +5:00", label: "GMT +5:00", extraData: "Islamabad, Pakistan" },
//     { value: "GMT +1:00", label: "GMT +1:00", extraData: "Warsaw, Poland" },
//     { value: "GMT +7:00", label: "GMT +7:00", extraData: "Jakarta, Indonesia" },
//     { value: "GMT -5:00", label: "GMT -5:00", extraData: "Toronto, Canada" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Helsinki, Finland" },
//     { value: "GMT +4:30", label: "GMT +4:30", extraData: "Kabul, Afghanistan" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Istanbul, Turkey" },
//     { value: "GMT +9:00", label: "GMT +9:00", extraData: "Seoul, South Korea" },
//     { value: "GMT +10:00", label: "GMT +10:00", extraData: "Sydney, Australia" },
//     { value: "GMT +3:00", label: "GMT +3:00", extraData: "Nairobi, Kenya" },
//     { value: "GMT +1:00", label: "GMT +1:00", extraData: "Prague, Czech Republic" },
//     { value: "GMT -2:00", label: "GMT -2:00", extraData: "South Georgia and the South Sandwich Islands" },
//     { value: "GMT +5:45", label: "GMT +5:45", extraData: "Kathmandu, Nepal" },
//     { value: "GMT +1:00", label: "GMT +1:00", extraData: "Belgrade, Serbia" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Cairo, Egypt" },
//     { value: "GMT +8:00", label: "GMT +8:00", extraData: "Beijing, China" },
//     { value: "GMT -3:00", label: "GMT -3:00", extraData: "Greenland" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Bucharest, Romania" },
//     { value: "GMT -6:00", label: "GMT -6:00", extraData: "Mexico City, Mexico" },
//     { value: "GMT +5:00", label: "GMT +5:00", extraData: "Yekaterinburg, Russia" },
//     { value: "GMT +7:00", label: "GMT +7:00", extraData: "Ho Chi Minh City, Vietnam" },
//     { value: "GMT +12:00", label: "GMT +12:00", extraData: "Fiji" },
//     { value: "GMT +6:00", label: "GMT +6:00", extraData: "Astana, Kazakhstan" },
//     { value: "GMT +1:00", label: "GMT +1:00", extraData: "Amsterdam, Netherlands" },
//     { value: "GMT +5:00", label: "GMT +5:00", extraData: "Karachi, Pakistan" },
//     { value: "GMT +1:00", label: "GMT +1:00", extraData: "Lagos, Nigeria" },
//     { value: "GMT +3:00", label: "GMT +3:00", extraData: "Kuwait City, Kuwait" },
//     { value: "GMT +3:00", label: "GMT +3:00", extraData: "Moscow, Russia" },
//     { value: "GMT -4:00", label: "GMT -4:00", extraData: "La Paz, Bolivia" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Harare, Zimbabwe" },
//     { value: "GMT +4:00", label: "GMT +4:00", extraData: "Dubai, United Arab Emirates" },
//     { value: "GMT +11:00", label: "GMT +11:00", extraData: "Norfolk Island, Australia" },
//     { value: "GMT +3:00", label: "GMT +3:00", extraData: "Baghdad, Iraq" },
//     { value: "GMT +6:30", label: "GMT +6:30", extraData: "Yangon, Myanmar" },
//     { value: "GMT -7:00", label: "GMT -7:00", extraData: "Phoenix, United States" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Athens, Greece" },
//     { value: "GMT +4:00", label: "GMT +4:00", extraData: "Baku, Azerbaijan" },
//     { value: "GMT +8:00", label: "GMT +8:00", extraData: "Kuala Lumpur, Malaysia" },
//     { value: "GMT +7:00", label: "GMT +7:00", extraData: "Bangkok, Thailand" },
//     { value: "GMT +3:30", label: "GMT +3:30", extraData: "Tehran, Iran" },
//     { value: "GMT +5:00", label: "GMT +5:00", extraData: "Islamabad, Pakistan" },
//     { value: "GMT +8:00", label: "GMT +8:00", extraData: "Singapore, Singapore" },
//     { value: "GMT +10:00", label: "GMT +10:00", extraData: "Sydney, Australia" },
//     { value: "GMT +12:00", label: "GMT +12:00", extraData: "Auckland, New Zealand" },
//     { value: "GMT +9:00", label: "GMT +9:00", extraData: "Tokyo, Japan" },
//     { value: "GMT +8:00", label: "GMT +8:00", extraData: "Perth, Australia" },
//     { value: "GMT -5:00", label: "GMT -5:00", extraData: "New York, United States" },
//     { value: "GMT -3:00", label: "GMT -3:00", extraData: "Buenos Aires, Argentina" },
//     { value: "GMT +1:00", label: "GMT +1:00", extraData: "Berlin, Germany" },
//     { value: "GMT +9:00", label: "GMT +9:00", extraData: "Seoul, South Korea" },
//     { value: "GMT +7:00", label: "GMT +7:00", extraData: "Jakarta, Indonesia" },
//     { value: "GMT +5:30", label: "GMT +5:30", extraData: "New Delhi, India" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Jerusalem, Israel" },
//     { value: "GMT +3:00", label: "GMT +3:00", extraData: "Nairobi, Kenya" },
//     { value: "GMT +8:00", label: "GMT +8:00", extraData: "Beijing, China" },
//     { value: "GMT +5:45", label: "GMT +5:45", extraData: "Kathmandu, Nepal" },
//     { value: "GMT +1:00", label: "GMT +1:00", extraData: "Amsterdam, Netherlands" },
//     { value: "GMT -3:00", label: "GMT -3:00", extraData: "Santiago, Chile" },
//     { value: "GMT -4:00", label: "GMT -4:00", extraData: "Caracas, Venezuela" },
//     { value: "GMT +4:00", label: "GMT +4:00", extraData: "Tbilisi, Georgia" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Bucharest, Romania" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Cairo, Egypt" },
//     { value: "GMT +3:30", label: "GMT +3:30", extraData: "Tehran, Iran" },
//     { value: "GMT +5:00", label: "GMT +5:00", extraData: "Tashkent, Uzbekistan" },
//     { value: "GMT -5:00", label: "GMT -5:00", extraData: "Toronto, Canada" },
//     { value: "GMT -8:00", label: "GMT -8:00", extraData: "Los Angeles, United States" },
//     { value: "GMT +1:00", label: "GMT +1:00", extraData: "Warsaw, Poland" },
//     { value: "GMT -6:00", label: "GMT -6:00", extraData: "Mexico City, Mexico" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Helsinki, Finland" },
//     { value: "GMT -7:00", label: "GMT -7:00", extraData: "Denver, United States" },
//     { value: "GMT +1:00", label: "GMT +1:00", extraData: "Prague, Czech Republic" },
//     { value: "GMT +12:00", label: "GMT +12:00", extraData: "Fiji" },
//     { value: "GMT +11:00", label: "GMT +11:00", extraData: "Solomon Islands" },
//     { value: "GMT +5:30", label: "GMT +5:30", extraData: "Colombo, Sri Lanka" },
//     { value: "GMT +8:00", label: "GMT +8:00", extraData: "Manila, Philippines" },
//     { value: "GMT +4:30", label: "GMT +4:30", extraData: "Kabul, Afghanistan" },
//     { value: "GMT +3:00", label: "GMT +3:00", extraData: "Moscow, Russia" },
//     { value: "GMT +9:30", label: "GMT +9:30", extraData: "Darwin, Australia" },
//     { value: "GMT +10:00", label: "GMT +10:00", extraData: "Guam" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Athens, Greece" },
//     { value: "GMT +6:00", label: "GMT +6:00", extraData: "Astana, Kazakhstan" },
//     { value: "GMT +3:00", label: "GMT +3:00", extraData: "Riyadh, Saudi Arabia" },
//     { value: "GMT +8:00", label: "GMT +8:00", extraData: "Hong Kong, China" },
//     { value: "GMT +4:00", label: "GMT +4:00", extraData: "Baku, Azerbaijan" },
//     { value: "GMT +3:00", label: "GMT +3:00", extraData: "Baghdad, Iraq" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Beirut, Lebanon" },
//     { value: "GMT +1:00", label: "GMT +1:00", extraData: "Belgrade, Serbia" },
//     { value: "GMT -3:00", label: "GMT -3:00", extraData: "Greenland" },
//     { value: "GMT +5:45", label: "GMT +5:45", extraData: "Kathmandu, Nepal" },
//     { value: "GMT +4:00", label: "GMT +4:00", extraData: "Dubai, United Arab Emirates" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Cairo, Egypt" },
//     { value: "GMT +8:00", label: "GMT +8:00", extraData: "Beijing, China" },
//     { value: "GMT +12:00", label: "GMT +12:00", extraData: "Auckland, New Zealand" },
//     { value: "GMT +5:00", label: "GMT +5:00", extraData: "Yekaterinburg, Russia" },
//     { value: "GMT -5:00", label: "GMT -5:00", extraData: "New York, United States" },
//     { value: "GMT +8:00", label: "GMT +8:00", extraData: "Singapore, Singapore" },
//     { value: "GMT +3:00", label: "GMT +3:00", extraData: "Kuwait City, Kuwait" },
//     { value: "GMT +1:00", label: "GMT +1:00", extraData: "Amsterdam, Netherlands" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Jerusalem, Israel" },
//     { value: "GMT +9:00", label: "GMT +9:00", extraData: "Tokyo, Japan" },
//     { value: "GMT +10:00", label: "GMT +10:00", extraData: "Sydney, Australia" },
//     { value: "GMT +5:30", label: "GMT +5:30", extraData: "New Delhi, India" },
//     { value: "GMT +3:30", label: "GMT +3:30", extraData: "Tehran, Iran" },
//     { value: "GMT -6:00", label: "GMT -6:00", extraData: "Mexico City, Mexico" },
//     { value: "GMT -7:00", label: "GMT -7:00", extraData: "Phoenix, United States" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Helsinki, Finland" },
//     { value: "GMT +9:00", label: "GMT +9:00", extraData: "Seoul, South Korea" },
//     { value: "GMT +4:00", label: "GMT +4:00", extraData: "Tbilisi, Georgia" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Bucharest, Romania" },
//     { value: "GMT +7:00", label: "GMT +7:00", extraData: "Ho Chi Minh City, Vietnam" },
//     { value: "GMT +3:00", label: "GMT +3:00", extraData: "Nairobi, Kenya" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Harare, Zimbabwe" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Athens, Greece" },
//     { value: "GMT +3:00", label: "GMT +3:00", extraData: "Moscow, Russia" },
//     { value: "GMT +3:00", label: "GMT +3:00", extraData: "Baghdad, Iraq" },
//     { value: "GMT +4:30", label: "GMT +4:30", extraData: "Kabul, Afghanistan" },
//     { value: "GMT -3:00", label: "GMT -3:00", extraData: "Buenos Aires, Argentina" },
//     { value: "GMT +8:00", label: "GMT +8:00", extraData: "Perth, Australia" },
//     { value: "GMT -4:00", label: "GMT -4:00", extraData: "Santiago, Chile" },
//     { value: "GMT -7:00", label: "GMT -7:00", extraData: "Denver, United States" },
//     { value: "GMT -8:00", label: "GMT -8:00", extraData: "Los Angeles, United States" },
//     { value: "GMT -5:00", label: "GMT -5:00", extraData: "Toronto, Canada" },
//     { value: "GMT +5:00", label: "GMT +5:00", extraData: "Islamabad, Pakistan" },
//     { value: "GMT +3:00", label: "GMT +3:00", extraData: "Nairobi, Kenya" },
//     { value: "GMT +4:00", label: "GMT +4:00", extraData: "Dubai, United Arab Emirates" },
//     { value: "GMT +3:30", label: "GMT +3:30", extraData: "Tehran, Iran" },
//     { value: "GMT +5:30", label: "GMT +5:30", extraData: "Colombo, Sri Lanka" },
//     { value: "GMT +6:30", label: "GMT +6:30", extraData: "Yangon, Myanmar" },
//     { value: "GMT +5:45", label: "GMT +5:45", extraData: "Kathmandu, Nepal" },
//     { value: "GMT +12:00", label: "GMT +12:00", extraData: "Fiji" },
//     { value: "GMT +9:00", label: "GMT +9:00", extraData: "Tokyo, Japan" },
//     { value: "GMT +7:00", label: "GMT +7:00", extraData: "Bangkok, Thailand" },
//     { value: "GMT +6:00", label: "GMT +6:00", extraData: "Astana, Kazakhstan" },
//     { value: "GMT +8:00", label: "GMT +8:00", extraData: "Hong Kong, China" },
//     { value: "GMT +9:00", label: "GMT +9:00", extraData: "Seoul, South Korea" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Istanbul, Turkey" },
//     { value: "GMT +3:00", label: "GMT +3:00", extraData: "Kuwait City, Kuwait" },
//     { value: "GMT +8:00", label: "GMT +8:00", extraData: "Singapore, Singapore" },
//     { value: "GMT +5:30", label: "GMT +5:30", extraData: "New Delhi, India" },
//     { value: "GMT +10:00", label: "GMT +10:00", extraData: "Guam" },
//     { value: "GMT -11:00", label: "GMT -11:00", extraData: "Samoa" },
//     { value: "GMT -10:00", label: "GMT -10:00", extraData: "Hawaii" },
//     { value: "GMT -9:00", label: "GMT -9:00", extraData: "Alaska" },
//     { value: "GMT -8:00", label: "GMT -8:00", extraData: "Pacific Time (US & Canada)" },
//     { value: "GMT -7:00", label: "GMT -7:00", extraData: "Mountain Time (US & Canada)" },
//     { value: "GMT -6:00", label: "GMT -6:00", extraData: "Central Time (US & Canada)" },
//     { value: "GMT -5:00", label: "GMT -5:00", extraData: "Eastern Time (US & Canada)" },
//     { value: "GMT -4:00", label: "GMT -4:00", extraData: "Atlantic Time (Canada)" },
//     { value: "GMT -3:00", label: "GMT -3:00", extraData: "Brasilia" },
//     { value: "GMT -2:00", label: "GMT -2:00", extraData: "Mid-Atlantic" },
//     { value: "GMT -1:00", label: "GMT -1:00", extraData: "Azores" },
//     { value: "GMT +0:00", label: "GMT +0:00", extraData: "Casablanca, Monrovia" },
//     { value: "GMT +1:00", label: "GMT +1:00", extraData: "Central European Time" },
//     { value: "GMT +2:00", label: "GMT +2:00", extraData: "Eastern European Time" },
//     { value: "GMT +3:00", label: "GMT +3:00", extraData: "Baghdad, Kuwait, Nairobi" },
//     { value: "GMT +4:00", label: "GMT +4:00", extraData: "Abu Dhabi, Muscat" },
//     { value: "GMT +5:00", label: "GMT +5:00", extraData: "Yekaterinburg" },
//     { value: "GMT +6:00", label: "GMT +6:00", extraData: "Almaty, Novosibirsk" },
//     { value: "GMT +7:00", label: "GMT +7:00", extraData: "Bangkok, Hanoi, Jakarta" },
//     { value: "GMT +8:00", label: "GMT +8:00", extraData: "Beijing, Hong Kong, Singapore" },
//     { value: "GMT +9:00", label: "GMT +9:00", extraData: "Tokyo, Seoul" },
//     { value: "GMT +10:00", label: "GMT +10:00", extraData: "Sydney, Guam" },
//     { value: "GMT +11:00", label: "GMT +11:00", extraData: "Solomon Islands" },
//     { value: "GMT +12:00", label: "GMT +12:00", extraData: "Fiji, Kamchatka" },
// ];


export const timeZones = [
    { value: 'UTC+12:00', label: 'UTC+12:00' },
    { value: 'UTC+11:00', label: 'UTC+11:00' },
    { value: 'UTC+10:00', label: 'UTC+10:00' },
    { value: 'UTC+09:00', label: 'UTC+09:00' },
    { value: 'UTC+08:00', label: 'UTC+08:00' },
    { value: 'UTC+07:00', label: 'UTC+07:00' },
    { value: 'UTC+06:00', label: 'UTC+06:00' },
    { value: 'UTC+05:00', label: 'UTC+05:00' },
    { value: 'UTC+04:00', label: 'UTC+04:00' },
    { value: 'UTC+03:00', label: 'UTC+03:00' },
    { value: 'UTC+02:00', label: 'UTC+02:00' },
    { value: 'UTC+01:00', label: 'UTC+01:00' },
    { value: 'UTC+00:00', label: 'UTC+00:00' },
    { value: 'UTC-01:00', label: 'UTC-01:00' },
    { value: 'UTC-02:00', label: 'UTC-02:00' },
    { value: 'UTC-03:00', label: 'UTC-03:00' },
    { value: 'UTC-04:00', label: 'UTC-04:00' },
    { value: 'UTC-05:00', label: 'UTC-05:00' },
    { value: 'UTC-06:00', label: 'UTC-06:00' },
    { value: 'UTC-07:00', label: 'UTC-07:00' },
    { value: 'UTC-08:00', label: 'UTC-08:00' },
    { value: 'UTC-09:00', label: 'UTC-09:00' },
    { value: 'UTC-10:00', label: 'UTC-10:00' },
    { value: 'UTC-11:00', label: 'UTC-11:00' },
    { value: 'UTC-12:00', label: 'UTC-12:00' },
];