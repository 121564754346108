import { parseDestinationUrl } from 'helpers/urlParser';

export const removeFileExtension = (str) => {
    const lastDotIndex = str.lastIndexOf('.');
    if (lastDotIndex !== -1) {
        return str.slice(0, lastDotIndex);
    }
    return str;
};

export const getFileNameFromUrl = (url) => {
    try {
        if (!url) {
            return ''; // Return an empty string for undefined URLs
        }
        const path = new URL(url).pathname;
        const filename = path.split('/').pop();
        return filename;
    } catch (error) {
        //console.error('Error parsing URL:', error.message);
        return ''; // Return an empty string for invalid URLs
    }
};

// this doesn't handle cases when url is just filename 'video_1_eye.webm' in direct upload for example
// export const getFileNameWithoutExtension = (url) => {
//     try {
//         const path = new URL(url).pathname;
//         const filenameWithExtension = path.split('/').pop();
//         const filename = filenameWithExtension.replace(/\.[^.]*$/, ''); // Remove the extension
//         return filename;
//     } catch (error) {
//         //console.error('Error parsing URL:', error.message);
//         return ''; // Return an empty string if there's an error
//     }
// };

export const getFileNameWithoutExtension = (input) => {
    try {
        let filenameWithExtension;

        // Check if the input is a valid URL
        try {
            const path = new URL(input).pathname;
            filenameWithExtension = path.split('/').pop();
        } catch (e) {
            // If the input is not a valid URL, assume it's a file name
            filenameWithExtension = input;
        }

        // Remove the extension if it exists
        const filename = filenameWithExtension.includes('.') ? filenameWithExtension.replace(/\.[^.]*$/, '') : filenameWithExtension;
        return filename;
    } catch (error) {
        // Return an empty string if there's an error
        return '';
    }
};


export const getFileExtension = (outputFormat) => {
    const formatMap = {
        mp4: '.mp4',
        webm: '.webm',
        advanced_hls: '',
        advanced_dash: '',
        mp3: '.mp3',
        thumbnail: '.jpg',
        thumbnails: '',
        repack: '.mp4',
        gif: '.gif',
        hls_audio: '',
        speech_to_text: '',
        jpg: '.jpg',
        png: '.png'
    };

    return formatMap[outputFormat?.toLowerCase()] || ''; // Default to empty string if format is not found
}


const defaultResolutions = [
    { height: 2160,  bitrate: 12000 }, 
    { height: 1440, bitrate: 8500 },
    { height: 1080, bitrate: 5200 },
    { height: 720, bitrate: 3100 },
    { height: 540, bitrate: 2400 },
    { height: 480, bitrate: 1800 },
    { height: 360, bitrate: 950 },
];

export const defaultQuality = 20;

// export const getDefaultFormat = ({ output, videoSrc, destination }) => {
//     let format = {};

//     console.log("destination in getDefaultFormat: ", destination)

//     let fileName = getFileNameWithoutExtension(videoSrc);
//     // const baseUrl = destination ? destination.url : '';
//     const { baseUrl, path } = destination ? parseDestinationUrl(destination?.url) : ''

//     if(path) {
//         fileName = removeFileExtension(path)
//     }

//     // console.log("baseUrl: ", baseUrl)
//     // console.log("path: ", path)

//     // console.log({
//     //     destination,
//     //     output, 
//     //     fileName,
//     //     ext: getFileExtension(output)
//     // })

//     switch (output) {
//         case 'mp4':
//             format = {
//                 output,
//                 video_codec: 'libx264',
//                 height: 2160,
//                 quality: 20,
//                 destination: {
//                     ...destination,
//                     url: `${baseUrl}${fileName}${getFileExtension(output)}`
//                 }
//             };
//             break;        
//         case 'webm':
//             format = {
//                 output,
//                 video_codec: 'libvpx',
//                 height: 2160,
//                 quality: 20,
//                 destination: {
//                     ...destination,
//                     url: `${baseUrl}${fileName}${getFileExtension(output)}`
//                 }
//             };
//             break;
//         case 'advanced_hls':
//             format = {
//                 output,
//                 separate_audio: 1,
//                 stream: defaultResolutions.map(res => ({
//                     video_codec: 'libx264',
//                     height: res.height,
//                     bitrate: res.bitrate
//                 })),
//                 destination: {
//                     ...destination,
//                     url: `${baseUrl}${fileName}`
//                 }
//             };
//             break; 
//         case 'advanced_dash':
//             format = {
//                 output,
//                 separate_audio: 1,
//                 stream: defaultResolutions.map(res => ({
//                     video_codec: 'libx264',
//                     height: res.height,
//                     bitrate: res.bitrate
//                 })),
//                 destination: {
//                     ...destination,
//                     url: `${baseUrl}${fileName}`
//                 }
//             };
//             break;             
//         case 'mp3':
//             format = {
//                 output,
//                 audio_codec: "mp3",
//                 destination: {
//                     ...destination,
//                     url: `${baseUrl}${fileName}${getFileExtension(output)}`
//                 }
//             };
//             break;             
//         case 'thumbnail':
//             format = {
//                 output,
//                 image_format: 'jpg',
//                 time: '0.1',
//                 height: '270',
//                 width: '270',
//                 destination: {
//                     ...destination,
//                     url: `${baseUrl}${fileName}${getFileExtension(output)}`
//                 }
//             };
//             break;             
//         case 'thumbnails':
//             format = {
//                 output,
//                 image_format: 'jpg',
//                 interval: '5',
//                 height: '270',
//                 width: '270',
//                 destination: {
//                     ...destination,
//                     url: `${baseUrl}${fileName}${getFileExtension(output)}`
//                 }
//             };
//             break; 
//         case 'repack':
//             format = {
//                 output,
//                 file_extension: 'mp4',
//                 video_copy: 1,
//                 audio_copy: 1,
//                 subtitles_copy: 1,
//                 metadata_copy: 1,
//                 destination: {
//                     ...destination,
//                     url: `${baseUrl}${fileName}${getFileExtension(output)}`
//                 }
//             };
//             break; 
//         case 'gif':
//             format = {
//                 output,
//                 height: '180',
//                 width: '320',
//                 framerate: '15',
//                 destination: {
//                     ...destination,
//                     url: `${baseUrl}${fileName}${getFileExtension(output)}`
//                 }
//             };
//             break;   
//         case 'hls_audio':
//             format = {
//                 output,
//                 audio_codec: 'libfdk_aac',
//                 destination: {
//                     ...destination,
//                     url: `${baseUrl}${fileName}${getFileExtension(output)}`
//                 }
//             };
//             break;  
//         case 'speech_to_text':
//             format = {
//                 output,
//                 transcript: 1,
//                 json: 1,
//                 srt: 1,
//                 vtt: 1,
//                 mode: 'balanced',
//                 destination: {
//                     ...destination,
//                     url: `${baseUrl}${fileName}${getFileExtension(output)}`
//                 }
//             };
//             break;                                                                      
//         // Add other cases as needed
//         default:
//             format = {
//                 output,
//                 destination: {
//                     ...destination,
//                     url: `${baseUrl}${fileName}${getFileExtension(output)}`
//                     //url: `${baseUrl}${fileName}`
//                 }
//             };
//     }

//     return format;
// };

export const getDefaultFormat = ({ output, videoSrc, destination }) => {
    let format = {};

    //console.log("destination in getDefaultFormat: ", destination)

    let fileName = getFileNameWithoutExtension(videoSrc);
    const { baseUrl, path } = destination ? parseDestinationUrl(destination?.url) : {};

    if (path) {
        fileName = removeFileExtension(path);
    }

    switch (output) {
        case 'mp4':
            format = {
                output,
                video_codec: 'libx264',
                height: 2160,
                quality: 20
            };
            break;        
        case 'webm':
            format = {
                output,
                video_codec: 'libvpx',
                height: 2160,
                quality: 20
            };
            break;
        case 'advanced_hls':
            format = {
                output,
                separate_audio: 1,
                stream: defaultResolutions.map(res => ({
                    video_codec: 'libx264',
                    height: res.height,
                    bitrate: res.bitrate
                }))
            };
            break; 
        case 'advanced_dash':
            format = {
                output,
                separate_audio: 1,
                stream: defaultResolutions.map(res => ({
                    video_codec: 'libx264',
                    height: res.height,
                    bitrate: res.bitrate
                }))
            };
            break;             
        case 'mp3':
            format = {
                output,
                audio_codec: "mp3"
            };
            break;             
        case 'thumbnail':
            format = {
                output,
                image_format: 'jpg',
                time: '0.1',
                height: '270',
                width: '270'
            };
            break;             
        case 'thumbnails':
            format = {
                output,
                image_format: 'jpg',
                interval: '5',
                height: '270',
                width: '270'
            };
            break; 
        case 'repack':
            format = {
                output,
                file_extension: 'mp4',
                video_copy: 1,
                audio_copy: 1,
                subtitles_copy: 1,
                metadata_copy: 1
            };
            break; 
        case 'gif':
            format = {
                output,
                height: '180',
                width: '320',
                framerate: '15'
            };
            break;   
        case 'hls_audio':
            format = {
                output,
                audio_codec: 'libfdk_aac'
            };
            break;  
        case 'speech_to_text':
            format = {
                output,
                transcript: 1,
                json: 1,
                srt: 1,
                vtt: 1,
                mode: 'balanced'
            };
            break;                                                                      
        // Add other cases as needed
        default:
            format = {
                output
            };
    }

    if (destination) {
        format.destination = {
            ...destination,
            url: `${baseUrl}${fileName}${getFileExtension(output)}`
        };
    }

    return format;
};


export const removeFilenameFromUrl = (url) => {
    if (!url || typeof url !== 'string') {
        return ''; // Return an empty string if the input is not a valid string
    }
    
    const lastSlashIndex = url.lastIndexOf('/');
    if (lastSlashIndex === -1) {
        return url; // Return the original URL if no '/' is found
    }
    
    return url.substring(0, lastSlashIndex);
}

export const getPath = ({ output, videoSrc, destination }) => {
    const fileName = getFileNameWithoutExtension(videoSrc);
    const baseUrl = destination ? destination.url : '';

    const path = `${baseUrl}${fileName}${getFileExtension(output)}`

    return path;
};

export const getBitrateByHeight = (height) => {
    const resolution = defaultResolutions.find(res => res.height === height);
    return resolution ? resolution.bitrate : null;
};