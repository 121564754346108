// passing role to main layout

import React, { useEffect, useState, useRef  } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { jwtDecode } from 'jwt-decode'; // Use named import

const ProtectedRoute = ({ element: Component, layout: Layout, restrictedRoles = [], pageName, ...rest }) => {
    const { 
        isAuthenticated, getToken, setIsAuthenticated, 
        logout 
    } = useAuth();
    const location = useLocation();
    const [isCheckingToken, setIsCheckingToken] = useState(true);
    const [userRole, setUserRole] = useState(null);

    const hasLoggedOut = useRef(false); // Ref to track if logout has already been called

    useEffect(() => {
        const checkToken = async () => {
            console.log('Checking token...');
            try {
                const token = await getToken();
                if (!token) {
                    setIsAuthenticated(false);
                    console.log('No valid access token, setting authentication to false...');
                } else {
                    const decodedToken = jwtDecode(token);
                    setUserRole(decodedToken?.role);
                }
            } catch (error) {
                console.error('Token check failed:', error);
                setIsAuthenticated(false);
            }
            setIsCheckingToken(false);
        };

        checkToken();
    }, [getToken, setIsAuthenticated]);


    // useEffect(() => {
    //     // Listen for logout event across tabs
    //     const handleStorageChange = (event) => {
    //         if (event.key === 'Qencode_logout') {
    //             logout(); // Call logout function to clear local state
    //         }
    //     };

    //     window.addEventListener('storage', handleStorageChange);

    //     return () => {
    //         window.removeEventListener('storage', handleStorageChange);
    //     };
    // }, [logout]);    

    useEffect(() => {
        // Move channel initialization inside the useEffect
        const channel = new BroadcastChannel('Qencode_channel');

        // const handleChannelMessage = (event) => {
        //   const { type } = event.data;
        //   if (type === 'logout') {
        //     console.log("Trigger logout in Protected Route")
        //     logout(); // Trigger logout
        //   }
        // };

        const handleChannelMessage = (event) => {
            const { type } = event.data;
            if (type === 'logout' && !hasLoggedOut.current) {
                //console.log("Trigger logout in Protected Route by catching logout event from another tab")
                hasLoggedOut.current = true; // Set the flag to true to prevent multiple calls
                logout(); // Trigger logout
            }
        };        
      
        channel.onmessage = handleChannelMessage;
      
        return () => {
          channel.close(); // Cleanup when the component unmounts
        };
    }, [logout]);    


    if (isCheckingToken) {
        return <div>Loading...</div>; // Show loading indicator until the token is checked
    }

    const isRoleRestricted = restrictedRoles.includes(userRole);

    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    } else if (!isRoleRestricted) {
        // Pass userRole to Layout
        return (
          <Layout pageName={pageName} userRole={userRole}>
            <Component {...rest} />
          </Layout>        
        );
    } else {
        return userRole === "billing" ? <Navigate to="/billing" replace /> : <Navigate to="/dashboard" replace />;
    }
};

export default ProtectedRoute;


// // verify token on every protected route access

// import React, { useEffect, useState, useCallback } from 'react';
// import { Navigate, useLocation, useNavigate } from 'react-router-dom';
// import { useAuth } from '../context/AuthContext';
// import { jwtDecode } from 'jwt-decode'; // Use named import

// const ProtectedRoute = ({ element: Component, layout: Layout, restrictedRoles = [], pageName, ...rest }) => {
//     const { isAuthenticated, getToken, setIsAuthenticated, verifyToken, logout } = useAuth();
//     const location = useLocation();
//     const [isCheckingToken, setIsCheckingToken] = useState(true);
//     const [userRole, setUserRole] = useState(null);
//     const navigate = useNavigate();

//     const handleLogout = useCallback(() => {
//         logout();
//         navigate('/');
//     },[logout, navigate]);

//     useEffect(() => {
//         const checkToken = async () => {
//             console.log('Verifying token...');
//             try {
//                 const token = await getToken();
//                 if (!token) {
//                     setIsAuthenticated(false);
//                     console.log('No valid access token, setting authentication to false...');
//                 } else {
//                     // Verify token validity with backend
//                     const isValid = await verifyToken(token);
//                     if (!isValid) {
//                         console.log('Token is invalid. Logging out.');
//                         handleLogout(); // Logout if the token is invalid
//                         return;
//                     }

//                     const decodedToken = jwtDecode(token);
//                     setUserRole(decodedToken?.role);
//                 }
//             } catch (error) {
//                 console.error('Token verification failed:', error);
//                 setIsAuthenticated(false);
//             }
//             setIsCheckingToken(false);
//         };

//         // Verify the token every time the component renders
//         checkToken();
//     }, [location.pathname, getToken, setIsAuthenticated, verifyToken, handleLogout]);  // Adding location.pathname ensures the effect runs on every route change

//     if (isCheckingToken) {
//         return <div>Loading...</div>; // Show loading indicator until the token is checked
//     }

//     const isRoleRestricted = restrictedRoles.includes(userRole);

//     if (!isAuthenticated) {
//         return <Navigate to="/login" state={{ from: location }} replace />;
//     } else if (!isRoleRestricted) {
//         // Pass userRole to Layout
//         return (
//           <Layout pageName={pageName} userRole={userRole}>
//             <Component {...rest} />
//           </Layout>        
//         );
//     } else {
//         return userRole === "billing" ? <Navigate to="/billing" replace /> : <Navigate to="/dashboard" replace />;
//     }
// };

// export default ProtectedRoute;






// // passing role to main layout

// import React, { useEffect, useState } from 'react';
// import { Navigate, useLocation } from 'react-router-dom';
// import { useAuth } from '../context/AuthContext';
// import { jwtDecode } from 'jwt-decode'; // Use named import

// const ProtectedRoute = ({ element: Component, layout: Layout, restrictedRoles = [], pageName, ...rest }) => {
//     const { isAuthenticated, getToken, setIsAuthenticated, verifyToken } = useAuth();
//     const location = useLocation();
//     const [isCheckingToken, setIsCheckingToken] = useState(true);
//     const [userRole, setUserRole] = useState(null);

//     useEffect(() => {
//         const checkToken = async () => {
//             console.log('Checking token...');
//             try {
//                 const token = await getToken();
//                 if (!token) {
//                     setIsAuthenticated(false);
//                     console.log('No valid access token, setting authentication to false...');
//                 } else {
//                     const decodedToken = jwtDecode(token);
//                     setUserRole(decodedToken?.role);
//                 }
//             } catch (error) {
//                 console.error('Token check failed:', error);
//                 setIsAuthenticated(false);
//             }
//             setIsCheckingToken(false);
//         };

//         checkToken();
//     }, [getToken, setIsAuthenticated, verifyToken]);

//     if (isCheckingToken) {
//         return <div>Loading...</div>; // Show loading indicator until the token is checked
//     }

//     const isRoleRestricted = restrictedRoles.includes(userRole);

//     if (!isAuthenticated) {
//         return <Navigate to="/login" state={{ from: location }} replace />;
//     } else if (!isRoleRestricted) {
//         // Pass userRole to Layout
//         return (
//           <Layout pageName={pageName} userRole={userRole}>
//             <Component {...rest} />
//           </Layout>        
//         );
//     } else {
//         return userRole === "billing" ? <Navigate to="/billing" replace /> : <Navigate to="/dashboard" replace />;
//     }
// };

// export default ProtectedRoute;









// // getting user role from access token

// import React, { useEffect, useState } from 'react';
// import { Navigate, useLocation } from 'react-router-dom';
// import { useAuth } from '../context/AuthContext';
// import { jwtDecode } from 'jwt-decode'; // Use named import

// const ProtectedRoute = ({ element: Component, restrictedRoles = [], ...rest }) => {
//     const { isAuthenticated, getToken, setIsAuthenticated } = useAuth();
//     const location = useLocation();
//     const [isCheckingToken, setIsCheckingToken] = useState(true);
//     const [userRole, setUserRole] = useState(null);

//     useEffect(() => {
//         const checkToken = async () => {
//             console.log('Checking token...');
//             try {
//                 const token = await getToken();
//                 //console.log("token: ", token);
//                 if (!token) {
//                     setIsAuthenticated(false);
//                     console.log('No valid access token, setting authentication to false...');
//                 } else {
//                     // Decode the token to get the user's role
//                     const decodedToken = jwtDecode(token);
//                     //console.log('Decoded token:', decodedToken);
//                     setUserRole(decodedToken?.role);
//                 }
//             } catch (error) {
//                 console.error('Token check failed:', error);
//                 setIsAuthenticated(false);
//             }
//             setIsCheckingToken(false);
//         };

//         checkToken();
//     }, [getToken, setIsAuthenticated]);

//     if (isCheckingToken) {
//         return <div>Loading...</div>; // Show loading indicator until the token is checked
//     }

//     // Check if the user's role is restricted
//     const isRoleRestricted = restrictedRoles.includes(userRole);
//     // console.log("restrictedRoles: ", restrictedRoles);
//     // console.log("isRoleRestricted: ", isRoleRestricted);
//     // console.log("userRole: ", userRole);

//     if (!isAuthenticated) {
//         // If not authenticated, redirect to login
//         return <Navigate to="/login" state={{ from: location }} replace />;
//     } else if (!isRoleRestricted) {
//         // If authenticated and role is not restricted, render the component
//         return <Component {...rest} userRole={userRole}/>; // Pass userRole as a prop
//     } else {
//         // If authenticated but role is restricted, redirect based on role
//         if (userRole === "billing") {
//             return <Navigate to="/billing" replace />;
//         } else {
//             return <Navigate to="/dashboard" replace />;
//         }
//     }
// };

// export default ProtectedRoute;





// checking user roles from userData
// import React, { useEffect, useState } from 'react';
// import { Navigate, useLocation } from 'react-router-dom';
// import { useAuth } from '../context/AuthContext';
// import { useUser } from '../context/UserContext'; // Assuming this provides userData

// const ProtectedRoute = ({ element: Component, restrictedRoles = [], ...rest }) => {
//     const { isAuthenticated, getToken, setIsAuthenticated } = useAuth();
//     // const { userData } = useUser(); // Assuming this provides userData
//     const { userData, loading: userLoading } = useUser(); // Check for user loading state
//     const location = useLocation();
//     const [isCheckingToken, setIsCheckingToken] = useState(true);

//     useEffect(() => {
//         const checkToken = async () => {
//             console.log('Checking token...');
//             try {
//                 const token = await getToken();
//                 console.log("token: ", token)
//                 if (!token) {
//                     setIsAuthenticated(false);
//                     console.log('No valid access token, setting authentication to false...');
//                 }
//             } catch (error) {
//                 console.error('Token check failed:', error);
//                 setIsAuthenticated(false);
//             }
//             setIsCheckingToken(false);
//         };
    
//         checkToken();
//     }, [getToken, setIsAuthenticated]);

//     // if (isCheckingToken) {
//     //     return <div>Loading...</div>; // Or any other loading indicator
//     // }

//     if (isCheckingToken || userLoading) {
//         return <div>Loading...</div>; // Show loading indicator until user data is ready
//     }        

//     // Check if the user's role is restricted
//     const isRoleRestricted = restrictedRoles.includes(userData?.role);
//     console.log("restrictedRoles: ", restrictedRoles)

//     console.log("isRoleRestricted: ", isRoleRestricted);

//     console.log("userData: ", userData)

//     if (!isAuthenticated) {
//         // If not authenticated, redirect to login
//         return <Navigate to="/login" state={{ from: location }} replace />;
//     } else if (!isRoleRestricted) {
//         // If authenticated and role is not restricted, render the component
//         return <Component {...rest} />;
//     } else {
//         // If authenticated but role is restricted, redirect based on role
//         if (userData?.role === "billing") {
//             return <Navigate to="/billing" replace />;
//         } else {
//             return <Navigate to="/dashboard" replace />;
//         }
//     }
// };

// export default ProtectedRoute;



// original no user roles checking
// import React, { useEffect, useState } from 'react';
// import { Navigate, useLocation } from 'react-router-dom';
// import { useAuth } from '../context/AuthContext';

// const ProtectedRoute = ({ element: Component, ...rest }) => {
//     const { isAuthenticated, getToken, setIsAuthenticated } = useAuth();
//     const location = useLocation();
//     const [isCheckingToken, setIsCheckingToken] = useState(true);

//     useEffect(() => {
//         const checkToken = async () => {
//             console.log('Checking token...');
//             try {
//                 const token = await getToken(); // Ensure getToken checks and possibly refreshes the token
//                 if (!token) {
//                     setIsAuthenticated(false);
//                     console.log('No valid access token, redirecting to login...');
//                 }
//             } catch (error) {
//                 console.error('Token check failed:', error);
//                 setIsAuthenticated(false);
//             }
//             setIsCheckingToken(false);
//         };
    
//         checkToken();
//     }, [getToken, setIsAuthenticated]);

//     if (isCheckingToken) {
//         return <div>Loading...</div>; // Or any other loading indicator
//     }

//     return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" state={{ from: location }} replace />;
// };

// export default ProtectedRoute;