import React, {useState} from 'react';
import {Modal} from "components/Modal";
import {Button} from "components/Button";
import {Select} from "components/Select";
import {useAuth} from "context/AuthContext";
import {createKey, listLiveDomains} from "api/qencode-content-delivery-api";
import useDataLoadingStatus from "hooks/useDataLoadingStatus";
import {listLiveStreams} from "api/live-streaming-api";
import PropTypes from "prop-types";
import {getBucketList} from "api/qencode-account-api";
import {validateFields} from "helpers/validation";
import ErrorMessage from "components/ErrorMessage";
import {Add} from "icons";

const keyDomain = 'domain';
const keyLive = 'live';
const keyBucket = 'bucket';

const signingKeyTypeVariants = [
  { value: keyDomain, label: "Live Streaming Domain" },
  { value: keyLive, label: "Live Stream" },
  { value: keyBucket, label: "Media Storage Bucket" },
];

export const signingKeySubtypesRequiredOneOf = [
  'bucket_name',
  'stream_id',
  'domain_id',
];

function CreateSigningKeyButton({ onSuccessfulFinish }) {
  const { getToken } = useAuth();

  const [open, setOpen] = useState(false);

  const [signingKeyType, setSigningKeyType] = useState('');
  const [signingKeySubtype, setSigningKeySubtype] = useState({});
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);


  function handleClose() {
    setOpen(!open);
    setSigningKeyType('');
    setSigningKeySubtype({});
    setErrors({});
    setSubmitting(false);
  }

  async function handleSubmit() {
    const {
      isValid,
      errors: validationErrors,
    } = validateFields({
      signingKeyType,
      signingKeySubtype,
    }, ['signingKeyType', 'signingKeySubtype']);

    if (!isValid) {
      setErrors(validationErrors);
      return;
    }
    const accessToken = await getToken();
    const subtypeCopy = { ...signingKeySubtype };
    delete subtypeCopy.bucketId;

    setSubmitting(true);
    const result = await createKey({
      accessToken,
      ...subtypeCopy,
    });

    setSubmitting(false);

    if (result.success) {
      handleClose();
      onSuccessfulFinish?.( result.data );
    }

    if (result.error) {
      setErrors({
        general: result.error,
      });
    }
  }
  
  function changeType(e) {
    setSigningKeyType(e.target.value);
    setSigningKeySubtype({});
    setErrors({});
  }

  function changeSubtype(value) {
    setSigningKeySubtype(value);
    setErrors({});
  }

  return (
    <>
      <Modal
        isOpen={open}
        onClose={handleClose}
        header={
          <h3>
            Create Signing Key
          </h3>
        }
        footer={
          <>
            <Button
              onClick={handleClose}
              type="secondary"
              variant="modalFooter"
            >
              Cancel
            </Button>
            <Button
              variant="modalFooter"
              onClick={handleSubmit}
              state={ submitting ? "disabled" : "default" }
            >
              Create
            </Button>
          </>
        }
      >
        { errors.general && <ErrorMessage>{ errors.general }</ErrorMessage> }
        <form className="form">
          <div className="row">
            <Select
              options={ signingKeyTypeVariants }
              label="Type"
              onChange={ changeType }
              value={ signingKeyType }
              state={ errors.signingKeyType ? 'error' : 'default' }
              hint={ errors.signingKeyType }
            />
          </div>
          <div className="row">
            <Subtype
              type={ signingKeyType }
              subtype={ signingKeySubtype }
              onChange={ changeSubtype }
              state={ errors.signingKeySubtype ? 'error' : 'default' }
              hint={ errors.signingKeySubtype }
            />
          </div>
        </form>
      </Modal>
      <Button
        onClick={handleClose}
        icon={<Add/>}
      >
        Create Signing Key
      </Button>
    </>
  );
}

CreateSigningKeyButton.propTypes = {
  onSuccessfulFinish: PropTypes.func,
};

function Subtype({ type, subtype, ...restProps }) {
  if (type === keyDomain) {
    return (
      <LiveStreamingDomain
        value={ Object.values(subtype)[0] }
        {...restProps}
      />
    )
  }
  if (type === keyLive) {
    return (
      <LiveStream
        value={ Object.values(subtype)[0] }
        {...restProps}
      />
    )
  }
  if (type === keyBucket) {
    return (
      <MediaStorageBucket
        value={ subtype['bucket_name'] }
        {...restProps}
      />
    )
  }
}

function LiveStreamingDomain({ onChange, ...restProps }) {
  const {
    data: domains,
    errorMessage,
  } = useDataLoadingStatus( listLiveDomains );
  const liveDomains = domains
    .filter((domain) => domain.type === keyLive)
    .map((domain) => ({
      value: domain.id,
      label: domain['domain_name'],
    }));

  return (
    <Select
      options={liveDomains}
      label="Select a Live Streaming Domain"
      onChange={( { target: { value } } ) => onChange({
        'domain_id': value,
      })}
      state={ errorMessage ? 'error' : 'default' }
      hint={ errorMessage }
      { ...restProps }
    />
  );
}

LiveStreamingDomain.propTypes = {
  onChange: PropTypes.func,
};

function LiveStream({ onChange, ...restProps }) {
  const {
    data: streams,
    errorMessage,
  } = useDataLoadingStatus( listLiveStreams );
  const streamsWithPlaybackDomain = streams
    .map(({ name, id }) => ({
      value: id,
      label: name,
    }));


  return (
    <Select
      options={streamsWithPlaybackDomain}
      label="Select a Live stream"
      onChange={( { target: { value } } ) => onChange({
        'stream_id': value,
      })}
      state={ errorMessage ? 'error' : 'default' }
      hint={ errorMessage }
      { ...restProps }
    />
  );
}

LiveStream.propTypes = {
  onChange: PropTypes.func,
};

function MediaStorageBucket({
  onChange,
  ...restProps
}) {
  const {
    data: buckets,
    errorMessage,
  } = useDataLoadingStatus( getBucketList );
  const options = buckets
    .reduce(pickItemsWithUniqueNameReducer, [])
    .map(({ name }) => ({
      label: name,
      value: name,
    }));

  return (
    <Select
      options={options}
      label="Select a bucket"
      onChange={( { target: { value } } ) => {
        const option = options.find((bucket) => bucket.value === value);

        if (option?.label) {
          onChange({
            'bucket_name': option.label,
          })
        }
      }}
      state={ errorMessage ? 'error' : 'default' }
      hint={ errorMessage }
      { ...restProps }
    />
  );
}

function pickItemsWithUniqueNameReducer(uniqueBuckets, bucket) {
  if (!uniqueBuckets.find((uniqueBucket) => uniqueBucket.name === bucket.name)) {
    uniqueBuckets.push(bucket);
  }
  return uniqueBuckets;
}

MediaStorageBucket.propTypes = {
  onChange: PropTypes.func,
};

export default CreateSigningKeyButton;