import PropTypes from "prop-types";
import React from "react";

export const Shield = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}       
        xmlns="http://www.w3.org/2000/svg" width="55" height="60" viewBox="0 0 55 60" fill="none"
    >
        <path fillRule="evenodd" clipRule="evenodd" d="M0 9.09365V30.603C0 43.9863 11.2871 56.1075 26.0179 60C41.2837 56.2828 54.3691 43.9863 54.3691 30.603V9.1435C54.368 7.55529 53.0796 6.26871 51.4913 6.26985C51.2113 6.27005 50.9327 6.31117 50.6647 6.39271C47.3286 7.40034 44.0547 7.90416 40.8429 7.90416C37.5582 7.90416 34.2085 5.82202 30.7938 1.65776L30.7959 1.65601C29.2119 -0.276216 26.3615 -0.558521 24.4292 1.02547C24.2037 1.21039 23.9965 1.41669 23.8106 1.64149C20.3632 5.81658 17.0232 7.90416 13.7905 7.90416C10.5789 7.90416 7.21263 7.38269 3.6916 6.33977C2.16895 5.88815 0.568983 6.75732 0.118242 8.28024C0.0400827 8.54431 0.000262412 8.81825 0 9.09365Z" fill="#F1F4F7"/>
        <path d="M33.8385 27.6467H20.7646C18.6855 27.6467 17 29.3322 17 31.4113V40.7051C17 42.7842 18.6855 44.4697 20.7646 44.4697H33.8385C35.9176 44.4697 37.603 42.7842 37.603 40.7051V31.4113C37.603 29.3322 35.9176 27.6467 33.8385 27.6467Z" stroke="#316FEA" strokeWidth="3"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.8983 31.5289H30.4176C30.8464 31.5289 31.194 31.8766 31.194 32.3054C31.194 32.5265 31.0997 32.7371 30.9348 32.8844L22.1567 40.7259C21.8369 41.0115 21.3461 40.9839 21.0604 40.6641C20.9333 40.5218 20.863 40.3376 20.863 40.1468V32.5642C20.863 31.9924 21.3265 31.5289 21.8983 31.5289Z" fill="#316FEA"/>
        <path d="M20.863 27.6467V22.4384C20.863 18.8826 23.7456 16 27.3015 16C30.8573 16 33.7399 18.8826 33.7399 22.4384V27.6467" stroke="#316FEA" strokeWidth="3"/>
    </svg>    

  );
};

Shield.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Shield.defaultProps = {
  color: 'currentColor', // Or any default color
};