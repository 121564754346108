import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import style from './style.module.css';
import {InputField} from "components/InputField";
import {LookingGlass} from "icons";

function Search({ list }) {
  const [filter, setFilter] = useState('');
  
  const onSubmit = useCallback(function onSubmit(e) {
    e?.preventDefault();
    const params = new URLSearchParams({ token: filter });
    list(params);
  }, [filter, list]);
  
  useEffect(() => {
    if (filter?.length === 0) {
      list();
    }
    if (filter?.length >= 32) {
      onSubmit();
    }
    // eslint-disable-next-line
  }, [filter]);

  return (
    <form
      className={ style.search }
      onSubmit={onSubmit}
    >
      <InputField
        icon={ <LookingGlass/> }
        className={ style.search }
        placeholder="Job Id"
        inputText={filter}
        onChange={ (event) => {
          setFilter(String(event.target.value))
        } }
      />
    </form>
  );
}

Search.propTypes = {
  list: PropTypes.func.isRequired,
};

export default Search;