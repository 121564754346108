import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import getResolutionReadable from "helpers/getResolutionReadable";
import style from "./style.module.css";
import CellDetails from "components/CellDetails";
import MiniTable from "components/Table/MiniTable";
import getCodecReadable from "helpers/getCodecReadable";
import {videoFormatCodeToReadable} from "helpers/videoFormats";
import HorizontalList from "components/HorizontalList";
import {ChevronDown} from "icons";

function DetailsCell({ info }) {
  const format = info.row.original?.query?.query?.format || [];
  const firstFormat = format?.[0];
  const outputReadable = videoFormatCodeToReadable[firstFormat?.output];
  const [open, setOpen] = useState(false);
  const dropdownButtonRef = useRef();

  function handleDropdownClick() {
    setOpen( (state) => {
      return !state;
    } );
  }

  if (Array.isArray(format)) {
    const multipleFormatItems = format.length > 1;

    return (
      <div className={style.dropdownButtonWrapper}>
        <CellDetails
          open={open}
          dropdownButtonRef={dropdownButtonRef}
          hideMenu={() => setOpen(false)}
          dropdownWidth={640}
        >
          <MiniTable
            headings={[
              'Output',
              'Format',
              'Codec',
              'Resolution',
            ]}
          >
            { format
              .filter(format => format)
              .map((format, index) => {
              const codec = getCodecReadable(format);
              const resolution = getResolutionReadable(format);
              const key = `${ format?.output } ${ format?.['audio_codec'] }`;
              return (
                <tr key={ key }>
                  <td>
                    {
                      `Output ${ String(index + 1).padStart(2, '0') }`
                    }
                  </td>
                  <td>
                    <span className={style.output}>
                      {
                        videoFormatCodeToReadable[format?.output] ?? ''
                      }
                    </span>
                  </td>
                  <td>
                    { codec }
                  </td>
                  <td>
                    {
                      Array.isArray(resolution) ?
                        ( <HorizontalList list={ resolution }/> )
                        : resolution
                    }
                  </td>
                </tr>
              )
            }) }
          </MiniTable>
        </CellDetails>
        <span>{
          outputReadable
        }</span>
        { multipleFormatItems && (
          <button
            className={style.dropdownButton}
            onClick={handleDropdownClick}
            aria-haspopup="true"
            aria-expanded={open}
            type="button"
            ref={dropdownButtonRef}
          >
            <ChevronDown/>
          </button>
        ) }
      </div>
    );
  }
}

DetailsCell.propTypes = {
  info: PropTypes.object.isRequired,
};

export default DetailsCell;