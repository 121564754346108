import React from 'react';
import PropTypes from 'prop-types';
import style from "./style.module.css";
import InputMask from "react-input-mask";

function ValueRange({
  wrapperRef,
  commonProps,
  startProps,
  endProps,
}) {
  function onKeydown(e) {
    if (e.code === 'Enter') {
      commonProps?.onSubmit?.();
    }
  }
  
  return (
    <div ref={wrapperRef} className={ style.valueRangeWrapper } onKeyDown={onKeydown} >
      <InputMask
        className={ style.input }
        { ...startProps }
        { ...commonProps }
      ></InputMask>
      <div>
        <span className={ style.between }>to</span>
      </div>
      <InputMask
        className={ style.input }
        { ...endProps }
        { ...commonProps }
      ></InputMask>
    </div>
  );
}

ValueRange.propTypes = {
  wrapperRef: PropTypes.shape({ current: PropTypes.any }),
  commonProps: PropTypes.shape({
    placeholder: PropTypes.string,
    mask: PropTypes.string,
    maskChar: PropTypes.string,
    onSubmit: PropTypes.func,
  }),
  startProps: PropTypes.shape({
    ref: PropTypes.shape({ current: PropTypes.any }),
    onChange: PropTypes.func,
    'aria-invalid': PropTypes.bool,
  }),
  endProps: PropTypes.shape({
    ref: PropTypes.shape({ current: PropTypes.any }),
    onChange: PropTypes.func,
    'aria-invalid': PropTypes.bool,
  }),
};

export default ValueRange;