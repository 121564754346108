export const columnIds = {
  jobId: 'jobId',
  project: 'project',
  videoLength: 'videoLength',
  size: 'size',
  dateCreated: 'dateCreated',
  status: 'status',
};

export const columnIdToReadableName = {
  [columnIds.jobId]: 'Job Id',
  [columnIds.project]: 'Project',
  [columnIds.videoLength]: 'Video Length',
  [columnIds.size]: 'Size',
  [columnIds.dateCreated]: 'Date Created',
  [columnIds.status]: 'Status',
};

export const backendParamNames = {
  limit: 'limit',
  offset: 'offset',
  
  sizeMin: 'size_min',
  sizeMax: 'size_max',
  
  startDate: 'start_date',
  endDate: 'end_date',
  
  durationMin: 'duration_min',
  durationMax: 'duration_max',
  
  token: 'token',
  status: 'status',
  projectId: 'project_id',
  projectName: 'project_name',
  
  orderBy: 'order_by',
  orderType: 'order_type',
};

export const backendParamToColumn = {
  [backendParamNames.durationMax]: columnIds.videoLength,
  [backendParamNames.durationMin]: columnIds.videoLength,
  [backendParamNames.sizeMax]: columnIds.size,
  [backendParamNames.sizeMin]: columnIds.size,
  [backendParamNames.startDate]: columnIds.dateCreated,
  [backendParamNames.endDate]: columnIds.dateCreated,
};

export const columnIdToFilterName = {
  [ columnIds.videoLength ]: 'duration',
  [ columnIds.size ]: 'size',
  [ columnIds.dateCreated ]: 'date',
  [ columnIds.status ]: 'status',
};
