import React from "react";
import {Button} from "components/Button";
import {Modal} from "components/Modal";
import {InputField} from "components/InputField";
import PropTypes from "prop-types";
import {renameProject} from "api/qencode-account-api";
import useSingleFieldForm from "helpers/useSingleFieldForm";

function RenameProjectModal({
    open,
    handleCancel,
    projectId,
    onSuccessfulFinish,
  }) {
  const {
    newName,
    submitting,
    errorMessage,
    handleNameInput,
    clearFormAndClose,
    handleConfirmSubmit,
  } = useSingleFieldForm({
    handleCancel,
    onSuccessfulFinish,
    apiFunction: (({ ...rest }) => renameProject({
      id: projectId,
      type: 'vod',
      ...rest,
    })) ,
  });

  return (
    <Modal
      isOpen={open}
      onClose={ clearFormAndClose }
      header={
        <h3>
          Rename Project
        </h3>
      }
      footer={
        <>
          <Button
            variant="modalFooter"
            onClick={ clearFormAndClose }
            type="secondary"
          >
            Cancel
          </Button>
          <Button
            variant="modalFooter"
            state={ submitting ? "disabled" : "default" }
            onClick={ handleConfirmSubmit }
          >
            Save
          </Button>
        </>
      }
    >
      <form
        className="form"
        onSubmit={ handleConfirmSubmit }
      >
        <div className="row">
          <InputField
            placeholder=""
            onChange={ handleNameInput }
            label="New Project Name"
            name="new-name"
            inputText={newName}
            state={errorMessage ? 'error' : 'default'}
            hint={errorMessage}
          />
        </div>
      </form>
    </Modal>
  );
}

RenameProjectModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  onSuccessfulFinish: PropTypes.func,
};

export default RenameProjectModal;