import style from './style.module.css';
import PropTypes from "prop-types";

function HorizontalList({ list }) {
    if (Array.isArray(list)) {
        return (
            <div
               className={style.list}
            >
                { list.reduce((acc, listItem, index) => {
                    if (index > 0 && index < (list.length)) {
                        acc.push(
                            <div key={ `${ listItem }_circle` } className={style.circle}></div>
                        )
                    }
                    acc.push(
                        <span key={listItem}>{listItem}</span>
                    );
                    return acc;
                }, []) }
            </div>
      );
    } else {
        return (
            <span>{list}</span>
        );
    }
}

HorizontalList.propTypes = {
    list: PropTypes.array,
};

export default HorizontalList;