import PropTypes from "prop-types";
import React from "react";

export const StorageQencode = ({ className, color, label, iconColor }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M7.40882 2.75C5.9175 2.75 4.61755 3.76497 4.25586 5.21176L2.93234 10.5058C2.81124 10.9902 2.75 11.4877 2.75 11.987V14.5714C2.75 16.0508 3.94924 17.25 5.42857 17.25H14.5714C16.0508 17.25 17.25 16.0508 17.25 14.5714V11.987C17.25 11.4877 17.1888 10.9902 17.0677 10.5058L15.7441 5.21176C15.3824 3.76497 14.0825 2.75 12.5912 2.75H7.40882ZM2.80064 4.84796C3.32928 2.73341 5.2292 1.25 7.40882 1.25H12.5912C14.7708 1.25 16.6707 2.73341 17.1994 4.84796L18.5229 10.142C18.6737 10.7454 18.75 11.365 18.75 11.987V14.5714C18.75 16.8792 16.8792 18.75 14.5714 18.75H5.42857C3.12081 18.75 1.25 16.8792 1.25 14.5714V11.987C1.25 11.365 1.32628 10.7454 1.47713 10.142L2.80064 4.84796ZM14.4572 11.1429C15.404 11.1429 16.1715 11.9104 16.1715 12.8571V14.4571C16.1715 15.4039 15.404 16.1714 14.4572 16.1714H5.5429C4.59613 16.1714 3.82861 15.4039 3.82861 14.4571V12.8571C3.82861 11.9104 4.59613 11.1429 5.5429 11.1429H14.4572ZM13.7143 12.8571C13.2409 12.8571 12.8572 13.2409 12.8572 13.7143C12.8572 14.1877 13.2409 14.5714 13.7143 14.5714C14.1877 14.5714 14.5715 14.1877 14.5715 13.7143C14.5715 13.2409 14.1877 12.8571 13.7143 12.8571ZM10 13.2H6.57147C6.25588 13.2 6.00004 13.4558 6.00004 13.7714C6.00004 14.087 6.25588 14.3429 6.57147 14.3429H10C10.3156 14.3429 10.5715 14.087 10.5715 13.7714C10.5715 13.4558 10.3156 13.2 10 13.2Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9.9818 4.17969C11.1859 4.17969 12.2678 4.99667 12.3983 6.00447C12.5287 7.01227 11.4468 7.82926 9.9818 7.82926C8.51676 7.82926 7.43488 7.01227 7.56534 6.00447C7.69581 4.99667 8.77769 4.17969 9.9818 4.17969ZM7.5 8.77512C7.99032 8.42879 8.55391 8.25563 9.20186 8.25563C9.76287 8.25563 10.1119 8.37538 10.4143 8.47914C10.6358 8.55514 10.8322 8.62255 11.0686 8.62803C11.4418 8.63667 11.7396 8.57826 11.9597 8.4528L12.5 8.96409C12.1056 9.29347 11.5628 9.46507 10.8807 9.47889C10.2897 9.49085 9.90033 9.36628 9.56406 9.2587C9.31731 9.17976 9.09915 9.10997 8.85089 9.10997C8.45983 9.10997 8.15447 9.17066 7.93308 9.29204L7.5 8.77512ZM9.98185 5.09209C9.34718 5.09209 8.80623 5.50058 8.77362 6.00448C8.741 6.50838 9.28194 6.91687 9.98185 6.91687C10.6817 6.91687 11.2227 6.50838 11.1901 6.00448C11.1575 5.50058 10.6165 5.09209 9.98185 5.09209Z" fill={iconColor || 'currentColor'}/>
    </svg>    
  );
};

StorageQencode.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  iconColor: PropTypes.string,
};

StorageQencode.defaultProps = {
  color: 'currentColor', // Or any default color
  iconColor: '#316FEA' // Default color for icon
};