import PropTypes from "prop-types";
import React from "react";

export const GoogleCloud = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"
    >
      <path d="M14.7185 7.22112C14.408 6.11268 13.7847 5.1367 12.9481 4.39062L11.0968 6.24192C11.772 6.79151 12.2274 7.6004 12.3103 8.51598V9.12315C12.3208 9.12315 12.3304 9.11993 12.3409 9.11993C13.2758 9.11993 14.0368 9.8809 14.0368 10.8158C14.0368 11.7507 13.2758 12.5121 12.3409 12.5121C12.3304 12.5121 12.3208 12.5093 12.3103 12.5089V12.5121H9.62637H9.00391V15.1292H12.3103V15.1276C12.3208 15.1276 12.3304 15.1292 12.3409 15.1292C14.7193 15.1292 16.6539 13.1942 16.6539 10.8162C16.6539 9.31641 15.8833 7.99417 14.7185 7.22112Z" fill="#1976D2"/>
      <path opacity="0.5" d="M7.21416 10.1322L9.11458 8.2318C9.11297 8.22978 9.11136 8.22817 9.11015 8.22656L7.21094 10.1258C7.21174 10.1278 7.21295 10.1298 7.21416 10.1322Z" fill="#FFE082"/>
      <path opacity="0.5" d="M7.21416 10.1322L9.11458 8.2318C9.11297 8.22978 9.11136 8.22817 9.11015 8.22656L7.21094 10.1258C7.21174 10.1278 7.21295 10.1298 7.21416 10.1322Z" fill="#90CAF9"/>
      <path d="M9.00131 2.87891C5.72671 2.87891 3.0625 5.54312 3.0625 8.81772C3.0625 8.91153 3.07216 9.00293 3.07659 9.09553H5.69369C5.68604 9.00373 5.6796 8.91153 5.6796 8.81772C5.6796 6.98615 7.16974 5.49601 9.00131 5.49601C9.79409 5.49601 10.5225 5.77624 11.0942 6.24128L12.9455 4.38998C11.8954 3.45346 10.5156 2.87891 9.00131 2.87891Z" fill="#FF3D00"/>
      <path opacity="0.5" d="M5.66463 12.5096H5.66382C5.41781 12.5096 5.1855 12.4544 4.97452 12.3594L3.07812 14.2558C3.80004 14.7997 4.69388 15.1267 5.66382 15.1267H5.66463H9.00163V12.5096H5.66463Z" fill="#90CAF9"/>
      <path d="M5.66463 12.5096H5.66382C5.41781 12.5096 5.1855 12.4544 4.97452 12.3594L3.07812 14.2558C3.80004 14.7997 4.69388 15.1267 5.66382 15.1267H5.66463H9.00163V12.5096H5.66463Z" fill="#4CAF50"/>
      <path d="M3.96867 10.8161C3.96867 9.88198 4.72964 9.12101 5.66374 9.12101C6.34942 9.12101 6.94331 9.5329 7.21025 10.1252L9.10946 8.22596C8.3199 7.17831 7.07013 6.50391 5.66334 6.50391C3.28822 6.50391 1.35156 8.43654 1.35156 10.8161C1.35156 12.2217 2.03241 13.4694 3.07805 14.2574L4.97444 12.361C4.38338 12.0961 3.96867 11.5042 3.96867 10.8161Z" fill="#FFC107"/>
    </svg>    
  );
};

GoogleCloud.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

GoogleCloud.defaultProps = {
  color: 'currentColor', // Or any default color
};