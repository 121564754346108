import PropTypes from "prop-types";
import React from "react";

export const RightArrow = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M11.0038 6.41464C11.0043 6.15119 11.104 5.86522 11.3624 5.81365C11.5841 5.76938 11.8231 5.83319 11.995 6.00507L14.995 9.00507C15.2684 9.27844 15.2684 9.72166 14.995 9.99502L11.995 12.995C11.8239 13.1662 11.5862 13.2302 11.3653 13.187C11.105 13.1362 11.0051 12.8478 11.005 12.5826C11.0044 11.0018 11.0012 7.7598 11.0038 6.41464Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2.50005 3.80005C2.88665 3.80005 3.20005 4.11345 3.20005 4.50005V6.58718C3.20005 7.85743 4.22979 8.88718 5.50005 8.88718H13.8969C14.2835 8.88718 14.5969 9.20058 14.5969 9.58718C14.5969 9.97378 14.2835 10.2872 13.8969 10.2872H5.50005C3.4566 10.2872 1.80005 8.63063 1.80005 6.58718V4.50005C1.80005 4.11345 2.11345 3.80005 2.50005 3.80005Z" fill="currentColor"/>
    </svg>    
  );
};

RightArrow.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

RightArrow.defaultProps = {
  color: 'currentColor', // Or any default color
};