import React, {useCallback} from 'react';
import useDataLoadingStatus from "hooks/useDataLoadingStatus";
import {getBucket} from "api/qencode-account-api";
import {useParams} from "react-router-dom";

function BucketDetailPage() {
  const { region, bucket } = useParams();
  const loadBuckets = useCallback((rest) => {
    return getBucket({
      ...rest,
      region,
      bucket,
    })
  }, [bucket, region])
  const loadingStatus = useDataLoadingStatus( loadBuckets );
  console.log( loadingStatus );
  return (
    <></>
  )
}

BucketDetailPage.propTypes = {};

export default BucketDetailPage;