import PropTypes from "prop-types";
import React from "react";

export const Edit = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path d="M10.1738 5.52773L11.3285 4.33764C11.5208 4.13945 11.8373 4.13467 12.0355 4.32696L12.0468 4.33831L12.6839 4.99736C12.8733 5.19327 12.8709 5.50472 12.6786 5.69775L11.509 6.8718L10.1738 5.52773Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.1426 3.12726C10.9618 2.31224 12.2855 2.31223 13.1048 3.12726L13.8544 3.87303C14.6766 4.69102 14.68 6.02066 13.862 6.84286L13.8544 6.8505L7.13181 13.5386C6.87879 13.7904 6.56579 13.9734 6.22236 14.0706L3.50833 14.8384C2.92376 15.0038 2.3158 14.664 2.15043 14.0794C2.09665 13.8893 2.09506 13.6882 2.14582 13.4973L2.87261 10.7636C2.96815 10.4042 3.1574 10.0766 3.42101 9.81438L10.1426 3.12726ZM12.2584 3.97796C11.9073 3.62867 11.34 3.62867 10.9889 3.97796L4.64943 10.285L6.67511 12.3003L13.008 5.99979L13.0113 5.99653C13.3619 5.64415 13.3604 5.0743 13.008 4.72373L12.2584 3.97796ZM5.65962 12.9827L3.97096 11.3027L3.35065 13.6359L5.65962 12.9827Z" fill="currentColor"/>
    </svg>    
  );
};

Edit.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Edit.defaultProps = {
  color: 'currentColor', // Or any default color
};