import PropTypes from "prop-types";
import React from "react";

export const Receipt = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M5.49995 2C4.06398 2 2.8999 3.16406 2.8999 4.6V13.6029C2.8999 14.5082 3.93241 15.0259 4.6579 14.4844L6.1525 13.3688L7.7939 14.7835C8.19491 15.1292 8.78523 15.1401 9.19879 14.8096L11.0023 13.3684L12.3125 14.4176C13.0326 14.9943 14.1001 14.4816 14.1001 13.559V4.6C14.1001 3.16406 12.936 2 11.5001 2H5.49995ZM4.09992 4.6C4.09992 3.8268 4.72674 3.2 5.49995 3.2H11.5001C12.2733 3.2 12.9001 3.8268 12.9001 4.6V13.3508L11.69 12.3817C11.2884 12.0601 10.7176 12.0598 10.3157 12.381L8.51448 13.8204L6.87516 12.4075C6.48449 12.0708 5.91234 12.0507 5.49902 12.3592L4.09992 13.4035V4.6ZM5.99996 5C5.66858 5 5.39995 5.26863 5.39995 5.6C5.39995 5.93137 5.66858 6.2 5.99996 6.2H11C11.3314 6.2 11.6001 5.93137 11.6001 5.6C11.6001 5.26863 11.3314 5 11 5H5.99996ZM5.99996 7.5C5.66858 7.5 5.39995 7.76863 5.39995 8.1C5.39995 8.43137 5.66858 8.7 5.99996 8.7H11C11.3314 8.7 11.6001 8.43137 11.6001 8.1C11.6001 7.76863 11.3314 7.5 11 7.5H5.99996ZM5.39995 10.6C5.39995 10.2686 5.66858 10 5.99996 10H9.00001C9.33139 10 9.60002 10.2686 9.60002 10.6C9.60002 10.9314 9.33139 11.2 9.00001 11.2H5.99996C5.66858 11.2 5.39995 10.9314 5.39995 10.6Z" fill="currentColor"/>
    </svg>    
  );
};

Receipt.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Receipt.defaultProps = {
  color: 'currentColor', // Or any default color
};