import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as StepComplete } from 'assets/images/StepComplete.svg';
import { ReactComponent as StepPending } from 'assets/images/StepPending.svg';
import { ReactComponent as StepError } from 'assets/images/StepError.svg';

import styles from './Stepper.module.css';

export const Stepper = ({ position, passed, nextStepPassed, nextStepError, error, title, timestamp, duration, loading }) => {

    const stepIcon = () => {
        switch (true) {
            case error:
                return <StepError />;
            case passed:
                return <StepComplete />;
            case loading:
                return <div className={`${styles.loadingIcon} skeleton-loading`}></div>;                
            default:
                return <StepPending />;
        }
    };

    return (
        <div className={`${styles.stepper} ${styles[position]} ${passed ? styles.passed : ''} ${nextStepPassed ? styles.nextPassed : ''} ${nextStepError ? styles.nextError : ''} ${error ? styles.error : ''}`}>
            <div className={styles.header}>
                <div className={`${styles.stepLine} ${styles.left}`}></div>
                {stepIcon()} 
                <div className={`${styles.stepLine} ${styles.right}`}></div>
            </div>
            <div className={styles.details}>
                {
                    loading
                    ?
                    <>
                        <div className={`${styles.title_Loading} skeleton-loading`}></div>
                        <div className={`${styles.timestamp_Loading} skeleton-loading`}></div>
                    </>
                    :
                    <>
                        <div className={styles.title}>
                            <span className={styles.titleText}>{title}</span> 
                            <span className={styles.duration}>{duration}</span>
                        </div>
                        <div className={styles.timestamp}>{timestamp}</div>
                    </>
                }                
            </div>
        </div>
    );
};

Stepper.propTypes = {
    passed: PropTypes.bool, 
    error: PropTypes.bool,
    position: PropTypes.string, // 'start', 'middle', 'end'
    title: PropTypes.string,
    timestamp: PropTypes.string,
    loading: PropTypes.bool
    //duration: PropTypes.string,
};

Stepper.defaultProps = {
    passed: false,
    error: false,
    position: 'start',
    loading: false
};

export default Stepper;




// import React from 'react';
// import PropTypes from 'prop-types';
// import styles from './Stepper.module.css';

// export const Stepper = ({ position, passed, error, title, timestamp, duration }) => {
//     return (
//         <div className={`${styles.stepper} ${styles[position]}`}>
//             <div className={`${styles.circle} ${passed ? styles.passed : ''} ${error ? styles.error : ''}`}>
//                 {error ? <span className={styles.errorIcon}>X</span> : <span className={styles.checkIcon}>✔</span>}
//             </div>
//             <div className={styles.line}></div>
//             <div className={styles.details}>
//                 <div className={styles.title}>{title} {duration && <span className={styles.duration}>({duration})</span>}</div>
//                 <div className={styles.timestamp}>{timestamp}</div>
//             </div>
//         </div>
//     );
// };

// Stepper.propTypes = {
//     passed: PropTypes.bool, 
//     error: PropTypes.bool,
//     position: PropTypes.string, // 'start', 'middle', 'end'
//     title: PropTypes.string.isRequired,
//     timestamp: PropTypes.string,
//     duration: PropTypes.string,
// };

// Stepper.defaultProps = {
//     passed: false,
//     error: false,
//     position: 'start',
// };
