import React, { 
    useState, useEffect, useCallback 
    //useRef 
} from 'react';
import PropTypes from 'prop-types';
import { Select } from 'components/Select';
import { InputField } from 'components/InputField'
import { Label } from 'components/Label';
import { Button } from 'components/Button'
import { FormToggle } from 'components/FormToggle';
import { Delete, Duplicate, ChevronDown, ChevronUp, Add, Info } from 'icons';

import { ReactComponent as FormatMP4Image } from 'assets/images/FormatMP4.svg'
import { ReactComponent as FormatHLSImage } from 'assets/images/FormatHLS.svg'
import { ReactComponent as FormatMPEGDASHImage } from 'assets/images/FormatMPEGDASH.svg'

import { ReactComponent as FormatSTTImage } from 'assets/images/FormatSpeechToText.svg'
import { ReactComponent as FormatWebMImage } from 'assets/images/FormatWebM.svg'
import { ReactComponent as FormatRepackImage } from 'assets/images/FormatRepack.svg'

import { ReactComponent as FormatMP3Image } from 'assets/images/FormatMP3.svg'
import { ReactComponent as FormatHLSAudioImage } from 'assets/images/FormatHLSAudio.svg'
import { ReactComponent as FormatGifImage } from 'assets/images/FormatGif.svg'

import { ReactComponent as FormatThumbnailImage } from 'assets/images/FormatThumbnail.svg'
import { ReactComponent as FormatVTTImage } from 'assets/images/FormatVTT.svg'

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import styles from '../index.module.css';

import { 
    getLabelByValue, 
    outputOptions, 
    videoCodecOption,
    audioCodecOption,
    imageFormatOptions, 
    fileExtentionOptions,
    modeOption,
    languageOptions
} from './optionsMap';

import { 
    //getFileNameFromUrl, 
    getFileNameWithoutExtension, getFileExtension, 
    getDefaultFormat, 
    //getPath,
    getBitrateByHeight, defaultQuality 
} from './helpers'

import { parseDestinationUrl } from 'helpers/urlParser';

import { AdvancedSettingsModal } from './AdvancedSettingsModal';

//import { getDocsTooltipData } from 'helpers/getDocsTooltipData'

import { tooltipData } from 'helpers/docsTooltipData'

export const OutputCard = ({ index, format, initialFormat, destination, videoSrc, onFormatUpdate, onFormatDelete, onFormatDuplicate }) => {

    console.log("format: ", format)
   
    // console.log("tooltipData: ", tooltipData({
    //     name: 'audio_bitrate',
    //     api: 'transcoding'
    // }))


    const [savedBitrates, setSavedBitrates] = useState({}); // when there is stream list

    const [savedQuality, setSavedQuality] = useState(format.quality);

    const [encryptionToggle, setEncryptionToggle] = useState(!!format?.encryption);   
    const [cencDrmToggle, setCencDrmToggle] = useState(!!format?.cenc_drm);   
    const [fpsDrmToggle, setFpsDrmToggle] = useState(!!format?.fps_drm);   

    // mode help content
    const modeHelpContent = {
        balanced: {
            no_header: true,
            title: 'Balanced',
            content: 'Balance between speed and accuracy. This is the default.' 
        },
        accuracy: {
            no_header: true,
            title: 'Accuracy',
            content: 'More accurate in some cases at the expense of speed.' 
        },
        speed: {
            no_header: true,
            title: 'Speed',
            content: 'Faster at the expense of being more accurate in some cases.' 
        }
    }

    //console.log("videoSrc: ", videoSrc)

    //const [outputPath, setOutputPath] = useState(`${getFileNameWithoutExtension(videoSrc)}${getFileExtension(format?.output)}` || '');
    
    //const [pathName, setPathName] = useState(parseDestinationUrl(format?.destination?.url)?.path || '')

    const [fileName, setFileName] = useState('');

    // Extract the file name from the URL and set it to the state
    // useEffect(() => {
    //     const url = format?.destination?.url || '';
    //     // const parsed = parseDestinationUrl(url);
    //     // setFileName(parsed.path || ''); // Get the path part of the URL
    //     const fileName = getFileNameFromUrl(url)
    //     setFileName(fileName || ''); // Get the path part of the URL
    // }, [format]);    

    useEffect(() => {
        const url = format?.destination?.url || '';
        const { path } = parseDestinationUrl(url)
        setFileName(path || ''); // Get the path part of the URL
    }, [format]);     
    
        
    
    // detect if value of videoSrc actually changed
    // const prevVideoSrcRef = useRef();
    // const prevVideoSrc = prevVideoSrcRef.current; 

    // detect if value of destination actually changed
    // const prevDestinationRef = useRef();
    // const prevDestination = prevDestinationRef.current;     


    // const [outputPath, setOutputPath] = useState(() => {
    //     if (format?.destination?.url) {
    //         return getFileNameFromUrl(format.destination.url);
    //     }
    //     return `${getFileNameWithoutExtension(videoSrc)}${getFileExtension(format?.output)}` || '';
    // });

    // const [isExpanded, setIsExpanded] = useState(false);
    const [isExpanded, setIsExpanded] = useState(true);

    // save inital bitrates in case there is stream field with elements
    useEffect(() => {
        if (initialFormat?.stream && initialFormat.stream.length > 0) {
            const initialBitrates = initialFormat.stream.reduce((acc, item, idx) => {
                acc[idx] = item.bitrate;
                return acc;
            }, {});
            setSavedBitrates(initialBitrates);
        }
    }, [initialFormat]);

    
    // Destination is not updated when edited in form
    // Update the destination for each format when the destination or videoSrc changes
    // useEffect(() => {
    //     //console.log("format: ", format)
    //     prevVideoSrcRef.current = videoSrc;

    //     //console.log("DESTINATION: ", destination)

    //     //prevDestinationRef.current = destination

    //     // console.log("prevVideoSrc: ", prevVideoSrc)
    //     // console.log("videoSrc: ", videoSrc)

    //     // console.log({
    //     //     index,
    //     //     prevDestination,
    //     //     destination
    //     // })

    //     // console.log(JSON.stringify(destination) !== JSON.stringify(prevDestination))

    //     // console.log("prevVideoSrc !== videoSrc :", prevVideoSrc !== videoSrc)


    //     //const outputPathFromSrc = `${getFileNameWithoutExtension(videoSrc)}${getFileExtension(format?.output)}`;
    //     //console.log({outputPathFromSrc})


    //     const baseUrl = destination ? destination.url : '';
    //     let newUrl = `${baseUrl}/${outputPath}`     

    //     const fileNameFromFormat = getFileNameFromUrl(format?.destination?.url)
    //     if(fileNameFromFormat){
    //         newUrl = `${baseUrl}/${fileNameFromFormat}`
    //         //console.log("fileNameFromFormat: ", fileNameFromFormat)
    //         setOutputPath(fileNameFromFormat)  
    //     }
        
    //     // ok, need to get outputPath value from format
    //     //console.log("getFileNameFromUrl: ", getFileNameFromUrl(format?.destination?.url))
        
    //     // console.log({
    //     //     baseUrl,
    //     //     newUrl,
    //     //     fileNameFromFormat,
    //     //     outputPath
    //     // })

    //     console.log({
    //         newUrl,
    //         destination,
    //         videoSrc,
    //         prevVideoSrc
    //     })

    //     const needsUpdate = (destination && (!format.destination || format.destination.url !== newUrl)) ||
    //                         (!destination && format.destination) ||
    //                         (videoSrc !== prevVideoSrc) 
    //                         // ||
    //                         // (JSON.stringify(destination) !== JSON.stringify(prevDestination))
      


    //     // format.destination.url                    
    //     // s3://us-west.s3-qa.qencode.com/bucket-2024-05-27-test-internalapi/jhgjl.webm


    //     // console.log("needsUpdate: ", needsUpdate)
    //     // console.log("outputPath: ", outputPath)

    //     console.log("needsUpdate: ", needsUpdate)

    //     if (needsUpdate) {            
    //         if (destination) {
    //             // if(videoSrc !== prevVideoSrc){
    //             //     newUrl = `${baseUrl}/${outputPathFromSrc}`
    //             // }
    //             // console.log("UPFATE FORMAT newUrl ", newUrl)
    //             console.log("UPDATE FORMAT HERE...")
    //             const updatedFormat = { ...format, destination: { ...destination, url: newUrl } };
    //             //setOutputPath(outputPath)
    //             onFormatUpdate(updatedFormat);
    //         } else {
    //             const { destination, ...rest } = format;
    //             onFormatUpdate(rest);
    //         }
    //     }
    // }, [destination, videoSrc, prevVideoSrc, format, onFormatUpdate, outputPath, index]);       
    

    const toggleExpand = () => setIsExpanded(!isExpanded);


    const handleOutputChange = (e) => {
        const { name, value } = e.target;
        console.log({name, value});

        //console.log("destination in handleOutputChange: ", destination)

        let updatedFormat = getDefaultFormat({
            output: value,
            videoSrc,
            //destination
            destination: format?.destination
        });

        // update output select
        updatedFormat = { 
            ...updatedFormat, 
            [name]: value,             
        };

        // save bitrates, so can retore them when toggle
        if (updatedFormat?.stream && updatedFormat.stream.length > 0) {
            const initialBitrates = updatedFormat.stream.reduce((acc, item, idx) => {
                acc[idx] = item.bitrate;
                return acc;
            }, {});
            setSavedBitrates(initialBitrates);
        }

        // update output path - not sure if need to do it
        // const pathName = `${getFileNameWithoutExtension(videoSrc)}${getFileExtension(updatedFormat?.output)}`
        // setOutputPath(pathName)

        onFormatUpdate(updatedFormat); // update state in parent immediately
    };


    const handleSetOutput = (value) => {
        const name = 'output'
        const event = {
            target: {
                name: name,
                value: value,
            }
        };
        handleOutputChange(event); // Pass the simulated event object        
    }    


    // to deal with all input and select fields
    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;

    //     console.log({name, value})

    //     let updatedFormat = {}

    //     // true is 1 and false is 0 - used for toggles
    //     const fieldsToTransform = [
    //         'audio_mute', 'deinterlace',         // toggles in video formats
    //         'video_copy', 'audio_copy', 'subtitles_copy', 'metadata_copy', // toggles for repack
    //         'transcript', 'json', 'srt', 'vtt', // toggles for speech_to_text
    //         'fmp4', 'separate_audio'
    //     ]; // Add other fields here as needed

    //     let transformedValue = value;
    //     if (fieldsToTransform.includes(name)) {
    //         transformedValue = value === 'true' || value === true ? 1 : 0;
    //     }        

    //     // check if there is stream list and update video_codec in every element of the list
    //     // if (format?.stream && format?.stream?.length > 0) {
    //     if (format?.stream && format?.stream?.length > 0 && name === 'video_codec') {
    //         // let updatedStream = format.stream.map(item => ({
    //         //     ...item,
    //         //     video_codec: name === 'video_codec' ? value : item.video_codec
    //         // }));
    //         // updatedFormat = { ...format, stream: updatedStream };

    //         let updatedStream = format.stream.map(item => ({
    //             ...item,
    //             [name]: value
    //         }));
    //         updatedFormat = { ...format, stream: updatedStream };       
    //     } else {
    //         updatedFormat = { ...format, [name]: transformedValue };
    //     }

    //     // delete language field if default is selected
    //     if(format?.output === 'speech_to_text' && name === 'language'){
    //         if(transformedValue === 'default'){
    //             delete updatedFormat.language;
    //         }
    //     }

    //     // delete fields that are turned off, or empty or value not set
    //     const fieldsToDelete = [
    //         'audio_mute', 'deinterlace', // video formats
    //         'audio_bitrate', 'audio_sample_rate', 'audio_channels_number', // mp3
    //         'width', 'height', 'interval', // thumbnails
    //         'video_copy', 'audio_copy', 'subtitles_copy', 'metadata_copy', // repack
    //         'framerate', 'start_time', 'duration', // gif
    //         'fmp4'
    //     ];

    //     for (const field of fieldsToDelete) {
    //         if (updatedFormat[field] === 0 || updatedFormat[field] === '' || !updatedFormat[field]) {
    //             delete updatedFormat[field];
    //         }
    //     }

    //     onFormatUpdate(updatedFormat); // update state in parent immediately
    // };    


    // support encryption fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        console.log({ name, value });
    
        let updatedFormat = { ...format };
    
        // true is 1 and false is 0 - used for toggles
        const fieldsToTransform = [
            'audio_mute', 'deinterlace',         // toggles in video formats
            'video_copy', 'audio_copy', 'subtitles_copy', 'metadata_copy', // toggles for repack
            'transcript', 'json', 'srt', 'vtt', // toggles for speech_to_text
            'fmp4', 'separate_audio',
            'allow_soft_fail'
        ]; // Add other fields here as needed
    
        let transformedValue = value;
        if (fieldsToTransform.includes(name)) {
            transformedValue = value === 'true' || value === true ? 1 : 0;
        }
    
        // Check if the field is nested
        const nestedFields = name.split('.');
        if (nestedFields.length > 1) {
            // Handle nested fields
            const [parentField, childField] = nestedFields;
            if (!updatedFormat[parentField]) {
                updatedFormat[parentField] = {};
            }
            updatedFormat[parentField][childField] = transformedValue;
        } else {
            // Check if there is stream list and update video_codec in every element of the list
            if (format?.stream && format?.stream?.length > 0 && (name === 'video_codec' || name === 'keyframe' || name === 'framerate')) {
                // let updatedStream = format.stream.map(item => ({
                //     ...item,
                //     [name]: value
                // }));
                // updatedFormat = { ...format, stream: updatedStream };

                let updatedStream = format.stream.map(item => {
                    const updatedItem = { ...item, [name]: transformedValue };
    
                    // Delete keyframe or framerate if they are empty
                    if ((name === 'keyframe' || name === 'framerate') && !transformedValue) {
                        delete updatedItem[name];
                    }
    
                    return updatedItem;
                });
                updatedFormat = { ...format, stream: updatedStream };
                
            } else {
                updatedFormat[name] = transformedValue;
            }
        }

        // update destination url if this is repack
        // this needs also to be handled on source update and destination update in TranscodingPage
        if (format?.output === 'repack' && name === 'file_extension' && destination?.url) {
            //console.log("format.destination.url: ", format.destination.url)
            const { baseUrl } = parseDestinationUrl(destination?.url)
            const fileName = getFileNameWithoutExtension(videoSrc) || '';
            const extension = getFileExtension(value) || ''
            const newUrl = `${baseUrl}${fileName}${extension}`;                          
            //console.log("newUrl: ", newUrl)
            updatedFormat.destination.url = newUrl
        }   

        if (format?.output === 'thumbnail' && name === 'image_format' && destination?.url) {
            //console.log("format.destination.url: ", format.destination.url)
            const { baseUrl } = parseDestinationUrl(destination?.url)
            const fileName = getFileNameWithoutExtension(videoSrc) || '';
            const extension = getFileExtension(value) || ''
            const newUrl = `${baseUrl}${fileName}${extension}`;                          
            //console.log("newUrl: ", newUrl)
            updatedFormat.destination.url = newUrl
        }           
    
        // Delete language field if default is selected
        if (format?.output === 'speech_to_text' && name === 'language') {
            if (transformedValue === 'default') {
                delete updatedFormat.language;
            }
        }
    
        // Delete fields that are turned off, or empty or value not set
        const fieldsToDelete = [
            'audio_mute', 'deinterlace', // video formats
            'audio_bitrate', 'audio_sample_rate', 'audio_channels_number', // mp3
            'width', 'height', 'interval', // thumbnails
            'video_copy', 'audio_copy', 'subtitles_copy', 'metadata_copy', // repack
            'framerate', 'start_time', 'duration', // gif
            'fmp4',
            'playlist_name', 'segment_duration',
            'time',
            'allow_soft_fail'
        ];
    
        for (const field of fieldsToDelete) {
            if (updatedFormat[field] === 0 || updatedFormat[field] === '' || !updatedFormat[field]) {
                delete updatedFormat[field];
            }
        }
    
        // Clean nested fields if they are empty
        if (updatedFormat.encryption) {
            ['key', 'iv', 'key_url'].forEach(key => {
                if (updatedFormat.encryption[key] === '') {
                    delete updatedFormat.encryption[key];
                }
            });
            if (Object.keys(updatedFormat.encryption).length === 0) {
                delete updatedFormat.encryption;
                setEncryptionToggle(false);
            }
        }   
        
        // Clean nested fields if they are empty for cenc_drm
        if (updatedFormat.cenc_drm) {
            ['key', 'key_id', 'pssh', 'la_url'].forEach(key => {
                if (updatedFormat.cenc_drm[key] === '') {
                    delete updatedFormat.cenc_drm[key];
                }
            });
            if (Object.keys(updatedFormat.cenc_drm).length === 0) {
                delete updatedFormat.cenc_drm;
                setCencDrmToggle(false)
            }
        }           

        // Clean nested fields if they are empty for fps_drm
        if (updatedFormat.fps_drm) {
            ['key', 'iv', 'key_url'].forEach(key => {
                if (updatedFormat.fps_drm[key] === '') {
                    delete updatedFormat.fps_drm[key];
                }
            });
            if (Object.keys(updatedFormat.fps_drm).length === 0) {
                delete updatedFormat.fps_drm;
                setFpsDrmToggle(false)
            }
        }          
    
        onFormatUpdate(updatedFormat); // update state in parent immediately
    };
    


    const handleBitrateToggle = (e) => {
        console.log("savedBitrates: ", savedBitrates)

        const { name, value } = e.target;

        let updatedFormat = {}

        // true is 1 and false is 0

        let transformedValue = value === 'true' || value === true ? 1 : 0;
   
        // check if there is stream list and update video_codec in every element of the list
        if (format?.stream && format?.stream?.length > 0) {
            let updatedStream
            // if optimize_bitrate is 1 in transformedValue remove bitrate field from each element in stream
            // if optimize_bitrate is 0 in transformedValue remove optimize_bitrate field and restore bitrate field with it's valur
            // so bitrate field and it's value should be remembered

            if (transformedValue === 1) {
                updatedStream = format.stream.map((item) => {
                    const updatedItem = { 
                        ...item,
                        optimize_bitrate: 1
                    };
                    delete updatedItem.bitrate;
                    return updatedItem;
                });
            } else {             
                updatedStream = format.stream.map((item, idx) => {
                    //if there is no saved bitrate, like if it was not there initially, 
                    // find bitrate from defaultResolutions map by height
                    const bitrate = savedBitrates[idx] !== undefined ? savedBitrates[idx] : getBitrateByHeight(item.height);
                    const updatedItem = { 
                        ...item,
                        //bitrate: savedBitrates[idx], // getBitrateByHeight
                        //bitrate: savedBitrates[idx] !== undefined ? savedBitrates[idx] : getBitrateByHeight(item?.height),
                    };

                    // only add bitrate field if value is found
                    if (bitrate !== undefined && bitrate !== null) {
                        updatedItem.bitrate = bitrate;
                    }

                    delete updatedItem.optimize_bitrate;

                    // delete fields
                    delete updatedItem.adjust_crf;
                    delete updatedItem.min_crf;
                    delete updatedItem.max_crf;    

                    return updatedItem;
                });     
            }     

            updatedFormat = { ...format, stream: updatedStream };
        } else {
            
            // if optimize_bitrate is 1 in transformedValue remove "quality" field but need to remember it's value
            // when it's switched to 0 need to restore "quality" field with it's value and remove optimize_bitrate field

            updatedFormat = { ...format, [name]: transformedValue };


            if (transformedValue === 1) {
                setSavedQuality(format.quality);
                delete updatedFormat.quality;
            } else {
                const quality = savedQuality || defaultQuality // quality is 20 by default for all
                updatedFormat.quality = quality;
                delete updatedFormat.optimize_bitrate;

                // delete fields
                delete updatedFormat.adjust_crf;
                delete updatedFormat.min_crf;
                delete updatedFormat.max_crf;                   
            }

        }


        onFormatUpdate(updatedFormat); // update state in parent immediately        
    }

    // original
    // const handleOutputPathChange = (e) => {
    //     const { value } = e.target;

    //     const baseUrl = destination ? destination.url : '';
    //     const newUrl = `${baseUrl}/${value}`    
    
    //     const updatedFormat = {
    //         ...format,
    //         destination: {
    //             ...format.destination,
    //             //url: value,             // actually url here should be different
    //             url: newUrl 
    //         },
    //     };
    
    //     setOutputPath(value);
    //     onFormatUpdate(updatedFormat); // update state in parent immediately
    // };       
          
    const handlePathChange = useCallback((e) => {
        const { value } = e.target;
        setFileName(value);

        const baseUrl = destination?.url || ''

        // Update the format object with the new URL
        const updatedFormat = {
            ...format,
            destination: {
                ...format.destination,
                url: `${baseUrl}${value}`
            }
        };

        onFormatUpdate(updatedFormat);
    }, [format, destination, onFormatUpdate]);

    const handleDeleteClick = (e) => {
        e.stopPropagation(); // Prevents the toggleExpand from being triggered
        onFormatDelete();
    };

    const handleDuplicateClick = (e) => {
        e.stopPropagation(); // Prevents the toggleExpand from being triggered
        onFormatDuplicate();
    };

    // working with resolutions
    const handleDeleteResolution = (index) => {
        const updatedStream = format.stream.filter((_, i) => i !== index);
        const updatedFormat = { ...format, stream: updatedStream };
        onFormatUpdate(updatedFormat);
    };


    const handleAddSize = () => {
        let newResolution = {
            video_codec: format?.stream[0]?.video_codec,    // codec should match existing
            height: 2160,                                    // Default height, change as needed
        };

        // // if optimize_bitrate toggle is on
        // if(format?.stream[0]?.optimize_bitrate){
        //     newResolution.optimize_bitrate = 1
        // } else {
        //     newResolution.bitrate = 12000
        // }

        // if optimize_bitrate toggle is on for all elements in the stream array
        if (format?.stream.every(item => item.optimize_bitrate === 1)) {
            newResolution.optimize_bitrate = 1;
        } else {
            newResolution.bitrate = 12000;
        }        

        const updatedStream = [...format.stream, newResolution];
        const updatedFormat = { ...format, stream: updatedStream };
        onFormatUpdate(updatedFormat);
    };


    // dealing with subtitles
    const handleSubtitlesChange = () => {
        //const { name, value } = e.target;
        //console.log({name, value})

        let updatedFormat = { ...format };

        // if there are subs, delete them, otherwise - add them
        if(format?.subtitles){
            delete updatedFormat.subtitles;
        } else {
            const defaultSubsObject = {
                sources: [                    
                    {
                        source: '',
                        language: ''
                    }
                ],
                //copy: 0
            }

            updatedFormat = { ...format, subtitles: defaultSubsObject };
        }

        onFormatUpdate(updatedFormat);
    }

    // dealing with encryption
    // const handleEncryptionChange = () => {
    //     let updatedFormat = { ...format };

    //     if(format?.encryption){
    //         delete updatedFormat.encryption;
    //         onFormatUpdate(updatedFormat);
    //     }         

    //     setEncryptionToggle(!encryptionToggle)
    // }    


    // dealing with encryption
    const handleEncryptionChange = () => {
        let updatedFormat = { ...format };

        if (encryptionToggle) {
            delete updatedFormat.encryption;
            onFormatUpdate(updatedFormat);
            setEncryptionToggle(false);
        } else {
            setEncryptionToggle(true);
        }
    };

    const handleCencDrmChange = () => {
        let updatedFormat = { ...format };

        if (cencDrmToggle) {
            delete updatedFormat.cenc_drm;
            onFormatUpdate(updatedFormat);
            setCencDrmToggle(false);
        } else {
            setCencDrmToggle(true);
        }
    };    

    const handleFpsDrmChange = () => {
        let updatedFormat = { ...format };

        if (fpsDrmToggle) {
            delete updatedFormat.fps_drm;
            onFormatUpdate(updatedFormat);
            setFpsDrmToggle(false);
        } else {
            setFpsDrmToggle(true);
        }
    };        

    const handleSubtitlesCopyChange = (e) => {
        const { name, value } = e.target;


        // true is 1 and false is 0
        const fieldsToTransform = ['subtitles.copy']; // Add other fields here as needed

        let transformedValue = value;
        if (fieldsToTransform.includes(name)) {
            transformedValue = value === 'true' || value === true ? 1 : 0;
        }      

        let updatedFormat = {
            ...format,
            subtitles: {
                ...format.subtitles,
                copy: transformedValue,
            },
        };

        // delete if value is 0
        if(transformedValue === 0){
            delete updatedFormat.subtitles.copy;
        }

        onFormatUpdate(updatedFormat);
    }

    const handleSubtitleChange = (index, key, value) => {
        const updatedSubtitles = format.subtitles.sources.map((subtitle, i) => {
            if (i === index) {
                return { ...subtitle, [key]: value };
            }
            return subtitle;
        });
        const updatedFormat = { ...format, subtitles: { ...format.subtitles, sources: updatedSubtitles } };
        onFormatUpdate(updatedFormat);
    };    

    const handleAddSub = () => {
        const newSubtitle = {
            source: '',
            language: ''
        };
        const updatedSubtitles = [...format.subtitles.sources, newSubtitle];
        const updatedFormat = { ...format, subtitles: { ...format.subtitles, sources: updatedSubtitles } };
        onFormatUpdate(updatedFormat);
    };    

    const handleDeleteSub = (index) => {
        const updatedSubtitles = format.subtitles.sources.filter((_, i) => i !== index);
        const updatedFormat = { ...format, subtitles: { ...format.subtitles, sources: updatedSubtitles } };
        onFormatUpdate(updatedFormat);
    };    

  

    // dealing with Advanced Settings Modal
    const [isAdvancedSettingsModalOpen, setAdvancedSettingsModalOpen] = useState(false);
    const [resolutionIndex, setResolutionIndex] = useState(null);

    const handleShowSettingsModal = (index) => {
        // set resolution index
        if(index !== null){
            setResolutionIndex(index)
        }

        console.log("Show Advanced Settings Modal")
        setAdvancedSettingsModalOpen(true)
    }

    const handleCloseAdvancedSettingsModal = () => {
        console.log("Close Advanced Settings Modal")
        setAdvancedSettingsModalOpen(false);
        setResolutionIndex(undefined)
    };    

    const handleUpdateAdvancedSettings = (format) => {
        console.log("Update Advanced Settings: ", format)
        // update format
        onFormatUpdate(format);

        // close modal
        setAdvancedSettingsModalOpen(false);
        setResolutionIndex(undefined)
    };        

    return (
        <div className={`${styles.outputCard} ${isExpanded ? styles.cardExpanded : ''}`}>
            <div className={styles.outputHeader} onClick={toggleExpand}>
                <div className={styles.outputHeaderTitle}>
                    <div className={styles.outputHeaderTitleText}>Output {index + 1}</div>
                    <div className={styles.outputHeaderTitleButtons}>
                            <div className={styles.outputHeaderTitleBtn} onClick={handleDuplicateClick}>
                                <Duplicate />
                            </div>  
                        {
                            onFormatDelete && 
                            <div className={styles.outputHeaderTitleBtn} onClick={handleDeleteClick}>
                                <Delete />
                            </div>                            
                        }
                    </div>
                    {
                        isExpanded ? <ChevronUp className={styles.outputHeaderTitleExpandIcon} /> : <ChevronDown className={styles.outputHeaderTitleExpandIcon} />
                    }
                </div>
                <div className={styles.outputHeaderDetails}>
                    <span>
                        {format?.output && <>{getLabelByValue(format?.output)}</>}
                        {format?.stream && format?.stream[0]?.video_codec
                            ? <> / {getLabelByValue(format?.stream[0].video_codec)}</>
                            : format?.video_codec && <> / {getLabelByValue(format?.video_codec)}</>
                        }
                    </span>
                </div>
            </div>
            {isExpanded && (
                <div className={styles.outputBody}>
                    <div className={styles.formBody}>

                        {
                            !format?.output
                            ?
                            <div className={styles.formRow}>
                                <Label text="Choose Output Format" />
                                <div className={styles.formatSelectorContainer}>

                                    <div className={styles.formatSelectorRow}>

                                        <div className={styles.formatSelectorCard} onClick={() => handleSetOutput('mp4')}>
                                            <div className={styles.formatSelectorTitle}>MP4</div>
                                            <FormatMP4Image className={styles.formatSelectorIcon} />
                                            <Tippy maxWidth={240} content='Contains video, audio and metadata all within a single .mp4 container'>
                                                <div className={styles.formatSelectorInfoIconContainer}>
                                                    <Info className={styles.formatSelectorInfoIcon} />
                                                </div>   
                                            </Tippy>                                          
                                        </div>

                                        <div className={styles.formatSelectorCard} onClick={() => handleSetOutput('advanced_hls')}>
                                            <div className={styles.formatSelectorTitle}>HLS</div>
                                            <FormatHLSImage className={styles.formatSelectorIcon} />       
                                            <Tippy maxWidth={240} content='An adaptive bitrate streaming protocol using .m3u8 manifest.'>
                                                <div className={styles.formatSelectorInfoIconContainer}>
                                                    <Info className={styles.formatSelectorInfoIcon} />
                                                </div>   
                                            </Tippy>                                                                                                 
                                        </div>

                                        <div className={styles.formatSelectorCard} onClick={() => handleSetOutput('advanced_dash')}>
                                            <div className={styles.formatSelectorTitle}>MPEG-DASH</div>
                                            <FormatMPEGDASHImage className={styles.formatSelectorIcon} />    
                                            <Tippy maxWidth={240} content='An adaptive bitrate streaming protocol using .mpd manifest.'>
                                                <div className={styles.formatSelectorInfoIconContainer}>
                                                    <Info className={styles.formatSelectorInfoIcon} />
                                                </div>   
                                            </Tippy>                                                                                                     
                                        </div>                                                                                                                                
                                    </div>

                                    <div className={styles.formatSelectorRow}>

                                        <div className={styles.formatSelectorCard} onClick={() => handleSetOutput('speech_to_text')}>
                                            <div className={styles.formatSelectorTitle}>Speech-to-Text</div>
                                            <FormatSTTImage className={styles.formatSelectorIcon} />
                                            <Tippy maxWidth={240} content='Automatically generate subtitles and transcripts in .vtt, .srt, .json and .txt.'>
                                                <div className={styles.formatSelectorInfoIconContainer}>
                                                    <Info className={styles.formatSelectorInfoIcon} />
                                                </div>   
                                            </Tippy>                                          
                                        </div>

                                        <div className={styles.formatSelectorCard} onClick={() => handleSetOutput('webm')}>
                                            <div className={styles.formatSelectorTitle}>WebM</div>
                                            <FormatWebMImage className={styles.formatSelectorIcon} />     
                                            <Tippy maxWidth={240} content='Contains video, audio and metadata all within a single .webm container.'>
                                                <div className={styles.formatSelectorInfoIconContainer}>
                                                    <Info className={styles.formatSelectorInfoIcon} />
                                                </div>   
                                            </Tippy>                                                                                                   
                                        </div>

                                        <div className={styles.formatSelectorCard} onClick={() => handleSetOutput('repack')}>
                                            <div className={styles.formatSelectorTitle}>Repack</div>
                                            <FormatRepackImage className={styles.formatSelectorIcon} />  
                                            <Tippy maxWidth={240} content='Repackages existing video streams without re-encoding, often used to change containers or multiplex streams.'>
                                                <div className={styles.formatSelectorInfoIconContainer}>
                                                    <Info className={styles.formatSelectorInfoIcon} />
                                                </div>   
                                            </Tippy>                                                                                                       
                                        </div>                                                                                                                                
                                    </div>    

                                    <div className={styles.formatSelectorRow}>

                                        <div className={styles.formatSelectorCard} onClick={() => handleSetOutput('mp3')}>
                                            <div className={styles.formatSelectorTitle}>MP3</div>
                                            <FormatMP3Image className={styles.formatSelectorIcon} />
                                            <Tippy maxWidth={240} content='Audio-only format commonly used for music and sound files, contained within an .mp3 file.'>
                                                <div className={styles.formatSelectorInfoIconContainer}>
                                                    <Info className={styles.formatSelectorInfoIcon} />
                                                </div>   
                                            </Tippy>                                          
                                        </div>

                                        <div className={styles.formatSelectorCard} onClick={() => handleSetOutput('hls_audio')}>
                                            <div className={styles.formatSelectorTitle}>HLS Audio</div>
                                            <FormatHLSAudioImage className={styles.formatSelectorIcon} />      
                                            <Tippy maxWidth={240} content='A streaming protocol designed for audio-only content using .m3u8 manifest files.'>
                                                <div className={styles.formatSelectorInfoIconContainer}>
                                                    <Info className={styles.formatSelectorInfoIcon} />
                                                </div>   
                                            </Tippy>                                                                                                  
                                        </div>

                                        <div className={styles.formatSelectorCard} onClick={() => handleSetOutput('gif')}>
                                            <div className={styles.formatSelectorTitle}>GIF</div>
                                            <FormatGifImage className={styles.formatSelectorIcon} />        
                                            <Tippy maxWidth={240} content='Can support both static and animated images using a .gif container.'>
                                                <div className={styles.formatSelectorInfoIconContainer}>
                                                    <Info className={styles.formatSelectorInfoIcon} />
                                                </div>   
                                            </Tippy>                                                                                                 
                                        </div>                                                                                                                                
                                    </div>   

                                    <div className={styles.formatSelectorRow}>

                                        <div className={styles.formatSelectorCard} onClick={() => handleSetOutput('thumbnail')}>
                                            <div className={styles.formatSelectorTitle}>Thumbnail</div>
                                            <FormatThumbnailImage className={styles.formatSelectorIcon} />
                                            <Tippy maxWidth={240} content='A small, static image that represents a larger piece of content, often used for previews.'>
                                                <div className={styles.formatSelectorInfoIconContainer}>
                                                    <Info className={styles.formatSelectorInfoIcon} />
                                                </div>   
                                            </Tippy>                                          
                                        </div>

                                        <div className={styles.formatSelectorCard} onClick={() => handleSetOutput('thumbnails')}>
                                            <div className={styles.formatSelectorTitle}>Thumbnails/VTT</div>
                                            <FormatVTTImage className={styles.formatSelectorIcon} />        
                                            <Tippy maxWidth={240} content='A collection of small images and associated timecode information stored in .vtt files, typically used for video scrubbing previews.'>
                                                <div className={styles.formatSelectorInfoIconContainer}>
                                                    <Info className={styles.formatSelectorInfoIcon} />
                                                </div>   
                                            </Tippy>                                                                                                
                                        </div>

                                        <div className={styles.formatSelectorCardBlank}>
                                                            
                                        </div>                                                                                                                                
                                    </div>                                                                                                
                                                                                                                    
                                </div>
                            </div>     
                            :
                            <>
                                <div className={styles.formRow}>
                                    <Select
                                        label='Output Format'
                                        //help={getDocsTooltipData('1')}                
                                        help={tooltipData({api: 'transcoding', name: 'format'})}
                                        name='output'
                                        placeholder=''
                                        options={outputOptions}
                                        value={format?.output || ''}
                                        //onChange={handleInputChange}
                                        onChange={handleOutputChange}
                                    />
                                    

                                    {/* handle video output */}
                                    {(format?.output === 'mp4' || format?.output === 'webm' || format?.output === 'advanced_hls' || format?.output === 'advanced_dash') &&
                                        <Select
                                            label='Codec'
                                            //help={getDocsTooltipData('2')}
                                            help={tooltipData({api: 'transcoding', name: 'video_codec'})}
                                            name='video_codec'
                                            placeholder=''
                                            options={videoCodecOption[format?.output]}
                                            value={(format?.stream && format?.stream.length > 0 ? format?.stream[0]?.video_codec : format?.video_codec) || ''}
                                            onChange={handleInputChange}
                                        />
                                    }                               

                                    {/* handle audio output */}
                                    {(format?.output === 'mp3' || format?.output === 'hls_audio') && (
                                        <Select
                                            label='Codec'
                                            //help={getDocsTooltipData('3')}
                                            help={tooltipData({api: 'transcoding', name: 'audio_codec'})}
                                            name='audio_codec'
                                            placeholder=''
                                            options={audioCodecOption[format?.output]}
                                            value={format?.audio_codec || ''}
                                            onChange={handleInputChange}
                                        />
                                    )}         

                                    {/* thumbnails output */}
                                    {
                                        format?.output === 'thumbnails' &&
                                        <InputField
                                            label='Interval'
                                            //help={getDocsTooltipData('4')}
                                            help={tooltipData({api: 'transcoding', name: 'interval'})}
                                            name='interval'
                                            inputText={format?.interval || ''}
                                            onChange={handleInputChange}
                                        />                                
                                    }

                                    {
                                        format?.output === 'thumbnail' &&
                                        <InputField
                                            label='Time'
                                            help={tooltipData({api: 'transcoding', name: 'time'})}
                                            name='time'
                                            inputText={format?.time || ''}
                                            onChange={handleInputChange}
                                        />                                
                                    }                            

                                    {/* repack */}
                                    {
                                        format?.output === 'repack' &&
                                        <Select
                                            label='Extension'
                                            name='file_extension'
                                            options={fileExtentionOptions}
                                            value={format?.file_extension || ''}
                                            onChange={handleInputChange}
                                        />
                                    }

                                    {/* gif */}
                                    {
                                        format?.output === 'gif' &&
                                        <InputField
                                            className={styles.framerateInput}
                                            label='Framerate'
                                            //help={getDocsTooltipData('5')}
                                            help={tooltipData({api: 'transcoding', name: 'framerate'})}
                                            name='framerate'
                                            inputText={format?.framerate || ''}
                                            onChange={handleInputChange}
                                        />                                    
                                    }             
                                    
                                    {/* speech_to_text */}
                                    {
                                        format?.output === 'speech_to_text' &&
                                        <Select
                                            label='Mode'
                                            help={modeHelpContent[format?.mode]}                                    
                                            name='mode'
                                            options={modeOption}
                                            value={format?.mode || ''}
                                            onChange={handleInputChange}
                                        />
                                    }                                           
                                </div>


                                {/* show optimize_bitrate toggle */}
                                {
                                    (format?.output === 'mp4' || format?.output === 'advanced_hls' || format?.output === 'advanced_dash') &&
                                    (
                                        (format?.video_codec === 'libx264' || format?.video_codec === 'libx265') ||
                                        (format?.stream && format?.stream.length > 0 && (format?.stream[0]?.video_codec === 'libx264' || format?.stream[0]?.video_codec === 'libx265'))
                                    ) &&
                                    <div className={styles.formRow}>
                                        <div className={styles.formToggleContainer}>
                                            <div className={styles.formToggleLabel}>
                                                <Label 
                                                    text="Enable Per-Title Encoding" 
                                                    //help={getDocsTooltipData('6')}
                                                    help={tooltipData({api: 'transcoding', name: 'optimize_bitrate'})}
                                                />
                                            </div>                                
                                            <FormToggle 
                                                name='optimize_bitrate'
                                                //checked={format?.stream ? format?.stream[0]?.optimize_bitrate === 1 : format?.optimize_bitrate === 1}
                                                checked={format?.stream ? format?.stream.every(item => item.optimize_bitrate === 1) : format?.optimize_bitrate === 1}
                                                onChange={handleBitrateToggle}
                                            />      
                                        </div>
                                    </div>                            
                                }


                                {/* Show resolution for video formats  */}
                                {(format?.output === 'mp4' || format?.output === 'webm' || format?.output === 'advanced_hls' || format?.output === 'advanced_dash') && 
                                    <div className={styles.resolutionsContainer}>
                                        <Label text="Resolution" />
                                        <div className={styles.resolutionsTableContainer}>
                                            <div className={styles.resolutionsTable}>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <Label 
                                                                    variant="tableHeader" 
                                                                    text="SIZE" 
                                                                    //help={getDocsTooltipData('7')}
                                                                    help={tooltipData({api: 'transcoding', name: 'size'})}
                                                                />
                                                            </th>
                                                            <th>
                                                                <Label 
                                                                    variant="tableHeader" 
                                                                    text="QUALITY" 
                                                                    //help={getDocsTooltipData('8')}
                                                                    help={tooltipData({api: 'transcoding', name: 'quality'})}
                                                                />
                                                            </th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            !format?.stream && 
                                                            <>
                                                                <tr>
                                                                    <td>
                                                                        {format?.width && format?.height && <>{format?.width} x {format?.height}</>}
                                                                        {format?.width && !format?.height && <>{format?.width}</>}
                                                                        {!format?.width && format?.height && <>{format?.height}</>}
                                                                        {!format?.width && !format?.height && <>original</>}
                                                                    </td>
                                                                    <td>
                                                                        { format?.quality && <>{format?.quality} CRF</> }
                                                                        { format?.optimize_bitrate && <>optimized</> }     
                                                                        { !format?.optimize_bitrate && !format?.quality && <>—</>}                                                                                                                      
                                                                    </td>
                                                                    <td>
                                                                        <div className={styles.resolutionControls}>
                                                                            <Button
                                                                                onClick={() => handleShowSettingsModal(null)}
                                                                                variant="link"
                                                                            >
                                                                                Show Advanced Settings
                                                                            </Button>
                                                                        </div>
                                                                    </td>                                                        
                                                                </tr>                                                                                                           
                                                            </>
                                    
                                                        }
                                                        {format?.stream?.map((resolution, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        {resolution?.width && resolution?.height && <>{resolution?.width} x {resolution?.height}</>}
                                                                        {resolution?.width && !resolution?.height && <>{resolution?.width}</>}
                                                                        {!resolution?.width && resolution?.height && <>{resolution?.height}</>}   
                                                                        { !resolution?.width && !resolution?.height && <>original</>}                                                               
                                                                    </td>
                                                                    <td>
                                                                        { resolution?.bitrate && <>{resolution?.bitrate} Kbps</> }
                                                                        { resolution?.optimize_bitrate && <>optimized</> }     
                                                                        { !resolution?.optimize_bitrate && !resolution?.bitrate && <>—</>}                                                         
                                                                    </td>
                                                                    <td>
                                                                        <div className={styles.resolutionControls}>
                                                                            {/* <div>Show Advanced Settings</div>  */}

                                                                            <Button
                                                                                onClick={() => handleShowSettingsModal(index)}
                                                                                variant="link"
                                                                            >
                                                                                Show Advanced Settings
                                                                            </Button>

                                                                            <div className={styles.resolutionDeleteBtn} onClick={() => handleDeleteResolution(index)}>
                                                                                <Delete />
                                                                            </div>    
                                                                            
                                                                        </div>
                                                                    </td>                                                                                                                        
                                                                </tr>
                                                        ))}                                                 
                                                    </tbody>
                                                </table>    

                                                { isAdvancedSettingsModalOpen && (
                                                    <AdvancedSettingsModal
                                                        open={isAdvancedSettingsModalOpen}
                                                        handleCancel={handleCloseAdvancedSettingsModal}
                                                        handleUpdate={handleUpdateAdvancedSettings}
                                                        format={format}
                                                        resolutionIndex={resolutionIndex}
                                                    />
                                                )}   

                                                {
                                                    format?.stream &&
                                                    <div className={styles.resolutionsTableFooter}>
                                                        <Button
                                                            icon={<Add label="Add size" />}
                                                            onClick={handleAddSize}
                                                            variant="link"
                                                        >
                                                            Add size
                                                        </Button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    (format?.output === 'mp3' || format?.output === 'hls_audio') &&
                                    <div className={styles.formRow}>
                                        <InputField
                                            label='Audio Bitrate'
                                            //help={getDocsTooltipData('9')}
                                            help={tooltipData({api: 'transcoding', name: 'audio_bitrate'})}
                                            placeholder='64'
                                            name='audio_bitrate'
                                            inputText={format?.audio_bitrate || ''}
                                            onChange={handleInputChange}
                                        />
                                        <InputField
                                            label='Sample Rate'
                                            help={tooltipData({api: 'transcoding', name: 'audio_sample_rate'})}
                                            placeholder='44100'
                                            name='audio_sample_rate'
                                            inputText={format?.audio_sample_rate || ''}
                                            onChange={handleInputChange}
                                        />
                                        <InputField
                                            label='No. of channels'
                                            help={tooltipData({api: 'transcoding', name: 'audio_channels_number'})}
                                            placeholder='10'
                                            name='audio_channels_number'
                                            inputText={format?.audio_channels_number || ''}
                                            onChange={handleInputChange}
                                        />                                                                
                                    </div>                             
                                }

                                {
                                    (format?.output === 'thumbnails' || format?.output === 'thumbnail') &&
                                    <div className={styles.formRow}>
                                        <Select
                                            className={styles.imageFormat}
                                            label='Image Format'
                                            help={tooltipData({api: 'transcoding', name: 'image_format'})}
                                            name='image_format'
                                            options={imageFormatOptions}
                                            value={format?.image_format || ''}
                                            onChange={handleInputChange}
                                        />

                                        <InputField
                                            label='Width'
                                            help={tooltipData({api: 'transcoding', name: 'width'})}
                                            name='width'
                                            inputText={format?.width || ''}
                                            onChange={handleInputChange}
                                        />
                                        <InputField
                                            label='Height'
                                            help={tooltipData({api: 'transcoding', name: 'height'})}
                                            name='height'
                                            inputText={format?.height || ''}
                                            onChange={handleInputChange}
                                        />                                                                
                                    </div>  
                                }

                                {
                                    format?.output === 'gif' &&
                                    <div className={styles.formRow}>
                                        <InputField
                                            label='Start Time'
                                            help={tooltipData({api: 'transcoding', name: 'start_time'})}
                                            name='start_time'
                                            placeholder='120.5'
                                            inputText={format?.start_time || ''}
                                            onChange={handleInputChange}
                                        />    
                                        <InputField
                                            label='Duration'
                                            help={tooltipData({api: 'transcoding', name: 'duration'})}
                                            name='duration'
                                            placeholder='10'
                                            inputText={format?.duration || ''}
                                            onChange={handleInputChange}
                                        />  
                                        <InputField
                                            label='Width'
                                            help={tooltipData({api: 'transcoding', name: 'width'})}
                                            name='width'
                                            inputText={format?.width || ''}
                                            onChange={handleInputChange}
                                        />    
                                        <InputField
                                            label='Height'
                                            help={tooltipData({api: 'transcoding', name: 'height'})}
                                            name='height'
                                            inputText={format?.height || ''}
                                            onChange={handleInputChange}
                                        />                                                                                                          
                                    </div>                          
                                }          

                                {/* only if destination block filled */}
                                {/* or format?.destination?.url && */}
                                {
                                    destination &&                             
                                    <div className={styles.formRow}>
                                        <InputField
                                            label='Output Path'
                                            // help={tooltipData({api: 'transcoding', name: 'output_path'})}
                                            help={{
                                                no_header: true,
                                                title: 'Output Path',
                                                content: 'Part of the destination url, including the output file name (for MP4 or WEBM), or folder (for HLS or DASH).'                                            
                                            }}                                    
                                            name='output_path'
                                            inputText={fileName}
                                            onChange={handlePathChange}
                                        />
                                    </div>                              
                                }
                                            

                                {
                                    (format?.output === 'advanced_hls' || format?.output === 'advanced_dash') &&
                                    <>
                                        <div className={styles.formRow}>
                                            <InputField
                                                label='Playlist Name'
                                                help={tooltipData({api: 'transcoding', name: 'playlist_name'})}
                                                placeholder='playlist.m3u8' 
                                                name='playlist_name'
                                                inputText={format?.playlist_name || ''}
                                                onChange={handleInputChange}
                                            />                                      
                                        </div>

                                        <div className={styles.formRow}>
                                            <InputField
                                                label='Framerate'
                                                help={tooltipData({api: 'transcoding', name: 'framerate'})}
                                                name='framerate'
                                                inputText={(format?.stream && format?.stream.length > 0 ? format?.stream[0]?.framerate : '')}
                                                onChange={handleInputChange}
                                            /> 
                                            <InputField
                                                label='Keyframe'
                                                help={tooltipData({api: 'transcoding', name: 'keyframe'})}
                                                placeholder='90'
                                                name='keyframe'
                                                inputText={(format?.stream && format?.stream.length > 0 ? format?.stream[0]?.keyframe : '')}
                                                onChange={handleInputChange}
                                            />            
                                            <InputField
                                                label='Segment Duration'
                                                help={tooltipData({api: 'transcoding', name: 'segment_duration'})}
                                                placeholder='6' 
                                                name='segment_duration'
                                                inputText={format?.segment_duration || ''}
                                                onChange={handleInputChange}
                                            />                                                                                                    
                                        </div> 

                                    </>
                                }

                                {
                                    (format?.output === 'advanced_hls') &&
                                    <>
                                        <div className={styles.formRow}>
                                            <div className={styles.formToggleContainer}>
                                                <div className={styles.formToggleLabel}>
                                                    <Label 
                                                        text="Create fMp4 chunks" 
                                                        help={tooltipData({api: 'transcoding', name: 'fmp4'})}
                                                    />
                                                </div>                                
                                                <FormToggle 
                                                    name='fmp4'
                                                    checked={format?.fmp4 === 1}
                                                    onChange={handleInputChange} 
                                                />      
                                            </div>
                                        </div> 
                                    </>
                                }                        


                                {
                                    (format?.output === 'mp4' || format?.output === 'webm' || format?.output === 'advanced_hls' || format?.output === 'advanced_dash') &&
                                    <div className={styles.formRow}>
                                        <div className={styles.formToggleContainer}>
                                            <div className={styles.formToggleLabel}>
                                                <Label 
                                                    text="Audio Mute" 
                                                    help={tooltipData({api: 'transcoding', name: 'audio_mute'})}
                                                />
                                            </div>                                
                                            <FormToggle 
                                                name='audio_mute'
                                                checked={format?.audio_mute === 1}
                                                onChange={handleInputChange} 
                                            />      
                                        </div>
                                    </div>                            
                                }


                                {
                                    (format?.output === 'advanced_hls') &&
                                    <>
                                        <div className={styles.formRow}>
                                            <div className={styles.formToggleContainer}>
                                                <div className={styles.formToggleLabel}>
                                                    <Label 
                                                        text="Separate Audio" 
                                                        help={tooltipData({api: 'transcoding', name: 'separate_audio'})}
                                                    />
                                                </div>                                
                                                <FormToggle 
                                                    name='separate_audio'
                                                    checked={format?.separate_audio === 1}
                                                    onChange={handleInputChange} 
                                                />      
                                            </div>
                                        </div> 
                                    </>
                                }                        
                                

                                {/* Dealing with subtitles */}
                                {
                                    (format?.output === 'mp4' || format?.output === 'webm' || format?.output === 'advanced_hls' || format?.output === 'advanced_dash') &&
                                    <>
                                        <div className={styles.formRow}>
                                            <div className={styles.formToggleContainer}>
                                                <div className={styles.formToggleLabel}>
                                                    <Label 
                                                        text="Enable Subtitles" 
                                                        help={tooltipData({api: 'transcoding', name: 'subtitles'})}
                                                    />
                                                </div>                                
                                                <FormToggle 
                                                    name='subtitles'
                                                    // checked={format?.subtitles}
                                                    checked={!!format?.subtitles}
                                                    onChange={handleSubtitlesChange} 
                                                />      
                                            </div>
                                        </div>              
                                        
                                        {format?.subtitles &&
                                            <div className={styles.subsContainer}>
                                                {format?.subtitles?.sources?.map((subtitle, index) => (
                                                    <div key={index} className={styles.formRow}>
                                                        <InputField
                                                            label='Source'
                                                            help={tooltipData({api: 'transcoding', name: 'sources'})}
                                                            placeholder='https://server.com/sub/v.ENG.txt'
                                                            name='subtitles.sources.source'
                                                            inputText={subtitle?.source}                                                    
                                                            onChange={(e) => handleSubtitleChange(index, 'source', e.target.value)}
                                                        />  
                                                        <InputField
                                                            className={styles.subsLangInput}
                                                            label='Language'
                                                            help={tooltipData({api: 'transcoding', name: 'language'})}
                                                            placeholder='ENG'
                                                            name='subtitles.sources.language'
                                                            inputText={subtitle?.language}
                                                            onChange={(e) => handleSubtitleChange(index, 'language', e.target.value)}
                                                        />    
                                                        {
                                                            format?.subtitles?.sources?.length > 1 &&
                                                                <div className={styles.subDeleteBtn} onClick={() => handleDeleteSub(index)}>
                                                                    <Delete />
                                                                </div>       
                                                        }            
                                                    </div>
                                                ))} 

                                                <div>
                                                    <Button
                                                        icon={<Add label="Add subtitle" />}
                                                        onClick={handleAddSub}
                                                        variant="link"
                                                    >
                                                        Add Subtitle
                                                    </Button>
                                                </div>
                                            </div>
                                        }

                                            

                                        {
                                            format?.subtitles &&
                                            <div className={styles.formRow}>
                                                <div className={styles.formToggleContainer}>
                                                    <div className={styles.formToggleLabel}>
                                                        <Label 
                                                            text="Copy URL EIA Captions" 
                                                            help={tooltipData({api: 'transcoding', name: 'copy'})}
                                                        />
                                                    </div>                                
                                                    <FormToggle 
                                                        name='subtitles.copy'
                                                        checked={format?.subtitles?.copy === 1}
                                                        onChange={handleSubtitlesCopyChange} 
                                                    />      
                                                </div>
                                            </div>                                      
                                        }
                                    </>           
                                }                    


                                {
                                    (format?.output === 'advanced_hls') &&
                                    <>
                                        <div className={styles.formRow}>
                                            <div className={styles.formToggleContainer}>
                                                <div className={styles.formToggleLabel}>
                                                    <Label 
                                                        text="Enable AES-128 Encryption" 
                                                        help={tooltipData({api: 'transcoding', name: 'encryption'})}
                                                    />
                                                </div>                                
                                                <FormToggle 
                                                    name='encryption'
                                                    //checked={!!format?.encryption}
                                                    checked={encryptionToggle}
                                                    onChange={handleEncryptionChange} 
                                                />      
                                            </div>
                                        </div> 

                                        { encryptionToggle && 
                                            <>
                                                <div className={styles.formRow}>
                                                    <InputField
                                                        label='Encryption key'
                                                        name='encryption.key'
                                                        inputText={format?.encryption?.key || ''}     
                                                        onChange={handleInputChange}                                               
                                                    />                                               
                                                </div>     
                                                <div className={styles.formRow}>
                                                    <InputField
                                                        label='Encryption key IV'
                                                        name='encryption.iv'
                                                        inputText={format?.encryption?.iv || ''}     
                                                        onChange={handleInputChange}                                               
                                                    />                                               
                                                </div>  
                                                <div className={styles.formRow}>
                                                    <InputField
                                                        label='Encryption key URL'
                                                        name='encryption.key_url'
                                                        inputText={format?.encryption?.key_url || ''}     
                                                        onChange={handleInputChange}                                               
                                                    />                                               
                                                </div>                                                                                                                   
                                            </>
                                        }                                
                                    </>
                                }   


                                {
                                    (format?.output === 'advanced_hls') &&
                                    <>
                                        <div className={styles.formRow}>
                                            <div className={styles.formToggleContainer}>
                                                <div className={styles.formToggleLabel}>
                                                    <Label 
                                                        text="Enable Fairplay DRM encryption" 
                                                        help={tooltipData({api: 'transcoding', name: 'fps_drm'})}
                                                    />
                                                </div>                                
                                                <FormToggle 
                                                    name='fps_drm'
                                                    checked={fpsDrmToggle}
                                                    onChange={handleFpsDrmChange} 
                                                />      
                                            </div>
                                        </div> 

                                        { fpsDrmToggle && 
                                            <>
                                                <div className={styles.formRow}>
                                                    <InputField
                                                        label='DRM encryption key'
                                                        help={
                                                            {
                                                                title: 'Key',
                                                                name: "key",
                                                                data_type: "string (hex)",
                                                                optional: 'required',
                                                                content: 'Encryption key',
                                                                link: {
                                                                    text: 'Show API',
                                                                    url: 'https://docs.qencode.com/#start_encode2___query__attributes--format__attributes--encryption__attributes--key__attributes'
                                                                }
                                                            }                                                            
                                                        }                                                   
                                                        name='fps_drm.key'
                                                        inputText={format?.fps_drm?.key || ''}     
                                                        onChange={handleInputChange}                                               
                                                    />                                               
                                                </div>   
                                                <div className={styles.formRow}>
                                                    <InputField
                                                        label='DRM encryption key IV'
                                                        help={tooltipData({api: 'transcoding', name: 'iv'})}
                                                        name='fps_drm.iv'
                                                        inputText={format?.fps_drm?.iv || ''}     
                                                        onChange={handleInputChange}                                               
                                                    />                                               
                                                </div>   
                                                <div className={styles.formRow}>
                                                    <InputField
                                                        label='Encryption key URL'
                                                        help={tooltipData({api: 'transcoding', name: 'key_url'})}
                                                        name='fps_drm.key_url'
                                                        inputText={format?.fps_drm?.key_url || ''}     
                                                        onChange={handleInputChange}                                               
                                                    />                                               
                                                </div>                                                                                                                                                                                                   
                                            </>
                                        }                                
                                    </>
                                }                            

                                {
                                    (format?.output === 'advanced_dash') &&
                                    <>
                                        <div className={styles.formRow}>
                                            <div className={styles.formToggleContainer}>
                                                <div className={styles.formToggleLabel}>
                                                    <Label 
                                                        text="Enable Widevine and Playready DRM encryption" 
                                                        help={tooltipData({api: 'transcoding', name: 'cenc_drm'})}
                                                    />
                                                </div>                                
                                                <FormToggle 
                                                    name='cenc_drm'
                                                    checked={cencDrmToggle}
                                                    onChange={handleCencDrmChange} 
                                                />      
                                            </div>
                                        </div> 

                                        { cencDrmToggle && 
                                            <>
                                                <div className={styles.formRow}>
                                                    <InputField
                                                        label='DRM encryption key'
                                                        //help={tooltipData({api: 'transcoding', name: 'key'})}
                                                        help={
                                                            {
                                                                title: 'Key',
                                                                name: "key",
                                                                data_type: "string (hex)",
                                                                optional: 'required',
                                                                content: 'Encryption key',
                                                                link: {
                                                                    text: 'Show API',
                                                                    url: 'https://docs.qencode.com/#start_encode2___query__attributes--format__attributes--encryption__attributes--key__attributes'
                                                                }
                                                            }                                                            
                                                        }                                                
                                                        name='cenc_drm.key'
                                                        inputText={format?.cenc_drm?.key || ''}     
                                                        onChange={handleInputChange}                                               
                                                    />                                               
                                                </div>     
                                                <div className={styles.formRow}>
                                                    <InputField
                                                        label='DRM encryption key id'
                                                        help={tooltipData({api: 'transcoding', name: 'key_id'})}
                                                        name='cenc_drm.key_id'
                                                        inputText={format?.cenc_drm?.key_id || ''}     
                                                        onChange={handleInputChange}                                               
                                                    />                                               
                                                </div>  
                                                <div className={styles.formRow}>
                                                    <InputField
                                                        label='DRM encryption PSSH'
                                                        help={tooltipData({api: 'transcoding', name: 'pssh'})}
                                                        name='cenc_drm.pssh'
                                                        inputText={format?.cenc_drm?.pssh || ''}     
                                                        onChange={handleInputChange}                                               
                                                    />                                               
                                                </div>   
                                                <div className={styles.formRow}>
                                                    <InputField
                                                        label='License server URL'
                                                        help={tooltipData({api: 'transcoding', name: 'la_url'})}
                                                        name='cenc_drm.la_url'
                                                        inputText={format?.cenc_drm?.la_url || ''}     
                                                        onChange={handleInputChange}                                               
                                                    />                                               
                                                </div>                                                                                                                                                           
                                            </>
                                        }                                
                                    </>
                                }                                                    


                                {
                                    (format?.output === 'mp4' || format?.output === 'webm' || format?.output === 'advanced_hls' || format?.output === 'advanced_dash') &&
                                    <div className={styles.formRow}>
                                        <div className={styles.formToggleContainer}>
                                            <div className={styles.formToggleLabel}>
                                                <Label 
                                                    text="Deinterlace" 
                                                    help={tooltipData({api: 'transcoding', name: 'deinterlace'})}
                                                />
                                            </div>                                
                                            <FormToggle 
                                                name='deinterlace'
                                                checked={format?.deinterlace === 1}
                                                onChange={handleInputChange} 
                                            />      
                                        </div>
                                    </div>                            
                                }           


                                {/* repack */}
                                {
                                    format?.output === 'repack' &&
                                    <>
                                        <div className={styles.formRow}>
                                            <div className={styles.formToggleContainer}>
                                                <div className={styles.formToggleLabel}>
                                                    <Label 
                                                        text="Copy URL video stream(s)" 
                                                        help={tooltipData({api: 'transcoding', name: 'video_copy'})}
                                                    />
                                                </div>                                
                                                <FormToggle 
                                                    name='video_copy'
                                                    checked={format?.video_copy === 1}
                                                    onChange={handleInputChange} 
                                                />      
                                            </div>
                                        </div>     
                                        <div className={styles.formRow}>
                                            <div className={styles.formToggleContainer}>
                                                <div className={styles.formToggleLabel}>
                                                    <Label 
                                                        text="Copy URL audio stream(s)" 
                                                        help={tooltipData({api: 'transcoding', name: 'audio_copy'})}
                                                    />
                                                </div>                                
                                                <FormToggle 
                                                    name='audio_copy'
                                                    checked={format?.audio_copy === 1}
                                                    onChange={handleInputChange} 
                                                />      
                                            </div>
                                        </div>   
                                        <div className={styles.formRow}>
                                            <div className={styles.formToggleContainer}>
                                                <div className={styles.formToggleLabel}>
                                                    <Label 
                                                        text="Copy URL subtitles" 
                                                        help={tooltipData({api: 'transcoding', name: 'subtitles_copy'})}
                                                    />
                                                </div>                                
                                                <FormToggle 
                                                    name='subtitles_copy'
                                                    checked={format?.subtitles_copy === 1}
                                                    onChange={handleInputChange} 
                                                />      
                                            </div>
                                        </div>   
                                        <div className={styles.formRow}>
                                            <div className={styles.formToggleContainer}>
                                                <div className={styles.formToggleLabel}>
                                                    <Label 
                                                        text="Copy URL metadata" 
                                                        help={tooltipData({api: 'transcoding', name: 'metadata_copy'})}
                                                    />
                                                </div>                                
                                                <FormToggle 
                                                    name='metadata_copy'
                                                    checked={format?.metadata_copy === 1}
                                                    onChange={handleInputChange} 
                                                />      
                                            </div>
                                        </div>                                                                                                   
                                    </>                       
                                }   

                                {/* speech_to_text */}
                                {
                                    format?.output === 'speech_to_text' &&
                                    <>
                                        <div className={styles.formRow}>
                                            <Select
                                                label='Language'
                                                help={{
                                                    no_header: true,
                                                    title: 'Language',
                                                    content: 'Speech-to-Text processing with Qencode automatically tries to recognize the spoken language in your media files, however there may be instances where more control over selected language is required. In case you want to explicitly define the target langauage for Speech-to-Text processing, you can do so using the following optional language parameter.'                                            
                                                }}
                                                name='language'
                                                options={languageOptions}
                                                value={format?.language || languageOptions[0].value || ''}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className={styles.formRow}>
                                            <div className={styles.formToggleContainer}>
                                                <div className={styles.formToggleLabel}>
                                                    <Label text="Transcript" />
                                                </div>                                
                                                <FormToggle 
                                                    name='transcript'
                                                    checked={format?.transcript === 1}
                                                    onChange={handleInputChange} 
                                                />                                                                                      
                                            </div>
                                        </div>       
                                        <div className={styles.formRow}>
                                            <div className={styles.formToggleContainer}>
                                                <div className={styles.formToggleLabel}>
                                                    <Label text="JSON" />
                                                </div>                                
                                                <FormToggle 
                                                    name='json'
                                                    checked={format?.json === 1}
                                                    onChange={handleInputChange} 
                                                />                                                                                   
                                            </div>
                                        </div> 
                                        <div className={styles.formRow}>
                                            <div className={styles.formToggleContainer}>
                                                <div className={styles.formToggleLabel}>
                                                    <Label text="SRT" />
                                                </div>  
                                                <FormToggle 
                                                    name='srt'
                                                    checked={format?.srt === 1}
                                                    onChange={handleInputChange} 
                                                />                                                                                      
                                            </div>
                                        </div> 
                                        <div className={styles.formRow}>
                                            <div className={styles.formToggleContainer}>
                                                <div className={styles.formToggleLabel}>
                                                    <Label text="VTT" />
                                                </div>      
                                                <FormToggle 
                                                    name='vtt'
                                                    checked={format?.vtt === 1}
                                                    onChange={handleInputChange} 
                                                />                                                                                      
                                            </div>
                                        </div>                                                                                                                                                                                                                               
                                    </>                       
                                }          

                                <div className={styles.formRow}>
                                    <div className={styles.formToggleContainer}>
                                        <div className={styles.formToggleLabel}>
                                            <Label 
                                                text="Allow Soft-fail" 
                                                help={tooltipData({api: 'transcoding', name: 'allow_soft_fail'})}
                                            />
                                        </div>                                
                                        <FormToggle 
                                            name='allow_soft_fail'
                                            checked={format?.allow_soft_fail === 1}
                                            onChange={handleInputChange} 
                                        />      
                                    </div>
                                </div>     

                            </>                        
                        }
                   

                    </div>
                </div>
            )}
        </div>
    );
};

OutputCard.propTypes = {
    index: PropTypes.number.isRequired,
    format: PropTypes.object.isRequired,
    onFormatUpdate: PropTypes.func.isRequired,
    onFormatDelete: PropTypes.func,
};




// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { Select } from 'components/Select';
// import { InputField } from 'components/InputField';
// import { Delete } from 'icons';
// import styles from '../index.module.css';

// import { getLabelByValue, outputOptions, videoCodecOption, fileExtentionOption, audioCodecOption, modeOption } from './optionsMap';

// export const OutputCard = ({ index, format, onFormatUpdate, onFormatDelete }) => {

//     console.log("format:", format )

//     const [isExpanded, setIsExpanded] = useState(false);


//     const toggleExpand = () => setIsExpanded(!isExpanded);


//     const handleInputChange = (e) => {
//         const { name, value } = e.target;

//         // check if there is stream list and update video_codec in every element of the list
//         if(format?.stream && format?.stream?.length > 0){
//             const updatedStream = format.stream.map(item => ({
//                 ...item,
//                 video_codec: value
//             }));
//             const updatedFormat = { ...format, stream: updatedStream };
//             onFormatUpdate(updatedFormat);
//         } else {
//             const updatedFormat = { ...format, [name]: value };    
//             onFormatUpdate(updatedFormat); // update state in parent imidiatelly
//         }

        
//     };  

//     return (
//         <div className={`${styles.outputCard} ${isExpanded ? styles.cardExpanded : ''}`}>
//             <div className={styles.outputHeader} onClick={toggleExpand}>
//                 <div className={styles.outputHeaderTitle}>
//                     <div className={styles.outputHeaderTitleText}>Output {index + 1}</div>  
//                     <div className={styles.outputHeaderTitleButtons}>
//                         <div className={styles.outputHeaderTitleBtn} onClick={onFormatDelete}>
//                             <Delete />
//                         </div>
//                     </div>  
//                 </div>
//                 <div className={styles.outputHeaderDetails}>
//                     <span>                
//                         {
//                             format?.output && <>{getLabelByValue(format?.output)}</>
//                         }

//                         {format?.stream && format?.stream[0]?.video_codec 
//                             ? <> / {getLabelByValue(format.stream[0].video_codec)}</>
//                             : format?.video_codec && <> / {getLabelByValue(format.video_codec)}</>
//                         }                        
//                     </span>
//                 </div>
//             </div>
//             {isExpanded && (
//                 <div className={styles.outputBody}>
//                     <div className={styles.formBody}>

//                         <div className={styles.formRow}>
//                             <Select
//                                 label='Output Format'
//                                 name='output'
//                                 placeholder=''
//                                 options={outputOptions}
//                                 value={format?.output || ''}
//                                 onChange={handleInputChange}
//                             />

//                             {
//                                 format?.output && videoCodecOption[format?.output]?.length > 0 &&
//                                 <Select
//                                     label='Codec'
//                                     name='video_codec'
//                                     placeholder=''
//                                     options={videoCodecOption[format?.output]}
//                                     value={format?.video_codec || format?.stream[0]?.video_codec || ''} // if stream get value from there
//                                     onChange={handleInputChange}
//                                 />                            
//                             }

    
//                         </div>


//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };

// OutputCard.propTypes = {
//     index: PropTypes.number.isRequired,
//     format: PropTypes.object.isRequired,
//     onFormatUpdate: PropTypes.func.isRequired
// };

// export default OutputCard;








// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { Select } from 'components/Select';
// import { InputField } from 'components/InputField';
// import { Button } from 'components/Button';
// import styles from '../index.module.css';

// export const OutputCard = ({ index, format, onFormatUpdate }) => {

//     // output
//     const outputOptions = [
//         {value: 'mp4', label: 'MP4'},
//         {value: 'webm', label: 'WebM'},
//         {value: 'advanced_hls', label: 'HLS'},
//         {value: 'advanced_dash', label: 'MPEG-DASH'},
//         {value: 'mp3', label: 'MP3'},
//         {value: 'thumbnails', label: 'Thumbnails/VTT'},
//         {value: 'repack', label: 'Repack'},
//         {value: 'gif', label: 'GIF'},
//         {value: 'hls_audio', label: 'HLS AUDIO'},
//         {value: 'speech_to_text', label: 'Speech-to-Text'}
//     ]

//     // video_codec
//     const videoCodecOption = {
//         mp4: [
//             {value: 'libx264', label: 'H.264'},
//             {value: 'libx265', label: 'H.265'},
//             {value: 'libaom-av1', label: 'AV1'},
//             {value: 'lcevc_h264', label: 'LCEVC_H264'},
//             {value: 'lcevc_hevc', label: 'LCEVC_HEVC'},
//         ],
//         webm: [
//             {value: 'libvpx', label: 'VP8'},
//             {value: 'libvpx-vp9', label: 'VP9'},
//         ],
//         advanced_hls: [
//             {value: 'libx264', label: 'H.264'},
//             {value: 'libx265', label: 'H.265'},
//             {value: 'lcevc_h264', label: 'LCEVC_H264'},
//             {value: 'lcevc_hevc', label: 'LCEVC_HEVC'},
//         ],
//         advanced_dash: [
//             {value: 'libx264', label: 'H.264'},
//             {value: 'libx265', label: 'H.265'},
//             {value: 'lcevc_h264', label: 'LCEVC_H264'},
//             {value: 'lcevc_hevc', label: 'LCEVC_HEVC'},            
//         ],
//         mp3: [
//             {value: 'mp3', label: 'MP3'},  
//         ],
//     }    

//     // file_extension
//     const fileExtentionOption = {
//         repack: [
//             {value: 'mp4', label: 'MP4'}, 
//             {value: 'webm', label: 'WEBM'}, 
//         ]
//     }

//     // audio_codec
//     const audioCodecOption = {
//         hls_audio: [
//             {value: 'libfdk_aac', label: 'FDK AAC'}, 
//         ]
//     }

//     // mode
//     const modeOption = {
//         speech_to_text: [
//             {value: 'balanced', label: 'Balanced'}, 
//             {value: 'accuracy', label: 'Accuracy'}, 
//             {value: 'speed', label: 'Speed'}, 
//         ]
//     }

//     console.log("format:", format )

//     const [isExpanded, setIsExpanded] = useState(false);
//     const [localFormat, setLocalFormat] = useState(format);

//     useEffect(() => {
//         setLocalFormat(format);
//     }, [format]);

//     const toggleExpand = () => setIsExpanded(!isExpanded);

//     // const handleInputChange = (name, value) => {
//     //     const updatedFormat = { ...localFormat, [name]: value };
//     //     setLocalFormat(updatedFormat);
//     // };

//     const handleStreamCodecChange = (value) => {
//         const updatedStream = localFormat.stream.map(item => ({
//             ...item,
//             video_codec: value
//         }));
//         const updatedFormat = { ...localFormat, stream: updatedStream };
//         setLocalFormat(updatedFormat);
//     };

//     const handleSave = () => {
//         onFormatUpdate(localFormat);
//     };

//     const handleArrayInputChange = (arrayName, index, key, value) => {
//         const updatedArray = localFormat[arrayName].map((item, idx) => 
//             idx === index ? { ...item, [key]: value } : item
//         );
//         const updatedFormat = { ...localFormat, [arrayName]: updatedArray };
//         setLocalFormat(updatedFormat);
//     };

//     const renderInputField = (label, name, value) => {
//         const type = typeof value === 'number' ? 'number' : 'text';
//         return (
//             <InputField
//                 inputText={value !== undefined && value !== null ? value.toString() : ''}
//                 onChange={(e) => {
//                     const newValue = type === 'number' ? parseFloat(e.target.value) : e.target.value;
//                     handleInputChange(name, newValue);
//                 }}
//                 name={name}
//                 placeholder=""
//                 label={label}
//                 type={type}
//             />
//         );
//     };

//     const renderSelectField = (label, name, value, options, onChangeHandler) => (
//         <Select
//             label={label}
//             name={name}
//             options={options}
//             value={value || ''}
//             onChange={(e) => onChangeHandler(e.target.value)}
//         />
//     );

//     const renderArrayItems = (array, title) => (
//         <div className={styles.arrayContainer}>
//             <div className={styles.arrayTitle}>{title}</div>
//             {array.map((item, idx) => (
//                 <div key={idx} className={styles.arrayItem}>
//                     {Object.keys(item).map((key) => (
//                         <span key={key}>
//                             {key}: {item[key]}
//                         </span>
//                     ))}
//                 </div>
//             ))}
//         </div>
//     );

//     const renderEditableArrayItems = (arrayName, array, itemFields) => (
//         <div className={styles.arrayContainer}>
//             {array.map((item, idx) => (
//                 <div key={idx} className={styles.arrayItem}>
//                     {itemFields.map((field) => (
//                         <InputField
//                             key={`${field.name}-${idx}`}
//                             inputText={item[field.name] !== undefined && item[field.name] !== null ? item[field.name].toString() : ''}
//                             onChange={(e) => {
//                                 const newValue = field.type === 'number' ? parseFloat(e.target.value) : e.target.value;
//                                 handleArrayInputChange(arrayName, idx, field.name, newValue);
//                             }}
//                             name={`${arrayName}.${idx}.${field.name}`}
//                             placeholder=""
//                             label={field.label}
//                             type={field.type}
//                         />
//                     ))}
//                 </div>
//             ))}
//         </div>
//     );

//     const handleInputChange = (e) => {
//         const { name, value } = e.target;
//         const updatedFormat = { ...localFormat, [name]: value };
//         onFormatUpdate(updatedFormat); // update state in parent imidiatelly
//     };  

//     return (
//         <div className={`${styles.outputCard} ${isExpanded ? styles.expanded : ''}`}>
//             <div className={styles.header} onClick={toggleExpand}>
//                 <span>Output {index + 1}</span>
//                 <span>{localFormat.output} / {localFormat.video_codec}</span>
//                 <span className={styles.icon}> {/* Add your icon here */} </span>
//             </div>
//             {isExpanded && (
//                 <div className={styles.body}>
//                     <div className={styles.formRow}>
//                         <Select
//                             label='Output Format'
//                             name='output'
//                             placeholder=''
//                             options={outputOptions}
//                             value={format?.output || ''}
//                             onChange={handleInputChange}
//                         />

//                         {renderSelectField("Output Format", "output", localFormat.output, outputOptions, (value) => handleInputChange('output', value))}
//                         {renderSelectField("Codec", "video_codec", localFormat.video_codec, [
//                             { value: 'MP3', label: 'MP3' },
//                             { value: 'H264', label: 'H264' },
//                             { value: 'libvpx', label: 'libvpx' },
//                             { value: 'libx264', label: 'libx264' }
//                         ], (value) => {
//                             handleInputChange('video_codec', value);
//                             if (localFormat.output === 'advanced_dash') {
//                                 handleStreamCodecChange(value);
//                             }
//                         })}
//                     </div>
//                     <div className={styles.formRow}>
//                         {localFormat.output !== 'advanced_dash' && renderInputField("Height", "height", localFormat.height)}
//                         {localFormat.output === 'gif' && (
//                             <>
//                                 {renderInputField("Width", "width", localFormat.width)}
//                                 {renderInputField("Framerate", "framerate", localFormat.framerate)}
//                             </>
//                         )}
//                     </div>
//                     <div className={styles.formRow}>
//                         {renderInputField("Quality", "quality", localFormat.quality)}
//                     </div>
//                     {localFormat.output === 'advanced_dash' && (
//                         <>
//                             {renderArrayItems(localFormat.stream, 'Stream')}
//                             {localFormat.subtitles && renderEditableArrayItems('subtitles.sources', localFormat.subtitles.sources, [
//                                 { name: 'source', label: 'Source', type: 'text' },
//                                 { name: 'language', label: 'Language', type: 'text' }
//                             ])}
//                         </>
//                     )}
//                     <div className={styles.formFooter}>
//                         <Button size="sm" onClick={handleSave}>Save</Button>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };

// OutputCard.propTypes = {
//     index: PropTypes.number.isRequired,
//     format: PropTypes.object.isRequired,
//     onFormatUpdate: PropTypes.func.isRequired
// };

// export default OutputCard;












// import React, { useState } from 'react';
// import PropTypes from 'prop-types';
// import { Select } from 'components/Select';
// import { InputField } from 'components/InputField';
// import { Button } from 'components/Button';
// import styles from '../index.module.css';

// export const OutputCard = ({ index, format, onFormatUpdate }) => {

//     console.log("format: ", format)

//     const [isExpanded, setIsExpanded] = useState(false);
//     const [localFormat, setLocalFormat] = useState(format);

//     const toggleExpand = () => setIsExpanded(!isExpanded);

//     const handleInputChange = (name, value) => {
//         // const { name, value } = e.target;
//         console.log({name, value})
//         const updatedFormat = { ...localFormat, [name]: value };
//         setLocalFormat(updatedFormat);
//     };

//     // const handleInputChange = (name, value) => {
//     //     console.log({name, value})
//     //     const updatedFormat = { ...localFormat, [name]: value };
//     //     setLocalFormat(updatedFormat);
//     // };

//     const handleArrayInputChange = (arrayName, index, key, value) => {
//         const updatedArray = localFormat[arrayName].map((item, i) => 
//             i === index ? { ...item, [key]: value } : item
//         );
//         setLocalFormat({ ...localFormat, [arrayName]: updatedArray });
//     };

//     const handleSave = () => {
//         onFormatUpdate(localFormat);
//     };

//     const renderInputField = (label, name, value) => {
//         const type = typeof value === 'number' ? 'number' : 'text';
//         return (
//             <InputField
//                 inputText={value !== undefined && value !== null ? value.toString() : ''}
//                 onChange={(e) => {
//                     const newValue = type === 'number' ? parseFloat(e.target.value) : e.target.value;
//                     handleInputChange(name, newValue);
//                 }}
//                 name={name}
//                 placeholder=""
//                 label={label}
//                 type={type}
//             />
//         );
//     };

//     const renderSelectField = (label, name, value, options) => (
//         <Select
//             label={label}
//             options={options}
//             value={value || ''}
//             // onChange={(value) => handleInputChange(name, value)}
//             onChange={(e) => handleInputChange(name, e.target.value)}
//         />
//     );

//     const renderArrayItems = (array, title) => (
//         <div className={styles.arrayContainer}>
//             <div className={styles.arrayTitle}>{title}</div>
//             {array.map((item, idx) => (
//                 <div key={idx} className={styles.arrayItem}>
//                     {Object.keys(item).map((key) => (
//                         <span key={key}>
//                             {key}: {item[key]}
//                         </span>
//                     ))}
//                 </div>
//             ))}
//         </div>
//     );

//     const renderEditableArrayItems = (arrayName, array, itemFields) => (
//         <div className={styles.arrayContainer}>
//             {array.map((item, idx) => (
//                 <div key={idx} className={styles.arrayItem}>
//                     {itemFields.map((field) => (
//                         <InputField
//                             key={`${field.name}-${idx}`}
//                             inputText={item[field.name] !== undefined && item[field.name] !== null ? item[field.name].toString() : ''}
//                             onChange={(e) => {
//                                 const newValue = field.type === 'number' ? parseFloat(e.target.value) : e.target.value;
//                                 handleArrayInputChange(arrayName, idx, field.name, newValue);
//                             }}
//                             name={`${arrayName}.${idx}.${field.name}`}
//                             placeholder=""
//                             label={field.label}
//                             type={field.type}
//                         />
//                     ))}
//                 </div>
//             ))}
//         </div>
//     );

//     return (
//         <div className={`${styles.outputCard} ${isExpanded ? styles.expanded : ''}`}>
//             <div className={styles.header} onClick={toggleExpand}>
//                 <span>Output {index + 1}</span>
//                 <span>{localFormat.output} / {localFormat.video_codec}</span>
//                 <span className={styles.icon}> {/* Add your icon here */} </span>
//             </div>
//             {isExpanded && (
//                 <div className={styles.body}>
//                     <div className={styles.formRow}>
//                         {renderSelectField("Output Format", "output", localFormat.output, [
//                             { value: 'mp3', label: 'MP3' },
//                             { value: 'mp4', label: 'MP4' },
//                             { value: 'webm', label: 'WEBM' },
//                             { value: 'gif', label: 'GIF' },
//                             { value: 'advanced_dash', label: 'Advanced DASH' }
//                         ])}
//                         {renderSelectField("Codec", "video_codec", localFormat.video_codec, [
//                             { value: 'MP3', label: 'MP3' },
//                             { value: 'H264', label: 'H264' },
//                             { value: 'libvpx', label: 'libvpx' },
//                             { value: 'libx264', label: 'libx264' }
//                         ])}
//                     </div>
//                     <div className={styles.formRow}>
//                         {localFormat.output !== 'advanced_dash' && renderInputField("Height", "height", localFormat.height)}
//                         {localFormat.output === 'gif' && (
//                             <>
//                                 {renderInputField("Width", "width", localFormat.width)}
//                                 {renderInputField("Framerate", "framerate", localFormat.framerate)}
//                             </>
//                         )}
//                     </div>
//                     <div className={styles.formRow}>
//                         {renderInputField("Quality", "quality", localFormat.quality)}
//                     </div>
//                     {localFormat.output === 'advanced_dash' && (
//                         <>
//                             {renderArrayItems(localFormat.stream, 'Stream')}
//                             {localFormat.subtitles && renderEditableArrayItems('subtitles.sources', localFormat.subtitles.sources, [
//                                 { name: 'source', label: 'Source', type: 'text' },
//                                 { name: 'language', label: 'Language', type: 'text' }
//                             ])}
//                         </>
//                     )}
//                     <div className={styles.formFooter}>
//                         <Button size="sm" onClick={handleSave}>Save</Button>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };

// OutputCard.propTypes = {
//     index: PropTypes.number.isRequired,
//     format: PropTypes.object.isRequired,
//     onFormatUpdate: PropTypes.func.isRequired
// };

// export default OutputCard;









// import React, { useState } from 'react';
// import PropTypes from 'prop-types';
// import { Select } from 'components/Select';
// import { InputField } from 'components/InputField';
// import { Button } from 'components/Button';
// import styles from '../index.module.css';

// export const OutputCard = ({ index, format, onFormatUpdate }) => {

//     console.log("format: ", format)

//     const [isExpanded, setIsExpanded] = useState(false);
//     const [localFormat, setLocalFormat] = useState(format);

//     const toggleExpand = () => setIsExpanded(!isExpanded);

//     const handleInputChange = (name, value) => {
//         const updatedFormat = { ...localFormat, [name]: value };
//         setLocalFormat(updatedFormat);
//     };

//     const handleSave = () => {
//         onFormatUpdate(localFormat);
//     };

//     const renderInputField = (label, name, value) => {
//         const type = typeof value === 'number' ? 'number' : 'text';
//         return (
//             <InputField
//                 inputText={value.toString()}
//                 onChange={(e) => {
//                     const newValue = type === 'number' ? parseFloat(e.target.value) : e.target.value;
//                     handleInputChange(name, newValue);
//                 }}
//                 name={name}
//                 placeholder=""
//                 label={label}
//                 type={type}
//             />
//         );
//     };

//     const renderSelectField = (label, name, value, options) => (
//         <Select
//             label={label}
//             options={options}
//             value={value}
//             onChange={(value) => handleInputChange(name, value)}
//         />
//     );

//     return (
//         <div 
//             className={`${styles.outputCard} ${isExpanded ? styles.expanded : ''}`} 
//         >
//             <div className={styles.header} onClick={toggleExpand}>
//                 <span>Output {index + 1}</span>
//                 <span>{localFormat.output} / {localFormat.video_codec}</span>
//                 <span className={styles.icon}> {/* Add your icon here */} </span>
//             </div>
//             {isExpanded && (
//                 <div className={styles.body}>
//                     <div className={styles.formRow}>
//                         {renderSelectField("Output Format", "output", localFormat.output, [
//                             { value: 'mp3', label: 'MP3' },
//                             { value: 'mp4', label: 'MP4' },
//                             { value: 'webm', label: 'WEBM' },
//                             { value: 'gif', label: 'GIF' },
//                             { value: 'advanced_dash', label: 'Advanced DASH' }
//                         ])}
//                         {renderSelectField("Codec", "video_codec", localFormat.video_codec, [
//                             { value: 'MP3', label: 'MP3' },
//                             { value: 'H264', label: 'H264' },
//                             { value: 'libvpx', label: 'libvpx' },
//                             { value: 'libx264', label: 'libx264' }
//                         ])}
//                     </div>
//                     <div className={styles.formRow}>
//                         {localFormat.output !== 'advanced_dash' && renderInputField("Height", "height", localFormat.height)}
//                         {localFormat.output === 'advanced_dash' && (
//                             <>
//                                 <InputField
//                                     inputText={localFormat.separate_audio.toString()}
//                                     onChange={(e) => handleInputChange('separate_audio', parseInt(e.target.value, 10))}
//                                     name="separate_audio"
//                                     placeholder=""
//                                     label="Separate Audio"
//                                     type="number"
//                                 />
//                                 {localFormat.stream.map((stream, idx) => (
//                                     <div key={idx} className={styles.streamRow}>
//                                         {renderInputField(`Stream ${idx + 1} Height`, `stream[${idx}].height`, stream.height)}
//                                         {renderInputField(`Stream ${idx + 1} Bitrate`, `stream[${idx}].bitrate`, stream.bitrate)}
//                                     </div>
//                                 ))}
//                             </>
//                         )}
//                         {localFormat.output === 'gif' && (
//                             <>
//                                 {renderInputField("Width", "width", localFormat.width)}
//                                 {renderInputField("Framerate", "framerate", localFormat.framerate)}
//                             </>
//                         )}
//                     </div>
//                     <div className={styles.formRow}>
//                         {renderInputField("Quality", "quality", localFormat.quality)}
//                     </div>
//                     <div className={styles.formFooter}>
//                         <Button size="sm" onClick={handleSave}>Save</Button>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };

// OutputCard.propTypes = {
//     index: PropTypes.number.isRequired,
//     format: PropTypes.object.isRequired,
//     onFormatUpdate: PropTypes.func.isRequired
// };

// export default OutputCard;






// import React, { useState } from 'react';
// import PropTypes from 'prop-types';
// import { Select } from 'components/Select';
// import { InputField } from 'components/InputField';
// import { Button } from 'components/Button';
// import styles from '../index.module.css';

// export const OutputCard = ({ index, format, onFormatUpdate }) => {

//     console.log("Format: ", format)

//     const [isExpanded, setIsExpanded] = useState(false);
//     const [localFormat, setLocalFormat] = useState(format);

//     const toggleExpand = () => setIsExpanded(!isExpanded);

//     const handleInputChange = (name, value) => {
//         const updatedFormat = { ...localFormat, [name]: value };
//         setLocalFormat(updatedFormat);
//     };

//     const handleSave = () => {
//         onFormatUpdate(localFormat);
//     };

//     const renderInputField = (label, name, value, type = 'text') => (
//         <InputField
//             inputText={value}
//             onChange={(e) => handleInputChange(name, e.target.value)}
//             name={name}
//             placeholder=""
//             label={label}
//             type={type}
//         />
//     );

//     const renderSelectField = (label, name, value, options) => (
//         <Select
//             label={label}
//             options={options}
//             value={value}
//             onChange={(value) => handleInputChange(name, value)}
//         />
//     );

//     return (
//         <div 
//             className={`${styles.outputCard} ${isExpanded ? styles.expanded : ''}`} 
//         >
//             <div className={styles.header} onClick={toggleExpand}>
//                 <span>Output {index + 1}</span>
//                 <span>{localFormat.output} / {localFormat.video_codec}</span>
//                 <span className={styles.icon}> {/* Add your icon here */} </span>
//             </div>
//             {isExpanded && (
//                 <div className={styles.body}>
//                     <div className={styles.formRow}>
//                         {renderSelectField("Output Format", "output", localFormat.output, [
//                             { value: 'mp3', label: 'MP3' },
//                             { value: 'mp4', label: 'MP4' },
//                             { value: 'webm', label: 'WEBM' },
//                             { value: 'gif', label: 'GIF' },
//                             { value: 'advanced_dash', label: 'Advanced DASH' }
//                         ])}
//                         {renderSelectField("Codec", "video_codec", localFormat.video_codec, [
//                             { value: 'MP3', label: 'MP3' },
//                             { value: 'H264', label: 'H264' },
//                             { value: 'libvpx', label: 'libvpx' },
//                             { value: 'libx264', label: 'libx264' }
//                         ])}
//                     </div>
//                     <div className={styles.formRow}>
//                         {localFormat.output !== 'advanced_dash' && renderInputField("Height", "height", localFormat.height)}
//                         {localFormat.output === 'advanced_dash' && (
//                             <>
//                                 <InputField
//                                     inputText={localFormat.separate_audio}
//                                     onChange={(e) => handleInputChange('separate_audio', e.target.value)}
//                                     name="separate_audio"
//                                     placeholder=""
//                                     label="Separate Audio"
//                                     type="number"
//                                 />
//                                 {localFormat.stream.map((stream, idx) => (
//                                     <div key={idx} className={styles.streamRow}>
//                                         {renderInputField(`Stream ${idx + 1} Height`, `stream[${idx}].height`, stream.height)}
//                                         {renderInputField(`Stream ${idx + 1} Bitrate`, `stream[${idx}].bitrate`, stream.bitrate)}
//                                     </div>
//                                 ))}
//                             </>
//                         )}
//                         {localFormat.output === 'gif' && (
//                             <>
//                                 {renderInputField("Width", "width", localFormat.width)}
//                                 {renderInputField("Framerate", "framerate", localFormat.framerate)}
//                             </>
//                         )}
//                     </div>
//                     <div className={styles.formRow}>
//                         {renderInputField("Quality", "quality", localFormat.quality)}
//                     </div>
//                     <div className={styles.formFooter}>
//                         <Button size="sm" onClick={handleSave}>Save</Button>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };

// OutputCard.propTypes = {
//     index: PropTypes.number.isRequired,
//     format: PropTypes.object.isRequired,
//     onFormatUpdate: PropTypes.func.isRequired
// };

// export default OutputCard;

