import React from 'react';
import PropTypes from 'prop-types';
import getResolutionReadable from "helpers/getResolutionReadable";
import HorizontalList from "components/HorizontalList";

function ResolutionsCell({ info }) {
  const format = info.row.original?.query?.query?.format || [];

  if (Array.isArray(format)) {
    const resolution = getResolutionReadable(format[0]);

    return (
      <span>{
        Array.isArray(resolution) ?
          (
            <HorizontalList list={resolution}/>
          )
          : resolution
      }</span>
    );
  }
}


ResolutionsCell.propTypes = {
  info: PropTypes.shape({
    row: PropTypes.shape({
      original: PropTypes.shape({
        query: PropTypes.shape({
          query: PropTypes.shape({
            format: PropTypes.array,
          }),
        }),
      }),
    }),
  }),
};

export default ResolutionsCell;