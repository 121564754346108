import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputField } from 'components/InputField';
import { FormCheckbox } from 'components/FormCheckbox';
import { Button } from 'components/Button';
import { Select } from 'components/Select';
import styles from '../../index.module.css'

const amazonRegionOptions = [
    { label: 'US East (N. Virginia)', value: 'us-east-1' },
    { label: 'US East (Ohio)', value: 'us-east-2' },
    { label: 'US West (N. California)', value: 'us-west-1' },
    { label: 'US West (Oregon)', value: 'us-west-2' },
    { label: 'Canada (Central)', value: 'ca-central-1' },
    { label: 'Asia Pacific (Mumbai)', value: 'ap-south-1' },
    { label: 'Asia Pacific (Seoul)', value: 'ap-northeast-2' },
    { label: 'Asia Pacific (Singapore)', value: 'ap-southeast-1' },
    { label: 'Asia Pacific (Sydney)', value: 'ap-southeast-2' },
    { label: 'Asia Pacific (Tokyo)', value: 'ap-northeast-1' },
    { label: 'China (Beijing)', value: 'cn-north-1' },
    { label: 'China (Ningxia)', value: 'cn-northwest-1' },
    { label: 'EU (Frankfurt)', value: 'eu-central-1' },
    { label: 'EU (Ireland)', value: 'eu-west-1' },
    { label: 'EU (London)', value: 'eu-west-2' },
    { label: 'EU (Paris)', value: 'eu-west-3' },
    { label: 'South America (São Paulo)', value: 'sa-east-1' }
];    


export const AmazonForm = ({ storageCredentials, onChange, onSave, onCancel }) => {
    const { 
        bucket_name, region_name, access_key_id, secret_access_key, public_permissions, reduce_redundancy 
    } = storageCredentials.amazon;

    const [validationErrors, setValidationErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        onChange({
            target: {
                name: name,
                value: value
            }
        })

        const [, key] = name.split('.');

        // Clear validation errors when input changes
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [key]: null,
        }));
    };

    const handleSave = () => {
        const errors = {};

        if (!bucket_name) {
            errors.bucket_name = 'Bucket name is required';
        }

        if (!region_name) {
            errors.region_name = 'Region name is required';
        }

        if (!access_key_id) {
            errors.access_key_id = 'Access Key ID is required';
        }
        if (!secret_access_key) {
            errors.secret_access_key = 'Secret Access Key is required';
        }

        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
        } else {
            onSave();
        }
    };

    // enable button when all input fields are set
    const isSaveDisabled = !bucket_name || !region_name || !access_key_id || !secret_access_key;

    return (
        <div className={styles.storageFormContainer}>

            <div className={styles.formRow}>
                <InputField
                    inputText={bucket_name}
                    onChange={handleInputChange}
                    name="amazon.bucket_name"
                    placeholder=""
                    label="Bucket Name"
                    state={validationErrors.bucket_name ? 'error' : 'default'}
                    hint={validationErrors.bucket_name || ''}
                />  
                <Select
                    onChange={handleInputChange}                                        
                    name="amazon.region_name"
                    label="Region name"
                    placeholder='Select region'
                    options={amazonRegionOptions}
                    value={region_name}
                    state={validationErrors.region_name ? 'error' : 'default'}
                    hint={validationErrors.region_name || ''}                    
                />                                               
            </div>    

            <div className={styles.formRow}>
                <InputField
                    inputText={access_key_id}
                    onChange={handleInputChange}
                    name="amazon.access_key_id"
                    placeholder=""
                    label="Access Key ID"
                    autocomplete="off"
                    state={validationErrors.access_key_id ? 'error' : 'default'}
                    hint={validationErrors.access_key_id || ''}
                />  
                <InputField
                    inputText={secret_access_key}
                    onChange={handleInputChange}
                    name="amazon.secret_access_key"
                    placeholder=""
                    label="Secret Access Key"
                    type="password"
                    autocomplete="new-password"
                    state={validationErrors.secret_access_key ? 'error' : 'default'}
                    hint={validationErrors.secret_access_key || ''}
                />                                              
            </div>        

            <div className={styles.formRow}>
                <FormCheckbox
                    name="amazon.public_permissions"
                    checked={public_permissions || false}
                    label="Public permissions"
                    onChange={handleInputChange}
                />
                <FormCheckbox
                    name="amazon.reduce_redundancy"
                    checked={reduce_redundancy || false}
                    label="Reduce redundancy storage"
                    onChange={handleInputChange}
                />
            </div>   


            <div className={styles.formFooter}>
                <Button 
                    size="sm"
                    type="secondary"
                    onClick={onCancel}
                >
                    Cancel
                </Button>                 
                <Button size="sm" onClick={handleSave} state={isSaveDisabled ? "disabled" : undefined}>
                    Save
                </Button>
            </div>
        </div>
    );
};

AmazonForm.propTypes = {
    storageCredentials: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};


