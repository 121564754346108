import PropTypes from "prop-types";
import React from "react";

export const ChevronRight = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path d="M7.5 11.5L10.5 8.5L7.5 5.5" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>    
  );
};

ChevronRight.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

ChevronRight.defaultProps = {
  color: 'currentColor', // Or any default color
};