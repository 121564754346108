import getErrorMessage from "helpers/getErrorMessage";
import utcStringToDate from "helpers/utcStringToDate";

//const protocol = 'https'
//const endpoint = 'account-api-qa.qencode.com'
//const api_version = 'v1'

import config from 'config';

const { protocol, apiVersion: api_version, accountApiSubdomain, domain } = config;

const endpoint = `${accountApiSubdomain}.${domain}`

export const generate2FAQRCode = async (accessToken) => {
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/user/2fa/qr`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        });

        if (!response.ok) {
            // Handle HTTP error responses
            return { success: false, error: `HTTP error: ${response.status}` };
        }

        // Convert the ReadableStream to a Blob
        const blob = await response.blob();

        // Create an object URL for the blob
        const qrDataUrl = URL.createObjectURL(blob);

        // Now qrDataUrl can be used as the src for an image
        return { success: true, qrDataUrl };

    } catch (error) {
        return { success: false, error: error.message || 'Failed to generate 2FA QR code' };
    }
};

export const get2FAData = async (accessToken) => {
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/user/2fa/url`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        });

        if (!response.ok) {
            // Handle HTTP error responses
            return { success: false, error: `HTTP error: ${response.status}` };
        }

        const { 
            detail,
            error,
            secret,
            uri
        } = await response.json();

        if (error) {
            return { success: false, error, detail };
        }
  
        return { success: true, secret, uri };        

    } catch (error) {
        return { success: false, error: error.message || 'Failed to get 2FA data' };
    }
};

export const delete2FAQRCode = async (accessToken) => {
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/user/2fa`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        });

  
        if (!response.ok) {
            // Handle HTTP error responses
            return { success: false, error: `HTTP error: ${response.status}` };
        }
  
        const { 
            detail,
            error
        } = await response.json();


        if (error) {
            return { success: false, error, detail };
        }
  
        return { success: true };
  
    } catch (error) {
        return { success: false, error: error.message || 'Failed to delete 2FA' };
    }
};

export const disable2FA = async (accessToken) => {
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/user/2fa`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        });

  
        if (!response.ok) {
            // Handle HTTP error responses
            return { success: false, error: `HTTP error: ${response.status}` };
        }
  
        const { 
            detail,
            error
        } = await response.json();


        if (error) {
            return { success: false, error, detail };
        }
  
        return { success: true };
  
    } catch (error) {
        return { success: false, error: error.message || 'Failed to delete 2FA' };
    }
};

export const getUserData = async (accessToken) => {
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/user`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        });

  
        if (!response.ok) {
            // Handle HTTP error responses
            return { success: false, error: `HTTP error: ${response.status}` };
        }
  
        const { 
            detail,
            error,
            data
        } = await response.json();


        if (error) {
            return { success: false, error, detail };
        }
  
        return { success: true, data };
  
    } catch (error) {
        return { success: false, error: error.message || 'Failed to get user data' };
    }
};

export const updateUserData = async (accessToken, credentials) => {
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/user`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(credentials)
        });

  
        if (!response.ok) {
            // Handle HTTP error responses
            return { success: false, error: `HTTP error: ${response.status}` };
        }
  
        const { 
            detail,
            error,
            data
        } = await response.json();


        if (error) {
            return { success: false, error, detail };
        }
  
        return { success: true, data };
  
    } catch (error) {
        return { success: false, error: error.message || 'Failed to update user data' };
    }
};

export const getOrganizationData = async (accessToken) => {
    console.log("accessToken in getOrganizationData: ", accessToken)
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/organization`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        });

  
        if (!response.ok) {
            // Handle HTTP error responses
            return { success: false, error: `HTTP error: ${response.status}` };
        }
  
        const { 
            detail,
            error,
            data
        } = await response.json();


        if (error) {
            return { success: false, error, detail };
        }
  
        return { success: true, data };
  
    } catch (error) {
        return { success: false, error: error.message || 'Failed to get organization data' };
    }
};

export const updateOrganizationData = async (accessToken, credentials) => {
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/organization`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(credentials)
        });

  
        if (!response.ok) {
            // Handle HTTP error responses
            return { success: false, error: `HTTP error: ${response.status}` };
        }
  
        const { 
            detail,
            error,
            data
        } = await response.json();


        if (error) {
            return { success: false, error, detail };
        }
  
        return { success: true, data };
  
    } catch (error) {
        return { success: false, error: error.message || 'Failed to update organization data' };
    }
};

export const verifyUserPassword = async (accessToken, credentials) => {
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/user/verify-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(credentials)
        });

  
        if (!response.ok) {
            // Handle HTTP error responses
            return { success: false, error: `HTTP error: ${response.status}` };
        }
  
        const { 
            detail,
            error,
        } = await response.json();


        if (error) {
            return { success: false, error, detail };
        }
  
        return { success: true };
  
    } catch (error) {
        return { success: false, error: error.message || 'Failed to verify user password' };
    }
};

export const setNewUserPassword = async (accessToken, credentials) => {
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/user/passwd`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(credentials)
        });

  
        if (!response.ok) {
            // Handle HTTP error responses
            return { success: false, error: `HTTP error: ${response.status}` };
        }
  
        const { 
            detail,
            error,
        } = await response.json();


        if (error) {
            return { success: false, error, detail };
        }
  
        return { success: true };
  
    } catch (error) {
        return { success: false, error: error.message || 'Failed to set new user password' };
    }
};

export const getTeamData = async (accessToken) => {
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/organization/team`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        });

  
        if (!response.ok) {
            // Handle HTTP error responses
            return { success: false, error: `HTTP error: ${response.status}` };
        }
  
        const { 
            detail,
            error,
            data
        } = await response.json();


        if (error) {
            return { success: false, error, detail };
        }
  
        return { success: true, data };
  
    } catch (error) {
        return { success: false, error: error.message || 'Failed to get team data' };
    }
};

export const updateTeam = async (accessToken, credentials) => {
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/organization/team`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(credentials)
        });

  
        if (!response.ok) {
            // Handle HTTP error responses
            return { success: false, error: `HTTP error: ${response.status}` };
        }
  
        const { 
            detail,
            error,
            data
        } = await response.json();


        if (error) {
            return { success: false, error, detail };
        }
  
        return { success: true, data };
  
    } catch (error) {
        return { success: false, error: error.message || 'Failed to update team data' };
    }
};

export const addTeamMember = async (accessToken, credentials) => {
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/organization/team`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(credentials)
        });

  
        if (!response.ok) {
            // Handle HTTP error responses
            return { success: false, error: `HTTP error: ${response.status}` };
        }
  
        const { 
            detail,
            error,
            data
        } = await response.json();


        if (error) {
            return { success: false, error, detail };
        }
  
        return { success: true, data };
  
    } catch (error) {
        return { success: false, error: error.message || 'Failed to add team member' };
    }
};

export const removeTeamMember = async (accessToken, credentials) => {
    const {uuid} = credentials
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/organization/team/${uuid}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        });

  
        if (!response.ok) {
            // Handle HTTP error responses
            return { success: false, error: `HTTP error: ${response.status}` };
        }
  
        const { 
            detail,
            error,
            data
        } = await response.json();


        if (error) {
            return { success: false, error, detail };
        }
  
        return { success: true, data };
  
    } catch (error) {
        return { success: false, error: error.message || 'Failed to remove team member' };
    }
};


// BILLING PAGE
export const getInvoicesList = async (accessToken) => {
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/billing/invoice`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            //body: JSON.stringify(settings)
        });

  
        if (!response.ok) {
            // Handle HTTP error responses
            return { success: false, error: `HTTP error: ${response.status}` };
        }
  
        const {
            detail,
            error,
            data,
            total_count
        } = await response.json();


        if (error) {
            return { success: false, error, detail };
        }
  
        return { success: true, data, total_count };
  
    } catch (error) {
        return { success: false, error: error.message || 'Failed to get invoices data' };
    }
};

export const getReceipstList = async (accessToken) => {
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/billing/receipt`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            //body: JSON.stringify(settings)
        });

  
        if (!response.ok) {
            // Handle HTTP error responses
            return { success: false, error: `HTTP error: ${response.status}` };
        }
  
        const {
            detail,
            error,
            data,
            total_count
        } = await response.json();


        if (error) {
            return { success: false, error, detail };
        }
  
        return { success: true, data, total_count };
  
    } catch (error) {
        return { success: false, error: error.message || 'Failed to get receipts data' };
    }
};

export const getPaymentMethodsList = async (accessToken) => {
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/billing/payment-method`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            //body: JSON.stringify(settings)
        });

  
        if (!response.ok) {
            // Handle HTTP error responses
            return { success: false, error: `HTTP error: ${response.status}` };
        }
  
        const {
            detail,
            error,
            data,
            total_count
        } = await response.json();


        if (error) {
            return { success: false, error, detail };
        }
  
        return { success: true, data, total_count };
  
    } catch (error) {
        return { success: false, error: error.message || 'Failed to get receipts data' };
    }
};

export const getInvoiceData = async (accessToken, settings) => {
    const {id} = settings
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/billing/invoice/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        });

  
        if (!response.ok) {
            // Handle HTTP error responses
            return { success: false, error: `HTTP error: ${response.status}` };
        }
  
        const {
            detail,
            error,
            data,
        } = await response.json();


        if (error) {
            return { success: false, error, detail };
        }
  
        return { success: true, data };
  
    } catch (error) {
        return { success: false, error: error.message || 'Failed to get invoice data' };
    }
};

export const getReceiptData = async (accessToken, settings) => {
    const {id} = settings
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/billing/receipt/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        });

  
        if (!response.ok) {
            // Handle HTTP error responses
            return { success: false, error: `HTTP error: ${response.status}` };
        }
  
        const {
            detail,
            error,
            data,
        } = await response.json();


        if (error) {
            return { success: false, error, detail };
        }
  
        return { success: true, data };
  
    } catch (error) {
        return { success: false, error: error.message || 'Failed to get invoice data' };
    }
};

export const addPaypal = async (accessToken, credentials) => {
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/billing/paypal`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(credentials)
        });

        const responseBody = await response.json(); // Parse the JSON body upfront for any type of response

        if (!response.ok) {
            // Handle HTTP error responses
            if (response.status === 400) {
                // More specific handling for 400 Bad Request
                return {
                    success: false,
                    error: responseBody.error,
                    detail: responseBody.detail || `Bad Request: Status code ${response.status}`
                };
            } else {
                // General error handling for other types of errors
                return {
                    success: false,
                    error: responseBody.error,
                    detail: `HTTP error: ${response.status} ${responseBody.detail || ''}`
                };
            }
        }

        if (responseBody.error && responseBody.error !== 0) {
            // Check if there's an error reported by the server, assuming '0' means no error
            return {
                success: false,
                error: responseBody.error,
                detail: responseBody.detail || 'An error occurred'
            };
        }

        // If the response is successful and contains no error code
        return { success: true, data: responseBody.data };

    } catch (error) {
        return {
            success: false,
            error: 1, // Using '1' as a generic error code for exceptions
            detail: error.message || 'Failed to add Paypal due to an unexpected error'
        };
    }
};

export const deletePaypal = async (accessToken, settings) => {
    const { email } = settings;
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/billing/paypal/${email}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        });

        const responseBody = await response.json(); // Parse the JSON body upfront for any type of response

        if (!response.ok) {
            // Handle HTTP error responses
            if (response.status === 400) {
                // Specific handling for 400 Bad Request
                return {
                    success: false,
                    error: responseBody.error, // Use the error code from the server
                    detail: responseBody.detail || `Bad Request: Status code ${response.status}`
                };
            } else {
                // General error handling for other types of errors
                return {
                    success: false,
                    error: responseBody.error, // Use the error code from the server if available
                    detail: `HTTP error: ${response.status} ${responseBody.detail || ''}`
                };
            }
        }

        if (responseBody.error && responseBody.error !== 0) {
            // Check if there's an error reported by the server, assuming '0' means no error
            return {
                success: false,
                error: responseBody.error,
                detail: responseBody.detail || 'An unspecified error occurred during the deletion process'
            };
        }

        // If the response is successful and contains no error code
        return { success: true, detail: responseBody.detail || 'PayPal payment method deleted successfully' };

    } catch (error) {
        return {
            success: false,
            error: 1, // Using '1' as a generic error code for exceptions
            detail: error.message || 'Failed to remove Paypal payment method due to an unexpected error'
        };
    }
};

export const addCard = async (accessToken, credentials) => {
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/billing/card`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(credentials)
        });

        const responseBody = await response.json(); // Parse the JSON body upfront for any type of response

        if (!response.ok) {
            // Handle HTTP error responses
            if (response.status === 400) {
                // More specific handling for 400 Bad Request
                return {
                    success: false,
                    error: responseBody.error,
                    detail: responseBody.detail || `Bad Request: Status code ${response.status}`
                };
            } else {
                // General error handling for other types of errors
                return {
                    success: false,
                    error: responseBody.error,
                    detail: `HTTP error: ${response.status} ${responseBody.detail || ''}`
                };
            }
        }

        if (responseBody.error && responseBody.error !== 0) {
            // Check if there's an error reported by the server, assuming '0' means no error
            return {
                success: false,
                error: responseBody.error,
                detail: responseBody.detail || 'An error occurred'
            };
        }

        // If the response is successful and contains no error code
        return { success: true, data: responseBody.data };

    } catch (error) {
        return {
            success: false,
            error: 1, // Using '1' as a generic error code for exceptions
            detail: error.message || 'Failed to add Card due to an unexpected error'
        };
    }
};

export const deleteCard = async (accessToken, settings) => {
    const { card_id } = settings;
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/billing/card/${card_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        });

        const responseBody = await response.json(); // Parse the JSON body upfront for any type of response

        if (!response.ok) {
            // Handle HTTP error responses
            if (response.status === 400) {
                // Specific handling for 400 Bad Request
                return {
                    success: false,
                    error: responseBody.error, // Use the error code from the server
                    detail: responseBody.detail || `Bad Request: Status code ${response.status}`
                };
            } else {
                // General error handling for other types of errors
                return {
                    success: false,
                    error: responseBody.error, // Use the error code from the server if available
                    detail: `HTTP error: ${response.status} ${responseBody.detail || ''}`
                };
            }
        }

        if (responseBody.error && responseBody.error !== 0) {
            // Check if there's an error reported by the server, assuming '0' means no error
            return {
                success: false,
                error: responseBody.error,
                detail: responseBody.detail || 'An unspecified error occurred during the deletion process'
            };
        }

        // If the response is successful and contains no error code
        return { success: true, detail: responseBody.detail || 'Card payment method deleted successfully' };

    } catch (error) {
        return {
            success: false,
            error: 1, // Using '1' as a generic error code for exceptions
            detail: error.message || 'Failed to remove Card payment method due to an unexpected error'
        };
    }
};

export const makeCardDefault = async (accessToken, settings) => {
    const { card_id } = settings;
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/billing/card/${card_id}/default`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        });

        const responseBody = await response.json(); // Parse the JSON body upfront for any type of response

        if (!response.ok) {
            // Handle HTTP error responses
            if (response.status === 400) {
                // Specific handling for 400 Bad Request
                return {
                    success: false,
                    error: responseBody.error, // Use the error code from the server
                    detail: responseBody.detail || `Bad Request: Status code ${response.status}`
                };
            } else {
                // General error handling for other types of errors
                return {
                    success: false,
                    error: responseBody.error, // Use the error code from the server if available
                    detail: `HTTP error: ${response.status} ${responseBody.detail || ''}`
                };
            }
        }

        if (responseBody.error && responseBody.error !== 0) {
            // Check if there's an error reported by the server, assuming '0' means no error
            return {
                success: false,
                error: responseBody.error,
                detail: responseBody.detail || 'An unspecified error occurred during the updating process'
            };
        }

        // If the response is successful and contains no error code
        return { success: true, detail: responseBody.detail || 'Card payment method made default successfully' };

    } catch (error) {
        return {
            success: false,
            error: 1, // Using '1' as a generic error code for exceptions
            detail: error.message || 'Failed to made default Card payment method due to an unexpected error'
        };
    }
};

export const getWallet = async (accessToken) => {
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/billing/wallet`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
        });

        const responseBody = await response.json(); // Parse the JSON body upfront for any type of response

        if (!response.ok) {
            // Handle HTTP error responses
            if (response.status === 400) {
                // More specific handling for 400 Bad Request
                return {
                    success: false,
                    error: responseBody.error,
                    detail: responseBody.detail || `Bad Request: Status code ${response.status}`
                };
            } else {
                // General error handling for other types of errors
                return {
                    success: false,
                    error: responseBody.error,
                    detail: `HTTP error: ${response.status} ${responseBody.detail || ''}`
                };
            }
        }

        if (responseBody.error && responseBody.error !== 0) {
            // Check if there's an error reported by the server, assuming '0' means no error
            return {
                success: false,
                error: responseBody.error,
                detail: responseBody.detail || 'An error occurred'
            };
        }

        // If the response is successful and contains no error code
        return { success: true, data: responseBody.data };

    } catch (error) {
        return {
            success: false,
            error: 1, // Using '1' as a generic error code for exceptions
            detail: error.message || 'Failed to get Wallet info due to an unexpected error'
        };
    }
};

export async function getProjectList(
  {
    accessToken,
    type,
  }
){
    try {
        const params = new URLSearchParams();
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/project/${type}?${ params }`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        });
        const json = await response.json();

        return {
            success: true,
            data: json.data.map((i) => ({
              ...i,
              create_datetime: utcStringToDate(i.create_datetime),
            })),
        }
    } catch (error) {
        return {
            success: false,
            error: "An error happened",
        }
    }
}

export async function createProject(
  {
      accessToken,
      type,
      name,
  }
) {
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/project/${type}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                name,
            })
        });
        const json = await response.json();

        if (json.error === 1) {
            return {
                success: false,
                error: getErrorMessage(json),
            };
        }
        return {
            success: true,
        }
    } catch (error) {
        return {
            success: false,
            error: "An error happened",
        }
    }
}

export async function deleteProject(
  {
      accessToken,
      type,
      id,
  }
) {
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/project/${type}/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        });
        const json = await response.json();
        if (json.error === 0) {
            return {
                success: true,
            }
        }
        return {
            success: false,
            error: json.detail || "An error happened",
        }
    } catch (error) {
        return {
            success: false,
            error: "An error happened",
        }
    }
}

export async function renameProject(
  {
      accessToken,
      type,
      id,
      name,
  }
) {
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/project/${type}/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
                name,
            }),
        });
        const json = await response.json();

        if (json.error === 1) {
            return {
                success: false,
                error: json.detail[0].error,
            };
        }
        return {
            success: true,
        }
    } catch (error) {
        return {
            success: false,
            error: "An error happened",
        }
    }
}

export async function recreateProjectApiKey(
  {
      accessToken,
      type,
      id,
  }
) {
    try {
        const response = await fetch(`${protocol}://${endpoint}/${api_version}/project/${type}/reset-api-key/${ id }`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        });
        await response.json();
        return {
            success: true,
        }
    } catch (error) {
        return {
            success: false,
            error: "An error happened",
        }
    }
}

export async function getTemplateList(
  {
    accessToken,
  }
){
  try {
    const response = await fetch(`${protocol}://${endpoint}/${api_version}/transcoding/template`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });
    const json = await response.json();

    if (json.error === 0) {
      return {
        success: true,
        data: json.data,
      }
    }
    return {
      success: false,
      error: "An error happened",
    }
  } catch (error) {
    return {
      success: false,
      error: "An error happened",
    }
  }
}

export async function updateTemplate(
  {
    accessToken,
    id,
    name,
  }
) {
  try {
    const response = await fetch(`${protocol}://${endpoint}/${api_version}/transcoding/template/${ id }`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        name,
      })
    });
    const json = await response.json();
    return getErrorAndSuccessStatus(json);
  } catch (error) {
    return {
      success: false,
      error: "An error happened",
    }
  }
}

function getErrorAndSuccessStatus(json) {
  let maybeError = json?.detail?.[0]?.error;
  if (json.error === 1 || maybeError) {
    if (Array.isArray(json?.detail)) {
      
      if (typeof maybeError === "string") {
        return {
          success: false,
          error: maybeError,
        }
      }
    } else if (typeof json?.detail === 'string') {
      return {
        success: false,
        error: json.detail,
      };
    }
  }
  
  return {
    success: true,
  }
}

export async function duplicateTemplate(
  {
    accessToken,
    id,
    name,
  }
) {
  try {
    const response = await fetch(`${protocol}://${endpoint}/${api_version}/transcoding/template/${ id }/copy`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        name,
      })
    });
    const json = await response.json();
    return getErrorAndSuccessStatus(json);
  } catch (error) {
    return {
      success: false,
      error: "An error happened",
    }
  }
}

export async function deleteTemplate(
  {
    accessToken,
    id,
  }
) {
  try {
    const response = await fetch(`${protocol}://${endpoint}/${api_version}/transcoding/template/${ id }`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });
    const json = await response.json();
    if (json.error === 0) {
      return {
        success: true,
      }
    }
    return {
      success: false,
      error: "An error happened",
    }
  } catch (error) {
    return {
      success: false,
      error: "An error happened",
    }
  }
}

export async function listTasks({
  accessToken,
  params,
}) {
  const genericError = 'Failed to get list of tasks';
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/transcoding/task?${ params }`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0 && Array.isArray(json.data)) {
      return {
        data: json.data,
        totalCount: json['total_count'] || json.data.length,
        success: true,
      };
    } else {
      if (Array.isArray(json.detail)) {
        const error = json.detail.find((errorObj) => errorObj['field_name'] === 'token');
        if (error) {
          return {
            error: error.error,
            success: false,
          };
        } else {
          return {
            error: json.detail,
            success: false,
          };
        }
      }
      return {
        data: [],
        totalCount: 0,
        success: false,
      };
    }
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function listPlayers({
    accessToken,
  }) {
  const genericError = 'Failed to load list of video players.';
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/player`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0 && Array.isArray(json.data)) {
      return {
        data: json.data.map((item) => ({
          ...item,
          created: utcStringToDate(item.created),
        })),
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function createPlayer({
  accessToken,
  name,
  domain,
}){
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/player`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        name,
        domain,
      }),
    } );
    
    const json = await response.json();
    return getErrorAndSuccessStatus(json);
  } catch (error) {
    return {
      success: false,
    };
  }
}

export async function deletePlayer({
   accessToken,
   id,
 }){
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/player/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    
    const json = await response.json();
    return getErrorAndSuccessStatus(json);
  } catch (error) {
    return {
      success: false,
    };
  }
}

export async function clonePlayer({
  accessToken,
  id,
  name,
  domain,
}) {
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/player/${id}/copy`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        domain,
      }),
    } );
    
    const json = await response.json();
    return getErrorAndSuccessStatus(json);
  } catch (error) {
    return {
      success: false,
    }
  }
}

export async function editPlayer({
  accessToken,
  id,
  name,
  domain,
}) {
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/player/${id}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        domain,
      }),
    } );

    const json = await response.json();
    if (json.error === 1) {
      return {
        details: json.detail,
        success: false,
      };
    }
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
}

export async function getPlayerStats({
    accessToken,
    params,
  }) {
  const genericError = 'Failed to load video player statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/player/stats?${params}`, {
    
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0 && Array.isArray(json.data)) {
      return {
        data: json.data.map((i) => parseNumeric(i, [
          'impressions',
          'minutes_played',
          'plays',
        ])),
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function listPlayersWithStats(options) {
  const genericError = 'Failed to load list of video players.';
  try {
    const [players, stats] = await Promise.allSettled([
      listPlayers(options),
      getPlayerStats(options),
    ]);

    if (players.status === 'fulfilled' && players.value.success) {
      if (stats.status === 'fulfilled' && stats.value.success) {
        const idToStat = {};
        for (let stat of stats.value.data) {
          idToStat[stat?.id] = stat;
        }
        const newData = players.value.data.map((player) => ({
          ...player,
          stats: idToStat[player?.id],
        }))
        return {
          ...players.value,
          data: newData,
        }
      } else {
        return players.value;
      }
    }
  } catch (error) {
    return {
      data: [],
      success: false,
      error: genericError,
    }
  }
}

export async function getTranscodingStats({
   accessToken,
   params,
 }) {
  const genericError = 'Failed to load transcoding statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/transcoding/stats?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();

    if (json.error === 0 && Array.isArray(json.data)) {
      return {
        data: json.data,
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getTranscodingTotal({
    accessToken,
    params,
  }) {
  const genericError = 'Failed to load transcoding statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/transcoding/total?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();

    if (json.error === 0) {
      return {
        data: json.data,
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getTranscodingTotalCount( {
     accessToken,
     params,
   }) {
  const genericError = 'Failed to load transcoding statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/transcoding/total/count?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();

    if (json.error === 0) {
      return {
        data: json.data,
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getTranscodingTopFormat({
     accessToken,
     params,
   }) {
  const genericError = 'Failed to load transcoding statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/transcoding/top/format?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();

    if (json.error === 0 && Array.isArray(json.data)) {
      json.data.sort((a, b) => b.duration - a.duration);
      return {
        data: json.data,
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getTranscodingTopCodec({
     accessToken,
     params,
   }) {
  const genericError = 'Failed to load transcoding statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/transcoding/top/codec?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0 && Array.isArray(json.data)) {
      json.data.sort((a, b) => b.duration - a.duration);
      return {
        data: json.data,
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getTranscodingTopResolution({
     accessToken,
     params,
   }) {
  const genericError = 'Failed to load transcoding statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/transcoding/top/resolution?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();

    if (json.error === 0 && Array.isArray(json.data)) {
      json.data.sort((a, b) => b.duration - a.duration);
      return {
        data: json.data,
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getTranscodingTopProject({
    accessToken,
    params,
  }) {
  const genericError = 'Failed to load transcoding statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/transcoding/top/project?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0 && Array.isArray(json.data)) {
      return {
        data: json.data,
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function startGeneratingTranscodingReport( {
                                                 accessToken,
                                                 postBody,
                                               }) {
  const genericError = 'Failed to start transcoding generation.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/transcoding/usage-report`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: JSON.stringify(postBody),
    } );

    const json = await response.json();

    if (json.error === 0 && typeof json.token === 'string') {
      return {
        token: json.token,
        success: true,
      };
    }
    return {
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getTranscodingReportStatus( {
                                                          accessToken,
                                                          token,
                                                        }) {
  const genericError = 'Failed to start transcoding generation.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/transcoding/usage-report/${token}/status`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    
    const json = await response.json();
    // console.log('status', json)
    if (json.error === 0) {
      return {
        data: json,
        success: true,
      };
    }
    return {
      success: false,
      error: json.error_description || genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}


export async function getTranscodingStatsByProject({
    accessToken,
    params,
  }) {
  const genericError = 'Failed to load transcoding statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/transcoding/project?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();

    if (json.error === 0 && Array.isArray(json.data)) {
      for (let item of json.data) {
        if (Array.isArray(item.tariffs)) {
          for (let tariff of item.tariffs) {
            tariff.name = tariff.tariff_name;
          }
        }
      }
      return {
        data: json.data,
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getStreamStats({
   accessToken,
   params,
 }) {
  const genericError = 'Failed to load stream statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/stream/stats?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0 && Array.isArray(json.data)) {
      return {
        data: json.data.map((i) => parseNumeric(i, [
          'amount',
          'duration',
          'bandwidth',
          'bandwidth_cost',
          'cdn_amount',
          'requests',
          'requests_cost',
          'tariff_id',
        ])),
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getStreamTotal({
   accessToken,
   params,
 }) {
  const genericError = 'Failed to load total stream statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/stream/total?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0) {
      return {
        data: parseNumeric(json.data, [
          'amount',
          'bandwidth',
          'bandwidth_cost',
          'cdn_amount',
          'duration',
          'requests',
          'requests_cost',
        ]),
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getStreamTotalCount({
    accessToken,
    params,
  }) {
  const genericError = 'Failed to load total stream statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/stream/total/count?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0) {
      return {
        data: json.data,
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getStreamTopResolution({
   accessToken,
   params,
 }) {
  const genericError = 'Failed to load total stream statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/stream/top/resolution?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0) {
      json.data.sort((a, b) => b.duration - a.duration);
      return {
        data: json.data,
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getStreamTopProject({
                                               accessToken,
                                               params,
                                               selectedCategory,
                                             }) {
  const genericError = 'Failed to load total stream statistics.';
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/stream/${ selectedCategory }?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0) {
      return {
        data: json.data,
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}


export async function getStreamTopRegion({
   accessToken,
   params,
 }) {
  const genericError = 'Failed to load total stream statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/stream/top/region?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0) {
      const numericPropertyNames = [
        'amount',
        'bandwidth',
        'bandwidth_cost',
        'cdn_amount',
        'duration',
        'percent',
        'requests',
        'requests_cost',
      ];

      return {
        data: json.data
          .filter((item) => !['all', 'unused'].includes(item.region))
          .map((item) => parseNumeric(item, numericPropertyNames)),
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getStorageStats({
    accessToken,
    params,
  }) {
  const genericError = 'Failed to load storage statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/storage/stats?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0) {
      return {
        data: json.data.map((i) => parseNumeric(i, [
          'amount',
        ])),
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getStorageTotal({
    accessToken,
    params,
  }) {
  const genericError = 'Failed to load total storage statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/storage/total?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    
    if (json.error === 0) {
      const jsonCopy = {...json};
      delete jsonCopy.error;
      delete jsonCopy.detail;
      
      return {
        data: jsonCopy,
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getStorageTopRegion({
    accessToken,
    params,
  }) {
  const genericError = 'Failed to load storage top region statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/storage/top/region?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0) {
      return {
        data: json.data,
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getStorageTopBucket({
    accessToken,
    params,
  }) {
  const genericError = 'Failed to load storage top bucket statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/storage/top/bucket?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0 && Array.isArray(json.data)) {
      return {
        data: json.data.map((i) => parseNumeric(i, [
          'amount',
          'new_storage_gb_month',
          'storage_gb_month',
        ])),
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getStorageByBuckets({
                                            accessToken,
                                            params,
                                          }) {
  const genericError = 'Failed to load storage by bucket statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/storage/buckets?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0) {
      return {
        data: json.data,
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}


export async function getCdnStats({
    accessToken,
    params,
  }) {
  const numericProperties = [
    'amount',
    'bandwidth',
    'requests',
  ];
  const genericError = 'Failed to load CDN statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/cdn/stats?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0) {
      const ls = (Array.isArray(json.data.ls) ? json.data.ls : [])
        .map((i) => parseNumeric(i, numericProperties));
      const ms = (Array.isArray(json.data.ms) ? json.data.ms : [])
        .map((i) => parseNumeric(i, numericProperties));
      return {
        data: {
          ls,
          ms,
        },
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getCdnTotal({
    accessToken,
    params,
  }) {
  const genericError = 'Failed to load total CDN statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/cdn/total?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0) {
      const jsonCopy = {...json};
      delete jsonCopy.error;
      delete jsonCopy.detail;
      jsonCopy.ls = parseNumeric(jsonCopy.ls, ['amount']);
      jsonCopy.ms = parseNumeric(jsonCopy.ms, ['amount']);

      return {
        data: jsonCopy,
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

function parseCdnTopValues(item) {
  const numericPropertyNames = [
    'amount',
    'bandwidth',
    'requests',
  ];
  
  return parseNumeric(item, numericPropertyNames)
}

function parseNumeric(item, properties) {
  const itemCopy = {...item};
  for (const prop of properties) {
    itemCopy[prop] = parseFloat(item[prop]);
  }
  return itemCopy;
}

export async function getCdnTopRegion({
    accessToken,
    params,
  }) {
  const genericError = 'Failed to load top CDN region statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/cdn/top/region?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0) {
      const jsonCopy = {...json};
      delete jsonCopy.error;
      delete jsonCopy.detail;
      jsonCopy.ls = jsonCopy.ls.map(parseCdnTopValues);
      jsonCopy.ms = jsonCopy.ms.map(parseCdnTopValues);

      return {
        data: jsonCopy,
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getCdnUsage({
    accessToken,
    params,
  }) {
  const genericError = 'Failed to load CDN usage statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/cdn/usage?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0) {
      
      return {
        data: json.data,
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getPlayerTotal({
   accessToken,
   params,
 }) {
  const genericError = 'Failed to load player statistics.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/player/total?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0) {
      return {
        data: parseNumeric(json.data, [
          'plays',
          'impressions',
          'minutes_played',
        ]),
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getStatsByPlayers({
   accessToken,
   params,
 }) {
  const genericError = 'Failed to load statistics by player.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/stats/player/list?${params}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0) {
      return {
        data: json.data,
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getBucketList({
  accessToken,
}) {
  const genericError = 'Failed to load list of storage buckets.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/storage/bucket`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0 && Array.isArray(json.data)) {
      return {
        data: json.data.map((item) => ({
          ...item,
          created: utcStringToDate(item.created),
        })),
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getStorageAccessKeyList({
                                      accessToken,
                                    }) {
  const genericError = 'Failed to load list of access keys.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/storage/access-key`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0 && Array.isArray(json.data)) {
      return {
        data: json.data.map((item) => ({
          ...item,
          'create_datetime': utcStringToDate(item['create_datetime']),
        })),
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getBucket({
  accessToken,
  region,
  bucket,
}) {
  const genericError = 'Failed to load bucket data.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/storage/bucket/${ region }/${ bucket }`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();

    if (json.error === 0) {
      return {
        data: {
          ...json.data,
          'creation_date': utcStringToDate(json.data['creation_date']),
        },
        success: true,
      };
    }
    return {
      data: [],
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function createBucket({
    accessToken,
    region,
    name,
  }) {
  const genericError = 'Failed to create bucket.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/storage/bucket`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        region,
        name,
      }),
    } );
    const json = await response.json();
    
    if (json.error === 0) {
      return {
        success: true,
        data: json.data,
      };
    }
    return {
      success: false,
      error: json.detail || genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function deleteBucket({
  accessToken,
  region,
  name,
}) {
  const genericError = 'Failed to delete bucket.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/storage/bucket/${ region }/${ name }`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    
    if (json.error === 0) {
      return {
        success: true,
      };
    }
    if (typeof json.detail === 'string') {
      return {
        success: false,
        error: json.detail,
      };
    }
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function createAccessKey({
    accessToken,
  }) {
  const genericError = 'Failed to create access key.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/storage/access-key`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    } );
    const json = await response.json();
    if (json.error === 0) {
      return {
        success: true,
        data: json.data,
      };
    }
    if (typeof json.detail === 'string') {
      return {
        success: false,
        error: json.detail,
      }
    }
    return {
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function renameAccessKey({
    accessToken,
    name,
    id,
  }) {
  const genericError = 'Failed to rename access key.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/storage/access-key/${ id }`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
      })
    } );
    const json = await response.json();
    if (json.error === 0) {
      return {
        success: true,
      };
    }
    const possibleError = json.detail?.[0]?.error;
    if (Array.isArray(json.detail) && possibleError) {
      return {
        success: false,
        error: possibleError,
      };
    }
    return {
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function regenerateAccessKey({
    accessToken,
    id,
  }) {
  const genericError = 'Failed to regenerate access key.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/storage/access-key/${ id }/regenerate`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    } );
    const json = await response.json();

    if (json.error === 0) {
      return {
        success: true,
        data: json.data,
      };
    }
    if (typeof json.detail === 'string') {
      return {
        success: false,
        error: json.detail,
      }
    }
    return {
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function deleteAccessKey({
    accessToken,
    id,
  }) {
  const genericError = 'Failed to delete access key.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/storage/access-key/${ id }`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();

    if (json.error === 0) {
      return {
        success: true,
      };
    }
    if (typeof json.detail === 'string') {
      return {
        success: false,
        error: json.detail,
      }
    }
    return {
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function getBucketInfo({
  accessToken,
  bucketName,
  region,
}) {
  const genericError = 'Failed to get bucket info.'
  try {
    const response = await fetch( `${protocol}://${endpoint}/${api_version}/storage/bucket/${region}/${bucketName}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    } );
    const json = await response.json();
    if (json.error === 0) {
      return {
        success: true,
        data: json.data,
      }
    }
    return {
      success: false,
      error: genericError,
    };
  } catch (error) {
    return {
      success: false,
      error: genericError,
    };
  }
}

export async function enableCdnService({
                                         accessToken,
                                         region,
                                         bucketName,
                                       }) {
  try {
    const response = await fetch(`${protocol}://${endpoint}/${api_version}/storage/cdn/${region}/${bucketName}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      }
    });
    const json = await response.json();
    console.log(json)
    if (json.status === 'ok') {
      return {
        success: true,
      }
    }
    return {
      success: false,
    }
  } catch (error) {
    return {
      success: false,
    };
  }
}

export async function disableCdnService({
                                          accessToken,
                                          region,
                                          bucketName,
                                        }) {
  try {
    const response = await fetch(`${protocol}://${endpoint}/${api_version}/storage/cdn/${region}/${bucketName}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      }
    });
    const json = await response.json();
    console.log(json)
    if (json.status === 'ok') {
      return {
        success: true,
      }
    }
    return {
      success: false,
    }
  } catch (error) {
    return {
      success: false,
    };
  }
}
