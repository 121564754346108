import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import BriefPanel from "pages/StatisticsPage/components/BriefPanel";
import {Select} from "components/Select";
import TopResolutions from "pages/StatisticsPage/LiveStreamsTab/TopResolutions";
import TopDeliveryRegions from "pages/StatisticsPage/LiveStreamsTab/TopDeliveryRegions";
import style from './style.module.css';
import OptionList from "components/OptionList";
import UsageBy from "pages/StatisticsPage/LiveStreamsTab/UsageBy";
import useSelect from "components/Select/useSelect";
import Controls from "components/Controls";
import VerticalGapsLayout from "components/VerticalGapsLayout";
import Card from "pages/StatisticsPage/components/Card";
import Chart from "pages/StatisticsPage/components/Chart";
import useLineChartDataset from "pages/StatisticsPage/components/Chart/useLineChartDataset";
import {streamingCodeToName, transcodingTypeToColor} from "pages/StatisticsPage/config";
import BriefItem from "pages/StatisticsPage/components/BriefPanel/BriefItem";
import StreamsTooltipContent from "pages/StatisticsPage/LiveStreamsTab/StreamsTooltipContent";
import NoChartData from "pages/StatisticsPage/components/Chart/NoData";

const usageByEnum = {
  minutes: 'minutes',
  bandwidth: 'bandwidth',
};

const usageByOptions = [
  {
    label: 'Minutes',
    value: usageByEnum.minutes,
  },
  {
    label: 'Bandwidth',
    value: usageByEnum.bandwidth,
  }
];

const chartTypeToChartDataProperty = {
  [usageByEnum.minutes]: 'duration',
  [usageByEnum.bandwidth]: 'bandwidth',
};

const chartTypeToQuantityProperty = {
  [usageByEnum.minutes]: 'amount',
  [usageByEnum.bandwidth]: 'bandwidth_cost',
};

const chartTypeToYTitle = {
  [usageByEnum.minutes]: 'Minutes',
  [usageByEnum.bandwidth]: 'GB',
};

function LiveStreamsTab({
  streaming,
  datepicker,
}) {
  const usageBySelect = useSelect(usageByEnum.minutes);
  const chartDataProperty = useMemo(() => chartTypeToChartDataProperty[ usageBySelect.value ], [usageBySelect.value])
  const quantityDataProperty = useMemo(() => chartTypeToQuantityProperty[ usageBySelect.value ], [usageBySelect.value]);
  const yTitle = useMemo(() => chartTypeToYTitle[ usageBySelect.value ], [usageBySelect.value]);
  
  const {
    options,
    setOptions,
    chartData,
    shortlistCheckedIds,
    quantity,
    amountSums,
  } = useLineChartDataset({
    data: streaming.stats.status.data,
    longlistToShortlist: streamingCodeToName,
    chartDataProperty,
    quantityDataProperty,
    longlistProperty: 'tariff_name',
    dates: datepicker.dates,
  });
  
  const chartDataWithFormattedValues = useMemo(() => {
    if (usageBySelect.value === usageByEnum.bandwidth) {
      return chartData.map((item) => {
        const itemCopy = {
          ...item,
        }
        for (let [key, value] of Object.entries(item)) {
          if (typeof value === 'number' && Number.isFinite(value)) {
            itemCopy[key] =value / Math.pow(1024, 3);
          }
        }
        return itemCopy;
      });
    }
    return chartData;
  }, [chartData, usageBySelect]);

  const noDataInSelected = useMemo(() => {
    const sum = shortlistCheckedIds.reduce((acc, selectedId) => acc + (amountSums[selectedId] || 0), 0);
    return typeof sum === 'number' && Number.isFinite(sum) && sum === 0;
  }, [shortlistCheckedIds, amountSums]);

  return (
    <VerticalGapsLayout>
      <Controls
        title="Usage by"
        leftContent={
          <Select
            className={style.usageBySelect}
            options={usageByOptions}
            disabled={streaming.stats.status.loading}
            { ...usageBySelect }
          />
        }
        rightContent={
          <OptionList
            options={options}
            setOptions={setOptions}
            skeleton={streaming.stats.status.loading}
            horizontal={true}
          />
        }
      />
      <Chart
        chartData={chartDataWithFormattedValues}
        loading={streaming.stats.status.loading}
        keyToColor={transcodingTypeToColor}
        dataKeys={shortlistCheckedIds}
        legend={ yTitle }
        quantity={quantity}
        contentComponent={StreamsTooltipContent}
      >
        {
          noDataInSelected ? (
            <NoChartData
              startDate={datepicker.startDate}
              endDate={datepicker.endDate}
            />
          ) : null
        }
      </Chart>
      <BriefPanel>
        <BriefItem
          heading="Streams Created"
          value={streaming.total.status.data?.['streams_created']}
          loading={streaming.total.status.loading}
        />
        <BriefItem
          heading="Total Bandwidth"
          type="byte"
          value={streaming.total.status.data?.bandwidth}
          loading={streaming.total.status.loading}
        />
        <BriefItem
          heading="Total Cost"
          value={streaming.total.status.data?.amount}
          loading={streaming.total.status.loading}
          type="money"
        />
      </BriefPanel>
      <div className={style.topWrapper}>
        <Card title="Top Resolutions">
          <TopResolutions
            stats={streaming.topResolution.status}
          />
        </Card>
        <TopDeliveryRegions
          stats={streaming.topDeliveryRegions.status}
        />
      </div>
      <UsageBy
        byStream={ streaming.topProjectByStream.status }
        byProject={ streaming.topProjectByProject.status }
      />
    </VerticalGapsLayout>
  )
}

LiveStreamsTab.propTypes = {
  datepicker: PropTypes.object.isRequired,
};

export default LiveStreamsTab;