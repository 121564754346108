import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { AuthProvider } from './context/AuthContext';
import { UserProvider } from './context/UserContext';
import { WalletProvider } from './context/WalletContext';
import ProtectedRoute from './components/ProtectedRoute';
import routeConfig from './routeConfig';

import PageTitle from './components/PageTitle';

// Load the Stripe.js script with your publishable key from the environment variable
const stripe_pk = "pk_test_lpviVW1qpQXwmamrGhnwrsJH"
const stripePromise = loadStripe(stripe_pk); // this is public key
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const App = () => {

  return (
    <AuthProvider>
      <UserProvider>
        <WalletProvider>
          <Router>
            <Elements stripe={stripePromise} >
              {/* <Routes>
                {routeConfig.map(({ path, component: Component, pageName, layout: Layout, isProtected, restrictedRoles = [] }) => (
                  <Route key={path} path={path} element={
                    <Layout pageName={pageName}>
                      {isProtected ? <ProtectedRoute element={Component} restrictedRoles={restrictedRoles} /> : <Component />}
                    </Layout>
                  } />
                ))}
              </Routes>         */}

              {/* <Routes>
                {routeConfig.map(({ path, component: Component, pageName, layout: Layout, isProtected, restrictedRoles = [] }) => (
                  <Route key={path} path={path} element={
                    isProtected 
                      ? <ProtectedRoute element={Component} layout={Layout} restrictedRoles={restrictedRoles} pageName={pageName} />
                      : <Layout pageName={pageName}><Component /></Layout>
                  } />
                ))}
              </Routes>                 */}

              <Routes>
                {routeConfig.map(({ path, component: Component, pageName, layout: Layout, isProtected, restrictedRoles = [] }) => (
                  <Route key={path} path={path} element={
                    <>
                      {/* Add the PageTitle component to set the title based on the pageName */}
                      <PageTitle title={pageName} /> 

                      {isProtected 
                        ? <ProtectedRoute element={Component} layout={Layout} restrictedRoles={restrictedRoles} pageName={pageName} />
                        : <Layout pageName={pageName}><Component /></Layout>}
                    </>
                  } />
                ))}
              </Routes>  

            </Elements>        
          </Router>     
        </WalletProvider>   
      </UserProvider>
    </AuthProvider>
  );
};

export default App;


// before Stripe
// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { AuthProvider } from './context/AuthContext';
// import { UserProvider } from './context/UserContext';
// import ProtectedRoute from './components/ProtectedRoute';
// import routeConfig from './routeConfig';

// const App = () => {
//   return (
//     <AuthProvider>
//       <UserProvider>
//         <Router>
//           <Routes>
//             {routeConfig.map(({ path, component: Component, pageName, layout: Layout, isProtected }) => (
//               <Route key={path} path={path} element={
//                 <Layout pageName={pageName}>
//                   {isProtected ? <ProtectedRoute element={Component} /> : <Component />}
//                 </Layout>
//               } />
//             ))}
//           </Routes>        
//         </Router>        
//       </UserProvider>
//     </AuthProvider>
//   );
// };

// export default App;





// pass pageName parameter to layout
// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// import { AuthProvider } from './context/AuthContext';

// import Layout from './components/Layout'; // Import Layout here

// import HomePage from './pages/HomePage';
// import DashboardPage from './pages/DashboardPage';
// // import LoginPage from './pages/LoginPage';
// // import SignupPage from './pages/SignupPage';
// import TemplatePage from './pages/TemplatePage';

// import { LoginPage, SignupPage, CompleteSignupPage, SetNewPasswordPage } from './pages/AuthPages';

// import ProtectedRoute from './components/ProtectedRoute';

// const App = () => {
//   return (
//     <AuthProvider>
//       <Router>
//         <Routes>
//           <Route path="/" element={<Layout pageName="Home"><HomePage /></Layout>} />
//           <Route path="/template" element={<Layout pageName="Template"><TemplatePage /></Layout>} />
//           <Route path="/dashboard" element={<Layout pageName="Dashboard"><ProtectedRoute element={DashboardPage} /></Layout>} />
//           <Route path="/login" element={<Layout pageName="Login"><LoginPage /></Layout>} />
//           <Route path="/signup" element={<Layout pageName="Signup"><SignupPage /></Layout>} />
//           <Route path="/signup/:token" element={<Layout pageName="Complete Signup"><CompleteSignupPage /></Layout>} />
//           <Route path="/password-set" element={<Layout pageName="Set New Password"><SetNewPasswordPage /></Layout>} />
//         </Routes>          
//       </Router>      
//     </AuthProvider>
//   );
// };

// export default App;




// original routing
// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// import { AuthProvider } from './context/AuthContext';

// import Layout from './components/Layout'; // Import Layout here

// import HomePage from './pages/HomePage';
// import DashboardPage from './pages/DashboardPage';
// // import LoginPage from './pages/LoginPage';
// // import SignupPage from './pages/SignupPage';
// import TemplatePage from './pages/TemplatePage';

// import { LoginPage, SignupPage, CompleteSignupPage, SetNewPasswordPage } from './pages/AuthPages';

// import ProtectedRoute from './components/ProtectedRoute';

// const App = () => {
//   return (
//     <AuthProvider>
//       <Router>
//         <Layout>
//           <Routes>
//             <Route path="/" element={<HomePage />} />
//             <Route path="/template" element={<TemplatePage />} />
//             {/* <Route path="/template" element={<ProtectedRoute element={TemplatePage} />} /> */}
//             <Route path="/dashboard" element={<ProtectedRoute element={DashboardPage} />} />
//             <Route path="/login" element={<LoginPage />} />
//             <Route path="/signup" element={<SignupPage />} />
//             <Route path="/signup/:token" element={<CompleteSignupPage />} />
//             <Route path="/password-set" element={<SetNewPasswordPage />} />
//           </Routes>          
//         </Layout>
//       </Router>     
//     </AuthProvider>
//   );
// };

// export default App;
