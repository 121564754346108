import PropTypes from "prop-types";
import React from "react";


export function Rename({ color, label }) {
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      aria-label={label}
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path d="M7.5 3.17212H10.5C11.8807 3.17212 13 4.29141 13 5.67212V8.67212C13 10.0528 11.8807 11.1721 10.5 11.1721H7.5M5 11.1721H3.5C2.11929 11.1721 1 10.0528 1 8.67212V5.67212C1 4.29141 2.11929 3.17212 3.5 3.17212H5"
            stroke="currentColor"
            strokeWidth="1.2"
            strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7017 4.47302H8.4888C8.21266 4.47302 7.9888 4.69688 7.9888 4.97302V9.39657C7.9888 9.67272 8.21266 9.89657 8.4888 9.89657H10.7017C11.2539 9.89657 11.7017 9.44886 11.7017 8.89657V5.47302C11.7017 4.92074 11.2539 4.47302 10.7017 4.47302Z"
        fill="currentColor"
      />
      <path
        d="M5 1V13"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
      />
    </svg>
  );
}

Rename.propTypes = {
  color: PropTypes.string,
};

Rename.defaultProps = {
  color: 'currentColor',
};
