
export default function getErrorMessage(json) {
    if (typeof json.error === 'string') {
        return json.error;
    }
    if (typeof json.detail === 'string') {
        return json.detail;
    }
    return json?.detail?.[0]?.error || 'An error happened';
}

