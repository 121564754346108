import PropTypes from "prop-types";
import React from "react";

export const Storage = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M4.25586 5.21176C4.61755 3.76497 5.9175 2.75 7.40882 2.75H12.5912C14.0825 2.75 15.3824 3.76497 15.7441 5.21176L17.0677 10.5058C17.1888 10.9902 17.25 11.4877 17.25 11.987V14.5714C17.25 16.0508 16.0508 17.25 14.5714 17.25H5.42857C3.94924 17.25 2.75 16.0508 2.75 14.5714V11.987C2.75 11.4877 2.81124 10.9902 2.93234 10.5058L4.25586 5.21176ZM7.40882 1.25C5.2292 1.25 3.32928 2.73341 2.80064 4.84796L1.47713 10.142C1.32628 10.7454 1.25 11.365 1.25 11.987V14.5714C1.25 16.8792 3.12081 18.75 5.42857 18.75H14.5714C16.8792 18.75 18.75 16.8792 18.75 14.5714V11.987C18.75 11.365 18.6737 10.7454 18.5229 10.142L17.1994 4.84796C16.6707 2.73341 14.7708 1.25 12.5912 1.25H7.40882ZM16.1715 12.8571C16.1715 11.9104 15.404 11.1429 14.4572 11.1429H5.5429C4.59613 11.1429 3.82861 11.9104 3.82861 12.8571V14.4571C3.82861 15.4039 4.59613 16.1714 5.5429 16.1714H14.4572C15.404 16.1714 16.1715 15.4039 16.1715 14.4571V12.8571ZM12.8572 13.7143C12.8572 13.2409 13.2409 12.8571 13.7143 12.8571C14.1877 12.8571 14.5715 13.2409 14.5715 13.7143C14.5715 14.1877 14.1877 14.5714 13.7143 14.5714C13.2409 14.5714 12.8572 14.1877 12.8572 13.7143ZM6.57147 13.2H10C10.3156 13.2 10.5715 13.4558 10.5715 13.7714C10.5715 14.087 10.3156 14.3429 10 14.3429H6.57147C6.25588 14.3429 6.00004 14.087 6.00004 13.7714C6.00004 13.4558 6.25588 13.2 6.57147 13.2Z" fill="currentColor"/>
    </svg>    
  );
};

Storage.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Storage.defaultProps = {
  color: 'currentColor', // Or any default color
};