import PropTypes from "prop-types";
import React from "react";

export const Status = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path d="M8.94235 1.94231C8.94235 1.42189 8.52047 1 8.00005 1C7.47962 1 7.05774 1.42189 7.05774 1.94231V3.55769C7.05774 4.07811 7.47962 4.5 8.00005 4.5C8.52047 4.5 8.94235 4.07811 8.94235 3.55769V1.94231Z" fill="currentColor"/>
      <path d="M8.94231 12.4423C8.94231 11.9219 8.52042 11.5 8 11.5C7.47958 11.5 7.05769 11.9219 7.05769 12.4423V14.0577C7.05769 14.5781 7.47958 15 8 15C8.52042 15 8.94231 14.5781 8.94231 14.0577V12.4423Z" fill="currentColor"/>
      <path d="M1 8.00007C1 7.47965 1.42189 7.05777 1.94231 7.05777H3.55769C4.07811 7.05777 4.5 7.47965 4.5 8.00007C4.5 8.5205 4.07811 8.94238 3.55769 8.94238H1.94231C1.42189 8.94238 1 8.5205 1 8.00007Z" fill="currentColor"/>
      <path d="M12.4423 7.05777C11.9219 7.05777 11.5 7.47965 11.5 8.00008C11.5 8.5205 11.9219 8.94238 12.4423 8.94238H14.0577C14.5781 8.94238 15 8.5205 15 8.00008C15 7.47965 14.5781 7.05777 14.0577 7.05777H12.4423Z" fill="currentColor"/>
      <path d="M3.05022 3.05024C3.41822 2.68225 4.01485 2.68225 4.38285 3.05024L5.5251 4.19249C5.89309 4.56048 5.89309 5.15712 5.5251 5.52511C5.1571 5.89311 4.56047 5.89311 4.19247 5.52511L3.05022 4.38286C2.68223 4.01487 2.68223 3.41823 3.05022 3.05024Z" fill="currentColor"/>
      <path d="M11.8074 10.4749C11.4394 10.1069 10.8428 10.1069 10.4748 10.4749C10.1068 10.8428 10.1068 11.4395 10.4748 11.8075L11.6171 12.9497C11.9851 13.3177 12.5817 13.3177 12.9497 12.9497C13.3177 12.5817 13.3177 11.9851 12.9497 11.6171L11.8074 10.4749Z" fill="currentColor"/>
      <path d="M12.9496 3.05035C13.3176 3.41834 13.3176 4.01498 12.9496 4.38297L11.8074 5.52522C11.4394 5.89321 10.8428 5.89321 10.4748 5.52522C10.1068 5.15723 10.1068 4.56059 10.4748 4.19259L11.617 3.05035C11.985 2.68235 12.5816 2.68235 12.9496 3.05035Z" fill="currentColor"/>
      <path d="M5.52507 11.8076C5.89306 11.4396 5.89306 10.843 5.52507 10.475C5.15707 10.107 4.56044 10.107 4.19244 10.475L3.05019 11.6172C2.6822 11.9852 2.6822 12.5818 3.05019 12.9498C3.41819 13.3178 4.01482 13.3178 4.38282 12.9498L5.52507 11.8076Z" fill="currentColor"/>
    </svg>    
  );
};

Status.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Status.defaultProps = {
  color: 'currentColor', // Or any default color
};