import PropTypes from "prop-types";
import React from "react";

export const Collapse = ({ className, color, label }) => {

  const style = color !== 'currentColor' ? { color } : undefined;

  return (  
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}       
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M3.25 3.5C3.25 3.08579 2.91421 2.75 2.5 2.75C2.08579 2.75 1.75 3.08579 1.75 3.5V13.5C1.75 13.9142 2.08579 14.25 2.5 14.25C2.91421 14.25 3.25 13.9142 3.25 13.5V3.5ZM9.57983 11.5206V9.00006H14.4999C14.9141 9.00006 15.2499 8.66427 15.2499 8.25006C15.2499 7.83585 14.9141 7.50006 14.4999 7.50006H9.57983V4.97937C9.57983 4.21973 8.62278 3.8393 8.06304 4.37645L4.65488 7.64708C4.30789 7.98006 4.30789 8.51994 4.65488 8.85292L8.06304 12.1236C8.62277 12.6607 9.57983 12.2803 9.57983 11.5206Z" fill="currentColor"/>
    </svg>
  );
};

Collapse.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Collapse.defaultProps = {
  color: 'currentColor', // Or any default color
};