export const formatsMap = [
    { value: 'mp4', label: 'MP4' },
    { value: 'webm', label: 'WebM' },
    { value: 'advanced_hls', label: 'HLS' },
    { value: 'advanced_dash', label: 'MPEG-DASH' },
    { value: 'mp3', label: 'MP3' },
    { value: 'thumbnail', label: 'Thumbnail' },
    { value: 'thumbnails', label: 'Thumbnails/VTT' },
    { value: 'repack', label: 'Repack' },
    { value: 'gif', label: 'GIF' },
    { value: 'hls_audio', label: 'HLS AUDIO' },
    { value: 'speech_to_text', label: 'Speech-to-Text' }
];

export const getFormatLabel = (value) => {
    const format = formatsMap.find(format => format.value === value);
    return format ? format.label : null;
};