import React from 'react';
import PropTypes from 'prop-types';

export function AccountShield({ className, color, label }) {
    // Apply inline style only if color prop is not currentColor
    const style = color !== 'currentColor' ? { color } : undefined;

    return (
        <svg
            className={className}
            role="img"
            aria-label={label}
            style={style}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
        >
          <path d="M1.5 2.86v4.48C1.5 9.91 4.02 12.25 7 13c3.09-.71 5.5-3.09 5.5-5.66V2.87c0-.21-.18-.38-.4-.39h-.05c-.78.06-1.54.04-2.29-.07a4.74 4.74 0 0 1-2.45-1.3.44.44 0 0 0-.57 0A4.83 4.83 0 0 1 4.3 2.4c-.74.11-1.52.13-2.34.07-.23-.02-.43.14-.45.35v.03Z" stroke="currentColor"/>
          <path d="M3.59 9.4a5.28 5.28 0 0 1 6.82 0A5.62 5.62 0 0 1 7 11.69 6.33 6.33 0 0 1 3.59 9.4Zm3.42-5.32a1.8 1.8 0 1 0 0 3.6 1.8 1.8 0 0 0 0-3.6Z" fill="currentColor"/>
        </svg>
    );
}

AccountShield.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.string,
};

AccountShield.defaultProps = {
    color: 'currentColor', // Or any default color
};
