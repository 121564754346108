import React, { useState } from 'react';

import { addPaypal } from '../../../api/qencode-account-api'

import { Button } from '../../../components/Button';
import { InputField } from '../../../components/InputField';

import { validateFields } from '../../../helpers/validation';

import { useAuth } from '../../../context/AuthContext'

import styles from '../index.module.css';

const AddPaypalForm = ({ onCloseModal, onAddPaypal }) => {

  const { 
    //getAccessToken, 
    getToken // use this method in case access token expired and need to refresh it
  } = useAuth();

  const [isSubmittingModal, setIsSubmittingModal] = useState(false);

  const [credentials, setCredentials] = useState({
    email: '',
  });

  const [validationErrors, setValidationErrors] = useState({});

  const [paymentMethodError, setPaymentMethodError] = useState(null);

  const handleInputChange = (e) => {
      if (paymentMethodError) {
        setPaymentMethodError(null);
      }

    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });

    setValidationErrors(prevErrors => ({
        ...prevErrors,
        [name]: null
    }));
  };

  const handleAddPaymentMethod = async (e) => {
    e.preventDefault();

    // do input validation on the client side 1st
    const fieldsToValidate = ['email']; 
    const { isValid, errors } = validateFields(credentials, fieldsToValidate);
    setValidationErrors(errors); // Update the validation errors state
  
    if (!isValid) {
      console.log("Validation errors", errors);
      return; // Stop the submission if validation fails
    }      

    setIsSubmittingModal(true);

    //const token = getAccessToken();
    const token = await getToken()
    //console.log("token:" , token)

    const response = await addPaypal(token, {
        email: credentials.email
    });

    setIsSubmittingModal(false);

    const {success, data, detail} = response

    if (success && data) {
        console.log("Paypal payment method data: ", data)

        // will call function to add payment method to the top of the list
        onAddPaypal(data)

        // Сlose the modal:
        onCloseModal();
    } else {
        setPaymentMethodError(detail || "Failed to add Paypal");
        console.log(detail || "Failed to add Paypal");
    }      

  };    

  const handleCancel = () => {
    // Optionally reset state or perform other actions on cancel
    onCloseModal(); // Simply close the modal
  };

  return (
    <div>
      <div className="form">
          <div className="row">             
              <InputField
                  inputText={credentials.email}
                  onChange={handleInputChange}
                  name="email"
                  placeholder="Email address"
                  label="Email address"
                  state={paymentMethodError || validationErrors.email ? 'error' : 'default'}
                  hint={paymentMethodError ? paymentMethodError : validationErrors.email ? validationErrors.email : undefined}
              />
          </div>                           
      </div>  

      <div className={styles.addPaymentModalFooter}>
        <Button variant="modalFooter" onClick={handleCancel} type="secondary">Cancel</Button>
        <Button 
          variant="modalFooter" 
          onClick={handleAddPaymentMethod} 
          state={isSubmittingModal ? "disabled" : undefined}
        >
          Save
        </Button>        
      </div>
    </div>
  );
};

export default AddPaypalForm;
