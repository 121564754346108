import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.css';
import CreateKeyButton from "pages/MediaStorage/AccessKeysPage/CreateKeyButton";

function KeyGenerator({ onSuccess }) {
  return (
    <aside className={style.generateKey}>
      <div className={style.section}>
        <h3 className={style.heading}>
          Generate New Access Key
        </h3>
        <p className={style.explanation}>
          Generate and manage access key pairs used to connect with third party applications or Media Storage API.
        </p>
      </div>
      <div className={style.section}>
        <CreateKeyButton
          onSuccess={onSuccess}
        />
      </div>
    </aside>
  )
}

KeyGenerator.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default KeyGenerator;