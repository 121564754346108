import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import BriefPanel from "pages/StatisticsPage/components/BriefPanel";
import PlayersTable from "pages/StatisticsPage/PlayerTab/PlayersTable";
import Controls from 'components/Controls';
import {Select} from "components/Select";
import VerticalGapsLayout from "components/VerticalGapsLayout";
import Chart from "pages/StatisticsPage/components/Chart";
import style from './style.module.css';
import BriefItem from "pages/StatisticsPage/components/BriefPanel/BriefItem";
import PlayerTooltipContent from "pages/StatisticsPage/PlayerTab/PlayerTooltipContent";
import {
  template,
  usageByTypeIds,
  options,
  typeToColor,
  playerUsageByIdToName
} from "pages/StatisticsPage/PlayerTab/config";
import NoChartData from "pages/StatisticsPage/components/Chart/NoData";


function PlayerTab({
  datepicker,
  player,
}) {
  const [usageBy, setUsageBy] = useState(usageByTypeIds.impressions);
  const dataKeys = useMemo(() => [usageBy], [usageBy]);

  const {
    idToLineData,
    statsByCategory,
  } = useMemo(() => {
    const statsByCategory = {
      [usageByTypeIds.impressions]: 0,
      [usageByTypeIds.plays]: 0,
      [usageByTypeIds.minutesPlayed]: 0
    };
    const datesEntries = datepicker.dates.map((date) => [date, {
      date,
      ...template,
    } ]);
    const stats = {
      [usageByTypeIds.impressions]: Object.fromEntries(datesEntries),
      [usageByTypeIds.plays]: Object.fromEntries(datesEntries),
      [usageByTypeIds.minutesPlayed]: Object.fromEntries(datesEntries),
    };

    for (let obj of player.stats.status.data) {
      stats[usageByTypeIds.impressions][obj.date] = stats[usageByTypeIds.impressions][obj.date] || {
        date: obj.date,
        ...template,
      };
      const impressions = obj.impressions || 0;
      stats[usageByTypeIds.impressions][obj.date][usageByTypeIds.impressions] = impressions;
      statsByCategory[usageByTypeIds.impressions] += impressions;

      stats[usageByTypeIds.plays][obj.date] = stats[usageByTypeIds.plays][obj.date] || {
        date: obj.date,
        ...template,
      };
      const plays = obj.plays || 0;
      stats[usageByTypeIds.plays][obj.date][usageByTypeIds.plays] = plays;
      statsByCategory[usageByTypeIds.plays] += plays;

      stats[usageByTypeIds.minutesPlayed][obj.date] = stats[usageByTypeIds.minutesPlayed][obj.date] || {
        date: obj.date,
        ...template,
      };
      const minutesPlayed = obj['minutes_played'] || 0;
      stats[usageByTypeIds.minutesPlayed][obj.date][usageByTypeIds.minutesPlayed] = minutesPlayed;
      statsByCategory[usageByTypeIds.minutesPlayed] += minutesPlayed;
    }

    return {
      idToLineData: stats,
      statsByCategory,
    };
  }, [datepicker.dates, player.stats.status.data]);

  const chartData = useMemo(() => {
    return Object.values(idToLineData[usageBy]);
  }, [usageBy, idToLineData]);
  
  const noDataInSelected = useMemo(() => {
    const sum = statsByCategory[usageBy];
    return typeof sum === 'number' && Number.isFinite(sum) && sum === 0;
  }, [usageBy, statsByCategory]);

  return (
    <VerticalGapsLayout>
      <Controls
        title="Usage by"
        leftContent={
          <Select
            options={options}
            value={ usageBy }
            className={style.select}
            onChange={(e) => setUsageBy(e?.target?.value)}
            disabled={player.stats.status.loading}
          />
        }
      />
      <Chart
        chartData={chartData}
        loading={player.stats.status.loading}
        typeToColor={typeToColor}
        dataKeys={dataKeys}
        color={typeToColor[usageBy]}
        contentComponent={PlayerTooltipContent}
        singleRow={true}
        legend={playerUsageByIdToName[usageBy]}
      >
        {
          noDataInSelected ? (
            <NoChartData
              startDate={datepicker.startDate}
              endDate={datepicker.endDate}
            />
          ) : null
        }
      </Chart>
      <BriefPanel>
        <BriefItem
          heading="Impressions"
          value={player.total.status?.data?.impressions}
          loading={player.total.status.loading}
          precision={0}
        />
        <BriefItem
          heading="Plays"
          value={player.total.status?.data?.plays}
          loading={player.total.status.loading}
          precision={0}
        />
        <BriefItem
          heading="Minutes Played"
          value={player.total.status?.data?.minutes_played}
          loading={player.total.status.loading}
        />
      </BriefPanel>
      <PlayersTable loadingStatus={ player.totalByPlayers.status }/>
    </VerticalGapsLayout>
  )
}

PlayerTab.propTypes = {
  datepicker: PropTypes.object.isRequired,
};

export default PlayerTab;