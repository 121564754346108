import PropTypes from "prop-types";
import React from "react";

export const APIReference = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M2.75 6C2.75 4.20507 4.20507 2.75 6 2.75H14C15.7949 2.75 17.25 4.20507 17.25 6V14C17.25 15.7949 15.7949 17.25 14 17.25H6C4.20507 17.25 2.75 15.7949 2.75 14V6ZM6 1.25C3.37665 1.25 1.25 3.37665 1.25 6V14C1.25 16.6234 3.37665 18.75 6 18.75H14C16.6234 18.75 18.75 16.6234 18.75 14V6C18.75 3.37665 16.6234 1.25 14 1.25H6ZM5.33325 6C5.88554 6 6.33325 5.55228 6.33325 5C6.33325 4.44772 5.88554 4 5.33325 4C4.78097 4 4.33325 4.44772 4.33325 5C4.33325 5.55228 4.78097 6 5.33325 6ZM8.03003 6C8.58231 6 9.03003 5.55228 9.03003 5C9.03003 4.44772 8.58231 4 8.03003 4C7.47774 4 7.03003 4.44772 7.03003 5C7.03003 5.55228 7.47774 6 8.03003 6ZM9.89999 11.525C9.89888 11.7921 9.77917 12.0449 9.57326 12.215L6.57326 14.6938C6.19008 15.0104 5.6228 14.9564 5.30619 14.5733C4.98959 14.1901 5.04356 13.6228 5.42674 13.3062L7.59414 11.5154L5.42096 9.68899C5.04044 9.3692 4.99121 8.80148 5.31101 8.42096C5.6308 8.04044 6.19852 7.99121 6.57904 8.31101L9.57904 10.8322C9.78352 11.0041 9.90111 11.2579 9.89999 11.525ZM14 14.9C14.4971 14.9 14.9 14.4971 14.9 14C14.9 13.5029 14.4971 13.1 14 13.1H11C10.5029 13.1 10.1 13.5029 10.1 14C10.1 14.4971 10.5029 14.9 11 14.9H14Z" fill="currentColor"/>
    </svg>    
  );
};

APIReference.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

APIReference.defaultProps = {
  color: 'currentColor', // Or any default color
};