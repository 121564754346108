import React from 'react';
import {Button} from "components/Button";
import {Close, Filter} from "icons";
import style from './style.module.css';
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import {FilterType} from "components/FilterSidebar/useFilter";


function FilterSidebar({
  children,
  filter: {
    visible,
    setVisible,
    columnFilters,
    clearAllFilters,
    applyDraftColumnFilters,
  }
}) {

    function close() {
      setVisible(false);
    }
    
    function handleClear(e) {
      e.preventDefault();
      e.stopPropagation();
      clearAllFilters();
    }

    return (
        <>
            <div className={style.filterButtonWrapper}>
              <Button
                type="secondary"
                className={style.filters}
                onClick={() => setVisible(!visible)}
              >
                <Filter className={ style.filterIcon }/>
                Filter
                { columnFilters?.length > 0 && (
                  <>
                  <span className={style.activeFilterCount}>
                    <span>{columnFilters.length}</span>
                  </span>
                  </>
                )}
              </Button>
              {
                columnFilters?.length > 0 && (
                  <button
                    className={style.activeFilterClose}
                    onClick={handleClear}
                  >
                    <Close className={style.close}/>
                  </button>
                )
              }
            </div>
          {
            ReactDOM.createPortal(
              <aside className={`${style.filter} ${visible ? style.visible : ''}`}>
                <div className={style.content}>
                  <div className={style.header}>
                    <h3 className={style.title}>Filter</h3>
                    <button
                      className={style.closeButton}
                      onClick={close}
                    >
                      <Close className={style.closeButtonIcon}/>
                    </button>
                  </div>
                  <div className={style.main}>
                    {children}
                  </div>
                  <div className={style.footer}>
                    <Button
                      type="secondary"
                      onClick={clearAllFilters}
                    >
                      Clear All
                    </Button>
                    <Button onClick={applyDraftColumnFilters}>
                      Apply
                    </Button>
                  </div>
                </div>
              </aside>,
            document.getElementById("filter-sidebar")
            )
          }
        </>
    );
}

FilterSidebar.propTypes = {
  filter: PropTypes.shape( FilterType ),
};

export default FilterSidebar;