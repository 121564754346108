import React from 'react';

import { useWallet } from '../../context/WalletContext';

//import styles from './index.module.css';

const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(value);
}

const AddFunds = () => {

    const { walletData, walletError, walletLoading } = useWallet();
  
    // after funds added need to call fetchWalletData to update wallet balance everywhere

    if (walletLoading) {
        return <div>Loading...</div>; // Show a loading state
    }

    if (walletError){
        return <div>Error: {walletError}</div>;
    }

    return (
        <div>
            {
                walletData?.wallet && <div>{formatCurrency(walletData?.wallet)}</div>
            }

            <div>Current Wallet Amount</div>


        </div>
    );
};

export default AddFunds;