import {typeIds} from "pages/StatisticsPage/CdnTab/config";
import React from "react";

export default function NameHeaderCell({ selectedType }) {
  if ([
    typeIds.regions,
    typeIds.storage,
    typeIds.liveStream,
  ].includes(selectedType)) {
    return <span>Region name</span>
  } else if (selectedType === typeIds.buckets) {
    return <span>Bucket name</span>
  } else if (selectedType === typeIds.liveStreamProject) {
    return <span>Project name</span>
  }
}

