import PropTypes from "prop-types";
import React from "react";

export const Nitofications = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path d="M4.13037 6.18204C4.41535 4.54263 5.82245 3.05179 7.66352 2.90287C8.17757 2.86128 8.63545 2.89574 9.03715 3.00624C9.10914 3.02683 9.17423 3.06711 9.22493 3.12295C9.3747 3.28789 9.36445 3.5449 9.20049 3.69528L4.18591 8.40599C4.13212 8.45863 4.05697 8.48186 3.98327 8.46954C3.85248 8.44767 3.76391 8.32226 3.78571 8.18947C3.8832 7.61219 3.99808 6.94304 4.13037 6.18204Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2.11443 5.92395C2.60994 3.02708 5.08903 0.900398 8.00161 0.900398C10.914 0.900398 13.3926 3.02762 13.8869 5.92451C14.1219 7.30189 14.2987 8.38328 14.4169 9.16694C14.5314 9.9256 14.5997 10.4577 14.5997 10.6936C14.5997 11.7582 13.8016 12.719 12.7017 12.719H10.5856C10.4504 14.044 9.35829 15.1004 7.99972 15.1004C6.64112 15.1004 5.54904 14.044 5.41382 12.719H3.29771C2.19785 12.719 1.39972 11.7582 1.39972 10.6936C1.39972 10.4575 1.46825 9.92529 1.583 9.16663C1.70154 8.38291 1.87882 7.30142 2.11443 5.92395ZM8.00161 2.1004C5.68694 2.1004 3.69643 3.79256 3.29725 6.12627C3.06211 7.50097 2.88633 8.57366 2.7695 9.3461C2.64888 10.1436 2.59972 10.5695 2.59972 10.6936C2.59972 11.2036 2.96385 11.519 3.29771 11.519H12.7017C13.0356 11.519 13.3997 11.2036 13.3997 10.6936C13.3997 10.5694 13.3507 10.1434 13.2304 9.34596C13.1138 8.57357 12.9385 7.50094 12.704 6.12633C12.3059 3.79281 10.3159 2.1004 8.00161 2.1004ZM6.62479 12.719H9.37465C9.24917 13.4027 8.66889 13.9004 7.99972 13.9004C7.33054 13.9004 6.75026 13.4027 6.62479 12.719Z" fill="currentColor"/>
    </svg>    
  );
};

Nitofications.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Nitofications.defaultProps = {
  color: 'currentColor', // Or any default color
};