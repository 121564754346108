import React from 'react';
import PropTypes from 'prop-types';
import { InputField } from 'components/InputField';
import { Select } from 'components/Select';
import { FormToggle } from 'components/FormToggle';
import { Label } from 'components/Label';
import styles from '../index.module.css'

import { tooltipData } from 'helpers/docsTooltipData'

export const AdditionalSettings = ({ callbackUrl, encoderVersion, useSubtaskCallback, upscale, retryOnError, onUpdate }) => {

    // to deal with all input and select fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // true is 1 and false is 0 - used for toggles
        const fieldsToTransform = [
            'use_subtask_callback', 'upscale', 'retry_on_error'      
        ]; // Add other fields here as needed

        let transformedValue = value;
        if (fieldsToTransform.includes(name)) {
            transformedValue = value === 'true' || value === true ? 1 : 0;
        }     

        onUpdate(name, transformedValue)
    };   

    return (
        <div className={styles.formBody}>
            <div className={styles.formRow}>
                <InputField
                    label='Callback URL'
                    help={tooltipData({api: 'transcoding', name: 'callback_url'})}
                    placeholder='http://your-server.com/task_callback.php'
                    name='callback_url'
                    inputText={callbackUrl}
                    onChange={handleInputChange}
                />
                <Select
                    className={styles.encodeVersion}
                    label='Encoder Version'
                    help={tooltipData({api: 'transcoding', name: 'encoder_version'})}
                    name='encoder_version'
                    options={[
                        { value: '1', label: 'Version 1' },
                        { value: '2', label: 'Version 2' },
                    ]}
                    value={encoderVersion || '1'}
                    onChange={handleInputChange}
                />
            </div>

            {
                callbackUrl && 
                <div className={styles.formRow}>
                    <div className={styles.formToggleContainer}>
                        <FormToggle 
                            name='use_subtask_callback'
                            checked={useSubtaskCallback === 1}
                            onChange={handleInputChange} 
                        />                                         
                        <div className={styles.formToggleLabel}>
                            <Label 
                                text="Use Subtask Callback" 
                                help={tooltipData({api: 'transcoding', name: 'use_subtask_callback'})}
                            />
                        </div>                                 
                    </div>
                </div>                 
            }


            <div className={styles.formRow}>
                <div className={styles.formToggleContainer}>
                    <FormToggle 
                        name='upscale'
                        checked={upscale === 1}
                        onChange={handleInputChange} 
                    />                                         
                    <div className={styles.formToggleLabel}>
                        <Label 
                            text="Upscale" 
                            help={tooltipData({api: 'transcoding', name: 'upscale'})}
                        />
                    </div>                                 
                </div>
            </div>          

            <div className={styles.formRow}>
                <div className={styles.formToggleContainer}>
                    <FormToggle 
                        name='retry_on_error'
                        checked={retryOnError === 1}
                        onChange={handleInputChange} 
                    />                                         
                    <div className={styles.formToggleLabel}>
                        <Label 
                            text="Retry on Error" 
                            help={tooltipData({api: 'transcoding', name: 'retry_on_error'})}
                        />
                    </div>                                 
                </div>
            </div>              

        </div>
    );
};

AdditionalSettings.propTypes = {
    callbackUrl: PropTypes.string,
    encoderVersion: PropTypes.string,
    useSubtaskCallback: PropTypes.number,
    upscale: PropTypes.number,
    onUpdate: PropTypes.func.isRequired
};

