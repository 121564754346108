import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import Card from 'pages/StatisticsPage/components/Card';
import Heading from "pages/StatisticsPage/components/Heading";
import {getCoreRowModel, getPaginationRowModel, useReactTable} from "@tanstack/react-table";
import Table from "components/Table";
import Region from "components/Region";
import bytesToReadable from "helpers/bytesToReadable";
import UnifiedTwoCells from "components/Table/Cells/UnifiedTwoCells";
import TopTotal from "pages/StatisticsPage/components/TopTotal";
import {ReactComponent as CdnIcon} from "assets/images/Cdn.svg";

const columnIds = {
  resolutions: 'resolutions',
  minutes: 'minutes',
};

function TopCdn({
  stats,
  totalValue,
  loading,
}) {
  const {regionCodeToStatistics} = useMemo(() => {
    const regionToBandwidth = {};
    if (stats.loadedSuccessfully) {
      const all = [
        ...(stats.data?.ls || []),
        ...(stats.data?.ms || []),
      ];
      for (const {region, bandwidth} of all) {
        regionToBandwidth[region] = (regionToBandwidth[region] || 0) + ( bandwidth || 0 );
      }
    }
    const values = Object.entries(regionToBandwidth);
    values.sort((a, b) => b[1] - a[1]);
    
    return {
      regionCodeToStatistics: values.map(( [ region, bandwidth ] ) => ({
        regionCode: region,
        bandwidth,
      })),
    };
  }, [stats.data?.ls, stats.data?.ms, stats.loadedSuccessfully]);

  const columns = useMemo(() => [
    {
      id: columnIds.resolutions,
      header: () => <span>Top Regions</span>,
      accessorKey: 'regionCode',
      meta: {
        colspan: 2,
      },
      cell: (info) => <UnifiedTwoCells>
        <Region
          regionCode={info.getValue()}
        />
        <span>{bytesToReadable( info.row.original.bandwidth )}</span>
      </UnifiedTwoCells>,
      enableSorting: false,
    },
    {
      id: columnIds.minutes,
      header: () => <span>Bandwidth</span>,
      accessorKey: 'bandwidth',
      enableSorting: false,
      meta: {
        align: 'right',
        colspan: 0,
      },
    },
  ], []);
  
  const table = useReactTable({
    data: regionCodeToStatistics,
    columns,
    getCoreRowModel: getCoreRowModel(),
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      pagination: {
        pageIndex: 0,
        pageSize: 5,
      },
    },
  });

  return (
    <Card>
      <Heading
        title="CDN"
        href={`?tab=cdn`}
      />
      <TopTotal
        title="Global Delivery"
        total={ bytesToReadable(totalValue) }
        loading={loading}
      />
      <Table
        {...stats}
        table={table}
        lastLineInvisible
        noDataImage={<CdnIcon/>}
        noDataDescription="No data found"
        skeletonRowCount={5}
      />
    </Card>
  )
}

TopCdn.propTypes = {
  stats: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  totalValue: PropTypes.number.isRequired,
};

export default TopCdn;