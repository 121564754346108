import {useCallback, useMemo, useRef, useState} from 'react';
import {addDays, format, isAfter, isDate, isValid, subDays} from "date-fns";
import useValueClearing from "components/FilterSidebar/useValueClearing";

const dateTextFormat = 'MM/dd/yyyy';

function useDay(key) {
  const [date, setDate] = useState();
  const [invalid, setInvalid] = useState(false);
  const [dateKey, setDateKey] = useState(key);
  const ref = useRef();
  
  return {
    date,
    ref,
    setDate,
    dateKey,
    setDateKey,
    invalid,
    setInvalid,
    clearInput() {
      ref.current?.setInputValue('');
      ref.current.value = ''; // probably not needed, but keeping as callback
      setDate();
    },
  };
}

export default function useDays({
  columnId,
  setOpenCalendarType,
  setNewDraft,
  lastClearType,
  lastClearFilterId,
  }) {
  const start = useDay('start');
  const end = useDay('end');
  const days = useMemo( () =>[start, end], [start, end]);
  
  /*
  * This function is run when user changes start/end date either via calendar or via text input (or any future method).
  * So it's catch-all.
  * It should look for valid pairs of dates (when user selected appropriate range), and set draft column filter.
  * It's important that draft column filter includes valid date ranges, because it's draft is applied to actual column filters without modifications.
  * ~~~
  * Another purpose of this function is to auto-correct values when user selected END date before START date.
  * This auto-correct would save the trouble of manually correcting another input.
  * */
  function setDate(startDateMaybeChanged, endDateMaybeChanged) {
    if (isDate(startDateMaybeChanged)) {
      if (isValid(startDateMaybeChanged)) {
        start.setDate(startDateMaybeChanged);
        start.setDateKey( `start-${ startDateMaybeChanged.getFullYear() }-${ startDateMaybeChanged.getMonth() }` );
        start.setInvalid(false);
        if (end.date) {
          if (isAfter(end.date, startDateMaybeChanged)) {
            setNewDraft(columnId, [startDateMaybeChanged, end.date]);
          } else {
            const endDateGuess = addDays(startDateMaybeChanged, 1);
            end.setDate(endDateGuess);
            setNewDraft(columnId, [startDateMaybeChanged, endDateGuess]);
            
            end.ref.current.value = format(endDateGuess, dateTextFormat);
          }
        }
      } else {
        start.setInvalid(true);
      }
    }
    
    if (isDate(endDateMaybeChanged)) {
      if (isValid(endDateMaybeChanged)) {
        end.setDate(endDateMaybeChanged);
        end.setDateKey( `end-${ endDateMaybeChanged.getFullYear() }-${ endDateMaybeChanged.getMonth() }` );
        end.setInvalid(false);
        if (start.date) {
          if (isAfter(endDateMaybeChanged, start.date)) {
            setNewDraft(columnId, [start.date, endDateMaybeChanged]);
          } else {
            const startDateGuess = subDays(endDateMaybeChanged, 1);
            start.setDate(startDateGuess);
            setNewDraft(columnId, [startDateGuess, endDateMaybeChanged]);
            start.ref.current.value = format(startDateGuess, dateTextFormat);
          }
        }
      } else {
        end.setInvalid(true);
      }
    }
  }

  function setStartDateDatepicker(value) {
    if (value) {
      start.ref.current.value = format(value, dateTextFormat);
      setDate(value);
      setOpenCalendarType();
    }
  }

  function setEndDateDatepicker(value) {
    if (value) {
      end.ref.current.value = format(value, dateTextFormat);
      setDate(undefined, value);
      setOpenCalendarType();
    }
  }

  const clearDatesAndInputs = useCallback(() => {
    days.forEach((date) => {
      date.date = undefined;
      date.clearInput();
    });
  }, [days])

  useValueClearing(columnId, lastClearFilterId, lastClearType, clearDatesAndInputs)

  return {
    start,
    end,
    setDate,
    setStartDateDatepicker,
    setEndDateDatepicker,
  };
}
