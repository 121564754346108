import CreateDomainButton from "pages/DomainsPage/CreateDomainButton";
import React from "react";
import { ReactComponent as Www } from "assets/images/WWW.svg";
import PropTypes from "prop-types";

function EmptyMessage({ openCreateModal }) {
  return (
    <div className="tableNodata">
      <div className="tableNodataImage">
        <Www/>
      </div>
      <h3 className="tableNodataTitle">
        You don't have any Domains yet
      </h3>
      <p className="tableNodataDescription">
        Start by creating a domain
      </p>
      <CreateDomainButton handleOpen={ openCreateModal } />
    </div>
  );
}

EmptyMessage.propTypes = {
  openCreateModal: PropTypes.func.isRequired,
};

export default EmptyMessage;