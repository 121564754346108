import {Copy} from "icons";
import style from './style.module.css';
import Tooltip from "components/Tooltip";
import PropTypes from "prop-types";
import useCopyToClipboard from "components/CopyToClipboard/useCopyToClipboard";

function CopyToClipboard({ text }) {
  const {
    copy,
    copied,
  } = useCopyToClipboard(text);

  return (
    <Tooltip
      visible={copied}
      text={ copied ? 'Copied!' : 'Copy' }
    >
      <button
        className={style.button}
        onClick={copy}
      >
        <Copy/>
      </button>
    </Tooltip>
  );
}

CopyToClipboard.propTypes = {
  text: PropTypes.string.isRequired,
}

export default CopyToClipboard;