import React, {useState, useEffect, useCallback} from 'react';

import { CardButton } from 'components/CardButton';
import { Button } from 'components/Button'
import { Select } from 'components/Select'
import { InputField } from 'components/InputField'

import { 
    ChevronLeft,
    Storage as StorageIcon, 
    StorageQencode as StorageQencodeIcon,
    StorageTemp as StorageTempIcon, 
    Backblaze as BackblazeIcon,
    GoogleCloud as GoogleCloudIcon,
    DigitalOcean as DigitalOceanIcon,
    Azure as AzureIcon,
    AWS as AWSIcon
} from 'icons';

import { ReactComponent as QencodeLogo } from 'assets/images/QencodeLogo.svg'

//import { ReactComponent as MediaStorageConfig } from 'assets/images/MediaStorageConfig.svg'

import { QencodeStorageForm } from './QencodeStorageForm';

import { useApi } from 'api/useApi'

import { MultiValidation } from 'components/MultiValidation';

import { validateFields } from 'helpers/validation';

import { qencodeRegions } from 'helpers/qencodeRegions';
import { parseDestinationUrl } from 'helpers/urlParser';

import { 
    BackblazeForm, DigitaloceanForm, AmazonForm, GoogleForm, 
    AzureForm, GenericS3Form, FTPForm, SFTPForm 
} from './StorageForms/'

import { EnableMediaStorage } from '../EnableMediaStorage';

import config from 'config';

import styles from '../index.module.css';
import { Label } from 'components/Label';

export const DestinationSelector = ({destination, templateChanged, onDestinationUpdate, setTemplateChanged, configData, onEnableSuccess, onEnableFail, updateStepStatus}) => {

    // const s3url = process.env.REACT_APP_S3_URL || 's3-qa.qencode.com';
    const { s3Subdomain, domain } = config;
    const s3url = `${s3Subdomain}.${domain}`

    const { 
        getBucketsListData,
        createBucket
    } = useApi();

    // Local states to manage incoming props
    const [localConfigData, setLocalConfigData] = useState(configData);
    
    // Sync local state with incoming props
    useEffect(() => {
        setLocalConfigData(configData);
    }, [configData]);       

    // card values
    const [qencodeStorageCardValue, setQencodeStorageCardValue] = useState('');
    const [qencodeStorageCardExtraData, setQencodeStorageCardExtraData] = useState('');

    const [thirdPartyStorageCardValue, setThirdPartyStorageCardValue] = useState('');
    const [thirdPartyStorageCardValueIcon, setThirdPartyStorageCardValueIcon] = useState(undefined);
    const [thirdPartyStorageCardExtraData, setThirdPartyStorageCardExtraData] = useState('');

    // const [temporaryStorageCardValue, setTemporaryStorageCardValue] = useState('');
    const temporaryStorageCardValue = "After 24 hours all files will be deleted."

    // forms
    const [activeBlock, setActiveBlock] = useState('');
    const [activeEditForm, setActiveEditForm] = useState('');

    // track visible block
    const initialBlocksVisibility = {
        qencode_storage: true,
        third_party_storage: true,
        temporary_storage: true        
    }
    const [blocksVisibility, setBlocksVisibility] = useState(initialBlocksVisibility);

    // Function to set all blocks to visible
    const showAllBlocks = () => {
        setBlocksVisibility({
            qencode_storage: true,
            third_party_storage: true,
            temporary_storage: true
        });
    };

    // Function to set only one block to visible and the rest to false
    const showOneBlock = (visibleBlock) => {
        setBlocksVisibility({
            qencode_storage: visibleBlock === 'qencode_storage',
            third_party_storage: visibleBlock === 'third_party_storage',
            temporary_storage: visibleBlock === 'temporary_storage'
        });
    };    


    const [validationErrors, setValidationErrors] = useState({});

    // STORAGE

    const providerIcon = (provider) => {
        let iconComponent;
        switch (provider) {
            case 'backblaze':
                iconComponent = <BackblazeIcon label="Backblaze" />;
                break;
            case 'digitalocean':
                iconComponent = <DigitalOceanIcon label="Digital Ocean" />;
                break;
            case 'amazon':
                iconComponent = <AWSIcon label="Amazon Web Service" />;
                break;     
            case 'google':
                iconComponent = <GoogleCloudIcon label="Google Cloud" />;
                break;     
            case 'azure':
                iconComponent = <AzureIcon label="Microsoft Azure" />;
                break;     
            case 'generic_s3':
                iconComponent = <StorageIcon label="Generic S3" />;
                break;  
            case 'ftp':
                iconComponent = <StorageIcon label="FTP(s)" />;
                break;  
            case 'sftp':
                iconComponent = <StorageIcon label="SFTP" />;
                break;                                                                                             
            default:
                iconComponent = undefined;
                break;
        }

        return iconComponent
    };    

    const storageOptions = [
        { icon: providerIcon("backblaze"), value: 'backblaze', label: 'Backblaze' },
        { icon: providerIcon("digitalocean"), value: 'digitalocean', label: 'Digital Ocean' },
        { icon: providerIcon("amazon"), value: 'amazon', label: 'Amazon Web Service' },
        { icon: providerIcon("google"), value: 'google', label: 'Google Cloud' },
        { icon: providerIcon("azure"), value: 'azure', label: 'Microsoft Azure' },
        { icon: providerIcon("generic_s3"), value: 'generic_s3', label: 'Generic S3' },
        { icon: providerIcon("ftp"), value: 'ftp', label: 'FTP(s)' },
        { icon: providerIcon("sftp"), value: 'sftp', label: 'SFTP' },
    ] 

    const getOptionLabel = (value, options) => {
        const option = options.find(option => option.value === value);
        return option ? option.label : null;
    };    
 
    const defaultStotageFormsValues = {
        storage_provider: '', 

        backblaze: {
            bucket_name: '',
            access_key_id: '',
            secret_access_key: '',
            public_permissions: false,
        },

        digitalocean: {
            bucket_name: '',
            region_name: '',
            access_key_id: '',
            secret_access_key: '',
            public_permissions: false,            
        },

        amazon: {
            bucket_name: '',
            region_name: '',
            access_key_id: '',
            secret_access_key: '',
            public_permissions: false,   
            reduce_redundancy: false,
        },

        google: {
            bucket_name: '',
            access_key_id: '',
            secret_access_key: '',
            public_permissions: false,
        },

        azure: {
            bucket_name: '',
            azure_host: "core.windows.net", // preset host value
            access_key_id: '',
            secret_access_key: '',
        },

        generic_s3: {
            bucket_name: '',
            s3_endpoint: '',
            access_key_id: '',
            secret_access_key: '',
            public_permissions: false,
        },

        ftp: {
            ftp_endpoint: '',
            ftp_username: '',
            ftp_password: '',
            ftp_tls: false,
            ftp_is_passive: false,
        },
        
        sftp: {
            sftp_endpoint: '',
            sftp_username: '',
            sftp_password: '',            
        }        
    }

    const [storageCredentials, setStorageCredentials] = useState(defaultStotageFormsValues)
    const [storageCredentialsSnapshot, setStorageCredentialsSnapshot] = useState(defaultStotageFormsValues);

    // save snapshot on initial render
    // useEffect(() => {
    //     if (storageCredentialsSnapshot === null) {
    //         setStorageCredentialsSnapshot(storageCredentials);
    //     }
    // }, [storageCredentials, storageCredentialsSnapshot]);    
    
    // const [storageCredentials, setStorageCredentials] = useState({

    //     storage_provider: '', 

    //     backblaze: {
    //         bucket_name: '',
    //         access_key_id: '',
    //         secret_access_key: '',
    //         public_permissions: false,
    //     },

    //     digitalocean: {
    //         bucket_name: '',
    //         region_name: '',
    //         access_key_id: '',
    //         secret_access_key: '',
    //         public_permissions: false,            
    //     },

    //     amazon: {
    //         bucket_name: '',
    //         region_name: '',
    //         access_key_id: '',
    //         secret_access_key: '',
    //         public_permissions: false,   
    //         reduce_redundancy: false,
    //     },

    //     google: {
    //         bucket_name: '',
    //         access_key_id: '',
    //         secret_access_key: '',
    //         public_permissions: false,
    //     },

    //     azure: {
    //         bucket_name: '',
    //         azure_host: "core.windows.net", // preset host value
    //         access_key_id: '',
    //         secret_access_key: '',
    //     },

    //     generic_s3: {
    //         bucket_name: '',
    //         s3_endpoint: '',
    //         access_key_id: '',
    //         secret_access_key: '',
    //         public_permissions: false,
    //     },

    //     ftp: {
    //         ftp_endpoint: '',
    //         ftp_username: '',
    //         ftp_password: '',
    //         ftp_tls: false,
    //         ftp_is_passive: false,
    //     },
        
    //     sftp: {
    //         sftp_endpoint: '',
    //         sftp_username: '',
    //         sftp_password: '',            
    //     }
        
    // });     
     

    const handleInputChangeStorage = (e) => {
        const { name, value } = e.target;
        //console.log({ name, value })
        const newValue = value === '' ? '' : value === 'true' ? true : value === 'false' ? false : value;
    
        const [provider, key] = name.split('.');
    
        if (provider && key) {
            setStorageCredentials(prevState => ({
                ...prevState,
                [provider]: {
                    ...prevState[provider],
                    [key]: newValue
                }
            }));
        } else {
            setStorageCredentials(prevState => ({
                ...prevState,
                [name]: newValue
            }));
        }
    
    };

    const handleSetProvider = (value) => {
        const name = 'storage_provider'
        const event = {
            target: {
                name: name,
                value: value,
            }
        };
        handleInputChangeStorage(event); // Pass the simulated event object        
    }
    
 
    // BUCKETS

    ///////////////////////////////////////////////
    // handle create new bucket form

    // to manage multiline validation
    const bucketNameValidationRules = [
        {
            message: 'The bucket name must be from 6 to 63 characters max.',
            test: (value) => value.length >= 6 && value.length <= 63
        },        
        {
            message: 'Must start and end with a lowercase letter or number',
            // test: (value) => /^[a-z0-9]([a-z0-9-]*[a-z0-9])?$/.test(value)
            test: (value) => /^[a-z0-9].*[a-z0-9]$/.test(value)
        },
        {
            message: 'Only Latin lower-case characters, number, and dashes',
            test: (value) => /^[a-z0-9-]+$/.test(value)
        },
        {
            message: 'No space',
            test: (value) => !/\s/.test(value)
        }
    ];


    const [bucketNameValidations, setbucketNameValidations] = useState(bucketNameValidationRules.map(rule => ({ message: rule.message, isValid: null })));

    const validateBucketName = (value) => {
        if (value === '') {
            // Reset all validations to neutral state
            setbucketNameValidations(bucketNameValidationRules.map(rule => ({ message: rule.message, isValid: null })));
            return;
        }

        const newValidations = bucketNameValidationRules.map(rule => ({
            message: rule.message,
            isValid: rule.test(value)
        }));

        setbucketNameValidations(newValidations);
    };    

    const [creatingBucket, setCreatingBucket] = useState(false);

    const [newBucketError, setNewBucketError] = useState(false);

    const [newBucketCredentials, setNewBucketCredentials] = useState({
        qencode_bucket_name: "",
        qencode_bucket_region: qencodeRegions[0]?.value || ''  // set 1st region on load by default
    });

    const handleInputChangeNewBucket = (e) => {
        if (newBucketError) {
            setNewBucketError(null);
        }
    
        const { name, value } = e.target;
        // Check if the value is an empty string and set it to null if true
        const newValue = value === '' ? '' : value;
    
        const newCredentials = { ...newBucketCredentials, [name]: newValue };
        setNewBucketCredentials(newCredentials);
    
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: null
        }));

        // run validation for MultiValidation block
        // when typing bucket name
        if(name === 'qencode_bucket_name'){
            validateBucketName(value);
        }
    };     

    // make sure Create bucket is disabled if there is any error on input
    const isBucketNameValid = bucketNameValidations.every(validation => validation.isValid);
    const isBucketNameEmpty = newBucketCredentials?.qencode_bucket_name?.length === 0;
    ///////////////////////////////////////////////

    const [buckets, setBuckets] = useState([]);
    const [currentBucket, setCurrentBucket] = useState(null);

    const [currentBucketSnapshot, setCurrentBucketSnapshot] = useState(null);

    // loading buckets list and setting 1st bucket as default
    const fetchBucketsData = useCallback(async () => {
        try {
            const { success, data, detail } = await getBucketsListData();
            if (success && data) {
                console.log("buckets data: ", data);
                setBuckets(data);
        
                // Set the first bucket as the selected one if there are buckets available
                if (data.length > 0) {
                    setCurrentBucket(data[0]);
                    setCurrentBucketSnapshot(data[0]);
                }
            } else {
                console.error(detail || "Failed to get buckets list data");
            }
        } catch (error) {
            console.error(error.message); // Handle the error as needed
        }
    }, [getBucketsListData]);

    useEffect(() => {
        fetchBucketsData();
    }, [fetchBucketsData]);    

    const handleBucketChange = (bucket) => {
        console.log("bucket: ", bucket)
        setCurrentBucket(bucket)
    }    

    const handleCancelCreateNewBucket = () => {
        console.log("Cancel create new bucket")
        setActiveEditForm('qencode_storage')
    }

    const handleShowCreateNewBucketForm = () => {
        console.log("Show Create New Bucket Form")

        // clear errors and reset values

        //setNewBucketError(null)

        // setNewBucketCredentials ({
        //     qencode_bucket_name: "",
        //     qencode_bucket_region: qencodeRegions[0]?.value || ''  // set 1st region on load by default
        // });        

        // Reset all validations to neutral state
        //setbucketNameValidations(bucketNameValidationRules.map(rule => ({ message: rule.message, isValid: null })));

        // clear valudation error for "qencode_bucket_name" input field
        // setValidationErrors(prevErrors => ({
        //     ...prevErrors,
        //     qencode_bucket_name: null
        // }));
        
        setActiveEditForm('create_bucket')
    }
    

    useEffect(() => {
        if (templateChanged) {
            console.log("update destination: ", destination);
    
            if (!destination) {
                setActiveBlock('temporary_storage');

                console.log("Set temp storage as only visible")
                // show one block only
                // const visibleBlock = 'temporary_storage'
                // setBlocksVisibility({
                //     qencode_storage: visibleBlock === 'qencode_storage',
                //     third_party_storage: visibleBlock === 'third_party_storage',
                //     temporary_storage: visibleBlock === 'temporary_storage'
                // }); 
                showOneBlock('temporary_storage')               
            } else {
                console.log("Clear all cards");
                setQencodeStorageCardValue('');
                setQencodeStorageCardExtraData('');
    
                setThirdPartyStorageCardValue('');
                setThirdPartyStorageCardExtraData('');
                setThirdPartyStorageCardValueIcon(undefined)
    
                setActiveBlock('');
                setActiveEditForm('');

                // show all blocks
                // setBlocksVisibility({
                //     qencode_storage: true,
                //     third_party_storage: true,
                //     temporary_storage: true
                // });  
                showAllBlocks()              

                if(destination?.url){
                    console.log("Set destination card data based on url");
        
                    const { type, provider, region, bucket, host, endpoint } = parseDestinationUrl(destination?.url);
        
                    console.log({
                        type,
                        provider,
                        region,
                        bucket,
                        host,
                        endpoint
                    });
        
                    if (type === "qencode_storage") {
                        setQencodeStorageCardValue(bucket); // this is bucket name
                        // find bucket by name from bucket list
                        const bucketName = bucket
                        const qencodeBucket = buckets.find(bucket => bucket.name === bucketName);
                        setCurrentBucket(qencodeBucket)
                        setCurrentBucketSnapshot(qencodeBucket)
                        setQencodeStorageCardExtraData(region);
                        setActiveBlock('qencode_storage');

                        // show one block only
                        // const visibleBlock = 'qencode_storage'
                        // setBlocksVisibility({
                        //     qencode_storage: visibleBlock === 'qencode_storage',
                        //     third_party_storage: visibleBlock === 'third_party_storage',
                        //     temporary_storage: visibleBlock === 'temporary_storage'
                        // });      
                        showOneBlock('qencode_storage')

                    } else if (type === "third_party_storage") {
                        setStorageCredentials(prevState => ({
                            ...prevState,
                            storage_provider: provider
                        }));

                        // save provider in snapshot
                        setStorageCredentialsSnapshot(prevState => ({
                            ...prevState,
                            storage_provider: provider
                        }));                        
        
                        if (provider === 'backblaze') {
                            setThirdPartyStorageCardValue('Backblaze');
                            setThirdPartyStorageCardExtraData(bucket);
                            setThirdPartyStorageCardValueIcon(providerIcon(provider))  
                            setStorageCredentials(prevState => ({
                                ...prevState,
                                backblaze: {
                                    bucket_name: bucket,
                                    access_key_id: destination?.key,
                                    secret_access_key: destination?.secret,
                                    public_permissions: destination?.permissions === 'public-read'
                                }
                            }));

                            // save snapshot
                            setStorageCredentialsSnapshot(prevState => ({
                                ...prevState,
                                backblaze: {
                                    bucket_name: bucket,
                                    access_key_id: destination?.key,
                                    secret_access_key: destination?.secret,
                                    public_permissions: destination?.permissions === 'public-read'
                                }
                            }));

                        } else if (provider === 'digitalocean') {
                            setThirdPartyStorageCardValue('Digital Ocean');
                            setThirdPartyStorageCardExtraData(bucket);
                            setThirdPartyStorageCardValueIcon(providerIcon(provider))  
                            setStorageCredentials(prevState => ({
                                ...prevState,
                                digitalocean: {
                                    bucket_name: bucket,
                                    region_name: region,
                                    access_key_id: destination?.key,
                                    secret_access_key: destination?.secret,
                                    public_permissions: destination?.permissions === 'public-read'
                                }
                            }));

                            setStorageCredentialsSnapshot(prevState => ({
                                ...prevState,
                                digitalocean: {
                                    bucket_name: bucket,
                                    region_name: region,
                                    access_key_id: destination?.key,
                                    secret_access_key: destination?.secret,
                                    public_permissions: destination?.permissions === 'public-read'
                                }
                            }));                            
                        } else if (provider === 'amazon') {
                            setThirdPartyStorageCardValue('Amazon');
                            setThirdPartyStorageCardExtraData(bucket);
                            setThirdPartyStorageCardValueIcon(providerIcon(provider))  
                            setStorageCredentials(prevState => ({
                                ...prevState,
                                amazon: {
                                    bucket_name: bucket,
                                    region_name: region,
                                    access_key_id: destination?.key,
                                    secret_access_key: destination?.secret,
                                    public_permissions: destination?.permissions === 'public-read',
                                    reduce_redundancy: destination?.storage_class === 'REDUCED_REDUNDANCY'
                                }
                            }));

                            setStorageCredentialsSnapshot(prevState => ({
                                ...prevState,
                                amazon: {
                                    bucket_name: bucket,
                                    region_name: region,
                                    access_key_id: destination?.key,
                                    secret_access_key: destination?.secret,
                                    public_permissions: destination?.permissions === 'public-read',
                                    reduce_redundancy: destination?.storage_class === 'REDUCED_REDUNDANCY'
                                }
                            }));                            
                        } else if (provider === 'google') {
                            setThirdPartyStorageCardValue('Google');
                            setThirdPartyStorageCardExtraData(bucket);
                            setThirdPartyStorageCardValueIcon(providerIcon(provider))  
                            setStorageCredentials(prevState => ({
                                ...prevState,
                                google: {
                                    bucket_name: bucket,
                                    access_key_id: destination?.key,
                                    secret_access_key: destination?.secret,
                                    public_permissions: destination?.permissions === 'public-read'
                                }
                            }));

                            setStorageCredentialsSnapshot(prevState => ({
                                ...prevState,
                                google: {
                                    bucket_name: bucket,
                                    access_key_id: destination?.key,
                                    secret_access_key: destination?.secret,
                                    public_permissions: destination?.permissions === 'public-read'
                                }
                            }));                            
                        } else if (provider === 'azure') {
                            setThirdPartyStorageCardValue('Azure');
                            setThirdPartyStorageCardExtraData(bucket);
                            setThirdPartyStorageCardValueIcon(providerIcon(provider))  
                            setStorageCredentials(prevState => ({
                                ...prevState,
                                azure: {
                                    bucket_name: bucket,
                                    azure_host: host,
                                    access_key_id: destination?.key,
                                    secret_access_key: destination?.secret
                                }
                            }));

                            setStorageCredentialsSnapshot(prevState => ({
                                ...prevState,
                                azure: {
                                    bucket_name: bucket,
                                    azure_host: host,
                                    access_key_id: destination?.key,
                                    secret_access_key: destination?.secret
                                }
                            }));                            
                        } else if (provider === 'generic_s3') {
                            setThirdPartyStorageCardValue('Generic S3');
                            setThirdPartyStorageCardExtraData(bucket);
                            setThirdPartyStorageCardValueIcon(undefined)  
                            setStorageCredentials(prevState => ({
                                ...prevState,
                                generic_s3: {
                                    bucket_name: bucket,
                                    s3_endpoint: endpoint,
                                    access_key_id: destination?.key,
                                    secret_access_key: destination?.secret,
                                    public_permissions: destination?.permissions === 'public-read'
                                }
                            }));

                            setStorageCredentialsSnapshot(prevState => ({
                                ...prevState,
                                generic_s3: {
                                    bucket_name: bucket,
                                    s3_endpoint: endpoint,
                                    access_key_id: destination?.key,
                                    secret_access_key: destination?.secret,
                                    public_permissions: destination?.permissions === 'public-read'
                                }
                            }));                            
                        } else if (provider === 'ftp') {
                            setThirdPartyStorageCardValue('FTP');
                            setThirdPartyStorageCardValueIcon(undefined)  
                            setStorageCredentials(prevState => ({
                                ...prevState,
                                ftp: {
                                    ftp_endpoint: endpoint,
                                    ftp_username: destination?.key,
                                    ftp_password: destination?.secret,
                                    ftp_tls: destination?.use_tls === '1',
                                    ftp_is_passive: destination?.is_passive === '1'
                                }
                            }));

                            setStorageCredentialsSnapshot(prevState => ({
                                ...prevState,
                                ftp: {
                                    ftp_endpoint: endpoint,
                                    ftp_username: destination?.key,
                                    ftp_password: destination?.secret,
                                    ftp_tls: destination?.use_tls === '1',
                                    ftp_is_passive: destination?.is_passive === '1'
                                }
                            }));                            
                        } else if (provider === 'sftp') {
                            setThirdPartyStorageCardValue('SFTP');
                            setThirdPartyStorageCardValueIcon(undefined)  
                            setStorageCredentials(prevState => ({
                                ...prevState,
                                sftp: {
                                    sftp_endpoint: endpoint,
                                    sftp_username: destination?.key,
                                    sftp_password: destination?.secret
                                }
                            }));

                            setStorageCredentialsSnapshot(prevState => ({
                                ...prevState,
                                sftp: {
                                    sftp_endpoint: endpoint,
                                    sftp_username: destination?.key,
                                    sftp_password: destination?.secret
                                }
                            }));                            
                        }
        
                        setActiveBlock('third_party_storage');

                        // show one block only
                        // const visibleBlock = 'third_party_storage'
                        // setBlocksVisibility({
                        //     qencode_storage: visibleBlock === 'qencode_storage',
                        //     third_party_storage: visibleBlock === 'third_party_storage',
                        //     temporary_storage: visibleBlock === 'temporary_storage'
                        // });  
                        showOneBlock('third_party_storage')                         
                    }                    
                }


            }
        }
    }, [destination, templateChanged, buckets]);
    


    // card handlers
    const handleUpdateCardValue = (name, value) => {
        console.log("Update card: ", name)

        // make sure there are no open forms
        setActiveEditForm('')

        // make block active
        setActiveBlock(name)

        // show one block only
        // const visibleBlock = name
        // setBlocksVisibility({
        //     qencode_storage: visibleBlock === 'qencode_storage',
        //     third_party_storage: visibleBlock === 'third_party_storage',
        //     temporary_storage: visibleBlock === 'temporary_storage'
        // });    
        showOneBlock(name)     

        // clear values for all cards
        setQencodeStorageCardValue('')
        setQencodeStorageCardExtraData('')

        setThirdPartyStorageCardValue('')
        setThirdPartyStorageCardExtraData('')
        setThirdPartyStorageCardValueIcon(undefined)
        //setTemporaryStorageCardValue('')

        // set value for specific card
        if(name === 'qencode_storage'){
            setQencodeStorageCardValue(currentBucket.name)    
            setQencodeStorageCardExtraData(currentBucket.region)

            setCurrentBucketSnapshot(currentBucket)

            onDestinationUpdate({
                url: `s3://${currentBucket.region}.${s3url}/${currentBucket.name}/`,
                // permissions: "public-read"
            })
        }

        if(name === 'third_party_storage'){
            //setThirdPartyStorageCardValue(value)    

            // onDestinationUpdate({
            //     url: value
            // })

            const { 
                storage_provider,
                backblaze,
                digitalocean,
                amazon,
                google,
                azure,
                generic_s3,
                ftp,
                sftp
            } = storageCredentials

            // take new snapshot
            setStorageCredentialsSnapshot(storageCredentials)

            //console.log("storage_provider: ", storage_provider)

            // Create new snapshot
            const newSnapshot = {
                ...defaultStotageFormsValues, // Start with all default values
                storage_provider, // Keep the current storage_provider value
                [storage_provider]: {
                    ...defaultStotageFormsValues[storage_provider], // Keep default values for the specific provider
                    ...storageCredentials[storage_provider] // Override only for the relevant provider
                }
            };

            // make sure previous data is reset to default and only updated is saved            
            setStorageCredentials(newSnapshot)
            setStorageCredentialsSnapshot(newSnapshot);            

            setThirdPartyStorageCardValue(getOptionLabel(storage_provider, storageOptions))

            let destinationObject = {};

            // Construct and update the destination object if the provider is Backblaze
            if(storage_provider === 'backblaze'){
                setThirdPartyStorageCardExtraData(backblaze.bucket_name)
                setThirdPartyStorageCardValueIcon(providerIcon(storage_provider))    
                // Create the base destination object
                destinationObject = {
                    url: `b2://backblaze.com/${backblaze.bucket_name}/`,
                    key: backblaze.access_key_id,
                    secret: backblaze.secret_access_key,
                }                
                // Add public permissions if applicable
                if(backblaze.public_permissions){
                    destinationObject.permissions = "public-read"
                }                            
            }

            // Construct and update the destination object if the provider is Digital Ocean
            if(storage_provider === 'digitalocean'){
                setThirdPartyStorageCardExtraData(digitalocean.bucket_name)
                setThirdPartyStorageCardValueIcon(providerIcon(storage_provider))    
                // Create the base destination object
                destinationObject = {
                    url: `s3://${digitalocean.region_name}.digitaloceanspaces.com/${digitalocean.bucket_name}/`,
                    key: digitalocean.access_key_id,
                    secret: digitalocean.secret_access_key,
                }                
                // Add public permissions if applicable
                if(digitalocean.public_permissions){
                    destinationObject.permissions = "public-read"
                }              
            }  
            
            // Construct and update the destination object if the provider is Amazon
            if(storage_provider === 'amazon'){
                setThirdPartyStorageCardExtraData(amazon.bucket_name)
                setThirdPartyStorageCardValueIcon(providerIcon(storage_provider))    
                // Create the base destination object
                destinationObject = {
                    url: `s3://${amazon.region_name}.amazonaws.com/${amazon.bucket_name}/`,
                    key: amazon.access_key_id,
                    secret: amazon.secret_access_key,
                }                
                // Add public permissions if applicable
                if(amazon.public_permissions){
                    destinationObject.permissions = "public-read"
                }
                if(amazon.reduce_redundancy){
                    destinationObject.storage_class = "REDUCED_REDUNDANCY"
                }                
            }              
            
            // Construct and update the destination object if the provider is Google
            if(storage_provider === 'google'){
                setThirdPartyStorageCardExtraData(google.bucket_name)
                setThirdPartyStorageCardValueIcon(providerIcon(storage_provider))    
                // Create the base destination object
                destinationObject = {
                    url: `s3://storage.googleapis.com/${google.bucket_name}/`,
                    key: google.access_key_id,
                    secret: google.secret_access_key,
                }                
                // Add public permissions if applicable
                if(google.public_permissions){
                    destinationObject.permissions = "public-read"
                }             
            }      
            
            // Construct and update the destination object if the provider is Azure
            if(storage_provider === 'azure'){
                setThirdPartyStorageCardExtraData(azure.bucket_name)
                setThirdPartyStorageCardValueIcon(providerIcon(storage_provider))    
                // Create the base destination object
                destinationObject = {
                    url: `azblob://${azure.azure_host}/${azure.bucket_name}/`,
                    key: azure.access_key_id,
                    secret: azure.secret_access_key,
                }                           
            }      
            
            // Construct and update the destination object if the provider is Generic S3
            if(storage_provider === 'generic_s3'){
                setThirdPartyStorageCardExtraData(generic_s3.bucket_name)
                setThirdPartyStorageCardValueIcon(undefined)  
                // Create the base destination object
                destinationObject = {
                    url: `s3://${generic_s3.s3_endpoint}/${generic_s3.bucket_name}/`,
                    key: generic_s3.access_key_id,
                    secret: generic_s3.secret_access_key,
                }                
                // Add public permissions if applicable
                if(generic_s3.public_permissions){
                    destinationObject.permissions = "public-read"
                }             
            }     
            
            // Construct and update the destination object if the provider is FTP
            if(storage_provider === 'ftp'){
                setThirdPartyStorageCardValueIcon(undefined)  
                // Create the base destination object
                destinationObject = {
                    url: `ftp://${ftp.ftp_endpoint}/`,
                    key: ftp.ftp_username,
                    secret: ftp.ftp_password,
                }                
 
                if(ftp.ftp_tls){
                    destinationObject.use_tls = "1"
                }

                if(ftp.ftp_is_passive){
                    destinationObject.is_passive = "1"
                }                           
            }  
            
            // Construct and update the destination object if the provider is SFTP
            if(storage_provider === 'sftp'){
                setThirdPartyStorageCardValueIcon(undefined)  
                // Create the base destination object
                destinationObject = {
                    url: `sftp://${sftp.sftp_endpoint}/`,
                    key: sftp.sftp_username,
                    secret: sftp.sftp_password,
                }                                       
            }              
                        
            // based on selected Destination
            onDestinationUpdate(destinationObject) 
        }


        setTemplateChanged(false)  
        //setTemplateChanged(true)
        
    }    

    const handleCardClick = (name) => {
        console.log("Click card: ", name)

        if(name === 'temporary_storage'){
            setActiveBlock(name)
            //setTemporaryStorageCardValue('After 24 hours all files will be deleted.')

            onDestinationUpdate(null)
            setTemplateChanged(false) 

            // show one block only
            // const visibleBlock = 'temporary_storage'
            // setBlocksVisibility({
            //     qencode_storage: visibleBlock === 'qencode_storage',
            //     third_party_storage: visibleBlock === 'third_party_storage',
            //     temporary_storage: visibleBlock === 'temporary_storage'
            // }); 
            showOneBlock('temporary_storage')             
        } else {
          setActiveEditForm(name)  
        }

        
    }    

    const handleClearClick = (name) => {
        console.log("Clear card: ", name)

        // when clear card set temp storage as active
        // setActiveBlock('temporary_storage');

        // show all blocks
        // setBlocksVisibility({
        //     qencode_storage: true,
        //     third_party_storage: true,
        //     temporary_storage: true
        // });  
        showAllBlocks()         

        if(name === 'qencode_storage'){
            setQencodeStorageCardValue(null)    
            setQencodeStorageCardExtraData(null)

            // onDestinationUpdate({
            //     url: ''
            // })

            // reset selected bucket to 1st one or to undefined?
            setCurrentBucket(buckets[0])
            setCurrentBucketSnapshot(buckets[0])
            //setCurrentBucket(undefined)

            onDestinationUpdate(undefined)            
        }

        if(name === 'third_party_storage'){
            setThirdPartyStorageCardValue(null)   

            // onDestinationUpdate({
            //     url: ''
            // })

            // clear destination
            // onDestinationUpdate({})
            onDestinationUpdate(undefined)

            // // reset form values to default
            // setStorageCredentials(defaultStotageFormsValues)
        }        

        if(name === 'temporary_storage'){
            setActiveBlock('');         
        }        
        
        //handleUpdateCardValue(name, '')

        // indicate that the change was not caused by template change
        setTemplateChanged(false)  

        // clear this destination step as passed
        updateStepStatus('selectDestination', false);

        setActiveBlock('');
        setActiveEditForm('');

        // reset form values to default
        setStorageCredentials(defaultStotageFormsValues)
        setStorageCredentialsSnapshot(defaultStotageFormsValues)
    }

    const handleEditClick = (name) => {
        console.log("Edit card: ", name)

        setActiveEditForm(name)
    }

    // form handlers

    const handleFormBackClick = (name) => {
        console.log("Back clicked in card: ", name)

        // this closes any open form
        setActiveEditForm('')

        if(name === 'qencode_storage'){
            // restore selected bucket to snapshot
            setCurrentBucket(currentBucketSnapshot)
        }

        // restore state of forms if back/cancel clicked in third_party_storage
        if(name === 'third_party_storage'){
            //console.log("storageCredentialsSnapshot: ", storageCredentialsSnapshot)
            setStorageCredentials(storageCredentialsSnapshot)
        }
    }


    // BUCKETS
    // also need to add state like buket is creating and block button
    // const handleCreateNewBucket = async (e) => {
    //     e.preventDefault()

    //     // Clear previous errors
    //     if (newBucketError) {
    //         setNewBucketError(null);
    //     }

    //     // validate bucket name field 
    //     const fieldsToValidate = ['qencode_bucket_name']; 
    //     const { isValid, errors } = validateFields(newBucketCredentials, fieldsToValidate);
    //     setValidationErrors(errors); // Update the validation errors state
      
    //     if (!isValid) {
    //       console.log("Validation errors", errors);
    //       return; // Stop the submission if validation fails
    //     }   

    //     console.log("Handle create new bucket")

    //     // const bucketName = 'bucket03-1234567'
    //     // const region = 'eu-central'

    //     // createBucket

    //     // const settings = {
    //     //     name: bucketName,
    //     //     region: region
    //     // }

    //     const settings = {
    //         name: newBucketCredentials.qencode_bucket_name.trim(), // trim whitespaces
    //         region: newBucketCredentials.qencode_bucket_region
    //     }

    //     try {
    //         const { success, data, detail } = await createBucket(settings);
    //         if (success && data) {
    //             console.log("bucket data: ", data);

    //             // fetch buckets list and pass create bucket data to set it as currentBucket
    //             // that is slow request and afer bucket is created it's not available from the list yet
    //             //fetchBucketsData(data);

    //             // instead add created bucket on top of the list
    //             setBuckets(prevBuckets => [data, ...prevBuckets]);

    //             // and set this bucket as current bucket
    //             setCurrentBucket(data)
                

    //             // close create bucket form and show qencode_storage form 
    //             setActiveEditForm('qencode_storage')
    //         } else {
    //             console.error(detail || "Failed to create new bucket");
    //             setNewBucketError(detail || "Failed to create new bucket")
    //         }
    //     } catch (error) {
    //         console.error(error.message); // Handle the error as needed
    //         setNewBucketError(error.message)
    //     }        

      
    // }   
    
    const handleCreateNewBucket = async (e) => {
        e.preventDefault();
    
        // Clear previous errors
        if (newBucketError) {
            setNewBucketError(null);
        }
    
        // Validate bucket name field 
        const fieldsToValidate = ['qencode_bucket_name']; 
        const { isValid, errors } = validateFields(newBucketCredentials, fieldsToValidate);
        setValidationErrors(errors); // Update the validation errors state
      
        if (!isValid) {
            console.log("Validation errors", errors);
            return; // Stop the submission if validation fails
        }   
    
        console.log("Handle create new bucket");
    
        const settings = {
            name: newBucketCredentials.qencode_bucket_name.trim(), // Trim whitespaces
            region: newBucketCredentials.qencode_bucket_region
        };
    
        setCreatingBucket(true); // Set creatingBucket to true
    
        try {
            const { success, data, detail } = await createBucket(settings);
            if (success && data) {
                console.log("bucket data: ", data);
    
                // Instead, add created bucket on top of the list
                setBuckets(prevBuckets => [data, ...prevBuckets]);
    
                // Set this bucket as current bucket
                setCurrentBucket(data);
    
                // Close create bucket form and show qencode_storage form 
                setActiveEditForm('qencode_storage');
            } else {
                console.error(detail || "Failed to create new bucket");
                setNewBucketError(detail || "Failed to create new bucket");
            }
        } catch (error) {
            console.error(error.message); // Handle the error as needed
            setNewBucketError(error.message);
        } finally {
            setCreatingBucket(false); // Ensure creatingBucket is set to false when the request is done
        }        
    };
        

    return (
        <div>
            {
                !activeBlock && !activeEditForm
                ?
                <div className={styles.workflowInit}>
                    <div className={styles.workflowSelector} onClick={() => handleCardClick('qencode_storage')}>
                        <StorageQencodeIcon className={styles.workflowSelectorIcon} iconColor='#316FEA'/>
                        <div className={styles.workflowSelectorBody}>
                            <div className={styles.workflowSelectorTitle}>Qencode S3 Storage</div>
                            <div className={styles.workflowSelectorDescr}>Store and organise your videos easily with S3</div>
                        </div>                    
                    </div>
                    <div className={styles.workflowSelector} onClick={() => handleCardClick('third_party_storage')}>
                        <StorageIcon className={styles.workflowSelectorIcon} />
                        <div className={styles.workflowSelectorBody}>
                            <div className={styles.workflowSelectorTitle}>Third-Party Storage</div>
                            <div className={styles.workflowSelectorDescr}>Connect to third party storage destinations</div>
                        </div>                    
                    </div>
                    <div className={styles.workflowSelector} onClick={() => handleCardClick('temporary_storage')}>
                        <StorageTempIcon className={styles.workflowSelectorIcon} />
                        <div className={styles.workflowSelectorBody}>
                            <div className={styles.workflowSelectorTitle}>Temporary Storage</div>
                            <div className={styles.workflowSelectorDescr}>Free storage for 24 hours</div>
                        </div>                    
                    </div>                                
                </div>                   
                :
                <div className={styles.workflowContainer}>
                    {
                        blocksVisibility.qencode_storage &&
                        <div>
                            {
                                !activeEditForm &&
                                <CardButton 
                                    icon={<StorageQencodeIcon iconColor='#316FEA'/>}
                                    title='Qencode S3 Storage'
                                    name='qencode_storage'
                                    description="Store and organise your videos easily with S3"
                                    value={qencodeStorageCardValue}
                                    extraData={qencodeStorageCardExtraData}
                                    active={qencodeStorageCardValue && activeBlock === 'qencode_storage'} // Set active based on activeBlock state
                                    onClick={() => handleCardClick("qencode_storage")}
                                    onEdit={() => handleEditClick("qencode_storage")}
                                    onClear={() => handleClearClick("qencode_storage")}
                                />                            
                            }

                            {activeEditForm === 'qencode_storage' && (
                                <div>

                                    {/* <Button
                                        icon={<ChevronLeft label="Back" />}
                                        onClick={() => handleFormBackClick('qencode_storage')}
                                        variant="link"
                                    >
                                        Back
                                    </Button>  */}

                                    <div className={styles.form}>
                                        {/* <div className={styles.formTitle}>Qencode Storage</div> */}
                                        <div className={styles.qencodeStorageHeader}>
                                            <QencodeLogo className={styles.qencodeStorageHeaderLogo}/>
                                            <div className={styles.qencodeStorageHeaderText}>Storage</div>
                                        </div>                            

                                        {
                                            // !loadingS3config && !configError 
                                            localConfigData?.access_id && localConfigData?.secret_key
                                            ?
                                            <QencodeStorageForm
                                                buckets={buckets}
                                                currentBucket={currentBucket}
                                                // buckets={[]} // use to test no buckets
                                                // currentBucket={undefined} // use to test no buckets
                                                onBucketChange={handleBucketChange}
                                                handleCreateNewBucket={handleShowCreateNewBucketForm}
                                            />                                
                                            :
                                            <EnableMediaStorage 
                                                onEnableSuccess={onEnableSuccess} 
                                                onEnableFail={onEnableFail}
                                                onCancel={() => handleFormBackClick('qencode_storage')}
                                            />
                                            // <div className={styles.mediaStorageNotReady}>
                                            //     <MediaStorageConfig />
                                            //     <div className={styles.mediaStorageNotReadyMessage}>Please wait a few minutes while your Media Storage environment is being configured.</div>
                                            // </div>                                
                                        }



                                        {/* can save only if there is bucket selected */}
                                        {
                                            currentBucket &&
                                            <div className={styles.formFooter}>
                                                <div className={styles.formMainExtraButtons}></div>
                                                <div className={styles.formMainButtons}>
                                                    <Button 
                                                        size="sm"
                                                        onClick={() => handleFormBackClick('qencode_storage')}
                                                        type="secondary"
                                                    >
                                                        Cancel
                                                    </Button> 
                                                    <Button 
                                                        size="sm"
                                                        onClick={() => handleUpdateCardValue('qencode_storage', "qencode storage value")}
                                                    >
                                                        Save
                                                    </Button>   
                                                </div>
                                            </div>                                
                                        }
                                    </div>                            


                                </div>
                            )}     

                            {activeEditForm === 'create_bucket' && (
                                <div>

                                    {/* <Button
                                        icon={<ChevronLeft label="Back" />}
                                        onClick={handleCancelCreateNewBucket}
                                        variant="link"
                                    >
                                        Back
                                    </Button>   */}

                                    <div className={styles.form}>

                                        <div className={styles.qencodeStorageHeader}>
                                            <QencodeLogo className={styles.qencodeStorageHeaderLogo}/>
                                            <div className={styles.qencodeStorageHeaderText}>Storage</div>
                                        </div>

                                        <div className={styles.formTitle}>Create Bucket</div>

                                        <div className={styles.formBody}>
                                            <div className={styles.formRow}>
                                                <Select
                                                    onChange={handleInputChangeNewBucket}
                                                    name="qencode_bucket_region"
                                                    label="Choose region"
                                                    help={{
                                                        no_header: true,
                                                        title: 'Region',
                                                        content: 'Qencode media storage region.'                                            
                                                    }}
                                                    options={qencodeRegions}
                                                    value={newBucketCredentials.qencode_bucket_region}
                                                />   
                                            </div>
                                            <div className={styles.formRow}>
                                                <InputField
                                                    inputText={newBucketCredentials.qencode_bucket_name}
                                                    onChange={handleInputChangeNewBucket}
                                                    name="qencode_bucket_name"
                                                    placeholder=""
                                                    label="Bucket Name"
                                                    help={{
                                                        no_header: true,
                                                        title: 'Bucket Name',
                                                        content: 'Your bucket in Qencode media storage'                                            
                                                    }}                                        
                                                    state={
                                                        newBucketError || 
                                                        bucketNameValidations.some(validation => validation.isValid === false) || 
                                                        (validationErrors.qencode_bucket_name && newBucketCredentials.qencode_bucket_name.length > 0) ? 
                                                        'error' : 
                                                        'default'
                                                    }
                                                    hint={(newBucketError ? newBucketError : undefined) || (validationErrors.qencode_bucket_name ? validationErrors.qencode_bucket_name : undefined)}
                                                />   
                                                
                                                {/* <MultiValidation validations={bucketNameValidations} /> */}
                                            </div> 
                                            <MultiValidation validations={bucketNameValidations} />
                                        </div>

                                        <div className={styles.formFooter}>
                                            <div className={styles.formMainExtraButtons}>
                                                <Button 
                                                    className={styles.backBtn}
                                                    size="sm"
                                                    type="secondary"
                                                    onClick={handleCancelCreateNewBucket}
                                                    icon={<ChevronLeft className={styles.backBtnIcon} />}
                                                >
                                                    Back
                                                </Button>                                    
                                            </div>
                                            <div className={styles.formMainButtons}>

                                                <Button 
                                                    size="sm"
                                                    type="secondary"
                                                    // onClick={handleCancelCreateNewBucket}
                                                    onClick={() => handleFormBackClick('qencode_storage')}
                                                >
                                                    Cancel
                                                </Button> 

                                                <Button 
                                                    size="sm"
                                                    onClick={handleCreateNewBucket}
                                                    //state={!isBucketNameValid || isBucketNameEmpty ? "disabled" : undefined}
                                                    state={!isBucketNameValid || isBucketNameEmpty || creatingBucket ? "disabled" : undefined}
                                                >
                                                    Create
                                                </Button> 

                                            </div>
                                        </div>


                                    </div>

                                </div>
                            )}                           
                        </div>                    
                    }

                    {/* <div className={blocksVisibility.third_party_storage ? styles.visible : styles.hidden}></div> */}
                    {
                        blocksVisibility.third_party_storage &&
                        <div>
                            {
                                !activeEditForm &&
                                <CardButton 
                                    icon={<StorageIcon />}
                                    title='Third-Party Storage'
                                    name='third_party_storage'
                                    description="Connect to third party storage destinations"
                                    value={thirdPartyStorageCardValue}
                                    valueIcon={thirdPartyStorageCardValueIcon}
                                    extraData={thirdPartyStorageCardExtraData}
                                    active={thirdPartyStorageCardValue && activeBlock === 'third_party_storage'} // Set active based on activeBlock state
                                    onClick={() => handleCardClick("third_party_storage")}
                                    onEdit={() => handleEditClick("third_party_storage")}
                                    onClear={() => handleClearClick("third_party_storage")}
                                />                            
                            }

                            {activeEditForm === 'third_party_storage' && (
                                <div>

                                    <div className={styles.form}>
                                        <div className={styles.formTitle}>Third-Party Storage</div>

                                        <div className={styles.formBody}>

                                            {
                                                !storageCredentials.storage_provider
                                                ?
                                                <>
                                                    <div className={styles.formRow}>
                                                        <Label text="Choose Provider" />
                                                        <div className={styles.destProvidersContainer}>
                                                            <div className={styles.destProvidersRow}>

                                                                <div className={styles.destProvider} onClick={() => handleSetProvider('amazon')}>
                                                                    <AWSIcon className={styles.destProviderIcon} label="Amazon Web Service" />
                                                                    <div className={styles.destProviderName}>AWS</div>                                                                    
                                                                </div>

                                                                <div className={styles.destProvider} onClick={() => handleSetProvider('google')}>
                                                                    <GoogleCloudIcon className={styles.destProviderIcon} label="Google Cloud" />
                                                                    <div className={styles.destProviderName}>Google Cloud</div>                                                                    
                                                                </div>

                                                                <div className={styles.destProvider} onClick={() => handleSetProvider('azure')}>
                                                                    <AzureIcon className={styles.destProviderIcon} label="Microsoft Azure" />
                                                                    <div className={styles.destProviderName}>Microsoft Azure</div>                                                                    
                                                                </div>                                                                                                                                
                                                            </div>

                                                            <div className={styles.destProvidersRow}>
                                                                
                                                                <div className={styles.destProvider} onClick={() => handleSetProvider('backblaze')}>
                                                                    <BackblazeIcon className={styles.destProviderIcon} label="Backblaze" />
                                                                    <div className={styles.destProviderName}>Backblaze</div>                                                                    
                                                                </div>

                                                                <div className={styles.destProvider} onClick={() => handleSetProvider('digitalocean')}>
                                                                    <DigitalOceanIcon className={styles.destProviderIcon} label="Digital Ocean" />
                                                                    <div className={styles.destProviderName}>Digital Ocean</div>                                                                    
                                                                </div>

                                                                <div className={styles.destProvider} onClick={() => handleSetProvider('generic_s3')}>
                                                                    <StorageIcon className={styles.destProviderIcon} label="Generic S3" />
                                                                    <div className={styles.destProviderName}>Generic S3</div>                                                                    
                                                                </div>                                                                                                                                
                                                            </div>     

                                                            <div className={styles.destProvidersRow}>
                                                                
                                                                <div className={styles.destProvider} onClick={() => handleSetProvider('ftp')}>
                                                                    <StorageIcon className={styles.destProviderIcon} label="FTP(s)" />
                                                                    <div className={styles.destProviderName}>FTP(s)</div>                                                                    
                                                                </div>    

                                                                <div className={styles.destProvider} onClick={() => handleSetProvider('sftp')}>
                                                                    <StorageIcon className={styles.destProviderIcon} label="SFTP" />
                                                                    <div className={styles.destProviderName}>SFTP</div>                                                                    
                                                                </div>    

                                                                <div className={styles.destProviderBlank}></div>

                                                            </div>                                                                                                                     
                                                        </div>
                                                    </div>

                                                    {/* if storage provider not selected show these buttons
                                                    otherwise show buttons in forms */}
                                                    <div className={styles.formFooter}>
                                                        <Button 
                                                            size="sm"
                                                            type="secondary"
                                                            onClick={() => handleFormBackClick('third_party_storage')}
                                                        >
                                                            Cancel
                                                        </Button>     
                                                        <Button size="sm" state="disabled">
                                                            Save
                                                        </Button>                                                                
                                                    </div>                                                    
                                                </>
                                                :
                                                <>
                                                    <div className={styles.formRow}>
                                                        <Select
                                                            onChange={handleInputChangeStorage}                                        
                                                            name="storage_provider"
                                                            label="Bucket Provider"
                                                            placeholder='Select Bucket Provider'
                                                            options={storageOptions}
                                                            value={storageCredentials.storage_provider}
                                                            wrappedIcon={true}
                                                        />   
                                                    </div>                                 

                                                    {/* show form based on which storage option is selected */}   

                                                    {
                                                        storageCredentials.storage_provider === 'backblaze' &&
                                                        <BackblazeForm
                                                            storageCredentials={storageCredentials}
                                                            onChange={handleInputChangeStorage}
                                                            onSave={() => handleUpdateCardValue('third_party_storage')}
                                                            onCancel={() => handleFormBackClick('third_party_storage')}
                                                        />
                                                    }


                                                    {
                                                        storageCredentials.storage_provider === 'digitalocean' &&
                                                        <DigitaloceanForm
                                                            storageCredentials={storageCredentials}
                                                            onChange={handleInputChangeStorage}
                                                            onSave={() => handleUpdateCardValue('third_party_storage')}
                                                            onCancel={() => handleFormBackClick('third_party_storage')}
                                                        />
                                                    }
                    
                                                    {
                                                        storageCredentials.storage_provider === 'amazon' &&
                                                        <AmazonForm 
                                                            storageCredentials={storageCredentials}
                                                            onChange={handleInputChangeStorage}
                                                            onSave={() => handleUpdateCardValue('third_party_storage')}
                                                            onCancel={() => handleFormBackClick('third_party_storage')}
                                                        />
                                                    }                        
                                                    
                                                    {
                                                        storageCredentials.storage_provider === 'google' &&
                                                        <GoogleForm 
                                                            storageCredentials={storageCredentials}
                                                            onChange={handleInputChangeStorage}
                                                            onSave={() => handleUpdateCardValue('third_party_storage')}
                                                            onCancel={() => handleFormBackClick('third_party_storage')}
                                                        />
                                                    }                                

                                                    {
                                                        storageCredentials.storage_provider === 'azure' &&
                                                        <AzureForm 
                                                            storageCredentials={storageCredentials}
                                                            onChange={handleInputChangeStorage}
                                                            onSave={() => handleUpdateCardValue('third_party_storage')}
                                                            onCancel={() => handleFormBackClick('third_party_storage')}
                                                        />
                                                    }                                  

                                                    {
                                                        storageCredentials.storage_provider === 'generic_s3' &&
                                                        <GenericS3Form 
                                                            storageCredentials={storageCredentials}
                                                            onChange={handleInputChangeStorage}
                                                            onSave={() => handleUpdateCardValue('third_party_storage')}
                                                            onCancel={() => handleFormBackClick('third_party_storage')}
                                                        />
                                                    }    

                                                    {
                                                        storageCredentials.storage_provider === 'ftp' &&
                                                        <FTPForm 
                                                            storageCredentials={storageCredentials}
                                                            onChange={handleInputChangeStorage}
                                                            onSave={() => handleUpdateCardValue('third_party_storage')}
                                                            onCancel={() => handleFormBackClick('third_party_storage')}
                                                        />
                                                    }    

                                                    {
                                                        storageCredentials.storage_provider === 'sftp' &&
                                                        <SFTPForm 
                                                            storageCredentials={storageCredentials}
                                                            onChange={handleInputChangeStorage}
                                                            onSave={() => handleUpdateCardValue('third_party_storage')}
                                                            onCancel={() => handleFormBackClick('third_party_storage')}
                                                        />
                                                    }                          

 

                                                </>                                                       
                                            }

                                     

                                        </div>


                                    </div>                            


                                </div>
                            )}           
                        </div>
                    }

                    {
                        blocksVisibility.temporary_storage &&
                        <div>
                            {
                                !activeEditForm &&
                                <CardButton 
                                    icon={<StorageTempIcon />}
                                    title='Temporary Storage'
                                    name='temporary_storage'
                                    description="Free storage for 24 hours"
                                    value={temporaryStorageCardValue}
                                    active={activeBlock  === 'temporary_storage'} // Set active based on activeBlock state
                                    onClick={() => handleCardClick("temporary_storage")}
                                    onClear={() => handleClearClick("temporary_storage")}
                                    important={true}
                                />                            
                            }

                            {/* there is no edit form for temp storage */}
                        </div>
                    }


                </div>                
            }

  


        </div>
    );
};
