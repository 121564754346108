import React, {useCallback, useState} from 'react';
import {
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import style from "./style.module.css";
import useDataLoadingStatus from "hooks/useDataLoadingStatus";
import Pagination from "components/Table/Pagination";
import { ReactComponent as Template } from "assets/images/Template.svg";
import Table from "components/Table";
import MoreRowActions from "./MoreRowActions";
import CreateStreamButton from "pages/Streaming/StreamsPage/CreateStreamButton";
import globalFilterFn from "components/Table/columnFilters/exactCaseInsensitiveFilter";
import {listLiveStreamsWithProjects} from "api/live-streaming-api";
import StreamStatus from "pages/Streaming/StreamsPage/StreamStatus";
import MaskedKey from "components/MaskedKey";
import PlaybackId from "pages/Streaming/StreamsPage/PlaybackId";
import DateFormatted from "components/DateFormatted";
import Search from "components/Search";
import RenameStreamModal from "pages/Streaming/StreamsPage/RenameStreamModal";
import DeleteStreamModal from "pages/Streaming/StreamsPage/DeleteStreamModal";
import CreateStreamModal from "pages/Streaming/StreamsPage/CreateStreamModal";
import FilterSidebar from "components/FilterSidebar";
import DateRangeFilter from "components/FilterSidebar/DateRangeFilter";
import isWithinDateRange from "components/FilterSidebar/DateRangeFilter/isWithinDateRange";
import {columnIds, columnIdToReadableName, modals, streamStatusToReadable, streamStatusTypicalOrder} from "./config";
import useFilter from "components/FilterSidebar/useFilter";
import CopyToClipboard from "components/CopyToClipboard";
import {Link} from "react-router-dom";
import utcStringToDate from "helpers/utcStringToDate";
import isWithinEnumerableRange from "components/FilterSidebar/MultiSelectFilter/filterFn";
import MultiSelectFilter from "components/FilterSidebar/MultiSelectFilter";
import TableWrapper from "components/TableWrapper";

export default function StreamsPage() {
  const loadingStatus = useDataLoadingStatus(useCallback((properties) => listLiveStreamsWithProjects({
    ...properties,
  }), []));
  const {
    data: streamsData,
    data2: projectsData,
    refresh,
  } = loadingStatus;
  
  const columns = React.useMemo(
    () => [
      {
        id: columnIds.streamId,
        accessorKey: 'id',
        cell: info => {
          const templateName = info.getValue();
          return (
            <div className="cellContainer">
              <Link to={`/stream/${ info.row?.original?.id }`  } className={ `linkEllipsis` } >
                  { templateName }
              </Link>
              <CopyToClipboard text={templateName}/>
            </div>
          )
        },
        header: () => <span>Stream id</span>,
      },
      {
        id: columnIds.streamName,
        accessorKey: 'name',
        cell: (info) => {
          return <Link
            to={ `/stream/${ info.row?.original?.id }` }
            className="linkEllipsis"
            >
              { info.getValue() }
            </Link>
        },
        header: () => <span>Stream Name</span>,
      },
      {
        id: columnIds.playbackId,
        accessorKey: 'playback_ids',
        header: () => <span>Playback Url</span>,
        enableSorting: false,
        cell: (info) => {
          return <PlaybackId playbackIdList={ info.getValue() }/>;
        },
      },
      {
        id: columnIds.project,
        accessorKey: 'projectName',
        filterFn: 'isWithinEnumerableRange',
        header: () => <span>Project Name</span>,
        cell: (info) => (
          <span className={ style.cellLimited }>{ info.getValue() }</span>
        )
      },
      {
        id: columnIds.dateCreated,
        accessorKey: 'createdAtDate',
        sortingFn: 'datetime',
        filterFn: 'isWithinDateRange',
        cell: info => (
          <span>
            <DateFormatted date={ info.getValue() } />
          </span>
        ),
        header: () => <span>Date Created</span>,
      },
      {
        id: columnIds.streamKey,
        accessorKey: 'stream_key',
        cell: (info) => {
          return (
            <MaskedKey rawKey={info.getValue()} withCopy={true}/>
          )
        },
        header: () => <span>Stream Key</span>,
      },
      {
        id: columnIds.status,
        accessorKey: 'status',
        filterFn: 'isWithinEnumerableRange',
        cell: (info) => {
          const status = info.getValue();

          return (
            <StreamStatus status={ status }/>
          )
        },
        header: () => <span>Status</span>,
      },
      {
        id: columnIds.moreActions,
        enableSorting: false,
        accessorKey: 'id',
        cell: (info) => (
          <MoreRowActions
            streamId={info.getValue()}
            handleOpenModal={handleOpenModal}
          />
        ),
      },
    ],
    []
  );
  
  const [openedModal, setOpenedModal] = useState();
  const [selectedStreamId, setSelectedStreamId] = useState();

  function handleOpenModal(type, id) {
    setSelectedStreamId(id);
    setOpenedModal(type);
  }
  
  const modalProps = {
    streamId: selectedStreamId,
    handleCancel: handleCloseModals,
    onSuccessfulFinish: () => {
      handleCloseModals();
      refresh();
    },
  };
  
  function handleCloseModals() {
    handleOpenModal();
  }

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([{
    id: columnIds.dateCreated,
    desc: true,
  }]);
  const filter = useFilter({ setPagination });
  const table = useReactTable({
    data: streamsData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    /* Sorting */
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    /* Filtering */
    filterFns:{
      isWithinDateRange,
      isWithinEnumerableRange,
    },
    globalFilterFn,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    onColumnFiltersChange: filter.setColumnFiltersAndClearPagination,

    state: {
      sorting,
      globalFilter,
      pagination,
      columnFilters: filter.columnFilters,
    },
  });
  
  const uniqueProjectNamesSortedByDateDesc = Array.from(
    new Set(
      projectsData
        .sort((a, b) => utcStringToDate( b['create_datetime'] ) - utcStringToDate(a['create_datetime']))
        .map((project) => project?.name)
     )
    );
   
  const statusOptions = filter.filterSectionProps.addCheckedStatus(columnIds.status, streamStatusTypicalOrder
    .map(([ statusId ]) => ({
      value: statusId,
      label: streamStatusToReadable[ statusId ],
    })));

  const projectOptions = filter.filterSectionProps.addCheckedStatus(columnIds.project, uniqueProjectNamesSortedByDateDesc.map((projectName) => ({
    label: projectName,
    value: projectName,
  })));

  return (
    <TableWrapper>
      <div className={style.toolbar}>
        <Search
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
        />
        <FilterSidebar filter={filter}>
          <MultiSelectFilter
            columnId={columnIds.project}
            title={columnIdToReadableName[ columnIds.project ]}
            defaultExpanded={true}
            options={projectOptions}
            nothingFoundText="No projects found"
            search={true}
            { ...filter.filterSectionProps }
          />
          <DateRangeFilter
            columnId={ columnIds.dateCreated }
            title={columnIdToReadableName[ columnIds.dateCreated ]}
            defaultExpanded={true}
            { ...filter.filterSectionProps }
          />
          <MultiSelectFilter
            columnId={columnIds.status}
            defaultExpanded={true}
            title={columnIdToReadableName[ columnIds.status ]}
            options={ statusOptions }
            { ...filter.filterSectionProps }
          />
        </FilterSidebar>
        {/*<ActiveFiltersList*/}
        {/*  filter={filter}*/}
        {/*  columnIdToReadableName={columnIdToReadableName}*/}
        {/*  columnToActiveFilterContent={{*/}
        {/*    [ columnIds.dateCreated ]: {*/}
        {/*      component: DateRangeActiveFilter,*/}
        {/*    },*/}
        {/*    [ columnIds.status ]: {*/}
        {/*      component: EnumerableActiveFilter,*/}
        {/*      props: {*/}
        {/*         valueToReadable: streamStatusToReadable,*/}
        {/*        }*/}
        {/*      },*/}
        {/*    [ columnIds.project ]: {*/}
        {/*      component: EnumerableActiveFilter,*/}
        {/*    }*/}
        {/*  }}*/}
        {/*/>*/}
        <CreateStreamButton handleOpenModal={handleOpenModal} />
      </div>
      <Table
        { ...loadingStatus }
        minWidth={800}
        customClassTable={ `fixedTable ${ style.table }` }
        table={table}
        getEmptyComponent={() => <EmptyMessage handleOpenModal={handleOpenModal}/>}
        clearAllFilters={filter.clearAllFilters}
        columnIdToClassName={{
          [columnIds.dateCreated]: style.dateCreated,
          [columnIds.streamKey]: style.streamKey,
          [columnIds.status]: style.statusColumn,
          [columnIds.moreActions]: style.moreActionsColumn,
        }}
      />
      <Pagination table={ table } />
      <RenameStreamModal
        { ...modalProps }
        open={ openedModal === modals.rename }
      />
      <DeleteStreamModal
        { ...modalProps }
        open={ openedModal === modals.delete }
      />
      <CreateStreamModal
        { ...modalProps }
        projects={ projectsData }
        open={ openedModal === modals.create }
      />
    </TableWrapper>
  );
}

function EmptyMessage({ handleOpenModal }) {
  return (
    <div className="tableNodata">
      <div className="tableNodataImage">
        <Template/>
      </div>
      <h3 className="tableNodataTitle">
          You don't have any Streams yet
      </h3>
      <p className="tableNodataDescription">
          Start by creating a stream
      </p>
      <CreateStreamButton handleOpenModal={handleOpenModal}/>
    </div>
  )
}
