// if basePath is not specified it works as simple tab component without navigation

import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, Link, Navigate } from 'react-router-dom';
import styles from './Tabs.module.css';

export const Tabs = ({ tabs, basePath, activeTab, onTabClick, additionalContent }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const currentTabQuery = queryParams.get('tab');
    const currentTabExists = tabs.some(tab => tab.name === currentTabQuery);
    
    // Default to the first tab if the current tab does not exist
    const defaultTab = tabs[0].name;
    const currentTab = basePath ? (currentTabExists ? currentTabQuery : defaultTab) : activeTab;

    // Optionally, you can redirect to the default tab URL if the current tab does not exist and basePath is specified
    if (basePath && !currentTabExists) {
        return <Navigate to={`${basePath}?tab=${defaultTab}`} replace />;
    }

    // Find the currently selected tab component
    const ActiveTabComponent = tabs.find(tab => tab.name === currentTab)?.component;

    return (
        <div>
          <div className={styles.tabButtonsWrapper}>
            <div className={styles.tabButtons}>
                {tabs.map((tab) => (
                    basePath ? (
                        <Link
                            key={tab.name}
                            to={`${basePath}?tab=${tab.name}`}
                            className={`${styles.tabButton} ${currentTab === tab.name ? styles.active : ''}`}
                        >
                            {tab.icon}
                            <div className={styles.label}>{tab.label}</div>
                        </Link>
                    ) : (
                        <div
                            key={tab.name}
                            onClick={() => onTabClick(tab.name)}
                            className={`${styles.tabButton} ${currentTab === tab.name ? styles.active : ''}`}
                        >
                            {tab.icon}
                            <div className={styles.label}>{tab.label}</div>
                        </div>
                    )
                ))}
            </div>
            <div>{ additionalContent }</div>
          </div>
          <div>
            {/* Render only the active tab component */}
            {ActiveTabComponent}
          </div>
        </div>
    );
};

Tabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            icon: PropTypes.node, // Icon is optional
            component: PropTypes.element.isRequired, // Make sure this is 'element', not 'node'
        })
    ).isRequired,
    basePath: PropTypes.string, // Optional basePath
    // activeTab: PropTypes.string.isRequired, // Active tab controlled by the parent component
    activeTab: PropTypes.string, // Active tab controlled by the parent component
    // onTabClick: PropTypes.func.isRequired, // onClick handler controlled by the parent component
    onTabClick: PropTypes.func, // onClick handler controlled by the parent component
};

Tabs.defaultProps = {
    basePath: null, // Default to null if not provided
};

export default Tabs;



// this only works with navigation
// import React from 'react';
// import PropTypes from 'prop-types';
// import { useLocation, Link, Navigate } from 'react-router-dom';
// import styles from './Tabs.module.css';

// export const Tabs = ({ tabs, basePath }) => {
//     const location = useLocation();
//     const queryParams = new URLSearchParams(location.search);
//     const currentTabQuery = queryParams.get('tab');
//     const currentTabExists = tabs.some(tab => tab.name === currentTabQuery);

//     // Default to the first tab if the current tab does not exist
//     const currentTab = currentTabExists ? currentTabQuery : tabs[0].name;

//     // Optionally, you can redirect to the default tab URL if the current tab does not exist
//     if (!currentTabExists) {
//         return <Navigate to={`${basePath}?tab=${tabs[0].name}`} replace />;
//     }

//     // Find the currently selected tab component
//     const ActiveTabComponent = tabs.find(tab => tab.name === currentTab)?.component;

//     return (
//         <div>
//             <div className={styles.tabButtons}>
//                 {tabs.map((tab) => (
//                     <Link
//                         key={tab.name}
//                         to={`${basePath}?tab=${tab.name}`}
//                         className={`${styles.tabButton} ${currentTab === tab.name ? styles.active : ''}`}
//                     >
//                         {tab.icon}
//                         <div className={styles.label}>{tab.label}</div>
//                     </Link>
//                 ))}
//             </div>
//             <div>
//                 {/* Render only the active tab component */}
//                 {ActiveTabComponent}
//             </div>
//         </div>
//     );
// };

// Tabs.propTypes = {
//     tabs: PropTypes.arrayOf(
//         PropTypes.shape({
//             name: PropTypes.string.isRequired,
//             label: PropTypes.string.isRequired,
//             component: PropTypes.element.isRequired, // Make sure this is 'element', not 'node'
//         })
//     ).isRequired,
//     basePath: PropTypes.string.isRequired,
// };










// renderes all components and uses display to hide and show
// import React from 'react';
// import PropTypes from 'prop-types';
// import { useLocation, Link, Navigate } from 'react-router-dom';
// import styles from './Tabs.module.css';

// export const Tabs = ({ tabs, basePath }) => {

//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);
//   const currentTabQuery = queryParams.get('tab');
//   const currentTabExists = tabs.some(tab => tab.name === currentTabQuery);
  
//   // Default to the first tab if the current tab does not exist
//   const currentTab = currentTabExists ? currentTabQuery : tabs[0].name;

//   // Optionally, you can redirect to the default tab URL if the current tab does not exist
//   if (!currentTabExists) {
//     return <Navigate to={`${basePath}?tab=${tabs[0].name}`} replace />;
//   }  

//   return (
//     <div>
//       <div className={styles.tabButtons}>
//         {tabs.map((tab) => (
//           <Link
//             key={tab.name}
//             to={`${basePath}?tab=${tab.name}`}
//             className={`${styles.tabButton} ${currentTab === tab.name ? styles.active : ''}`}
//           >
//             {tab.icon}
//             <div className={styles.label}>{tab.label}</div>
//           </Link>
//         ))}
//       </div>
//       <div>
//         {tabs.map((tab) => (
//           <div
//             key={tab.name}
//             style={{ display: currentTab === tab.name ? 'block' : 'none' }}
//           >
//             {tab.component}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// Tabs.propTypes = {
//   tabs: PropTypes.arrayOf(
//     PropTypes.shape({
//       name: PropTypes.string.isRequired,
//       label: PropTypes.string.isRequired,
//       component: PropTypes.element.isRequired, // Make sure this is 'element', not 'node'
//     })
//   ).isRequired,
//   basePath: PropTypes.string.isRequired,
// };






// import React from 'react';
// import PropTypes from 'prop-types';
// import { useLocation, Link } from 'react-router-dom';
// import styles from './Tabs.module.css';

// export const Tabs = ({ tabs, basePath }) => {
//   const location = useLocation();
//   const currentTab = new URLSearchParams(location.search).get('tab') || tabs[0].name;

//   return (
//     <div>
//       <div className={styles.tabButtons}>
//         {tabs.map((tab) => (
//           <Link
//             key={tab.name}
//             to={`${basePath}?tab=${tab.name}`}
//             className={`${styles.tabButton} ${currentTab === tab.name ? styles.active : ''}`}
//           >
//             { tab.icon && tab.icon }
//             { tab.label && <div className={styles.label}>{tab.label}</div> }
//           </Link>
//         ))}
//       </div>
//       <div>
//         {tabs.map((tab) => {
//           if (tab.name === currentTab) {
//             // Make sure to render the component for the current tab
//             return <div key={tab.name}>{tab.component}</div>;
//           }
//           return null;
//         })}
//       </div>
//     </div>
//   );
// };

// Tabs.propTypes = {
//   tabs: PropTypes.arrayOf(
//     PropTypes.shape({
//       name: PropTypes.string.isRequired,
//       label: PropTypes.string.isRequired,
//       component: PropTypes.element.isRequired, // Make sure this is 'element', not 'node'
//     })
//   ).isRequired,
//   basePath: PropTypes.string.isRequired,
// };