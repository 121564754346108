import React, { useState, useCallback, useEffect } from 'react';

import { 
    useLocation,
    useNavigate, 
    Link 
} from 'react-router-dom';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { Button } from '../../components/Button';
import { NoData } from '../../components/NoData';

import { Modal } from '../../components/Modal';

import { getReceipstList, getReceiptData } from '../../api/qencode-account-api'

import { useAuth } from '../../context/AuthContext'
import { useUser } from '../../context/UserContext' 

import { Download, Print, EyeOpen } from '../../icons'

import { OptionsMenu } from '../../components/OptionsMenu';

import { ReactComponent as QencodeLogo } from '../../assets/images/QencodeLogo.svg';

import { formatDateTime, formatCurrency } from 'helpers/formatting';

import styles from './index.module.css';

// const formatCurrency = (value) => {
//     return new Intl.NumberFormat('en-US', {
//         style: 'currency',
//         currency: 'USD',
//     }).format(value);
// }

// const formatDateTime = (dateString) => {
//     const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
//     const date = new Date(dateString);
//     return date.toLocaleString('en-US', options);
// }

const Receipts = () => {

    const { 
        userData
    } = useUser(); 

    const location = useLocation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    const { 
        isAuthenticated, 
        //getAccessToken,
        getToken 
    } = useAuth();

    const [receiptsData, setReceiptsData] = useState([]);
    const [receiptsError, setReceiptsError] = useState(null);
    const [loadingReceipt, setLoadingReceipt] = useState(true);

    const [shouldGeneratePdf, setShouldGeneratePdf] = useState(false);
    const [shouldPrint, setShouldPrint] = useState(false);
    const [shouldView, setShouldView] = useState(false);

    const [currentReceipt, setCurrentReceipt] = useState(null);
    const [loadReceiptError, setLoadReceiptError] = useState(null);

    const [isReceiptModalOpen, setReceiptModalOpen] = useState(false);

    const fetchReceiptsData = useCallback(async () => {
        if (isAuthenticated) {
          setLoading(true);
          //const token = getAccessToken();
          const token = await getToken()
          const { success, data, total_count } = await getReceipstList(token);
          console.log("total_count: ", total_count)
          if (success && data) {
            console.log("receipts data: ", data)
            setReceiptsData(data);
            setReceiptsError(null);
          } else {
            setReceiptsError("Failed to get receipts data");
            console.log("Failed to get receipts data");
          }
          setLoading(false);
        } else {
          setReceiptsData([]);
          setReceiptsError(null);
          setLoading(false);
        }
    }, [isAuthenticated, getToken]);    

    useEffect(() => {
        fetchReceiptsData();
    }, [fetchReceiptsData]);   

    const getReceiptById = useCallback(async (id) => {
        //const token = getAccessToken();
        const token = await getToken()
        const settings = {id}
        //const settings = {} // to test error
        setLoadingReceipt(true)
        const { success, data } = await getReceiptData(token, settings);
        setLoadingReceipt(false)
        if (success && data) {
            console.log("Receipt data: ", data)
            setCurrentReceipt(data)
        } else {
            console.log("Failed to get receipt data");
            setLoadReceiptError("Failed to get receipt data")
        }            
    }, [getToken]); 

    useEffect(() => {    
        // Parse the query parameters
        const searchParams = new URLSearchParams(location.search);
        const receiptId = searchParams.get('id');
        const tab = searchParams.get('tab');

        if (receiptId && tab === 'receipts') {
            console.log('Load receipt:', receiptId);
            
            // open modal window with receipt 
            setReceiptModalOpen(true)

            // get invoice by id
            getReceiptById(receiptId)
        } else {
            setReceiptModalOpen(false)
        }
    }, [location.search, getReceiptById]); // Depend on location.search     

    const getMenuItems = (receipt, onDownload, onPrint, onView) => [
        {
            icon: <EyeOpen label="View" />,
            onClick: () => onView(receipt), 
            text: "View"
        },
        {
            icon: <Download label="Download" />,
            onClick: () => onDownload(receipt), 
            text: "Download"
        },
        {
            icon: <Print label="Print" />,
            onClick: () => onPrint(receipt), 
            text: "Print"
        },
    ];  

    const generatePdf = useCallback(async (receipt) => {
        const receiptElement = document.getElementById('receipt-section');
        if (receiptElement) {
            try {
                const canvas = await html2canvas(receiptElement);
                const data = canvas.toDataURL('image/png');
                const pdf = new jsPDF({
                    orientation: 'portrait',
                });
                const imgProps = pdf.getImageProperties(data);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
                pdf.save(`receipt_${receipt?.id}.pdf`);
                // Reset the flag and potentially notify the user of success
                setShouldGeneratePdf(false);
                console.log('PDF generated successfully!');
            } catch (error) {
                console.error('Error while generating PDF: ', error);
                // Reset the flag and notify user of failure
                setShouldGeneratePdf(false);
            }
        } else {
            console.error('No receipt element found to print!');
        }
    }, []);
    
    const createReceiptLink = (receipt) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('id', receipt.id);
      
        // Ensure the 'tab' parameter is present
        if (!searchParams.get('tab')) {
          searchParams.set('tab', 'receipts');
        }
      
        return `?${searchParams.toString()}`;
    };      

    const closeReceiptModal = () => {
        console.log("Close receipt modal")

        // Parse the current search parameters
        const searchParams = new URLSearchParams(location.search);
        // Remove the 'id' parameter from the URL
        searchParams.delete('id');
        // Navigate to the updated URL without the 'id'
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });

        setReceiptModalOpen(false)
        setCurrentReceipt(null)
        setLoadReceiptError(null)
    }

    const handleReceiptAction = async (receipt, actionFlagSetter, updateUrl = true) => {
        const searchParams = new URLSearchParams(location.search);
        const receiptIdFromParams = searchParams.get('id');
        const receiptId = receipt.id;
    
        if (receiptId === receiptIdFromParams) {
            console.log('Load receipt:', receiptId);
            setReceiptModalOpen(true);
            getReceiptById(receiptId);
        } else if (updateUrl) {
            navigate(createReceiptLink(receipt), { replace: true });
            if (actionFlagSetter) {
                actionFlagSetter(true);
            }
        }
    };

    const handleDownload = async (receipt) => {
        handleReceiptAction(receipt, setShouldGeneratePdf);
    };
    
    const handlePrint = async (receipt) => {
        handleReceiptAction(receipt, setShouldPrint);
    };
    
    const handleView = async (receipt) => {
        handleReceiptAction(receipt, setShouldView);
    };

    const handleReceiptClick = (receipt) => {
        console.log("Receipt clicked: ", receipt);
        handleReceiptAction(receipt, null, false);
    };

    // const handleReceiptClick = (receipt) => {
    //     // Log the entire receipt data
    //     console.log("Receipt clicked: ", receipt);

    //     // Parse the query parameters to get receipt ID from params
    //     const searchParams = new URLSearchParams(location.search);
    //     const receiptIdIdFromParams = searchParams.get('id');

    //     const receiptId = receipt.id;
        
    //     // in passed receipt ID is same as in params load it
    //     // other cases will be handle in useEffect watching location.search change
    //     if (receiptId === receiptIdIdFromParams) {
    //         console.log('Load receipt:', receiptId);
            
    //         // open modal window with receiptId 
    //         setReceiptModalOpen(true)

    //         // get receiptId by id
    //         getReceiptById(receiptId)
    //     }
    // };

        // will generate and save PDF when clicked from menu and receipt is loaded
        useEffect(() => {
            const generatePdfIfReady = async () => {
                if (shouldGeneratePdf && currentReceipt && !loadingReceipt) {
                    await generatePdf(currentReceipt);
                    // Reset the flag after generating the PDF
                    setShouldGeneratePdf(false);
                }
            };
        
            generatePdfIfReady();
        }, [shouldGeneratePdf, loadingReceipt, generatePdf, currentReceipt]);   
        
        // will print receipt when clicked in menu and when receipt loaded
        useEffect(() => {
            const printInvoice = async () => {
                if (shouldPrint && currentReceipt && !loadingReceipt) {
                    // print invoice
                    window.print()
                    // Reset the flag after generating the PDF
                    setShouldPrint(false);
                }
            };
        
            printInvoice();
        }, [shouldPrint, loadingReceipt, currentReceipt]);   
    
        // will show receipt when clicked in menu and when receipt loaded
        useEffect(() => {
            const showInvoice = async () => {
                if (shouldView && currentReceipt && !loadingReceipt) {
                    // show invoice
                    setReceiptModalOpen(true)
                    // Reset the flag after generating the PDF
                    setShouldView(false);
                }
            };
        
            showInvoice();
        }, [shouldView, loadingReceipt, currentReceipt]);      

    if (loading) {
        return <div>Loading...</div>; // Show a loading state
    }

    return (
        <div>
            <div className="tableContainer">
                <table className={`standardTable ${receiptsData?.length === 0 ? 'emptyTable' : ''}`}>
                    <thead>
                        <tr>
                            <th>Payment date</th>
                            <th>Receipt id</th>
                            <th>Amount</th>
                            <th>Payment Method</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {receiptsData.map(receipt => (
                      <tr key={receipt.id} className="tableRow">
                        <td>
                            <div className="cellContainer">{formatDateTime(receipt.create_datetime)}</div>
                        </td>
                        <td className='cellHighlighted'>
                            <div className="cellContainer">
                                <Link 
                                    to={createReceiptLink(receipt)}
                                    onClick={() => handleReceiptClick(receipt)}
                                >
                                    {receipt.id}
                                </Link>
                            </div>
                        </td>                        
                        <td>
                            <div className="cellContainer">{formatCurrency(receipt.amount)}</div>
                        </td>
                        <td className="cellCapitilized">
                            <div className="cellContainer">{receipt.payment_method}</div>
                        </td>
                        <td className="optionButtonsCell">
                            <div className="cellContainer">
                                <OptionsMenu menuItems={getMenuItems(receipt, handleDownload, handlePrint, handleView)} />
                            </div>
                        </td>
                      </tr>
                    ))}                    
                    </tbody>
                </table>    
                {/* {
                    receiptsData?.length === 0 &&
                    <div className="tableNodata">
                        <div className="tableNodataTitle">No receipts found</div>
                    </div>                        
                }         */}
                {
                    receiptsData?.length === 0 &&
                    <NoData
                        title="No receipts found"
                    />                       
                }
            </div>

            {receiptsError && <div className={styles.errorMessage}>{receiptsError}</div>}

            <Modal isOpen={isReceiptModalOpen} onClose={closeReceiptModal}
                variant="fullScreen"
                header={
                    <>  
                        <div className={styles.fullScreenModalTitle}>
                            <h3>Receipt</h3>     
                            <span className={styles.fullScreenModalTitleId}>
                                { loadingReceipt && <>loading...</>} {currentReceipt?.id}                                
                            </span>                               
                        </div>                      
                        <div>
                            <Button
                                icon={<Print />}
                                onClick={() => window.print()}
                            >
                                Print
                            </Button>      
                        </div>      
                    </>
                }
            >
                <div id='receipt-section' className={styles.fullScreenModalContainer}>      

                    <div className={styles.fullScreenBody}> 
                        <div className={styles.invoiceHeader}><QencodeLogo /></div>

                        <div className={styles.fullScreenSummary}>
                            <div className={styles.fullScreenSummaryLeft}>
                                <div className={styles.fullScreenSummaryCard}>
                                    <div className={styles.fullScreenSummaryCardTitle}>From</div>
                                    <div className={styles.fullScreenSummaryCardBusinessName}>
                                        Qencode, Corp.
                                    </div>
                                    <div className={styles.fullScreenSummaryCardAddress}>
                                        <div>
                                            16530 Ventura Blvd.<br/>
                                            Suite 402<br/>
                                            Encino, CA 91436, USA<br/>
                                        </div>
                                        <div>
                                            +1 310 905-8600<br/>
                                            support@qencode.com
                                        </div>                                    
                                    </div>

                                </div>
                                <div className={styles.fullScreenSummaryCard}>
                                    <div className={styles.fullScreenSummaryCardTitle}>To</div>
                                    <div className={styles.fullScreenSummaryCardBusinessName}>
                                        {userData.business_name}
                                    </div>
                                    <div className={styles.fullScreenSummaryCardAddress}>
                                        {
                                            userData.billing_address &&
                                            <div>
                                                {userData.billing_address}
                                            </div>
                                        }
                                        {
                                            (userData.phone || userData.billing_email) &&
                                            <div>
                                                {userData.phone}<br/>
                                                {userData.billing_email}
                                            </div>                                          
                                        }                                  
                                    </div>

                                </div>
                            </div>
                            <div className={styles.fullScreenSummaryRight}>
                                <div className={styles.fullScreenSummaryCard}>
                                    <div className={styles.fullScreenSummaryCardTitle}>Receipt ID:</div>
                                    <div className={styles.fullScreenSummaryCardData}>{currentReceipt?.id}</div>
                                </div>
                                <div className={styles.fullScreenSummaryCard}>
                                    <div className={styles.fullScreenSummaryCardTitle}>Payment date</div>
                                    {
                                        currentReceipt?.create_datetime && <div className={styles.fullScreenSummaryCardData}>{formatDateTime(currentReceipt?.create_datetime)}</div>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className={styles.fullScreenFooter}>
                        If you have questions about your receipt, 
                        please review <Link to={'/billing'}>Billing</Link> or contact us at <a href="mailto:support@qencode.com">support@qencode.com</a>
                    </div>

                    { loadReceiptError && <div className={styles.errorMessage}>{loadReceiptError}</div> }   
                </div>  
            </Modal>   
        </div>
    );
};

export default Receipts;