import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Modal} from "components/Modal";
import {Button} from "components/Button";
import {Select} from "components/Select";
import style from './style.module.css';
import {Add, ChevronUp} from "icons";
import useDataLoadingStatus from "hooks/useDataLoadingStatus";
import {listLiveDomains} from "api/qencode-content-delivery-api";
import {createLiveStream, getAccessToken} from "api/live-streaming-api";
import {Link} from "react-router-dom";
import {useAuth} from "context/AuthContext";
import ErrorMessage from "components/ErrorMessage";

function CreateStreamModal({
                               open,
                               handleCancel,
                               onSuccessfulFinish,
                               projects,
                           }) {
    const { getToken } = useAuth();
    const [submitting, setSubmitting] = useState(false);
    const {
        data: domains,
        errorMessage,
    } = useDataLoadingStatus( listLiveDomains );
    const [selectedProject, setSelectedProject] = useState();
    const [selectedDomain, setSelectedDomain] = useState();

    async function handleConfirmSubmit() {
        setSubmitting(true);
        let token = await getToken();

        const project = projects.find(({id}) => id === selectedProject);

        if (project) {
            const tokenResponse = await getAccessToken({ accessToken: token, id: project['api_key'] });
            if (tokenResponse.success === true) {
                token = tokenResponse.data;
            }
        }

        await createLiveStream({
            accessToken: token,
            body: {
                'playback_domain': selectedDomain,
            },
        });
        setSubmitting(false);
        clearFormAndClose();
        onSuccessfulFinish();
    }

    function clearFormAndClose() {
        setSelectedProject();
        setSelectedDomain();
        handleCancel();
    }

    function handleChangeDomain(e) {
        setSelectedDomain(e.target.value);
    }

    function handleChangeProject(e) {
        setSelectedProject(e.target.value);
    }

    return (
        <Modal
            isOpen={open}
            onClose={ clearFormAndClose }
            header={
                <>
                    <h3>
                        Let’s Start a Stream
                    </h3>
                    <p>
                        Launch high-quality live streams with global delivery in just a few simple steps
                    </p>
                    { errorMessage && <ErrorMessage>{ errorMessage }</ErrorMessage> }
                </>

            }
            footer={
                <>
                    <Button
                        variant="modalFooter"
                        onClick={ clearFormAndClose }
                        type="secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="modalFooter"
                        state={ submitting ? "disabled" : "default" }
                        onClick={ handleConfirmSubmit }
                    >
                        Create Stream
                    </Button>
                </>
            }
        >
            <div className={ style.wrapper }>
                <Select
                    label="Select a project"
                    options={ projects.map((project) => ({
                        label: project.name,
                        value: project.id,
                    })) }
                    onChange={handleChangeProject}
                    value={selectedProject}
                />
                <Link
                    to={ `/project/live_projects` }
                    className={ `${ style.fieldAdditionalAction } link`  }
                >
                    <Add/>
                    Create New Project
                </Link>
            </div>

            <details className={ style.details }>
                <summary className={ style.summary }>
                    <span>
                        Use custom domain for playback
                    </span>
                    <div className={ style.expand }>
                        <ChevronUp />
                    </div>
                </summary>
                <div className={ style.wrapper }>
                    <Select
                        label="Select a live streaming domain"
                        options={ domains.map((domain) => ({
                            label: domain['domain_name'],
                            value: domain['domain_name'],
                        })) }
                        onChange={handleChangeDomain}
                        value={ selectedDomain }
                    />
                    <Link
                        to={ `/delivery/domains?createDomainModal=1` }
                        className={ `${ style.fieldAdditionalAction } link`  }
                    >
                        <Add/>
                        Create New Domain
                    </Link>
                </div>
            </details>
        </Modal>
    )
}

CreateStreamModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    onSuccessfulFinish: PropTypes.func.isRequired,
    projects: PropTypes.arrayOf(PropTypes.object),
};

export default CreateStreamModal;