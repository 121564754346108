import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from "components/Modal";
import {Button} from "components/Button";
import {InputField} from "components/InputField";
import {clonePlayer} from "api/qencode-account-api";
import {VideoPlayerType} from "pages/Player/VideoPlayerListPage/config";
import useSingleFieldForm from "helpers/useSingleFieldForm";

function CloneModal({
  open,
  handleCancel,
  onSuccessfulFinish,
  selected,
}) {
  const {
    newName,
    submitting,
    errorMessage,
    handleNameInput,
    clearFormAndClose,
    handleConfirmSubmit,
  } = useSingleFieldForm({
    handleCancel,
    onSuccessfulFinish,
    apiFunction: (({ ...rest }) => clonePlayer({
      id: selected?.id,
      ...rest,
    })) ,
  });

  return (
    <Modal
      isOpen={open}
      onClose={ handleCancel }
      header={
        <h3>Clone Video Player</h3>
      }
      footer={
        <>
          <Button
            variant="modalFooter"
            onClick={ clearFormAndClose }
            type="secondary"
          >
            Cancel
          </Button>
          <Button
            variant="modalFooter"
            state={ submitting ? "disabled" : "default" }
            onClick={ handleConfirmSubmit }
          >
            Save
          </Button>
        </>
      }
    >
      <form
        className="form"
        onSubmit={ handleConfirmSubmit }
      >
        <div className="row">
          <InputField
            placeholder="Enter New Player Name"
            label="New Player Name"
            inputText={newName}
            state={errorMessage ? 'error' : 'default'}
            hint={errorMessage}
            onChange={ handleNameInput }
          />
        </div>
      </form>
    </Modal>
  )
}

CloneModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  onSuccessfulFinish: PropTypes.func,
  selected: PropTypes.shape( VideoPlayerType ),
};

export default CloneModal;