import PropTypes from "prop-types";
import React from "react";

export const CDN = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M5.79638 6.29083C6.04012 3.96148 7.94976 2.75 10 2.75C12.0502 2.75 13.9599 3.96148 14.2036 6.29083C14.2406 6.64432 14.5206 6.92331 14.8743 6.95899C16.2624 7.09904 17.25 8.16154 17.25 9.34691C17.25 10.6162 16.1111 11.75 14.5714 11.75H10H5.42857C3.88886 11.75 2.75 10.6162 2.75 9.34691C2.75 8.16154 3.73761 7.09904 5.12574 6.95899C5.47937 6.92331 5.75939 6.64432 5.79638 6.29083ZM14.5714 13.25H10.75V14.378C11.3893 14.604 11.896 15.1107 12.122 15.75H15C15.4142 15.75 15.75 16.0858 15.75 16.5C15.75 16.9142 15.4142 17.25 15 17.25H12.122C11.8131 18.1239 10.9797 18.75 10 18.75C9.02034 18.75 8.18691 18.1239 7.87803 17.25H5C4.58579 17.25 4.25 16.9142 4.25 16.5C4.25 16.0858 4.58579 15.75 5 15.75H7.87803C8.10399 15.1107 8.61069 14.604 9.25 14.378V13.25H5.42857C3.18119 13.25 1.25 11.5604 1.25 9.34691C1.25 7.48495 2.621 5.99023 4.39095 5.56561C4.97494 2.66258 7.54965 1.25 10 1.25C12.4503 1.25 15.0251 2.66258 15.609 5.56562C17.379 5.99023 18.75 7.48495 18.75 9.34691C18.75 11.5604 16.8188 13.25 14.5714 13.25ZM9.25 16.5C9.25 16.0858 9.58579 15.75 10 15.75C10.4142 15.75 10.75 16.0858 10.75 16.5C10.75 16.9142 10.4142 17.25 10 17.25C9.58579 17.25 9.25 16.9142 9.25 16.5ZM13.2931 6.14161C13.3062 6.20109 13.3183 6.27177 13.3294 6.35364C13.3368 6.40805 13.3354 6.46329 13.325 6.5172C13.2729 6.78837 13.0108 6.96594 12.7397 6.91336L7.45809 5.87273C7.41218 5.86307 7.36772 5.84739 7.3261 5.82574C7.08112 5.69831 6.98583 5.39641 7.11299 5.15129C7.15585 5.06743 7.196 4.99703 7.23345 4.94007C7.40999 4.67153 7.62312 4.43795 7.8672 4.2426C8.43446 3.7886 9.16893 3.54108 10.0001 3.54108C11.1286 3.54108 12.0941 4.01363 12.7024 4.82755C12.9797 5.19872 13.1828 5.64089 13.2931 6.14161Z" fill="currentColor"/>
    </svg>    
  );
};

CDN.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

CDN.defaultProps = {
  color: 'currentColor', // Or any default color
};