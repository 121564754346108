import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom'; // Add this line
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './MenuItem.module.css'; 

import { 
    //ChevronDown, ChevronUp, 
    ChevronTop, ChevronBottom 
} from "../../../icons";
import { SubmenuItem } from '../SubmenuItem';
import { useOutsideClick } from '../../../hooks/useOutsideClick';

export const MenuItem = ({ icon, label, to, isActive, isBeta, submenuItems, isCollapsed, external }) => {
    // const [isOpen, setIsOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(isActive);
    const menuItemRef = useRef(null);

    // Portal element for cloned submenu
    const portalRoot = document.getElementById('submenu-portal');

    // Submenu clone with calculated position
    // const submenuClone = isOpen && isCollapsed && portalRoot && ReactDOM.createPortal(        
    //     <div className={styles.submenuContainerClone} style={{ top: menuItemRef.current?.getBoundingClientRect().top + window.scrollY }}>
    //         {submenuItems.map((submenuItem, index) => (
    //             <SubmenuItem 
    //                 key={index}
    //                 label={submenuItem.label} 
    //                 to={submenuItem.to}
    //                 isActive={submenuItem.isActive}
    //             />
    //         ))}
    //     </div>,
    //     portalRoot
    // );   
    
    // Submenu clone with calculated position
    const submenuClone = isOpen && isCollapsed && Array.isArray(submenuItems) && submenuItems.length > 0 && portalRoot && ReactDOM.createPortal(
        <div className={styles.submenuContainerClone} style={{ top: menuItemRef.current?.getBoundingClientRect().top + window.scrollY }}>
            {submenuItems.map((submenuItem, index) => (
                <SubmenuItem 
                    key={index}
                    label={submenuItem.label} 
                    to={submenuItem.to}
                    isActive={submenuItem.isActive}
                />
            ))}
        </div>,
        portalRoot
    );    

    useOutsideClick(menuItemRef, () => isOpen && isCollapsed && setIsOpen(false));
 
    // const hasSubmenuItems = submenuItems && submenuItems.length > 0;
    const hasSubmenuItems = Array.isArray(submenuItems) && submenuItems.length > 0;
    const toggleSubmenu = (e) => {
        if (hasSubmenuItems) {
            e.preventDefault();
            setIsOpen(!isOpen);
        }
    };

    const MenuItemContent = (
        <>
            <div className={styles.menuItem}>
                {icon && React.cloneElement(icon, { className: styles.icon })}
                <div className={styles.menuItemTextWrapper}>
                    <span>{label}</span> {isBeta && <div className={styles.beta}>Beta</div>}   
                </div>                           
            </div>
            {hasSubmenuItems && (isOpen ? <ChevronTop className={styles.arrowIcon} /> : <ChevronBottom className={styles.arrowIcon} />)}
            {/* {hasSubmenuItems && (isOpen ? <ChevronUp /> : <ChevronDown />)} */}
        </>
    );
 
    // make sure this is only applied when sidebar menu is collapsed
    // Direct scroll handling within the component
    useEffect(() => {
        const handleWindowScroll = () => {
            // Logic to close submenu on significant scroll
            // if (isOpen) {
            //     setIsOpen(false);
            // }
            if (isOpen && isCollapsed) {
                setIsOpen(false);
            }
        };

        // Consider debouncing this if it's too sensitive
        window.addEventListener('scroll', handleWindowScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleWindowScroll);
    }, [isOpen, isCollapsed]);    

    // Set isOpen to true if isActive is true on initial load
    useEffect(() => {
        if (isActive) {
            setIsOpen(true);
        }
    }, [isActive]);    

    return (
        <div ref={menuItemRef} className={`${styles.container} ${isActive ? styles.active : ''} ${isCollapsed ? styles.collapsed : ''} ${isOpen ? styles.opened : ''}`}>
            {external ? (
                <a href={to} target="_blank" rel="noopener noreferrer" className={styles.menuItemContainer}>
                    {MenuItemContent}
                </a>
            ) : (
                <Link to={to} className={styles.menuItemContainer} onClick={toggleSubmenu}>
                    {MenuItemContent}
                </Link>
            )}
            {hasSubmenuItems && isOpen && !isCollapsed && (
                <div className={styles.submenuContainer}>
                    {submenuItems.map((submenuItem, index) => (
                        <SubmenuItem 
                            key={index}
                            label={submenuItem.label} 
                            to={submenuItem.to}
                            isActive={submenuItem.isActive}
                        />
                    ))}
                </div>
            )}

            { isCollapsed && submenuClone}
        </div>
    );
};

MenuItem.propTypes = {
    label: PropTypes.string.isRequired,
    to: PropTypes.string,
    isActive: PropTypes.bool,
    isBeta: PropTypes.bool,
    submenuItems: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
        isActive: PropTypes.bool,
    })),
    isCollapsed: PropTypes.bool,
    external: PropTypes.bool,
};

MenuItem.defaultProps = {
    isActive: false,
    isBeta: false,
    submenuItems: undefined,
    isCollapsed: false,
    external: false,
};




// import React, { useState, useRef } from 'react';
// import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
// import styles from './MenuItem.module.css'; 

// import { ChevronDown, ChevronUp } from "../../../icons";

// import { SubmenuItem } from '../SubmenuItem'

// import { useOutsideClick } from '../../../hooks/useOutsideClick'

// export const MenuItem = ({ icon, label, to, isActive, isBeta, submenuItems, isCollapsed, external }) => {

//     const [isOpen, setIsOpen] = useState(false);

//     const menuItemRef = useRef(null);

//     useOutsideClick(menuItemRef, () => {
//         if (isCollapsed && isOpen) {
//             setIsOpen(false);
//         }
//     });

//     const getIconComponent = (icon) => {
//         return React.cloneElement(icon, { className: styles.icon });
//     };

//     const hasSubmenuItems = submenuItems && submenuItems.length > 0;

//     const toggleSubmenu = (e) => {
//         const hasSubmenuItems = submenuItems && submenuItems.length > 0;
//         if (hasSubmenuItems) {
//             e.preventDefault(); // Prevents the default link behavior
//             setIsOpen(!isOpen);
//         }
//     };

//     return (
//         <div ref={menuItemRef} className={`${styles.container} ${isActive ? styles.active : ''} ${isCollapsed ? styles.collapsed : ''} ${isOpen ? styles.opened : ''}`}>

//             {
//                 external
//                 ?
//                 <a className={styles.menuItemContainer} href={to} target="_blank" rel="noopener noreferrer">
//                     <div className={styles.menuItem}>
//                         { icon && getIconComponent(icon)}
//                         <div className={styles.menuItemTextWrapper}>
//                             <span>{label}</span> {isBeta && <div className={styles.beta}>beta</div>}   
//                         </div>                           
//                     </div>
//                 </a>      
//                 :
//                 <Link className={styles.menuItemContainer} to={to} onClick={toggleSubmenu} >
//                     <div className={styles.menuItem}>
//                         { icon && getIconComponent(icon)}
//                         <div className={styles.menuItemTextWrapper}>
//                             <span>{label}</span> {isBeta && <div className={styles.beta}>beta</div>}   
//                         </div>                           
//                     </div>
//                     {
//                         hasSubmenuItems &&
//                         <>
//                             {isOpen ? <ChevronUp /> : <ChevronDown />}
//                         </>
//                     }
//                 </Link>                          
//             }

//             {
//                 hasSubmenuItems &&
//                 <div className={styles.submenuContainer}>
//                     {submenuItems.map((submenuItem, index) => (
//                         <SubmenuItem 
//                             key={index}
//                             label={submenuItem.label} 
//                             to={submenuItem.to}
//                             isActive={submenuItem.isActive}
//                         />
//                     ))}                
//                 </div>                  
//             }    
//         </div>
//     );
// };

// MenuItem.propTypes = {
//     label: PropTypes.string.isRequired,
//     to: PropTypes.string.isRequired,
//     isActive: PropTypes.bool,
//     isBeta: PropTypes.bool,
//     submenuItems: PropTypes.arrayOf(PropTypes.shape({
//         label: PropTypes.string.isRequired,
//         to: PropTypes.string.isRequired,
//         isActive: PropTypes.bool,
//     })),
//     isCollapsed: PropTypes.bool,
//     external: PropTypes.bool,
// };

// MenuItem.defaultProps = {
//     label: 'Menu Item',
//     to: '/',
//     isActive: false,
//     isBeta: false,
//     submenuItems: undefined,
//     isCollapsed: false,
//     external: false
// };


            // <Link className={styles.menuItemContainer} to={to} onClick={toggleSubmenu} >
            //     <div className={styles.menuItem}>
            //         { icon && getIconComponent(icon)}
            //         <div className={styles.menuItemTextWrapper}>
            //             <span>{label}</span> {isBeta && <div className={styles.beta}>beta</div>}   
            //         </div>                           
            //     </div>
            //     {
            //         hasSubmenuItems &&
            //         <>
            //             {isOpen ? <ChevronUp /> : <ChevronDown />}
            //         </>
            //     }
            // </Link>   