import React from 'react';
import PropTypes from 'prop-types';
import style from "pages/StatisticsPage/components/Chart/Tooltip/style.module.css";
import {typeToColor} from "pages/StatisticsPage/PlayerTab/config";
import classNames from "classnames";
import {playerUsageByIdToName} from "pages/StatisticsPage/PlayerTab/config";


function PlayerTooltipContent(props) {
  const {
    data,
    id: singleVisibleId,
    singleRow,
    keys,
  } = props;

  return (
    <table className="standardTable">
      <thead>
        <tr>
          <th>Type</th>
          <th className={style.bandwidthColumn}>Quantity</th>
        </tr>
      </thead>
      <tbody>
      { keys.map((id) => {
        if (singleRow && id !== singleVisibleId) {
          return null;
        }
        return (
          <tr key={id}>
            <td className={ classNames(style.cell, style.mainCell) }>
              <div className={style.colorSample} style={{ 'backgroundColor': typeToColor[id] }}></div>
              <span>
                { playerUsageByIdToName[id] }
              </span>
            </td>
            <td className={style.cell}>
              { data[id] }
            </td>
          </tr>
        )
      }) }
      </tbody>
    </table>
  )
}

PlayerTooltipContent.propTypes = {
  keys: PropTypes.arrayOf(PropTypes.string),
  singleRow: PropTypes.bool,
  id: PropTypes.string,
  data: PropTypes.object,
};

export default PlayerTooltipContent;