import PropTypes from "prop-types";
import React from "react";

export const Rename2 = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M6.59998 2.00002C6.59998 1.66865 6.33135 1.40002 5.99997 1.40002C5.66859 1.40002 5.39996 1.66865 5.39996 2.00002V3.57214H4.49995C2.78784 3.57214 1.3999 4.96005 1.3999 6.67212V9.67211C1.3999 11.3842 2.78784 12.7721 4.49995 12.7721H5.39996V14C5.39996 14.3313 5.66859 14.6 5.99997 14.6C6.33135 14.6 6.59998 14.3313 6.59998 14V12.1721V4.17213V2.00002ZM5.39996 4.77213H4.49995C3.45059 4.77213 2.59992 5.62279 2.59992 6.67212V9.67211C2.59992 10.7214 3.45059 11.5721 4.49995 11.5721H5.39996V4.77213ZM7.9 4.17213C7.9 3.84076 8.16863 3.57214 8.50001 3.57214H11.5001C13.2122 3.57214 14.6001 4.96005 14.6001 6.67212V9.67211C14.6001 11.3842 13.2122 12.7721 11.5001 12.7721H8.50001C8.16863 12.7721 7.9 12.5035 7.9 12.1721C7.9 11.8407 8.16863 11.5721 8.50001 11.5721H11.5001C12.5494 11.5721 13.4001 10.7214 13.4001 9.67211V6.67212C13.4001 5.62279 12.5494 4.77213 11.5001 4.77213H8.50001C8.16863 4.77213 7.9 4.5035 7.9 4.17213ZM11.7017 5.47303H9.48882C9.21268 5.47303 8.98882 5.69689 8.98882 5.97303V10.3966C8.98882 10.6727 9.21268 10.8966 9.48882 10.8966H11.7017C12.254 10.8966 12.7017 10.4488 12.7017 9.89657V6.47303C12.7017 5.92074 12.254 5.47303 11.7017 5.47303Z" fill="currentColor"/>
    </svg>    
  );
};

Rename2.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Rename2.defaultProps = {
  color: 'currentColor', // Or any default color
};
