import React, {useMemo, useState} from 'react';
import useDataLoadingStatus from "hooks/useDataLoadingStatus";
import {getStorageAccessKeyList} from "api/qencode-account-api";
import {columnIds, modals} from "./config";
import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import Table from "components/Table";
import Pagination from "components/Table/Pagination";
import MoreActions from "pages/MediaStorage/AccessKeysPage/MoreActions";
import DateFormatted from "components/DateFormatted";
import NoData from "pages/MediaStorage/AccessKeysPage/NoData";
import pageStyle from './style.module.css';
import KeyGenerator from "pages/MediaStorage/AccessKeysPage/KeyGenerator";
import RenameModal from "pages/MediaStorage/AccessKeysPage/RenameModal";
import RegenerateModal from "pages/MediaStorage/AccessKeysPage/RegenerateModal";
import DeleteModal from "pages/MediaStorage/AccessKeysPage/DeleteModal";
import KeyCell from "pages/MediaStorage/AccessKeysPage/KeyCell";
import useSecret from "pages/MediaStorage/AccessKeysPage/useSecret";
import TableWrapper from "components/TableWrapper";

function AccessKeysPage() {
  const loadingStatus = useDataLoadingStatus( getStorageAccessKeyList );
  const {
    data,
    refresh,
  } = loadingStatus;
  
  const [sorting, setSorting] = useState([{
    id: columnIds.dateCreated,
    desc: true,
  }]);
  const {
    dataWithSecret,
    getSecret,
    handleAfterGenerate,
    handleAfterRegenerate,
    handleDeleteKey,
  } = useSecret({
    data,
    setSorting,
  });
  const [openedModal, setOpenedModal] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [selectedAccessKey, setSelectedAccessKey] = useState();
  const columns = useMemo( () => [
    {
      id: columnIds.name,
      accessorKey: 'name',
      header: () => <span>Name</span>,
      sortingFn: 'text',
      cell: (info) => {
        const {
          secret,
          isRegenerated,
        } = getSecret(
          info,
        );
        return (
          <div className={pageStyle.keyName}>
            <span className={pageStyle.cellLimited}>{info.getValue()}</span>
            { (secret && !isRegenerated) ? <span className={pageStyle.tag}>New</span> : null}
          </div>
        )
      },
    },
    {
      id: columnIds.key,
      accessorKey: 'access_id',
      header: () => <span>Key</span>,
      enableSorting: false,
      cell: (info) => {
        const {secret} = getSecret(info);
        
        return (
          <KeyCell
            rawKey={info.getValue()}
            secret={secret}
          />
        )
      },
    },
    {
      id: columnIds.dateCreated,
      accessorKey: 'create_datetime',
      header: () => <span>Date Created</span>,
      sortingFn: 'datetime',
      cell: (info) => {
        return (
        <DateFormatted date={ info.getValue() }/>
        )
      },
    },
    {
      id: columnIds.moreActions,
      enableSorting: false,
      cell: (info) => (
        <MoreActions
          handleOpenModal={handleOpenModal}
          accessKeyProp={ info.row.original }
        />
      ),
    }
  ], [getSecret]);
  
  function handleOpenModal(accessKey, modal) {
    setOpenedModal(modal);
    setSelectedAccessKey(accessKey);
  }
  
  function handleCancel() {
    setOpenedModal();
  }
  
  const table = useReactTable({
    data: dataWithSecret,
    columns,
    getCoreRowModel: getCoreRowModel(),
    /* Sorting */
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination,
    },
  });
  
  return (
    <TableWrapper>
      <KeyGenerator onSuccess={handleAfterGenerate} />
      <Table
        { ...loadingStatus }
        table={table}
        keepShowingContentOnLoad
        getEmptyComponent={() => <NoData onSuccess={handleAfterGenerate} />}
        rowClass={pageStyle.accessKeyRow}
        columnIdToClassName={{
          [columnIds.name]: pageStyle.nameColumn,
          [columnIds.dateCreated]: pageStyle.date,
          [columnIds.moreActions]: pageStyle.moreActionsColumn,
        }}
      />
      <Pagination table={table}/>
      <RenameModal
        open={ openedModal === modals.rename }
        handleCancel={handleCancel}
        id={selectedAccessKey?.['access_id']}
        onSuccessfulFinish={refresh}
      />
      <RegenerateModal
        open={ openedModal === modals.regenerate }
        handleCancel={handleCancel}
        id={selectedAccessKey?.['access_id']}
        name={selectedAccessKey?.['name']}
        onSuccessfulFinish={handleAfterRegenerate}
      />
      <DeleteModal
        open={ openedModal === modals.delete }
        handleCancel={handleCancel}
        accessKeyObject={selectedAccessKey}
        onSuccessfulFinish={(key) => {
          handleDeleteKey(key);
          refresh();
        }}
      />
    </TableWrapper>
  );
}

AccessKeysPage.propTypes = {};

export default AccessKeysPage;