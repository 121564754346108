import React, {useState} from "react";
import {InputField} from "components/InputField";
import { LookingGlass} from "icons";
import style from './style.module.css';
import OptionList from "components/OptionList";
import PropTypes from "prop-types";

function MultiSelectWithSearch({ options, handleCheck, nothingFoundText }) {
  const [search, setSearch] = useState('');
  let searchedOptions = options;
  if (search) {
    searchedOptions = options.filter(({ label }) => label?.toLowerCase()?.includes( search.toLowerCase().trim() ))
  }
  
  function onSearchChange(searchString) {
    setSearch(searchString ?? '');
  }
  
  return (
    <>
      <InputField
        className={ style.search }
        placeholder="Search..."
        icon={ <LookingGlass/> }
        onChange={ (event) => onSearchChange(String(event.target.value)) }
        inputText={search ?? ''}
      />
      <div className={ style.wrapper }>
        { searchedOptions.length === 0 ? (
          <div className={ style.nothingFound }>
            <p className={style.nothingFoundText}>{ nothingFoundText }</p>
          </div>
        ) : (
          <OptionList
            options={searchedOptions}
            handleCheck={ handleCheck }
          />
        ) }
      </div>
    </>
  );
}

MultiSelectWithSearch.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
  })).isRequired,
  handleCheck: PropTypes.func.isRequired,
  nothingFoundText: PropTypes.string.isRequired,
};

export default MultiSelectWithSearch;