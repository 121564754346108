// has error on downloading - http://localhost:3000/my_encodings/e732d0f735261fdd8a09c90da4d4c43d
// no errors completed - http://localhost:3000/my_encodings/be462fdabff6e5fccb777cd4db4d3313

import React, { 
    // useState, 
    useEffect, 
    // useCallback, 
    useRef 
} from 'react';

import { highlightJson } from 'helpers/highlightJson'

import { CopyButton } from 'components/CopyButton'

import styles from './index.module.css';

export const Payload = ({payloadData}) => {
    const codeRef = useRef(null);
    
    useEffect(() => {
        if (codeRef.current) {
            // If payloadData is an object, highlight JSON. If it's a string, just display it as is.
            const highlightedData = typeof payloadData === 'string'
                ? payloadData
                : highlightJson(payloadData, false);

            codeRef.current.innerHTML = highlightedData;
        }
    }, [payloadData]);    


    // const payloadDataJson = JSON.stringify(payloadData, null, 2);

    // Prepare content to copy based on the type of payloadData
    const payloadDataJson = typeof payloadData === 'string'
        ? payloadData
        : JSON.stringify(payloadData, null, 2);    

    return (
        <div className={styles.inputWrapper}>
            <div className={styles.inputParamsContainer}>
                <pre className={styles.json}>
                    <code ref={codeRef}></code>
                </pre>    
            </div>
            <div className={styles.inputParamsActions}>
                <CopyButton contentToCopy={payloadDataJson} />      
            </div>        
        </div>
    );
};

