
const startYear = 2017;
const currentYear = (new Date()).getFullYear();

export const companyYears = []

for (let year = currentYear; year >= startYear;  year -= 1) {
  companyYears.push(year);
}

