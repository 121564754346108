export default function getResolutionReadable(format) {
  switch (format?.output) {
    case 'advanced_hls':
    case 'advanced_dash':
    case 'webm-dash': {
      const { stream } = format;
      if (Array.isArray(stream) && stream.length > 0) {
        return stream.map(({ height }) => height)
          .sort((a, b) => b - a); /* Descending. */
      }
      return [];
    }
    case 'mp4':
    case 'webm': {
      if (format.resolution) {
        return format.resolution;
      }
      if (format.height) {
        return format.height;
      }
      if (typeof format.size === 'string') {
        const [, num2] = format.size.split('x');
        return num2;
      }
      if (format.width) {
        return format.width;
      }
      return 'Original';
    }
    case 'thumbnail':
    case 'thumbnails': {
      if (format.resolution) {
        return format.resolution;
      }
      if (format.height) {
        return format.height;
      }
      if (format.width) {
        return format.width;
      }
      return 'Original';
    }
    default:
      return '';
  }
}