import React, { useState, useRef } from 'react';
import { Checkbox } from './Checkbox';

const ItemList = () => {
  const [items, setItems] = useState([
    { id: 1, checked: false },
    { id: 2, checked: false },
    { id: 3, checked: false },
  ]);

  const allChecked = items.every(item => item.checked);
  const someChecked = items.some(item => item.checked) && !allChecked;
  const setAll = (checked) => setItems(items.map(item => ({ ...item, checked })));

  const indeterminateCheckboxRef = useRef(null);

  return (
    <div style={{ margin: '10px 0'}}>
      <Checkbox 
        ref={indeterminateCheckboxRef}
        label="Select All" 
        checked={allChecked} 
        indeterminate={someChecked}
        onChange={(e) => setAll(e.target.checked)}
      />
      <div>
        {items.map((item) => (
          <div key={item.id} style={{paddingLeft: '20px', margin: '10px 0'}}>
            <Checkbox 
              label={`Item ${item.id}`} 
              checked={item.checked} 
              onChange={() => {
                const newItems = items.map(i => 
                  i.id === item.id ? { ...i, checked: !i.checked } : i
                );
                setItems(newItems);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ItemList;
