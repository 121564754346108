import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApi } from 'api/useApi';
import { useBackend } from 'api/useBackend';
import { useAWS } from 'api/useAWS';
import styles from './index.module.css';

import { BucketDetailsHeader } from 'components/BucketDetailsHeader';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { OptionsMenu } from "components/OptionsMenu";

import { NoData } from 'components/NoData';
import { formatSize, formatDateTime } from 'helpers/formatting';
import { Button } from 'components/Button';
import { Select } from 'components/Select'
import { 
    Settings, FolderAlt, VideoFile, Upload, FolderAdd, 
    Delete as DeleteIcon, Copy2 as CopyIcon,
    Download as DownloadIcon, Rename2 as RenameIcon, Move as MoveIcon, Duplicate as DuplicateIcon 
} from 'icons';

import { MoveToModal, NotMovedFilesModal, DuplicateModal, RenameModal, NewFolderModal, DeleteModal, UploadModal } from './Modals'

import { ReactComponent as Folder2 } from 'assets/images/Folder2.svg';

// table related
import Pagination from "components/Table/Pagination";
import {getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable, getPaginationRowModel} from "@tanstack/react-table";
import globalFilterFn from "components/Table/columnFilters/exactCaseInsensitiveFilter";
import Table from "components/Table";
import Search from "components/Search";
import useSkipper from "hooks/useSkipper";

// http://localhost:3000/storage/list/bucket?region=eu-central&name=few-files-in-bucket

import {CalculateSizeButton} from './CalculateSizeButton'

import utcStringToDate from "helpers/utcStringToDate";
import BucketSettingsModal from "pages/MediaStorage/BucketSettingsModal";
import DeleteBucketModal from "pages/MediaStorage/DeleteBucketModal";

export const BucketDetailsPage = () => {
    const { getBucketInfo } = useApi();
    const { getS3config } = useBackend();
    const { 
        getItemsForQencodeBucket, 
    } = useAWS();
    const location = useLocation();
    const navigate = useNavigate();

    // load based on path
    const [region, setRegion] = useState(null);
    const [bucketName, setBucketName] = useState(null);
    const [path, setPath] = useState(''); // Add state for path parameter    

    // Bucket and Config Data
    const [loadingCurrentBucket, setLoadingCurrentBucket] = useState(true);
    const [bucketError, setBucketError] = useState(null);
    const [currentBucket, setCurrentBucket] = useState(null);

    const [loadingS3config, setLoadingS3config] = useState(true);
    const [configError, setConfigError] = useState(null);
    const [configData, setConfigData] = useState({});

    // Items in the bucket
    const [currentItems, setCurrentItems] = useState([]);
    const [loadingCurrentItems, setLoadingCurrentItems] = useState(true);
    const [currentItemsLoadedSuccessfully, setCurrentItemsLoadedSuccessfully] = useState(false);
    const [currentItemsError, setCurrentItemsError] = useState(null);
    const [breadcrumbPath, setBreadcrumbPath] = useState([]);

    const [rowSelection, setRowSelection] = useState({})


    // Modals 
    // delete items
    const [deleteItems, setDeleteItems] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    // new folder
    // in new folder form, reflects current prefix - opened folder
    const [locationName, setLocationName] = useState('');    
    const [isNewFolderModalOpen, setNewFolderModalOpen] = useState(false);

    // rename
    const [renameItem, setRenameItem] = useState(null);
    const [isRenameModalOpen, setRenameModalOpen] = useState(false);

    // move to
    const [isMoveToModalOpen, setMoveToModalOpen] = useState(false);
    const [moveItems, setMoveItems] = useState([]);
    const [isNotMovedFilesModalOpen, setNotMovedFilesModalOpen] = useState(false);
    const [notMovedFiles, setNotMovedFiles] = useState([]);

    // duplicate
    const [isDuplicateModalOpen, setDuplicateModalOpen] = useState(false);
    const [duplicateItem, setDuplicateItem] = useState(null);

    // upload
    const [isUploadModalOpen, setUploadModalOpen] = useState(false);
    // const [hasIncompleteUploads, setHasIncompleteUploads] = useState(false);
    // const [isCancelUploadModalOpen, setCancelUploadModalOpen] = useState(false);

    // imported from buckets list
    // settings modal
    const [isBucketSettingsModalOpen, setBucketSettingsModalOpen] = useState(false);
    // delete modal
    const [isDeleteBucketModalOpen, setDeleteBucketModalOpen] = useState(false);
  
    const fetchcurrentBucket = useCallback(async (region, bucketName) => {
        try {
            setLoadingCurrentBucket(true);
            const { success, data, detail } = await getBucketInfo(region, bucketName);
            if (success && data) {
                setCurrentBucket(data);
                setBucketError(null);
                // setBreadcrumbPath([bucketName])
            } else {
                setBucketError(detail || "Failed to get bucket data");
            }
        } catch (error) {
            setBucketError(error.message);
        } finally {
            setLoadingCurrentBucket(false);
        }
    }, [getBucketInfo]);

    const fetchConfigData = useCallback(async () => {
        try {
            setLoadingS3config(true);
            const { success, data, detail } = await getS3config();
            if (success && data) {
                setConfigData(data);
                setConfigError(null);
            } else {
                setConfigError(detail || "Failed to get S3 config data");
            }
        } catch (error) {
            setConfigError(error.message);
        } finally {
            setLoadingS3config(false);
        }
    }, [getS3config]);

    const fetchItems = useCallback(async(bucketName, config, prefix = '') => {

        console.log({bucketName, config, prefix})

        // Set breadcrumbs based on the prefix
        //console.log("prefix: ", prefix)
        const breadcrumbSegments = prefix.split('/').filter(segment => segment !== '');
        setBreadcrumbPath(breadcrumbSegments);      

        // when creating new folder we use this
        setLocationName(prefix)

        // reset selection
        setRowSelection({})

        // clear items list
        setCurrentItems([])

        setLoadingCurrentItems(true)
        setCurrentItemsLoadedSuccessfully(false)

        const { success, items, error } = await getItemsForQencodeBucket(bucketName, config, prefix);
        if (success) {
            console.log("items: ", items)
            setCurrentItems(items);
            setCurrentItemsLoadedSuccessfully(true);
            setCurrentItemsError(null);
            setLoadingCurrentItems(false)        
        } else {
            setCurrentItems([]);
            setCurrentItemsError(error);
            setLoadingCurrentItems(false)
        }   
    }, [getItemsForQencodeBucket]); 

    const updatePathInUrl = useCallback((newPath) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('path', newPath);
        // Ensure newPath is URL-encoded
        //queryParams.set('path', encodeURIComponent(newPath));        

        // Remove the 'item' parameter from the URL in case page was initially loaded with it
        queryParams.delete('item');
        // and reset filter
        setGlobalFilterItems('')

        navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
    }, [location, navigate]);
    
    const onFolderCellClick = useCallback((item) => {
        if (item.type === 'folder') {
            updatePathInUrl(item.name); // Update path in the URL  
        }
    }, [updatePathInUrl]);        


    // Function to handle copying the URL to the clipboard
    const handleCopyUrl = (url) => {
        if (!url) {
            console.warn("No URL provided to copy.");
            return;
        }

        navigator.clipboard.writeText(url)
            .then(() => {
                console.log('URL copied to clipboard:', url);
            })
            .catch(err => {
                console.error('Failed to copy URL:', err);
            });
    };

    // Function to handle downloading the file
    const handleDownload = (url) => {
        if (!url) {
            console.warn("No URL provided for download.");
            return;
        }

        console.log("Download: ", url);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', url.split('/').pop()); // Use the file name from the URL

        // For certain URLs, ensure it opens in a new tab
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };


    // bucket items table
    const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });    
    const [globalFilterItems, setGlobalFilterItems] = useState('');
    const [sortingItems, setSortingItems] = useState([]);
    const columnsItems = React.useMemo(() => [
        {
            id: '1',
            accessorKey: 'displayName',
            header: () => <span>Name</span>,
            cell: (info) => {
              return (
                <>
                    {
                        info.row.original?.type && info.row.original?.type === "folder" 
                        ?
                        <div className='cellContainer cellClickable cellFullWidth' onClick={() => onFolderCellClick(info.row.original)}> 
                            <FolderAlt className={styles.bucketItemIcon} />
                            <div className='cellLimited cellHighlighted cellFullWidth'>
                                { info.getValue() }
                            </div>
                        </div>        
                        :
                        <div className='cellContainer'> 
                            <VideoFile className={styles.bucketItemIcon} />
                            <div className='cellLimited cellHighlighted cellFullWidth'>
                                { info.getValue() }
                            </div>
                        </div>                                        
                    }
                </>
              );
            },
          }, 
        {
            id: '2',
            accessorKey: 'type',
            header: () => <span>type</span>,
            cell: (info) => (
                <div className='cellContainer cellCapitilized'>
                    { info.getValue() }
                </div>
            )
        },
        {
            id: '3',
            accessorKey: 'size',
            header: () => <span>size</span>,
            cell: (info) => (
              <div className='cellContainer'>
                {
                    info.getValue()
                    ? formatSize(info.getValue(), 'bytes') 
                    : 
                    <CalculateSizeButton
                        info={info}
                        bucketName={currentBucket.name}
                        config={{
                            access_id: configData.access_id,
                            secret_key: configData.secret_key,
                            region: currentBucket.region,
                        }}
                    />                
                }
              </div>
            )              
        },     
        {
            id: '4',
            accessorKey: 'lastModified',
            header: () => <span>Last Modified</span>,
            cell: (info) => (
                <div className='cellContainer'>{ formatDateTime(info.getValue()) }</div> 
            )        
        },         
        // {
        //     id: '5',
        //     enableSorting: false,
        //     cell: ({row}) => (
        //         <OptionsMenu
        //             menuItems={
        //                 [
        //                     {
        //                         danger: true,
        //                         text: 'Delete',
        //                         icon: <DeleteIcon label="Delete"/>,
        //                         // onClick: () => handleDeleteItem(row.original),
        //                         onClick: () => {
        //                             setDeleteItems([row.original]); // Set state for items to delete
        //                             setDeleteModalOpen(true); // Open the confirmation mod 
        //                         },
        //                     }
        //                 ]
        //             }
        //         />                
        //     )
        // },            
        {
            id: '5',
            enableSorting: false,
            cell: ({ row }) => (
                <OptionsMenu
                    menuItems={
                        row.original.type === 'file'
                            ? [
                                {
                                    text: 'Copy URL',
                                    icon: <CopyIcon label="Copy URL" />,
                                    onClick: () => handleCopyUrl(row.original.sourceUrl),
                                },
                                {
                                    text: 'Download',
                                    icon: <DownloadIcon label="Download" />,
                                    onClick: () => handleDownload(row.original.sourceUrl),
                                },                
                                {
                                    text: 'Rename',
                                    icon: <RenameIcon label="Rename" />, // You can replace RenameIcon with an appropriate icon
                                    onClick: () => {
                                        setRenameItem(row.original); // Set the item to be renamed
                                        setRenameModalOpen(true)
                                    },
                                },    
                                {
                                    text: 'Duplicate',
                                    icon: <DuplicateIcon label="Duplicate" />,
                                    onClick: () => {
                                        setDuplicateItem(row.original); // Set state for item to duplicate
                                        setDuplicateModalOpen(true); // Open modal
                                    },
                                },                                    
                                {
                                    text: 'Move',
                                    icon: <MoveIcon label="Move" />,
                                    onClick: () => {
                                        setMoveItems([row.original]); // Set state for items to move
                                        setMoveToModalOpen(true); // Open modal
                                    },
                                },                                                                          
                                {
                                    danger: true,
                                    text: 'Delete',
                                    icon: <DeleteIcon label="Delete" />,
                                    onClick: () => {
                                        setDeleteItems([row.original]); // Set state for items to delete
                                        setDeleteModalOpen(true); // Open the confirmation modal
                                    },
                                }
                            ]
                            : [
                                {
                                    danger: true,
                                    text: 'Delete',
                                    icon: <DeleteIcon label="Delete" />,
                                    onClick: () => {
                                        setDeleteItems([row.original]); // Set state for items to delete
                                        setDeleteModalOpen(true); // Open the confirmation modal
                                    },
                                }
                            ]
                    }
                />
            )
        }        
    ],[onFolderCellClick, configData, currentBucket]);
    const tableItems = useReactTable({
        data: currentItems,
        columns: columnsItems,
        getCoreRowModel: getCoreRowModel(),
        /* Sorting */
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSortingItems,
        /* Filtering */
        globalFilterFn,
        getFilteredRowModel: getFilteredRowModel(),
        onGlobalFilterChange: setGlobalFilterItems,
        /* Pagination */
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,    
        /* Row selection */
        enableRowSelection: true, //enable row selection for all rows
        onRowSelectionChange: setRowSelection,    
        state: {
            sorting: sortingItems,
            globalFilter: globalFilterItems,
            pagination,
            rowSelection,
        },
        autoResetPageIndex,
        meta: {            
            updateData: (rowIndex, columnId, value) => {
                // Skip page index reset until after next rerender
                skipAutoResetPageIndex()
                setCurrentItems(old =>
                    old.map((row, index) => {
                        if (index === rowIndex) {
                            return {
                                ...old[rowIndex],
                                [columnId]: value,
                            };
                        }
                        return row;
                    })
                );
            },
        },        
    })

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const regionParam = queryParams.get('region');
        const bucketNameParam = queryParams.get('name');
        const pathParam = queryParams.get('path'); // Capture the path parameter
        const itemParam = queryParams.get('item'); // Capture the item parameter
        //const decodedPath = pathParam ? decodeURIComponent(pathParam) : ''; // Decode it
    
        // Only update if region or bucketName changed
        if (regionParam !== region || bucketNameParam !== bucketName) {
            setRegion(regionParam);
            setBucketName(bucketNameParam);
    
            if (regionParam && bucketNameParam) {
                fetchcurrentBucket(regionParam, bucketNameParam);
                fetchConfigData();
            } else {
                setBucketError('Region or bucket name is missing in the URL');
                setLoadingCurrentBucket(false);
            }
        }
    
        setPath(pathParam || ''); // Update path state, always do this regardless of region/bucketName change
        //setPath(decodedPath || ''); // Update path state, always do this regardless of region/bucketName change

        // to select item specified in path
        if(itemParam){
            setGlobalFilterItems(itemParam)
        }
    
    }, [location.search, region, bucketName, fetchcurrentBucket, fetchConfigData]);
    
    useEffect(() => {
        if (currentBucket && configData.access_id) {
            const config = {
                access_id: configData.access_id,
                secret_key: configData.secret_key,
                region: currentBucket.region,
            };
    
            // If the path parameter is missing, set prefix to an empty string
            const prefix = path ? path : '';
            fetchItems(currentBucket.name, config, prefix);
        }
    }, [path, currentBucket, configData, fetchItems]); // Triggered only when path, currentBucket, or configData changes
      

    const handleBackToBucketsClick = () => {
        navigate('/storage/list');
    };

    const handleShowBucketSettingsModal = () => {
        console.log("Show bucket settings modal for: ", currentBucket);
        setBucketSettingsModalOpen(true)
    };

    const onBreadcrumbClick = useCallback(async (index) => {
        // Reset search filter
        //setGlobalFilterItems('');
    
        // Slice the path up to the clicked breadcrumb's index
        let newPath = breadcrumbPath.slice(0, index + 1).join('/');
    
        // Ensure the path ends with a /
        if (newPath && !newPath.endsWith('/')) {
            newPath += '/';
        }
    
        // Update the path in the URL without reloading the page
        updatePathInUrl(newPath);
    }, [breadcrumbPath, updatePathInUrl]);    
    

    // reload items in current folder
    const reloadItems = () => {
        // Prepare the config for S3 operations
        const config = {
            access_id: configData.access_id,
            secret_key: configData.secret_key,
            region: currentBucket.region,
        };

        let path = breadcrumbPath.join('/')       

        //console.log("path: ", path)

        // Ensure the path ends with a /
        if (path && !path.endsWith('/')) {
            path += '/';
        }

        //console.log("breadcrumbPath.join('/'): ", breadcrumbPath.join('/'))

        // Fetch updated items to include the new folder
        // fetchItems(bucketName, config, breadcrumbPath.join('/'));
        fetchItems(bucketName, config, path);
        //closeMoveToModal();  // Close the modal      
    }

    // delete
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);   
    };       

    const onSuccessfullDelete = () => {
        reloadItems()
        closeDeleteModal()
    }      


    // new folder
    const showNewFolderModal = () => {
        setNewFolderModalOpen(true);
    }

    const closeNewFolderModal = () => {
        setNewFolderModalOpen(false);       
    };  

    const onSuccessfullNewFolder = () => {
        reloadItems()
        closeNewFolderModal()
    }       


    // rename file 
    const closeRenameModal = () => {
        setRenameModalOpen(false);       
    };  

    const onSuccessfullRename = () => {
        reloadItems()
        closeRenameModal()
    }       


    // move files
    const closeMoveToModal = () => {
        setMoveToModalOpen(false);

      

        // reset error
        // if (renamingError) {
        //     setRenamingError(null);
        // }        
    };      

    const onSuccessfullMove = (notMovedFiles) => {
        reloadItems()
        closeMoveToModal()

        // if some files were not moved show modal with list of those files
        if(notMovedFiles?.length > 0){
            console.log("notMovedFiles: ", notMovedFiles)
            setNotMovedFiles(notMovedFiles)
            setNotMovedFilesModalOpen(true)
        }
        
    }

    const closeNotMovedModal = () => {
        setNotMovedFilesModalOpen(false)
        setNotMovedFiles([])
    }

    // duplicate file
    const closeDuplicateModal = () => {
        setDuplicateModalOpen(false);       
    };      

    const onSuccessfullDuplicate = () => {
        reloadItems()
        closeDuplicateModal()
    }  
    
    // upload files
    const showUploadModal = () => {
        setUploadModalOpen(true);
    }

    const closeUploadModal = () => {
        reloadItems() // some items can aleady be loaded so need to reload
        setUploadModalOpen(false);       
    };      

    // const closeUploadModal = (hasUploadsInProgress) => {

    //     console.log("hasUploadsInProgress: ", hasUploadsInProgress)
    //     // there are not finished uploads in progress
    //     if(hasUploadsInProgress){

    //     } else {
    //         reloadItems() // some items can aleady be loaded so need to reload
    //         setUploadModalOpen(false);    
    //     }   
    // };          

    const onSuccessfullUpload = () => {
        console.log("Reload items")
        reloadItems()
        closeUploadModal()
    }      

    // multi
    const handleMultActions = (e) => {
        const {value} = e.target
     
        const selectedItems = tableItems.getSelectedRowModel().rows.map((tanstackRow) => tanstackRow.original)

        if(value === 'delete'){
            console.log("Delete items: ", selectedItems)

            // show modal window asking to confirm deleting items with list items

            // Show confirmation modal with the list of items to delete
            setDeleteItems(selectedItems); // Set state for items to delete
            setDeleteModalOpen(true); // Open the confirmation mod            

            // when delete confirm set state of confirm button in modal to state="disabled"

            // when all items deleted close modal and call fetchItems

        }

        if(value === 'move'){

            // Filter out folders, only keep files
            const filesToMove = selectedItems.filter(item => item.type === 'file');
            console.log("Move files: ", filesToMove)

            // Show confirmation modal with the list of items to move
            setMoveItems(filesToMove); // Set state for items to move
            setMoveToModalOpen(true); // Open modal

        }        
    }

    //console.log(tableItems.getSelectedRowModel().rows.map((tanstackRow) => tanstackRow.original));
    
    return (
        <div className={styles.container}>
            {loadingCurrentBucket || loadingS3config ? (
                <div>Loading...</div>
            ) : bucketError || configError ? (
                <div>Error: {bucketError || configError}</div>
            ) : (
                <>
                    <BucketDetailsHeader
                        bucket={currentBucket}
                        controls={
                            <Button icon={<Settings />} onClick={handleShowBucketSettingsModal} type="secondary">
                                Bucket Settings
                            </Button>
                        }
                        onBackClick={handleBackToBucketsClick}
                        size="lg"
                    />

                    <div className={styles.itemsBody}>

                        <div className={styles.itemsControls}>
                            <Breadcrumbs path={breadcrumbPath} onNavigate={onBreadcrumbClick} />

                            <div className={styles.itemsFiltersContainer}>

                                <Select                        
                                    variant='menu'
                                    dropdownAlignment="left"
                                    dropdownMinWidth="150px"
                                    placeholder='Actions'
                                    onChange={handleMultActions}
                                    options={[
                                        ...(tableItems.getSelectedRowModel().rows.map((tanstackRow) => tanstackRow.original).some(item => item.type === 'file') 
                                            ? [{ label: 'Move', value: 'move', icon: <MoveIcon /> }] 
                                            : []),
                                        { label: 'Delete', value: 'delete', icon: <DeleteIcon />, danger: true }
                                    ]} 
                                    disabled={!(tableItems.getSelectedRowModel().rows.map((tanstackRow) => tanstackRow.original) && tableItems.getSelectedRowModel().rows.map((tanstackRow) => tanstackRow.original).length > 0)}
                                />    

                                {/* {
                                    (tableItems.getSelectedRowModel().rows.map((tanstackRow) => tanstackRow.original) && tableItems.getSelectedRowModel().rows.map((tanstackRow) => tanstackRow.original).length > 0) &&
                                    <Select                        
                                        variant='menu'
                                        dropdownAlignment="left"
                                        dropdownMinWidth="150px"
                                        placeholder='Actions'
                                        onChange={handleMultActions}
                                        options={[
                                            ...(tableItems.getSelectedRowModel().rows.map((tanstackRow) => tanstackRow.original).some(item => item.type === 'file') 
                                                ? [{ label: 'Move', value: 'move', icon: <MoveIcon /> }] 
                                                : []),
                                            { label: 'Delete', value: 'delete', icon: <DeleteIcon />, danger: true }
                                        ]} 
                                    />                                     
                                }                                */}
                                <div className={styles.itemsSearchContainer}>
                                    <Search setGlobalFilter={setGlobalFilterItems} globalFilter={globalFilterItems}/> 
                                </div>                                
                                <div className={styles.itemsFiltersDelimeter}></div>
                                <div className={styles.itemsActionsContainer}>
                                    <Button 
                                        type="secondary" 
                                        icon={<FolderAdd color="var(--Gray-600, #67717B)" />} 
                                        onClick={showNewFolderModal}
                                    >
                                        Folder
                                    </Button>
                                    <Button onClick={showUploadModal} icon={<Upload />}>Upload</Button>
                                </div>
                            </div>
                        </div>

                        <Table
                            data={currentItems}
                            table={tableItems}
                            globalFilter={globalFilterItems}
                            resetFilter={() => setGlobalFilterItems('')}
                            loading={loadingCurrentItems}
                            loadedSuccessfully={currentItemsLoadedSuccessfully}
                            errorMessage={currentItemsError}
                            getEmptyComponent={() => <NoData
                                image={<Folder2 />}
                                title="This folder is empty"
                                description="Start by adding new files and folders"
                                footer={
                                    <div className={styles.noDataFooterBtns}>
                                        <Button 
                                            type="secondary" 
                                            icon={<FolderAdd color="var(--Gray-600, #67717B)" />} 
                                            onClick={showNewFolderModal}
                                        >
                                            Folder
                                        </Button>
                                        <Button onClick={showUploadModal} icon={<Upload />}>Upload</Button>
                                    </div>
                                }
                            />}
                            rowSelectionAllowed={true}
                            columnIdToClassName={{
                                "1": styles.tableNameCell,
                                "2": styles.tableTypeCell,
                                "3": styles.tableSizeCell,
                                "4": styles.tableDateCell,
                                "5": styles.tableOptionsMenuCell
                              }}
                        />     

                        <div className={styles.tablePaginationContainer}>
                            <Pagination table={ tableItems } />      
                        </div>
                                           
                    </div>

                </>
            )}


            {/* modals */}

            {/* Modal for showing deletion progress */}
            {
                isDeleteModalOpen && deleteItems &&
                <DeleteModal 
                    open={isDeleteModalOpen}
                    currentBucket={currentBucket}
                    configData={configData}
                    itemsToDelete={deleteItems}
                    handleCancel={closeDeleteModal}
                    onSuccessfulFinish={onSuccessfullDelete}
                />                
            }


            {/* Modal for creating new folder */}
            {
                isNewFolderModalOpen && currentItems &&
                <NewFolderModal 
                    open={isNewFolderModalOpen}
                    currentBucket={currentBucket}
                    configData={configData} 
                    locationName={locationName}
                    initialItems={currentItems}
                    handleCancel={closeNewFolderModal}
                    onSuccessfulFinish={onSuccessfullNewFolder}                    
                />
            }

            {/* Modal for renaming file */}
            {
                isRenameModalOpen && renameItem && currentItems &&
                <RenameModal 
                    open={isRenameModalOpen}
                    currentBucket={currentBucket}
                    configData={configData} 
                    itemToRename={renameItem}
                    initialItems={currentItems}
                    handleCancel={closeRenameModal}
                    onSuccessfulFinish={onSuccessfullRename}
                />                 
            }            

            {/* Modal for moving items - files */}
            {
                isMoveToModalOpen && moveItems && currentItems &&
                <MoveToModal 
                    open={isMoveToModalOpen}
                    currentBucket={currentBucket}
                    configData={configData}
                    itemsToMove={moveItems}
                    initialItems={currentItems}
                    initialPath={path}
                    handleCancel={closeMoveToModal}
                    onSuccessfulFinish={onSuccessfullMove}
                />                
            }

            {/* Modal to show files that were not moved */}
            {
                isNotMovedFilesModalOpen && notMovedFiles.length > 0 &&
                <NotMovedFilesModal 
                    open={isNotMovedFilesModalOpen}
                    files={notMovedFiles}
                    handleClose={closeNotMovedModal}
                />                
            }            
               

            {/* Modal for duplicating item - file */}
            {
                isDuplicateModalOpen && duplicateItem && currentItems &&
                <DuplicateModal 
                    open={isDuplicateModalOpen}
                    currentBucket={currentBucket}
                    configData={configData} 
                    itemToDuplicate={duplicateItem}
                    initialItems={currentItems}
                    handleCancel={closeDuplicateModal}
                    onSuccessfulFinish={onSuccessfullDuplicate}
                />                 
            }

            {/* Modal for uploading files */}
            {
                isUploadModalOpen && 
                <UploadModal 
                    open={isUploadModalOpen}
                    currentBucket={currentBucket}
                    configData={configData} 
                    locationName={locationName}
                    initialItems={currentItems}
                    handleCancel={closeUploadModal}
                    onSuccessfulFinish={onSuccessfullUpload}                    
                />
            }            

            {/* modals from buckets list */}
            {
                isBucketSettingsModalOpen &&
                <BucketSettingsModal
                    open={isBucketSettingsModalOpen}
                    handleClose={() => setBucketSettingsModalOpen(false)}
                    refreshTable={() => ()=>{}} // pass emmpty function as this param is req
                    bucket={{
                        ...currentBucket, // Spread the existing properties
                        created: utcStringToDate(currentBucket.created) // Replace `created` field with the result of `utcStringToDate` because BucketSettingsModal is expecting that field to be date not string
                    }}
                    key={`${currentBucket?.region}-${currentBucket?.name}`}
                    goToDeleteModal={() => {
                        setBucketSettingsModalOpen(false)
                        setDeleteBucketModalOpen(true)
                    }}
                />                
            }

            {
                isDeleteBucketModalOpen &&
                <DeleteBucketModal
                    open={isDeleteBucketModalOpen}
                    handleClose={() => setDeleteBucketModalOpen(false)}
                    onSuccessfulFinish={() => navigate('/storage/list')}
                    bucket={currentBucket}
                />                     
            }
       

        </div>
    );
};