import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useLocation, useNavigate, Link } from 'react-router-dom';

import Oauth2 from '../../components/Oauth2'

// import { validateFields } from './validation';
import { validateFields } from '../../helpers/validation';

import { Button } from '../../components/Button';
import { InputField } from '../../components/InputField'

import { Shield } from '../../icons/'

import { ReactComponent as Logo } from '../../assets/images/QencodeLogo.svg';

import styles from './index.module.css';

const LoginPage = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [validationErrors, setValidationErrors] = useState({});

    const [credentials, setCredentials] = useState({ email: '', password: '' });

    const [currentStep, setCurrentStep] = useState('loginUser'); // start with the first step

    const [token, setToken] = useState(undefined); // used for 2FA auth flow

    // for 2FA auth flow
    const [otpDigits, setOtpDigits] = useState(Array(6).fill(''));
    const inputRefs = useRef([...Array(6)].map(() => React.createRef()));

    const { 
        isAuthenticated, login, authError, 
        setAuthError,
        resetPassword,
        login2FA,
        setIsAuthenticated
    } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();

    // const from = location.state?.from?.pathname || '/dashboard';
    // Combine pathname and search query to get full path including query parameters
    const from = location.state?.from?.pathname + location.state?.from?.search || '/dashboard';

    useEffect(() => {
      if (isAuthenticated) {
        navigate(from); // Redirect if already authenticated
      }
    }, [isAuthenticated, navigate, from]);


    // Listen for login event across tabs
    // useEffect(() => {
    //     const handleStorageEvent = (event) => {
    //         if (event.key === 'Qencode_login') {
    //             // Refresh the page or redirect to dashboard
    //             window.location.reload();
    //         }
    //     };

    //     window.addEventListener('storage', handleStorageEvent);
    //     return () => {
    //         window.removeEventListener('storage', handleStorageEvent);
    //     };
    // }, []);    

    // Listen for login event across tabs
    // useEffect(() => {
    //     const handleStorageEvent = (event) => {
    //         if (event.key === 'Qencode_login') {
    //             setIsAuthenticated(true)
    //             navigate(from); // Redirect to dashboard or the previous page instead of reloading
    //         }
    //     };

    //     window.addEventListener('storage', handleStorageEvent);
    //     return () => {
    //         window.removeEventListener('storage', handleStorageEvent);
    //     };
    // }, [navigate, from, setIsAuthenticated]); // Ensure navigate and from are dependencies so they can be used in the effect    


    // Listen for login event across tabs with retries
    useEffect(() => {
        const channel = new BroadcastChannel('Qencode_channel');
        const maxRetries = 3;
        let retryCount = 0;

        const handleLoginMessage = async (event) => {
            if (event.data.type === 'login') {
                const intervalId = setInterval(() => {
                    // Check if cookies are set by checking token existence
                    if (document.cookie.includes('access_token')) {
                        setIsAuthenticated(true);
                        clearInterval(intervalId);
                        navigate(from);
                    } else if (retryCount >= maxRetries) {
                        clearInterval(intervalId);
                    } else {
                        retryCount += 1;
                    }
                }, 100); // Retry every 100ms
            }
        };

        channel.onmessage = handleLoginMessage;

        return () => {
            channel.close();
        };
    }, [navigate, from, setIsAuthenticated]);


    const handleLogin = async (e) => {
        e.preventDefault();

        // do input validation on the client side 1st
        const fieldsToValidate = ['password', 'email']; 
        const { isValid, errors } = validateFields(credentials, fieldsToValidate, 'login');
        setValidationErrors(errors); // Update the validation errors state
      
        if (!isValid) {
          console.log("Validation errors", errors);
          return; // Stop the submission if validation fails
        }      

        setIsSubmitting(true); // Disable the button
        const {email, password} = credentials;
        const success = await login({email, password});
        // success: true - when no 2FA 
        // success: {access_token: "fjefkjwl"} - when 2FA


        if (success === true) {
            const channel = new BroadcastChannel('Qencode_channel');
            channel.postMessage({ type: 'login' });
            channel.close();

            navigate(from); // Redirect to the URL from where the user was redirected
        } else {
            const {access_token} = success
            if(access_token){
                console.log("Login using 2FA")
                setToken(access_token)
                setCurrentStep("logn2FA")
            } else {
                // actually no need to show error here, it's in AuthContext
                //console.log("Handle login failure, show message, etc.")   
            }            
        }        

        setIsSubmitting(false); // Re-enable the button
    };

    const handle2FaLogin = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable the button

        const otp = otpDigits.join('');
        const success = await login2FA({code: otp, accessToken: token});

        if (success) {
            const channel = new BroadcastChannel('Qencode_channel');
            channel.postMessage({ type: 'login' });
            channel.close();

            navigate(from); // Redirect to the URL from where the user was redirected
        } else {
            // no need to log here, it's logged in AuthContext
            //console.log("Handle login with 2FA failure, show message, etc.")
        }      

        setIsSubmitting(false); // Re-enable the button
    };


    const handleInputChange = (e) => {
        // Reset the authError when the user starts typing
        if (authError) {
            setAuthError(null);
        }

        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
    };


    const handle2FaInputChange = (index) => (e) => {
        const newOtpDigits = [...otpDigits];
        newOtpDigits[index] = e.target.value;
        setOtpDigits(newOtpDigits);

        // Focus next input if value entered and not the last input
        if (e.target.value && index < 5) {
            inputRefs.current[index + 1].current.focus();
        }
    };

    useEffect(() => {
        // This function runs when the component mounts
        setAuthError(null); // Clear the login error state
        // Optionally, if you want to clear any signup errors when navigating to the login page:
        // setSignupError(null);
      
        // This function runs when the component unmounts
        return () => {
          setAuthError(null); // Clear the login error state again
          // Optionally, clear the signup errors again:
          // setSignupError(null);
        };
    }, [setAuthError, /* setSignupError */]);
      
    const handleRemindPassword = () => {
        setAuthError(null)
        setCurrentStep("remindPassword")
    }

    const handleSendEmail = async (e) => {
        e.preventDefault();

        // do input validation on the client side 1st
        const fieldsToValidate = ['email']; 
        const { isValid, errors } = validateFields(credentials, fieldsToValidate);
        setValidationErrors(errors); // Update the validation errors state
      
        if (!isValid) {
          console.log("Validation errors", errors);
          return; // Stop the submission if validation fails
        }  

        setIsSubmitting(true); // Disable the button
        const success = await resetPassword(credentials);
        if (success) {
            navigate(from); // Redirect to the URL from where the user was redirected
        } else {
            // show log in AuthContext
            //console.log("Handle reset password email failure, show message, etc.")
        }
        setIsSubmitting(false); // Re-enable the button
    };

    const handleCancelSendEmail = () => {
        // reset 
        setAuthError(null)
        setCredentials({ email: '', password: '' });
        // change step
        setCurrentStep("loginUser")
    }

    return (
        <div className={styles.container}>
            <Logo className={styles.logo} />

            {currentStep === 'loginUser' && (
                <div className={styles.authWindow}>

                    <div className={styles.title}>Log in to your account</div>

                    <Oauth2 />

                    <div className={styles.delimeterContainer}>
                        <div className={styles.delimeterLine}></div>
                        <div className={styles.delimeterText}>OR</div>
                        <div className={styles.delimeterLine}></div>
                    </div>

                    <div className={styles.form}>
                        <div className={styles.row}>
                            <InputField
                                inputText={credentials.email}
                                onChange={handleInputChange}
                                name="email"
                                placeholder="Work email"
                                size="lg"
                                // state={authError ? 'error' : 'default'}
                                // hint={authError ? authError : undefined}
                                state={validationErrors.email ? 'error' : 'default'}
                                hint={validationErrors.email ? validationErrors.email : undefined}
                            />    
                        </div>
                        <div className={styles.row}>             
                            <InputField
                                inputText={credentials.password}
                                onChange={handleInputChange}
                                name="password"
                                placeholder="Password"
                                size="lg"
                                type='password'
                                state={validationErrors.password ? 'error' : 'default'}
                                hint={validationErrors.password ? validationErrors.password : undefined}
                            />
                        </div>

                        <div className={styles.passReminder}>
                            <span onClick={handleRemindPassword}>Forgot your password?</span>
                        </div>

                        <div className={styles.formFooter}>
                            <Button onClick={handleLogin} className={styles.formFooterBtn} state={isSubmitting ? "disabled" : undefined} fullWidth={true} size='lg'>
                                Log in to Qencode
                            </Button>                               
                        </div>

                        {authError && <div className={styles.errorMessage}>{authError}</div>}
                 
                    </div>



                    <div className={styles.extraAuthMessage}>
                        Is your company new to Qencode? <Link to='/signup'>Sign up</Link>
                    </div>

                </div>
            )}

            {currentStep === 'remindPassword' && (
                <div className={styles.authWindow}>
                    <div className={styles.title}>Forgot Password?</div>

                    <div className={styles.form}>
                        <div className={styles.row}>
                            <InputField
                                inputText={credentials.email}
                                onChange={handleInputChange}
                                name="email"
                                placeholder="Enter your email"
                                size="lg"
                                // state={authError ? 'error' : 'default'}
                                // hint={authError ? authError : undefined}
                                // state={validationErrors.email ? 'error' : 'default'}
                                // hint={validationErrors.email ? validationErrors.email : undefined}
                                state={authError || validationErrors.email ? 'error' : 'default'}
                                hint={authError ? authError : validationErrors.email ? validationErrors.email : undefined}
                            />    
                        </div>


                        <div className={styles.formFooter}>
                            <Button onClick={handleSendEmail} className={styles.formFooterBtn} state={isSubmitting ? "disabled" : undefined} fullWidth={true} size='lg'>
                                Send
                            </Button>     
                            <Button onClick={handleCancelSendEmail} className={styles.formFooterBtn} type='secondary' fullWidth={true} size='lg'>
                                Cancel
                            </Button>                               
                        </div>
                 
                    </div>
                </div>
            )}

            {currentStep === 'logn2FA' && token && (
                <div className={styles.authWindow}>
                    <Shield className={styles.shieldIcon} />

                    <div className={styles.title}>Please enter 2FA code</div>
                    <div className={styles.info}>Please enter the 6-digit code from you authenticator app below</div>

                    <div className={`${styles.form} ${styles.form2FA}`}>

                        {/* 6 input fields for each digit */}
                        <div className={styles.row}>
                            {otpDigits.map((digit, index) => (
                                <InputField
                                    className={styles.digit2FA}
                                    key={index}
                                    inputText={digit}
                                    size="lg"
                                    onChange={handle2FaInputChange(index)}
                                    name={`digit-${index + 1}`}
                                    maxLength={1}
                                    ref={inputRefs.current[index]}
                                />
                            ))}   
                        </div>                        

                        <div className={styles.formFooter}>   
                            <Button onClick={handle2FaLogin} className={styles.formFooterBtn} fullWidth={true} size='lg'>
                                Log in
                            </Button>       
                        </div>

                        {authError && <div className={styles.errorMessage}>{authError}</div>}
                    </div>
                    
                </div>
            )} 

        </div>
    );
};

export default LoginPage;

