
export default function utcStringToDate(utcString) {
  if (typeof utcString === 'string') {
    const [year, month, day, hour, minute, second] = utcString.split(/\D/);
    return new Date(Date.UTC(
      parseInt(year),
      parseInt(month) - 1, // Month (zero-based)
      parseInt(day),
      parseInt(hour),
      parseInt(minute),
      parseInt(second)
    ));
  }
}

