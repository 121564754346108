import React from 'react';
import PropTypes from 'prop-types';
import styles from './TextArea.module.css';

import { Label } from '../Label'

export const TextArea = ({ className, inputText, label, size = "md", help, name, rows = 4, cols, disabled, readOnly, hint, error, placeholder, onChange }) => {


  
  return (
    <div className={`${styles.textArea} ${className} ${error ? styles.error : ''}`}>
      {label && <Label size={size} text={label} help={help} />}
      <textarea 
        placeholder={placeholder} 
        disabled={disabled} 
        readOnly={readOnly}
        name={name} 
        rows={rows} 
        cols={cols}
        value={inputText}
        onChange={onChange}
      />   
      { hint && <div className={styles.hint}>{hint}</div>}
    </div>
  );
};

TextArea.propTypes = {
  className: PropTypes.string,
  inputText: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  name: PropTypes.string,
  rows: PropTypes.number,
  cols: PropTypes.number,
  help: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  hint: PropTypes.string,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

TextArea.defaultProps = {
  className: '',
  inputText: '',
  label: '',
  size: 'md',
  name: '',
  rows: undefined,
  cols: undefined,
  help: false,
  disabled: false,
  readOnly: false,
  hint: '',
  error: false,
  placeholder: '',
  onChange: () => {},
};

