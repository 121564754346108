// Define the options

// output
export const outputOptions = [
    { value: 'mp4', label: 'MP4' },
    { value: 'webm', label: 'WebM' },
    { value: 'advanced_hls', label: 'HLS' },
    { value: 'advanced_dash', label: 'MPEG-DASH' },
    { value: 'mp3', label: 'MP3' },
    { value: 'thumbnail', label: 'Thumbnail' },
    { value: 'thumbnails', label: 'Thumbnails/VTT' },
    { value: 'repack', label: 'Repack' },
    { value: 'gif', label: 'GIF' },
    { value: 'hls_audio', label: 'HLS AUDIO' },
    { value: 'speech_to_text', label: 'Speech-to-Text' }
];

// video_codec
export const videoCodecOption = {
    mp4: [
        { value: 'libx264', label: 'H.264' },
        { value: 'libx265', label: 'H.265' },
        { value: 'libaom-av1', label: 'AV1' },
        { value: 'lcevc_h264', label: 'LCEVC_H264' },
        { value: 'lcevc_hevc', label: 'LCEVC_HEVC' },
    ],
    webm: [
        { value: 'libvpx', label: 'VP8' },
        { value: 'libvpx-vp9', label: 'VP9' },
    ],
    advanced_hls: [
        { value: 'libx264', label: 'H.264' },
        { value: 'libx265', label: 'H.265' },
        { value: 'lcevc_h264', label: 'LCEVC_H264' },
        { value: 'lcevc_hevc', label: 'LCEVC_HEVC' },
    ],
    advanced_dash: [
        { value: 'libx264', label: 'H.264' },
        { value: 'libx265', label: 'H.265' },
        { value: 'lcevc_h264', label: 'LCEVC_H264' },
        { value: 'lcevc_hevc', label: 'LCEVC_HEVC' },
    ],
    mp3: [
        { value: 'mp3', label: 'MP3' },
    ],
};

export const mp4CodecOptions = [
    { value: 'libx264', label: 'H.264' },
    { value: 'libx265', label: 'H.265' },
    { value: 'libaom-av1', label: 'AV1' },
    { value: 'lcevc_h264', label: 'LCEVC_H264' },
    { value: 'lcevc_hevc', label: 'LCEVC_HEVC' },
]

// image_format
export const imageFormatOptions = [
    { value: 'jpg', label: 'JPG' },
    { value: 'png', label: 'PNG' },
]

// file_extension - for repack
export const fileExtentionOptions =  [
    { value: 'mp4', label: 'MP4' },
    { value: 'webm', label: 'WEBM' },
]

// audio_codec
export const audioCodecOption = {
    mp3: [
        { value: 'mp3', label: 'MP3' },
    ],    
    hls_audio: [
        { value: 'libfdk_aac', label: 'FDK AAC' },
    ],
    // used in advanced setttings
    mp4: [
        { value: 'aac', label: 'AAC' },
        { value: 'mp3', label: 'MP3' },
    ], 
    webm: [
        { value: 'libfdk_aac', label: 'FDK AAC' },
    ],    
    advanced_hls: [
        { value: 'aac', label: 'AAC' },
        { value: 'mp3', label: 'MP3' },
    ],
    advanced_dash: [
        { value: 'aac', label: 'AAC' },
        { value: 'mp3', label: 'MP3' },
    ],  
};

// mode
export const modeOption =  [
    { value: 'balanced', label: 'Balanced' },
    { value: 'accuracy', label: 'Accuracy' },
    { value: 'speed', label: 'Speed' },
]

// speech_to_text
export const languageOptions = [
    { label: "Auto (Default)", value: "default" },
    { label: "Afrikaans", value: "af" },
    { label: "Arabic", value: "ar" },
    { label: "Armenian", value: "hy" },
    { label: "Azerbaijani", value: "az" },
    { label: "Belarusian", value: "be" },
    { label: "Bosnian", value: "bs" },
    { label: "Bulgarian", value: "bg" },
    { label: "Catalan", value: "ca" },
    { label: "Chinese", value: "zh" },
    { label: "Croatian", value: "hr" },
    { label: "Czech", value: "cs" },
    { label: "Danish", value: "da" },
    { label: "Dutch", value: "nl" },
    { label: "English", value: "en" },
    { label: "Estonian", value: "et" },
    { label: "Finnish", value: "fi" },
    { label: "French", value: "fr" },
    { label: "Galician", value: "gl" },
    { label: "German", value: "de" },
    { label: "Greek", value: "el" },
    { label: "Hebrew", value: "he" },
    { label: "Hindi", value: "hi" },
    { label: "Hungarian", value: "hu" },
    { label: "Icelandic", value: "is" },
    { label: "Indonesian", value: "id" },
    { label: "Italian", value: "it" },
    { label: "Japanese", value: "ja" },
    { label: "Kannada", value: "kn" },
    { label: "Kazakh", value: "kk" },
    { label: "Korean", value: "ko" },
    { label: "Latvian", value: "lv" },
    { label: "Lithuanian", value: "lt" },
    { label: "Macedonian", value: "mk" },
    { label: "Malay", value: "ms" },
    { label: "Marathi", value: "mr" },
    { label: "Maori", value: "mi" },
    { label: "Nepali", value: "ne" },
    { label: "Norwegian", value: "no" },
    { label: "Persian", value: "fa" },
    { label: "Polish", value: "pl" },
    { label: "Portuguese", value: "pt" },
    { label: "Romanian", value: "ro" },
    { label: "Russian", value: "ru" },
    { label: "Serbian", value: "sr" },
    { label: "Slovak", value: "sk" },
    { label: "Slovenian", value: "sl" },
    { label: "Spanish", value: "es" },
    { label: "Swahili", value: "sw" },
    { label: "Swedish", value: "sv" },
    { label: "Tagalog", value: "tl" },
    { label: "Tamil", value: "ta" },
    { label: "Thai", value: "th" },
    { label: "Turkish", value: "tr" },
    { label: "Ukrainian", value: "uk" },
    { label: "Urdu", value: "ur" },
    { label: "Vietnamese", value: "vi" },
    { label: "Welsh", value: "cy" }
];

export const outputResolutionOptions = [
    { label: "Original", value: "original" },
    { label: "4K", value: "2160" },
    { label: "1440p", value: "1440" },
    { label: "1080p", value: "1080" },
    { label: "720p", value: "720" },
    { label: "540p", value: "540" },
    { label: "480p", value: "480" },
    { label: "360p", value: "360" },
    { label: "240p", value: "240" },
    { label: "120p", value: "120" },
    { label: "Custom Settings", value: "custom" },
]

export const resizeModeOptions = [
    { label: "Scale", value: "scale" },
    { label: "Crop", value: "crop" },
]

// Create the lookup map
const createLookupMap = (options) => {
    const map = {};
    options.forEach(({ value, label }) => {
        map[value] = label;
    });
    return map;
};

export const lookupMap = {
    ...createLookupMap(outputOptions),
    ...Object.values(videoCodecOption).flat().reduce((acc, option) => ({ ...acc, [option.value]: option.label }), {}),
    ...Object.values(audioCodecOption).flat().reduce((acc, option) => ({ ...acc, [option.value]: option.label }), {}),
};

// Function to get label by value
export const getLabelByValue = (value) => {
    return lookupMap[value] || 'Unknown';
};
