import {typeIds} from "pages/StatisticsPage/CdnTab/config";
import Region from "components/Region";
import {serviceCodeToName} from "pages/StatisticsPage/config";
import ExpandableCellWithMeter from "pages/StatisticsPage/components/ExpandableCellWithMeter";
import React from "react";

export default function NameBodyCell({ info, selectedType }) {
  let Content = <span>{ info.getValue() }</span>;
  if ([
    typeIds.regions,
    typeIds.storage,
    typeIds.liveStream,
  ].includes(selectedType)) {
    Content = <Region regionCode={info.getValue()}/>;
  }
  let level1Value = <span>{ info.getValue() }</span>;
  
  if ([
    typeIds.buckets,
    typeIds.liveStreamProject,
  ].includes(selectedType)) {
    level1Value = <Region regionCode={info.getValue()}/>;
  }
  
  if ([
    typeIds.regions,
  ].includes(selectedType)) {
    level1Value = <span>{ serviceCodeToName[ info.getValue() ] || info.getValue() }</span>
  }
  
  return (
    <ExpandableCellWithMeter
      info={info}
      level0Value={Content}
      level1Value={level1Value}
      dataProperty="bandwidth"
    />
  )
}
