
export const columnIds = {
  'name': 'name',
  'details': 'details',
  'codec': 'codec',
  'resolution': 'resolution',
  'dateCreated': 'dateCreated',
  'lastUpdate': 'lastUpdate',
  'moreActions': 'moreActions',
};
