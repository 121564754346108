import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styles from './OptionsMenu.module.css';
import { MoreVert as VerticalDots } from "../../icons";
import { ListItem } from '../ListItem';

export const OptionsMenu = ({ menuItems, onClose, size  }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const buttonRef = useRef(null); // Ref for the button to calculate position
    const menuRef = useRef(null);

    const toggleMenu = useCallback(() => {
        setShowMenu(prevShowMenu => {
            if (!prevShowMenu) {
                // Position menu off-screen to measure
                setMenuPosition({ top: -9999, left: -9999 });
                
                // Wait for the next repaint to ensure the menu is rendered
                requestAnimationFrame(() => {
                    if (menuRef.current) {
                        const buttonRect = buttonRef.current.getBoundingClientRect();
                        const menuWidth = menuRef.current.offsetWidth;
                        const buttonWidth = buttonRef.current.offsetWidth / 2;
                        const rightOffset = 20
                        const topOffset = 4
                        
                        // Now set the actual position
                        setMenuPosition({
                            top: buttonRect.bottom + window.scrollY + topOffset,
                            left: buttonRect.left + window.scrollX - menuWidth + buttonWidth + rightOffset,
                        });
                    }
                });
            }
            return !prevShowMenu;
        });
    }, []);    

    const handleClickOutside = useCallback((event) => {
        // Check if both refs are not null before calling `.contains`
        if (menuRef.current && buttonRef.current && 
            !menuRef.current.contains(event.target) && 
            !buttonRef.current.contains(event.target)) {
            setShowMenu(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [handleClickOutside]);

    // const handleItemClick = useCallback((onClick) => {
    //     setShowMenu(false);
    //     if (onClick) onClick();
    // }, []);

    const handleItemClick = useCallback((onClick) => {
        setShowMenu(false);
        if (onClose) onClose();
        if (onClick) onClick();
    }, [onClose]);    

    const menu = (
        <div className={`${showMenu ? styles.menu : `${styles.menu} ${styles.menuInitialPosition}`}`} ref={menuRef} style={{ position: 'absolute', top: menuPosition.top, left: menuPosition.left }}>
            {menuItems.map((menuItem, index) => (
                <ListItem 
                    key={index}
                    text={menuItem.text}
                    onClick={() => handleItemClick(menuItem.onClick)}
                    icon={menuItem.icon}
                    danger={menuItem.danger}
                    trailingIcon={menuItem.trailingIcon}
                    submenuItems={menuItem.submenuItems}
                    closeMenu={() => setShowMenu(false)} // Pass closeMenu function to ListItem
                />
            ))}
        </div>
    );

    return (
        <div className={`${styles.container} ${size ? styles[size] : ''} ${showMenu ? styles.opened : ''}`}>
            <button
                className={styles.menuButton}
                onClick={toggleMenu}
                ref={buttonRef}
                aria-haspopup="true"
                aria-expanded={showMenu}
                type="button"
            >
                <VerticalDots className={styles.icon} />
            </button>
            {showMenu && ReactDOM.createPortal(menu, document.getElementById('menu-portal'))}
        </div>
    );
};

OptionsMenu.propTypes = {
    menuItems: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        onClick: PropTypes.func,
        icon: PropTypes.node,
        danger: PropTypes.bool,
    })).isRequired,
};

OptionsMenu.defaultProps = {
    menuItems: [],
};




// using ref
// import React, { useState, useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
// import ReactDOM from 'react-dom';
// import PropTypes from 'prop-types';
// import styles from './OptionsMenu.module.css';
// import { VerticalDots } from "../../icons";
// import { ListItem } from '../ListItem';

// export const OptionsMenu = forwardRef(({ menuItems }, ref) => {
//     const [showMenu, setShowMenu] = useState(false);
//     const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
//     const buttonRef = useRef(null); // Ref for the button to calculate position
//     const menuRef = useRef(null);

//     useImperativeHandle(ref, () => ({
//         closeMenu: () => setShowMenu(false)
//     }));

//     const toggleMenu = useCallback(() => {
//         setShowMenu(prevShowMenu => {
//             if (!prevShowMenu) {
//                 // Position menu off-screen to measure
//                 setMenuPosition({ top: -9999, left: -9999 });
                
//                 // Wait for the next repaint to ensure the menu is rendered
//                 requestAnimationFrame(() => {
//                     if (menuRef.current) {
//                         const buttonRect = buttonRef.current.getBoundingClientRect();
//                         const menuWidth = menuRef.current.offsetWidth;
//                         const buttonWidth = buttonRef.current.offsetWidth / 2;
//                         const rightOffset = 20;
//                         const topOffset = 2;
                        
//                         // Now set the actual position
//                         setMenuPosition({
//                             top: buttonRect.bottom + window.scrollY + topOffset,
//                             left: buttonRect.left + window.scrollX - menuWidth + buttonWidth + rightOffset,
//                         });
//                     }
//                 });
//             }
//             return !prevShowMenu;
//         });
//     }, []);    

//     const handleClickOutside = useCallback((event) => {
//         // Check if both refs are not null before calling `.contains`
//         if (menuRef.current && buttonRef.current && 
//             !menuRef.current.contains(event.target) && 
//             !buttonRef.current.contains(event.target)) {
//             setShowMenu(false);
//         }
//     }, []);

//     useEffect(() => {
//         document.addEventListener("mousedown", handleClickOutside);
//         return () => document.removeEventListener("mousedown", handleClickOutside);
//     }, [handleClickOutside]);

//     const handleItemClick = useCallback((onClick) => {
//         setShowMenu(false);
//         if (onClick) onClick();
//     }, []);

//     const menu = (
//         <div className={`${showMenu ? styles.menu : `${styles.menu} ${styles.menuInitialPosition}`}`} ref={menuRef} style={{ position: 'absolute', top: menuPosition.top, left: menuPosition.left }}>
//             {menuItems.map((menuItem, index) => (
//                 <ListItem 
//                     key={index}
//                     text={menuItem.text}
//                     onClick={() => handleItemClick(menuItem.onClick)}
//                     icon={menuItem.icon}
//                     danger={menuItem.danger}
//                     trailingIcon={menuItem.trailingIcon}
//                     submenuItems={menuItem.submenuItems}
//                 />
//             ))}
//         </div>
//     );

//     return (
//         <div className={styles.container}>
//             <button
//                 className={styles.menuButton}
//                 onClick={toggleMenu}
//                 ref={buttonRef}
//                 aria-haspopup="true"
//                 aria-expanded={showMenu}
//                 type="button"
//             >
//                 <VerticalDots className={styles.icon} />
//             </button>
//             {showMenu && ReactDOM.createPortal(menu, document.getElementById('menu-portal'))}
//         </div>
//     );
// });

// OptionsMenu.propTypes = {
//     menuItems: PropTypes.arrayOf(PropTypes.shape({
//         text: PropTypes.string,
//         onClick: PropTypes.func,
//         icon: PropTypes.node,
//         danger: PropTypes.bool,
//     })).isRequired,
// };

// OptionsMenu.defaultProps = {
//     menuItems: [],
// };











// import React, { useState, useCallback, useEffect, useRef } from 'react';
// import PropTypes from 'prop-types';
// import styles from './OptionsMenu.module.css'; 
// import { VerticalDots } from "../../icons";
// import { ListItem } from '../ListItem';

// export const OptionsMenu = ({ menuItems }) => {
//     const [showMenu, setShowMenu] = useState(false);
//     const menuRef = useRef(null);

//     const toggleMenu = useCallback(() => setShowMenu(prev => !prev), []);

//     const handleClickOutside = useCallback((event) => {
//         if (menuRef.current && !menuRef.current.contains(event.target)) {
//             setShowMenu(false);
//         }
//     }, []);

//     useEffect(() => {
//         document.addEventListener("mousedown", handleClickOutside);
//         return () => document.removeEventListener("mousedown", handleClickOutside);
//     }, [handleClickOutside]);

//     const handleItemClick = useCallback((onClick) => {
//         setShowMenu(false);
//         if (onClick) onClick();
//     }, []);

//     return (
//         <div className={styles.container} ref={menuRef}>
//             <button
//                 className={styles.menuButton}
//                 onClick={toggleMenu}
//                 aria-haspopup="true"
//                 aria-expanded={showMenu}
//                 type="button"
//             >
//                 <VerticalDots className={styles.icon} />
//             </button>
//             {showMenu && (
//                 <div className={styles.menu}>
//                     {menuItems.map((menuItem, index) => (
//                         <ListItem 
//                             key={index}
//                             text={menuItem.text}
//                             onClick={() => handleItemClick(menuItem.onClick)}
//                             icon={menuItem.icon}
//                             danger={menuItem.danger}
//                         />
//                     ))}
//                 </div>
//             )}
//         </div>
//     );
// };

// OptionsMenu.propTypes = {
//     menuItems: PropTypes.arrayOf(PropTypes.shape({
//         text: PropTypes.string,
//         onClick: PropTypes.func,
//         icon: PropTypes.node,
//         danger: PropTypes.bool,
//     })).isRequired,
// };

// OptionsMenu.defaultProps = {
//     menuItems: [],
// };

