import {useCallback, useRef} from "react";
import useValueClearing from "components/FilterSidebar/useValueClearing";


export default function useValueRange(
  columnId,
  lastClearFilterId,
  lastClearType,
  ) {
  
  const startRef = useRef();
  const endRef = useRef();

  const clearInputs = useCallback(() => {
    startRef.current?.setInputValue?.('');
    endRef.current?.setInputValue?.('');
  }, [startRef, endRef]);

  useValueClearing(columnId, lastClearFilterId, lastClearType, clearInputs);
  
  return {
    startRef,
    endRef,
  };
}

