import PropTypes from "prop-types";
import React from "react";

export const Settings2 = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M6.66999 2.85928V2.4C6.66999 2.17908 6.85378 2 7.08049 2H8.91911C9.14583 2 9.32961 2.17908 9.32961 2.4V2.85928C9.32961 3.39078 9.62135 3.8817 10.0944 4.14627L10.4341 4.3362C10.9267 4.61177 11.5318 4.61527 12.0278 4.34543L12.4786 4.1002C12.6727 3.99459 12.9179 4.0588 13.0315 4.24501L13.9421 5.73714C14.058 5.92702 13.994 6.17248 13.7992 6.2854C13.7963 6.28708 13.7934 6.28871 13.7904 6.29033L13.3572 6.5275C12.863 6.79803 12.5571 7.30735 12.5571 7.85967V8.14403C12.5571 8.69578 12.8632 9.20444 13.3572 9.47399L13.7896 9.70991C13.9874 9.81783 14.0579 10.0616 13.9472 10.2544C13.9454 10.2574 13.9437 10.2604 13.9418 10.2634L13.0319 11.7545C12.918 11.9409 12.6725 12.005 12.4784 11.899L12.0278 11.653C11.5316 11.3821 10.926 11.3842 10.4319 11.6585L10.1008 11.8423C9.62399 12.107 9.32961 12.6005 9.32961 13.1352V13.6C9.32961 13.8209 9.14583 14 8.91911 14H7.08049C6.85378 14 6.66999 13.8209 6.66999 13.6V13.1386C6.66999 12.6069 6.37439 12.1169 5.8976 11.8582L5.52975 11.6586C5.04086 11.3935 4.44582 11.3943 3.95767 11.6608L3.52122 11.899C3.32703 12.005 3.08153 11.9409 2.96777 11.7545L2.05787 10.2635C1.94198 10.0736 2.00601 9.8282 2.20087 9.71523C2.20398 9.71345 2.20712 9.71169 2.21029 9.70997L2.62517 9.48382C3.10852 9.22035 3.40792 8.72285 3.40792 8.18317V7.81962C3.40792 7.27952 3.1086 6.78152 2.62517 6.51731L2.20964 6.29021C2.01191 6.18215 1.94152 5.93834 2.05242 5.74566C2.05412 5.74271 2.05585 5.73979 2.05762 5.73689L2.96773 4.24558C3.08151 4.05913 3.32706 3.99504 3.52125 4.1011L3.95767 4.33944C4.44567 4.60595 5.04073 4.60625 5.529 4.34021L5.90032 4.1379C6.37557 3.87896 6.66999 3.38986 6.66999 2.85928Z" stroke="currentColor" strokeWidth="1.2"/>
      <path d="M9.77364 8.00011L7.11523 9.72511V6.27511L9.77364 8.00011Z" fill="currentColor" stroke="currentColor"/>
    </svg>    
  );
};

Settings2.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Settings2.defaultProps = {
  color: 'currentColor', // Or any default color
};