import React, { useState } from 'react';
import { Button } from 'components/Button';
import { useAWS } from 'api/useAWS';

export const CalculateSizeButton = ({ info, config, bucketName }) => {
    const { getTotalSizeOfItemsInBucket } = useAWS();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleCalcSizeClick = async () => {
        setLoading(true);
        setError(null);

        const prefix = info.row.original.name;
        const { success, totalSize } = await getTotalSizeOfItemsInBucket(bucketName, config, prefix);

        setLoading(false);
        if (success) {
            //console.log("totalSize: ", totalSize)
            //info.table.options.meta?.updateData(info.row.index, 'size', totalSize);
            info.table.options.meta?.updateData(info.row.index, 'size', totalSize !== null ? totalSize : 0);
        } else {
            setError('Failed to calculate size');
        }
    };

    return (
        <>
            {error && <>Error</>}
            {/* {!info.getValue() ? ( */}
            {info.getValue() === undefined || info.getValue() === null ? (
                <Button 
                    variant='link' 
                    onClick={handleCalcSizeClick} 
                    state={loading ? "disabled" : null}
                >
                    {loading ? 'Calculating...' : 'Calculate'}
                </Button>
            ) : (
                // <>{formatSize(info.getValue())}</>
                <>{info.getValue()}</>
            )}
        </>
    );
};



// import React, { useState } from 'react';
// import { Button } from 'components/Button';
// import { useAWS } from 'api/useAWS';
// import { formatSize } from 'helpers/formatting';

// export const CalculateSizeButton = ({ item, bucketName, config }) => {
//     const { getTotalSizeOfItemsInBucket } = useAWS();
//     const [size, setSize] = useState(null);
//     const [loading, setLoading] = useState(false);

//     const handleCalcSizeClick = async () => {
//         setLoading(true);
//         const prefix = item.name;

//         const { success, totalSize } = await getTotalSizeOfItemsInBucket(bucketName, config, prefix);
//         if (success) {
//             setSize(totalSize);
//         } else {
//             console.error('Failed to calculate size');
//         }
//         setLoading(false);
//     };

//     return (
//         <div>
//             {size !== null ? (
//                 formatSize(size)
//             ) : (
//                 <Button variant='link' onClick={handleCalcSizeClick} disabled={loading}>
//                     {loading ? 'Calculating...' : 'Calculate'}
//                 </Button>
//             )}
//         </div>
//     );
// };
