import React from 'react';
import PropTypes from 'prop-types';
import {Active, Clock, ClockDashed, Edit2, InProgress, Rocket, Stopped} from "icons";
import style from './style.module.css';
import {streamStatusEnum, streamStatusToReadable} from "pages/Streaming/StreamsPage/config";

const streamStatusToIcon = {
    [ streamStatusEnum.live ]: <Active className={style.active}/>,
    [ streamStatusEnum.idle ]: <Clock className={style.idle}/>,
    [ streamStatusEnum.launching ]: <Rocket className={style.launching}/>,
    [ streamStatusEnum.stopped ]: <Stopped className={style.stopped}/>,
    [ streamStatusEnum.created ]: <Edit2 className={style.created}/>,
    [ streamStatusEnum.starting ]: <InProgress className={style.inProgress}/>,
    [ streamStatusEnum.waiting ]: <ClockDashed className={style.waiting}/>,
    [ streamStatusEnum.stopping ]: <Stopped className={style.stopped}/>,
};

function StreamStatus({ status }) {
    return (
        <span className={style.wrapper}>
            { streamStatusToIcon[ status ] }
            <span>
                { streamStatusToReadable[ status ] }
            </span>
        </span>
    );
}

StreamStatus.propTypes = {
    status: PropTypes.string,
};

export default StreamStatus;