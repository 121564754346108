import PropTypes from "prop-types";
import React from "react";

export const Error = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M2.60002 8.00039C2.60002 5.01776 5.0174 2.60039 8.00002 2.60039C10.9827 2.60039 13.4 5.01776 13.4 8.00039C13.4 10.983 10.9827 13.4004 8.00002 13.4004C5.0174 13.4004 2.60002 10.983 2.60002 8.00039ZM8.00002 1.40039C4.35465 1.40039 1.40002 4.35502 1.40002 8.00039C1.40002 11.6458 4.35465 14.6004 8.00002 14.6004C11.6454 14.6004 14.6 11.6458 14.6 8.00039C14.6 4.35502 11.6454 1.40039 8.00002 1.40039ZM10.4274 5.57298C10.6617 5.80729 10.6617 6.18719 10.4274 6.42151L8.84849 8.00045L10.4274 9.57939C10.6617 9.8137 10.6617 10.1936 10.4274 10.4279C10.1931 10.6622 9.81321 10.6622 9.5789 10.4279L7.99996 8.84897L6.42102 10.4279C6.1867 10.6622 5.8068 10.6622 5.57249 10.4279C5.33817 10.1936 5.33817 9.8137 5.57249 9.57939L7.15143 8.00045L5.57249 6.42151C5.33817 6.18719 5.33817 5.80729 5.57249 5.57298C5.8068 5.33866 6.1867 5.33866 6.42102 5.57298L7.99996 7.15192L9.5789 5.57298C9.81321 5.33866 10.1931 5.33866 10.4274 5.57298Z" fill="currentColor"/>
    </svg>    
  );
};

Error.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Error.defaultProps = {
  color: 'currentColor', // Or any default color
};