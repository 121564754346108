import PropTypes from "prop-types";
import React from "react";

export const SDK = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M10.7812 10.0375C10.284 10.2705 9.71511 10.2725 9.21642 10.0429L1.95785 6.70171C1.56976 6.52307 1.39423 6.05066 1.56579 5.64656C1.64171 5.46774 1.77789 5.32406 1.94861 5.24266L9.20721 1.78163C9.71099 1.54142 10.2898 1.53944 10.7951 1.77619L18.0468 5.17408C18.4337 5.35539 18.6063 5.829 18.4321 6.23191C18.3551 6.41019 18.218 6.55293 18.0468 6.63316L10.7812 10.0375ZM18.4528 9.57969C18.6317 9.95329 18.4738 10.4012 18.1002 10.58L11.2274 13.8709C10.4838 14.227 9.61957 14.2306 8.87296 13.8808L2.00355 10.662C1.62847 10.4862 1.46689 10.0397 1.64264 9.66462C1.81839 9.28954 2.26492 9.12796 2.64 9.30371L9.50941 12.5225C9.84878 12.6815 10.2416 12.6799 10.5796 12.518L17.4524 9.22714C17.826 9.04826 18.2739 9.2061 18.4528 9.57969ZM18.1002 14.58C18.4738 14.4012 18.6317 13.9533 18.4528 13.5797C18.2739 13.2061 17.826 13.0483 17.4524 13.2271L10.5796 16.518C10.2416 16.6799 9.84878 16.6815 9.50941 16.5225L2.64 13.3037C2.26492 13.128 1.81839 13.2895 1.64264 13.6646C1.46689 14.0397 1.62847 14.4862 2.00355 14.662L8.87296 17.8808C9.61957 18.2306 10.4838 18.227 11.2274 17.8709L18.1002 14.58Z" fill="currentColor"/>
    </svg>    
  );
};

SDK.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

SDK.defaultProps = {
  color: 'currentColor', // Or any default color
};