import React, {useCallback, useState} from 'react';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {useNavigate} from 'react-router-dom';
import pageStyle from "./style.module.css";
import useDataLoadingStatus from "hooks/useDataLoadingStatus";
import {getProjectList} from "api/qencode-account-api";
import Pagination from "components/Table/Pagination";
import MoreRowActions, { rowModals } from "pages/Transcoding/ProjectsPage/MoreRowActions";
import DeleteModal from "pages/Transcoding/ProjectsPage/DeleteModal";
import RenameModal from "pages/Transcoding/ProjectsPage/RenameModal";
import RecreateKeyModal from "pages/Transcoding/ProjectsPage/RecreateKeyModal";
import { ReactComponent as Folder2 } from "assets/images/Folder2.svg";
import Table from "components/Table";
import globalFilterFn from "components/Table/columnFilters/exactCaseInsensitiveFilter";
import MaskedKey from "components/MaskedKey";
import DateFormatted from "components/DateFormatted";
import Search from "components/Search";
import {Button} from "components/Button";
import CreateProjectModal from "pages/Transcoding/ProjectsPage/CreateModal";
import {Add} from "icons";
import TableWrapper from "components/TableWrapper";

const columnIds = {
  'name': 'name',
  'apiKey': 'apiKey',
  'dateCreated': 'dateCreated',
  'recentJobs': 'recentJobs',
  'moreActions': 'moreActions',
};

export default function TranscodingProjectsPage() {
  const navigate = useNavigate();
  const loadingStatus = useDataLoadingStatus(useCallback((properties) => getProjectList({
    ...properties,
    type: 'vod',
  }), [])
  );
  const {
    data,
    refresh,
  } = loadingStatus;
  const [openedModal, setOpenedModal] = useState();
  const [selectedProjectId, setSelectedProjectId] = useState();

  const project = data.find((anyProject) => anyProject.id === selectedProjectId);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);

  const columns = React.useMemo(
    () => [
      {
        id: columnIds.name,
        accessorKey: 'name',
        cell: info => {
          const projectName = info.getValue();
          return (
            <div className="cellContainer">
              <span className={ pageStyle.cellLimited }>
                { projectName }
              </span>
            </div>
          )
        },
        header: () => <span>Project Name</span>,
      },
      {
        id: columnIds.apiKey,
        accessorKey: 'api_key',
        enableSorting: false,
        cell: (info) => (
          <MaskedKey rawKey={ info.getValue() } withCopy={true}/>
        ),
        header: () => <span>API Key</span>,
      },
      {
        id: columnIds.dateCreated,
        accessorKey: 'create_datetime',
        sortingFn: 'datetime',
        cell: info => (
          <span>
            <DateFormatted date={ info.getValue() }/>
          </span>
        ),
        header: () => <span>Date Created</span>,
      },
      {
        id: columnIds.recentJobs,
        cell: info => {
          return (
            <Button
              variant="link"
              onClick={() => navigate( `/my_encodings&pid=${ encodeURIComponent(info.row.original.name) }` )}
            >
              Recent Jobs
            </Button>
          )
        },
      },
      {
        id: columnIds.moreActions,
        enableSorting: false,
        cell: ({ row }) => (
          <MoreRowActions
            projectId={row.original.id}
            handleOpenModal={handleOpenModal}
          />
        ),
      },
    ],
    [navigate]
  );


  function handleOpenModal(type, id) {
    setSelectedProjectId(id);
    setOpenedModal(type);
  }

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    /* Sorting */
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    /* Filtering */
    globalFilterFn,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    /* Pagination */
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,

    state: {
      sorting,
      globalFilter,
      pagination,
    },
  });

  function handleCloseModals() {
    handleOpenModal();
  }

  const modalProps = {
    projectId: selectedProjectId,
    handleCancel: handleCloseModals,
    onSuccessfulFinish: refresh,
  };

  return (
    <TableWrapper>
      <div className={pageStyle.toolbar}>
        <Search setGlobalFilter={setGlobalFilter} globalFilter={globalFilter}/>
        <Button
          onClick={() => setOpenedModal(rowModals.create)}
          icon={<Add/>}
        >
          Create New Project
        </Button>
      </div>
      <Table
        { ...loadingStatus }
        table={table}
        minWidth={600}
        getEmptyComponent={() => <EmptyMessage setOpenedModal={setOpenedModal}/>}
        columnIdToClassName={{
          [ columnIds.recentJobs ]: pageStyle.recentJobs,
          [ columnIds.moreActions ]: pageStyle.moreActionsColumn,
        }}
      />
        <Pagination table={ table } />
        <CreateProjectModal
          { ...modalProps }
          open={ openedModal === rowModals.create }
        />
        <DeleteModal
          { ...modalProps }
          projectName={project?.name}
          open={ openedModal === rowModals.deletion }
        />
        <RenameModal
          { ...modalProps }
          open={ openedModal === rowModals.rename }
        />
        <RecreateKeyModal
          { ...modalProps }
          open={ openedModal === rowModals.recreateApiKey }
        />
    </TableWrapper>
  );
}


function EmptyMessage({ setOpenedModal }) {
  return (
    <div className="tableNodata">
      <div className="tableNodataImage">
        <Folder2/>
      </div>
      <h3 className="tableNodataTitle">
        You don't have any Transcoding Projects yet
      </h3>
      <p className="tableNodataDescription">
        Start by creating a new transcoding project
      </p>
      <Button
        onClick={() => setOpenedModal(rowModals.create)}
        icon={<Add/>}
      >
        Create New Project
      </Button>
    </div>
  )
}
