import React, { useState } from 'react';

import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { InputField } from 'components/InputField';
import { Select } from 'components/Select';
import { FormToggle } from 'components/FormToggle'
import { Label } from 'components/Label'

import { 
    audioCodecOption
} from './optionsMap';

import { getBitrateByHeight, defaultQuality } from './helpers'

import { outputResolutionOptions, resizeModeOptions } from './optionsMap'

import { ChevronBottom, ChevronTop } from 'icons';

import { tooltipData } from 'helpers/docsTooltipData'

import styles from '../index.module.css';

export const AdvancedSettingsModal = ({ open, handleCancel, handleUpdate, format, resolutionIndex }) => {

    console.log("resolutionIndex: ", resolutionIndex)

    const [localFormat, setLocalFormat] = useState(format);

    const [videoSettingsOpen, setVideoSettingsOpen] = useState(false);
    const [audioSettingsOpen, setAudioSettingsOpen] = useState(false);
    const [codecSettingsOpen, setCodecSettingsOpen] = useState(false);

    //const [videoClipToggle, setVideoClipToggle] = useState(false);

    const [videoClipToggle, setVideoClipToggle] = useState(
        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0)
            ? localFormat?.stream[resolutionIndex]?.start_time || localFormat?.stream[resolutionIndex]?.duration
            : localFormat?.start_time || localFormat?.duration) 
        ? true 
        : false
    );   


    const determineInitialOutputResolution = (localFormat, resolutionIndex) => {
        if (localFormat?.stream && localFormat.stream.length > 0 && resolutionIndex !== null) {
            const streamResolution = localFormat.stream[resolutionIndex]?.height;
            if (streamResolution) {
                const option = outputResolutionOptions.find(option => option.value === String(streamResolution));
                if (option) {
                    return option.value;
                }
                return 'custom';
            }
        }
        
        const formatResolution = localFormat?.height;
        if (formatResolution) {
            const option = outputResolutionOptions.find(option => option.value === String(formatResolution));
            if (option) {
                return option.value;
            }
            return 'custom';
        }
    
        const formatWidth = localFormat?.width;
        if (formatWidth) {
            return 'custom';
        }
    
        return 'original';
    };
    
    const [outputResolution, setOutputResolution] = useState(() => determineInitialOutputResolution(localFormat, resolutionIndex));
    
    const [watermarkToggle, setWatermarkToggle] = useState(
        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0)
            ? localFormat?.stream[resolutionIndex]?.logo?.source || localFormat?.stream[resolutionIndex]?.logo?.x || localFormat?.stream[resolutionIndex]?.logo?.y
            : localFormat?.logo?.source || localFormat?.logo?.x || localFormat?.logo?.y) 
        ? true 
        : false
    );       

    const toggleVideoSettings = () => setVideoSettingsOpen(!videoSettingsOpen);
    const toggleAudioSettings = () => setAudioSettingsOpen(!audioSettingsOpen);
    const toggleCodecSettings = () => setCodecSettingsOpen(!codecSettingsOpen);

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    
    //     console.log({ name, value });
    
    //     let updatedFormat = { ...localFormat };
    
    //     // true is 1 and false is 0 - used for toggles
    //     const fieldsToTransform = [
    //         'audio_mute', 'deinterlace', // toggles in video formats
    //         'video_copy', 'audio_copy', 'subtitles_copy', 'metadata_copy', // toggles for repack
    //         'transcript', 'json', 'srt', 'vtt', // toggles for speech_to_text
    //         'hdr_to_sdr' // toggle in advanced settings
    //     ];
    
    //     // Fields to delete if they are turned off, or empty or value not set
    //     const fieldsToDelete = [
    //         'audio_mute', 'deinterlace', // video formats
    //         'audio_bitrate', 'audio_sample_rate', 'audio_channels_number', // mp3
    //         'width', 'height', 'interval', // thumbnails
    //         'video_copy', 'audio_copy', 'subtitles_copy', 'metadata_copy', // repack
    //         'framerate', 'start_time', 'duration', // gif
    //         'hdr_to_sdr',
    //         'keyframe', // advanced settings - video settings
    //         'aspect_ratio', 'rotate'
    //     ];
    
    //     // Toggle value transformation
    //     const transformedValue = fieldsToTransform.includes(name)
    //         ? (value === 'true' || value === true ? 1 : 0)
    //         : value;
    
    //     // Function to delete fields with empty or default values
    //     const cleanFields = (obj, fields) => {
    //         for (const field of fields) {
    //             if (obj[field] === 0 || obj[field] === '' || !obj[field]) {
    //                 delete obj[field];
    //             }
    //         }
    //     };
    
    //     const [mainField, subField] = name.split('.');
    
    //     // Handle stream update
    //     if (localFormat?.stream && localFormat?.stream?.length > 0 && resolutionIndex !== null) {
    //         let updatedStream = localFormat.stream.map((item, index) => {
    //             if (index === resolutionIndex) {
    //                 let updatedItem = { ...item };
    //                 if (mainField === 'video_codec_parameters' || mainField === 'logo') {
    //                     if (!updatedItem[mainField]) {
    //                         updatedItem[mainField] = {};
    //                     }
    //                     updatedItem[mainField][subField] = value;
    //                     if (value === '' || value === null) {
    //                         delete updatedItem[mainField][subField];
    //                     }
    //                     if (Object.keys(updatedItem[mainField]).length === 0) {
    //                         delete updatedItem[mainField];
    //                     }
    //                 } else {
    //                     if (name === 'audio_codec' && value === 'aac') {
    //                         delete updatedItem[name];
    //                     } else {
    //                         updatedItem[name] = transformedValue;
    //                     }
    //                 }
    //                 cleanFields(updatedItem, fieldsToDelete);
    //                 return updatedItem;
    //             }
    //             return item;
    //         });
    //         updatedFormat = { ...localFormat, stream: updatedStream };
    //     } else {
    //         if (mainField === 'video_codec_parameters' || mainField === 'logo') {
    //             if (!updatedFormat[mainField]) {
    //                 updatedFormat[mainField] = {};
    //             }
    //             updatedFormat[mainField][subField] = value;
    //             if (value === '' || value === null) {
    //                 delete updatedFormat[mainField][subField];
    //             }
    //             if (Object.keys(updatedFormat[mainField]).length === 0) {
    //                 delete updatedFormat[mainField];
    //             }
    //         } else {
    //             updatedFormat[name] = transformedValue;
    //         }
    //     }
    
    //     // Specific deletions based on conditions
    //     if (localFormat?.output === 'speech_to_text' && name === 'language' && transformedValue === 'default') {
    //         delete updatedFormat.language;
    //     }
    
    //     if ((localFormat?.output === 'mp4' || localFormat?.output === 'webm') && name === 'audio_codec' &&
    //         (transformedValue === 'aac' || transformedValue === 'libfdk_aac')) {
    //         delete updatedFormat.audio_codec;
    //     }
    
    //     cleanFields(updatedFormat, fieldsToDelete);
    
    //     setLocalFormat(updatedFormat); // update state in parent immediately
    // };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        console.log({ name, value });
        
        let updatedFormat = { ...localFormat };
        
        // true is 1 and false is 0 - used for toggles
        const fieldsToTransform = [
            'audio_mute', 'deinterlace', // toggles in video formats
            'video_copy', 'audio_copy', 'subtitles_copy', 'metadata_copy', // toggles for repack
            'transcript', 'json', 'srt', 'vtt', // toggles for speech_to_text
            'hdr_to_sdr' // toggle in advanced settings
        ];
        
        // Fields to delete if they are turned off, or empty or value not set
        const fieldsToDelete = [
            'audio_mute', 'deinterlace', // video formats
            'audio_bitrate', 'audio_sample_rate', 'audio_channels_number', // mp3
            'width', 'height', 'interval', // thumbnails
            'video_copy', 'audio_copy', 'subtitles_copy', 'metadata_copy', // repack
            'framerate', 'start_time', 'duration', // gif
            'hdr_to_sdr',
            'keyframe', // advanced settings - video settings
            'aspect_ratio', 'rotate',
            'tag', 'chunklist_name'
        ];
        
        // Toggle value transformation
        const transformedValue = fieldsToTransform.includes(name)
            ? (value === 'true' || value === true ? 1 : 0)
            : value;
        
        // Function to delete fields with empty or default values
        const cleanFields = (obj, fields) => {
            for (const field of fields) {
                if (obj[field] === 0 || obj[field] === '' || !obj[field]) {
                    delete obj[field];
                }
            }
        };
        
        const [mainField, subField] = name.split('.');
        
        // Handle stream update
        if (localFormat?.stream && localFormat?.stream?.length > 0 && resolutionIndex !== null) {
            let updatedStream = localFormat.stream.map((item, index) => {
                if (index === resolutionIndex) {
                    let updatedItem = { ...item };
                    if (mainField === 'video_codec_parameters' || mainField === 'logo') {
                        if (!updatedItem[mainField]) {
                            updatedItem[mainField] = {};
                        }
                        updatedItem[mainField][subField] = value;
                        if (value === '' || value === null) {
                            delete updatedItem[mainField][subField];
                        }
                        if (Object.keys(updatedItem[mainField]).length === 0) {
                            delete updatedItem[mainField];
                        }
                    } else {
                        if (name === 'audio_codec' && value === 'aac') {
                            delete updatedItem[name];
                        } else if (name === 'resize_mode' && value === 'scale') {
                            delete updatedItem[name];
                        } else {
                            updatedItem[name] = transformedValue;
                        }
                    }
                    cleanFields(updatedItem, fieldsToDelete);
                    return updatedItem;
                }
                return item;
            });
            updatedFormat = { ...localFormat, stream: updatedStream };
        } else {
            if (mainField === 'video_codec_parameters' || mainField === 'logo') {
                if (!updatedFormat[mainField]) {
                    updatedFormat[mainField] = {};
                }
                updatedFormat[mainField][subField] = value;
                if (value === '' || value === null) {
                    delete updatedFormat[mainField][subField];
                }
                if (Object.keys(updatedFormat[mainField]).length === 0) {
                    delete updatedFormat[mainField];
                }
            } else {
                if (name === 'resize_mode' && value === 'scale') {
                    delete updatedFormat[name];
                } else {
                    updatedFormat[name] = transformedValue;
                }
            }
        }
        
        // Specific deletions based on conditions
        if (localFormat?.output === 'speech_to_text' && name === 'language' && transformedValue === 'default') {
            delete updatedFormat.language;
        }
        
        if ((localFormat?.output === 'mp4' || localFormat?.output === 'webm') && name === 'audio_codec' &&
            (transformedValue === 'aac' || transformedValue === 'libfdk_aac')) {
            delete updatedFormat.audio_codec;
        }
        
        cleanFields(updatedFormat, fieldsToDelete);
        
        setLocalFormat(updatedFormat); // update state in parent immediately
    };
    
    
    
    const handleBitrateToggle = (e) => {
        const { name, value } = e.target;
    
        let updatedFormat = {};
        const transformedValue = value ? 1 : 0;
    
        // Check if there is a stream list and update the specified element in the list
        if (localFormat?.stream && localFormat?.stream?.length > 0 && resolutionIndex !== null) {
            let updatedStream = localFormat.stream.map((item, idx) => {
                if (idx === resolutionIndex) {
                    let updatedItem = { ...item };
                    if (transformedValue === 1) {
                        updatedItem.optimize_bitrate = 1;
                        delete updatedItem.bitrate;
                        delete updatedItem.quality
                    } else {
                        const bitrate = getBitrateByHeight(item.height);
                        if (bitrate !== undefined && bitrate !== null) {
                            updatedItem.bitrate = bitrate;
                        }
                        delete updatedItem.optimize_bitrate;

                        // delete fields
                        delete updatedItem.adjust_crf;
                        delete updatedItem.min_crf;
                        delete updatedItem.max_crf;                        
                    }
                    return updatedItem;
                }
                return item;
            });
            updatedFormat = { ...localFormat, stream: updatedStream };
        } else {
            updatedFormat = { ...localFormat, [name]: transformedValue };
    
            if (transformedValue === 1) {
                delete updatedFormat.bitrate;
                delete updatedFormat.quality;
            } else {
                const quality = defaultQuality; // quality is 20 by default for all
                updatedFormat.quality = quality;
                delete updatedFormat.optimize_bitrate;

                // delete fields
                delete updatedFormat.adjust_crf;
                delete updatedFormat.min_crf;
                delete updatedFormat.max_crf;
            }
        }
    
        setLocalFormat(updatedFormat); // update state in parent immediately
    };


    const handleVideoClipToggle = () => {
        let updatedFormat = { ...localFormat };

        if (!videoClipToggle) {
            //console.log("Show fields and init them with empty values in localFormat");

            // Check if there is a stream list and update the specified element in the list
            if (localFormat?.stream && localFormat?.stream?.length > 0 && resolutionIndex !== null) {
                updatedFormat.stream = localFormat.stream.map((item, idx) => {
                    if (idx === resolutionIndex) {
                        return {
                            ...item,
                            start_time: item.start_time || '',
                            duration: item.duration || ''
                        };
                    }
                    return item;
                });
            } else {
                updatedFormat.start_time = updatedFormat.start_time || '';
                updatedFormat.duration = updatedFormat.duration || '';
            }
        } else {
            //console.log("Hide fields and delete them from localFormat");

            // Check if there is a stream list and update the specified element in the list
            if (localFormat?.stream && localFormat?.stream?.length > 0 && resolutionIndex !== null) {
                updatedFormat.stream = localFormat.stream.map((item, idx) => {
                    if (idx === resolutionIndex) {
                        const updatedItem = { ...item };
                        delete updatedItem.start_time;
                        delete updatedItem.duration;
                        return updatedItem;
                    }
                    return item;
                });
            } else {
                delete updatedFormat.start_time;
                delete updatedFormat.duration;
            }
        }

        setLocalFormat(updatedFormat);
        setVideoClipToggle(!videoClipToggle);
    };
    
    const handleWatermarkToggle = () => {
        let updatedFormat = { ...localFormat };

        if (!watermarkToggle) {
            //console.log("Show fields and init them with empty values in localFormat");

            // Check if there is a stream list and update the specified element in the list
            if (localFormat?.stream && localFormat?.stream?.length > 0 && resolutionIndex !== null) {
                updatedFormat.stream = localFormat.stream.map((item, idx) => {
                    if (idx === resolutionIndex) {
                        return {
                            ...item,
                            logo: {
                                source: item.logo?.source || '',
                                x: item.logo?.x || '',
                                y: item.logo?.y || '',
                            }
                        };
                    }
                    return item;
                });
            } else {
                updatedFormat.logo = {
                    source: updatedFormat.logo?.source || '',
                    x: updatedFormat.logo?.x || '',
                    y: updatedFormat.logo?.y || '',                    
                }
            }
        } else {
            //console.log("Hide fields and delete them from localFormat");

            // Check if there is a stream list and update the specified element in the list
            if (localFormat?.stream && localFormat?.stream?.length > 0 && resolutionIndex !== null) {
                updatedFormat.stream = localFormat.stream.map((item, idx) => {
                    if (idx === resolutionIndex) {
                        const updatedItem = { ...item };
                        delete updatedItem.logo;
                        return updatedItem;
                    }
                    return item;
                });
            } else {
                delete updatedFormat.logo;
            }
        }

        setLocalFormat(updatedFormat);
        setWatermarkToggle(!watermarkToggle);
    };    


    const handleOutputResolutionChange = (e) => {
        const { value } = e.target;

        setOutputResolution(value);
    
        let updatedFormat = { ...localFormat };
    
        if (value === 'original' || value === 'custom') {
            // If value is 'original' or 'custom', remove width and height from localFormat
            if (localFormat?.stream && localFormat?.stream?.length > 0 && resolutionIndex !== null) {
                updatedFormat.stream = localFormat.stream.map((item, idx) => {
                    if (idx === resolutionIndex) {
                        const updatedItem = { ...item };
                        delete updatedItem.width;
                        delete updatedItem.height;
                        return updatedItem;
                    }
                    return item;
                });
            } else {
                delete updatedFormat.width;
                delete updatedFormat.height;
            }
        } else {
            // If value is not 'original' or 'custom', remove width and set height to value in localFormat
            if (localFormat?.stream && localFormat?.stream?.length > 0 && resolutionIndex !== null) {
                updatedFormat.stream = localFormat.stream.map((item, idx) => {
                    if (idx === resolutionIndex) {
                        const updatedItem = { ...item };
                        delete updatedItem.width;
                        updatedItem.height = value;
                        return updatedItem;
                    }
                    return item;
                });
            } else {
                delete updatedFormat.width;
                updatedFormat.height = value;
            }
        }
    
        setLocalFormat(updatedFormat);
    };
    
      

    const updateAdvancedSettings = () => {
        let updatedFormat = { ...localFormat };
    
        // Fields to delete if they are turned off, or empty or value not set
        const fieldsToDelete = [
            'start_time', 'duration' // also in advanced Create Video Clip settings
        ];
    
        // Function to delete fields with empty or default values
        const cleanFields = (obj, fields) => {
            for (const field of fields) {
                if (obj[field] === '') {
                    delete obj[field];
                }
            }
        };
    
        // Function to clean logo fields
        const cleanLogoFields = (obj) => {
            if (obj.logo) {
                const logoFields = ['source', 'x', 'y'];
                for (const field of logoFields) {
                    if (obj.logo[field] === '') {
                        delete obj.logo[field];
                    }
                }
                if (Object.keys(obj.logo).length === 0) {
                    delete obj.logo;
                }
            }
        };
    
        // Clean fields from the main format object
        cleanFields(updatedFormat, fieldsToDelete);
        cleanLogoFields(updatedFormat);
    
        // Clean fields from stream elements, if present
        if (localFormat?.stream && localFormat?.stream?.length > 0 && resolutionIndex !== null) {
            let updatedStream = localFormat.stream.map((item, index) => {
                if (index === resolutionIndex) {
                    let updatedItem = { ...item };
                    cleanFields(updatedItem, fieldsToDelete);
                    cleanLogoFields(updatedItem);
                    return updatedItem;
                }
                return item;
            });
            updatedFormat = { ...localFormat, stream: updatedStream };
        }
    
        handleUpdate(updatedFormat);
    };
    

    return (
        <Modal
            isOpen={open}
            onClose={handleCancel}
            variant='advancedResolutionSettings'
            header={<h3>Resolution Settings</h3>}
            footer={
                <>
                    <Button 
                        size="sm"
                        onClick={handleCancel} 
                        type='secondary'
                    >
                        Cancel
                    </Button> 
                    <Button 
                        size="sm"
                        onClick={updateAdvancedSettings} 
                    >
                        Update
                    </Button>  
                </>
            }
        >
            <div className={styles.advancedSettingsContainer}>
                <div className={`${styles.advancedSettingsBlock} ${videoSettingsOpen ? styles.opened : ''}`}>
                    <div onClick={toggleVideoSettings} className={styles.advancedSettingsTitle}>
                        <div className={styles.advancedSettingsTitleText}>Video Settings</div>
                        { videoSettingsOpen ? <ChevronTop className={styles.advancedSettingsTitleIcon}/> : <ChevronBottom className={styles.advancedSettingsTitleIcon}/>}                        
                    </div>
                    {videoSettingsOpen && (
                        <div className={styles.videoSettingsContainer}>
                            {/* Your video settings form elements go here */}
                            <div className={styles.videoSettingsLeft}>
                                <div className={styles.formRow}>
                                    {/* Your audio settings form elements go here */}
                                    <Select
                                        className={styles.advancedSettingsInput}
                                        label='Output Resolution'
                                        help={{
                                            no_header: true,
                                            title: 'Output Resolution',
                                            content: 'Choose one of predefined output resolutions or enter a custom one.'                                            
                                        }}
                                        name='output_resolution'
                                        options={outputResolutionOptions}
                                        value={outputResolution}
                                        onChange={handleOutputResolutionChange}
                                    />            

                                    {
                                        outputResolution === 'custom' &&
                                        <>
                                            <InputField
                                                className={styles.advancedSettingsInput}
                                                label='Width'
                                                help={tooltipData({api: 'transcoding', name: 'width'})}
                                                name='width'
                                                inputText={
                                                    ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0)
                                                        ? localFormat?.stream[resolutionIndex]?.width
                                                        : localFormat?.width)
                                                    || ''
                                                }
                                                onChange={handleInputChange}
                                            />    

                                            <InputField
                                                className={styles.advancedSettingsInput}
                                                label='Height'
                                                help={tooltipData({api: 'transcoding', name: 'height'})}
                                                name='height'
                                                inputText={
                                                    ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0)
                                                        ? localFormat?.stream[resolutionIndex]?.height
                                                        : localFormat?.height)
                                                    || ''
                                                }
                                                onChange={handleInputChange}
                                            />                                         
                                        </>
                                    }

                                                                                                                
                                </div>
                                <div className={styles.formRow}>
                                    <InputField 
                                        className={styles.advancedSettingsInput}
                                        label='CRF' 
                                        help={tooltipData({api: 'transcoding', name: 'quality'})}
                                        name='quality' 

                                        inputText={
                                            ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                                ? localFormat?.stream[resolutionIndex]?.quality
                                                : localFormat?.quality) 
                                            || ''
                                        }    

                                        onChange={handleInputChange}
                                    />
                                    <InputField 
                                        className={styles.advancedSettingsInput}
                                        label='Bitrate, Mbps' 
                                        help={tooltipData({api: 'transcoding', name: 'bitrate'})}
                                        name='bitrate' 

                                        inputText={
                                            ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                                ? localFormat?.stream[resolutionIndex]?.bitrate
                                                : localFormat?.bitrate) 
                                            || ''
                                        }    

                                        onChange={handleInputChange}
                                    />                                    
                                </div>
                                <div className={styles.formRow}>
                                    <InputField 
                                        className={styles.advancedSettingsInput}
                                        label='Min Bitrate, Mbps' 
                                        help={tooltipData({api: 'transcoding', name: 'min_rate'})}
                                        name='video_codec_parameters.min_rate' 

                                        inputText={
                                            ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                                ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.min_rate
                                                : localFormat?.video_codec_parameters?.min_rate) 
                                            || ''
                                        }    

                                        onChange={handleInputChange}
                                    />
                                    <InputField 
                                        className={styles.advancedSettingsInput}
                                        label='Max Bitrate, Mbps' 
                                        help={tooltipData({api: 'transcoding', name: 'max_rate'})}
                                        name='video_codec_parameters.max_rate' 

                                        inputText={
                                            ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                                ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.max_rate
                                                : localFormat?.video_codec_parameters?.max_rate) 
                                            || ''
                                        }    

                                        onChange={handleInputChange}
                                    />                                    
                                </div>
                                <div className={styles.formRow}>
                                    <InputField 
                                        className={styles.advancedSettingsInput}
                                        label='Buffer size, Mbps' 
                                        help={tooltipData({api: 'transcoding', name: 'bufsize'})}
                                        name='video_codec_parameters.bufsize' 

                                        inputText={
                                            ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                                ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.bufsize
                                                : localFormat?.video_codec_parameters?.bufsize) 
                                            || ''
                                        }    

                                        onChange={handleInputChange}
                                    />                                
                                </div>  
                                {/* this is displayed in main with HLS and Dash */}
                                {
                                    (localFormat?.output === 'mp4' || localFormat?.output === 'webm') &&
                                    <div className={styles.formRow}>
                                        <InputField
                                            className={styles.advancedSettingsInput}
                                            label='Framerate'
                                            help={tooltipData({api: 'transcoding', name: 'framerate'})}
                                            name='framerate'
                                            inputText={
                                                ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0)
                                                    ? localFormat?.stream[resolutionIndex]?.framerate
                                                    : localFormat?.framerate)
                                                || ''
                                            }
                                            onChange={handleInputChange}
                                        /> 
                                        <InputField
                                            className={styles.advancedSettingsInput}
                                            label='Keyframe'
                                            help={tooltipData({api: 'transcoding', name: 'keyframe'})}
                                            name='keyframe'
                                            inputText={
                                                ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0)
                                                    ? localFormat?.stream[resolutionIndex]?.keyframe
                                                    : localFormat?.keyframe)
                                                || ''
                                            }
                                            onChange={handleInputChange}
                                        />                                                                          
                                    </div>    
                                } 
                                <div className={styles.formRow}>
                                    <InputField
                                        className={styles.advancedSettingsInput}
                                        label='Aspect Ratio'
                                        help={tooltipData({api: 'transcoding', name: 'aspect_ratio'})}
                                        name='aspect_ratio'
                                        placeholder='16:9'
                                        inputText={
                                            ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0)
                                                ? localFormat?.stream[resolutionIndex]?.aspect_ratio
                                                : localFormat?.aspect_ratio)
                                            || ''
                                        }
                                        onChange={handleInputChange}
                                    /> 
                                    <Select
                                        className={styles.advancedSettingsInput}
                                        label='Resize Mode'
                                        help={tooltipData({api: 'transcoding', name: 'resize_mode'})}
                                        name='resize_mode'
                                        options={resizeModeOptions}
                                        value={
                                            ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0)
                                                ? localFormat?.stream[resolutionIndex]?.resize_mode
                                                : localFormat?.resize_mode)
                                            || 'scale'
                                        }                                      
                                        onChange={handleInputChange}
                                    />                                        
                                    <InputField
                                        className={styles.advancedSettingsInput}
                                        label='Rotate'
                                        help={tooltipData({api: 'transcoding', name: 'rotate'})}
                                        name='rotate'
                                        inputText={
                                            ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0)
                                                ? localFormat?.stream[resolutionIndex]?.rotate
                                                : localFormat?.rotate)
                                            || ''
                                        }
                                        onChange={handleInputChange}
                                    />                                                                          
                                </div>    

                                {
                                    (localFormat?.output === "advanced_hls" || localFormat?.output === "advanced_dash") &&
                                    <div className={styles.formRow}>
                                        <InputField
                                            label='Chunklist Name'
                                            help={tooltipData({api: 'transcoding', name: 'chunklist_name'})}
                                            name='chunklist_name'
                                            placeholder='chunklist.mpd'
                                            inputText={
                                                ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0)
                                                    ? localFormat?.stream[resolutionIndex]?.chunklist_name
                                                    : localFormat?.chunklist_name)
                                                || ''
                                            }
                                            onChange={handleInputChange}
                                        />                                                                            
                                    </div>                                        
                                }

                                <div className={styles.formRow}>
                                    <InputField
                                        label='Tag Video'
                                        help={tooltipData({api: 'transcoding', name: 'tag'})}
                                        name='tag'
                                        placeholder='New video for Netflix'
                                        inputText={
                                            ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0)
                                                ? localFormat?.stream[resolutionIndex]?.tag
                                                : localFormat?.tag)
                                            || ''
                                        }
                                        onChange={handleInputChange}
                                    />                                                                            
                                </div>                                                                                              
                            </div>
                            <div className={styles.videoSettingsRight}>
                                {/* show optimize_bitrate toggle */}
                                {
                                    (format?.output === 'mp4' || format?.output === 'advanced_hls' || format?.output === 'advanced_dash') &&
                                    (
                                        (format?.video_codec === 'libx264' || format?.video_codec === 'libx265') ||
                                        (format?.stream && format?.stream.length > 0 && (format?.stream[0]?.video_codec === 'libx264' || format?.stream[0]?.video_codec === 'libx265'))
                                    ) &&
                                    <div className={styles.advSettingsToggleContainer}>
                                        <div className={styles.formRow}>
                                            <div className={styles.formToggleContainer}>
                                                <div className={styles.formToggleLabel}>
                                                    <Label 
                                                        text="Enable Per-Title Encoding" 
                                                        help={tooltipData({api: 'transcoding', name: 'optimize_bitrate'})}
                                                    />
                                                </div>                                
                                                <FormToggle 
                                                    name='optimize_bitrate'
                                                    checked={
                                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0)
                                                            ? localFormat?.stream[resolutionIndex]?.optimize_bitrate === 1
                                                            : localFormat?.optimize_bitrate === 1) 
                                                        || false
                                                    }  
                                                    onChange={handleBitrateToggle}
                                                />      
                                            </div>
                                        </div>        
                                        {
                                            (((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0)
                                                ? localFormat?.stream[resolutionIndex]?.optimize_bitrate === 1
                                                : localFormat?.optimize_bitrate === 1) 
                                            || false) && (
                                                <div className={styles.formRow}>
                                                    <InputField 
                                                        className={styles.advancedSettingsInput}
                                                        label='Adjust CRF' 
                                                        help={tooltipData({api: 'transcoding', name: 'adjust_crf'})}
                                                        name='adjust_crf' 

                                                        inputText={
                                                            ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                                                ? localFormat?.stream[resolutionIndex]?.adjust_crf
                                                                : localFormat?.adjust_crf) 
                                                            || ''
                                                        }    

                                                        onChange={handleInputChange}
                                                    />
                                                    <InputField 
                                                        className={styles.advancedSettingsInput}
                                                        label='Min CRF' 
                                                        help={tooltipData({api: 'transcoding', name: 'min_crf'})}
                                                        name='min_crf' 

                                                        inputText={
                                                            ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                                                ? localFormat?.stream[resolutionIndex]?.min_crf
                                                                : localFormat?.min_crf) 
                                                            || ''
                                                        }    

                                                        onChange={handleInputChange}
                                                    />  
                                                    <InputField 
                                                        className={styles.advancedSettingsInput}
                                                        label='Max CRF' 
                                                        help={tooltipData({api: 'transcoding', name: 'max_crf'})}
                                                        name='max_crf' 

                                                        inputText={
                                                            ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                                                ? localFormat?.stream[resolutionIndex]?.max_crf
                                                                : localFormat?.max_crf) 
                                                            || ''
                                                        }    

                                                        onChange={handleInputChange}
                                                    />                                                                                                        
                                                </div>
                                            )
                                        }                                                                       
                                    </div>                         
                                }


                                <div className={styles.advSettingsToggleContainer}>
                                    <div className={styles.formRow}>
                                        <div className={styles.formToggleContainer}>
                                            <div className={styles.formToggleLabel}>
                                                <Label 
                                                    text="Create Video Clip" 
                                                    help={{
                                                        no_header: true,
                                                        title: 'Video Clip',
                                                        content: 'Specifying Start time and Duration allows to cut needed part of the source to the output.'
                                                    }}
                                                />
                                            </div>
                                            <FormToggle
                                                name='video_clip_toggle'
                                                checked={videoClipToggle}
                                                onChange={handleVideoClipToggle}
                                            />
                                        </div>
                                    </div>

                                    {
                                        videoClipToggle && (
                                            <div className={styles.formRow}>
                                                <InputField
                                                    className={styles.advancedSettingsInput}
                                                    label='Start Time'
                                                    help={tooltipData({api: 'transcoding', name: 'start_time'})}
                                                    name='start_time'
                                                    inputText={
                                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0)
                                                            ? localFormat?.stream[resolutionIndex]?.start_time
                                                            : localFormat?.start_time)
                                                        || ''
                                                    }
                                                    onChange={handleInputChange}
                                                />
                                                <InputField
                                                    className={styles.advancedSettingsInput}
                                                    label='Duration'
                                                    help={tooltipData({api: 'transcoding', name: 'duration'})}
                                                    name='duration'
                                                    inputText={
                                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0)
                                                            ? localFormat?.stream[resolutionIndex]?.duration
                                                            : localFormat?.duration)
                                                        || ''
                                                    }
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        )
                                    }
                                </div>

                                <div className={styles.advSettingsToggleContainer}>
                                    <div className={styles.formRow}>
                                        <div className={styles.formToggleContainer}>
                                            <div className={styles.formToggleLabel}>
                                                <Label 
                                                    text="Enable Watermark" 
                                                    help={tooltipData({api: 'transcoding', name: 'logo'})}
                                                />
                                            </div>
                                            <FormToggle
                                                name='watermark_toggle'
                                                checked={watermarkToggle}
                                                onChange={handleWatermarkToggle}
                                            />
                                        </div>
                                    </div>

                                    {
                                        watermarkToggle && (
                                            <div className={styles.formBody}>
                                                <div className={styles.formRow}>
                                                    <InputField
                                                        label='Watermark URL'
                                                        help={
                                                            {
                                                                title: 'source',
                                                                name: "source",
                                                                data_type: "string",
                                                                optional: 'required',
                                                                content: 'This should be publicly available url.',
                                                                link: {
                                                                    text: 'Show API',
                                                                    url: 'https://docs.qencode.com/api-reference/transcoding/#start_encode2___query__attributes--format__attributes--logo__attributes--source__attributes'
                                                                }
                                                            }                                                            
                                                        }
                                                        name='logo.source'
                                                        placeholder='https://portal.qencode.com/img/watermark/4096_2160.png'
                                                        inputText={
                                                            ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0)
                                                                ? localFormat?.stream[resolutionIndex]?.logo?.source
                                                                : localFormat?.logo?.source)
                                                            || ''
                                                        }
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className={styles.formRow}>
                                                    <InputField
                                                        className={styles.advancedSettingsInput}
                                                        label='X Position'
                                                        help={tooltipData({api: 'transcoding', name: 'x'})}
                                                        name='logo.x'
                                                        inputText={
                                                            ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0)
                                                                ? localFormat?.stream[resolutionIndex]?.logo?.x
                                                                : localFormat?.logo?.x)
                                                            || ''
                                                        }
                                                        onChange={handleInputChange}
                                                    />
                                                    <InputField
                                                        className={styles.advancedSettingsInput}
                                                        label='Y Position'
                                                        help={tooltipData({api: 'transcoding', name: 'y'})}
                                                        name='logo.y'
                                                        inputText={
                                                            ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0)
                                                                ? localFormat?.stream[resolutionIndex]?.logo?.y
                                                                : localFormat?.logo?.y)
                                                            || ''
                                                        }
                                                        onChange={handleInputChange}
                                                    />                                                
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>

                                <div className={styles.advSettingsToggleContainer}>
                                    <div className={styles.formRow}>
                                        <div className={styles.formToggleContainer}>
                                            <div className={styles.formToggleLabel}>
                                                <Label 
                                                    text="Convert HDR to SDR" 
                                                    help={tooltipData({api: 'transcoding', name: 'hdr_to_sdr'})}
                                                />
                                            </div>                                
                                            <FormToggle 
                                                name='hdr_to_sdr'
                                                checked={
                                                    ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0)
                                                        ? localFormat?.stream[resolutionIndex]?.hdr_to_sdr === 1
                                                        : localFormat?.hdr_to_sdr === 1) 
                                                    || false
                                                }  
                                                onChange={handleInputChange} 
                                            />      
                                        </div>
                                    </div>                                     
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className={`${styles.advancedSettingsBlock} ${audioSettingsOpen ? styles.opened : ''}`}>
                    <div onClick={toggleAudioSettings} className={styles.advancedSettingsTitle}>
                        <div className={styles.advancedSettingsTitleText}>Audio Settings</div>
                        { audioSettingsOpen ? <ChevronTop className={styles.advancedSettingsTitleIcon}/> : <ChevronBottom className={styles.advancedSettingsTitleIcon}/>}
                    </div>
                    {audioSettingsOpen && (
                        <div className={styles.formRow}>
                            {/* Your audio settings form elements go here */}
                            <Select
                                className={styles.advancedSettingsInput}
                                label='Audio Codec'
                                help={tooltipData({api: 'transcoding', name: 'audio_codec'})}
                                name='audio_codec'
                                placeholder=''
                                options={audioCodecOption[localFormat?.output]}
                                //value={localFormat?.audio_codec || audioCodecOption[localFormat?.output][0]?.value || ''}

                                value={
                                    ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                        ? localFormat?.stream[resolutionIndex]?.audio_codec || audioCodecOption[localFormat?.output]?.[0]?.value
                                        : localFormat?.audio_codec || audioCodecOption[localFormat?.output]?.[0]?.value) 
                                    || ''
                                }                                

                                onChange={handleInputChange}
                            />                            
                            <InputField 
                                className={styles.advancedSettingsInput}
                                label='Audio Bitrate' 
                                help={tooltipData({api: 'transcoding', name: 'audio_bitrate'})}
                                name='audio_bitrate' 
                                placeholder='64'
                                //inputText={localFormat.audio_bitrate || ''} 

                                inputText={
                                    ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                        ? localFormat?.stream[resolutionIndex]?.audio_bitrate
                                        : localFormat?.audio_bitrate) 
                                    || ''
                                }    

                                onChange={handleInputChange}
                            />
                            <InputField 
                                className={styles.advancedSettingsInput}
                                label='Sample Rate' 
                                help={tooltipData({api: 'transcoding', name: 'audio_sample_rate'})}
                                name='audio_sample_rate' 
                                placeholder='44100'
                                //inputText={localFormat.audio_sample_rate || ''} 

                                inputText={
                                    ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                        ? localFormat?.stream[resolutionIndex]?.audio_sample_rate
                                        : localFormat?.audio_sample_rate) 
                                    || ''
                                }    

                                onChange={handleInputChange}
                            />    
                            <InputField 
                                className={styles.advancedSettingsInput}
                                label='No. of channels' 
                                help={tooltipData({api: 'transcoding', name: 'audio_channels_number'})}
                                name='audio_channels_number' 
                                placeholder='2'
                                //inputText={localFormat.audio_channels_number || ''} 

                                inputText={
                                    ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                        ? localFormat?.stream[resolutionIndex]?.audio_channels_number
                                        : localFormat?.audio_channels_number) 
                                    || ''
                                }   

                                onChange={handleInputChange}
                            />                            
                        </div>
                    )}
                </div>
                <div className={`${styles.advancedSettingsBlock} ${codecSettingsOpen ? styles.opened : ''}`}>
                    <div onClick={toggleCodecSettings} className={styles.advancedSettingsTitle}>
                        <div className={styles.advancedSettingsTitleText}>Codec Settings</div>
                        { codecSettingsOpen ? <ChevronTop className={styles.advancedSettingsTitleIcon}/> : <ChevronBottom className={styles.advancedSettingsTitleIcon}/>}                        
                    </div>
                    {codecSettingsOpen && (
                        <>
                            <div className={styles.formRow}>
                                {/* Your codec settings form elements go here */}                          
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='Color Space' 
                                    help={tooltipData({api: 'transcoding', name: 'color_space'})}
                                    name='color_space' 

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.color_space
                                            : localFormat?.color_space) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='Color Range' 
                                    help={tooltipData({api: 'transcoding', name: 'color_range'})}
                                    name='color_range' 

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.color_range
                                            : localFormat?.color_range) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />    
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='Color TRC' 
                                    help={tooltipData({api: 'transcoding', name: 'color_trc'})}
                                    name='color_trc' 

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.color_trc
                                            : localFormat?.color_trc) 
                                        || ''
                                    }   

                                    onChange={handleInputChange}
                                />    
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='Color Primaries' 
                                    help={tooltipData({api: 'transcoding', name: 'color_primaries'})}
                                    name='color_primaries' 

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.color_primaries
                                            : localFormat?.color_primaries) 
                                        || ''
                                    }   

                                    onChange={handleInputChange}
                                />                                                    
                            </div>  
                            <div className={styles.formRow}>
                                {/* Your codec settings form elements go here */}                          
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='VProfile' 
                                    help={tooltipData({api: 'transcoding', name: 'vprofile'})}
                                    name='video_codec_parameters.vprofile' 
                                    placeholder='baseline'

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.vprofile
                                            : localFormat?.video_codec_parameters?.vprofile) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='Level' 
                                    help={tooltipData({api: 'transcoding', name: 'level'})}
                                    name='video_codec_parameters.level' 
                                    placeholder='31'

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.level
                                            : localFormat?.video_codec_parameters?.level) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />    
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='Coder' 
                                    help={tooltipData({api: 'transcoding', name: 'coder'})}
                                    name='video_codec_parameters.coder' 
                                    placeholder='0'

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.coder
                                            : localFormat?.video_codec_parameters?.coder) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />   
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='Flags2' 
                                    help={tooltipData({api: 'transcoding', name: 'flags2'})}
                                    name='video_codec_parameters.flags2' 
                                    placeholder='-bpyramid+fastpskip-dct8x8'

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.flags2
                                            : localFormat?.video_codec_parameters?.flags2) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />  
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='Partitions' 
                                    help={tooltipData({api: 'transcoding', name: 'partitions'})}
                                    name='video_codec_parameters.partitions' 
                                    placeholder='+parti8x8+parti4x4+partp8x8+partb8x8'

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.partitions
                                            : localFormat?.video_codec_parameters?.partitions) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />                                                                                                                                                     
                            </div>   
                            <div className={styles.formRow}>
                                {/* Your codec settings form elements go here */}                          
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='Directpred' 
                                    help={tooltipData({api: 'transcoding', name: 'directpred'})}
                                    name='video_codec_parameters.directpred' 
                                    placeholder='2'

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.directpred
                                            : localFormat?.video_codec_parameters?.directpred) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='Me_method' 
                                    help={tooltipData({api: 'transcoding', name: 'me_method'})}
                                    name='video_codec_parameters.me_method' 
                                    placeholder='hex'

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.me_method
                                            : localFormat?.video_codec_parameters?.me_method) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />    
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='Subq' 
                                    help={tooltipData({api: 'transcoding', name: 'subq'})}
                                    name='video_codec_parameters.subq' 

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.subq
                                            : localFormat?.video_codec_parameters?.subq) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />   
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='Trellis' 
                                    help={tooltipData({api: 'transcoding', name: 'trellis'})}
                                    name='video_codec_parameters.trellis' 

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.trellis
                                            : localFormat?.video_codec_parameters?.trellis) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />  
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='Refs' 
                                    help={tooltipData({api: 'transcoding', name: 'refs'})}
                                    name='video_codec_parameters.refs' 

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.refs
                                            : localFormat?.video_codec_parameters?.refs) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />                                                                                                                                                     
                            </div>  
                            <div className={styles.formRow}>
                                {/* Your codec settings form elements go here */}                          
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='Cmp' 
                                    help={tooltipData({api: 'transcoding', name: 'cmp'})}
                                    name='video_codec_parameters.cmp' 

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.cmp
                                            : localFormat?.video_codec_parameters?.cmp) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='Me_range' 
                                    help={tooltipData({api: 'transcoding', name: 'me_range'})}
                                    name='video_codec_parameters.me_range' 

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.me_range
                                            : localFormat?.video_codec_parameters?.me_range) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />    
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='sc_threshold' 
                                    help={tooltipData({api: 'transcoding', name: 'sc_threshold'})}
                                    name='video_codec_parameters.sc_threshold' 

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.sc_threshold
                                            : localFormat?.video_codec_parameters?.sc_threshold) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />   
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='i_qfactor' 
                                    help={tooltipData({api: 'transcoding', name: 'i_qfactor'})}
                                    name='video_codec_parameters.i_qfactor' 

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.i_qfactor
                                            : localFormat?.video_codec_parameters?.i_qfactor) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />  
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='B_strategy' 
                                    help={tooltipData({api: 'transcoding', name: 'b_strategy'})}
                                    name='video_codec_parameters.b_strategy' 

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.b_strategy
                                            : localFormat?.video_codec_parameters?.b_strategy) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />                                                                                                                                                     
                            </div>   
                            <div className={styles.formRow}>
                                {/* Your codec settings form elements go here */}                          
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='Qcomp' 
                                    help={tooltipData({api: 'transcoding', name: 'qcomp'})}
                                    name='video_codec_parameters.qcomp' 

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.qcomp
                                            : localFormat?.video_codec_parameters?.qcomp) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='Qmin' 
                                    help={tooltipData({api: 'transcoding', name: 'qmin'})}
                                    name='video_codec_parameters.qmin' 

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.qmin
                                            : localFormat?.video_codec_parameters?.qmin) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />    
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='Qdiff' 
                                    help={tooltipData({api: 'transcoding', name: 'qdiff'})}
                                    name='video_codec_parameters.qdiff' 

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.qdiff
                                            : localFormat?.video_codec_parameters?.qdiff) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />   
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='Sws_flags' 
                                    help={tooltipData({api: 'transcoding', name: 'sws_flags'})}
                                    name='video_codec_parameters.sws_flags' 

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.sws_flags
                                            : localFormat?.video_codec_parameters?.sws_flags) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />                                                                                                                                                      
                            </div>     
                            <div className={styles.formRow}>
                                {/* Your codec settings form elements go here */}                          
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='Preset' 
                                    help={tooltipData({api: 'transcoding', name: 'preset'})}
                                    name='video_codec_parameters.preset' 

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.preset
                                            : localFormat?.video_codec_parameters?.preset) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='Flags' 
                                    help={tooltipData({api: 'transcoding', name: 'flags'})}
                                    name='video_codec_parameters.flags' 

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.flags
                                            : localFormat?.video_codec_parameters?.flags) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />    
                                <InputField 
                                    className={styles.advancedSettingsInput}
                                    label='Rc_lookahead' 
                                    help={tooltipData({api: 'transcoding', name: 'rc_lookahead'})}
                                    name='video_codec_parameters.rc_lookahead' 

                                    inputText={
                                        ((resolutionIndex !== null) && (localFormat?.stream && localFormat?.stream.length > 0) 
                                            ? localFormat?.stream[resolutionIndex]?.video_codec_parameters?.rc_lookahead
                                            : localFormat?.video_codec_parameters?.rc_lookahead) 
                                        || ''
                                    }    

                                    onChange={handleInputChange}
                                />                                                                                                                                                      
                            </div>                                                                                                                                                                    
                        </>

                    )}
                </div>
            </div>
        </Modal>
    );
}
