import PropTypes from "prop-types";
import React from "react";

export const ArrowLeft = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path d="M2.7822 7.93128C2.40593 8.23236 2.40593 8.76764 2.7822 9.06872L6.21006 12.3116C6.74438 12.7391 7.5806 12.3921 7.5806 11.7428V9.44474H13.467C14.0375 9.44474 14.5 9.02182 14.5 8.50012C14.5 7.97843 14.0375 7.55551 13.467 7.55551L7.5806 7.55551V5.25716C7.5806 4.6079 6.74438 4.26091 6.21006 4.68845L2.7822 7.93128Z" fill="currentColor"/>
    </svg>    
  );
};

ArrowLeft.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

ArrowLeft.defaultProps = {
  color: 'currentColor', // Or any default color
};