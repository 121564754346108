import {Modal} from "components/Modal";
import {ReactComponent as APIRegenerate} from "assets/images/APIRegenerate.svg";
import {Button} from "components/Button";
import React, {useState} from "react";
import {recreateProjectApiKey} from "api/qencode-account-api";
import {useAuth} from "context/AuthContext";
import PropTypes from "prop-types";
import ErrorMessage from "components/ErrorMessage";

function RecreateKeyModal({
  open,
  handleCancel,
  projectId,
  onSuccessfulFinish,
  }) {
  const { getToken } = useAuth();
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  async function handleSubmit() {
    const accessToken = await getToken();
    setSubmitting(true);
    const {success, error} = await recreateProjectApiKey({
      accessToken,
      id: projectId,
      type: 'live',
    });
    if (success) {
      handleCancel();
      onSuccessfulFinish?.();
    } else {
      setErrorMessage(error);
    }
    setSubmitting(false);
  }

  return (
    <Modal
      isOpen={open}
      onClose={handleCancel}
      variant="popUp"
      header={
        <>
          <APIRegenerate/>
          <h3>
            Recreate API Key?
          </h3>
          <p>
            Are you sure you want to recreate your API Key? This could affect applications currently using your current API Key for this Project.
          </p>
        </>
      }
      footer={
        <>
          <Button
            onClick={handleCancel}
            type="secondary"
            variant="modalFooter"
          >
            Cancel
          </Button>
          <Button
            variant="modalFooter"
            onClick={handleSubmit}
            state={ submitting ? 'disabled' : 'default' }
            hint={ errorMessage }
          >
            Recreate
          </Button>
        </>
      }
    >
      { errorMessage && <ErrorMessage>{ errorMessage }</ErrorMessage> }
    </Modal>
  );
}

RecreateKeyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  onSuccessfulFinish: PropTypes.func,
};

export default RecreateKeyModal;