import {backendParamNames, columnIds, columnIdToFilterName} from "pages/Transcoding/JobHistoryPage/config";
import {format} from "date-fns";

export default function buildRequestParams(pagination, columnFilters, sorting) {
  const params = new URLSearchParams();
  const limit = pagination.pageSize;
  const offset = pagination.pageIndex * pagination.pageSize;
  if (offset > 0) {
    params.set(backendParamNames.offset, offset);
  }
  params.set(backendParamNames.limit, limit);
  
  const sizeFilter = columnFilters.find((filter) => filter.id === columnIds.size);
  
  if (sizeFilter) {
    const [min, max] = sizeFilter.value;
    params.set(backendParamNames.sizeMin, min);
    params.set(backendParamNames.sizeMax, max);
  }
  
  const statusFilter = columnFilters.find((filter) => filter.id === columnIds.status);
  if (statusFilter) {
    for (let status of statusFilter.value) {
      params.append(backendParamNames.status, status); /* Intentionally allow duplicate param names. */
    }
  }
  
  const dateCreatedFilter = columnFilters.find((filter) => filter.id === columnIds.dateCreated);
  if (dateCreatedFilter) {
    const [min, max] = dateCreatedFilter.value;
    const dateFormat = 'yyyy-MM-dd HH:mm:ss';
    params.set(backendParamNames.startDate, format(min, dateFormat));
    params.set(backendParamNames.endDate, format(max, dateFormat));
  }
  
  const lengthFilter = columnFilters.find((filter) => filter.id === columnIds.videoLength);
  if (lengthFilter) {
    const [min, max] = lengthFilter.value;
    params.set(backendParamNames.durationMin, min);
    params.set(backendParamNames.durationMax, max);
  }
  
  const projectFilter = columnFilters.find((filter) => filter.id === columnIds.project);
  if (projectFilter) {
    for (let project of projectFilter.value) {
      params.append(backendParamNames.projectName, project); /* Intentionally allow duplicate param names. */
    }
  }
  
  if (sorting.length > 0) {
    const sorter = sorting[0];
    if (sorter) {
      const orderType = sorter.desc ? 'desc' : 'asc';
      params.set(backendParamNames.orderType, orderType);
      const backendFilterName = columnIdToFilterName[ sorter.id ];
      params.set(backendParamNames.orderBy, backendFilterName);
    }
  }
  
  return params;
}
