export const usageByTypeIds = {
  impressions: 'impressions',
  plays: 'plays',
  minutesPlayed: 'minutesPlayed',
};

export const playerUsageByIdToName = {
  [usageByTypeIds.impressions]: 'Impressions',
  [usageByTypeIds.plays]: 'Plays',
  [usageByTypeIds.minutesPlayed]: 'Minutes played',
}

export const typeToColor = {
  [usageByTypeIds.impressions]: `#006ac5`,
  [usageByTypeIds.plays]: `#1292ff`,
  [usageByTypeIds.minutesPlayed]: `#082876`,
}

export const options = [
  {
    label: playerUsageByIdToName[usageByTypeIds.impressions],
    value: usageByTypeIds.impressions,
  },
  {
    label: playerUsageByIdToName[usageByTypeIds.plays],
    value: usageByTypeIds.plays,
  },
  {
    label: playerUsageByIdToName[usageByTypeIds.minutesPlayed],
    value: usageByTypeIds.minutesPlayed,
  },
];

export const template = {
  [usageByTypeIds.impressions]: 0,
  [usageByTypeIds.plays]: 0,
  [usageByTypeIds.minutesPlayed]: 0,
}
