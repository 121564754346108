import React from 'react';
import {Button} from "components/Button";
import {Add} from "icons";
import { modals } from 'pages/Streaming/StreamsPage/config';
import PropTypes from "prop-types";

function CreateStreamButton({ handleOpenModal }) {
    return (
        <Button
            icon={<Add/>}
            onClick={ () =>  handleOpenModal( modals.create ) }
        >
            Create Stream
        </Button>
    );
}

CreateStreamButton.propTypes = {
    handleOpenModal: PropTypes.func.isRequired,
};

export default CreateStreamButton;