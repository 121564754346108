import PropTypes from "prop-types";
import React from "react";

export const FolderAlt = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M3.7 1.90002C2.48497 1.90002 1.5 2.885 1.5 4.10002V4.90002V12.1C1.5 13.7569 2.84315 15.1 4.5 15.1H12.5C14.1569 15.1 15.5 13.7569 15.5 12.1V7.30002C15.5 5.64317 14.1569 4.30002 12.5 4.30002H10.5259C10.4591 4.30002 10.3966 4.2666 10.3595 4.21096L9.47202 2.87969C9.06399 2.26765 8.37708 1.90002 7.64151 1.90002H3.7ZM2.7 4.10002C2.7 3.54774 3.14771 3.10002 3.7 3.10002H7.64151C7.97586 3.10002 8.28809 3.26713 8.47356 3.54532L9.36108 4.8766C9.62073 5.26608 10.0579 5.50002 10.5259 5.50002H12.5C13.4941 5.50002 14.3 6.30591 14.3 7.30002V12.1C14.3 13.0941 13.4941 13.9 12.5 13.9H4.5C3.50589 13.9 2.7 13.0941 2.7 12.1V4.90002V4.10002ZM4.25986 3.70004C3.81804 3.70004 3.45986 4.05821 3.45986 4.50004V5.30004C3.45986 5.52095 3.63895 5.70004 3.85986 5.70004H8.19343C8.50434 5.70004 8.69639 5.36085 8.53642 5.09424L7.93294 4.08844C7.78837 3.84748 7.52796 3.70004 7.24695 3.70004H4.25986Z" fill="currentColor"/>
    </svg>    
  );
};

FolderAlt.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

FolderAlt.defaultProps = {
  color: 'currentColor', // Or any default color
};