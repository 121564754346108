import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {ChevronDown} from "icons";
import {Dropdown} from "components/Dropdown";
import style from './style.module.css';

function Select({ value, options, onChange }) {
  const rootRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  
  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }
  
  function clickOption(...arg) {
    onChange(...arg);
    setIsOpen(false);
  }
  
  const handleClickOutside = useCallback((event) => {
    if (
      !rootRef?.current?.contains(event.target)
    ) {
      setIsOpen(false);
    }
  }, [rootRef]);
  
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);
  
  return (
    <div className={ style.select }>
      <button
        className={ style.dateComponent }
        onClick={ toggleDropdown }
      >
        <div>{ value }</div>
        <ChevronDown  />
      </button>
      { isOpen && (
        <div className={style.dropdownContainer} ref={rootRef}>
          <Dropdown
            className={ style.dropdownContent }
            options={options}
            onOptionClick={clickOption}
          />
        </div>
      ) }
    </div>
  );
}


Select.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
  })),
  onChange: PropTypes.func.isRequired,
};

export default Select;