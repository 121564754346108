import PropTypes from "prop-types";
import React from "react";

export const Download = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M3.09992 8.49997C3.09992 5.51764 5.51762 3.09999 8.5 3.09999C11.4824 3.09999 13.9001 5.51764 13.9001 8.49997C13.9001 11.4823 11.4824 13.8999 8.5 13.8999C5.51762 13.8999 3.09992 11.4823 3.09992 8.49997ZM8.5 1.89999C4.85487 1.89999 1.8999 4.8549 1.8999 8.49997C1.8999 12.145 4.85487 15.0999 8.5 15.0999C12.1451 15.0999 15.1001 12.145 15.1001 8.49997C15.1001 4.8549 12.1451 1.89999 8.5 1.89999ZM9.03238 5.49998C9.28125 5.49998 9.48301 5.68571 9.48301 5.91482V8.61126H10.6621C10.7426 8.61126 10.8205 8.63775 10.8817 8.68596C11.0236 8.79765 11.0402 8.99405 10.9189 9.12463L8.84679 11.3548C8.83179 11.3709 8.81546 11.386 8.79796 11.3998C8.60917 11.5491 8.32466 11.5292 8.1625 11.3554L6.08155 9.12512C6.02891 9.06869 5.99996 8.99677 5.99996 8.92239C5.99996 8.75056 6.15128 8.61126 6.33793 8.61126H7.54019V5.91482C7.54019 5.68571 7.74194 5.49998 7.99082 5.49998H9.03238Z" fill="currentColor"/>
    </svg>    
  );
};

Download.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Download.defaultProps = {
  color: 'currentColor', // Or any default color
};