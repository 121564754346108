import PropTypes from "prop-types";
import React from "react";

export const Stopped2 = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path d="M5.57675 7.12036L4.96942 5.65576C4.90747 5.50635 4.76166 5.40894 4.59991 5.40894C4.379 5.40894 4.19992 5.58813 4.19992 5.80884V9.80005C4.19992 10.9045 5.09534 11.8 6.19992 11.8H9.905C10.0737 11.8 10.2243 11.6941 10.2814 11.5354C10.3562 11.3274 10.2482 11.0984 10.0403 11.0234L9.44833 10.8108C7.69577 10.1807 6.2901 8.84082 5.57675 7.12036Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.99991 2.3999C4.01169 2.3999 2.3999 4.01172 2.3999 6V10C2.3999 11.9883 4.01169 13.6001 5.99991 13.6001H9.99991C11.9881 13.6001 13.5999 11.9883 13.5999 10V6C13.5999 4.01172 11.9881 2.3999 9.99991 2.3999H5.99991ZM3.59991 6C3.59991 4.67456 4.67444 3.6001 5.99991 3.6001H9.99991C11.3254 3.6001 12.3999 4.67456 12.3999 6V10C12.3999 11.3254 11.3254 12.3999 9.99991 12.3999H5.99991C4.67444 12.3999 3.59991 11.3254 3.59991 10V6Z" fill="currentColor"/>
    </svg>    
  );
};

Stopped2.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Stopped2.defaultProps = {
  color: 'currentColor', // Or any default color
};