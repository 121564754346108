import React from 'react';
import PropTypes from 'prop-types';
import { Toggle } from 'components/Toggle'

export const FormToggle = ({ name, checked, label, disabled, onChange }) => {
  const handleChange = (e) => {
    onChange({ target: { name, value: e.target.checked } });
  };

  return (
    <Toggle
      label={label}
      checked={checked}
      disabled={disabled}
      onChange={handleChange}
    />
  );
};

FormToggle.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

FormToggle.defaultProps = {
  checked: false,
  label: '',
  disabled: false,
};
