import PropTypes from "prop-types";
import React from "react";

export function LookingGlass({ className, color, label }) {
  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg
      className={className}
      role="img"
      aria-label={label}
      style={style}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.87887 12.0354C9.5734 12.0354 11.7577 9.7889 11.7577 7.0177C11.7577 4.2465 9.5734 2 6.87887 2C4.18435 2 2 4.2465 2 7.0177C2 9.7889 4.18435 12.0354 6.87887 12.0354Z"
        stroke="currentColor"
        strokeWidth="1.2"
      />
      <path
        d="M14 13.9999L10.6072 10.8137"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>

  );
}

LookingGlass.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

LookingGlass.defaultProps = {
  color: 'currentColor', // Or any default color
};
