import PropTypes from "prop-types";
import React from "react";

export const Print = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none"
    >
      <path d="M4.92882 7.45741C4.61322 7.45741 4.35739 7.72413 4.35739 8.05316C4.35739 8.38218 4.61322 8.6489 4.92882 8.6489H5.6431C5.95869 8.6489 6.21453 8.38218 6.21453 8.05316C6.21453 7.72413 5.95869 7.45741 5.6431 7.45741H4.92882Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.35739 5.22337V3.5851C4.35739 2.43354 5.2528 1.49997 6.35739 1.49997H10.6431C11.7477 1.49997 12.6431 2.43354 12.6431 3.5851V5.22337H13.0241C14.3915 5.22337 15.5002 6.37874 15.5002 7.80461V11.5286C15.5002 12.9545 14.3915 14.1099 13.0241 14.1099H12.0717L12.0686 14.1099C12.0196 14.8861 11.4002 15.5 10.6432 15.5H6.35748C5.6005 15.5 4.98103 14.8861 4.93203 14.1099L4.92882 14.1099H3.97643C2.60899 14.1099 1.50024 12.9545 1.50024 11.5286V7.80461C1.50024 6.37873 2.60899 5.22337 3.97643 5.22337H4.35739ZM5.50024 3.5851C5.50024 3.09156 5.88401 2.69146 6.35739 2.69146H10.6431C11.1165 2.69146 11.5002 3.09156 11.5002 3.5851V5.22337H5.50024V3.5851ZM13.0241 12.9184H12.0718V11.6276C12.0718 10.8051 11.4322 10.1383 10.6432 10.1383H6.35748C5.5685 10.1383 4.92891 10.8051 4.92891 11.6276V12.9184H3.97643C3.23994 12.9184 2.6431 12.2962 2.6431 11.5286V7.80461C2.6431 7.03702 3.23994 6.41486 3.97643 6.41486H13.0241C13.7606 6.41486 14.3574 7.03702 14.3574 7.80461V11.5286C14.3574 12.2962 13.7606 12.9184 13.0241 12.9184Z" fill="currentColor"/>
    </svg>    
  );
};

Print.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Print.defaultProps = {
  color: 'currentColor', // Or any default color
};