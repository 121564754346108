import React from 'react';
import PropTypes from 'prop-types';
import styles from './NoData.module.css';

export const NoData = ({ image, title, description, footer }) => {
    return (
        <div className={styles.noDataContainer}>
            { image && <div className={styles.noDataImage}>{image}</div> }
            { title && <h3 className={styles.noDataTitle}>{title}</h3>}
            { description && <p className={styles.noDataDescription}>{description}</p>}
            { footer && <div className={styles.noDataFooter}>{footer}</div>}
        </div>
    );
};

NoData.propTypes = {
    image: PropTypes.element,
    title: PropTypes.string,
    description: PropTypes.node,
    footer: PropTypes.node,
};

NoData.defaultProps = {
    image: null,
    footer: null,
};

export default NoData;
