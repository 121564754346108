import domainTypes from "helpers/domainTypes";
import {signingKeySubtypesRequiredOneOf} from "pages/SigningKeysPage/CreateSigningKeyButton";

const fieldValidators = {
    first_name: (value) => {
        if (!value.trim()) return 'First name is required.';
        if (value.trim().length < 2) return 'Must be at least 2 characters';
        return '';
    },
    last_name: (value) => {
        if (!value.trim()) return 'Last name is required.';
        if (value.trim().length < 2) return 'Must be at least 2 characters';
        return '';
    },
    organization: (value) => {
        if (!value.trim()) return 'Organization is required.';
        if (value.trim().length < 2) return 'Must be at least 2 characters';
        return '';
    },
    phone: (value) => {
        if (!value.trim()) return 'Phone is required.';
        if (value.trim().length < 8) return 'Must be at least 8 characters';
        if (value.trim().length > 32) return 'Must have less than 32 characters';
        return '';
    },
    email: (value) => {
        if (!value.trim()) return 'Email is required.';
        // Optionally add more specific email validation here
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) return 'Please enter a valid email address.';
        return '';
    },
    password: (value, credentials) => {
        if (credentials.provider) return ''; // Skip validation if SSO provider is present

        // For the login form, only check if a value is provided
        if (credentials.formContext === 'login') {
            return !value ? 'Password is required.' : '';
        }

        // For the 2FA form, only check if a value is provided
        if (credentials.formContext === '2FA') {
            return !value ? 'Password is required.' : '';
        }

        // Comprehensive validation for other contexts
        if (!value) return 'Password is required.';
        if (value.trim().length < 8) return 'Password must be at least 8 characters.';
        if (!/[A-Z]/.test(value)) return 'Password must contain at least one uppercase letter.';
        if (!/[a-z]/.test(value)) return 'Password must contain at least one lowercase letter.';
        if (!/\d/.test(value)) return 'Password must contain at least one numeral.';

        // Check if passwords match only if confirm_password is part of the validation
        if (credentials.confirm_password && value !== credentials.confirm_password) {
            return 'Passwords do not match.';
        }        

        return '';
    },    
    password_confirm: (value, credentials) => {
        if (!value) return 'Confirm Password is required.';
        // No need for detailed validation as it must simply match the password
        if (value !== credentials.password) return 'Passwords do not match.';
        return '';
    },
    // in account settings form
    email_confirm: (value, credentials) => {
      if (!value) return 'Email is required.';
      // No need for detailed validation as it must simply match the current email
      if (value !== credentials.current_email) return 'Email does not match.';
      return '';
    },    
    current_email: (value) => {
      if (!value) return 'Email is required.';
      return '';
    },     
    timezone: (value) => {
        return '';
    },
    current_password: (value) => {
        if (!value) return 'Password is required.';
        return '';
    }, 
    website: (value) => {
      value = value.trim(); // Trim the whitespace from both ends of the input
  
      if (value.length < 5) return 'URL must have at least 5 characters';
      if (value.length > 2048) return 'URL must have less than 2048 characters';
  
      // Prepend http:// if no protocol is provided
      if (!/^https?:\/\//i.test(value)) {
          value = 'http://' + value;
      }
  
      try {
          const url = new URL(value); // Attempt to create a URL object
          const hostname = url.hostname;
  
          // Check if the hostname is valid (contains at least one dot)
          if (!hostname || !hostname.includes('.')) {
              return 'Please enter a valid URL';
          }
  
          return ''; // Return an empty string if the URL is valid
      } catch (e) {
          return 'Please enter a valid URL';
      }
    },
    name: (value) => {
        if (value.trim().length < 2) return 'Must be at least 2 characters.';
        if (value.trim().length > 2048) return 'Must have less than 2048 characters';
        return '';
    },
    address: (value) => {
        if (value.trim().length < 5) return 'Must have at least 5 characters';
        if (value.trim().length > 2048) return 'Must have less than 2048 characters';
        return '';
    },
    role: (value) => {
        const validRoles = ['developer', 'admin', 'billing'];
        if (!value.trim()) return 'Role is required.';
        if (!validRoles.includes(value)) return `Role must be one of the following: ${validRoles.join(', ')}.`;
        return '';
    },
    domainName: (value) => {
      if (value?.length < 1) {
        return 'Domain name is required';
      }
      return '';
    },
    domainType: (value) => {
      if (!domainTypes.find((type) => type.value === value)) {
        return 'Domain type is required';
      }
      return '';
    },
    signingKeyType: (value) => {
      if (!value) {
        return 'Please specify signing key type';
      }
      return '';
    },
    bucket: (value) => {
      if (!value) {
        return 'Please specify bucket';
      }
      return '';
    },
    signingKeySubtype: (value) => {
      const keyName = Object.keys(value)[0];

      if (keyName) {
        if (signingKeySubtypesRequiredOneOf.includes(keyName)) {
          return ''
        } else {
          return 'Please fill in required field.';
        }
      } else {
        return 'Please fill in required field.';
      }
    },
    // in transcoding builder
    video_url: (value) => {
      value = value.trim(); // Trim the whitespace from both ends of the input
      if (!value) return 'Video URL is required';

      if (value.includes(' ')) return 'URL must not contain spaces'; // Check for spaces in the URL
  
      try {
          new URL(value); // Attempt to create a URL object
          return ''; // Return an empty string if the URL is valid
      } catch (e) {
          return 'Please enter a valid URL';
      }
    },
    qencode_bucket_name: (value) => {
      value = value.trim(); // Trim the whitespace from both ends of the input
      if (!value) return 'Bucket name is required';

      if (value.length < 6 || value.length > 63) {
        return 'The bucket name must be from 6 to 63 characters max.';
      }

      if (/\s/.test(value)) {
          return 'No spaces';
      }

      const startEndPattern = /^[a-z0-9]([a-z0-9-]*[a-z0-9])?$/;
      if (!startEndPattern.test(value)) {
          return 'Must start and end with a lowercase letter or number';
      }

      const allowedCharsPattern = /^[a-z0-9-]+$/;
      if (!allowedCharsPattern.test(value)) {
          return 'Only Latin lower-case characters, numbers, and dashes are allowed';
      }

      return '';
    },  
    key_name: (value) => {
      if (!value) return 'Value is required.';

      if (value.length < 1) return 'Must be at least 1 character.';
      if (value.length > 1024) return 'Must have less than 1024 characters';

      return '';
    },  
    transcoding_template_name: (value) => {
      if (!value) return 'Value is required.';

      if (value.length < 3) return 'Must be at least 3 characters.';
      if (value.length > 254) return 'Must have less than 254 characters';

      return '';
    },    
    // folder_name field is used in BucketDetailsPage
    folder_name: (value) => {
      if (!value.trim()) return 'Folder name cannot be empty';
      if (value.length < 1) return 'Folder name must be at least 1 character';
      if (value.length > 1024) return 'Folder name must be less than 1024 characters';

      // this would allow other alphabet letters
      if (/[^a-zA-Z0-9 _\p{L}-]/u.test(value)) return 'Folder name can only contain letters, numbers, spaces, underscores, and dashes';
      return '';
    },         
    // file_name field is used in BucketDetailsPage
    file_name: (value) => {
      if (!value.trim()) return 'File name cannot be empty';
      if (value.length < 1) return 'File name must be at least 1 character';
      if (value.length > 1024) return 'File name must be less than 1024 characters';
  
      // Check for disallowed patterns
      if (value.startsWith('/') || value.endsWith('/') || value.includes('//')) return 'File name cannot start with /, end with /, or contain //';
      
      // Allow all other characters, including dots
      return '';
    },             
};

export const validateFields = (credentials, fieldsToValidate = [], formContext = '') => {
    let errors = {};
    let isValid = true;

    fieldsToValidate.forEach((field) => {
        // // Pass the entire credentials object for complex validations that might involve other fields
        // const errorMessage = fieldValidators[field](credentials[field], credentials);
        // Pass the entire credentials object along with formContext for complex validations
        const errorMessage = fieldValidators[field](credentials[field], {...credentials, formContext});
        if (errorMessage) {
            isValid = false;
            errors[field] = errorMessage;
        }
    });

    return { isValid, errors };
};