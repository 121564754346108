import {flexRender} from "@tanstack/react-table";
import {Arrow2} from "icons/Arrow2";
import {Sorting} from "icons/Sorting";
import React from "react";
import style from './style.module.css';
import {Checkbox} from "components/Checkbox";

export default function TableHeadingCell({ header, table, rowSelectionAllowed }) {
  let trailingIcon,
  ariaSort = 'none';

  if (header.column.getCanSort()) {
    const sortDirection = header.column.getIsSorted();
    

    if (sortDirection) {
      if (sortDirection === 'asc') {
        trailingIcon = <Arrow2 className={ `${ style.downArrow  } ${ style.arrow }` }/>;
        ariaSort = 'ascending';
      }
      else if (sortDirection === 'desc') {
        trailingIcon = <Arrow2 className={ style.arrow } />;
        ariaSort = 'descending';
      }
    } else {
      trailingIcon = <Sorting className={ style.arrow } />;
      ariaSort = 'none';
    }
  }
  
  const align = header.column?.columnDef?.meta?.align;
  const alignClassName = align === 'right' ? style.right : '';
  const cellContent = <div tabIndex="0" aria-sort={ ariaSort } onClick={header.column.getToggleSortingHandler()} >
    {
      flexRender(
        header.column.columnDef.header,
        header.getContext()
      )
    }
    { trailingIcon }
  </div>;

  return (
    <th key={header.id}
        className={ `${ style.headerCell } ${ alignClassName } ${ header.column.getCanSort() ? style.canSort : '' }` }
    >
      {
        (header.index === 0 && rowSelectionAllowed) ? (
          <div className={style.checkboxAndContentWrapper}>
            <Checkbox
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
            { cellContent }
          </div>
        ) : cellContent
      }
    </th>
  );
}
