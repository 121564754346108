import PropTypes from "prop-types";
import React from "react";

export const Info = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
    >
      <path d="M8 14C9.5913 14 11.1174 13.3679 12.2426 12.2426C13.3679 11.1174 14 9.5913 14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2C6.4087 2 4.88258 2.63214 3.75736 3.75736C2.63214 4.88258 2 6.4087 2 8C2 9.5913 2.63214 11.1174 3.75736 12.2426C4.88258 13.3679 6.4087 14 8 14ZM7.0625 9.875H7.625V8.375H7.0625C6.75078 8.375 6.5 8.12422 6.5 7.8125C6.5 7.50078 6.75078 7.25 7.0625 7.25H8.1875C8.49922 7.25 8.75 7.50078 8.75 7.8125V9.875H8.9375C9.24922 9.875 9.5 10.1258 9.5 10.4375C9.5 10.7492 9.24922 11 8.9375 11H7.0625C6.75078 11 6.5 10.7492 6.5 10.4375C6.5 10.1258 6.75078 9.875 7.0625 9.875ZM8 5C8.19891 5 8.38968 5.07902 8.53033 5.21967C8.67098 5.36032 8.75 5.55109 8.75 5.75C8.75 5.94891 8.67098 6.13968 8.53033 6.28033C8.38968 6.42098 8.19891 6.5 8 6.5C7.80109 6.5 7.61032 6.42098 7.46967 6.28033C7.32902 6.13968 7.25 5.94891 7.25 5.75C7.25 5.55109 7.32902 5.36032 7.46967 5.21967C7.61032 5.07902 7.80109 5 8 5Z" fill="currentColor"/>
    </svg>    
  );
};

Info.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Info.defaultProps = {
  color: 'currentColor', // Or any default color
};