import React from 'react';
import styles from './TooltipTemplate.module.css';
import DOMPurify from 'dompurify';

import { ArrowRight } from '../../../icons'

export const TooltipTemplate = ({ name, data_type, optional, method, title, content, link, no_header }) => {

  // Sanitize HTML content
  const sanitizedContent = DOMPurify.sanitize(content, {
    ADD_ATTR: ['target', 'title', 'style'] // Allow the 'target', 'title', and 'style' attributes
  });

  return (
    <div className={styles.tooltip}>
      {/* if there is no header flag - do not render header */}
      {
        !no_header &&
        <>
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              <div className={styles.name}>{name}</div>
              {data_type && <div className={styles.type}>{data_type}</div>}          
            </div>
            <div className={styles.headerRight}>
              {
                optional
                ? <div className={styles.optional}>optional</div>
                : <div className={styles.required}>required</div>
              }
              {method && <div className={styles.method}>{method}</div>}          
            </div>
          </div>
          <div className={styles.delimeter}></div>        
        </>
      }

      <h1 className={styles.title}>{title}</h1>
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: sanitizedContent }}></div>
      { link && 
        <div className={styles.footer}>
          <a className={styles.link} href={link.url} target='_blank' rel="noopener noreferrer">{link.text} <ArrowRight className={styles.icon}  /></a>
        </div>
      }
    </div>
  );
};




// import React from 'react';
// import styles from './TooltipTemplate.module.css';

// import parse from 'html-react-parser';
// import DOMPurify from 'dompurify';


// export const TooltipTemplate = ({ name, optional, method, title, content, link }) => {

//   // Function to create markup
//   const createMarkup = (htmlContent) => {
//     return parse(DOMPurify.sanitize(htmlContent));
//   };

//   return (
//     <div className={styles.tooltip}>
//       <div className={styles.header}>
//         <div className={styles.name}>{name}</div>
//         {optional && <div className={styles.optional}>{optional}</div>}
//         {method && <div className={styles.method}>{method}</div>}
//       </div>
//       <h1 className={styles.title}>{title}</h1>
//       <div className={styles.content}>{createMarkup(content)}</div>
//       {link && <div className={styles.footer}><a href={link.url}>{link.text}</a></div>}
//     </div>
//   );
// };
