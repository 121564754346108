import transcodingJSON from '../data/api/transcoding.json'
import streamingJSON from '../data/api/streaming.json'
import cdnJSON from '../data/api/cdn.json'

const docsBaseUrl = "https://docs.qencode.com"

export const tooltipData = ({name, api}) => {

    // this will find by name, but the problem is that there can be objects with same name, so can only use for search

    // description can have HTML with url to docs, so need to add https://docs.qencode.com to links
    // there also can be links starting with # to currently opened docs page
    const updateHref = (str, docsBaseUrl, prefix) => {
        if (!str) return str;
    
        return str.replace(/href="([^"]+)"/g, (match, href) => {
            let updatedHref = href;
            // Add docsBaseUrl before '/' if href starts with '/'
            if (href.startsWith('/')) {
                updatedHref = `${docsBaseUrl}${href}`;
            }
            // Add prefix before '#' if href starts with '#'
            else if (href.startsWith('#')) {
                updatedHref = `${docsBaseUrl}${prefix}${href}`;
            }
            
            // Add target="_blank" to open link in new tab
            return `href="${updatedHref}" target="_blank"`;
        });
    };

    // Part 1
    // Based on Documentation Search component

    let jsonData
    let apiPrefix
    let apiIndexIdPrefix

    if (api === 'transcoding') {
        jsonData = transcodingJSON;
        apiPrefix = '/api-reference/transcoding/'
        apiIndexIdPrefix = 'transcoding_section_'    
    } else if (api === 'streaming') {
        jsonData = streamingJSON;
        apiPrefix = '/api-reference/streaming/'
        apiIndexIdPrefix = 'transcoding_section_'  
    } else if (api === 'cdn') {
        jsonData = cdnJSON;
        apiPrefix = '/api-reference/cdn/'
        apiIndexIdPrefix = 'cdn_section_'  
    }

    // flatten the Tree for search index
    // 1st we add only info for Transcoding
    let jsonDataArray  = []

    // there can be different JSON data sources, now we only filter for transcoding
    // because each data source may have its own data structure
    // and data for seach index must be prepared based on that

    // SEARCH SECTIONS
    // api-reference   Api References
    // tutorials       Tutorials
    // faq             FAQ
    // support         don't add to search index

    let prefix = null

    let contentJSON 

    if(jsonData && jsonData?.content){

        prefix = apiPrefix
        contentJSON = jsonData.content

        contentJSON.forEach(({ title, description, short_description, name, method, version, method_arguments, return_object, input_objects, output_objects }, sectionIndex) => {

            const http_method = method
            const api_method = `/${version}/${name}`

            jsonDataArray.push({
                id: `${apiIndexIdPrefix}${sectionIndex}`,
                title: title,
                slug: `${prefix}#${title.toLowerCase().split(' ').join('-')}`,
                // content: description.replace(/<[^>]+>/g, ''),  
                content: updateHref(description, docsBaseUrl, prefix) || short_description, // this can be html   
                prefix: prefix,
                type: "section",
                http_method: http_method,
                method:api_method,
                var_type:'',
                required:'',
                section: 'Api References'
            })
            

            let methodName = name

            // remove /<>
            if(methodName){
                //console.log("methodName in search: ", methodName)
                let formatedMethodName = methodName.replace(/[^0-9a-zA-Z_-]/g, "")
                //console.log("formatedMethodName search: ", formatedMethodName)
                methodName = formatedMethodName
            }                  

            const flattenTree = (data, parent, tableName) => {
                data.forEach(({name, data_type, required, attributes, description, short_description}) => {

                    // name //todo: rename
                    // http://localhost:8000/api-reference/streaming/#live-streamsstream_idstoptodorename___timestamp__returns

        
                    let formatedName = ''
                    if(name){
                        formatedName = name.toLowerCase().replace(/\s/g, '-').replace(/[^0-9a-zA-Z_-]/g, "")
                    }  

                    //console.log("name: ", name);
                    
                    // we add methodName one time only
                    //const id = parent ? `${parent}--${name.toLowerCase().replace(/\s/g, '-')}__${tableName}` : `${methodName}___${name.toLowerCase().replace(/\s/g, '-')}__${tableName}`                    
                    const id = parent ? `${parent}--${formatedName}__${tableName}` : `${methodName}___${formatedName}__${tableName}`          

                    if(attributes){
                        flattenTree(attributes, id, tableName)
                    }

                    // let content = ''
                    // if(description){
                    //     content = description.replace(/<[^>]+>/g, '') 
                    // }
                    
                    jsonDataArray.push({
                        id: `${id}`,
                        title: name,
                        slug: `${prefix}#${id}`,
                        //content: content,
                        content: updateHref(description, docsBaseUrl, prefix) || short_description, // this can be html
                        prefix: prefix,
                        type: "parameter",
                        http_method: http_method,
                        method:api_method,
                        var_type: data_type,
                        required: required ? "req" : "opt",
                        section: 'Api References'                                      
                    })


                })
            }

            if(method_arguments){
                flattenTree(method_arguments, null, 'arguments')
            }  

            if(return_object){
                flattenTree(return_object, null, 'returns')
            }                                

            if(input_objects){
                flattenTree(input_objects, null, 'attributes')
            }

            if(output_objects){
                flattenTree(output_objects, null, 'attributes')
            }                
    
        })      
        
    }       

    // https://docs.qencode.com/api-reference/transcoding/#start_encode2___query__attributes--format__attributes--width__attributes

    // Function to generate tooltipProps object
    const generateTooltipProps = (name, data_type, required, method, title, content, linkUrl, linkText) => {
        return {
            name: name,
            data_type: data_type,
            optional: required ? 'required' : 'optional',
            method: method,
            title: title,
            content: content,
            link: {
                url: `${docsBaseUrl}${linkUrl}`,
                text: linkText
            }
        };
    };

    //console.log("jsonDataArray: ", jsonDataArray)

    // Part 2
    // Find api method by name and return it as an object for tooplip

    const argumentName = name

    // Find the corresponding data for the 'width' argument
    const argumentData = jsonDataArray.find(item => item.title === argumentName);

    // If the argument data is found, construct the tooltipProps object
    if (argumentData) {
        const tooltipProps = generateTooltipProps(
            argumentData.title,
            argumentData.var_type,
            argumentData.required === 'req', // Assuming 'required' field contains 'req' or 'opt'
            'api', // Assuming method is always 'api'
            argumentData.title, // Title can be the same as argument name
            argumentData.content,
            argumentData.slug, // Assuming slug contains link URL
            'Show API' // Default link text
        );

        //console.log("tooltipProps: ", tooltipProps)

        return tooltipProps
    } else {
        return null
    }    


}