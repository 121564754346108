export const sampleVideos = [
    {
        url: "https://nyc3.s3.qencode.com/qencode/samples/4k-sample.mov",
        name: "4K Sample (Qencode)",
        info: "3840 x 2160 / 3.12 GB / 01:04 / MOV / ProRes / 24 FPS",
        quality: "4K"
    },
    {
        url: "https://nyc3.s3.qencode.com/qencode/samples/1080-sample.mp4",
        name: "1080 Sample (Qencode)",
        info: "1920 x 1080 / 8.1 MB / 00:08 / MP4 / H.264 / 24 FPS",
        quality: "HD"
    },
    {
        url: "https://nyc3.s3.qencode.com/qencode/samples/bbb_sunflower_1080p_60fps_normal.mp4",
        name: "Big Buck Bunny",
        info: "1920x1080 / 355 MB / 10:34 / MP4 / H.264 / 24 FPS",
        quality: "HD"
    },
    {
        url: "https://nyc3.s3.qencode.com/qencode/samples/tears_of_steel_1080p.mov",
        name: "Tears of Steel",
        info: "1920 x 800 / 583.8 MB / 12:14 / MOV / H.264 / 24 FPS",
        quality: "HD"
    },
    {
        url: "https://nyc3.s3.qencode.com/qencode/samples/Sintel.2010.720p.mkv",
        name: "Sintel",
        info: "1280 x 720 / 673.9 MB / 14:48 / MKV / H.264 / 24 FPS",
        quality: "SD"
    }
];