import PropTypes from "prop-types";
import React from "react";

export const Sorting = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  const style = color !== 'currentColor' ? { color } : undefined;

  return (
    <svg
      className={className}
      role="img"
      aria-label={label}
      style={style}
      width="8"
      height="10"
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M1.32403 5.74013C0.702508 5.74013 0.391247 6.46872 0.830731 6.89483L3.5067 9.48938C3.77914 9.75354 4.22086 9.75354 4.4933 9.48938L7.16927 6.89483C7.60875 6.46872 7.29749 5.74013 6.67597 5.74013C3.28819 5.74013 4.32338 5.74013 1.32403 5.74013Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.67597 4.25987C7.29749 4.25987 7.60875 3.53128 7.16927 3.10517L4.4933 0.510615C4.22086 0.246462 3.77914 0.246462 3.5067 0.510615L0.83073 3.10517C0.391247 3.53128 0.702507 4.25987 1.32403 4.25987C4.71181 4.25987 3.67662 4.25987 6.67597 4.25987Z" fill="currentColor"/>
    </svg>
  );
};

Sorting.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

Sorting.defaultProps = {
  color: 'currentColor', // Or any default color
};