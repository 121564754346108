import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from "components/Modal";
import {Button} from "components/Button";

function TlsStatusModal({ open, handleCancel, selectedDomain }) {
  return (
    <Modal
      isOpen={open}
      onClose={handleCancel}
      alert='Your TLS subscription needs verification.'
      header={
        <>
          <h3>TLS subscription</h3>
        </>
      }
      footer={
        <>
          <Button
            variant="modalFooter"
            onClick={ handleCancel }
          >
            Close
          </Button>
        </>
      }
    >
      <p>
        { selectedDomain?.tlsSubscription?.message }
      </p>
    </Modal>
  );
}

TlsStatusModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  selectedDomain: PropTypes.object,
};

export default TlsStatusModal;