import React from 'react';
import PropTypes from 'prop-types';
import styles from './MultivalidationPassword.module.css'; // Make sure to create the corresponding CSS module

import {Check2 as Dot, Check2 as Tick, Check2 as Close} from '../../icons'

export const MultivalidationPassword = ({ validations }) => {
    return (
        <div className={styles.validationContainer}>
            {validations.map((validation, index) => (
                <div
                    key={index}
                    className={`${styles.validationMessage} ${
                        validation.isValid === null ? styles.initial : validation.isValid ? styles.valid : styles.invalid
                    }`}
                >
                    <div className={styles.iconContainer}>
                        {validation.isValid === null && <Dot className={styles.icon} />}
                        {validation.isValid === true && <Tick className={styles.icon} />}
                        {validation.isValid === false && <Close className={styles.icon} />}
                    </div>

                    <div className={styles.text}>
                        {validation.message}    
                    </div>
                    
                </div>
            ))}
        </div>
    );
};

MultivalidationPassword.propTypes = {
    validations: PropTypes.arrayOf(
        PropTypes.shape({
            message: PropTypes.string.isRequired,
            isValid: PropTypes.bool, // null for initial state, true for valid, false for invalid
        })
    ).isRequired,
};