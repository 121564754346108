//UPDATED

import React, { 
  createContext, useState, useContext, useCallback, 
  //useEffect 
} from 'react';
import Cookies from 'js-cookie';

import { 
  loginUser, 
  refreshAccessToken, 
  signupUser, 
  resendActivationEmail,
  completeUserSignup,
  completeUserSignupOauth2,
  completeUserInvite,
  sendPasswordResetEmail,
  setNewUserPassword,
  verifyUser2FA,
  verifyAccessToken
} from '../api/qencode-auth-api';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {

  const [authError, setAuthError] = useState(null);

  const [signupError, setSignupError] = useState(null);

  const [resendError, setResendError] = useState(null);

  const [completeSignupError, setCompleteSignupError] = useState(null);

  // operations regarding password reset
  const resetPassword = async ({email}) => {
    const response = await sendPasswordResetEmail({ email });
    const {success, detail} = response
    if (success) {
      setAuthError(null);
      // You might want to show a success message or perform some action
      console.log(detail || "Email to reset password sent");
      return true;
    } else {
      setAuthError(detail || "Failed to resend password reset email");
      // Show an error message to the user
      console.error(detail || "Failed to resend password reset email");
      return false;
    }
  };
  
  const setNewPassword = async (credentials) => {
    const response = await setNewUserPassword(credentials);
    const {success, detail} = response
    if (success) {
      setAuthError(null);
      // You might want to show a success message or perform some action
      console.log(detail || "Password reset successfully");
      return true;
    } else {
      setAuthError(detail || "Failed to set new password");
      // Show an error message to the user
      console.error(detail || "Failed to set new password");
      return false;
    }
  };   



  // operations regarding signup process
  const signup = async ({ email }) => {
    const response = await signupUser({ email });
    const {success, detail} = response
    if (success) {
      // Handle successful signup
      setSignupError(null);
      // You may want to navigate to a new page, or set state indicating the email has been sent
      console.log(detail || "Signup process started");
      return true;
    } else {
      // Handle error on signup
      setSignupError(detail || "Signup failed");
      console.error(detail || "Signup failed");
      return false;
    }
  };

  const resendActivation = async ({email}) => {
    const response = await resendActivationEmail({ email });
    const { success, detail} = response
    if (success) {
      setResendError(null);
      // You might want to show a success message or perform some action
      console.log(detail || "Activation email sent");
      return true;
    } else {
      setResendError(detail || "Failed to resend activation email");
      // Show an error message to the user
      console.error(detail || "Failed to resend activation email");
      return false;
    }
  };

  // operations with Refresh Token
  // Function to get the refresh token, memoized with useCallback
  const getRefreshToken = useCallback(() => {
    //return localStorage.getItem('refresh_token');
    return Cookies.get('refresh_token');
  }, []); // No dependencies, so it only gets created once  

  // const setRefreshToken = (token, expireTime) => {
  //   //localStorage.setItem('refresh_token', token);

  //   const expirationDate = new Date(expireTime * 1000); // Convert to milliseconds
  //   Cookies.set('refresh_token', token, { expires: expirationDate });
  // };

  const setRefreshToken = useCallback((token, expireTime) => {
    //localStorage.setItem('refresh_token', token);

    const expirationDate = new Date(expireTime * 1000); // Convert to milliseconds
    Cookies.set('refresh_token', token, { expires: expirationDate });
  },[]);

  const removeRefreshToken = () => {
    //localStorage.removeItem('refresh_token');
    Cookies.remove('refresh_token');
  };
  
  
  // operations with Access Token
  // const setAccessToken = (token, expireTime) => {
  //   const expirationDate = new Date(expireTime * 1000); // Convert to milliseconds
  //   Cookies.set('access_token', token, { expires: expirationDate });
  // };

  const setAccessToken = useCallback((token, expireTime) => {
    const expirationDate = new Date(expireTime * 1000); // Convert to milliseconds
    Cookies.set('access_token', token, { expires: expirationDate });
  },[]);

  // const getAccessToken = () => {
  //   return Cookies.get('access_token');
  // };

  const getAccessToken = useCallback(() => {
    return Cookies.get('access_token');
  },[]);
  
  const removeAccessToken = () => {
    Cookies.remove('access_token');
  };
  
  // Simplified function to check if the access token is expired
  const isTokenExpired = useCallback(() => {
    const token = getAccessToken();
    return !token; // If the token doesn't exist, it has expired or was never set
  }, [getAccessToken]);  

  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const token = getAccessToken();
    if (!token) return false;
  
    return !isTokenExpired(); // Utilize the isTokenExpired function
  });

  // Logout function, memoized with useCallback
  const logout = useCallback(() => {
    setIsAuthenticated(false);
    removeAccessToken()
    removeRefreshToken()
    //localStorage.setItem('Qencode_logout', Date.now()); // Trigger logout event across all tabs
    // trigger event with delay as there is some bug in Mozilla
    // setTimeout(() => {
    //     localStorage.setItem('Qencode_logout', Date.now());
    // }, 500); // Adjust the delay as needed    

    // Trigger logout event across tabs
    const channel = new BroadcastChannel('Qencode_channel');
    channel.postMessage({ type: 'logout' });
    channel.close();
    console.log("Logged out locally");

  }, [setIsAuthenticated]); // Dependency on setIsAuthenticated  

  const login = async ({email, password}) => {
    const response = await loginUser({email, password});
    const { 
      success, access_token, 
      //error, 
      token_expire, refresh_token, refresh_token_expire, detail,
      two_fa_required // to halde 2FA
    } = response;

    // 2FA
    // {
    //     "error": 0,
    //     "detail": "Please enter 2FA code to complete the login process",
    //     "timestamp": 1705593146.590502,
    //     "two_fa_required": 1,
    //     "access_token": "fewfjwlfjwl",
    //     "token_expire": "efewfwefwfe"
    // }
    if (success && access_token && token_expire ) {
    // if (success && access_token && token_expire && refresh_token && refresh_token_expire) {
      // Set cookie with expiration
      // setAccessToken(access_token, token_expire);
      // setRefreshToken(refresh_token, refresh_token_expire)
      // setIsAuthenticated(true);
      setAuthError(null);
      if(two_fa_required){
        return {access_token}
      }else{
        setAccessToken(access_token, token_expire);
        setRefreshToken(refresh_token, refresh_token_expire)
        setIsAuthenticated(true);
      }

      // Set login event in localStorage
      //localStorage.setItem('Qencode_login', Date.now());
      // setTimeout(() => {
      //     localStorage.setItem('Qencode_login', Date.now());
      // }, 500); // Adjust the delay as needed        
      console.log(detail || "Logged in successfully!");
      return true;
    } else {
      //setAuthError("Failed to log in"); 
      setAuthError(detail || "Invalid Email or Password")
      console.error(detail || "Invalid Email or Password");
      return false;
    }
  };

  // loging user using 2FA verifyUser2FA
  const login2FA = async (credentials) => {
    const response = await verifyUser2FA(credentials);
    const { 
      success, access_token, 
      //error, 
      token_expire, refresh_token, refresh_token_expire, detail
    } = response;

    if (success && access_token && token_expire && refresh_token && refresh_token_expire) {
      // Set cookie with expiration
      setAccessToken(access_token, token_expire);
      setRefreshToken(refresh_token, refresh_token_expire)
      setIsAuthenticated(true);
      //localStorage.setItem('Qencode_login', Date.now());
      // setTimeout(() => {
      //     localStorage.setItem('Qencode_login', Date.now());
      // }, 500); // Adjust the delay as needed              
      console.log(detail || "Logged in successfully!");
      return true;
    } else {
      //setAuthError("Failed to log in"); 
      setAuthError(detail || "2FA verification failed")
      console.error(detail || "2FA verification failed");
      return false;
    }
  };  

  // Function to refresh the access token
  // const refreshTheAccessToken = useCallback(async () => {
  //   const refreshToken = getRefreshToken();
  //   if (!refreshToken) {
  //     console.log("Session expired. Please log in again.");
  //     setAuthError(null);
  //     logout();
  //     return;
  //   }

  //   const response = await refreshAccessToken({ refreshTokenParam: refreshToken });
  //   const { success, access_token, error, token_expire, refresh_token, refresh_token_expire } = response;
  //   if (success && access_token && token_expire && refresh_token && refresh_token_expire) {
  //     // Update access token and expiration in cookie
  //     setAccessToken(access_token, token_expire);
  //     setRefreshToken(refresh_token, refresh_token_expire)
  //     setIsAuthenticated(true);
  //     setAuthError(null);
  //   } else {
  //     setAuthError("Session expired. Please log in again."); 
  //     console.error('Error refreshing access token:', error);
  //     logout();
  //   }
  // }, [getRefreshToken, logout]); // Add necessary dependencies here  

  const refreshTheAccessToken = useCallback(async () => {
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
        console.log("No refresh token available. Please log in again.");
        logout();
        return null; // No token available to return
    }

    try {
        const response = await refreshAccessToken({ refreshTokenParam: refreshToken });
        const { 
          success, access_token, 
          //error, 
          token_expire, refresh_token, refresh_token_expire, detail 
        } = response;

        if (success && access_token) {
            setAccessToken(access_token, token_expire);
            setRefreshToken(refresh_token, refresh_token_expire);
            setIsAuthenticated(true);
            console.log("Access token updated")
            return access_token; // Return the new token directly
        } else {
            // throw new Error("Failed to refresh token: " + (error || "Unknown error"));
            console.error(detail || "Failed to refresh token");
        }
    } catch (error) {
        console.error(error);
        setAuthError(error.message || "Failed to refresh session.");
        logout();
        return null; // Ensure to return null when there's an error
    }
  }, [getRefreshToken, setAccessToken, setRefreshToken, logout]);


  // Memoize getToken function to include refresh logic
  // Adjust getToken to handle asynchronous operation
  // const getToken = useCallback(async () => {
  //   if (isTokenExpired()) {
  //     await refreshTheAccessToken(); // Ensure refresh completes before proceeding
  //   }
  //   return getAccessToken()
  // }, [refreshTheAccessToken, isTokenExpired]); 

  const getToken = useCallback(async () => {
    if (isTokenExpired()) {
        const refreshedToken = await refreshTheAccessToken();
        if (refreshedToken) {
            return refreshedToken; // Use the freshly refreshed token
        }
        // Handle the case where token refresh might fail and no token is available
        return null;
    }
    return getAccessToken(); // Return the current token if it's not expired
  }, [refreshTheAccessToken, getAccessToken, isTokenExpired]);



  // Complete Signup 
  // const completeSignup = async ({
  //   provider,
  //   token,
  //   first_name,
  //   last_name,
  //   organization,
  //   password,
  //   password_confirm,
  //   phone
  // }) => {
  
  //   let response;

  //   if (provider) {
  //     response = await completeUserSignupOauth2({
  //       provider,
  //       token,
  //       first_name,
  //       last_name,
  //       organization,
  //       phone
  //     });
  //   } else {
  //     response = await completeUserSignup({
  //       token,
  //       first_name,
  //       last_name,
  //       organization,
  //       password,
  //       password_confirm,
  //       phone
  //     });
  //   }    

  //   const { 
  //     success, access_token, 
  //     //error, 
  //     token_expire, refresh_token, refresh_token_expire, detail 
  //   } = response;

  //   if (success && access_token && token_expire && refresh_token && refresh_token_expire) {
  //     // Set cookie with expiration
  //     setAccessToken(access_token, token_expire);
  //     setRefreshToken(refresh_token, refresh_token_expire)
  //     setIsAuthenticated(true);
  //     setCompleteSignupError(null);
  //     console.log(detail || "Signup is complete!");
  //     return true;
  //   } else {
  //     setCompleteSignupError(detail || "Signup complete failed")
  //     console.error(detail || "Signup complete failed");
  //     return false;
  //   }
  // };

  const completeSignup = async (credentials) => {

    const {provider} = credentials
  
    let response;

    if (provider) {
      response = await completeUserSignupOauth2(credentials);
    } else {
      response = await completeUserSignup(credentials);
    }    

    const { 
      success, access_token, 
      //error, 
      token_expire, refresh_token, refresh_token_expire, detail 
    } = response;

    if (success && access_token && token_expire && refresh_token && refresh_token_expire) {
      // Set cookie with expiration
      setAccessToken(access_token, token_expire);
      setRefreshToken(refresh_token, refresh_token_expire)
      setIsAuthenticated(true);
      setCompleteSignupError(null);
      //localStorage.setItem('Qencode_login', Date.now());
      // setTimeout(() => {
      //     localStorage.setItem('Qencode_login', Date.now());
      // }, 500); // Adjust the delay as needed              
      console.log(detail || "Signup is complete!");
      return true;
    } else {
      setCompleteSignupError(detail || "Signup complete failed")
      console.error(detail || "Signup complete failed");
      return false;
    }
  };

  const completeInvite = async (credentials) => {
  
    const response = await completeUserInvite(credentials)

    const { 
      success, access_token, 
      //error, 
      token_expire, refresh_token, refresh_token_expire, detail 
    } = response;

    if (success && access_token && token_expire && refresh_token && refresh_token_expire) {
      // Set cookie with expiration
      setAccessToken(access_token, token_expire);
      setRefreshToken(refresh_token, refresh_token_expire)
      setIsAuthenticated(true);
      setCompleteSignupError(null);
      //localStorage.setItem('Qencode_login', Date.now());
      // setTimeout(() => {
      //     localStorage.setItem('Qencode_login', Date.now());
      // }, 500); // Adjust the delay as needed              
      console.log(detail || "Signup is complete!");
      return true;
    } else {
      setCompleteSignupError(detail || "Signup complete failed")
      console.error(detail || "Signup complete failed");
      return false;
    }
  };

  // checking for token in ProtectedRoute.js
  // useEffect(() => {
  //   if (isTokenExpired()) {
  //     refreshTheAccessToken();
  //   }
  // }, [isTokenExpired, refreshTheAccessToken]);

  const verifyToken = async (acessToken) => {
    const response = await verifyAccessToken(acessToken);
    const {success, detail} = response
    if (success) {
      setAuthError(null);
      // You might want to show a success message or perform some action
      return true;
    } else {
      setAuthError(detail || "Failed to verify token");
      // Show an error message to the user
      console.error(detail || "Failed to verify token");
      return false;
    }
  };  

  return (
    <AuthContext.Provider value={{ 
      isAuthenticated, setIsAuthenticated, login, logout, getToken, getRefreshToken, authError, setAuthError,
      signup, signupError, setSignupError,
      resendActivation, resendError, setResendError,
      completeSignup, completeSignupError, setCompleteSignupError,
      completeInvite,
      resetPassword,
      setNewPassword,
      login2FA,
      verifyToken, // in protected route
      getAccessToken // for dashboard
    }}>
      {children}
    </AuthContext.Provider>
  );
};

// token_expire  зашит в самом токене, но что бы не делать лишних движений на клиенте я его вывел ещё и отдельное поле
// token_expire - это timestamp, float в секундах
// 1. получаем токен, пишем его в куки, кукам выставляем время жизни соответсвенно с token_expire
// 2. далее при любой операции требующей авторизованного запроса на сервер получаем токен из этого куки.
// 3. проверку срока жизни токена можно делать на клиенте или не далать этого, но тогда сервер вернет 401
// 4. если делаем проверку срока жизни токена на клиенте, то если он истек делаем запрос на v1/auth/refresh-token передав соответсвующий refresh_token
// разница между access_token и refresh_token:
// 1. срок жизни access_token - 1 день, refresh_token - 1 неделя (можем сделать любой)
// 2. в refresh_token - зашит только id пользователя
// 3. в access_token - зашита ещe дополнительная информация о пользователе - role, is_trial (как распаковать jwt токен думаю ты знаешь) (edited) 



// BEFORE Apr 26
// import React, { 
//   createContext, useState, useContext, useCallback, 
//   //useEffect 
// } from 'react';
// import Cookies from 'js-cookie';

// import { 
//   loginUser, 
//   refreshAccessToken, 
//   signupUser, 
//   resendActivationEmail,
//   completeUserSignup,
//   completeUserSignupOauth2,
//   sendPasswordResetEmail,
//   setNewUserPassword,
//   verifyUser2FA
// } from '../api/qencode-auth-api';

// const AuthContext = createContext(null);

// export const useAuth = () => useContext(AuthContext);

// export const AuthProvider = ({ children }) => {

//   const [authError, setAuthError] = useState(null);

//   const [signupError, setSignupError] = useState(null);

//   const [resendError, setResendError] = useState(null);

//   const [completeSignupError, setCompleteSignupError] = useState(null);

//   // operations regarding password reset
//   const resetPassword = async ({email}) => {
//     const response = await sendPasswordResetEmail({ email });
//     if (response.success) {
//       setAuthError(null);
//       // You might want to show a success message or perform some action
//       return true;
//     } else {
//       setAuthError(response.detail || "Failed to resend password reset email");
//       // Show an error message to the user
//       return false;
//     }
//   };
  
//   const setNewPassword = async (credentials) => {
//     const response = await setNewUserPassword(credentials);
//     if (response.success) {
//       setAuthError(null);
//       // You might want to show a success message or perform some action
//       return true;
//     } else {
//       setAuthError(response.detail || "Failed to set new password");
//       // Show an error message to the user
//       return false;
//     }
//   };   



//   // operations regarding signup process
//   const signup = async ({ email }) => {
//     const response = await signupUser({ email });
//     if (response.success) {
//       // Handle successful signup
//       setSignupError(null);
//       // You may want to navigate to a new page, or set state indicating the email has been sent
//       return true;
//     } else {
//       // Handle error on signup
//       setSignupError(response.detail || "Signup failed");
//       return false;
//     }
//   };

//   const resendActivation = async ({email}) => {
//     const response = await resendActivationEmail({ email });
//     if (response.success) {
//       setResendError(null);
//       // You might want to show a success message or perform some action
//       return true;
//     } else {
//       setResendError(response.detail || "Failed to resend activation email");
//       // Show an error message to the user
//       return false;
//     }
//   };

//   // operations with Refresh Token
//   // Function to get the refresh token, memoized with useCallback
//   const getRefreshToken = useCallback(() => {
//     //return localStorage.getItem('refresh_token');
//     return Cookies.get('refresh_token');
//   }, []); // No dependencies, so it only gets created once  

//   const setRefreshToken = (token, expireTime) => {
//     //localStorage.setItem('refresh_token', token);

//     const expirationDate = new Date(expireTime * 1000); // Convert to milliseconds
//     Cookies.set('refresh_token', token, { expires: expirationDate });
//   };

//   const removeRefreshToken = () => {
//     //localStorage.removeItem('refresh_token');
//     Cookies.remove('refresh_token');
//   };
  
  
//   // operations with Access Token
//   const setAccessToken = (token, expireTime) => {
//     const expirationDate = new Date(expireTime * 1000); // Convert to milliseconds
//     Cookies.set('access_token', token, { expires: expirationDate });
//   };

//   const getAccessToken = () => {
//     return Cookies.get('access_token');
//   };
  
//   const removeAccessToken = () => {
//     Cookies.remove('access_token');
//   };
  
//   // Simplified function to check if the access token is expired
//   const isTokenExpired = useCallback(() => {
//     const token = getAccessToken();
//     return !token; // If the token doesn't exist, it has expired or was never set
//   }, []);  

//   const [isAuthenticated, setIsAuthenticated] = useState(() => {
//     const token = getAccessToken();
//     if (!token) return false;
  
//     return !isTokenExpired(); // Utilize the isTokenExpired function
//   });

//   // Logout function, memoized with useCallback
//   const logout = useCallback(() => {
//     setIsAuthenticated(false);
//     removeAccessToken()
//     removeRefreshToken()
//     console.log("Logged out locally");
//   }, [setIsAuthenticated]); // Dependency on setIsAuthenticated  

//   const login = async ({email, password}) => {
//     const response = await loginUser({email, password});
//     const { 
//       success, access_token, error, token_expire, refresh_token, refresh_token_expire,
//       two_fa_required // to halde 2FA
//     } = response;

//     // 2FA
//     // {
//     //     "error": 0,
//     //     "detail": "Please enter 2FA code to complete the login process",
//     //     "timestamp": 1705593146.590502,
//     //     "two_fa_required": 1,
//     //     "access_token": "fewfjwlfjwl",
//     //     "token_expire": "efewfwefwfe"
//     // }
//     if (success && access_token && token_expire ) {
//     // if (success && access_token && token_expire && refresh_token && refresh_token_expire) {
//       // Set cookie with expiration
//       // setAccessToken(access_token, token_expire);
//       // setRefreshToken(refresh_token, refresh_token_expire)
//       // setIsAuthenticated(true);
//       setAuthError(null);
//       if(two_fa_required){
//         return {access_token}
//       }else{
//         setAccessToken(access_token, token_expire);
//         setRefreshToken(refresh_token, refresh_token_expire)
//         setIsAuthenticated(true);
//       }
//       console.log("Logged in successfully!");
//       return true;
//     } else {
//       //setAuthError("Failed to log in"); 
//       setAuthError("Invalid Email or Password")
//       console.error('Login Error:', error);
//       return false;
//     }
//   };

//   // loging user using 2FA verifyUser2FA
//   const login2FA = async (credentials) => {
//     const response = await verifyUser2FA(credentials);
//     const { 
//       success, access_token, error, token_expire, refresh_token, refresh_token_expire
//     } = response;

//     if (success && access_token && token_expire && refresh_token && refresh_token_expire) {
//       // Set cookie with expiration
//       setAccessToken(access_token, token_expire);
//       setRefreshToken(refresh_token, refresh_token_expire)
//       setIsAuthenticated(true);
//       console.log("Logged in successfully!");
//       return true;
//     } else {
//       //setAuthError("Failed to log in"); 
//       setAuthError("2FA verification failed")
//       console.error('Login Error:', error);
//       return false;
//     }
//   };  

//   // Function to refresh the access token
//   const refreshTheAccessToken = useCallback(async () => {
//     const refreshToken = getRefreshToken();
//     if (!refreshToken) {
//       console.log("Session expired. Please log in again.");
//       setAuthError(null);
//       logout();
//       return;
//     }

//     const response = await refreshAccessToken({ refreshTokenParam: refreshToken });
//     const { success, access_token, error, token_expire, refresh_token, refresh_token_expire } = response;
//     if (success && access_token && token_expire && refresh_token && refresh_token_expire) {
//       // Update access token and expiration in cookie
//       setAccessToken(access_token, token_expire);
//       setRefreshToken(refresh_token, refresh_token_expire)
//       setIsAuthenticated(true);
//       setAuthError(null);
//     } else {
//       setAuthError("Session expired. Please log in again."); 
//       console.error('Error refreshing access token:', error);
//       logout();
//     }
//   }, [getRefreshToken, logout]); // Add necessary dependencies here  

//   // Memoize getToken function to include refresh logic
//   // Adjust getToken to handle asynchronous operation
//   const getToken = useCallback(async () => {
//     if (isTokenExpired()) {
//       await refreshTheAccessToken(); // Ensure refresh completes before proceeding
//     }
//     return getAccessToken()
//   }, [refreshTheAccessToken, isTokenExpired]); 


//   // Complete Signup 
//   const completeSignup = async ({
//     provider,
//     token,
//     first_name,
//     last_name,
//     organization,
//     password,
//     password_confirm,
//     phone
//   }) => {
    
//     // const response = await completeUserSignup({
//     //   token,
//     //   first_name,
//     //   last_name,
//     //   organization,
//     //   password,
//     //   password_confirm,
//     //   phone
//     // });

//     let response;

//     if (provider) {
//       response = await completeUserSignupOauth2({
//         provider,
//         token,
//         first_name,
//         last_name,
//         organization,
//         phone
//       });
//     } else {
//       response = await completeUserSignup({
//         token,
//         first_name,
//         last_name,
//         organization,
//         password,
//         password_confirm,
//         phone
//       });
//     }    

//     const { success, access_token, error, token_expire, refresh_token, refresh_token_expire } = response;

//     if (success && access_token && token_expire && refresh_token && refresh_token_expire) {
//       // Set cookie with expiration
//       setAccessToken(access_token, token_expire);
//       setRefreshToken(refresh_token, refresh_token_expire)
//       setIsAuthenticated(true);
//       setCompleteSignupError(null);
//       console.log("Signup is complete!");
//       return true;
//     } else {
//       setCompleteSignupError(response.detail || "Signup complete failed")
//       console.error('Signup complete failed:', error);
//       return false;
//     }
//   };

//   // checking for token in ProtectedRoute.js
//   // useEffect(() => {
//   //   if (isTokenExpired()) {
//   //     refreshTheAccessToken();
//   //   }
//   // }, [isTokenExpired, refreshTheAccessToken]);

//   return (
//     <AuthContext.Provider value={{ 
//       isAuthenticated, setIsAuthenticated, login, logout, getToken, getRefreshToken, authError, setAuthError,
//       signup, signupError, setSignupError,
//       resendActivation, resendError, setResendError,
//       completeSignup, completeSignupError, setCompleteSignupError,
//       resetPassword,
//       setNewPassword,
//       login2FA,
//       getAccessToken // for dashboard
//     }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// // token_expire  зашит в самом токене, но что бы не делать лишних движений на клиенте я его вывел ещё и отдельное поле
// // token_expire - это timestamp, float в секундах
// // 1. получаем токен, пишем его в куки, кукам выставляем время жизни соответсвенно с token_expire
// // 2. далее при любой операции требующей авторизованного запроса на сервер получаем токен из этого куки.
// // 3. проверку срока жизни токена можно делать на клиенте или не далать этого, но тогда сервер вернет 401
// // 4. если делаем проверку срока жизни токена на клиенте, то если он истек делаем запрос на v1/auth/refresh-token передав соответсвующий refresh_token
// // разница между access_token и refresh_token:
// // 1. срок жизни access_token - 1 день, refresh_token - 1 неделя (можем сделать любой)
// // 2. в refresh_token - зашит только id пользователя
// // 3. в access_token - зашита ещe дополнительная информация о пользователе - role, is_trial (как распаковать jwt токен думаю ты знаешь) (edited) 






