import {useCallback, useMemo, useState} from 'react';
import { UTCDate } from "@date-fns/utc";
import {format, subDays, differenceInDays, eachDayOfInterval, startOfDay, isSameDay} from "date-fns";


export const dateRanges = {
  lastWeek: 'last-week',
  lastMonth: 'last-month',
  lastYear: 'last-year',
  custom: 'custom',
};

const dateRangeToDayCount = {
  [dateRanges.lastWeek]: 6,
  [dateRanges.lastMonth]: 30,
  [dateRanges.lastYear]: 365,
};

function getStartDate(range) {
  return subDays(startOfDay( new UTCDate() ), (dateRangeToDayCount[range] ?? dateRangeToDayCount[dateRanges.lastWeek]));
}

const initialOptions = [
  {
    label: 'Last Week',
    value: dateRanges.lastWeek,
    selected: true,
  },
  {
    label: 'Last Month',
    value: dateRanges.lastMonth,
  },
  {
    label: 'Last Year',
    value: dateRanges.lastYear,
  }
];

const todayUtc = startOfDay( new UTCDate() );

export default function useDatePicker() {
  /* Important to avoid using new Date() because it includes hours and minutes and it messes up day difference calculations. Use instead startOfDay( new UTCDate() ). */
  const [startDate, setStartDate] = useState(
    subDays(startOfDay(todayUtc), dateRangeToDayCount[dateRanges.lastWeek])
  );
  const [endDate, setEndDate] = useState(startOfDay(todayUtc));
  const params = useMemo(() => {
    const params = new URLSearchParams();
    params.set('start_date', format(startDate, 'yyyy-MM-dd'));
    params.set('end_date', format(endDate, 'yyyy-MM-dd'));
    return params;
  }, [startDate, endDate]);
  
  const handleSelect = useCallback((e) => {
    const rangeCode = e?.target?.value;
    const newStartDate = getStartDate(rangeCode);
    if ( !(isSameDay(startDate, newStartDate) && isSameDay(endDate, todayUtc)) ) {
      if (rangeCode) {
        setStartDate(newStartDate);
        setEndDate(todayUtc);
      }
    }
  }, [endDate, startDate]);
  
  const options = useMemo(() => {
    const diffDays = differenceInDays(startOfDay(endDate), startOfDay(startDate));
    const diffRange = Object.entries(dateRangeToDayCount).find(([name, days]) => days === diffDays)?.[0] ?? dateRanges.custom;

    let options = [...initialOptions];
    if (diffRange === dateRanges.custom) {
      options = options.map((i) => ({
        label: i.label,
        value: i.value,
      }));
      options.unshift({
        label: 'Custom',
        value: dateRanges.custom,
        selected: true,
      });
    } else {
      options = initialOptions.map((option) => ({
        ...option,
        selected: option.value === diffRange,
      }));
    }
    return options;
  }, [endDate, startDate]);
  
  const dates = useMemo(() => eachDayOfInterval({
    start: startDate,
    end: endDate
  }).map(date => format(date, 'yyyy-MM-dd')), [startDate, endDate]);

  return {
    dates,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    options,
    params,
    handleSelect,
  }
}
