import PropTypes from "prop-types";
import React from "react";

export const DigitalOcean = ({ className, color, label }) => {

  // Apply inline style only if color prop is not currentColor
  // const style = color !== 'currentColor' ? { color } : undefined;
  const style = color !== 'currentColor' ? { color } : { color: '#0080FF' };

  return (
    <svg 
      className={className} 
      role="img"
      aria-label={label}
      style={style}   
      xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"
    >
      <path d="M4.09458 15.5523H6.2345V13.4155H4.09458V15.5523ZM2.306 11.6271V13.4155H4.09458V11.6271H2.306ZM15.8423 6.70016C15.1745 4.54591 13.4498 2.83006 11.2954 2.15359C6.35645 0.611932 1.80078 4.28461 1.80078 8.99377H4.58238C4.58238 6.03821 7.51496 3.75331 10.6247 4.87979C11.7774 5.29496 12.7007 6.21821 13.1159 7.36792C14.2512 10.4745 11.9632 13.401 9.01029 13.4068V13.4155H9.00158V16.1969C13.7227 16.1969 17.387 11.6445 15.8423 6.70016ZM9.01029 13.4068V10.6399H6.2345V13.4155H9.00158V13.4068H9.01029Z" fill="currentColor"/>
    </svg>    
  );
};

DigitalOcean.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};

DigitalOcean.defaultProps = {
  color: 'currentColor', // Or any default color
};