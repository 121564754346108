import React, { 
    useState, useCallback, useEffect, 
    //useRef 
} from 'react';

//import { useOutsideClick } from '../../hooks/useOutsideClick'

import { useApi } from '../../api/useApi'

import { useAuth } from 'context/AuthContext';

import { useNavigate } from 'react-router-dom';

import { useUser } from '../../context/UserContext' 

import { OptionsMenu } from '../../components/OptionsMenu';

import { 
    //Edit, 
    Delete, Active, Inactive, Reload, ChevronRight as RightArrow 
} from '../../icons'

import { ReactComponent as Success } from '../../assets/images/Success.svg';
import { ReactComponent as MembersImage } from '../../assets/images/Members.svg';

import { ReactComponent as DeleteAccount } from '../../assets/images/DeleteAccount.svg';
import { ReactComponent as ChangeUser } from '../../assets/images/ChangeUser.svg';

import { Button } from '../../components/Button';
import { Select } from '../../components/Select';
import { InputField } from '../../components/InputField'
import { Label } from '../../components/Label'

import { NoData } from '../../components/NoData';

import { Modal } from '../../components/Modal';

import { validateFields } from '../../helpers/validation';

import styles from './index.module.css';

// "role": enum('developer', 'admin', 'billing'),
const defaultOptions = [
    { value: 'admin', label: 'Admin' },
    { value: 'billing', label: 'Billing' },
    { value: 'developer', label: 'Developer' },
];

const defaultInviteOption = { value: 'developer', label: 'Developer' }

const Team = () => {
    const { 
        getTeamData,
        updateTeam,
        addTeamMember,
        removeTeamMember,
        transferOrganization
    } = useApi();

    const { 
        userData,
        //fetchUserData
    } = useUser();     

    //console.log("userData: ", userData)

    const { logout } = useAuth();
    const navigate = useNavigate();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [loading, setLoading] = useState(true);

    const [isUpdateMemberModalOpen, setUpdateMemberModalOpen] = useState(false);
    const [isInvitedSuccessModalOpen, setInvitedSuccessModalOpen] = useState(false);
    const [isRemoveMemberModalOpen, setRemoveMemberModalOpen] = useState(false);
    const [isChangeOwnerModalOpen, setChangeOwnerModalOpen] = useState(false);

    const [isSubmittingModal, setIsSubmittingModal] = useState(false);

    const [teamData, setTeamData] = useState([]);
    const [teamError, setTeamError] = useState(null);

    const [currentMember, setCurrentMember] = useState(null);
    // const [inviteMember, setInviteMember] = useState({
    //     email: "",
    //     role: defaultInviteOption.value // default is 'developer' role 
    // });
    const inviteMember = {
        email: "",
        role: defaultInviteOption.value // default is 'developer' role 
    };    
    const [memberError, setMemberError] = useState(null);
    const [inviteError, setInviteError] = useState(null);

    const [options, setOptions] = useState(defaultOptions);
    //const [inviteOptions, setInviteOptions] = useState(defaultOptions);
    const inviteOptions = defaultOptions

    const [validationErrors, setValidationErrors] = useState({});

    // handle edit
    const [credentials, setCredentials] = useState({
        email: "",
        first_name: "",
        last_name: "",
        role: "",
        status: "",
        uid: ''        
    });    

    const [changedFields, setChangedFields] = useState({});

    // handle invite
    const [inviteCredentials, setInviteCredentials] = useState({
        email: "",
        role: defaultInviteOption.value // default is 'developer' role     
    });    
    
    const [changedInviteFields, setChangedInviteFields] = useState({});    

    // valeria.serdiuk@qencode.com / qencodemainqauser

    const fetchTeamData = useCallback(async () => {
        setLoading(true);
        try {
            const { success, data, detail } = await getTeamData();
            if (success && data) {
                console.log("Team data: ", data);
                setTeamData(data);
                setTeamError(null); // Clear any existing errors when data is successfully fetched
            } else {
                setTeamError(detail || "Failed to get team data");
                console.error(detail || "Failed to get team data");
            }
        } catch (error) {
            setTeamError(error.message || "An unexpected error occurred while fetching team data");
            console.error(error);
        } finally {
            setLoading(false); // Ensure loading state is reset whether an error occurs or not
        }
    }, [getTeamData]);
         
    useEffect(() => {
        fetchTeamData();
    }, [fetchTeamData]);

    // Handlers for edit and remove actions
    // const handleEdit = (member) => {
    //     // Logic to handle edit action
    //     console.log("Edit: ", member)
    //     // show edit member modal window
    //     setCurrentMember(member); // Set the current member to be edited
    //     setUpdateMemberModalOpen(true)
    // };

    const handleLogout = () => {
        logout();
        navigate('/');
    };  

    const handleChangeRole = async ({member, role}) => {
        console.log("Change Role for: ", member)
        console.log("Change Role to: ", role)

        //console.log("userData: ", userData)

        // Clear previous error states
        if (memberError) {
            setMemberError(null);
        }
    

        // setIsSubmittingModal(true); // Start submitting state
    
        try {
            const response = await updateTeam({
                uid: member?.uid,
                member_role: role
            });
    
            const { success, data, detail } = response;
    
            if (success && data) {
                console.log("Team data updated: ", data);
                setTeamData(data); // Update team data on successful update
                //closeUpdateMemberModal(); // Close modal on successful update

                // here is situation, admin can change role and admit is returned in the list of Team
                // in case user logs as admin and changing role for himself need to logout user
                // UI and access depends on user role, so if role of current user gets changed
                // we forse him to login again
                if(member?.uid === userData?.id){
                    handleLogout()
                }

            } else {
                setMemberError(detail || "Failed to update team member");
                console.error(detail || "Failed to update team member");
            }
        } catch (error) {
            setMemberError(error.message || "An unexpected error occurred while updating team member");
            console.error(error.message || "Failed to update team member");
        } finally {
            //setIsSubmittingModal(false); // Ensure submitting state is reset
        }
    };     
    
    const handleOrganizationTransfer = async ({member}) => {

        console.log("Transfer organization to: ", member)

        setChangeOwnerModalOpen(true)
        setCurrentMember(member); 
    };       

    const closeUpdateMemberModal = () => {
        setUpdateMemberModalOpen(false)
        setChangedFields({})
        setCurrentMember(null)
        setMemberError(null);

        // clear validation errors
        setValidationErrors(prevErrors => {
            return Object.keys(prevErrors).reduce((acc, key) => {
                acc[key] = null; // Set each error to null
                return acc;
            }, {});
        });
    }


    const handleUpdateMember = async (e) => {
        e.preventDefault();
        // Clear previous error states
        if (memberError) {
            setMemberError(null);
        }
    
        // Validate input fields that have changed
        const fieldsToValidate = Object.keys(changedFields); // Only validate changed fields
        const { isValid, errors } = validateFields(changedFields, fieldsToValidate);
        
        // Update validation errors
        setValidationErrors(errors);
        if (!isValid) {
            console.log("Validation errors", errors);
            return; // Exit if validation fails
        }
    
        setIsSubmittingModal(true); // Start submitting state
    
        try {
            const response = await updateTeam({
                uid: currentMember?.uid,
                member_role: changedFields?.role
            });
    
            const { success, data, detail } = response;
    
            if (success && data) {
                console.log("Team data updated: ", data);
                setTeamData(data); // Update team data on successful update
                setChangedFields({}); // Reset changes since they are now persisted
                closeUpdateMemberModal(); // Close modal on successful update
            } else {
                setMemberError(detail || "Failed to update team member");
                console.error(detail || "Failed to update team member");
            }
        } catch (error) {
            setMemberError(error.message || "An unexpected error occurred while updating team member");
            console.error(error.message || "Failed to update team member");
        } finally {
            setIsSubmittingModal(false); // Ensure submitting state is reset
        }
    };
        

    const handleInviteMember = async (e) => {
        e.preventDefault();
        if (inviteError) {
            setInviteError(null);
        }
    
        const fieldsToValidate = Object.keys(changedInviteFields); // Validate only changed fields
        const { isValid, errors } = validateFields(changedInviteFields, fieldsToValidate);
    
        setValidationErrors(errors);
        if (!isValid) {
            console.log("Validation errors", errors);
            return;
        }
    
        setIsSubmitting(true);
    
        try {
            const response = await addTeamMember({
                email: inviteCredentials.email,
                member_role: inviteCredentials.role
            });
    
            const { success, data, detail } = response;
    
            if (success && data) {
                console.log("team data: ", data);
                setTeamData(data);
                setChangedInviteFields({});
                // Clear inputs
                setInviteCredentials(inviteMember);
                // Show success modal
                setInvitedSuccessModalOpen(true);
            } else {
                setInviteError(detail || "Failed to invite team member");
                console.error(detail || "Failed to invite team member");
            }
        } catch (error) {
            // Handle exceptions that may occur during the API call
            setInviteError(error.message || "Unexpected error occurred");
            console.error("Error inviting team member:", error);
        } finally {
            // This will run whether the try block succeeds or an error occurs
            setIsSubmitting(false);
        }
    };
      
    const handleRemoveMember = async (e) => {
        e.preventDefault();
        if (memberError) {
            setMemberError(null);
        }
    
        setIsSubmittingModal(true);
    
        try {
            const response = await removeTeamMember({
                uuid: currentMember?.uid,
            });
    
            const { success, data, detail } = response;
    
            if (success && data) {
                console.log("team data: ", data);
                setTeamData(data);
                closeRemoveMemberModal();
            } else {
                setMemberError(detail || "Failed to remove team member");
                console.log("Failed to remove team member");
            }
        } catch (error) {
            // Handle exceptions that may occur during the API call
            setMemberError(error.message || "Unexpected error occurred");
            console.error("Error removing team member:", error);
        } finally {
            // This will run whether the try block succeeds or an error occurs
            setIsSubmittingModal(false);
        }
    };


    const handleTransferOrganization = async (e) => {

        console.log("Transfer organization to :", currentMember)

        // after owner transfered current user becomes admin, so need to fetch again data for current user
        // test with alexander+4@qencode.com - owner
        // transfet to alexander+44@qencode.com - admin

        e.preventDefault();
        if (memberError) {
            setMemberError(null);
        }
    
        setIsSubmittingModal(true);
    
        try {
            const response = await transferOrganization({
                id: currentMember?.uid,
            });
    
            const { success, detail } = response;

            //console.log("response: ", response)
    
            if (success) {
                // fetch curent user data
                // reload user data
                //fetchUserData()

                // close modal
                closeChangeOwnerModal();

                // logout user
                handleLogout()                
            } else {
                setMemberError(detail || "Failed to change owner");
                console.log("Failed to change owner");
            }
        } catch (error) {
            // Handle exceptions that may occur during the API call
            setMemberError(error.message || "Unexpected error occurred");
            console.error("Error changing owner:", error);
        } finally {
            // This will run whether the try block succeeds or an error occurs
            setIsSubmittingModal(false);
        }
    };    
    

    const closeInvitedSuccessModal = () => {
        setInvitedSuccessModalOpen(false);      
    }; 

    const handleInputChange = (e) => {
        // clear error
        if (memberError) {
            setMemberError(null);
        }

        const { name, value } = e.target;
        const newCredentials = { ...credentials, [name]: value };
        setCredentials(newCredentials);

        // Check if the field's new value is different from currentMember's value
        if (currentMember[name] !== value) {
            setChangedFields({ ...changedFields, [name]: value });
        } else {
            // If the field's value is reverted to the original, remove it from changedFields
            const updatedChangedFields = { ...changedFields };
            delete updatedChangedFields[name];
            setChangedFields(updatedChangedFields);
        }


        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: null
        }));
    };  
    
    const handleInputChangeInvite = (e) => {
        // clear error
        if (inviteError) {
            setInviteError(null);
        }

        const { name, value } = e.target;
        const newCredentials = { ...inviteCredentials, [name]: value };
        setInviteCredentials(newCredentials);

        // Check if the field's new value is different from inviteMember's value
        if (inviteMember[name] !== value) {
            setChangedInviteFields({ ...changedInviteFields, [name]: value });
        } else {
            // If the field's value is reverted to the original, remove it from changedFields
            const updatedChangedFields = { ...changedInviteFields };
            delete updatedChangedFields[name];
            setChangedInviteFields(updatedChangedFields);
        }


        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [name]: null
        }));
    };      


    const handleRemove = (member) => {
        // Logic to handle remove action
        console.log("Remove: ", member)
        // show remove member modal window
        setCurrentMember(member); // Set the current member to be removed
        setRemoveMemberModalOpen(true)
    };

    const closeRemoveMemberModal = () => {
        setRemoveMemberModalOpen(false)
        setChangedFields({})
        setCurrentMember(null)
        setMemberError(null);

        // clear validation errors
        setValidationErrors(prevErrors => {
            return Object.keys(prevErrors).reduce((acc, key) => {
                acc[key] = null; // Set each error to null
                return acc;
            }, {});
        });
    }


    const closeChangeOwnerModal = () => {
        setChangeOwnerModalOpen(false)
        setCurrentMember(null)
        setMemberError(null);

        // clear validation errors
        setValidationErrors(prevErrors => {
            return Object.keys(prevErrors).reduce((acc, key) => {
                acc[key] = null; // Set each error to null
                return acc;
            }, {});
        });
    }


    // Define menuItems as a function that takes a member's uid and returns the menu items

    const getMenuItems = ({ member, onRemove, onChangeRole, onOwnerChange }) => {
        //console.log("member: ", member);
    
        // Define all possible roles
        const allRoles = [
            { role: 'admin', text: 'Admin' },
            { role: 'developer', text: 'Developer' },
            { role: 'billing', text: 'Billing' }
        ];
    
        // Filter out the member's current role from the available roles
        const filteredRoles = allRoles.filter(roleItem => roleItem.role !== member.role);
    
        // Create the roleSubmenuItems based on the filtered roles
        const roleSubmenuItems = [
            // Conditionally add "Owner" submenu item
            ...(userData && userData.role === 'owner' ? [{
                onClick: () => onOwnerChange({ member }),
                text: "Owner",
            }] : []),
            ...filteredRoles.map(roleItem => ({
                onClick: () => onChangeRole({ member, role: roleItem.role }),
                text: roleItem.text
            }))
        ];
    
        return [
            {
                icon: <Reload label="Change Role" />,
                text: "Change Role",
                trailingIcon: <RightArrow />,
                submenuItems: roleSubmenuItems
            },
            {
                icon: <Delete label="Remove" />,
                onClick: () => onRemove(member),
                text: "Remove",
                danger: true
            }
        ];
    };
    

    // const getMenuItems = ({ member, onRemove, onChangeRole, onOwnerChange }) => {
    //     console.log("member: ", member)
    //     const roleSubmenuItems = [
    //         // Conditionally add "Owner" submenu item
    //         ...(userData && userData.role === 'owner' ? [{
    //             onClick: () => onOwnerChange({ member }),
    //             text: "Owner",
    //         }] : []),
    //         {
    //             onClick: () => onChangeRole({ member, role: "admin" }),
    //             text: "Admin",
    //         },
    //         {
    //             onClick: () => onChangeRole({ member, role: "developer" }),
    //             text: "Developer",
    //         },
    //         {
    //             onClick: () => onChangeRole({ member, role: "billing" }),
    //             text: "Billing",
    //         }
    //     ];
    
    //     return [
    //         {
    //             icon: <Reload label="Change Role" />,
    //             text: "Change Role",
    //             trailingIcon: <RightArrow />,
    //             submenuItems: roleSubmenuItems
    //         },
    //         {
    //             icon: <Delete label="Remove" />,
    //             onClick: () => onRemove(member),
    //             text: "Remove",
    //             danger: true
    //         }
    //     ];
    // };
    
      
    const getMenuItemsLimited = ({member, onRemove}) => [
        {
            icon: <Delete label="Remove" />,
            onClick: () => onRemove(member), // And here
            text: "Remove",
            danger: true
        }
    ];         

    // // Define menuItems as a function that takes a member's uid and returns the menu items
    // const getMenuItems = (member, onEdit, onRemove, onChangeRole) => [
    //   {
    //     icon: <Edit label="Change Role" />,
    //     onClick: () => onChangeRole(member), // Use the passed UID here
    //     text: "Change Role"
    //   },        
    //   {
    //     icon: <Edit label="Edit" />,
    //     onClick: () => onEdit(member), // Use the passed UID here
    //     text: "Edit"
    //   },
    //   {
    //     icon: <Delete label="Remove" />,
    //     onClick: () => onRemove(member), // And here
    //     text: "Remove",
    //     danger: true
    //   }
    // ];  
    
    // const getMenuItemsLimited = (member, onRemove) => [
    //     {
    //       icon: <Delete label="Remove" />,
    //       onClick: () => onRemove(member), // And here
    //       text: "Remove",
    //       danger: true
    //     }
    //   ];      
  
    // Update the local state when currentMember changes
    useEffect(() => {
        if (currentMember) {

            setCredentials({
                email: currentMember.email || '',
                first_name: currentMember.first_name || '',
                last_name: currentMember.last_name || '',
                role: currentMember.role || '',
                status: currentMember.status || '',
                uid: currentMember.uid || ''  
            });

            // Update options based on currentMember's role
            const updatedOptions = defaultOptions.map(option => ({
                ...option,
                // Assuming 'checked' is used for UI logic to visually indicate a match
                checked: currentMember.role === option.value,
            }));            

            setOptions(updatedOptions);            
        }
    }, [currentMember]);


    // manage combined input
    const [isFocused, setIsFocused] = useState(false);
    // const inviteRef = useRef(null);
    // useOutsideClick(inviteRef, () => setIsFocused(false));

    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    if (loading) {
        return <div>Loading...</div>; // Show a loading state
    }

    return (
        <div>

            <div className={styles.inviteMemberContainer}>
                <Label text='Invite team members' />
                <div className={styles.inviteMemberInputs}>
                    <div className={`${styles.inviteMemberCombinedInput} ${isFocused ? styles.focused : ''}`}>
                        <div className={styles.inviteMemberInputField}>
                            <InputField
                                inputText={inviteCredentials.email}
                                onChange={handleInputChangeInvite}
                                name="email"
                                placeholder="Enter email address"
                                variant="combined"

                                onFocus={handleFocus}
                                onBlur={handleBlur}
                            />                            
                        </div>
                        <div className={styles.inviteMemberSelect}>
                            <Select                            
                                onChange={handleInputChangeInvite}
                                name="role"
                                options={inviteOptions}
                                variant="combined"
                                value={inviteCredentials.role}

                                onFocus={handleFocus}
                                onBlur={handleBlur}
                            />                             
                        </div>
                    </div>
                    <div>
                        <Button 
                            onClick={handleInviteMember} 
                            state={isSubmitting || inviteCredentials.email === '' || Object.keys(changedInviteFields).length === 0 ? "disabled" : undefined} 
                        >
                            Send Invite
                        </Button>                         
                    </div>
                </div>
                
                {validationErrors?.role && <div className={styles.combinedErrorHint}>{validationErrors?.role}</div>}
                {validationErrors?.email && <div className={styles.combinedErrorHint}>{validationErrors?.email}</div>}
                {inviteError && <div className={styles.combinedErrorHint}>{inviteError}</div>} 
             
            </div>

            <div className="tableContainer">
                <table className={`standardTable ${teamData?.length === 0 ? 'emptyTable' : ''}`}>
                    <thead>
                        <tr>
                            <th>Member</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    {teamData.map(member => (
                      <tr key={member.uid} className="tableRow">
                        <td className='cellHighlighted'>
                            <div className="cellContainer">
                                <div className="cellLimited">{member.name}</div>
                            </div>
                        </td>
                        <td>
                            <div className="cellContainer">
                                <div className="cellLimited">{member.email}</div>
                            </div>
                        </td>
                        <td className="cellCapitilized">
                            <div className="cellContainer">{member.role}</div>
                        </td>
                        <td>
                            <div className={styles.cellContainer}>
                                <div className={styles.memberStatus}>
                                    {member.status === "active" && <Active className={`${styles.statusIcon} ${styles.active}`} />}
                                    {member.status === "invited" && <Inactive className={`${styles.statusIcon} ${styles.inactive}`}/>}
                                    <div>{member.status}</div>  
                                </div>                                
                            </div>
                        </td>
                        <td className="optionButtonsCell">
                            <div className="cellContainer">
                                {
                                    member.status === "active"
                                    ?<OptionsMenu 
                                        menuItems={getMenuItems({
                                            member, 
                                            onRemove: handleRemove, 
                                            onChangeRole: handleChangeRole,
                                            onOwnerChange: handleOrganizationTransfer
                                        })} 
                                    />
                                    :<OptionsMenu menuItems={getMenuItemsLimited({member, onRemove: handleRemove})} />
                                }                                
                            </div>                            
                            {/* <div className="cellContainer">
                                {
                                    member.status === "active"
                                    ?<OptionsMenu menuItems={getMenuItems(member, handleEdit, handleRemove)} />
                                    :<OptionsMenu menuItems={getMenuItemsLimited(member, handleRemove)} />
                                }                                
                            </div> */}
                        </td>
                      </tr>
                    ))}                    
                    </tbody>
                </table>    
                {
                    teamData?.length === 0 &&
                    <NoData
                        image={<MembersImage />}
                        title="No members found"
                        description={
                            <>
                                Start by adding members and assigning roles
                            </>
                        }
                    />                       
                }
            </div>

            {teamError && <div className={styles.errorMessage}>{teamError}</div>}

            <Modal isOpen={isUpdateMemberModalOpen} onClose={closeUpdateMemberModal}
                header={
                    <>
                        <h3>Change role</h3>                  
                    </>
                }
                footer={
                    <>
                        <Button
                            variant="modalFooter"
                            onClick={closeUpdateMemberModal}
                            type="secondary"
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="modalFooter"
                            onClick={handleUpdateMember} 
                            //state={isSubmittingModal ? "disabled" : undefined}
                            state={isSubmittingModal || Object.keys(changedFields).length === 0 ? "disabled" : undefined} 
                        >
                            Update Role
                        </Button>                       
                    </>
                }
            >
                <div className="form">      
                    <div className={styles.selectRoleContainer}>
                        <div className={styles.memberName}>
                            {currentMember?.first_name} {currentMember?.last_name} 
                        </div>

                        <div className={styles.memberRoleSelectContainer}>
                            <Select
                                onChange={handleInputChange}
                                name="role"
                                options={options}
                                value={credentials.role}
                                variant="changeRole"
                                size="lg"
                            />                               
                        </div>
                    </div>    
                    {validationErrors?.role && <div className={styles.errorMessage}>{validationErrors?.role}</div>}
                    {memberError && <div className={styles.errorMessage}>{memberError}</div>}   
                </div>  
            </Modal>      

            <Modal isOpen={isInvitedSuccessModalOpen} onClose={closeInvitedSuccessModal}
                variant="popUp"
                icon={<Success />}
                header={
                    <>
                        <h3>Member Invited</h3>
                        <p>Your team member should receive an email shortly.</p>                          
                    </>
                }
                footer={
                    <Button 
                        type='secondary'
                        variant="modalFooter"
                        onClick={closeInvitedSuccessModal} 
                    >
                        Close
                    </Button>                     
                }
            >
            </Modal>          

            <Modal isOpen={isRemoveMemberModalOpen} onClose={closeRemoveMemberModal}
                variant="popUp"
                icon={<DeleteAccount />}
                header={
                    <>
                        <h3>Remove this Team Member?</h3>      
                        <p>
                            Are you sure you want to remove team member <strong>{currentMember?.first_name} {currentMember?.last_name} {(currentMember?.first_name || currentMember?.last_name) && <>-</>} {currentMember?.email}</strong>?
                        </p>            
                    </>
                }
                footer={
                    <>
                        <Button
                            variant="modalFooter"
                            onClick={closeRemoveMemberModal}
                            type="secondary"
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="modalFooter"
                            icon={<Delete />}
                            onClick={handleRemoveMember} 
                            type="danger"
                            state={isSubmittingModal ? "disabled" : undefined}
                        >
                            Remove
                        </Button>                       
                    </>
                }
            > 
            </Modal>                                     


            {/* <Modal isOpen={isRemoveMemberModalOpen} onClose={closeRemoveMemberModal}
                header={
                    <>
                        <h3>Remove team member?</h3>                  
                    </>
                }
                footer={
                    <>
                        <Button
                            variant="modalFooter"
                            onClick={closeRemoveMemberModal}
                            type="secondary"
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="modalFooter"
                            onClick={handleRemoveMember} 
                            type="danger"
                            state={isSubmittingModal ? "disabled" : undefined}
                        >
                            Remove
                        </Button>                       
                    </>
                }
            >
                <div className="form">      
                    <div className={styles.removeMemberContainer}>
                        <div className={styles.removeMemberName}>
                            {currentMember?.first_name} {currentMember?.last_name} 
                        </div>

                        <div className={styles.removeMemberRole}>{currentMember?.role}</div>

                        <div className={styles.memberStatus}>
                            {currentMember?.status === "active" && <Active className={`${styles.statusIcon} ${styles.active}`} />}
                            {currentMember?.status === "invited" && <Inactive className={`${styles.statusIcon} ${styles.inactive}`}/>}
                            <div>{currentMember?.status}</div>  
                        </div>
                    </div>    
                    {validationErrors?.role && <div className={styles.errorMessage}>{validationErrors?.role}</div>}
                    {memberError && <div className={styles.errorMessage}>{memberError}</div>}   
                </div>  
            </Modal>                          */}

            <Modal isOpen={isChangeOwnerModalOpen} onClose={closeChangeOwnerModal}
                variant="popUp"
                icon={<ChangeUser />}
                header={
                    <>
                        <h3>Change Owner of Account?</h3>      
                        <p>
                            Are you sure you want to make <strong>{currentMember?.first_name} {currentMember?.last_name} {(currentMember?.first_name || currentMember?.last_name) && <>-</>} {currentMember?.email}</strong> the 
                            owner of&nbsp;
                            {userData && userData.organization_name  
                                ? <strong>{userData.organization_name}</strong>
                                : <>this organization</>
                            }
                            ?                            
                        </p>            
                    </>
                }
                footer={
                    <>
                        <Button
                            variant="modalFooter"
                            onClick={closeChangeOwnerModal}
                            type="secondary"
                        >
                            Cancel
                        </Button>
                        <Button 
                            variant="modalFooter"
                            onClick={handleTransferOrganization} 
                            state={isSubmittingModal ? "disabled" : undefined}
                        >
                            Confirm
                        </Button>                       
                    </>
                }
            > 
                <div className={styles.changeOwnerDetails}>
                    <div className={styles.changeOwnerDetailsTitle}>Owner Role Permissions:</div>
                    <ul className={styles.changeOwnerDetailsList}>
                        <li>All Admin Role Permissions</li>
                        <li>Ability to Delete Admin</li>
                        <li>Ability to Transfer Ownership to Another User</li>
                    </ul>
                </div>
            </Modal>   

        </div>
    );
};

export default Team;