import React from 'react';
import PropTypes from 'prop-types';
import {OptionsMenu} from "components/OptionsMenu";
import {Copy, Delete, Inactive, Settings} from "icons";
import {modals} from 'pages/MediaStorage/BucketsPage/config';
import useCopyToClipboard from "components/CopyToClipboard/useCopyToClipboard";
import {purgeCdnCache} from "api/qencode-content-delivery-api";
import {useAuth} from "context/AuthContext";

function MoreActions({ handleOpenModal, bucket, setSelected }) {
  const {
    getToken
  } = useAuth();
  const {
    copy,
  } = useCopyToClipboard(bucket.endpoint);

  return (
    <OptionsMenu
      menuItems={
        [
          {
            text: 'Copy Endpoint URL',
            icon: <Copy label="Copy Endpoint URL"/>,
            onClick: copy,
          },
          {
            text: 'Purge CDN Cache',
            icon: <Inactive label="Purge CDN Cache" />,
            onClick: async () => {
              const accessToken = await getToken()
              if (bucket.cdn_service) {
                purgeCdnCache({
                  accessToken,
                  bucketName: bucket.name,
                })
              }
              
              console.log(bucket)
            }
          },
          {
            text: 'Bucket Settings',
            icon: <Settings label="Bucket Settings"/>,
            onClick: () => {
              setSelected(bucket);
              handleOpenModal(modals.edit);
            },
          },
          {
            icon: <Delete label="Remove" />,
            onClick: () => {
              setSelected(bucket);
              handleOpenModal(modals.delete)
            },
            text: "Delete",
            danger: true,
          }
        ]
      }
    />
  );
}

MoreActions.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
  bucket: PropTypes.shape({
    cdn_service: PropTypes.string,
    created: PropTypes.object,
    endpoint: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    region: PropTypes.string.isRequired,
  }),
  setSelected: PropTypes.func.isRequired,
};

export default MoreActions;